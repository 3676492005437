import GRAPHICAL_COURSE_TYPES from './types';
import axios from 'axios';
import Vue from 'vue';
import { ALL_DEPOTS } from '@/components/depotpositions/types';
import {utils} from './helper';

export default {
  [GRAPHICAL_COURSE_TYPES.ACTIONS.CONFIG]({ commit, state, rootState }) {
    if (!state.config.beginDate && !state.config.request) {
      state.config.request = true;
      axios.get(rootState.core.apiAddress + '/graphicalcourse/get_configuration', {}).then(response => {
        commit(GRAPHICAL_COURSE_TYPES.MUTATIONS.CONFIG, response.data);
      }).catch(error => {
        state.config.request = false;
        console.error(error);
        // commit(GRAPHICAL_COURSE_TYPES.MUTATIONS.AGGREGATE, error.response && error.response.data);
      })
    }
  },
  
  [GRAPHICAL_COURSE_TYPES.ACTIONS.DEPOT]({ commit, dispatch, state, rootState }, arg) {
    if (arg?.depotid == -1) {
      arg.depotid = ALL_DEPOTS;
    }
    const session = state.sessions[arg.site];
    const changeDepot = arg.depotid && arg.depotid !== session.depot;
    if (changeDepot) {
      commit(GRAPHICAL_COURSE_TYPES.MUTATIONS.DEPOTID, {depotid: arg.depotid, site: arg.site});
    }
    const depot = session.depot;
    const beginDate = session.beginDate;
    const endDate = session.endDate;

    ['aggregate', 'details', 'perf_chart'].forEach(k => {
      if (!state[k][depot]) {
        Vue.set(state, k, {
          ...(state[k] || {}),
          [depot]: { request: '' },
        });
      }
    });
    if (!state.config.request) {
      dispatch(GRAPHICAL_COURSE_TYPES.ACTIONS.CONFIG);
    }
    const refresh = !state.aggregate[depot].request || changeDepot ;// || state.aggregate[depot].beginDate.getTime() < beginDate.getTime();
    if ( refresh) {
      Vue.set(state, 'aggregate', {
        ...(state.aggregate || {}),
        [depot]: {
          ...(state.aggregate[depot] || {}),
          request: '??',
          beginDate: beginDate,
          endDate: endDate,
        },
      });
      const depotId = depot === ALL_DEPOTS ? '-1' : depot;
      axios.get(rootState.core.apiAddress + `/graphicalcourse/get_data?depotId=${depotId}&years=-1`, {}).then(
        (response) => {
          console.log(response)
          if (response.data?.aggregate) {
            if (process.env.VUE_APP_USER === 'hannes') console.log('graphicalcourse', JSON.parse(JSON.stringify(response.data)) );
            
            const xLabel = utils.getXLabel(response.data);
            response.data.aggregate.options.xLabel = xLabel;
            if (response.data?.details?.options) {
              response.data.details.options.xLabel = xLabel;
            }
            response.data.aggregate.series.forEach(ser => ser.config.kapitalGewichtet=response.data.kapitalGewichtet);
            if (response.data.details?.series?.length) {
              const namen = {};
              response.data.details.series.forEach(ser => {
                ser.config.kapitalGewichtet=response.data.kapitalGewichtet
                if (namen[ser.name]) {
                  namen[ser.name]++;
                  ser.name = ser.name + ' (' + namen[ser.name] + ')';
                } else {
                  namen[ser.name] = 1;
                }
              });
            }
            
            const aktWert = response.data.aggregate.series.find(ser => ser.name==='Aktueller Wert');
            const anlage = response.data.aggregate.series.find(ser => ser.name==='Anlagesumme');
            if (aktWert && anlage ) {
              let config, perf_chart, perfSeries = [];
              response.data.perf_chart = {
                options: response.data.aggregate.options,
                series: perfSeries
              }; 
              response.data.aggregate.series.forEach(ser => {
                if (ser.name !== 'Anlagesumme' && (ser.config.type==='value' || ser.config.type==='derived')) {
                  config = { ...ser.config, currency: '%', aktWert: ser.data2, anlage: anlage.data2, xLabel, startIndex: 0};
                  if (ser.config.type==='derived') {
                    config.aktData = aktWert.data2;
                  }
                  perf_chart = utils.calcPerformanceChart(config, !state.config.grafNachTransakt);
                  if (perf_chart) {
                    perfSeries.push({
                      config: config,
                      data: perf_chart,
                      name: ser.name,
                    })
                  }
                }
              })
              if (response.data.details?.series?.length) {
                response.data.details.series.forEach((ser, index) => {
                  if (ser.config) {
                    ser.config.id = 'chart' + index;
                  }
                  for (let ix = 0; ix < ser.investment.length; ix++) {
                    if (ser.investment[ix] != null) {
                      ser.config.firstIndexAnlage = ix;
                      break;
                    }
                  }
                })
              }
            }
          }
          let anlageSummeNetto = undefined;
          if ( response.data.kapitalGewichtet === false && response.data.anlagesummeNetto?.length) {
            if (-1 == response.data.anlagesummeNetto.findIndex(a => a.fond == 'ANLAGESUMMENETTO')) {
              anlageSummeNetto = [];
              let item = {
                datum: response.data.anlagesummeNetto[0].datum, 
                wert: response.data.anlagesummeNetto[0].wert, 
                time: response.data.anlagesummeNetto[0].time,
                fond: 'ANLAGESUMMENETTO',
              };
              anlageSummeNetto.push(response.data.anlagesummeNetto[0]);
              for( let k = 1; k < response.data.anlagesummeNetto.length; k++ ) {
                if (item.time == response.data.anlagesummeNetto[k].time) {
                  item.wert += response.data.anlagesummeNetto[k].wert;
                } else {
                  anlageSummeNetto.push(item);
                  item =  {
                    datum: response.data.anlagesummeNetto[k].datum, 
                    wert: response.data.anlagesummeNetto[k].wert, 
                    time: response.data.anlagesummeNetto[k].time,
                    fond: 'ANLAGESUMMENETTO',
                  };
                }
                anlageSummeNetto.push(response.data.anlagesummeNetto[k]);
              }
              anlageSummeNetto.push(item);
            } else {
              anlageSummeNetto = response.data.anlagesummeNetto;
            }
            anlageSummeNetto = anlageSummeNetto.map(item => {
              const p = item.datum.split('.').map(v => parseInt(v,10));
              item.time = Date.UTC(p[2], p[1] - 1, p[0]);
              return item;
            } )
            if (process.env.VUE_APP_USER === 'hannes') console.log('ANLAGESUMMENETTO', JSON.parse(JSON.stringify(anlageSummeNetto)) );
          }
          ['aggregate', 'details', 'perf_chart'].forEach(k => {
            if (response.data?.[k]) {
              Vue.set(state, k, {
                ...state[k],
                [depot]: {
                  ...state[k][depot],
                  request: depot,
                  beginDate: beginDate,
                  endDate: endDate,
                  data: response.data[k],
                  anlageSummeNetto,
                },
              });
            }
          })
          commit(GRAPHICAL_COURSE_TYPES.MUTATIONS.DEPOT);
        }
      ).catch(error => {
        state.aggregate[depot].request = '';
        console.error(error);
      })
      
    } else if (state.aggregate[depot].request === depot) {
      commit(GRAPHICAL_COURSE_TYPES.MUTATIONS.DEPOT, arg.site);
    }
  },

  [GRAPHICAL_COURSE_TYPES.ACTIONS.CACHE_LEEREN]({commit, dispatch, state, rootState }, depotid) {
    axios.get(rootState.core.apiAddress + '/graphicalcourse/cache_leeren', {})
    .then(() => {
      console.log('page', depotid, state.sessions.page && JSON.parse(JSON.stringify(state.sessions.page)) );
      state.sessions.page.depot = {request: '' } ;
      dispatch(GRAPHICAL_COURSE_TYPES.ACTIONS.DEPOT, {site: 'page', depotid});
    })
  },
  
}