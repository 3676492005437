<template>
    <div>
        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.parent.label"
            :subtitle="$appNavigation.currentMenu.label"
            :defaultMenu="$appNavigation.currentTabMenu"
            :actions="actions"
            @action-NEW="navigateToAutomaticDepotLimit({id: 'neu'})"
            @action-RESET="doConfirm('zurücksetzen')"
        />

        <div class="box__container" v-if="!isCustomerView">
            <div class="layout__negative-margin--8 antrag-action-button__container">
                <div class="mt-4">
                    <InputToggleSwitch v-model="includeStruktur" label="Limits der Untermakler anzeigen" inLineLabel suppressValidationMessage v-if="!isCustomerView" @input="reload()"/>
                </div>
            </div>
        </div>

        <div class="box__container">
            <div v-if="!loading && rows && rows.length">
                <Table
                    tableId="11fcafaf-5332-48db-8ba2-1u5ba7251111"
                    :title="TABLE_TITLE"
                    :headers="headers"
                    :rows="rows"
                    :rowsPerPage="20"
                    rowId="id"
                    :mobileConfig="{title: 'depot', headers: ['kundenLinkName', 'abweichungUnten', 'abweichungOben']}"
                    @action-edit="navigateToAutomaticDepotLimit"
                    @action-delete="removeLimit"
                    @click-kundenLinkName="openCustomerNewTab"
                >
                </Table>
            </div>
            <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
            <NoData v-else :title="TABLE_TITLE" />
        </div>

    </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, PercentageColumn, SimpleAction} from "@/components/table2/table_util.js";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { mapGetters } from 'vuex';
import LIMIT_TYPES from '@/store/limit/types';
import mixin from '@/mixins/limit/limit-mixin.js';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const TABLE_TITLE = 'Automatische Depotpositionslimits';

export default {
    mixins: [mixin],
    components: {
        Table,
        InputToggleSwitch,
        PageHeaderTitleNavigation,
        GhostLoading,
        NoData,
    },
    data() {
        return {
            TABLE_TITLE,
            loading: false,
            includeStruktur: '0',
        }
    },
    mounted() {
        this.reload();
    },
    computed: {
        ...mapGetters({
            limits: LIMIT_TYPES.GETTERS.DEPOTPOSITION_LIMIT_AUTOMATIC_LIST,
        }),
        headers() {
            const kundenLinkName = TextColumn("kundenLinkName", "Name");
            const depot = TextColumn("depot", "Depot").makeAlwaysVisible();
            
            if (!this.isCustomerView) {
                kundenLinkName.makeLink();
                depot.makeConditionalLink("url_or_depotnr")
            }
            
            const headers = { 
                lockedLeft: [
                    depot, 
                ],
                center: [
                    PercentageColumn("abweichungUnten", "Unteres Limit", 2),
                    PercentageColumn("abweichungOben", "Oberes Limit", 2),
                    TextColumn("dynamik", "Dynamik"),
                ],
                lockedRight: [
                   ActionColumn("actions", ""),
                ],
            };
            if (!this.isCustomerView) {
                // insert after status
                headers.lockedLeft.splice(1, 0,
                    TextColumn("kundennr", "Kundennr").makeAlwaysVisible(),
                    kundenLinkName);
            }
            return headers;
        },
        rows() {
            const result = !this.limits ? [] : this.limits.map((limit, index) => {
                const actions = [SimpleAction("edit", '', "Limit bearbeiten"), SimpleAction("delete", '', "Limit löschen")];
                return {
                    ...limit,
                    dynamik: limit?.isDynamisch === '1' ? 'Ja' : 'Nein',
                    actions: actions,
                };
            });
            return result;
        },
        actions() {
            const actions = [];

            if (this.isCustomerView) {
                actions.push(PageHeaderSimpleAction('NEW', 'Neu'));
            }
            actions.push(
                // PageHeaderSimpleAction('REMOVE', 'Überschrittene Limits löschen').withDisabled(() => !this.rows?.length ),
                PageHeaderSimpleAction('RESET', 'Überschrittene Limits zurücksetzen').withDisabled(() => !this.rows?.length )
            );

            return actions;
        }
    },
    methods: {
        async reload() {
            try {
                this.loading = true;
                await this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_DEPOTPOSITION_LIMIT_AUTOMATIC_LIST, {includeStruktur: this.includeStruktur});
            } finally {
                this.loading = false;
            }
        },
        navigateToAutomaticDepotLimit(event) {
            this.$addBreadcrumb({
                label: `zurück zu den automatischen Depotpositionslimits`,
                fullPath: this.$route.fullPath,
                breadcrumb: 'Automatisches Depotpositionslimit',
            });
            this.$router.push(`${this.mainPath}/automatic-depotpositionlimit/${event?.id || 'neu'}?kundennr=${event?.id &&event?.id !== 'neu' && event?.kundennr || ''}`)
        },
        removeLimit(event) {
            if (event?.id) {
                this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_DEPOTPOSITION_LIMIT_AUTOMATIC, {id: event?.id, includeStruktur: this.includeStruktur});
            }
        },
        async doConfirm(exec) {
            await this.doConfirmAction(exec, 'depotpositionLimitAutomaticList');
        },
    }
}
</script>

<style>

</style>