import AFFILIATE_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import ANTRAG_TYPES from '@/store/antrag/types'
import axios from 'axios';
import { knownParams } from '@/mixins/antrag/antrag-mixin';
import {prepareParams} from '@/store/antrag/actions'


const config = {
  defaultSpinner: true
};

/**
 * Login request checks whether the 2FA is required.
 * 
 * When yes, this request should be spit into three steps:
 * 1 - (loginRequestFirstStep) Get mfaChallenge or get the regular login token if no 2FA is required.
 * 2 - (loginRequestSecondStep) Call a waiting endpoint until the 2FA is confirmed in the APP
 * 3 - (loginRequestThirdStep) Request the actual login data to get token and anything else
 * 
 * When no, request a single step
 * 1 - Request the actual login data to get token and anything else
 * 
 * Aside from that, when the request has sitzungID, it should load the session right away.
 * 
 * @param {*} getters 
 * @param {*} payload 
 */
async function loginRequest(getters, payload) {
  const sitzungID = payload.sitzungID || payload.SitzungID;

  payload.clientIdToken = getCookie("clientIdToken");

  if (sitzungID) {
    const sitzungPayload = {
      sitzungID,
      clientIdToken: payload.clientIdToken,
    }
    return await loginRequestUsingSitzungID(getters, sitzungPayload);
  }

  return await loginRequestThirdStep(getters, payload);
}

async function loginRequestThirdStep(getters, payload) {
  const { mfaSessionSecurityToken, mfaChallengeId } = payload;

  const mobileNativeSpec = getters[BRIDGE_TYPES.GETTERS.MOBILE_NATIVE_SPEC];

  const thirdStepPayload = {
    mfaSessionSecurityToken,
    mfaChallengeId,
    userid: payload.userid,
    mfaCode: payload.mfaCode,
    appLoginToken: payload.appLoginToken,
    factor2: payload.factor2,
    mobileNativeContext: getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT],
    callbackToken: payload.callbackToken,
    ...(mobileNativeSpec ? mobileNativeSpec : {})
  }

  const response =  await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/credentials`, thirdStepPayload, loginConfig);
  return response;
}

async function loginRequestUsingSitzungID(getters, sessionPayload) {
  return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/session`, sessionPayload, loginConfig);
}

export default {
  async [AFFILIATE_TYPES.ACTIONS.GET_ANTRAG]({state, commit, getters, rootState, dispatch }, payload) {
    return new Promise((resolve, reject) => {
          // const param = prepareParams(payload);
          const requiredParams = knownParams.reduce((accu, curr) => {
            payload[curr] ? accu[curr] = payload[curr] : ''
            return accu;
          }, {})
    
          requiredParams.hauptpersonId = payload.hauptpersonId
          
          const params = prepareParams(requiredParams);
          //?${params}
          //dispatch(CORE_TYPES.ACTIONS.GET_BROKER_LAYOUT, {});
          return axios.post(`${rootState.core.apiAddress}/affiliate/antrag`, {...payload}, config).then(response => {
            if (response.status === 200 && response.data.id) {
              console.log(response)
              commit(AFFILIATE_TYPES.MUTATIONS.GET_ANTRAG_SUCCESS, {...response.data, ignore: payload.ignore});
              resolve();
            } else {
              reject();
            }
          });
        });
  },
  [AFFILIATE_TYPES.ACTIONS.SAVE_ANTRAG]({state, commit, getters, rootState, dispatch }, payload) {
    return new Promise((resolve, reject) => {
          // const param = prepareParams(payload);
          // const requiredParams = knownParams.reduce((accu, curr) => {
          //   payload[curr] ? accu[curr] = payload[curr] : ''
          //   return accu;
          // }, {})
    
          // requiredParams.hauptpersonId = payload.hauptpersonId
          
          // const params = prepareParams(requiredParams);
          //?${params}
          return axios.post(`${rootState.core.apiAddress}/affiliate/save`, {...payload}, config).then(response => {
            if (response.status === 200 && response.data.id) {
              console.log(response)
              //commit(AFFILIATE_TYPES.MUTATIONS.GET_ANTRAG_SUCCESS, {...response.data, ignore: payload.ignore});
              resolve();
            } else {
              reject();
            }
          });
        });
  },
  [ANTRAG_TYPES.ACTIONS.CREATE_AFFILIATE_ACCOUNT]({ state, commit, getters, rootState, dispatch }, payload) {
    commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, {id, data});
    const antrag = getters[ANTRAG_TYPES.GETTERS.ANTRAEGE][id]

    // When Hauptperson was changed we need to do a reload of all persons
    if (data[state.antraege[id].hauptpersonName] !== undefined) {
      const payload = {...state.antraegeReloadPayload[id], ...antragData}
      payload.hauptpersonId = data[state.antraege[id].hauptpersonName]
      payload.ignore = state.antraege[id].hauptpersonName;
      dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_PERSONEN_AUSWAHL_CHANGE, payload);
    }

    
    if (state.antraege[id].reloadTriggerNames && data && Object.keys(data).length > 0 && state.antraege[id].reloadTriggerNames.includes(Object.keys(data)[0])) {
      const triggerconfig = {
        defaultSpinner: true,
        params: {
          requestPositions: positions,
          antragTyp: antrag.antragTyp, 
        }
      };
      const payload = {...state.antraegeReloadPayload[id], ...antragData, reloadTrigger: Object.keys(data)[0], reloadTriggerValue: Object.values(data)[0]}

      dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_TRIGGER_CHANGE, {config: triggerconfig, payload: payload});

    } else if (componentType && (componentType === 'BUTTON' || componentType === 'ATTACHMENTS')) {
      const payload = {...state.antraegeReloadPayload[id], 
        submitTrigger: data.button, 
        submitDataName: data.submitDataName, 
        submitData: data.submitData, 
        id:id,
        antragTyp: antrag.antragTyp, }
      dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_SUBMIT, payload);

    } else if (state.antraege[id].submitTriggerNames && data && Object.keys(data).length > 0 
      && state.antraege[id].submitTriggerNames.includes(Object.keys(data)[0])) {
        const payload = {...state.antraegeReloadPayload[id], 
          submitTrigger: Object.keys(data)[0], 
          submitDataName: Object.keys(data)[0], 
          submitData: Object.values(data)[0], 
          id:id,
          antragTyp: antrag.antragTyp, }
        dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_SUBMIT, payload);

    } else if (componentType && componentType === 'MULTIPLE_SELECTION_TABLE') {
      const payload = {
        ...state.antraegeReloadPayload[id], 
        submitTrigger: data.action,
        submitData: data.actionData || {[data.componentId] : {[data.componentData.key]: data.componentData.row[data.componentData.key]}}, 
        submitDataName: data.actionDataName || data?.componentId, 
        id:id,
        antragTyp: antrag.antragTyp
      }
      dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_SUBMIT, payload);
    }
  },

  [ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAEGE_DATA]({ state, commit, rootState, dispatch }) {
    Object.keys(state.antraege).forEach(id => {
      if (state.antraegeReloadPayload[id] && state.antraegeData[id] && state.antraege[id].hauptpersonName) {
        const payload = {...state.antraegeReloadPayload[id], ...state.antraegeData[id]}
        payload.hauptpersonId = state.antraegeData[id][state.antraege[id].hauptpersonName]
        dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_PERSONEN_AUSWAHL_CHANGE, payload).then(() => {
          Vue.set(state.antraege[id], 'dataHasChanged', true);
          dispatch(ANTRAG_TYPES.ACTIONS.SAVE_ANTRAG, { id: id, lagerstelle: state.antraege[id].id.split('-')[0], action: 'save'});
        });
      }
    });
  },

    async [CORE_TYPES.ACTIONS.LOGIN]({ commit, dispatch, getters }, payload) {
      const referrer = document.referrer;
  
      try {
        //commit(CORE_TYPES.MUTATIONS.SET_LOGGING_IN, true);
  
        const response = await loginRequest(getters, payload);
  
        let responseData = null;
  
        // if (response?.data?.validUntil != null) {
        //     commit(SSO_TYPES.MUTATIONS.LOGIN, {
        //         sessionId: response.headers.sso,
        //         loginName: payload.userid,
        //         referrer,
        //         ...response.data,
        //     });
        //     if (response.data.loginResponse) {
        //         responseData = { ...response.data.loginResponse, currentDate: new Date().getTime()};
        //     } else {
        //         router.push({name: 'sso-login'});
        //     }
        // }
        // else 
        if (response && response.data.token) {
            responseData = { ...response.data, currentDate: new Date().getTime()}
        } else {
            // login is incomplete or failed, let the caller handle it
            return response.data;
        }
  
        if (responseData?.clientIdToken) {
          setCookie("clientIdToken", responseData.clientIdToken, 90);
        }
  
        // redirects to the callbackURL
        if (payload.callbackURL) {
          window.location.replace(payload.callbackURL);
          return;
        }
  
        const loginName = payload.userid;
        
        if (response.data?.token && payload.userid && response.data?.appLoginToken) {
          dispatch(BRIDGE_TYPES.ACTIONS.SAVE_HASHED_PASSWORD, { 
            loginName, 
            password: response.data?.appLoginToken
          });
        }
        if (responseData) {
          saveLoginDataSessionStorage(getters, payload, responseData)
  
          // if (!payload.isKundenzugang && !payload.isMaklerzugang) {
          //   dispatch(BRIDGE_TYPES.ACTIONS.UPDATE_SETTINGS, {
          //     allowPushNotification: !!response?.data?.rights?.isAppAktiv,
          //     layoutParamsFC: response?.data?.layoutParamsFC
          //   });
          // }
  
          // dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, { 
          //   data: {
          //     ...responseData,
          //     rights: {
          //       ...responseData?.rights,
          //       isKundenzugang: payload.isKundenzugang,
          //       isMaklerzugang: payload.isMaklerzugang,
          //     }
          //   }, 
          //   nextUrl: payload.nextUrl, 
          //   isIntern: payload.isIntern, 
          //   tabsBroadcastId: payload.tabsBroadcastId,
          //   referrer,
          //   isOriginalUser: true,
          //   maklerzugangTabsBroadcastId: payload.maklerzugangTabsBroadcastId,
          // })

          /** TODO manage login response */


        } else {
          removeLoginDataSessionStorage();
        }
        return null;
      } catch (error) {
        removeLoginDataSessionStorage();
  
        let message = error?.response?.data?.message || ''
        let timeout = message.includes('Zugang ist gesperrt') ? 0 : 5000;
  
        if (error?.response?.status === 401) {
          dispatch(BRIDGE_TYPES.ACTIONS.DELETE_HASHED_PASSWORD, error)
          commit(BRIDGE_TYPES.MUTATIONS.RESET_LOADED_LOGIN_DATA)
          message = message || 'Zugangsnummer und/oder Passwort sind nicht korrekt.';
  
        } else {
          message = message || 'Es ist ein Fehler aufgetreten.';
        }
  
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'danger', true, timeout));
      //  commit(CORE_TYPES.MUTATIONS.LOGIN_FAIL);
        throw error;
      } 
      // finally {
      //   commit(CORE_TYPES.MUTATIONS.SET_LOGGING_IN, false);
      // }
    },
    

}