<template>
  <div class="date-picker-dropdown__container" :class="{ 'is-range-picker': isRangePicker, }">
    <!-- Dropdown for large screen -->
    <BaseDropdownMenu 
      ref="dropdownMenu"
      class="date-picker-dropdown--dropdown-menu"
      containerClass="date-picker-dropdown--dropdown-menu__container"
      :disabled="disabled"
      :closeWithInnerClick="false"
      @onShow="$emit('showedPicker', true)"
      @onHide="$emit('showedPicker', false)"
    >
      <template #hook-target><slot name="field" /></template>
      <template #default><slot name="container" /></template>
    </BaseDropdownMenu>

    <!-- Modal for small screen -->
    <div class="date-picker-dropdown--modal">
      <div @click="openModal()"><slot name="field" /></div>

      <BaseModal 
        ref="modal" 
        :modalTitle="label"
        :size="modalSize"
        :showDefaultButtons="false"
        :actions="modalActions"
        @action-ENABLE-KEYBOARD="$emit('enableKeyboard')"
      >
        <template #default><slot name="container" /></template>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue'
import BaseModal from '@/components/core/BaseModal.vue';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    isRangePicker: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalSize() {
      return this.isRangePicker ? 'full' : 'md';
    },
    modalActions() {
      return [
        BaseModalSimpleAction('ENABLE-KEYBOARD', 'Tastatur')
          .withVisibleOnSmallScreen(() => false),

        BaseModalSimpleAction('ENABLE-KEYBOARD', '')
          .withIcon('PhKeyboard', 20)
          .withVisibleOnLargeScreen(() => false),
      ];
    },
  },
  methods: {
    openModal() {
      if(this.disabled) return;
      this.$refs.modal?.open?.();
    },
    close() {
      const { dropdownMenu, modal, } = this.$refs;
      dropdownMenu?.close();
      modal?.close();
    },
  },
  mounted() {
    window.addEventListener('resize', this.close);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.close);
  },
  components: {
    BaseDropdownMenu,
    BaseModal,
  },
}
</script>

<style lang="scss" scoped>
.date-picker-dropdown__container {
  .date-picker-dropdown--modal {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .date-picker-dropdown__container {
    &.is-range-picker {
      .date-picker-dropdown--dropdown-menu {
        display: none;
      }
      .date-picker-dropdown--modal {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 500px), screen and (max-height: 575px) {
  .date-picker-dropdown__container {
    .date-picker-dropdown--dropdown-menu {
      display: none;
    }
    .date-picker-dropdown--modal {
      display: block;
    }
  }
}
</style>

<!-- GLOBAL STYLE -->
<style>
.dropdown-menu__container.date-picker-dropdown--dropdown-menu__container.active {
  max-width: 100vw;
}
</style>
