<template>
  <!-- <div class="h-full absolute" :style="{ backgroundImage: loginContentBackground }"> -->

  <div class="registration-page flex flex-row" style="margin-left: -40px; margin-right: -40px; background-color: whitesmoke;">
      <HeaderLogin @backButtonPressed="goToLogin()"/>
    <!-- <div class="flex flex-row"> -->
      <div class="box__container" style="padding: 100px; flex-basis: 40%; display: flex; flex-direction: column; align-items: center;">
        <span style="font-size:xx-large;">Aktivieren Sie ihren Zugang</span>
        <div style="display: grid; grid-template-columns: 1fr 3fr; column-gap: 30px;">
          <div style>Icon</div>
          <span>{{this.maklerNr}}Maximale Kaufkraft berechnen</span>
        </div>
        <div style="display: grid; grid-template-columns: 1fr 3fr; column-gap: 30px;">
          <div style>Icon</div>
          <span>t2</span>
        </div>
        <div style="display: grid; grid-template-columns: 1fr 3fr; column-gap: 30px;">
          <div style>Icon</div>
          <span>t3</span>
        </div>
      </div>


      <div data-app-content class="registration-page__container" style="flex-basis: 60%; height: 100vh; border-radius: 10px 0 0 10px;"  :style="{ backgroundImage: loginContentBackground }">
        <div class="box__container" style="background-color: white; width: 75vh; padding: 30px;">
          <div class="box__title" style="font-size: x-large; justify-self: center;">Zugang zu ihrem Finanzierungsportal sichern</div>
  
          <form autocomplete="off" @submit.prevent="checkDataAndProceed()" style="padding: 20px">
            <InputField
              id="email"
              label="E-Mail*"
              v-model="form.email"
              autocomplete="off"
            />
            <InputField
              id="new-password"
              label="Passwort*"
              type="password"
              allowPlainPassword
              allowPasswordRules
              v-model="form.password"
              autocomplete="new-password"
            />
            <InputField
              id="new-password-again"
              label="Passwort bestätigen*"
              type="password"
              allowPlainPassword
              allowPasswordRules
              v-model="form.passwordConfirm"
              autocomplete="new-password"
              validateUntouched
            />
            <PasswordPolicy class="change-password--policy is-collapsable" isCollapsable />
  
            <BaseButton isSecondary class="mr-16px" @click="goToLogin()">
              Zurück zur Anmeldung
            </BaseButton>
  
            <BaseButton type="submit" :disabled="isFormInvalid || loading ">
              Registrieren <AnimatedSpinner v-if="loading" />
            </BaseButton>
          </form>
        </div>
      </div>
    </div>
    <!-- </div> -->
  <!-- </div> -->
  </template>
  
  <script>
  import CORE_TYPES from '@/store/core/types';
  import CUSTOMERDATA_TYPES from '@/store/customerData/types';
  import { mapGetters } from 'vuex';
  
  import validator from '@/mixins/validator';
  import { required, email, telefon } from '@/mixins/validator/rules';
  import { getRegistrationQuery } from '@/router/features-routes/login.js';
  
  import HeaderLogin from '@/components/core/login/HeaderLogin.vue';
  import ComboBox from '@/components/core/forms/ComboBox.vue';
  import InputField from '@/components/core/forms/InputField.vue';
  import BaseButton from '@/components/core/BaseButton.vue';
  import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
  
  import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
  
  import { debounce, } from '@/helpers/commonfunctions.js';
  
  import PasswordPolicy, { NEW_PASSWORD_COMMON_RULES, deniedKeywordsRule, confirmPasswordRule } from '@/views/core/change-password/PasswordPolicy.vue';
  
  const GENDER_VALUES =  [
    { value: 'herr', label: 'Herr' },
    { value: 'frau', label: 'Frau' },
    { value: 'firma', label: 'Firma/Verein' }
  ];
  const PHONE_CHECK_TYPE = 'Mobiltelefon';
  
  
  export default {
    mixins: [validator],
    data() {
      return {
        loading: false,
        genderValues: GENDER_VALUES,
        maklernrIsValid: false,
        form: {
          gender: GENDER_VALUES[0].value,
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          password: '',
          passwordConfirm: '',
          registerCode: '',
          captchaStr: '',
        },
        datenschutzText: '',
        datenschutzVersion: '',
        datenschutzConfirmed: false,
      };
    },
    computed: {
      ...mapGetters({
        apiAddressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
        imageBackgroundLoginUrl: CORE_TYPES.GETTERS.GET_BROKER_IMAGE_BACKGROUND_LOGIN_URL,
        privacyRegistrationData: CORE_TYPES.GETTERS.PRIVACY_REGISTRATION_DATA,
        registrationData: CORE_TYPES.GETTERS.REGISTRATION_DATA,
        maklerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
        isDefaultBrokerID: CORE_TYPES.GETTERS.IS_DEFAULT_BROKER_ID,
        captchaData: CORE_TYPES.GETTERS.CAPTCHA_DATA,
      }),
      maklerNr(){
        return this.$route.params.id
      },
      maklernrFromBrokerInfo(){
        return this.maklerInfo?.maklernr;
      },
      maklernr() {
        return this.$route.query.maklernr || this.maklernrFromBrokerInfo;
      },
      registrationCode() {
        return this.maklernr || this.form?.registerCode || '';
      },
      hasValidMaklernr() {
        return !!this.maklernr && this.maklernrIsValid;
      },
      hasDefaultMaklernr() {
        return this.isDefaultBrokerID(this.maklernr)
      },
      isFormInvalid() {
        return this.validation.updated && this.validation.isInvalid('form')
      },
      loginContentBackgroundImage() {
        return this.imageBackgroundLoginUrl || `${this.apiAddressLegacy}/../images/etc/layout/msc_wolken4.png`;
      },
      loginContentBackground() {
        return `url(${this.loginContentBackgroundImage})`;
      }
    },
    watch: {
      maklernr: {
        handler(newMaklernr) {
          if (!this.isDefaultBrokerID(this.maklernr)) {
            this.form.registerCode = newMaklernr;
            this.checkMaklernrIfExists();
          }
        },
        immediate: true,
      },
      'form.password'() {
        const currentValidator = this.validation.getValidator('form.passwordConfirm');
        if (currentValidator?.watchCallback) {
          currentValidator.watchCallback(this.form.passwordConfirm, false);
        }
      },
      privacyRegistrationData: {
        handler(value) {
          this.datenschutzText = value?.config?.acceptLabelHtmlText;
          this.datenschutzVersion = value?.config?.metaDataConfig?.version;
        },
        immediate: true,
      },
    },
    methods: {
      // Check code can still send maklernr when this number comes from makernr query
      // But the customer is not allowed to type Maklernr as a valid Registrierungscode
      checkCode(code) {
        return this.$store.dispatch(CORE_TYPES.ACTIONS.CHECK_REGISTRATION_CODE, { code }).then((valid) => {
            this.maklernrIsValid = valid;
            if (valid) {
              this.getDatenschutzText(code);
            } else {
              this.$store.commit(CORE_TYPES.MUTATIONS.RESET_PRIVACY_REGISTRATION_DATA);
            }
          })
          .catch(() => this.maklernrIsValid = false);
      },
      checkCodeDebounce: debounce(async function checkCodeDebounce(code) {
        if (code?.length === 10) { // Only proper Registrierungscode are allowed to be typed
          this.checkCode(code);
        }
      }),
      checkMaklernrIfExists() {
        if(!this.maklernr) {
          return ;
        }
        this.checkCode(this.maklernr)
      },
      async checkCaptchaAndCode() {
        const response = await this.$store.dispatch(CORE_TYPES.ACTIONS.CHECK_CAPTCHA_AND_REGISTRATION_CODE, {
          code: this.registrationCode,
          captcha: this.form.captchaStr,
        });
        return response;
      },
      checkPhoneFormat(value) {
        if(!value) {
          return ;
        }
  
        const payload = {
          type: PHONE_CHECK_TYPE,
          value: value,
        };
  
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.CHECK_FORMAT_COMMUNICATION, payload)
          .catch((error) => {
            if(error?.text) {
              this.$pushErrors('form.phone', error?.text)
            }
          });
      },
      // checkData() {
      //   return new Promise((resolve, reject) => {
      //     this.checkCaptchaAndCode()
      //       .then((response) => {
      //         if (!response) {
      //           reject();
      //         } else if (!response?.regCode || !response?.captcha) {
      //           if(!response?.regCode) {
      //             this.$pushErrors('form.registerCode', 'Fehler: Registrierungscode ist ungültig');
      //           }
      //           if (!response?.captcha) {
      //             this.$pushErrors('form.captchaStr', 'Fehler: Captcha ist ungültig');
      //           }
      //           reject();
      //         } else if (response?.regCode && response?.captcha) {
      //           resolve();
      //         }
      //       });
      //   });
      // },
      goToPrivacyPage() {
        this.$router.push({
          path: '/login/register/privacy',
          query: getRegistrationQuery(this.$route),
        });
      },
      checkDataAndGoToPrivacyPage() {
        this.goToPrivacyPage();
        // this.loading = true;
        // this.checkData()
        //   .then(() => {
        //     const payload = { 
        //       ...this.form,
        //       captchaToken: this.captchaData?.captchaToken,
        //     };
        //     //this.$store.commit(CORE_TYPES.MUTATIONS.SET_REGISTRATION_DATA, payload);
        //     this.goToPrivacyPage();
        //   })
        //   .finally(() => this.loading = false);
      },
      goToLogin() {
        this.$store.commit(CORE_TYPES.MUTATIONS.GET_PRIVACY_REGISTRATION_DATA_SUCCESS, null);
        this.$router.push({
          path: '/affiliate/login',
          query: getRegistrationQuery(this.$route),
        });
      },
      getDatenschutzText(code) {
        if (code?.length >= 5) {
          this.$store.dispatch(CORE_TYPES.ACTIONS.GET_PRIVACY_REGISTRATION_DATA, {code: code});
        }
      },
      openLink(event) {
        if (event?.target?.id) {
          this.$store.commit(CORE_TYPES.MUTATIONS.SET_REGISTRATION_DATA, this.form);
          
          const type = event.target.id.toLowerCase()?.includes('agb') ? 'AGB_HTML' : 'DATENSCHUTZERKLAERUNG_HTML';
          this.$addBreadcrumb({
            fullPath: this.$route.fullPath,
          });
          this.$router.push(`/login/register/privacy/terms-content?version=${this.datenschutzVersion}&type=${type}`);
        }
      },
      checkDataAndProceed(){
        const payload = { 
              ...this.form,
              maklernr: this.maklerNr
            };


          
      }
    },
    mounted() {
      if (this.$route.query?.backAction && this.registrationData) {
        this.form = this.registrationData;
      }
  
      // this.$store.dispatch(CORE_TYPES.ACTIONS.GET_CAPTCHA);
    },
    validators: {
      form: {
        // gender: [required('Anrede ist erforderlich!')],
        // firstName: [required('Vorname ist erforderlich!')],
        // lastName: [required('Nachname ist erforderlich!')],
        email: [required('E-Mail ist erforderlich!'), email()],
        // phone: [required('Mobilnummer ist erforderlich!'), telefon()],
        password: [ 
          ...NEW_PASSWORD_COMMON_RULES, 
          deniedKeywordsRule((self) => [self.form.firstName, self.form.lastName]),
        ],
        passwordConfirm: [
          confirmPasswordRule((self) => self.form.password),
        ],
        // registerCode: [required('Registrierungscode ist erforderlich!')],
        // captchaStr: [required('Captcha ist erforderlich!')],
      },
    },
    components: {
      HeaderLogin,
      ComboBox,
      InputField,
      BaseButton,
      AnimatedSpinner,
      InputCheckBoxItem,
      PasswordPolicy,
    },
  }
  </script>
  
  <style scoped>
  .registration-page {
    padding-bottom: 1px;
  }
  .registration-page__container {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  
  </style>
  