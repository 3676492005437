var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.actions
        },
        on: { "action-NEW": _vm.openInitModal }
      }),
      _c(
        "div",
        [
          !_vm.scenariosList
            ? _c("div", [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col col-md-6" },
                    [
                      _c("GhostLoading", {
                        attrs: {
                          type: "block",
                          useBoxContainer: "",
                          config: { block: { height: "250px" } }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col col-md-6" },
                    [
                      _c("GhostLoading", {
                        attrs: {
                          type: "block",
                          useBoxContainer: "",
                          config: { block: { height: "250px" } }
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm.scenariosList.length
            ? _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.scenariosList, function(scenario, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "col col-md-6" },
                    [
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("PageHeaderTitleNavigation", {
                            attrs: {
                              id: scenario.id,
                              actions: _vm.scenarioHeaderActions,
                              noPrimaryAction: "",
                              showBackButton: false
                            },
                            on: {
                              "action-REMOVE": function($event) {
                                return _vm.deleteScenario(scenario)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function() {
                                    return [
                                      _vm._t("title", function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "cards__item--header "
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.goToScenario(
                                                        scenario.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(scenario.title) +
                                                      " | " +
                                                      _vm._s(
                                                        scenario.updateDate ||
                                                          scenario.startDate
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c(
                            "div",
                            [
                              _c("RetirementChart", {
                                attrs: {
                                  data: {
                                    data: _vm.apexSeries(scenario),
                                    series: _vm.apexSeries(scenario),
                                    xaxis: _vm.apexXaxis(scenario),
                                    annotations: _vm.apexAnnotations(scenario),
                                    markers: _vm.apexMarkers,
                                    dataLabels: { enabled: false },
                                    stroke: { curve: "smooth" },
                                    autoUpdateSeries: true
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            : _c("NoData", { staticClass: "box__container" })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "initModal",
          attrs: {
            modalTitle: "Financial planning erstellen",
            labelButtonConfirm: "Erstellen",
            confirmDisabled: !_vm.canSaveScenario
          },
          on: { onConfirmButton: _vm.save }
        },
        [
          _c("InputField", {
            attrs: {
              id: "scenario.title",
              label: "Name des Scenarios",
              value: _vm.scenario.title,
              validateUntouched: ""
            },
            on: {
              input: function($event) {
                return _vm.doChanges("title", $event, "new")
              }
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Notizen", value: _vm.scenario.bemerkung, rows: 5 },
            on: {
              input: function($event) {
                return _vm.doChanges("bemerkung", $event, "new")
              }
            }
          }),
          !_vm.scenario.geburtsdatum
            ? _c("DatePickerField", {
                attrs: {
                  label: "Geburtstag",
                  placeholder: "DD.MM.JJJJ",
                  isValueAsString: "",
                  value: _vm.scenario.geburtsdatum
                },
                on: {
                  change: function($event) {
                    return _vm.doChanges("geburtsdatum", $event, "new")
                  }
                }
              })
            : _vm._e(),
          _c("InputField", {
            attrs: {
              label: "Lebenserwartung",
              value: _vm.scenario.lebenserwartung
            },
            on: {
              input: function($event) {
                return _vm.doChanges("lebenserwartung", $event, "new")
              }
            }
          }),
          _c("InputField", {
            attrs: {
              label:
                "Monatlicher Bedarf in Euro in Rente (nach heutigem Geldwert)",
              value: _vm.scenario.bedarfMonatlich,
              type: "currency",
              disabled: _vm.scenario.ohneKapitalverzehr
            },
            on: {
              change: function($event) {
                return _vm.doChanges("bedarfMonatlich", $event, "new")
              }
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Geschätzte Renteneinnahmen pro Monat",
              value: _vm.scenario.renteMonatlichErwartet,
              type: "currency"
            },
            on: {
              change: function($event) {
                return _vm.doChanges("renteMonatlichErwartet", $event, "new")
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }