<template>
  <div class="base-buttons__container">
    <div class="base-buttons--content">
      <div class="base-buttons--main">
        <slot></slot>
      </div>

      <BaseDropdownMenu class="base-buttons--dropdown" placement="bottom-right">
        <template v-slot:hook-target>
          <BaseButton :isSecondary="!btnAsLink" :isLink="btnAsLink" :tabindex="btnTabindex">
            <span>Weitere</span>
            <PhCaretDown />
          </BaseButton>
        </template>
        <template #default>
          <div ref="dropdownContainerEl" class="base-buttons--dropdown-container"></div>
        </template>
      </BaseDropdownMenu>
    </div>
  </div>
</template>

<script>
import { PhCaretDown } from 'phosphor-vue';

import BaseButton from '@/components/core/BaseButton.vue';
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';

const CONTENT_SELECTOR = '.base-buttons--content';
const MAIN_SELECTOR = '.base-buttons--main';
const DROPDOWN_SELECTOR = '.base-buttons--dropdown';
const BUTTON_PRIMARY_SELECTOR = '.button--primary';
const MAIN_WRAP_CLASS = 'main-wrap';
const HAS_DROPDOWN_CLASS = 'has-dropdown';
const MARGIN_HORIZONZAL = 16;
const MIN_ITEMS_REQUIRED = 2;

function margimHorizontal(el) {
  const style = window.getComputedStyle(el);
  const marginLeft = parseFloat(style?.['marginLeft'] || 0);
  const marginRight = parseFloat(style?.['marginRight'] || 0);
  return marginLeft + marginRight;
}

export default {
  props: {
    primaryClass: {
      type: String,
      default: BUTTON_PRIMARY_SELECTOR,
    },
    btnAsLink: {
      type: Boolean,
      default: false,
    },
    minItemsRequired: {
      type: Number,
      default: MIN_ITEMS_REQUIRED,
    },
  },
  components: {
    PhCaretDown,
    BaseButton,
    BaseDropdownMenu,
  },
  data() {
    return {
      btnTabindex: -1,
    };
  },
  computed: {
    totalFixedItems() {
      const { minItemsRequired, } = this;
      return minItemsRequired <= 0 ? 0 : (minItemsRequired - 1);
    },
  },
  methods: {
    resetModifications() {
      const containerEl = this.$el;
      const mainEl = containerEl.querySelector(MAIN_SELECTOR);
      const { dropdownContainerEl } = this.$refs;

      // add all back to main container
      if(dropdownContainerEl?.children?.length) {
        [...dropdownContainerEl.children || []]
          .sort((child1, child2) =>  parseInt(child1.dataset.baseButtonIndex) > parseInt(child2.dataset.baseButtonIndex) ? 1 : 0)
          .forEach(childEl => {
            const nextSiblingEl = [...mainEl.children || []].find(el => parseInt(el.dataset.baseButtonIndex) > parseInt(childEl.dataset.baseButtonIndex));
            if(nextSiblingEl) {
              mainEl.insertBefore(childEl, nextSiblingEl);
            } else {
              mainEl.appendChild(childEl);
            }
          });
      }

      // set index
      if (!mainEl?.children?.forEach) {
        Object.values(mainEl?.children).forEach((childEl, i) => childEl.dataset.baseButtonIndex = i);
      } else {
        mainEl?.children?.forEach((childEl, i) => childEl.dataset.baseButtonIndex = i);
      }
    },
    prepareContent() {
      const containerEl = this.$el;
      const contentEl = containerEl.querySelector(CONTENT_SELECTOR);
      const mainEl = containerEl.querySelector(MAIN_SELECTOR);
      const dropdownEl = containerEl.querySelector(DROPDOWN_SELECTOR);
      const { dropdownContainerEl } = this.$refs;
      const { primaryClass, minItemsRequired, totalFixedItems, } = this;

      this.resetModifications();

      const totalChildren = (mainEl.children?.length || 0) + (dropdownContainerEl?.children?.length || 0);
      if(totalChildren > minItemsRequired) {
        // remove main-wrap class
        mainEl.classList.remove(MAIN_WRAP_CLASS);

        // check content
        const allItemsWidth = [...mainEl?.children || []].reduce((acc, childEl) => acc + childEl.offsetWidth + margimHorizontal(childEl), 0);
        const containerElWidth = containerEl.clientWidth + MARGIN_HORIZONZAL;
        const needDropdown = allItemsWidth > containerElWidth;
        const availableContainerWidth = needDropdown ? containerElWidth - dropdownEl.offsetWidth - MARGIN_HORIZONZAL : containerElWidth;

        // check if need to add to dropdown
        const mainItemsWidth = [...mainEl?.children || []].reduce((acc, childEl) => acc + childEl.offsetWidth + margimHorizontal(childEl), 0);
        if(mainItemsWidth >= availableContainerWidth) { // add to dropdown
          const primaryChildren = [...contentEl.querySelectorAll(`${MAIN_SELECTOR} > ${primaryClass}`) || []].reverse();
          const secondaryChildren = [...contentEl.querySelectorAll(`${MAIN_SELECTOR} > :not(${primaryClass})`) || []].reverse();
          const children = [...secondaryChildren, ...primaryChildren];

          let acc = 0;
          children.slice(0, children.length - totalFixedItems).forEach(childEl => {
            if(mainItemsWidth - acc <= availableContainerWidth) return;

            acc += childEl.offsetWidth + margimHorizontal(childEl);
            if(dropdownContainerEl?.children?.length) {
              dropdownContainerEl.insertBefore(childEl, dropdownContainerEl.children[0]);
            } else {
              dropdownContainerEl.appendChild(childEl);
            }
          });
        }
      } else {// add main-wrap class
        mainEl.classList.add(MAIN_WRAP_CLASS);
      }

      // add/remove has-dropdown class from content
      if(dropdownContainerEl?.children?.length > 0) {
        contentEl.classList.add(HAS_DROPDOWN_CLASS);
        this.btnTabindex = 0;
      } else {
        contentEl.classList.remove(HAS_DROPDOWN_CLASS);
        this.btnTabindex = -1;
      }
    },
    onResize() {
      this.$nextTick(() => this.prepareContent());
    },
  },
  beforeUpdate() {
    this.resetModifications();
  },
  updated() {
    this.$nextTick(() => this.prepareContent());
  },
  mounted() {
    this.prepareContent();
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>

<style lang="scss">
.base-buttons__container,
.base-buttons__container * {
  box-sizing: border-box;
}

.base-buttons__container {
  max-width: 100%;

  button {
    margin: 0;
  }

  .base-buttons--content {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;

    .base-buttons--main {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin: 0;

      &.main-wrap {
        flex-wrap: wrap;
      }

      > * {
        flex: 0 0 auto;
        margin: 8px;
      }
    }

    .base-buttons--dropdown {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      margin: 8px 0 8px 8px;

      pointer-events: none;
      position: fixed;
      top: -9999%;
      left: -9999%;

      button {
        margin: 0;
        outline-offset: -1px;
      }
    }

    &.has-dropdown {
      .base-buttons--dropdown {
        pointer-events: all;
        position: initial;
        top: 0;
        left: 0;
      }
    }
  }
}

.base-buttons--dropdown-container {
  display: flex;
  flex-flow: column;
  min-width: 220px;

  > * {
    flex: 0 0 auto;
    margin: 8px 0 !important;

    button {
      margin: 0;
      width: 100%;
    }

    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
