import { ROLES, VIEW_ROLES, BROKER_STRUCTURE_ROLES, } from '@/router/roles'
import limitRoutes from './limit'
import { generateGesellschaftRoute, } from './gesellschaften/gesellschaften'

export default [
  {
    path: '',
    redirect: 'overview'
  },
  {
    path: 'overview',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
        ]
      },
    },
    component: () => import(/* webpackChunkName: "service-overview" */ '@/views/service/Overview.vue'),
  },
  {
    path: 'externe-zugaenge',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
        ],
      }
    },
    component: () => import(/* webpackChunkName: "customer-externe-zugang-bank" */ '@/components/externeZugangBank/Customer.vue'),
  },
  {
    path: 'fondsvergleich',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]]
      }
    },
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "FondsVergleichList" */ '@/views/beratung/FondsVergleichList.vue'),
      },
      {
        path: 'fondsvergleicheinstellen',
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER]]
          }
        },
        component: () => import(/* webpackChunkName: "FondsVergleichEinstell" */ '@/components/beratung/FondsVergleichEinstell.vue'),
      },
      {
        path: ':fvid',
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER]]
          }
        },
        component: () => import(/* webpackChunkName: "FondsVergleichEdit" */ '@/views/beratung/FondsVergleich.vue'),
      },
    ]
  },
  
  {
    path: 'wertpapierinfo',
    component: () => import(/* webpackChunkName: "Wertpapierinfo" */ '@/views/beratung/Wertpapierinfo.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
        ]
      }
    }  
  },
  {
    path: 'meineziele',
    component: () => import(/* webpackChunkName: "MeineZiele" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, ROLES.IS_MAKLER_EARLY_ACCESS], [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN, ROLES.IS_MAKLER_EARLY_ACCESS]]
      }
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "meineziele-list" */ '@/components/myGoals/MyGoalsOverview.vue'),
      },
      {
        path: 'select',
        component: () => import(/* webpackChunkName: "meineziele-select" */ '@/components/myGoals/MyGoalSelectType.vue'),
      },
      {
        path: ':id',
        component: () => import(/* webpackChunkName: "meineziele-steps" */ '@/components/myGoals/MyGoalsSteps.vue'),
        meta: {
          step: 'preview',
        },
        children: [
          {
            path: '',
            redirect: 'preview'
          },
          {
            path: 'preview',
            component: () => import(/* webpackChunkName: "meineziele-ziel" */ '@/components/myGoals/MyGoalsView.vue'),
          },
          {
            path: 'form',
            component: () => import(/* webpackChunkName: "meineziele-form" */ '@/components/myGoals/MyGoalsForm.vue'),
          },
        ]
      },
    ]
  },
  {
    path: 'ruhestandsplanung-old',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, ROLES.IS_MAKLER_EARLY_ACCESS], [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN, ROLES.IS_MAKLER_EARLY_ACCESS]]
      }
    },
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "RetirementScenarioList" */ '@/components/retirementScenario/RetirementScenarioList.vue')
      },
      {
        path: 'view/:id',
        component: () => import(/* webpackChunkName: "RetirementScenario" */ '@/components/retirementScenario/RetirementScenario.vue'),
      },
      {
        path: 'new',
        component: () => import(/* webpackChunkName: "RetirementScenario" */ '@/components/retirementScenario/RetirementScenario.vue'),
      }
    ]
  },
  {
    path: 'ruhestandsplanung-alt',
    component: () => import(/* webpackChunkName: "Ruhestandsplanung" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, ROLES.IS_MAKLER_EARLY_ACCESS], [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN, ROLES.IS_MAKLER_EARLY_ACCESS]]
      }
    },
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "ruhestandsplanungList" */ '@/views/ruhestandsplanung/RuhestandsplanungList_old.vue')
      },
      {
        path: ':id/:stepKey',
        component: () => import(/* webpackChunkName: "ruhestandsplanung" */ '@/views/ruhestandsplanung/Ruhestandsplanung.vue'),
      },
    ]
  },
  {
    path: 'financial-planing',
    component: () => import(/* webpackChunkName: "Ruhestandsplanung" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, ROLES.IS_MAKLER_EARLY_ACCESS], [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN, ROLES.IS_MAKLER_EARLY_ACCESS]]
      }
    },
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "ruhestandsplanungList" */ '@/views/ruhestandsplanung/RuhestandsplanungList.vue')
      },
      {
        path: ':id',
        component: () => import(/* webpackChunkName: "ruhestandsplanung" */ '@/views/ruhestandsplanung/FinancialPlaning.vue'),
      },
    ]
  },

  generateGesellschaftRoute({
    allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN]
  }),

  {
    path: 'limit',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]
        ],
        denied: [
          [ROLES.SUPER_CUSTOMER]
        ]
      },
    },
    component: () => import(/* webpackChunkName: "LimitOverview" */ '@/views/BaseRouterView.vue'),
    children: [...limitRoutes]
  },
  {
    path: 'berichte',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER]
        ],
        denied: [
          [ROLES.SUPER_CUSTOMER]
        ],
      },
    },
    component: () => import(/* webpackChunkName: "service-berichte" */ '@/views/berichte/BerichteOverview.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "service-berichte" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            redirect: 'steuer-daten',
          },
          {
            path: 'steuer-daten',
            component: () => import(/* webpackChunkName: "service-berichte" */ '@/views/berichte/SteuerlicheDaten.vue'),
            meta: {
              roles: {
                allowed: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
              },
            },
          },
          {
            path: 'steuer-verlust',
            component: () => import(/* webpackChunkName: "service-berichte" */ '@/views/berichte/SteuerVerlustVerrechnung.vue'),
          },
        ]
      },
    ]
  },
  {
    path: 'vertragsspiegel',
    component: () => import(/* webpackChunkName: "Vertragsspiegel" */'@/views/berichte/Vertragsspiegel.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]],
      },
    },
  },
  {
    path: 'datenschutzkundenaenderungen',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN],
      },
    },
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "datenschutzkundenaenderungen" */ '@/views/intern/DatenschutzKundenAenderungen.vue')
      },      
      {
        path: 'details/:id',
        component: () => import(/* webpackChunkName: "datenschutzkundenaenderungendetails" */ '@/views/intern/DatenschutzKundenAenderungenDetails.vue'),
      },
      {
        path: 'externeErklaerungEintragen/:textModus',
        component: () => import(/* webpackChunkName: "externeErklaerungEintragen" */ '@/views/intern/ExterneErklaerungEintragen.vue'),
      },

      
    ]
  },  
]
