<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" :title="title" :subtitle="depotTitle" :actions="headerActions" />
    
    <div v-if="getCards.length">
      <div v-if="!$isSmallScreen" class="depotpositions-categories__container">
        <div v-for="(item, index) of getCards" :key="'sum'+index">
          <div class="box__container"  :tid="_generateTidFromString('box__container'+'sum'+index)">
            <div class="text-small text-center mp-0">{{item.label}}</div>
            <div class="text-center mp-0">
              <CurrencyLabel :value="item.value" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="box__container">
      <template v-for="(item, index) of getCards">
        <MobileInfoRow
          :key="'sum'+index"
          :label="item.label"
        > <template #value >
            <CurrencyLabel :value="item.value"/>
          </template>
        </MobileInfoRow>
      </template>
      </div>
    </div>



    <div class="box__container" tid="93ed63c9-5c64-4ae0-be49-a1a5e1008f3b">

      <div class="table-wrap" v-if="!refresh">
        <Table v-if="sparplaeneRows.length"
            title="Sparpläne"
            tableId="e4638090-f381-4da4-b8e0-7d3c7de233c7"
            :headers="headers"
            :rows="sparplaeneRows"
            :exportConfig="exportConfigSpar"
            rowId="index"
            hidePagination
            :mobileConfig="{}"
            @click-FONDSNAME="navigateToFondsinfo"
            @action-COURTAGE_BGS="openCourtagehistorie"
            @action-LIMITS="openLimits"
            @action-TRANSAKTIONEN="openTransactions"
            @action-NEW_APPOIMENT="openAddNewActivityModal"
            @action-FACTSHEET="openFactsheetFile($event, 'FACTSHEET')"
            @action-KAG="openFactsheetFile($event, 'MR')"
            @action-PRIIP-BIB="openFactsheetFile($event, 'KIID')"
        />
        <NoData title="Sparpläne" v-else />
      </div>
      <GhostLoading v-else type="table" />
    </div>

    <div class="box__container" tid="92363e45-aee1-49be-8322-99525e8c8f9e">

      <div class="table-wrap" v-if="!refresh">
        <Table v-if="entnahmeplaeneRows.length"
            title="Entnahmepläne"
            tableId="e4638090-f381-4da4-b8e0-7d3c7de233c7"
            :headers="headers"
            :rows="entnahmeplaeneRows"
            :exportConfig="exportConfigEntnahme"
            rowId="BGS_NR"
            hidePagination
            :mobileConfig="{}"
            @click-FONDSNAME="navigateToFondsinfo"
            @action-COURTAGE_BGS="openCourtagehistorie"
            @action-LIMITS="openLimits"
            @action-TRANSAKTIONEN="openTransactions"
            @action-NEW_APPOIMENT="openAddNewActivityModal"
            @action-FACTSHEET="openFactsheetFile($event, 'FACTSHEET')"
            @action-KAG="openFactsheetFile($event, 'MR')"
            @action-PRIIP-BIB="openFactsheetFile($event, 'KIID')"
        />
        <NoData title="Entnahmepläne" v-else />
      </div>
      <GhostLoading v-else type="table" />
    </div>
  </div>
</template>

<script>
import BALANCE_SHEET_TYPES from "@/store/balance-sheet/types";
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";
import CORE_TYPES from "@/store/core/types";
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import CALENDAR_TYPES from '@/store/calendar/types';
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import {TextColumn, CurrencyColumn, DateColumn, PercentageColumn, ActionColumn, SimpleAction, } from "@/components/table2/table_util.js";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderPortfolioSelectorAction, } from '@/components/core/header-title-navigation/page-header-utils';
import BaseModal from "@/components/core/BaseModal.vue";
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';

import { getRedirectionWindow, } from '@/components/table/export/utils';
import { viewDocument, } from '@/components/core/download/DownloadLink.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import MobileInfoRow from "@/components/core/MobileInfoRow.vue";

import { openLink } from '@/link-resolvers';
import { ResolverId } from '@/link-resolvers/types';

const SPARPLAN_TYP = 'Sparplan';
const ENTNAHMEPLAN_TYP = 'Entnahmeplan';

export default {
  mixins: [InteractiveHelpCommonsMixin],
	props: {},
	data() {
		return {
            title: 'Spar-/Entnahmepläne',
            selectedFund: null,
            refresh: false,
            headers: {
                lockedLeft: [
                    TextColumn("FONDSNAME", "Fondsname").makeLink(),
                ],
                center: [
                    TextColumn("ISIN", "ISIN"),
                    DateColumn("EINZAHLUNGSBEGINN", "Erste Rate"),
                    DateColumn("EINZAHLUNGSENDE", "Planende (Letzte Rate)"),
                    PercentageColumn("DYNAMIK", "Dynamisierungs-Prozentsatz"),
                    TextColumn("LAGERSTELLE", "Lagerstelle"),
                    TextColumn("GESELLSCHAFT", "Gesellschaft"),
                    TextColumn("KONTONR", "Depotnummer"),
                    TextColumn("IBAN", "IBAN"),
                    TextColumn("BIC", "BIC"),
                ],
                lockedRight: [
                    TextColumn("TURNUS", "Turnus"),
                    CurrencyColumn("BETRAG", "Betrag"),
                    ActionColumn('actions'),
                ],
            },
            noInitAppoiment: false,
        }
  },
  computed: {
    ...mapGetters({
      spar_entnahmeplane: BALANCE_SHEET_TYPES.GETTERS.SPAR_ENTNAHMEPLANE,
      depotId: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      depotTitle: DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_DEPOT_TITLE,
      isOriginalUserBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
    }),
    rows() {
      return ([ ...this.spar_entnahmeplane?.rows || [], ])
        .map((row, index) => ({
          ...row,
          index: index,
          actions: this.rowActions(row),
        }));
    },
    sparplaeneRows() {
        return this.rows.filter(row => row.TYP === SPARPLAN_TYP);
    },
    entnahmeplaeneRows() {
        return this.rows.filter(row => row.TYP === ENTNAHMEPLAN_TYP);
    },
    headerActions() {
      return [
        PageHeaderPortfolioSelectorAction('SELECT-DEPOT'),
      ];
    },
    getCards() {
      const result = [];
      let value = this.sparplaeneRows.reduce((sum, row) => sum + this.getJahrBetrag(row.TURNUS, row.BETRAG), 0)
      if (value) {
        result.push(
          {label: 'Sparpläne - ø Beiträge / Monat', value: value/12},
          {label: 'Sparpläne - ø Beiträge / Jahr', value: value},
        );
      }
      value = this.entnahmeplaeneRows.reduce((sum, row) => sum + this.getJahrBetrag(row.TURNUS, row.BETRAG), 0)
      if (value) {
        result.push(
          {label: 'Entnahmepläne - ø Beiträge / Monat', value: value/12},
          {label: 'Entnahmepläne - ø Beiträge / Jahr', value: value},
        );
      }
      return result
    },
    exportConfigSpar() {
      let title = 'Sparpläne - FULLNAME';
      if (this.getCards.length) {
        this.getCards.filter(c => c.label.indexOf('Spar') == 0)
        .forEach(c => {
          title += '\n' + c.label.slice('Sparpläne - '.length) + '\t' + c.value.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €'
        });
      }
      return {
        roottext: 'Sparpläne',
        title
      }
    },
    exportConfigEntnahme() {
      let title = 'Entnahmepläne - FULLNAME';
      if (this.getCards.length) {
        this.getCards.filter(c => c.label.indexOf('Entnahme') == 0)
        .forEach(c => {
          title += '\n' + c.label.slice('Entnahmepläne - '.length) + '\t' + c.value.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' €'
        });
      }
      return {
        roottext: 'Entnahmepläne',
        title
      }
    },
  },
  methods: {
    getJahrBetrag(turnus, betrag) {
      if (typeof betrag == 'string') {
        betrag = parseFloat(betrag);
      }
      return betrag * ({
        monatlich: 12,
        zweimonatlich: 6,
        'vierteljährlich': 4,
        'jährlich': 1,
        'halbjährlich': 2,
        viermonatlich: 3
      }[turnus] || 0)
    },
    rowActions(row) {
      const actions = [];
      if(!row) return actions;

      if (this.isOriginalUserBroker || this.isOriginalUserIntern) {
        actions.push(SimpleAction('COURTAGE_BGS', '', 'Courtagehistorie (PDF)'));
      }

      actions.push(SimpleAction('LIMITS', '', 'Limits'));
      actions.push(SimpleAction('TRANSAKTIONEN', '', 'Transaktionen'));

      if (this.isBrokerOrBypass) {
        actions.push(SimpleAction('NEW_APPOIMENT', '', 'Neue Aufgabe'));
      }

      actions.push(SimpleAction('FACTSHEET', '', 'Factsheet'));

      actions.push(SimpleAction('KAG', '', 'KAG'));

      actions.push(SimpleAction('PRIIP-BIB', '', 'PRIIP-BIB'));

      return actions;
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    navigateToFondsinfo(row) {
      if(row) {
        this.selectedFund = row.FONDSNAME;
        this.navigateTo(`/shared/vermogensubersicht/fondsinfo/${row.ISIN}`);
      }
    },
    async findSparEntnahmeplane() {
      this.refresh = true;
      await this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_SPAR_ENTNAHMEPLANE, {depotid: this.depotId});
      this.refresh = false;
    },
    openCourtagehistorie(row) {
      const windowRef = getRedirectionWindow(true);
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.COURTAGE_BGS, { bgsnr: `${row.BGS_NR}`, })
        .then(data => {
          if (data?.pdf) {
            viewDocument({
              windowRef,
              data: data.pdf,
              filename: 'Depotposition.pdf',
              contentType: 'application/pdf',
            }, false);
          }
        }).catch(() => windowRef?.close());
    },
    openLimits(row) {
      this.navigateTo(`/home/vermogensubersicht/wertpapiere/limits/${row.ISIN}/${row.BGS_NR}`);
    },
    openTransactions(row) {
      let paramsObj = {};
      if (row.ISIN) {
        paramsObj.isin = row.ISIN;
      }
      if(row.BGS_NR) {
        paramsObj.bgsNr = row.BGS_NR;
      }     
      const bodyParams = JSON.stringify(paramsObj);
      const params = encodeURIComponent(bodyParams);
      this.navigateTo(`/home/berichte/transaktionen?params=${params}&title=${encodeURIComponent(row.FONDSNAME)}&depoid=${this.depotId}`);
    },
    openAddNewActivityModal(row) {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_ACTIVITY);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE, []);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA, {
        appointment: {
          appointment: {
            label: row ? row.FONDSNAME : '',
            activity: true,
            bereich: 'Kunde',
            bereichDisplay: 'Kunde ' + this.loginData.fullname + ' (' + this.loginData.userid + ')',
            bereichId: this.loginData.userid,
          }
        }
      });
      this.noInitAppoiment = true;
      this.openAppointment();
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aufgabe bearbeiten',
        noInitialise: this.noInitAppoiment,
        attachCustomer: false,
        discardChanges: this.resetAppointment,
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    resetAppointment() {
      this.noInitAppoiment = false;
    },
    openFactsheetFile(row, type) {
      openLink(ResolverId.Factsheet, {
        type,
        isin: row.ISIN,
      });
    },
  },
  watch: {
    'depotId': function () {
      this.findSparEntnahmeplane()
    },
  },
  mounted() {
    this.findSparEntnahmeplane();
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      to,
      from,
      breadcrumb: this.selectedFund || '',
    });

    next();
  },
	components: {
    Table,
    OptionMenu,
    GhostLoading,
    NoData,
    PageHeaderTitleNavigation,
    BaseModal,
    AppointmentEditCreate,
    CurrencyLabel,
    MobileInfoRow,
	}
}
</script>

<style scoped>
.table-wrap {
  width: 100%;
}
.depotpositions-categories__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px 16px;
}
.depotpositions-categories__container div {
  margin: 0;
}
.depotpositions-categories__container > div {
  flex-grow: 1;
  padding: 0 12px;
  margin: 0 0 8px;
}
.text-center {
  text-align: center;
}
</style>
