<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
        <PageHeaderTitleNavigation title="Status der VideoIdents zu FFB Depoteröffnungen / Portfolioanträge" />

        <GenericPersonFilter  ref="filter"
            :saveKey="saveKey" 
            :metadata="searchMenu" 
            :configFilter="configFilter"
            :predefinedFilter="null"
            :defaultOptions="defaultOptions"
            immidiateSearch
            :extraButton="{buttonText: 'Markierte Positionen bestätigen', isExtraButtonPrimary: selection && selection.length > 0}"
            @search="handleSearch($event)"
            @extraButtonClick="confirmSelection()"
        >
        </GenericPersonFilter>

        <div v-if="!loading && rows && rows.length" class="box__container">
            <BaseCollapsable showSection>
                <template #title>
                    <div class="box__title m-0">Ausprägungen des Status Depoteröffnungsanträge</div>
                </template>

                <template #content>
                    <div class="row">
                        <div class="status-ffb--hinweise-items__container col-12 col-md-6">
                            <FormLabel :config="LABEL_CONFIG" class="color-warning" label="Angefordert teilweise -> Nur teilweise hinterlegt"/>

                            <FormLabel :config="LABEL_CONFIG" class="color-info" label="Bestätigt -> Vom Antragssteller bestätigt"/>

                            <FormLabel :config="LABEL_CONFIG" class="color-info" label="Legitimation angefordert -> Vorgang bei der Identifizierungsstelle angelegt"/>

                            <FormLabel :config="LABEL_CONFIG" class="color-success" label="Legitimation erfolgreich -> Legitimierung aller Antragbeteiligten war erfolgreich"/>

                            <FormLabel :config="LABEL_CONFIG" class="color-danger" label="FFB Sichtkontrolle negativ -> DEA wurde von der Sichtkontrolle abgelehnt"/>

                            <FormLabel :config="LABEL_CONFIG" class="color-danger" label=" DEA abgelaufen und anonymisiert -> Frist Bestätigung / Legitimierung abgel."/>

                            <FormLabel :config="LABEL_CONFIG" class="color-success" label="DEA erfolgreich -> DEA wurde erfolgreich durchgeführ"/>
                        </div>

                        <div class="status-ffb--hinweise-items__container col-12 col-md-6">
                            <FormLabel :config="LABEL_CONFIG" class="color-info" label="Angefordert -> Angelegt und wartet auf die Bestätigung"/>

                            <FormLabel :config="LABEL_CONFIG" class="color-info" label="Bestätigt ->Bestätigt durch Unterlagen -> Vom Antragssteller bestätigt mit Unterlagen"/>

                            <FormLabel :config="LABEL_CONFIG" class="color-warnung" label="Legitimation teilweise -> Legitimierung nur teilweise erfolgreich"/>

                            <FormLabel :config="LABEL_CONFIG" class="color-danger" label="Legitimation abgelehnt -> Legitimierung wurde abgelehnt"/>

                            <FormLabel :config="LABEL_CONFIG" class="color-success" label="Depoteröffnung initiiert -> DEA bestätigt und legitimiert, nun initiiert"/>

                            <FormLabel :config="LABEL_CONFIG" class="color-danger" label=" Ungültig -> DEA befindet sich in einem invaliden Zustand"/>

                            <FormLabel :config="LABEL_CONFIG"  label="DEA bestätigt -> Die DEA ist im MSC bestätigt"/>
                        </div>
                    </div>
                </template>
            </BaseCollapsable>
        </div>

        <div class="box__container">
            <div v-if="!loading && rows && rows.length">
                <Table
                    :title="TABLE_TITLE"
                    :headers="headers"
                    :rows="rows"
                    :rowsPerPage="20"
                    rowId="statusFFBDepots"
                    :selected="selection"
                    @selected="onSelect"
                    @click-kundenName="openCustomerNewTab"
                    @click-identUrl1="openIdentLink($event && $event.identUrl1)"
                    @click-identUrl2="openIdentLink($event && $event.identUrl2)"
                >
                </Table>
            </div>
            <GhostLoading v-else-if="loading" :title="TABLE_TITLE" type="table" />
            <NoData v-else :title="TABLE_TITLE" content="Keine Informationen" />
        </div>
    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, ActionColumn, IconColumn, DownloadLinkHrefAction, Icon } from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import BERICHTE_TYPES from '@/store/berichte/types';
import mixin from '@/mixins/limit/limit-mixin.js';
import CORE_TYPES from '@/store/core/types';
import GenericPersonFilter from '@/components/core/GenericPersonFilter.vue';
import NoData from '@/components/core/NoData.vue';
import BaseCollapsable from '@/components/core/BaseCollapsable.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

const TABLE_TITLE = 'Status der VideoIdents zu FFB Depoteröffnungen / Portfolioanträge';
const LABEL_CONFIG = { 'small': true };

export default {
    mixins: [mixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        Table,
        GenericPersonFilter,
        NoData,
        BaseCollapsable,
        FormLabel,
        GhostLoading,
    },
     data() {
        return {
            loading: false,
            saveKey: "ffbStatusSearchAdvanced",
            configFilter: { 
                placeholderForDefSearchInput: 'Kundennummer',
                kundenNummerKey: 'kundennr',
                noResetOnDefaultSearchInputExit: true,
            },
            selectedFilters: null,
            initialFilters: {
                unbestaetigt: true,
                INPUT_MONTH: '1',
            },
            selection: [],
            TABLE_TITLE,
            LABEL_CONFIG,
        }
    },
    mounted() {
        this.resetFilters();
    },
    computed: {
        ...mapGetters({
            list: BERICHTE_TYPES.GETTERS.STATUS_FFB_DEPOTS,
            savedFilters: BERICHTE_TYPES.GETTERS.FILTERS_FFB_STATUS_DEPOT,
            getPdfFilesLinkFn: CORE_TYPES.GETTERS.GET_PDF_FILES_LINK_FN,
        }),
        headers() {
            const headers = { 
                lockedLeft: [
                    IconColumn('bestaetigtIcon', 'bestätigt').setColorFn((row) => { return row?.color || ''}).makeSortable(),
                    TextColumn('isPortfolio', 'Portfolio Antrag').setColorFn((row) => { return row?.color || ''}),
                    TextColumn('kundennr', 'Kundennr').setColorFn((row) => { return row?.color || ''}).makeAlwaysVisible(),
                    TextColumn('kundenName', 'Kunde').makeLink().setColorFn((row) => { return row?.color || ''}),
                ],
                center: [
                    TextColumn('depotnr', 'reserv. Depotnummer').setColorFn((row) => { return row?.color || ''}),
                    TextColumn('statusStr', 'Status').setColorFn((row) => { return row?.color || ''}),
                    TextColumn('statusExplanation', 'Status erweitert').setColorFn((row) => { return row?.color || ''}),
                    DateColumn('expirationDate1', '1. Ablaufsdatum').setColorFn((row) => { return row?.color || ''}),
                    DateColumn('expirationDate2', '2. Ablaufsdatum').setColorFn((row) => { return row?.color || ''}),
                    DateColumn('lastChangeDate', 'Letzte Änderung').setColorFn((row) => { return row?.color || ''}),
                    TextColumn('identUrl1', 'Legi Link 1. Depotinhaber').makeLink(),
                    TextColumn('identUrl2', 'Legi Link 2. Depotinhaber').makeLink(),
                ],
                lockedRight: [
                    ActionColumn('actions', ''),
                ],
            };
            return headers;
        },
        rows() {
            return this.list?.map(elem => {
                let bestaetigtIcon = [];
                    if (elem.bestaetigt == '1')
                        bestaetigtIcon.push(Icon('PhCheck', "Ja", 20))
                
                const actions = [];
                if (elem?.dokId) {
                    const pdfLink = this.getPdfFilesLinkFn({ 
                        paramName: 'pdf', 
                        docId: elem.dokId, 
                    });
                    actions.push(DownloadLinkHrefAction("PDF", '', "FFB Depoteröffnungsdokument anzeigen", 
                        `FFB_Depoteroeffnung-${elem.kundennr || ''}.pdf`, pdfLink));
                }

                return {
                    ...elem,
                    bestaetigtIcon,
                    actions,
                }
            }); 
        },
        searchMenu() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Kundennummer',
                            key: 'kundennr',
                            inputmode: 'numeric',
                        },
                        {
                            type: 'text',
                            label: 'Depotnummer',
                            key: 'depotnr',
                        },
                        {
                            type: 'default',
                            label: 'nur offene Videoidents',
                            key: 'abgeschlossene',
                        },
                        {
                            type: 'default',
                            label: 'nur abgeschlossene Videoidents',
                            key: 'offene',
                        },
                        {
                            type: 'default',
                            label: 'nur unbestätigte Videoidents',
                            key: 'unbestaetigt',
                        },
                    ]
                },
                {
                    type: 'group',
                    key: 'lastChanges',
                    label: 'Letzte Änderung',
                    menuItems: [
                        {
                            type: 'default',
                            label: 'von diesem Jahr',
                            key: 'RADIOVALUE_THISYEAR',
                        },
                        {
                            type: 'integer',
                            label: 'der letzten Monaten',
                            key: 'INPUT_MONTH',
                        },
                        {
                            type: 'dateRange',
                            label: 'zwischen',
                            key: 'RADIOVALUE_FIXEDDATE',
                        },
                    ]
                },
            ]
        },
        filters() {
            return this.savedFilters || {};
        }, 
        defaultOptions() {
            const filters = {};
            Object.keys(this.initialFilters).forEach(key => filters[key] = { value: this.initialFilters[key]});
            return filters;
        }
    },
    methods: {
        openIdentLink(link) {
            if (link) {
                const elem = document.createElement('a');
                elem.setAttribute('href', link);
                elem.target = '_blank';
                elem.style.visibility = 'hidden';
                document.body.appendChild(elem);
                elem.click();
                setTimeout(() => document.body.removeChild(elem), 0);
            }
        },
        handleSearch(filters = {}) {
            this.$store.commit(BERICHTE_TYPES.MUTATIONS.SET_FILTERS_FFB_STATUS_DEPOT, filters);
            this.getStatusFFBDepots();
        },
        async getStatusFFBDepots() {
            try {
                this.loading = true;
                await this.$store.dispatch(BERICHTE_TYPES.ACTIONS.GET_STATUS_FFB_DEPOTS);
            } finally {
                this.loading = false;
            }
        },
        resetFilters() {
            this.$store.commit(BERICHTE_TYPES.MUTATIONS.SET_FILTERS_FFB_STATUS_DEPOT, this.initialFilters);
        },
        onSelect(rows) {
            this.selection = rows;
        },
        confirmSelection() {
            const selection = this.selection?.length && (this.selection.map(sel => sel.id) || []).filter(id => !!id) || null;
            if (selection?.length) {
                this.$store.dispatch(BERICHTE_TYPES.ACTIONS.CONFIRM_SELECTED_FFB_STATUS_ROWS, selection);
                this.selection = [];
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.status-ffb--hinweise-items__container {
    .input-forms__label-content {
        display: block;
        margin: 0 0 4px;
        height: auto;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
