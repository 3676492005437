<template>
  <DragnDropArea 
    hoverText="Datei hier ablegen"
    :disabled="isSomethingLoading || !stepHasDocuments"
    @files="dropFiles($event)"
  >

    <GesellschaftTitleNavigation
      :headerActions="headerActions"
      @action-CREATE_LESEZEICHEN="openCreateLesezeichenModal"
      @action-UPLOAD_DOCUMENT="uploadNewDocument"
    />

    <StepperForm
      ref="stepperForm"
      stepType="tab"
      stepperName="customer-data"
      :stepperMediator="tabsStepperMediator"
      :hasFertig="false"
      :selectedStepKey="currentStep"
      @set-step="setStep($event)"
    >
      <template #contentTemplate>
        <GhostLoading v-if="loading.init" useBoxContainer type="table" />
        <template v-else-if="currentStep === 'INTERNETADRESSEN'">
          <div class="box__container">
            <Table
              title="Internetadressen"
              rowId="urlKey"
              noDataContent="Keine Lesezeichen vorhanden"
              :headers="headers"
              :rows="internetAdressenRows"
              :rowsPerPage="10"
              @action-EDIT="openEditLesezeichenModal"
              @action-DELETE="deleteLesezeichen($event, 'internetadressen')"
            >
              <template v-slot:label="row"> 
                <a v-if="row.url" :href="row.url" target="_blank" rel="noopener noreferer" >
                  {{row.label || row.url}}
                </a>

                <span v-else>
                  {{row.label}}
                </span>
              </template>
            </Table>
          </div>
        </template>
        <template v-else-if="currentStep === 'FORMULARE'">
          <div class="box__container">
            <Table
              title="Formulare"
              rowId="urlKey"
              noDataContent="Keine Lesezeichen vorhanden"
              :headers="headers"
              :rows="formulareRows"
              :rowsPerPage="10"
              @action-EDIT="openEditLesezeichenModal"
              @action-DELETE="deleteLesezeichen($event, 'formulare')"
            >
              <template v-slot:label="row"> 
                <a v-if="row.url" :href="row.url" target="_blank" rel="noopener noreferer" >
                  {{row.label || row.url}}
                </a>

                <span v-else>
                  {{row.label}}
                </span>
              </template>
            </Table>
          </div>
          <div class="box__container">
            <Table
              title="Dokumente"
              noDataContent="Keine Dokumente vorhanden"
              :headers="documentHeaders"
              :rows="formulareDocumentRows"
              :rowsPerPage="10"
              @action-EDIT="editDocument"
              @action-DELETE="deleteDocument"
            >
              <template v-slot:bezeichnung="row">
                <DownloadLink 
                    :title="row.bezeichnung"
                    :filename="row.bezeichnung"
                    downloadServicePath="/get_simple_file"
                    :queryParams="{
                      fileId: row.id
                    }"
                  />
              </template>
            </Table>
          </div>
        </template>
        <template v-else-if="currentStep === 'INFOFILME'">
          <div class="box__container">
            <Table
              title="Infofilme"
              noDataContent="Keine Lesezeichen vorhanden"
              rowId="urlKey"
              :headers="headers"
              :rows="videoRows"
              :rowsPerPage="10"
              @action-EDIT="openEditLesezeichenModal"
              @action-DELETE="deleteLesezeichen($event, 'infofilme')"
            >
              <template v-slot:label="row"> 
                <a v-if="row.url" :href="row.url" target="_blank" rel="noopener noreferer" >
                  {{row.label || row.url}}
                </a>

                <span v-else>
                  {{row.label}}
                </span>
              </template>
            </Table>
          </div>
          <div class="box__container">
            <Table
              title="Dokumente"
              noDataContent="Keine Filme vorhanden"
              :headers="documentHeaders"
              :rows="videoDocumentRows"
              :rowsPerPage="10"
              @action-EDIT="editDocument"
              @action-DELETE="deleteDocument"
            >
              <template v-slot:bezeichnung="row">
                <DownloadLink 
                    :title="row.bezeichnung"
                    :filename="row.bezeichnung"
                    downloadServicePath="/get_simple_file"
                    :queryParams="{
                      fileId: row.id
                    }"
                  />
              </template>
            </Table>
          </div>
        </template>
      </template>
    </StepperForm>

    <BaseModal 
      ref="editLesezeichenModal"
      :modalTitle="editRow.urlKey ? 'Lesezeichen bearbeiten' : 'Lesezeichen anlegen'"
      :confirmDisabled="!editRow.url"
      @onConfirmButton="saveLesezeichen(editRow)"
    > 
      <InputField 
        label="Bezeichnung"
        v-model="editRow.label"
      />
      <InputField 
        label="Link"
        :renderDanger="!editRow.url"
        v-model="editRow.url"
      />
    </BaseModal>

    <!-- NOT implemented yet -->
    <BaseModal 
      ref="editSichtbarkeitModal"
      :modalTitle="'Formular sichtbar für'"
    >         
      <InputField
        isComponentHalfSize
        v-model="fileData.testing"
      />
      <div class="d-flex">
        <BaseButton
          label="nicht sichtbar"
          :disabled="!fileData.testing"
          @click="addSichtbar(false)"
        />
        <BaseButton
          label="nur sichtbar"
          :disabled="!fileData.testing"
          @click="addSichtbar()"
        />

      </div>
      <!-- NOT implemented yet -->
      <Table
        hidePagination
        :headers="sichtbarHeaders"
        :rows="sichtbarRows"
        :rowsPerPage="5"
        @action-DELETE="deleteDocument"
      />
    </BaseModal>

    <DokumentUploadModal
      ref="uploadModal"
      disableMultifile
      disableFotoUpload
      v-model="files"
      :confirmDisabled="!fileData.bezeichnung"
      @upload="uploadDocuments"
    >
      <InputField
        label="Bezeichnung"
        :renderDanger="!fileData.bezeichnung"
        v-model="fileData.bezeichnung"
      />
      <InputTextArea
        label="Bemerkung"
        v-model="fileData.bemerkung"
      />
      <ComboBox
        v-if="!isInfofilm"
        label="Kategorie" 
        :sortComboboxValues="false"
        :values="comboboxSelections.kategorien"
        v-model="fileData.formulareKategorie"
      />
      <DatePickerField
        label='Stand'
        isValueAsString
        v-model="fileData.dateStand"
      />
      <InputField
        v-if="!isInfofilm"
        label="Warnung"
        v-model="fileData.warnung"
      />
    
    </DokumentUploadModal>

  </DragnDropArea>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import { mapGetters } from 'vuex';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { makeQueryParam } from '@/helpers/utils-helper';

import axios from 'axios';
import { TextColumn, SlotColumn, PillColumn, ActionColumn, SimpleAction, ConfirmedAction, DateColumn } from "@/components/table2/table_util.js";
import { StepperMediator } from '@/components/stepper/utils';
import StepperForm from '@/components/stepper/StepperForm.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import BaseModal from '@/components/core/BaseModal.vue'
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import DokumentUploadModal from "@/views/gesellschaft/intern/DokumentUploadModal.vue";
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import dayjs from 'dayjs';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    GesellschaftTitleNavigation,
    Table,
    GhostLoading,
    StepperForm,
    ComboBox,
    InputField,
    InputTextArea,
    BaseModal,
    DatePickerField,
    DokumentUploadModal,
    DownloadLink,
    DragnDropArea,
    BaseButton,
  },
  data() {
    return {
      tabs: [
        {stepKey: 'INTERNETADRESSEN', label:'Internetadressen', totalProgress: 1},
        {stepKey: 'FORMULARE', label:'Formulare', totalProgress: 2},
        {stepKey: 'INFOFILME', label:'Infofilme', totalProgress: 3},
      ],
      loading: {
        init: false,
        save: false,
      },
      currentStep: 'INTERNETADRESSEN',
      editRow: {},
      fileData: {},
      files: [],
    }
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    comboboxSelections() {
      return this.gesellschaft.comboboxSelections || {}
    },
    lesezeichen() {
      return this.gesellschaft.lesezeichen || {}
    },
    headers() {
      return {
        lockedLeft: [
          SlotColumn("label", "Bezeichnung", 250, 1).makeAlwaysVisible(),
        ],
        center: [],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ],
      }
    },
    documentHeaders() {
      return {
        lockedLeft: [
          SlotColumn("bezeichnung", "Bezeichnung", 250, 1).makeAlwaysVisible(),
        ],
        center: [
          ... this.isInfofilm ? [] : [TextColumn("formulareKategorie", "Kategorie")],
          TextColumn("bemerkung", "Bemerkung"),
          DateColumn("dateStand", "Stand"),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ],
      }
    },
    internetAdressenRows() {
      let rows = this.lesezeichen.internetadressen || []

      return rows.map(row => ({
        ...row,
        actions: this.makeActions(row),
      }))
    },
    formulareRows() {
      let rows = this.lesezeichen.formulare || []

      return rows.map(row => ({
        ...row,
        actions: this.makeActions(row),
      }))
    },
    formulareDocumentRows() {
      let rows = this.lesezeichen.formulareDocs || []

      return rows.map(row => ({
        ...row,
        actions: this.makeActions(row, true),
      }))
    },
    videoRows() {
      let rows = this.lesezeichen.infofilme || []

      return rows.map(row => ({
        ...row,
        actions: this.makeActions(row),
      }))
    },
    videoDocumentRows() {
      let rows = this.lesezeichen.infofilmeDocs || []

      return rows.map(row => ({
        ...row,
        actions: this.makeActions(row, true),
      }))
    },
    sichtbarHeaders() {
      return {
        lockedLeft: [
          TextColumn("maklernr", "Maklernr"),
          TextColumn("name", "Name"),
          PillColumn("sichtbarkeit", "Sichtbar"),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ],
      }
    },
    sichtbarRows() {
      let rows = []

      return rows.map(row => ({
        ...row,
        sichtbarkeit: {
          label: row.sichtbar || '???',
          type: row.sichtbar === 'nein' ? 'danger' : 'success',
        },
        actions: [
          SimpleAction("DELETE", '', 'Sichtbarkeit entfernen'),
        ],
      }))
    },
    tabsStepperMediator() {
      return new StepperMediator(this.tabs);
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('CREATE_LESEZEICHEN', `Neues Lesezeichen (${this.tabs.find(tab => tab.stepKey === this.currentStep)?.label})`),
        PageHeaderSimpleAction('UPLOAD_DOCUMENT', this.isInfofilm ? 'Film hochladen' : 'Dokument hochladen')
          .withVisible(() => this.stepHasDocuments),
      ]
    },
    stepHasDocuments() {
      return this.currentStep === 'FORMULARE' || this.isInfofilm
    },
    isInfofilm() {
      return this.currentStep === 'INFOFILME'
    },
    isSomethingLoading() {
      return Object.values(this.loading).some(action => action) 
    },
  },
  mounted() {
    this.initLesezeichen()
  },
  methods: {
    initLesezeichen() {
      this.loading.init = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_LESEZEICHEN, { gesellschaftId: this.$route.params.gesellId })
      .finally(() => this.loading.init = false)
    },
    addSichtbar(sicht = true) {
      const sichtbarkeit = {
        maklernr: this.fileData.testing,
        sichtbar: sicht ? 'ja' : 'nein',
      }

      if (this.fileData.sichtbarkeiten) {
        this.$set(this.fileData, 'sichtbarkeiten', [...this.fileData.sichtbarkeiten, sichtbarkeit])
      } else {
        this.$set(this.fileData, 'sichtbarkeiten', [sichtbarkeit])
      }
    },
    openEditLesezeichenModal(row) {
      this.editRow = {...row}
      this.$refs.editLesezeichenModal.open()
    },
    openCreateLesezeichenModal() {
      this.editRow = {}
      this.$refs.editLesezeichenModal.open()
    },
    saveLesezeichen(lesezeichen, objKey = this.currentStep.toLowerCase()) {
      this.loading.save = true

      const params = makeQueryParam({ typ: this.currentStep, gesellschaftId: this.$route.params.gesellId }, true)
      const payload = {
        url: lesezeichen.url,
        urlKey: lesezeichen.urlKey,
        label: lesezeichen.label,
        labelKey: lesezeichen.labelKey,
      }

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/lesezeichen?${params}`, payload, config).then(response => {
        if (response && response.data) {
          const indx = this.gesellschaft.lesezeichen[objKey].findIndex(row => row.labelKey === response.data.labelKey && row.urlKey === response.data.urlKey)

          if (indx > -1) {
            this.$set(this.gesellschaft.lesezeichen[objKey], indx, response.data)
          } else {
            this.gesellschaft.lesezeichen[objKey].push(response.data)
          }
        } 
      }).finally(() => this.loading.save = false)
    },
    deleteLesezeichen({labelKey, urlKey}, objKey) {
      this.loading.save = true

      const params = makeQueryParam({ labelKey, urlKey, gesellschaftId: this.$route.params.gesellId }, true)

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/lesezeichen?${params}`, config).then(response => {
        if (response && response.status === 200) {
          this.gesellschaft.lesezeichen[objKey] = this.gesellschaft.lesezeichen[objKey].filter(row => row.labelKey !== labelKey && row.urlKey !== urlKey)
        } 
      }).finally(() => this.loading.save = false)
    },
    setStep(newStep){
      this.currentStep = newStep
    },
    makeActions(row, isDoc = false) {
      let actions = [
        SimpleAction("EDIT", '', "Bearbeiten")
      ]

      if (row.deletable) {
        if (isDoc) {
          actions.push(ConfirmedAction("DELETE", '', "Löschen", `Soll das Dokument "${row.bezeichnung || row.filename}" wirklich gelöscht werden?`, 'Dokument löschen', "Löschen"))
        } else {
          actions.push(ConfirmedAction("DELETE", '', "Löschen", `Soll das Lesezeichen "${row.label || row.url}" wirklich gelöscht werden?`, 'Lesezeichen löschen', "Löschen"))
        }
      }

      return actions
    },
    uploadNewDocument() {
      this.fileData = {
        formulareKategorie: 'Serviceformulare',
        dateStand: dayjs().format("DD.MM.YYYY"),
      }
      this.$refs.uploadModal.open()
    },
    editDocument(row) {
      this.fileData = JSON.parse(JSON.stringify(row))
      delete this.fileData.actions;
      this.files = [row]
      this.$refs.uploadModal.open()
    },
    deleteDocument({id}, objKey = this.currentStep.toLowerCase() + 'Docs') {
      this.loading.save = true
      const params = makeQueryParam({ id, gesellschaftId: this.$route.params.gesellId }, true)

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/documents?${params}`, config).then(response => {
        if (response && response.status === 200) {
          this.gesellschaft.lesezeichen[objKey] = this.gesellschaft.lesezeichen[objKey].filter(row => row.id !== id)
        } 
      }).finally(() => this.loading.save = false)
    },
    dropFiles(files) {
      this.fileData = {
        formulareKategorie: 'Serviceformulare',
        dateStand: dayjs().format("DD.MM.YYYY"),
      }
      this.$refs.uploadModal.dropFiles(files)
      this.$refs.uploadModal.open()
    },
    uploadDocuments({tempFileId}, objKey = this.currentStep.toLowerCase() + 'Docs') {
      this.loading.save = true

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)
      
      const payload = {
        ...this.fileData,
        typ: this.isInfofilm ? 'VIDEO_GESELLSCHAFT' : 'FORMULAR_PDF',
        tempFileId,
      }

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/documents?${params}`, payload, config).then(response => {
        if (response && response.data) {
          const indx = this.gesellschaft.lesezeichen[objKey].findIndex(row => row.id === response.data.id)

          if (indx > -1) {
            this.$set(this.gesellschaft.lesezeichen[objKey], indx, response.data)
          } else {
            this.gesellschaft.lesezeichen[objKey].push(response.data)
          }
        } 
      }).finally(() => this.loading.save = false)
    },
  },
}
</script>