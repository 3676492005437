<template>
  <div>
    <Table 
          tableId="47e82852-2bbf-4e87-9a9d-8ce3cafee39b"
          v-if="versicherungsSummen.length"
          title="Versicherungssumme"
          :headers="versicherungsSummenHeaders"
          :rows="versicherungsSummenRows"
          rowId="id"
          :headerActions="headerActions"
          @headerAction="handleHeaderAction"
          @action-EDIT="editRow"
          @action-DELETE="removeRow"
          hidePagination
      >
  
  
    </Table>

    <Table 
          tableId="497dcba3-ecbf-4587-a2dd-5eb0665e6880"
          v-if="versicherungsSummenFA.length"
          title="Versicherungssumme"
          :headers="versicherungsSummenHeadersFA"
          :rows="versicherungsSummenRowsFA"
          rowId="id"
          :headerActions="headerActions"
          @headerAction="handleHeaderAction"
          @action-EDIT="editRow"
          @action-DELETE="removeRow"
          hidePagination
      >
  
  
    </Table>

    <BaseModal ref="modalAdd" modalTitle="Versicherungssumme" size="lg" @onConfirmButton="saveOrUpdateRow">
          <ComboBox
            label="Sparte"
            v-model="form.sparteId"
            :values="availableSparten"
            v-if="!form.id"
          />      

          <InputField
            v-model="form.bezeichnung"
            label="Bezeichnung"
          />      

          <InputField
            type="currency"
            :precision="2"
            v-model="form.betrag"
            label="Versicherungssumme"
          />      



    </BaseModal>        

    
  </div>

</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhNotePencil, PhCopy, PhInfo, PhNoteBlank } from "phosphor-vue";
import InsuranceDetailMixin from './InsuranceDetail-mixin.js';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import {TextColumn, CurrencyColumn, ActionColumn, ConfirmedAction, SimpleAction} from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    BoxContainer,
    PhNotePencil,
    PhCopy,
    PhInfo,
    PhNoteBlank,
    Table,
    BaseModal,
    InputField,
    ComboBox,
    
  },
  data() {
    return {
      form: {
        id: null,
        betrag: null,
        bezeichnung: null,
        sparte: null,
        sparteId: null,
      }
    }
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA, 
      versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
      versicherungsSummen: VERSICHERUNG_TYPES.GETTERS.VERSICHERUNGSSUMMEN,
      versicherungsSummenFA: VERSICHERUNG_TYPES.GETTERS.VERSICHERUNGSSUMMEN_FA,
    }),
    versicherungsSummenHeaders() {
      const result = {
        lockedLeft: [
          TextColumn("id", "Id").makeHidden(),
          TextColumn("sparte", "Sparte").makeAlwaysVisible()
        ],
        center: [
          TextColumn("bezeichnung", "Beschreibung"),
          CurrencyColumn("betrag", "Wert")
        ],
        lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],        
      };

      
      return result;
    },
    versicherungsSummenHeadersFA() {
      const result = {
        lockedLeft: [
          TextColumn("id", "Id").makeHidden(),
          TextColumn("beschreibung", "Sparte").makeAlwaysVisible(),
          TextColumn("relatedSparte", "Schritt").makeAlwaysVisible()
        ],
        center: [
          TextColumn("bezeichnung", "Beschreibung"),
          TextColumn("personOderObjekt", "Person/Objekt"),
          CurrencyColumn("betrag", "Wert"),
        ],
        lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],        
      };

      
      return result;
    },      
    versicherungsSummenRows() {
      const actions = [
        SimpleAction("EDIT", '', "Bearbeiten"),
        ConfirmedAction("DELETE", '', "Löschen", `Soll die Versicherungssumme wirklich gelöscht werden?`, 'Versicherungssumme löschen', "Löschen")
      ]


      return this.versicherungsSummen.map(row => {
        return {
          ...row,
          actions
        }
      });

    },
    versicherungsSummenRowsFA() {
      const actions = [
        SimpleAction("EDIT", '', "Bearbeiten"),
        ConfirmedAction("DELETE", '', "Löschen", `Soll die Versicherungssumme wirklich gelöscht werden?`, 'Versicherungssumme löschen', "Löschen")
      ]


      return this.versicherungsSummenFA.map(row => {
        return {
          ...row,
          relatedSparte: this.versicherungenDetails?.steps.find(s => s?.config?.versSparteId == row.sparteId)?.title,
          actions
        }
      });

    },    
    headerActions() {
      return [
        PageHeaderSimpleAction('NEW', 'Hinzufügen'),
      ];

    },
    availableSparten() {
      return this.versicherungenDetails?.insurance?.sparten?.map(
        s => {
          return {

            label: s.beschreibung,
            value: s.id
          }
        }
      )
    }


   
  },
  methods: {
    editRow(whatRow) {
      const payload = {
        id: whatRow?.id, 
        sparte: whatRow?.sparte, 
        betrag: whatRow?.betrag, 
        bezeichnung: whatRow?.bezeichnung, 
        sparteId: whatRow?.sparteId,
        vertragId: this.versicherungenDetails.insurance.id,
      };

      Object.assign(this.form, payload);
      this.$refs.modalAdd.open();
    },
    async saveOrUpdateRow() {
      await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.SAVE_OR_UPDATE_VERSICHERUNG_SUMME, this.form);
      if (this.isFA) {
        await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERSICHERUNGSSUMMEN_FA, {vertragId: this.versicherungenDetails?.insurance?.id});
      } else {
        await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERSICHERUNGSSUMMEN, {vertragId: this.versicherungenDetails?.insurance?.id});
      }
    },
    async removeRow(whatRow) {
      await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.DELETE_VERSICHERUNG_SUMME, {
        id: whatRow?.id, 
        vertragId: this.versicherungenDetails?.insurance?.id
      });

      if (this.isFA) {
        await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERSICHERUNGSSUMMEN_FA, {vertragId: this.versicherungenDetails?.insurance?.id} );
      } else {
        await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERSICHERUNGSSUMMEN, {vertragId: this.versicherungenDetails?.insurance?.id} );
      }
    },
    handleHeaderAction({ key, value, }) {
      const payload = {
        id: null,
        betrag: null,
        bezeichnung: null,
        sparte: null,
        sparteId: null,
        vertragId: this.versicherungenDetails.insurance.id,
      };

      switch (key) {
        case 'NEW':
          Object.assign(this.form, payload);
          this.$refs.modalAdd.open();
          break;
      
        default:
          break;
      }
      this.$emit(`headerAction-${key}`, value)
    },    
  },
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>