var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-policies__container" },
    [
      _vm.isCollapsable
        ? _c("BaseCollapsable", {
            attrs: { showSection: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("div", {
                        staticClass: "text-bold",
                        domProps: {
                          innerHTML: _vm._s(_vm.$sanitize(_vm.TITLE))
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "ul",
                        { staticClass: "password-policies--list" },
                        _vm._l(_vm.POLICIES, function(policyText, index) {
                          return _c("li", { key: index }, [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.sanitize(policyText))
                              }
                            })
                          ])
                        }),
                        0
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3405409389
            )
          })
        : [
            _c("div", {
              staticClass: "text-bold",
              domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.TITLE)) }
            }),
            _c(
              "ul",
              { staticClass: "password-policies--list" },
              _vm._l(_vm.POLICIES, function(policyText, index) {
                return _c("li", { key: index }, [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.sanitize(policyText)) }
                  })
                ])
              }),
              0
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }