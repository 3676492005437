import { range } from './brokerSearchConstants.js';


export function searchMenuIntern(isFK) {
  return [
    {
      type: 'group',
      key: 'allgemein',
      label: 'Allgemein',
      menuItems: [
      
        {
          type: 'text',
          label: 'Maklernummer',
          key: 'brokerId',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Kundennummer',
          key: 'customerId',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Firma',
          key: 'firma',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Strukturleiter',
          key: 'strukturleiter',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Fremdnummer',
          key: 'fremdnummer',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Referenznummer',
          key: 'referenznummer',
          emptyDenied: true,
        },
        
        {
          type: 'default',
          label: 'mit Struktur',
          key: 'structure',
          emptyDenied: true,
        },
       
        {
          type: 'text',
          label: 'Name',
          key: 'surname',
          sortable: true,
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Vorname',
          key: 'firstName',
          emptyDenied: true,
        },

        {
          type: 'default',
          label: 'nur aktive Vermittler',
          key: 'vermittlerAktiv',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'nur inaktive Vermittler',
          key: 'vermittlerInaktiv',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'nur Passivmakler',
          key: 'maklerPassiv',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'keine Passivmakler',
          key: 'keineMaklerPassiv',
          emptyDenied: true,
        }, 
        ...(!isFK ? [
          {
            type: 'default',
            label: 'Persönliches Geschäft erforderlich',
            key: 'isPersGeschaeft',
          },
          {
            type: 'default',
            label: 'Persönliches Geschäft erforderlich (Unternummer)',
            key: 'isPersGeschaeftUN',
          },
        ] : []),
        {
          type: 'default',
          label: 'VV-Advisor',
          key: 'vvAdvisor',
          emptyDenied: true,
        },
      ]
    },
    {
      type: 'group',
      key: 'adresse',
      label: 'Adresse',
      menuItems: [
        {
          type: 'text',
          key: 'strasse',
          label: 'Straße',
          emptyDenied: true,
        },
        {
          type: 'text',
          key: 'ort',
          label: 'Ort',
          emptyDenied: true,
        },
        {
          type: 'text',
          key: 'plz',
          label: 'PLZ',
          emptyDenied: true,
        },
        {
          type: 'textRange',
          key: 'plzVonBis',
          label: 'PLZ Bereich',
          emptyDenied: true,
        },
       
        {
          type: 'default',
          key: 'fehlendeAngaben',
          label: 'fehlende Angaben',
          emptyDenied: true,
        },

      ]
    },
    {
      type: 'group',
      key: 'depot',
      label: 'Depot',
      menuItems: [
        {
          type: 'combobox',
          label: 'Gesellschaft',
          key: 'gesellschaftDepot',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Lagerstelle',
          key: 'lagerstelleDepot',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Wertpapier',
          key: 'wertpapier',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'text',
          label: 'ISIN',
          key: 'isin',
          emptyDenied: true,
          pattern: /\b([A-Z]{2})((?![A-Z]{10}\b)[A-Z0-9]{10})\b/i
        },
        {
          type: 'default',
          label: 'mit Bestand Investment',
          key: 'mitBestandInvestment',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'mit Bestand Investment Struktr',
          key: 'mitBestandInvestmentStruktur',
          emptyDenied: true,
        },

      ]
    },
    {
      type: 'group',
      key: 'versicherung',
      label: 'Versicherung',
      menuItems: [
        {
          type: 'combobox',
          label: 'Gesellschaft',
          key: 'gesellschaftVers',
          emptyDenied: true,
          comboOptions: []
        },
        {
          type: 'combobox',
          label: 'Lagerstelle',
          key: 'lagerstelleVers',
          emptyDenied: true,
          comboOptions: []
        },

      ]
    },
    {
      type: 'group',
      key: 'courtage',
      label: 'Courtage',
      menuItems: [
        {
          type: 'numberRange',
          label: 'Courtage Abschluss Wertpapiere Prozentbereich',
          key: 'courtageAbschlussWpVonBis',
          emptyDenied: true,
        },
       
        {
          type: 'numberRange',
          label: 'Courtage Bestand Wertpapiere Prozentbereich',
          key: 'courtageBestandVonBis',
          emptyDenied: true,
        },
      
        {
          type: 'numberRange',
          label: 'Courtage VWL Wertpapiere Prozentbereich',
          key: 'courtageVWLVonBis',
          emptyDenied: true,
        },
        
        {
          type: 'numberRange',
          label: 'Courtage Abschluss Versicherung Prozentbereich',
          key: 'courtageAbschlussVersVonBis',
          emptyDenied: true,
        },
       
        {
          type: 'numberRange',
          label: 'Courtage Bestand Versicherung Prozentbereich',
          key: 'courtageBestandVersVonBis',
          emptyDenied: true,
        },

      ]
    },
    {
      type: 'group',
      key: 'email',
      label: 'E-Mail',
      menuItems:[
        {  
        type: 'text',
        label: 'E-Mail',
        key: 'email',
        emptyDenied: true,
      },
        {
          type: 'default',
          label: 'E-Mail Investmentfonds',
          key: 'emailInvstmentfonds',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'E-Mail Geschlossene Fonds',
          key: 'emailGeschlosseneFonds',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'E-Mail Versicherungen',
          key: 'emailVersicherungen',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'auch bei den E-Mails der Ansprechspartner suchen',
          key: 'emailAnsprechspartner',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Rundmail empfänger',
          key: 'emailRundmailEmpfeanger',
          emptyDenied: true,
        },
      ],
    },

    {
      type: 'group',
      key: 'vertragFK',
      label: (isFK ? 'Vertrag FK' : 'Vertrag Fonds'),
      menuItems:[
        {
          type: 'dateRange',
          label: 'Vertrag verschickt zwischen',
          key: 'vertragVonBis',
        },
        {
          type: 'dateRange',
          label: 'Vertrag zurückerhalten zwischen',
          key: 'vertragZuruekVonBis',
        },
        {
          type:'datepicker',
          label:'Vertrag gekündigt am',
          key:'vertragGekuendigt',
        },
        {
          type: 'default',
          label: 'Vertrag verschickt aber nicht zurückerhalten',
          key: 'verschiktNichtZuruekerhalten',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Vertrag verschickt',
          key: 'verschikt',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Vertrag zurückerhalten',
          key: 'zuruekerhalten',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Vertrag gekündigt',
          key: 'istGekuendigt',
          emptyDenied: true,
        },

      ].concat( isFK ? [
        {
          key: 'gewOInv',
          type: 'multipleItemSelector',
          label: 'GewO Investment',
          comboOptions: [
            {value: 'f1', label:'§34fNr1'},
            {value: 'f2', label:'§34fNr2'},
            {value: 'f3', label:'§34fNr3'},
            {value: 'h1', label:'§34hNr1'},
            {value: 'h2', label:'§34hNr2'},
            {value: 'h3', label:'§34hNr3'},
            {value: '2m', label:'§32'},
            {value: '2s', label:'§32Struk'},
          ],
          emptyDenied: true,
          deniable: true,
        },
        {
          key: 'gewOInvB',
          type: 'multipleItemSelector',
          label: 'GewO Investment bestätigt',
          comboOptions: [
            {value: 'f1', label:'§34fNr1'},
            {value: 'f2', label:'§34fNr2'},
            {value: 'f3', label:'§34fNr3'},
            {value: 'h1', label:'§34hNr1'},
            {value: 'h2', label:'§34hNr2'},
            {value: 'h3', label:'§34hNr3'},
            {value: '2m', label:'§32'},
            {value: '2s', label:'§32Struk'},
          ],
          emptyDenied: true,
          deniable: true,
        },
      ] : [] )
    },

    {
      type: 'group',
      key: 'vertragAK',
      label: (isFK ? 'Vertrag AK' : 'Vertrag Versicherung'),
      menuItems:[
        {
          type: 'dateRange',
          label: 'Vertrag verschickt zwischen',
          key: 'vertragVonBisAK',
        },
        {
          type: 'dateRange',
          label: 'Vertrag zurückerhalten zwischen',
          key: 'vertragZuruekVonBisAK',
        },
        {
          type:'datepicker',
          label:'Vertrag gekündigt am',
          key:'vertragGekuendigtAK',
        },
        {
          type: 'default',
          label: 'Vertrag verschickt aber nicht zurückerhalten',
          key: 'verschiktNichtZuruekerhaltenAK',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Vertrag verschickt',
          key: 'verschiktAK',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Vertrag zurückerhalten',
          key: 'zuruekerhaltenAK',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Vertrag gekündigt',
          key: 'istGekuendigtAK',
          emptyDenied: true,
        },
      ].concat( isFK ? [
        {type: 'default', key: 'vMeFaAg', deniable: true, label: 'Mehrfachagent'},
        {
          key: 'gewOVers',
          type: 'multipleItemSelector',
          label: 'GewO Versicherung',
          comboOptions: [
            {value: 'd1', label:'§34dAbs1'},
            {value: 'd2', label:'§34dAbs2'},
            {value: 'i', label:'§34i'},
            {value: 'c1', label:'§34cNr1'},
            {value: 'c2', label:'§34cNr2'},
          ],
          emptyDenied: true,
          deniable: true,
        },
        {
          key: 'gewOVersB',
          type: 'multipleItemSelector',
          label: 'GewO Versicherung bestätigt',
          comboOptions: [
            {value: 'd1', label:'§34dAbs1'},
            {value: 'd2', label:'§34dAbs2'},
            {value: 'i', label:'§34i'},
            {value: 'c1', label:'§34cNr1'},
            {value: 'c2', label:'§34cNr2'},
          ],
          emptyDenied: true,
          deniable: true,
        },
      ] : [

      ] )
    },

    {
      type: 'group',
      key: 'diverses1',
      label: 'Diverses 1',
      menuItems:[
        {
         type: 'combobox',
         label: 'Kategorie',
         key: 'kategorie',
         emptyDenied: true,
         sortComboboxValues: false
        },
        {
          type: 'combobox',
          label: 'Potential',
          key: 'potential',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Interessent',
          key: 'interessent',
          emptyDenied: true,
        },
    
        {
          type: 'default',
          label: 'Spezialsuche Servicegebühr',
          key: 'servicegebuerSpezial',
          emptyDenied: true,
        },
    
        {
          type: 'default',
          label: 'mit Stornorisiko',
          key: 'mitStornorisiko',
          emptyDenied: true,
        },
    
        {
          type: 'default',
          label: 'sonstige Sicherheiten',
          key: 'sonstigeSicherheiten',
          emptyDenied: true,
        },
    
        {
          type: 'numberRange',
          label: 'Kontostand Übertragskonto Bereich',
          key: 'uebertragskontoVonBis',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Mitarbeiter bei Baader Bank geprüft',
          key: 'mitarbeiterBaaderBankLegitimiert',
          emptyDenied: true,
        },
        {
          type: 'combobox',
          label: 'Geldwäschebelehrung positiv',
          key: 'mitGeldwaesche',
          emptyDenied: true,
          deniable: false,
          comboOptions: range((new Date()).getFullYear() + 1, 2006)
        },
        {
          type: 'combobox',
          label: 'Geldwäschebelehrung negativ',
          key: 'ohneGeldwaesche',
          emptyDenied: true,
          deniable: false,
          comboOptions: range((new Date()).getFullYear() + 1, 2006)
        },

      ].concat( isFK ? [
        {
          type: 'text',
          label: 'Bürgel ab / schlechter',
          key: 'buergelSchlechter',
          emptyDenied: true,
        },
        {
          type: 'text',
          label:  'Bürgel ab / besser',
          key: 'buergelBesser',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Dokumentenprüfung ausstehend',
          key: 'dokPruefungAusstehend',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Vertrag FKIM',
          key: 'vertragFKIM',
          emptyDenied: true,
          deniable: true,
        },
        {
          type: 'default',
          label: 'Vertrag FKAM',
          key: 'vertragFKAM',
          emptyDenied: true,
          deniable: true,
        },
        {
          type: 'default',
          label: 'Vertrag WK',
          key: 'vertragWK',
          emptyDenied: true,
          deniable: true,
        },
        {
          type: 'combobox',
          label: 'IHK Reg.',
          key: 'regIHK',
          emptyDenied: true,
          comboOptions: [
            {label: 'Passiv', value: 'p'},
            {label: 'Unvollständig', value: 'u'},
            {label: 'Vollständig', value: 'v'},
            {label: 'Nicht geprüft', value: 'n'},
            {label: 'Prüfung abgel.', value: 'a'},
            {label: '§15', value: '1'},
          ],
          deniable: true,
        },
        {
          type: 'default',
          label: 'Zuverlässigkeitserklärung für Geschäftsführer/Berater akzeptiert',
          key: 'mitZuverlaessigkeit',
          emptyDenied: true,
          deniable: true,
        },
        {
          type: 'default',
          label: 'ohne aktuelle Wirtschaftsprüfung',
          key: 'ohneWitschaft',
          emptyDenied: true,
        },
    
        {
          type: 'default',
          label: 'Problem bei Wirtschaftsprüfung evistra: fehlender Upload',
          key: 'evistraFehlederUpload',
          emptyDenied: true,
        },
    
        {
          type: 'default',
          label: 'Problem bei Wirtschaftsprüfung evistra: kein Vertrag(hatte Vorjahr)',
          key: 'evistraHatteVorjahr',
          emptyDenied: true,
        },
    
        {
          type: 'default',
          label: 'Problem bei Wirtschaftsprüfung evistra: kein Vertrag(neuer Vermittler)',
          key: 'evistraNeuerVermittler',
          emptyDenied: true,
        },
      ] 
      : [
        {
          type: 'combobox',
          label: 'WAG-Prüfung positiv',
          key: 'mitWagPruefung',
          emptyDenied: true,
          deniable: false,
          comboOptions: range((new Date()).getFullYear() + 1, 2011)
        },
        {
          type: 'combobox',
          label: 'WAG-Prüfung negativ',
          key: 'ohneWagPruefung',
          emptyDenied: true,
          deniable: false,
          comboOptions: range((new Date()).getFullYear() + 1, 2011)
        },
        {
          type: 'combobox',
          label: 'Datenschutzschulung positiv',
          key: 'mitDatenschutzschulung',
          emptyDenied: true,
          deniable: false,
          comboOptions: range((new Date()).getFullYear() + 1, 2021)
        },
        {
          type: 'combobox',
          label: 'Datenschutzschulung negativ',
          key: 'ohneDatenschutzschulung',
          emptyDenied: true,
          deniable: false,
          comboOptions: range((new Date()).getFullYear() + 1, 2021)
        },
      ]),
    },

    {
      type: 'group',
      key: 'diverses2',
      label: 'Diverses 2',
      menuItems:[
        {
          type: 'text',
          label:  'Besuchsbericht Teilnehmer',
          key: 'besuchsberichtTeilnehmer',
          emptyDenied: true,
        },
        {
          type: 'combobox',
          label:  'Besuchsbericht von',
          key: 'besucher',
          emptyDenied: true,
        },
        {
          type: 'text',
          label:  'Nicht Anzeigen',
          key: 'nichtAnzeigen',
          emptyDenied: true,
        },
        {
          type: 'dateRange',
          label:  'Besuchtsbericht Datumsbereich',
          key: 'besuchtsberichtVonBis',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'telefonischer Termin',
          key: 'telefonischerTermin',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Außentermin',
          key: 'ausentermin',
          emptyDenied: true,
        },
        {
          type: 'chips',
          label:  'Hobbys',
          key: 'hobbies',
          supportCustomValue: true,
          uniqueSelection: false,
          emptyDenied: true,
        },
        {
          type: 'combobox',
          label: 'Vermittlerkriterien',
          key: 'maklerkriterien',
          comboOptions: [],
          secondaryItem: {
            type: 'text',
            key: 'maklerkriterienText',
            parentValue: ['Sonstige'],
          }
        },
        {
          type: 'combobox',
          label:  'Betreuer',
          key: 'betreuer',
          emptyDenied: true,
        },
      ],
    },

  ];
}
