<template>
<div>
    <PageHeaderTitleNavigation 
        :defaultMenu="$appNavigation.currentTabMenu" 
        title="GDV Datenimport"
        subtitle="Interface Verwalten" 
        :actions="headerActions" 
        @action-ADD="newInterface" 
    />

    <BaseFilter
        title="Interface Verwalten"
        filterId="Interface Verwalten"
        :configFilter="filterConfig"
        :metadata="metadata"
        :defaultOptions="defaultOptions"
        showSaveButton
        @onFilter="onFilter"
    />

    <div class="box__container">
        <span v-if="loading || !rows.length" class="box__title">
            Interface Verwalten
        </span>
        <GhostLoading v-if="loading" type="table"/>
        <Table v-else-if="rows.length" style="clear: both;"
            tableId="2f188530-c61e-4951-808d-5d1c9196c534"
            title="Interface Verwalten"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="20"
            @action-OPEN="actionOpen"
            @action-DELETE="deleteRow"
            @action-COPY="copyRow"
        />
        <NoData v-else/>
    </div>
</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import {TextColumn, NumberColumn, ActionColumn, SimpleAction, ConfirmedAction} from "@/components/table2/table_util.js";
import axios from 'axios';
import { mapGetters } from 'vuex';
import { buildMessage } from "@/helpers/log-message-helper";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const axiosConfig = {
    defaultSpinner: true,
};

export default {
    mixins: [],
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        BaseFilter,
        Table,
        GhostLoading,
        NoData,
        BaseButton,
    },
    data() {
        return {
            loading: false,
            filterConfig: {
                placeholderForDefSearchInput: 'Gesellschaft',
                checkDefaultSearchInput: false,
                cbDefSearchInput: null,
                hideFirstColumn: true,
                filterZurucksetzen: null,
                noResetOnDefaultSearchInputExit: true,
            },
            defaultOptions: {
                gesellschaft: "",
            },
            filterParams: {},
            rowsRaw: null,
            headers: {
                lockedLeft: [
                    NumberColumn("id", "ID").addCellProps({separator: ""}),
                    TextColumn("gesellschaft", "Gesellschaft").addCellProps({lineClamp: 4}),
                    TextColumn("tableName", "Interface Name").addCellProps({lineClamp: 4}),
                ],
                center: [
                    TextColumn("description", "Beschreibung", 300).addCellProps({lineClamp: 5}),
                    TextColumn("multiple", "Mehrfach"),
                    TextColumn("checkStatement", "Check Statement", 300).addCellProps({lineClamp: 5}),
                    TextColumn("type", "Typ"),
                    TextColumn("xPath", "XPath"),
                    TextColumn("xmlElement", "XML Elementname"),
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            },
        };
    },
    computed: {
        ...mapGetters({
            isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
        }),
        headerActions() {
            return [
                PageHeaderSimpleAction('ADD', 'Neue Daten anlegen').withVisible(() => this.interfaceEditEnable),
            ];
        },
        metadata() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Gesellschaft ID',
                            key: 'gesellschaft',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Interface Name',
                            key: 'interfaceName',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Beschreibung',
                            key: 'description',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Interface ID',
                            key: 'interfaceId',
                            emptyDenied: true,
                        },
                        {
                            type: 'default',
                            label: 'Inklusive nicht aktiven',
                            key: 'withInactive',
                            emptyDenied: true,
                        },
                        {
                            type: 'default',
                            label: 'Inklusive nicht sichtbaren',
                            key: 'withInvisible',
                            emptyDenied: true,
                        },
                    ],
                },
            ];
        },
        interfaceEditEnable() {
            return this.isIntern;
        },
        rows() {
            return (this.rowsRaw || []).map(row => {
                const actions = [];
                if (this.interfaceEditEnable) {
                    actions.push(SimpleAction("OPEN", '', "Interface editieren"));
                    actions.push(ConfirmedAction("DELETE", '', "Interface löschen", "Soll das Interface " + row.gesellschaft + ", " + row.tableName + " wirklich gelöscht werden?", " Löschen"));
                    actions.push(ConfirmedAction("COPY", '', "Interface kopieren", "Soll das Interface " + row.gesellschaft + ", " + row.tableName + " wirklich kopiert werden?", " Kopieren"));
                } else {
                    actions.push(SimpleAction("OPEN", '', "Interface anschauen"));
                }
                return {
                    ...row,
                    multiple: row.multiple ? "Ja" : "Nein",
                    actions,
                };
            });
        },
    },
    methods: {
        async onFilter(filterConfig) {
            let params = {};

            filterConfig.forEach(fc => {
                let value = fc.value;
                if (fc.type == 'default')
                    value = true;
                params[fc.key] = value;
            });
            this.filterParams = params;
            this.loadRows();
        },
        async loadRows() {
            this.loading = true;
            try {
                axios.post(`${process.env.VUE_APP_API}/gdv_interface/filter`, this.filterParams, axiosConfig)
                .then(response => {
                    this.rowsRaw = response.data;
                }).catch(error => {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Bei der Suche ist ein Fehler aufgetreten.', 'danger'));
                })
            } finally {
                this.loading = false;
            }
        },
        newInterface() {
            this.$router.push({path: "/intern/gdv/gdv-interfaces/gdv-interface"});
        },
        actionOpen(row) {
            // navigate to interface details view
            this.$router.push({path: "/intern/gdv/gdv-interfaces/gdv-interface", query: {id: row.id}});
        },
        deleteRow(row) {
            axios.delete(`${process.env.VUE_APP_API}/gdv_interface/delete?id=${row.id}`, axiosConfig)
            .then(_ => {
                this.loadRows();
            })
        },
        copyRow(row) {
            axios.post(`${process.env.VUE_APP_API}/gdv_interface/copy?id=${row.id}`, null, axiosConfig)
            .then(_ => {
                this.loadRows();
            })
        },
    },
    beforeRouteLeave (to, from, next) {
        this.$addBreadcrumb({
            label: 'zurück zu Interface Verwalten', 
            to,
            from,
        });
        next()
    },
}
</script>
