<template>
  <div class="box__container">
    <div class="box__title">Dokumente</div>
    <Table v-if="rows.length"
        tableId="e4443e72-ee74-40ff-b07e-821a4f74c3d3"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        rowId="fileId"
        @action-DOWNLOAD="downloadDocument"
        @action-DELETE="openDeleteDocumentConfirmModal"
    />
    <div v-else>Keine Daten</div>

    <BaseModal
      @onConfirmButton="deleteDocument()"
      ref="deleteDocumentConfirmModal"
      size="sm"
      modalTitle="Löschen">
        Wollen Sie das Dokument wirklich löschen?
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue';

import { viewDocument } from '@/components/core/download/DownloadLink.vue';


export default {
  data() {
    return {
        documentFile: null,
        headers: {
            lockedLeft: [
                TextColumn("filename", "Name"),
            ],
            center: [
                TextColumn("categoryDesc", "Kategorie"),
                TextColumn("description", "Bezeichnung"),
                DateColumn("dateCreated", "Erstellt am"),
                TextColumn("comment", "Bemerkung"),
            ],
            lockedRight: [
                ActionColumn("actions"),
            ],
        },
    };
  },
  computed: {
    ...mapGetters({
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
    }),
    isEditable() {
      return this.customerData?.isEditable;
    },
    rows() {
        const actions = [];
        if (this.customerDataConfig?.canViewDocument)
            actions.push(SimpleAction("DOWNLOAD", '', "Download"));
        if (this.customerDataConfig?.canChangeDocument && this.isEditable)
            actions.push(SimpleAction("DELETE", '', "Löschen"));
        return (this.customerData?.documents || []).map(row => ({
            ...row,
            actions,
        }));
    },
  },
  methods: {
    async downloadDocument(documentFile) {
      const response = await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DOWNLOAD_FILE, { fileId: documentFile?.fileId });
      viewDocument({
        data: response?.bytes,
        filename: response?.fileName,
        contentType: response?.responseContentType?.mimeType,
      })
    },
    openDeleteDocumentConfirmModal(documentFile) {
      this.documentFile = documentFile;
      this.$refs.deleteDocumentConfirmModal.open();
    },
    async deleteDocument() {
      await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_FILE, { 
        personId: this.personId, 
        fileId: this.documentFile?.fileId,
      });
    },
  },
  components: {
    Table,
    BaseModal,
  },
}
</script>
