<template id="card">
  <div class="section" :class="{ 'collapsed': !mutatedSection }">
      <span
        v-show="showEditButton"
        class="btn bearbeiten"
        @click="onEditItem()"
        style="background-color:transparent"
      >
        <ph-pencil-line :size="16" />
      </span>
      <span
        v-show="showDeleteButton"
        class="btn bearbeiten"
        style="background-color:transparent"
        @click="onDeleteItem()"
      >
        <ph-trash :size="16" />
      </span>
    <div class="title" v-on:click="toggle" >
      <div class="collapsable-title">
        <slot name="title" v-on:click="toggle"/>
      </div>
      &nbsp;
      <div class="collapsable-toggle-icon">
        <button type="button" class="btn-clear">
          <ph-caret-down :size="16" weight="bold" v-show="!mutatedSection"/>
          <ph-caret-up :size="16" weight="bold" v-show="mutatedSection"/>
        </button>
      </div>
    </div>
    <!--hr /-->
    <div class="body" v-show="mutatedSection">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { PhCaretDown, PhCaretUp, PhPencilLine, PhTrash } from 'phosphor-vue'
export default {
  name: "collapsable",
  props: {
		showEditButton: {
			type: Boolean,
			default: false,
    },
		showDeleteButton: {
			type: Boolean,
			default: false,
		},    
		showSection: {
			type: Boolean,
			default: false,
		},  
		visibile: { // prop for directive v-model
      type: Boolean,
      default: undefined
		},
  },
  model: {
    prop: 'visibile',
    event: 'change',
  },
  data: function() {
    return {
      // To avoid mutating the showSection prop directly
      mutatedSection : this.showSection
    };
  },
  computed: {
    visibileValue: {
      get() {
        return this.visibile
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
  methods: {
    toggle() {
      this.mutatedSection = !this.mutatedSection;
      this.visibileValue = this.mutatedSection;
    },
    expand(){
      this.mutatedSection = true;
    },
    collapse() {
      this.mutatedSection = false;
    },
    onEditItem(event) {
      this.showSection = false;
      this.$emit('edit-item');
    },
    onDeleteItem(event) {
      this.showSection = false;
      this.$emit('delete-item');
    }    
  },
  watch: {
    visibile: function() {
      this.mutatedSection = this.visibile;
    }
  },
  created() {
    if ( this.visibile !== undefined) {
      this.mutatedSection = this.visibile;
    }
  },
  components: {
    PhCaretDown, 
    PhCaretUp,
    PhPencilLine,
    PhTrash,
  }
};
</script>

<style scoped>
.title {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

span i {
  cursor: pointer;
}

.title i {
  font-size: 0.9em;
}

.bearbeiten {
  float: right;
}

.collapsable-title {
  flex-grow: 1;
}

.collapsable-toggle-icon {
  flex: 0 0 auto;
}

.collapsed .title {
  margin-bottom: 0;
}
</style>
