var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasItems
    ? _c(
        "div",
        {
          staticClass: "autocomplete-result__container",
          attrs: { "data-title": _vm.title }
        },
        [
          _vm.title
            ? _c(
                "div",
                { staticClass: "autocomplete-result--header fc-heading-small" },
                [
                  _c("span", { staticClass: "autocomplete-result--title" }, [
                    _vm._v(_vm._s(_vm.title))
                  ]),
                  _vm.loading.length === 0
                    ? _c("Pill", {
                        staticClass: "autocomplete-result--count",
                        attrs: { label: _vm.total, type: "default" }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          [
            _c(
              "ul",
              { staticClass: "autocomplete-result--items" },
              _vm._l(_vm.slicedResults, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "autocomplete-result--item",
                    attrs: { tabindex: "0" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", item.value)
                      },
                      keydown: [
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          return _vm.$emit("click", item.value)
                        },
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar"
                            ])
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          return _vm.$emit("click", item.value)
                        }
                      ]
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "autocomplete-result--item-wrap" },
                      [
                        item.icon
                          ? _c(
                              "div",
                              { staticClass: "autocomplete-result--item-icon" },
                              [
                                _c(
                                  item.icon.component,
                                  _vm._b(
                                    { tag: "component" },
                                    "component",
                                    item.icon.props,
                                    false
                                  )
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "autocomplete-result--item-content" },
                          [
                            item.title || item.subtitle
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "autocomplete-result--item-title"
                                  },
                                  [
                                    item.title
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.sanitize(item.title)
                                            )
                                          }
                                        })
                                      : _vm._e(),
                                    item.subtitle
                                      ? [
                                          _vm._v(" ("),
                                          _c("span", {
                                            staticClass: "text-small",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.sanitize(item.subtitle)
                                              )
                                            }
                                          }),
                                          _vm._v(") ")
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e(),
                            item.description
                              ? _c("div", {
                                  staticClass:
                                    "autocomplete-result--item-description text-small",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.sanitize(item.description)
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm.navigationType === _vm.NavigationType.NewWindow
                      ? _c("PhArrowSquareOut", {
                          staticClass:
                            "autocomplete-result--item-navigation-icon",
                          attrs: { size: 18 }
                        })
                      : _vm.navigationType === _vm.NavigationType.CurrentWindow
                      ? _c("PhCaretRight", {
                          staticClass:
                            "autocomplete-result--item-navigation-icon",
                          attrs: { size: 18 }
                        })
                      : _vm.navigationType === _vm.NavigationType.Copy
                      ? _c("PhCopy", {
                          staticClass:
                            "autocomplete-result--item-navigation-icon",
                          attrs: { size: 18 }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            ),
            _vm.hasMoreItems
              ? _c("div", { staticClass: "autocomplete-result--actions" }, [
                  _c(
                    "div",
                    {
                      staticClass: "autocomplete-result--show-more",
                      class: { active: _vm.isShowingMore }
                    },
                    [
                      _c(
                        "BaseButton",
                        {
                          staticClass: "text-small",
                          attrs: { isClear: "" },
                          on: {
                            click: function($event) {
                              return _vm.toggleShowMore()
                            }
                          }
                        },
                        [
                          _c("PhCaretDown", {
                            staticClass: "autocomplete-result--show-more-icon",
                            attrs: { size: 16 }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.isShowingMore ? "Weniger" : "Mehr") +
                              " sehen "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showAdvancedSearch
                    ? _c(
                        "div",
                        { staticClass: "autocomplete-result--advanced-search" },
                        [
                          _c(
                            "BaseButton",
                            {
                              staticClass: "text-small",
                              attrs: { isLink: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("advancedSearch")
                                }
                              }
                            },
                            [
                              _vm._v("Erweiterte Suche "),
                              _c("PhCaretRight", { attrs: { size: 16 } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }