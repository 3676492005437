<template>
  <div>
    <InsuranceOptionMenu title="Vertragsstatus"
      :headerActions="headerActions"
      @action-ADD="createStatus()"
    />

    <div class="row">
      <div class="col-12">
        <BoxContainer>
          <div class="row">
            <div class="col-12">
              <InputField
                v-model="zugehVertrag"
                label="Zugeh. Vertrag"
                :disabled="true"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <Table
                v-if="rows.length"
                :headers="headers"
                :rows="rows"
                :rowsPerPage="10"
                @action-delete="confirmDeleteStatus($event)"
                @click-status="editStatus($event)"
              >
              </Table>

            </div>
          </div>
        </BoxContainer>
      </div>
    </div>
    <DeleteVertragStatusModal ref="deleteModal" @delete="removeVertragStatus()"/>
    <VertragStatusModal ref="statusModal" @save="saveVertragStatus($event)"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VERTRAG_STATUS_TYPES from "@/store/vertragStatus/types";
import BoxContainer from "@/components/core/BoxContainer.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import DeleteVertragStatusModal from './DeleteVertragStatusModal.vue'
import VertragStatusModal from './VertragStatusModal.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn, ActionColumn, SimpleAction, dateToSortable} from "@/components/table2/table_util.js";
import InsuranceOptionMenu from '@/views/versicherungen/insurance-group/InsuranceOptionMenu.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
    BoxContainer,
    InputField,
    BaseButton,
    Table,
    DeleteVertragStatusModal,
    VertragStatusModal,
    InsuranceOptionMenu,
  },
  data() {
    return {
      vertragStatusToDelete: {id: null},
      vertragStatusEdited : {}
    }
  },
  computed: {
    ...mapGetters({
      vertragStatusList: VERTRAG_STATUS_TYPES.GETTERS.VERTRAG_STATUS_LIST,
      vertragStatus: VERTRAG_STATUS_TYPES.GETTERS.STATUS,
    }),
    rows(){
        if (!this.vertragStatusList?.statusList)
            return [];    

      const actions = [SimpleAction("delete", '', "Löschen")];

      return this.vertragStatusList?.statusList.map(item => {
        return {
          id: item.id,
          gueltigab: item.gueltigab,
          status: item.status,
          aenderdetails: item.aenderdetails,
          aendergrund: item.aendergrund,
          datumEingelesen: item.datumEingelesen,
          durchgef: item.durchgef,
          statusBeginn: item.statusBeginn,
          validationMessages: item.validationMessages,
          actions,
        };
      }).sort((a, b) => dateToSortable(b.gueltigab) - dateToSortable(a.gueltigab));
    },
    zugehVertrag() {
      return this.vertragStatusList?.beschreibung || "";
    },
    headers() {
      return {
            lockedLeft: [
                DateTimeColumn("gueltigab", "Gültig ab"),
                TextColumn("status", "Status").makeLink(),
                TextColumn("aenderdetails", "Änderungsdetails"),
                TextColumn("aendergrund", "Änderungsgrund"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        };

    },
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Neuer Status'),
      ]
    },
  },
  methods: {
    createStatus() {
      this.$store.dispatch(VERTRAG_STATUS_TYPES.ACTIONS.VERTRAG_STATUS, {vertragId: this.$route.params.vertragId})
      .then(response => {
        if(response){
          this.vertragStatusEdited = response;
          this.$refs.statusModal.open(response);
        }
      });
    },
    editStatus(row) {
      this.$store.dispatch(VERTRAG_STATUS_TYPES.ACTIONS.VERTRAG_STATUS, {vertragId: this.$route.params.vertragId, statusId: row.id})
      .then(response => {
        if(response) {
          this.vertragStatusEdited = response;
          this.$refs.statusModal.open(response);
        }
      })
    },
    confirmDeleteStatus(event) {
      this.vertragStatusToDelete = event;
      this.$refs.deleteModal.open(event);
    },
    removeVertragStatus(){
       this.$store.dispatch(VERTRAG_STATUS_TYPES.ACTIONS.DELETE_VERTRAG_STATUS, this.vertragStatusToDelete.id)
       .then(response => this.vertragStatusToDelete = null);
    },
    saveVertragStatus(event) {
      const payload = {vertragId: this.$route.params.vertragId, vertragStatus: event };
      const modal = this.$refs.statusModal;
      if(payload.vertragStatus.id) {
        this.$store.dispatch(VERTRAG_STATUS_TYPES.ACTIONS.UPDATE_VERTRAG_STATUS, payload)
        .then(thereAreValidationErrors => {
          this.vertragStatusEdited = null
          if(!thereAreValidationErrors) {
           modal.close(); 
          }
        });
      } else {
        this.$store.dispatch(VERTRAG_STATUS_TYPES.ACTIONS.SAVE_VERTRAG_STATUS, payload)
        .then(thereAreValidationErrors => {
          this.vertragStatusEdited = null
          if(!thereAreValidationErrors) {
           modal.close(); 
          }
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch(VERTRAG_STATUS_TYPES.ACTIONS.VERTRAG_STATUS_LIST, {vertragId: this.$route.params.vertragId})
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(VERTRAG_STATUS_TYPES.MUTATIONS.RESET_DATA);
    next();
  }

}
</script>
