<template>
    <div class="row">
      <div class="mt-2 mb-5">
        <BaseButton @click="hinzufugen()">Hinzufügen</BaseButton>
      </div>

      <Table v-if="rows.length"
          tableId="00479a60-4c16-4305-adc3-32a31d55a9f2"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="20"
          @click-name="openRow($event)"
          @action-delete="confirmDeletePerson($event)"
      />
      <NoData v-else />   
      
      <VertragsPersonenModal ref="vertragsPersonenModal"></VertragsPersonenModal>

      <BaseModal @onConfirmButton="deletePerson()" ref="deletePersonConfirmation" modalTitle="Vertragsperson löschen" labelButtonConfirm="Löschen">
        {{ deletePersonMessage }}
      </BaseModal>

    </div>

    
 
</template>


<script>
import InsuranceDetailMixin from './InsuranceDetail-mixin.js';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseButton from '@/components/core/BaseButton.vue';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import VertragsPersonenModal from './VertragsPersonenModal.vue';
import BaseModal from '@/components/core/BaseModal.vue';

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    GhostLoading,
    NoData,
    Table,
    BaseButton,
    VertragsPersonenModal,
    BaseModal,
},
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      selectedPerson: null,
      deletePersonMessage: null,
    }
  },
  mounted() {
    this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERTRAGPERSONEN_LIST, {vertragId: this.versicherung?.id}).then(response => {
            this.versicherung.vertragsPersonen = response;
          });

  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA, 
      vertragPersonenList: VERSICHERUNG_TYPES.GETTERS.VERTRAGPERSONEN_LIST,
    }),
    rows() {
      const actions = [SimpleAction("delete", '', "Löschen")];

      return this.vertragPersonenList?.map(vp => {
        return {
          id: vp.id,
          rolle: vp.vtgrolle,
          name: vp.person?.name,
          vorname: vp.person?.vorname,
          strasse: vp.person?.addresse?.strasse,
          plz: vp.person?.addresse?.plz,
          ort: vp.person?.addresse?.ort,
          canEdit: vp.canEdit,
          actions: vp.canEdit ? actions : [],
        }
      }) || [];
    },
    headers() {
      return {
            lockedLeft: [
                TextColumn("id", "Id").makeHidden(),
                TextColumn("rolle", "Rolle"),
                TextColumn("name", "Name").makeLink(),
                TextColumn("vorname", "Vorname"),
            ],
            center: [
                TextColumn("strasse", "Straße"),
                TextColumn("plz", "PLZ"),
                TextColumn("ort", "Ort"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],

        };
    }
  },
  methods: {
    openRow(row) {

      if (row?.canEdit) {
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERTRAGSPERSONENDATA, {
        vertragId: this.versicherung?.id,
        vertragsPersonId: row?.id,
        }).then(response => {
          // TODO: Open the popup

          if (response?.availableRoles) {
            this.$refs.vertragsPersonenModal.open();
          }
          

        })  
      }

    },
    confirmDeletePerson(row) {
      this.selectedPerson = row;
      this.deletePersonMessage = `Soll die Vertragsperson ${row.name} wirklich gelöscht werden? (Es wird nur die Zuordnung zum Vertrag gelöscht, die Person selbst bleibt bei den Personendaten erhalten.)`;
      this.$refs.deletePersonConfirmation.open();

    },
    deletePerson() {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.DELETE_VERTRAG_PERSON, {
        vertragsPersonId: this.selectedPerson?.id,
        }).then((response) => {

          if (response?.key)
          this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERTRAGPERSONEN_LIST, {vertragId: this.versicherung?.id});          

        })       
    },
    hinzufugen() {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERTRAGSPERSONENDATA, {
        vertragId: this.versicherung?.id,
        vertragsPersonId: null,
        }).then(response => {
          // TODO: Open the popup

          if (response?.availableRoles) {
            this.$refs.vertragsPersonenModal.open();
          }
          

        }) 

    },
  }
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>