<template>
<div>
    <div class="box__title">Abrechnungen</div>

    <Table v-if="!loading && rows.length"
        tableId="2e6961b2-7f82-4d53-809c-b67ecc15f500"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        @action-VIEW_PROV_STRUKTUR="selectBuchung"
    />
    <div v-else-if="loading" class="text-centered"><AnimatedSpinner /></div>
    <div v-else>Keine Daten</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';
import CORE_TYPES from '@/store/core/types';

import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, CurrencyColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';


export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      buchungen: OTHER_PRODUCTS_TYPES.GETTERS.BUCHUNGEN,
      combos: OTHER_PRODUCTS_TYPES.GETTERS.COMBOS,
      isBrokerMaster: CORE_TYPES.GETTERS.IS_BROKER_MASTER,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    allowEditCourtage() {
      return this.isBrokerMaster && this.combos?.allowReadVertragAbrechnung || this.isIntern;
    },
    headers() {
        const headers = {
            lockedLeft: [
                TextColumn("buchungsnr", "Buchungsnr"),
            ],
            center: [
                DateColumn("datum", "Datum"),
                DateColumn("courtageeingDatum", "Prov. Eingang"),
                TextColumn("courtageart", "Prov. Art"),
                CurrencyColumn("bezugssumme", "Bez.Summe"),
                TextColumn("status", "Status"),
                DateColumn("rechDatum", "Abr. Datum"),
                TextColumn("bemerkung", "Bemerkung"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        };
        if (this.allowEditCourtage) {
            headers.center.splice(4, 0, CurrencyColumn("courtagesumme", "Courtagesumme"));
            headers.center.splice(6, 0, TextColumn("automatisch", "Auto"));
        }
        return headers;
    },
    rows() {
        const actions = [
            SimpleAction("VIEW_PROV_STRUKTUR", '', "Verteilung der Abrechnung auf die Struktur"),
        ];
        return (this.buchungen || []).map(row => ({
            ...row,
            actions: !row.allowModify ? actions : undefined,
        }));
    },
  },
  methods: {
    async findBuchungen() {
      this.loading = true;
      await this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_BUCHUNGEN);
      this.loading = false;
    },
    selectBuchung(row) {
        this.$store.commit(OTHER_PRODUCTS_TYPES.MUTATIONS.SET_SELECTED_BUCHUNG, row);
    },
  },
  mounted() {
    this.findBuchungen();
  },
  components: {
    Table,
    AnimatedSpinner,
  },
}
</script>
