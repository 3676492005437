import { getMostContrastingColorWhiteBlackByContrast, getRgbDesaturated } from "../helpers/colors-helper";
import store from '../store'
import CORE_TYPES from '../store/core/types'
import axios from 'axios';

export const ColorSchemeChangedEvent = {
  name: 'color-scheme-changed',
  dispatch() {
    const { name: eventName } = this;
    window.dispatchEvent(new CustomEvent(eventName));
  },
}

const defaultColorScheme = {
  ['--logo-url']: '',

  ['--color-background']: '#fefefe',
  ['--color-text']: '#001833',
  ['--color-link']: '#0071F2',
  ['--color-box']: '#ffffff',
  ['--color-box-neutral']: '#efefef',
  ['--color-text-neutral']: '#111111',

  ['--color-primary']: '#0071F2',
  ['--color-primary-text']: '',

  ['--color-secondary']: '#FD9900',
  ['--color-secondary-text']: '#000000',

  ['--color-success']: '#64BC26',
  ['--color-warning']: '#FAD202',
  ['--color-info']: '#0071F2',
  ['--color-danger']: '#EA1601',

  ['--color-active']: '#0071F2',

  ['--color-top-nav-background']: '#0071F2',
  ['--color-top-nav-text']: '#fff',
  ['--color-top-nav-active']: '#FD9900',

  ['--color-workspaces-nav-background']: '#ffffff',
  ['--color-workspaces-nav-text']: '#000000',
  ['--color-workspaces-nav-active']: '#0071F2',

  ['--color-background-secondary']: '#ffffff',

  ['--disabled-text']:'#ffffff',

  ['--big-table-header']: '#F7F8F9',
  ['--big-table-active-row']: '#E9F2FF',
  ['--big-table-highlighted-row']: '#F1F2F4',
  ['--big-table-column-border']: '#DCDFE4',
  ['--big-table-highlighted-column-border']: '#758195',
  ['--big-table-header-text']:'#4454GF',
  ['--big-table-scrollbar']:'#A8A8A8',
  ['--big-table-dropdown-left-border']: '#0C66E4',
  ['--big-table-dropdown-color-success-background']: '#dcfff1',
  ['--big-table-dropdown-color-info-background']: '#e9f2ff',
  ['--big-table-dropdown-color-danger-background']: '#F8EDE3',
  ['--big-table-dropdown-color-default-foreground']: '#000000',
  ['--big-table-dropdown-color-default-background']: '#F7F8F9',
  

}


const defaultColorSchemeDarkMode = {
  ['--logo-url']: '',

  ['--color-background']: '#111111',
  ['--color-text']: '#222222',
  ['--color-link']: '#444444',
  ['--color-box']: '#2d2d2d',
  ['--color-box-neutral']: '#111111',
  ['--color-text-neutral']: '#111111',

  ['--color-primary']: '#aaaaaa',
  ['--color-primary-text']: '',

  ['--color-secondary']: '#222222',
  ['--color-secondary-text']: '#edeaea',

  ['--color-success']: '#23923d',
  ['--color-warning']: '#f39c12',
  ['--color-info']: '#007bff',
  ['--color-danger']: '#d32535',

  ['--color-active']: '#BFBFBF',

  ['--color-top-nav-background']: '#111111',
  ['--color-top-nav-text']: '#ffffff',
  ['--color-top-nav-active']: '#444444',

  ['--color-workspaces-nav-background']: '#111111',
  ['--color-workspaces-nav-text']: '#bfbfbf',
  ['--color-workspaces-nav-active']: '#ffffff',

  ['--color-background-secondary']: '#454545',

  ['--disabled-text']:'#ffffff',

  ['--big-table-header']: '#454545',
  ['--big-table-active-row']: '#666666',
  ['--big-table-highlighted-row']: '#454545',
  ['--big-table-column-border']: '#111111',
  ['--big-table-highlighted-column-border']: '#DCDFE4',
  ['--big-table-header-text']:'#4454GF',
  ['--big-table-scrollbar']:'#A8A8A8',
  ['--big-table-dropdown-left-border']: '#DCDFE4',
  ['--big-table-dropdown-color-success-background']: '#DCDFE4',
  ['--big-table-dropdown-color-info-background']: '#DCDFE4',
  ['--big-table-dropdown-color-danger-background']: '#DCDFE4',
  ['--big-table-dropdown-color-default-foreground']: '#ffffff',
  ['--big-table-dropdown-color-default-background']: '#454545',    

}

/**
 * deletes [data-loading-theme] from <html> tag
 */
function deleteDataLoadingTheme() {
  if ('loadingTheme' in (document?.documentElement?.dataset || {})) {
    delete document.documentElement.dataset.loadingTheme;
  }
}

function setFont(colorScheme, element) {
  if (colorScheme['--font-name']) {
    document.body.style.fontFamily = colorScheme['--font-name'];
  }
  return true;
}

function setTextColorPrimarySecondary(cs, element) {
  if (cs['--color-primary'] && !cs['--color-primary-text']) {
    const primaryText = getMostContrastingColorWhiteBlackByContrast(cs['--color-primary']);
    element.style.setProperty('--color-primary-text', primaryText);
  }

  if (cs['--color-secondary'] && !cs['--color-secondary-text']) {
    const secondaryText = getMostContrastingColorWhiteBlackByContrast(cs['--color-secondary']);
    element.style.setProperty('--color-secondary-text', secondaryText);
  }
}

function setBackgroundDesaturatedColorsForSuccessWarnInfoDangerColors(colorScheme, element) {
  const generateColors = () => {
    if (colorScheme['--color-success']) {
      const desaturated = getRgbDesaturated(colorScheme['--color-success'])
      element.style.setProperty('--color-success-background', desaturated);
    }
    if (colorScheme['--color-warning']) {
      const desaturated = getRgbDesaturated(colorScheme['--color-warning'])
      element.style.setProperty('--color-warning-background', desaturated);
    }
    if (colorScheme['--color-info']) {
      const desaturated = getRgbDesaturated(colorScheme['--color-info'])
      element.style.setProperty('--color-info-background', desaturated);
    }
    if (colorScheme['--color-danger']) {
      const desaturated = getRgbDesaturated(colorScheme['--color-danger'])
      element.style.setProperty('--color-danger-background', desaturated);
    }
  };

  if (colorScheme?.['--scheme'] === 'contrast') {
    element.style.setProperty('--color-success-background', '#D9D9D9');
    element.style.setProperty('--color-warning-background', '#D9D9D9');
    element.style.setProperty('--color-info-background', '#D9D9D9');
    element.style.setProperty('--color-danger-background', '#D9D9D9');
  } else {
    generateColors();
  }
}

export function loadDefaultColorsScheme() {
  store.dispatch(CORE_TYPES.ACTIONS.LOAD_COLOR_SCHEMA, { light: defaultColorScheme, dark: defaultColorSchemeDarkMode });
}

export function setFavicon(layoutParamsFC) {
  let url = layoutParamsFC['--favicon-url'];

  if (url) {
    url = process.env.VUE_APP_API + '/../../' + url;

    axios.get(url).then(result => {
      if (result && result.status && result.status === 200) {
        const link = document.querySelector("link[rel='shortcut icon']");
        if (link) {
          link.setAttribute('href', url);
          document.getElementsByTagName('head')[0].appendChild(link);
        }
      }
    }).catch(() => { });
  }
}

export function setMaklerLogo(layoutParamsFC) {
  let url = layoutParamsFC['--logo-url'];

  if (!url) {
    if (layoutParamsFC['--firma'] === 'FA') {
      url = 'images/etc/layout/finanzadmin_logo_fc.png';
    } else {
      url = layoutParamsFC['--scheme'] === 'dark' 
        ? 'images/etc/layout/Logo-smartMSC-weiss.png'
        : 'images/etc/layout/Logo-smartMSC.png';
    }
  }

  if (url) {
    const relativeLinkUrl = new RegExp('^(?!www.|(?:http)s?://|[A-Za-z]:\\|//).*', 'gm')

    if (relativeLinkUrl.test(url)) {
      url = process.env.VUE_APP_API + '/../../' + url;
    }
  }

  store.commit(CORE_TYPES.MUTATIONS.UPDATE_BROKER_LOGO, url);
}

export function setLoginWelcomeTitle(layoutParamsFC) {
  let loginWelcomeTitle = layoutParamsFC['--login-welcome-title'];

  if (loginWelcomeTitle) {
    store.commit(CORE_TYPES.MUTATIONS.UPDATE_LOGIN_WELCOME_TITLE, loginWelcomeTitle);
  }
}

export function setImageBackgroundLoginUrl(layoutParamsFC) {
  let url = layoutParamsFC['--image--background-login-url'];

  if (url) {
    url = process.env.VUE_APP_API + '/../../' + url;
  }

  store.commit(CORE_TYPES.MUTATIONS.UPDATE_BROKER_IMAGE_BACKGROUND_LOGIN_URL, url);
}

export function getActiveColorScheme() {
  // from add parameter
  const configuredColorScheme = store.getters[CORE_TYPES.GETTERS.CONFIGURED_COLOR_SCHEME];
  if (configuredColorScheme) return configuredColorScheme;

  // from query parameter (mobile native context)
  const preferredColorScheme = store.getters[CORE_TYPES.GETTERS.PREFERRED_COLOR_SCHEMA];
  if (preferredColorScheme) return preferredColorScheme;

  // from system preference
  return window.matchMedia(`(prefers-color-scheme: dark)`).matches ? 'dark' : 'light';
}

export function loadColorScheme(layoutParamsFC, querySelector) {
  if (!layoutParamsFC) {
    return
  }
  
  // the following keys doesn't need to be setted here, they will be handle on another place
  const ignoredKeys = ['--font-url', '--scheme', '--favicon-url', '--ksc-url', '--font-size'];

  let element = querySelector && document.querySelector(querySelector) 
    ? document.querySelector(querySelector) 
    : document.documentElement;

  const defaultScheme = getActiveColorScheme() === 'dark'
    ? defaultColorSchemeDarkMode
    : defaultColorScheme;

  const schemeKeys = Object.keys(Object.assign({}, defaultScheme, layoutParamsFC));
  schemeKeys.filter(key => !ignoredKeys.includes(key)).forEach(key => {
    if (!layoutParamsFC[key] && defaultScheme[key]) {
      layoutParamsFC[key] = defaultScheme[key];
    }

    if (layoutParamsFC[key]) {
      element.style.setProperty(key, layoutParamsFC[key]);
    }
  });

  setTextColorPrimarySecondary(layoutParamsFC, element);
  setFont(layoutParamsFC, element);
  setBackgroundDesaturatedColorsForSuccessWarnInfoDangerColors(layoutParamsFC, element);
  setFavicon(layoutParamsFC);
  setMaklerLogo(layoutParamsFC);
  setLoginWelcomeTitle(layoutParamsFC);
  setImageBackgroundLoginUrl(layoutParamsFC);
}

export function setColorScheme() {
  const setColorSchemeHandler = () => {
    const colorSchemes = store.getters[CORE_TYPES.GETTERS.GET_COLOR_SCHEMA];
    const colorScheme = getActiveColorScheme();
    if (colorScheme && colorScheme in colorSchemes) {
      loadColorScheme(colorSchemes[colorScheme]?.layout);
      ColorSchemeChangedEvent.dispatch();
    }

    deleteDataLoadingTheme();
  };

  requestAnimationFrame(setColorSchemeHandler);
}

export function getColorPropsUpdateSettings(layoutParamsFC) {
  return {
    "color-light-top-nav-background": layoutParamsFC?.light?.['--color-top-nav-background'] || defaultColorScheme?.['--color-top-nav-background'],
    "color-light-top-nav-text": layoutParamsFC?.light?.['--color-top-nav-text'] || defaultColorScheme?.['--color-top-nav-text'],
    "color-dark-top-nav-background": layoutParamsFC?.dark?.['--color-top-nav-background'] || defaultColorSchemeDarkMode?.['--color-top-nav-background'],
    "color-dark-top-nav-text": layoutParamsFC?.dark?.['--color-top-nav-text'] || defaultColorSchemeDarkMode?.['--color-top-nav-text'],
  }
}

export function getColorOfActiveScheme(name){
  return getActiveColorScheme() === 'dark' ? defaultColorSchemeDarkMode[name] : defaultColorScheme[name]
}