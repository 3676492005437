var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container-wrap" },
    [
      _vm.contactPerson
        ? [
            _c("div", { staticClass: "box__container-rows" }, [
              _vm.contactPerson
                ? _c("div", { staticClass: "agent-contact__container" }, [
                    _c("div", { staticClass: "agent-contact--info" }, [
                      _c("div", [_vm._v(_vm._s(_vm.contactPerson.name))]),
                      _vm._m(0),
                      _c("div", [_vm._v(_vm._s(_vm.contactPerson.street))]),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.contactPerson.zip) +
                            " " +
                            _vm._s(_vm.contactPerson.city)
                        )
                      ]),
                      _vm._m(1),
                      _vm.contactPerson.phone
                        ? _c("div", [
                            _vm._v(
                              "Telefon: " + _vm._s(_vm.contactPerson.phone)
                            )
                          ])
                        : _vm._e(),
                      _vm.contactPerson.mobile &&
                      _vm.contactPerson.isMobilePublic
                        ? _c("div", [
                            _vm._v("Mobil: " + _vm._s(_vm.contactPerson.mobile))
                          ])
                        : _vm._e(),
                      _vm.contactPerson.email &&
                      _vm.getEmail(_vm.contactPerson.email)
                        ? _c(
                            "div",
                            [
                              _c("nobr", [_vm._v("E-Mail")]),
                              _vm._v(
                                ": " +
                                  _vm._s(_vm.getEmail(_vm.contactPerson.email))
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.contactPerson.webPage
                        ? _c("div", [
                            _c(
                              "a",
                              { attrs: { href: _vm.contactPerson.webPage } },
                              [_vm._v(_vm._s(_vm.contactPerson.webPage))]
                            )
                          ])
                        : _vm._e(),
                      _vm._m(2)
                    ]),
                    _c(
                      "div",
                      { staticClass: "agent-contact--img my-3 ml-2" },
                      [
                        _c("UserPicture", {
                          staticClass: "profile-menu-burger__customer--picture",
                          attrs: {
                            size: "large",
                            useParamsImage: "",
                            image: _vm.contactPictureUrl,
                            ariaDescription: "Profilbild des Maklers"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]),
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between agent-contact__actions"
              },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: { isLink: "" },
                    on: {
                      click: function($event) {
                        _vm.newTicket()
                        _vm.$emit("click")
                      }
                    }
                  },
                  [_vm._v("Nachricht senden")]
                ),
                _c("PhoneButton", {
                  attrs: { showAsButton: false },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click")
                    }
                  }
                })
              ],
              1
            )
          ]
        : !_vm.contactPerson
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : _c("NoData", { attrs: { noIcon: "" } })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("br")])
  }
]
render._withStripped = true

export { render, staticRenderFns }