<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div class="input-forms__label-content input-forms__label-content--bigger" v-if="title">
            <ph-bank :size="16" />&nbsp;{{title}}
          </div>
        </div>
        <div class="col-auto" v-if="config && config.buttonText">
          <BaseButton @click="openFormsFinder">{{config.buttonText}}</BaseButton>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container" v-if="positions && positions.length">
        <Table  v-if="rows.length"
            :headers="headers"
            :rows="rows"
            rowId="index"
            hidePagination
            @action-DELETE="openModalDeleteFond"
        >
            <template v-slot:einmalBetrag="row">       
                <InputField 
                    type="currency"  :precision="2" :value="row.einmalBetrag"
                    :id="'einmalBetrag' + row.index"
                    placeholder="mind. 500,-EUR"
                    :disabled="disabled"
                    @input="onChangeInputField($event, row, 'einmalBetrag')"
                />
            </template>
    
            <template v-slot:sparplanBetrag="row">       
                <InputField type="currency" 
                    :precision="2" :value="row.sparplanBetrag"
                    :id="'sparplanBetrag' + row.index"
                    :disabled="disabled"
                    placeholder="mind. 10,-EUR"
                    @input="onChangeInputField($event, row, 'sparplanBetrag')"
                />
            </template>
        </Table>
      <div v-if="config.mitSummenbildung && this.positions && this.positions.length">
         <Summenbildung :antragId="antragId" :summe="summe" :summeSpar="summeSparplan" :mitSparplan="true" />
      </div> 
      <WertpapierAuswahlPositionFields class="mt-3" v-if="positions && positions.length && config && config.positionFields"
          :config="config"
          :categoryId="categoryId"
          :antragId="antragId" 
      />
      <BaseModal ref="fehlerModal" labelButtonCancel="Ok" :showConfirmButton="false">
        <template v-slot:modalTitle>
          <ph-warning :size="16" class="color-danger mr-2" />
          <span class="color-danger">Fehler</span>
        </template>
        
        Fehler: Sie dürfen nur {{config.anzahlFonds}} Positionen wählen.
      </BaseModal>

    </div>
    <div v-else>
      <div>Wertpapierauswahl ist leer</div>
    </div>

    

  <DeletePositionModal ref="deleteModal" :position="positionToDelete"
    @delete="doDeletePosition()"></DeletePositionModal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, SlotColumn} from "@/components/table2/table_util.js";
import InputField from '@/components/core/forms/InputField.vue';
import WertpapierAuswahlPositionFields from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue';
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import BaseButton from '@/components/core/BaseButton.vue';
import validator from "@/mixins/validator";
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import { minValue } from "@/mixins/validator/rules";
import BaseModal from '@/components/core/BaseModal.vue';
import { PhBank, PhWarning} from 'phosphor-vue';
import Summenbildung from '@/components/antrag/Summenbildung.vue';

export default {
  mixins: [antragNavigationMixin, antragMixin, validator],
  validators: {},
  components: {
    Table,
    InputField,
    WertpapierAuswahlPositionFields,
    BaseButton,
    BaseModal,
    DeletePositionModal,
    Summenbildung,
    PhBank,
    PhWarning
  },
  props: {
    title: {
    },
    categoryId: {
    },
    antragId: {
    },
    antragTyp: {
    },
    config: {
    },
    disabled:{
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.configureValidators();
  },
  data () {
    return {
      advices: [],
      dateValues: [{label: '1.', value: '1.'}, {label: '15.', value: '15.'}],
      modusAbbuchungValues: [{value: '1', label: 'soll sofort vom Konto (angegeben unter "Sonstiges" abgebucht werden'},
                              {value: '3', label: 'wird von mir überwiesen'},
                              {value: '2', label: 'soll abgebucht werden am'}],
      form: {
        einmalModusAbbuchung: '',
        einmalDatumAbbuchung: '',
        planTag: '',
        planFrequenz: '',
        sparplanBeginnDatum: '',
        sparplanDynamik: '',
        sparplanDynamikText: '',
        istSparplanErlaubt: true,
      },
      positionToDelete : {},
      summeSparplan: 0,
      summe:0,
    }
  },
 watch: {
    positionWarnings: {
      immediate: true,
      handler(newWarnings) {
        if (newWarnings) {
          for (const warn of newWarnings) {
            if (warn.id && warn.id.includes('§§')) {
              // arr[0] = componentId, arr[1] = posGrpId or ISIN
              const arr = warn.id.split('§§');
              if (arr[0] && arr[1]) {
                const index = this.positions.findIndex(pos => pos.posGrpId === arr[1] || pos.isin === arr[1]);
                if (index >= 0) {
                  this.$pushErrors(arr[0] + index, warn.message || '');
                }
              }
            }
          }
        }
      }
    },
    rows: {
      handler() {
        let sumKauf = 0;
        let sumSparplan = 0;
         if(this.rows && this.rows.length){
           this.rows.forEach(r=> {
             if(r.einmalBetrag){
               sumKauf+=r.einmalBetrag;
             }
             if(r.sparplanBetrag){
                sumSparplan+= r.sparplanBetrag;
             }
             })
         }
          this.summe = sumKauf;
          this.summeSparplan = sumSparplan;
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE
    }),
    headers() {
        const result = {
            lockedLeft: [
                TextColumn("isin", "ISIN"),
                TextColumn("fondsname", "Fondsname"),
            ],
            lockedRight: [
                ActionColumn("actions", ""),
            ],
        };
        if (!this.config.istCollect && !this.config.istBAVDE) {
            result.lockedLeft.push(SlotColumn("einmalBetrag", "Einmalanlage"));
            result.lockedLeft.push(SlotColumn("sparplanBetrag", "Sparplan"));
        }
        return result;
    },
    rows() {
        const actions = [
            SimpleAction("DELETE", "", "Löschen"),
        ];
        return this.positions.map(row => ({
            ...row,
            actions,
        }));
    },
    positions() {
      if (this.positionsAll && this.antragId && this.positionsAll[this.antragId]) {
        const positions = (this.positionsAll[this.antragId]['KAUF'] || []);
        // Object.keys(this.positionsAll[this.antragId]).forEach(category => positions.push(...this.positionsAll[this.antragId][category] || []));
        positions.map((pos, index) => Object.assign(pos, {index: index, fondsname: this.getFondsName(pos), lagerstelle: 'EBASE'}));

        return positions;
      } else {
        return [];
      }
    },
    positionWarnings() {
      return this.antraege && this.antraege[this.antragId] && this.antraege[this.antragId].warnings && this.antraege[this.antragId].warnings.positionWarnings || [];
    },
  },
  methods: {
    configureValidators() {
      if (this.positions && this.positions.length) {
        const valConfig = {};
        this.positions.forEach((pos, index) => {
          if(pos.einmalBetrag != null){
            valConfig['einmalBetrag' + index] = [minValue(500, 'Mindestbetrag 500 EUR')];
          }
          if(pos.sparplanBetrag != null){
             valConfig['sparplanBetrag' + index] = [minValue(10, 'Mindestbetrag 10 EUR')];
          }
         
        })
        this.$configureValidators(valConfig);
      }
    },
    getFondsName(pos) {
      return pos && this.positionInfo && this.positionInfo[pos.isin] && this.positionInfo[pos.isin].wertpapiername || pos.fondsname || pos.wertpapiername;
    },
    hasSparplan(pos) {
      // hasSparplan is wrong --> under Gesellschaft isFormularSparplanAvailableAndPossible       
      return pos && this.positionInfo && this.positionInfo[pos.isin]; //&& this.positionInfo[pos.isin].hasSparplan;
    },
    openFormsFinder() {
      if(this.config.istCollect){
         if (this.positions == undefined || this.positions.length < 4) {
          this.goToFormsFinder(this.categoryId, this.config, this.suppressFilterLagerstelle);
        } else if (this.positions.length >= 4) {
          this.$refs.fehlerModal.open();
        } 
      }else if(this.config.istBAVDE){
   if (this.positions == undefined || this.positions.length < 5) {
          this.goToFormsFinder(this.categoryId, this.config, this.suppressFilterLagerstelle);
        } else if (this.positions.length >= 5) {
          this.$refs.fehlerModal.open();
        } 
        }else{
       this.goToFormsFinder(this.categoryId, this.config, this.suppressFilterLagerstelle);
      }
     
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      
        const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)    
      
    },
    onChangeInputField($event, position, field) { 
      if((field == 'einmalBetrag' || field == 'sparplanBetrag') && $event == ''){
        $event = null; //to avoid Empty String exception
      }
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
    
      if (field === 'monat' || field === 'jahr') {
        const abDatum = position.abDatum ? position.abDatum.split('.') : ['', ''];
        if (field === 'monat') {
          abDatum[0] = $event;
        } else if (field === 'jahr') {
          abDatum[1] = $event;
        }
        const newDatum = { abDatum: abDatum.join('.') };
        this.commitUpdatePositionsFromEvent(newDatum, position.isin)
      }
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open()
    },
    doDeletePosition() {
      this.deletePosition(this.antragId, this.categoryId, this.positionToDelete);
      this.positionToDelete = null;
    },
    async checkIfSparplanAvailable(row){
      const payload = {isin: row.isin, lagId:'EBASE'}
      let response = await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.IS_SPARPLAN_AVAILABLE, payload);
      if(response && response.data){
        return response.data == 'Ja';
      }
    }
  }
}
</script>

<style scoped>
  .warning {
    color: var(--color-danger);
    font-size: smaller;
  }
</style>