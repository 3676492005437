<template>
  <div class="circle">
    <AnimatedSpinner v-if="isCounting" />
    <template v-else>
      {{count}}
    </template>
  </div>
</template>

<script>

import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import STECKBRIEF_TYPES from '@/components/steckbrief/store/types';
import { mapGetters } from 'vuex'
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue'

export default {
  computed: {
    ...mapGetters({
      openSignatures: OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS,
      countOpenSigns: OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS_COUNT,
      countVideoIndent: STECKBRIEF_TYPES.GETTERS.TODO_VIDEO_INDENT_COUNT,
      isCounting: OPEN_SIGNS_TYPES.GETTERS.IS_COUNTING,
    }),
    count() {
      return this.countOpenSigns + this.countVideoIndent;
    },
  },
  mounted() {
    if (!this.openSignatures?.offeneUnterschriften) {
      this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS_COUNT);
    }
    this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.GET_TODO_VIDEO_INDENT_COUNT);
  },
  components: {
    AnimatedSpinner
  }
}
</script>
<style scoped>
  .circle {
    background: var(--color-primary);
    color: var(--color-primary-text);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-size: min(20px, 1em);
    text-align: center;
    padding: 0;
    margin: 0;
    align-self: center;
  }
</style>
