<template>
  <div>
    <DepotpositionsNavigation :title="depotTitle" subtitle="Beteiligungen" :headerActions="headerActions" />

    <div class="row space-between" v-if="!$isSmallScreen">
      <div class="flex-item" v-for="(sumItem, index) of resultSums" :key="index" >
        <BoxContainer class="text-center" :tid="_generateTidFromString('sum' + index)">
          <span class="text-small mp-0">{{sumItem.label}}</span><br>
          <span v-if="sumItem.type.includes('currency')"><CurrencyLabel :value="sumItem.value"/></span>
          <span v-else>{{sumItem.value}}</span>
        </BoxContainer>
      </div>
    </div>
    <div v-else class="box__container">
      <template v-for="(sumItem, index) of resultSums">
        <MobileInfoRow
          :key="index"
          :label="sumItem.label"
        >

          <template #value >
            <span v-if="sumItem.type.includes('currency')"><CurrencyLabel :value="sumItem.value"/></span>
            <span v-else>{{sumItem.value}}</span>
          </template>
        </MobileInfoRow>
      </template>
    </div>

    <div class="box__container" tid="479e6147-3aa6-45b1-a2da-fae77eccae52">
      <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
      <Table
        v-else
        tableId="6eef4684-474d-4080-af40-51309952a843"
        rowId="bgsNr"
        :title="TABLE_TITLE"
        :headers="headers"
        :rows="rows"
        hidePagination
        :mobileConfig="mobileConfig"
        @click-fondsname="navigateToFondsinfo"
        @action-TRANSACTIONS="navigateToTransactions"
      />
    </div>
  </div>
</template>

<script>
import BoxContainer from '@/components/core/BoxContainer.vue';
import CORE_TYPES from '@/store/core/types';
import BETEILIGUNGEN_TYPES from "@/store/beteiligungen/types";
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";
import { mapGetters } from 'vuex';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import FinancialCalculator from '@/components/retirementScenario/financialCalculator';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import DepotpositionsNavigation from '@/components/depotpositions/DepotpositionsNavigation.vue';
import { PageHeaderPortfolioSelectorAction, } from '@/components/core/header-title-navigation/page-header-utils';
import MobileInfoRow from "@/components/core/MobileInfoRow.vue";
import Table from "@/components/table2/Table.vue";
import { TextColumn, NumberColumn, DateColumn, CurrencyColumn, ActionColumn, SimpleAction } from "@/components/table2/table_util.js";

const TABLE_TITLE = 'Beteiligungen';

export default {
  components: {
    BoxContainer,
    Table,
    CurrencyLabel,
    GhostLoading,
    DepotpositionsNavigation,
    MobileInfoRow,
  },
  mixins: [InteractiveHelpCommonsMixin],
  data() {
    return {
      TABLE_TITLE,
      selectedFund: null,
      loading: false,
      mobileConfig: {
        title: 'fondsname',
        headers: ['gesellschaftsname', 'anlagesumme']
      },
    }
  },
  computed: {
    ...mapGetters({
      beteiligungen: BETEILIGUNGEN_TYPES.GETTERS.BETEILIGUNGEN,
      depotTitle: DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_DEPOT_TITLE,
      depotId: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    headerActions() {
      return [
        PageHeaderPortfolioSelectorAction('SELECT-DEPOT'),
      ];
    },
    headers() {
      return {
          lockedLeft: [],
          center: [
              TextColumn("gesellschaftsname", "Gesellschaft"),
              TextColumn("fondsname", "Name").makeLink(),
              ...(this.isFA ? [TextColumn("kontonr", "Vertragsnr")] : []),
              TextColumn("kategorie", "Fondskategorie"),
              NumberColumn("risikoklasse", "SRI"),
              DateColumn("antragsdatum", "Antragsdatum"),
              CurrencyColumn("anlagesumme", "Betrag").withSumFooter(),
          ],
          lockedRight: [
            ActionColumn("actions", "Aktionen"),
          ],
      };
    },
    rows() {
      const headers = (this.beteiligungen?.tableHeaders || [])
        .reduce((acc, curr) => ({...acc, ...({[curr.key] : curr})}), {});;

      const headersKeys = Object.keys(headers);
      const records = (this.beteiligungen?.records || []).map(tuple => {
        return tuple.reduce((acc, curr, index) => ({...acc, ...({[headersKeys[index]] : curr})}), {});
      });

      return records.map(record => ({
        ...record,
        isin: JSON.parse(record.bgs_nr?.bodyParams)?.isin,
        bgsNr: JSON.parse(record.bgs_nr?.bodyParams)?.bgsNr,
        actions: [
          SimpleAction("TRANSACTIONS", '', "Transaktionen"),
        ]
      }))
    },
    columnActions() {
      return [
        {
          legend: {
            icon: 'PhArrowsLeftRight',
            index: 1,
            key: 'TRANSACTIONS',
            label: 'Transaktionen',
          }
        },
      ]
    },
    resultSums() {
       if(this.beteiligungen?.resultSums){
         const sumDetails = Object.entries(this.beteiligungen?.resultSums).reduce((obj, [key, value]) => {
           if(key !== 'LABEL_SALDO_BESTAND' && key !== 'LABEL_DEPOT_WERTENTWICKLUNG'){
             obj[key] =  value
           }
           return obj;
         }, {});
          return Object.values(sumDetails).map(arr => ({'label' : arr[0], 'value' : this.convertStringToNumber(arr[1].replace(' €', '')), 'type':'currency'}));
       }
      return [];
    },
  },
  watch: {
    depotId() {
      this.findBeteiligungen();
    },
  },
  mounted() {
    this.findBeteiligungen();
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from, breadcrumb: this.selectedFund});
    this.selectedFund = null;
    next();
  },
  methods: {
    async findBeteiligungen() {
      this.loading = true;
      try {
        await this.$store.dispatch(BETEILIGUNGEN_TYPES.ACTIONS.GET_BETEILIGUNGEN, { depotId: this.depotId, });
      } finally {
        this.loading = false;
      }
    },
    navigateToFondsinfo(row) {
      this.selectedFund = row.fondsname;
      this.$router.push({path: `/shared/beteiligungen/fondsinfo/${row.isin}/${row.bgsNr}`});
    },
    navigateToTransactions(row) {
      this.$router.push({path: `/home/berichte/transaktionen/beteiligungen?params={"isin":"${row.isin}"}`});
    },
    convertStringToNumber(value){
      return FinancialCalculator.convertStringToNumber(value)
    },
  }
}
</script>

<style scoped>
.space-between {
  justify-content: space-between;
}
.flex-item {
  flex: 1 1 auto;
}

.text-center {
  text-align: center;
}
</style>