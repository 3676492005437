var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "sticky",
          rawName: "v-sticky",
          value: _vm.isSticky,
          expression: "isSticky"
        }
      ],
      staticClass: "page-header-title-nav__container",
      class: {
        "is-title-wrapped": _vm.isTitleWrapped,
        "hide-primary-action": _vm.hidePrimaryAction,
        "is-secondary": _vm.isSecondary
      }
    },
    [
      _c(
        "div",
        { staticClass: "page-header-title-nav__body" },
        [
          _c(
            "PageHeaderTitleNavigationTitle",
            _vm._g(
              _vm._b(
                {
                  scopedSlots: _vm._u(
                    [
                      _vm.$slots.title || _vm.title
                        ? {
                            key: "title",
                            fn: function() {
                              return [_vm._t("title")]
                            },
                            proxy: true
                          }
                        : null,
                      _vm.$slots.subtitle || _vm.subtitle
                        ? {
                            key: "subtitle",
                            fn: function() {
                              return [_vm._t("subtitle")]
                            },
                            proxy: true
                          }
                        : null
                    ],
                    null,
                    true
                  )
                },
                "PageHeaderTitleNavigationTitle",
                _vm.$props,
                false
              ),
              _vm.$listeners
            )
          ),
          _c(
            "PageHeaderTitleNavigationTitle",
            _vm._g(
              _vm._b(
                {
                  attrs: { isWrapped: "" },
                  scopedSlots: _vm._u(
                    [
                      _vm.$slots.title || _vm.title
                        ? {
                            key: "title",
                            fn: function() {
                              return [_vm._t("title")]
                            },
                            proxy: true
                          }
                        : null,
                      _vm.$slots.subtitle || _vm.subtitle
                        ? {
                            key: "subtitle",
                            fn: function() {
                              return [_vm._t("subtitle")]
                            },
                            proxy: true
                          }
                        : null
                    ],
                    null,
                    true
                  )
                },
                "PageHeaderTitleNavigationTitle",
                _vm.$props,
                false
              ),
              _vm.$listeners
            )
          ),
          _c(
            "div",
            {
              staticClass:
                "page-header-title-nav__action-container no-line-height"
            },
            [
              _vm.hasNavigationValues && !_vm.showNavigationInActionsContext
                ? _c(
                    "div",
                    {
                      staticClass: "page-header-title-nav__navigation-container"
                    },
                    [
                      _c(
                        "BaseButtonsContainer",
                        {
                          staticClass:
                            "page-header-title-nav__buttons-container",
                          attrs: {
                            primaryClass: ".nav-item-bold",
                            btnAsLink: "",
                            minItemsRequired: 1
                          }
                        },
                        _vm._l(_vm.navigationValues, function(navItem, index) {
                          return _c("PageHeaderTitleNavigationNavItem", {
                            key: navItem.path + "-" + index,
                            staticClass: "page-header-title-nav--item",
                            attrs: { navItem: navItem }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showNavigationInActionsContext || _vm.hasActions
                ? _c(
                    "div",
                    {
                      staticClass:
                        "page-header-title-nav__action-button-container",
                      class: {
                        "no-primary-action": _vm.noPrimaryAction
                      }
                    },
                    [
                      _vm.hasPrimaryAction
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "page-header-title-nav__primary-action"
                            },
                            [
                              _vm.primaryAction.component
                                ? _c(
                                    _vm.primaryAction.component,
                                    _vm._b(
                                      {
                                        tag: "component",
                                        attrs: {
                                          isMainAction: "",
                                          tid: _vm._generateTidFromString(
                                            _vm.id + "-" + _vm.primaryAction.key
                                          )
                                        },
                                        on: {
                                          action: _vm.handlePageHeaderAction
                                        }
                                      },
                                      "component",
                                      _vm.primaryAction,
                                      false
                                    )
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "BaseContextMenu",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.showNavigationInActionsContext ||
                                _vm.hasRemainActions ||
                                _vm.showPrimaryActionInActionsContext,
                              expression:
                                "showNavigationInActionsContext || hasRemainActions || showPrimaryActionInActionsContext"
                            }
                          ],
                          key: _vm.actionsKey,
                          staticClass:
                            "page-header-title-nav__context-menu page-header-title-nav__remain-actions"
                        },
                        [
                          _vm.showNavigationInActionsContext
                            ? _c(
                                "ContextMenuGroup",
                                { attrs: { title: "Menü" } },
                                _vm._l(_vm.navigationValues, function(
                                  navItem,
                                  index
                                ) {
                                  return _c(
                                    "ContextMenuItem",
                                    {
                                      key: navItem.path + "-" + index,
                                      attrs: { tabindex: "-1" }
                                    },
                                    [
                                      _c("PageHeaderTitleNavigationNavItem", {
                                        attrs: { navItem: navItem }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          _vm.hasRemainActions ||
                          _vm.showPrimaryActionInActionsContext
                            ? _c(
                                "ContextMenuGroup",
                                {
                                  staticClass:
                                    "page-header-title-nav__context-menu--actions",
                                  attrs: {
                                    title: _vm.showNavigationInActionsContext
                                      ? "Aktionen"
                                      : ""
                                  }
                                },
                                [
                                  _vm._l(_vm.visibleActions, function(action) {
                                    return [
                                      action.component &&
                                      (_vm.showPrimaryActionInActionsContext ||
                                        action !== _vm.primaryAction)
                                        ? _c(
                                            action.asGroup
                                              ? "ContextMenuGroup"
                                              : "ContextMenuItem",
                                            {
                                              key: action.key,
                                              tag: "component",
                                              attrs: { tabindex: "-1" }
                                            },
                                            [
                                              _c(
                                                action.component,
                                                _vm._b(
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      tid: _vm._generateTidFromString(
                                                        _vm.id +
                                                          "-" +
                                                          action.key
                                                      )
                                                    },
                                                    on: {
                                                      action:
                                                        _vm.handlePageHeaderAction
                                                    }
                                                  },
                                                  "component",
                                                  action,
                                                  false
                                                ),
                                                [
                                                  _c(
                                                    "template",
                                                    {
                                                      slot:
                                                        "action-" +
                                                        action.actionKey
                                                    },
                                                    [
                                                      _vm._t(
                                                        "action-" +
                                                          action.actionKey
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _c("hr", { staticClass: "baseline" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }