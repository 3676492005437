<template>
    <span v-if="$isMobileNativeContext">{{ phoneNumber }}</span>
    <a v-else :href="'tel:' + phoneNumber.replace(/\s/g, '')" @click.stop>{{ phoneNumber }}</a>
</template>

<script>
    export default {
        props: {
            phoneNumber: {
                default: {}
            }
        } 
    }
</script>

