<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Vermittler Statistiken" 
      :subtitle="selectedStatistics.title"
      :defaultMenu="tabMenus" 
    />

    <div class="box__container" v-if="selectedStatistics.title== 'Versicherung'">
      <div class="warning" v-if="selectedStatistics.title== 'Versicherung'">
        <div class="font-bold">Hinweise für Statistik nach Vertragsanzahl:</div>
        <div class="small">Auf Grundlage des im MSC definierten Erfassungsdatums des Vertrages (ersichtlich in der Vertragsmaske) wird die Anzahl der Versicherungsverträge in der Statistik dargestellt. (Bsp. "Vertragsanzahl nach Gesellschaft pro Jahr"  VHV - 2018 – 55 – es wurden innerhalb des Jahres 2018 (Erfassungsdatum) 55 Verträge im MSC erfasst)</div>
      </div>
    </div>
    <div class="box__container" v-if="selectedStatistics.title== 'Versicherung'">
      <div class="checkbox-input__container">
        <InputCheckBoxItem label="Eigenbestand"  v-model= "eigenbestand" />
        <InputCheckBoxItem label="Fremdbestand" v-model= "fremdbestand" />
        <InputCheckBoxItem label="nur aktive Verträge" v-model= "aktiv" />
      </div>
    </div>
      <div class="box__container" v-if="selectedStatistics.title!= null && selectedStatistics.title!= 'Mitarbeiter'"> 
        <InputToggleSwitch label="Daten inklusive aller Vermittler der Struktur anzeigen" inLineLabel v-model= "struktur" />
      </div> 

    <div>
      <div v-if="!loadingTitles && statList.length" class="box__container">
        <Table
          tableId="4b2ce043-7354-47f4-8f15-b2ade56de5f3"
          :title="selectedStatistics.title"
          :headers="headers" 
          :rows="rows" 
          @click-bezeichnung="onClickBtn($event)" 
        />
      </div>
      <GhostLoading v-else-if="loadingTitles" type="table" :title="selectedStatistics.title" useBoxContainer />
      <NoData v-else :title="selectedStatistics.title" useBoxContainer />
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BROKER_STATISTICS_TYPES from '@/store/brokerStatistics/types'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import NoData from '@/components/core/NoData.vue';
import { PhTable } from "phosphor-vue";
import {TextColumn} from "@/components/table2/table_util.js";

  export default {
    beforeRouteLeave (to, from, next) {
      this.$addBreadcrumb({
        label: 'zurück zu Vermittler Statistiken',
        to,
        from
      });

      next()
    },
    data() {
      return {
        loading: false,
        title: '',
        eigenbestand: "1",
        fremdbestand: "1",
        aktiv: "1",
        struktur: "0",
        loadingTitles: false,
      };
    },
    components: {
      PageHeaderTitleNavigation, OptionMenu, InputCheckBoxItem, InputToggleSwitch,
      GhostLoading, NoData, PhTable, Table,
    },
    mounted() {      
      this.loadingTitles = true;
      this.$store.dispatch(BROKER_STATISTICS_TYPES.ACTIONS.RETRIEVE_TITLES_LIST).finally(() => this.loadingTitles = false);
    },
    watch:{
      struktur(){
        this.$store.state.brokerStatistics.struktur = this.struktur;
      },
    },
    computed: {
      ...mapGetters({ 
        titlesList: BROKER_STATISTICS_TYPES.GETTERS.BROKER_STATISTICS_TITLES_LIST,
      }),
      headers() {
        return {
          lockedLeft: [
            TextColumn('bezeichnung', 'Bezeichnung').makeLink().makeAlwaysVisible(),
          ],
          center: [],
          lockedRight: [],
        };
      },
      rows() {
        return [ ...(this.statList || []), ];
      },
      selectedBezeichnung() {
        return this.$route.meta?.bezeichnung;
      },
      selectedStatistics() {
        const list = this.titlesList?.data || [];
        return list.find(st => st?.statistikMaklerPositionenOutput?.bezeichnung === this.selectedBezeichnung) || {};
      },
      hasSelectedStatistics() {
        return !!Object.keys(this.selectedStatistics || {}).length;
      },
      statList() {
        return this.selectedStatistics?.statistikMaklerPositionenOutput?.liste || [];
      },
      tabMenus() {
        if (this.$appNavigation?.currentTabMenu?.length && this.titlesList?.data?.length) {
          return this.$appNavigation?.currentTabMenu.filter(item => (!!this.titlesList.data.find(d => d.title === item.label)));
        }
        return [];
      },
    },

    methods: {
      getTitle(row){
        if(!row.hasNoUnderStatistics){
          this.title = row.substId.bezeichnung;
        }else{
          this.title = ''
        }
      },
      onClickBtn(statistics) {
        this.$store.commit(BROKER_STATISTICS_TYPES.MUTATIONS.SET_DEEP_TITLE, [statistics.bezeichnung]);
        this.$store.dispatch(BROKER_STATISTICS_TYPES.ACTIONS.RETRIEVE_TABLE_VIEWER, 
          {
            index: 0,
            group: this.selectedStatistics.title, 
            title: statistics.bezeichnung, 
            id:statistics?.id, 
            typ:'msc.hibernate.persistence.StatistikMakler',
            eigenbestand:this.eigenbestand, 
            fremdbestand:this.fremdbestand, 
            aktiv:this.aktiv, 
            struktur: this.struktur
          });
        this.$router.push({path: `/intern/makler-statistiken/view`});
      },
    },
  }
</script>

<style scoped>
.text-center {
  text-align: center;
}
.item-cell {
  cursor: pointer;
}
.icon {
  width: 24px;
}
.item-content {
  display: flex;
}
.item-content > .box__container {
  flex: 1;
}
</style>