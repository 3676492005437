<template>
  <div class="box__container">
    <GhostLoading v-if="loading && !scrollLoading" type="table"/>
    <ScrollLoading
      v-else
      :hasScroll="!!maxRowCount && rows.length !== maxRowCount"
      @loading="scrollLoading = $event"
      @scroll="$emit('onScroll', $event)"
    >

      <Table
        v-model="selectedRows"
        title="Bemerkungen" 
        :headers="headers"
        :rows="rows"
        :headerActions="titleActions"
        :mobileConfig="mobileConfig"
        :selectionAsBlacklist="invertSelection"
        @clickRow="editBemerkung"
        @selectionAsBlacklist="invertSelection = $event"
        @click-subject="editBemerkung"
        @click-kundennr="openCustomerNewTab({kundennr: $event.kundennr})"
        @click-vermittlernr="openBrokerNewTab($event.vermittlernr)"
        @action-DELETE="deleteRow"
        @action-EDIT="editBemerkung"
        @action-PDF="showPDF"
        @headerAction-BULK_DELETE="deleteSelected"
        @action-ATTACHMENTS="openBemerkungAnhangModal"
      />
    </ScrollLoading>

    <BaseModal
      ref="bemerkungAnhangModal"
      modalTitle="Anhänge"
      labelButtonCancel="Zurück"
      :showConfirmButton="false"
    >
      <template #default>
        <BemerkungFiles
          :files="form.files"
          @deleteFile="deleteAttachment($event)"
        />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import COMMUNICATION_TYPES from "@/store/communication/types";
import CORE_TYPES from '@/store/core/types';

import { mapGetters } from "vuex";
import Table from '@/components/table2/Table.vue';
import { TextColumn, DateColumn, SimpleAction, ConfirmedAction, ActionColumn } from "@/components/table2/table_util.js";
import { PageHeaderConfirmedAction } from '@/components/core/header-title-navigation/page-header-utils';
import navigationMixin from '@/mixins/navigation-mixin.js';

import ScrollLoading from '@/views/communication/ScrollLoading.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import BemerkungFiles from "@/components/persoenliche-daten/bemerkungen/BemerkungFiles.vue";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { PhPaperclip } from 'phosphor-vue'

export default {
  mixins: [navigationMixin],
  components: {
    BaseModal,
    GhostLoading,
    BemerkungFiles,
    ScrollLoading,
    Table,
    PhPaperclip,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
      required: true,
    },
    recordCount: {
        type: Number,
        default: 0,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    typ: {
        type: String,
        default: null,
        required: true,
    },
    useInvertSelection: {
        type: Boolean,
        default: false,
    },
    basePath: {
        type: String,
    },
    additionalHeaders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      invertSelection: undefined,
      selectedRows: null,
      form: {},
      mobileConfig: {
          title: 'title',
          headers: ['subject', 'date', 'statusIcon']
      },
      scrollLoading: false,
    };
  },
  computed: {
    ...mapGetters({
        isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
        loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
        isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    headers() {            
      return {
        lockedLeft: [
          TextColumn("title", "Thema"),
          TextColumn("subject", "Betreff").addCellProps({lineClamp: 4}).makeLink(),
        ],
        center: [
          ...(this.additionalHeaders || []),
        ],
        lockedRight: [
          DateColumn("date", "Datum"),
          ActionColumn("actions", "Aktionen"),
        ]
      };
    },
    rows() {
      return this.records.map(record => ({
        ...record,
        date: record.datum,
        hasAttachment: record.files?.length,
        title: record.betreff || "[kein Thema]",
        subject: this.getCleanText(record.text || '')?.trim() || "[Kein Text]",
        isSubjectLink: true,
        actions: this.makeActions(record)
      }));
    },
    titleActions() {
      return [
        PageHeaderConfirmedAction('BULK_DELETE', 'Markierte löschen', 'Sollen die markierten Einträge wirklich gelöscht werden?', 'Markierte löschen', 'Löschen')
          .withDisabled(() => this.invertSelection  ? this.selectedRows?.length >= this.maxRowCount : !this.selectedRows?.some(item => item.deletable))
          .withVisible(() => !this.isCustomerOnly)
      ]
    },
    maxRowCount() {
      return this.recordCount
    },
  },
  mounted() {
    if (this.useInvertSelection) {
      this.invertSelection = false
    }
    if (!this.isCustomerOnly) {
      this.selectedRows = [];
    }
  },
  methods: {
    editBemerkung(whatRow) {
      this.$router.push({path: `${this.basePath || this.$route.path}/bemerkung/${whatRow?.id}`, query: {typ: this.typ}});
    },
    makeActions(row) {
      let actions = []

      if (row.files?.length) {
        actions.push(SimpleAction("ATTACHMENTS", '', "Anhänge anzeigen"))
      }

      if (row.id) {
        actions.push(SimpleAction("PDF", '', "PDF anzeigen"))
      }

      if (row.id && ! this.isCustomerOnly) {
        actions.push(SimpleAction("EDIT", '', "Bearbeiten"))
      }      

      if (row.deletable && ! this.isCustomerOnly) {
        actions.push(ConfirmedAction("DELETE", '', "Löschen", `Soll die Bemerkung "${row.betreff || "[kein Thema]"}" wirklich gelöscht werden?`, 'Bemerkung löschen', "Löschen"))
      }

      return actions
    },
    getCleanText(text) {
      var div = document.createElement("div");
      div.innerHTML = text;
      return div.textContent || div.innerText || "";
    },
    openBemerkungAnhangModal(bemerkung) {
      this.form = bemerkung
      this.$refs.bemerkungAnhangModal.open();
    },
    deleteAttachment(fileId) {
      this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.DELETE_BEMERKUNG_ATTACHMENT, {
          bemerkungId: this.form.id,
          fileId,
          typ: this.typ
      }).then(() => {
        const files = this.form.files || [];
        const fileIndex = files.findIndex((f) => f.fileId === fileId);
        files.splice(fileIndex, 1);
      })
    },
    deleteRow(row) {
      if (row.deletable) {
        this.$emit("delete", {id: row.id})
      }
    },
    showPDF({id}) {
      const href =`${process.env.VUE_APP_API}/postfachBemerkung/bemerkung-pdf?id=${id}&typ=${this.typ}&token=${this.loginData.token}`;
      viewDocument({href, filename: 'Bemerkung.pdf', contentType: 'application/pdf'});
    },
    deleteSelected() {
      const ids = this.selectedRows?.filter(row => row.deletable).map(v => v.id)
      this.$emit("deleteSelected", {ids, invertSelection: this.invertSelection,
        next: () => {
          this.selectedRows = this.isCustomerOnly ? null : []

          if (this.useInvertSelection) {
            this.invertSelection = false
          }
        }})
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from, });
    next();
  },
};
</script>
