var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base-image__container no-line-height" }, [
    _c("img", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.src && _vm.imgLoaded,
          expression: "src && imgLoaded"
        }
      ],
      staticClass: "base-image",
      class: ["image-" + _vm.fit],
      attrs: { src: _vm.src, alt: "Unternehmenslogo" },
      on: { load: _vm.onImgLoad }
    }),
    !_vm.imgLoaded
      ? _c("div", {
          staticClass: "base-image-placeholder",
          style: _vm.placeholderStyle
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }