<template>
  <div class="box__container">
    <div class="box__title" v-if="!$isSmallScreen">Kommunikationsdaten</div>

    <div>
      <BaseButton v-if="isEditable || (this.personId == 'person1' && (this.isCustomerLogin || this.isKundenzugang))" @click="openModalAddContact()" class="mb-3"
        >Hinzufügen</BaseButton
      >

      <Table
        v-if="rows.length"
        tableId="14c08a36-4128-40e7-b316-a5281f899506"
        :headers="headers"
        :rows="rows"
        rowId="value"
        :rowsPerPage="10"
        :mobileConfig="{title: 'Kommunikationsdaten', headers: ['title', 'value', 'hauptkommunikation']}"
        :exportConfig="{ roottext: 'Kommunikationsdaten' }"
        @click-value="openLink($event)"
        @action-EDIT="openEditModal"
        @action-DELETE="openDeleteModal"
      />
      <div v-else>Keine Daten</div>

      <BaseModal
        ref="addContact"
        size="sm"
        modalTitle="Neue Kommunikationsdaten"
        closeButtonLabel="Speichern"
        :showConfirmButtonSmallScreen="false"
        @onConfirmButton="saveContact()"
        @onCloseButton="saveContact()"
      >
        <ComboBox
          label="Typ"
          :values="contactTypes"
          v-model="formAddContact.type"
          :disabled="!isEditable && !isNeededFor2FA"
          :firstEmpty="true"
          @change="formatValue($event, formAddContact.value)"
        />

        <InputField
          :label="getBeschreibungLabel(formAddContact.type)"
          :type="valueInputType"
          v-model="formAddContact.value"
          :disabled="!isEditable && !isNeededFor2FA"
          @change="formatValue(formAddContact.type, $event)"
        />

        <InputToggleSwitch
          label="Hauptkommunikation"
          :inLineLabel="true"
          v-model="formAddContact.standart"
          :disabled="
            (!isEditable && !isNeededFor2FA) ||
            (contactToEdit &&
              contactToEdit.standart &&
              contactToEdit.title === formAddContact.type)
          "
        />

        <InputField
          label="Bemerkung"
          v-model="formAddContact.comment"
          :disabled="!isEditable"
        />

        <div
          class="fc-form-danger"
          v-if="checkFormatCommunication && checkFormatCommunication.text"
        >
          {{ checkFormatCommunication.text }}
        </div>
      </BaseModal>
      <BaseModal
        ref="deleteContact"
        size="sm"
        modalTitle="Eintrag löschen"
        labelButtonConfirm="Löschen"
        closeButtonLabel="Abbrechen"
        :showCancelButtonSmallScreen="false"
        @onConfirmButton="deleteContact(contactToDelete)"
      >
        <div v-if="contactToDelete">
          {{ contactToDelete.title }} {{ contactToDelete.value }} wirklich
          löschen?
        </div>
      </BaseModal>
      <BaseModal
        ref="showMeldung"
        size="md"
        modalTitle="Hinweis"
        closeButtonLabel="Weiter"
        :showConfirmButtonSmallScreen="false"
        @onConfirmButton="saveContact()"
        @onCloseButton="saveContact()"
        @onCancelButton="isFAMeldung=false"
      >
        <p>Wir weisen darauf hin, dass bei den Kundendaten im MSC nur tatsächlich vom Kunden angegebene E-Mailadressen hinterlegt werden dürfen. Es ist nicht zulässig, dass Vertriebspartner ihre E-Mailadressen eintragen, da FinanzAdmin ihren gesetzlichen Verpflichtungen nicht nachkommen kann, welche wir auf diesem Wege erfüllen. Hierzu gehört insbesondere die Kopie der Vertragsunterlagen (Depoteröffnung/Orders/Anlegerprofile etc.) und der jährliche Ex-Post Kostenausweis.
        </p><p>Hat der Kunde keine persönliche E-Mailadresse, sind beispielsweise die Vertrags/Antragsunterlagen in Kopie auszuhändigen, und die Übergabe ist auf der dafür vorgesehenen Übernahmebestätigung von Kunden zu bestätigen. Ex-Post Kostenausweise werden in diesen Fällen von FinanzAdmin per Post übermittelt.
        </p>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PhCheck } from "phosphor-vue";
import {ROLES, VIEW_ROLES} from "@/router/roles";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import { PhInfo } from "phosphor-vue";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  IconColumn,
  Icon,
  ActionColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import BaseModal from "@/components/core/BaseModal.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import TAPI from "@/store/tapi/types";
import CORE_TYPES from "@/store/core/types";
import LOG_TYPES from '@/store/log/types'
import { buildMessage } from '../../helpers/log-message-helper';
import { getRedirectionWindow } from '@/components/table/export/utils';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  data() {
    return {
      formAddContact: {
        type: "",
        typeId: null,
        standart: false,
        value: "",
        comment: "",
      },
      contactToEdit: null,
      contactToDelete: null,
      isNewCommunication: null,
      checkFormatCommunication: {},
      headers: {
        lockedLeft: [TextColumn("title", "Typ")],
        center: [
          TextColumn("value", "Beschreibung").makeConditionalLink("_isLink"),
          IconColumn("hauptkommunikation", "Hauptkommunikation"),
          TextColumn("comment", "Bemerkung"),
        ],
        lockedRight: [ActionColumn("actions", "Aktionen")],
      },
      isFAMeldung: false,
    };
  },
  computed: {
    ...mapGetters({
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      checkFAEmails: CUSTOMERDATA_TYPES.GETTERS.GET_FA_CHECK_EMAILS,
    }),
    isEditable() {
      return this.customerData?.isEditable;
    },
    isNeededFor2FA(){
      return (this.isCustomerLogin || this.isKundenzugang) && (this.personId == 'person1')
        && (this.formAddContact.type == 'Mobiltelefon' || this.formAddContact.type =='E-Mail' || this.formAddContact.type == '')
    },
    contactTypes() {
      return ( (this.isCustomerLogin || this.isKundenzugang) && !this.isEditable) ?  this.customerDataConfig?.contactTypesKundenLogin : this.customerDataConfig?.contactTypes || [];
    },
    isCustomerLogin() {
      return this.isCustomerOnly;
    },
    isKundenzugang() {
      return this.hasRoles(ROLES.IS_KUNDENZUGANG);
    },
    isFACheckMails()  {
      return this.hasRoles([[ROLES.FA, VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER]]);
    },
    getCheckFAEmails() {
      if (this.checkFAEmails.status == 2 && this.checkFAEmails.emails) {
        return this.checkFAEmails.emails;
      }
      return null;
    },
    valueInputType() {
      const { type } = this.formAddContact || {};
      if (!type) return "text";

      switch (type) {
        case "E-Mail":
          return "email";
        case "Telefon tagsüber":
        case "Telefon privat":
        case "Mobiltelefon":
        case "Fax":
          return "tel";
        case "Internet-Adresse":
          return "url";
        default:
          return "text";
      }
    },
    rows() {
      const actions = [
        SimpleAction(
          "EDIT",
          this.isEditable ? "" : "",
          "Bearbeiten"
        ),
      ];
      if (this.isEditable)
        actions.push(SimpleAction("DELETE", "", "Löschen"));
      return (this.customerData?.contacts || []).map((row) => ({
        ...row,
        _isLink: this.isTelefon(row.title) || row.type === 'Internet Standard' || row.title === 'Internet-Adresse',
        hauptkommunikation: row.standart
          ? [Icon(PhCheck, "Ja", 24, undefined, "color-success")]
          : null,
        actions,
      }));
    },
  },
  methods: {
    openDeleteModal(contact) {
      this.contactToDelete = contact;
      this.$refs.deleteContact.open();
    },
    openLink(row) {
      if(row) {
        if(this.isTelefon(row.title)) {
          this.tapiCall(row.value);
        }
        if(row.type === 'Internet Standard') {
            getRedirectionWindow().location.href = row.value;
        }
      }
    },
    tapiCall(phoneNumber) {
      let kundennr = this.$store.getters[CORE_TYPES.GETTERS.GET_USER_ID];
      this.$store.dispatch(TAPI.ACTIONS.OPEN_CALL_OUT_DIALOG, {
        userType: "Kunde",
        userId: kundennr,
        phoneNumber: phoneNumber,
      });
    },
    openModalAddContact() {
      this.isNewCommunication = true;
      this.contactToEdit = null;
      const type = this.contactTypes[0] || "";
      this.checkFormatCommunication = {};
      this.formAddContact.type = type;
      this.formAddContact.typeId = null;
      this.formAddContact.standart = this.isNeededFor2FA ? true : false;
      this.formAddContact.value = "";
      this.formAddContact.comment = "";
      this.$refs.addContact.open();
    },

    openEditModal(contact) {
      this.isNewCommunication = false;
      this.contactToEdit = { ...contact };
      this.checkFormatCommunication = {};
      this.formAddContact.type = contact.title;
      this.formAddContact.typeId = contact.typeId;
      this.formAddContact.standart = contact.standart;
      this.formAddContact.value = contact.value;
      this.formAddContact.comment = contact.comment;
      this.$refs.addContact.open();
    },

    saveContact() {
      if (this.getCheckFAEmails && 'E-Mail' == this.formAddContact.type && !this.isFAMeldung) {
        this.isFAMeldung = -1 !== this.getCheckFAEmails.findIndex(e => e == this.formAddContact.value.toLowerCase().replace(/ /g, ''))
        if (this.isFAMeldung) {
          this.$refs.showMeldung.open();
          return
        }
      }
      this.isFAMeldung = false;
      const payload = {
        type: this.formAddContact.type,
        value: this.formAddContact.value,
      };

      this.$store
        .dispatch(
          CUSTOMERDATA_TYPES.ACTIONS.CHECK_FORMAT_COMMUNICATION,
          payload
        )
        .then(
          (response) => {
            if (response?.valid) {
              const savePayload = {
                contact: {
                  ...this.formAddContact,
                },
                personId: this.personId,
              };
              this.$store.commit(
                CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED,
                savePayload
              );
              
              if (this.isCustomerLogin || this.isKundenzugang) {
                const params = {personId: this.personId, isNewCommunication: this.isNewCommunication}
                this.$store
                  .dispatch(
                    CUSTOMERDATA_TYPES.ACTIONS.SAVE_KOMMUNIKATION_DATEN_PENDING,
                    params
                  )
                  .then(() => {
                    if(!this.isNewCommunication){
                      this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Die Änderungen Ihrer Kommunikationsdaten wurden an Ihren Ansprechpartner weitergeleitet.', 'info'))
                      this.isNewCommunication = false;
                    }
                  });
              } else {
                this.$store.dispatch(
                  CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA
                );
              }
            }
          },
          (error) => {
            this.checkFormatCommunication = error;
          }
        );
    },

    deleteContact(contact) {
      const payload = {
        contact,
        personId: this.personId,
      };
      this.$store.dispatch(
        CUSTOMERDATA_TYPES.ACTIONS.DELETE_CUSTOMER_DATA,
        payload
      );
    },

    isTelefon(type) {
      if (type == null) {
        return false;
      }
      if (!this.$store.getters[TAPI.GETTERS.CAN_OUT_CALL]) {
        return false;
      }
      let str = type.toLowerCase();
      return (
        str.includes("mobile") ||
        str.includes("phone") ||
        str.includes("telefon")
      );
    },

    async formatValue(type, value) {
      switch (type) {
        case "Telefon tagsüber":
        case "Telefon privat":
        case "Mobiltelefon":
        case "Fax":
        case "Internet-Adresse":
          this.formAddContact.value = await this.$store.dispatch(
            CUSTOMERDATA_TYPES.ACTIONS.FORMAT_VALUE,
            { type, value }
          );
          break;
      }
    },
    getBeschreibungLabel(whatType) {
      const labels = {
        "Mobiltelefon": "Rufnummer",
        "Telefon privat": "Rufnummer",
        "Telefon tagsüber": "Rufnummer",
        "E-Mail": "E-Mailadresse",
        "Fax": "Faxnummer",
        "Internet-Adresse": "URL"
      };

      return labels[whatType] || 'Beschreibung';
    },
  },
  mounted() {
    if (this.isFACheckMails ) { // && this.checkFAEmails?.status === 0) {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.LOAD_FA_CHECK_EMAILS)
    }
  },
  components: {
    Table,
    BaseModal,
    ComboBox,
    InputField,
    BaseButton,
    PhCheck,
    InputToggleSwitch,
    PhInfo,
  },
};
</script>
