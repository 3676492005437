<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="input-forms__container">
        <ComboBox
          v-if="config.beenden"
          label="Bereits vorhandene Depots"
          :values="vorhandeneDepotsAuswahl"
          :value="selectedDepotValue"
          :sortComboboxValues="false"
          @input="
            updateStore(selectedDepotKey, $event)
          "
        />
        <WertpapierorderAnteileInfo v-if="selectedDepot" :position="selectedDepot" />
      </div>
      <div class="input-forms__label-container">
        <div class="row mt-0">
          <div class="col">
            <div
              class="input-forms__label-content input-forms__label-content--bigger"
              v-if="label"
            >
              <ph-bank :size="16" />&nbsp;{{ label }}
            </div>
          </div>
          <div class="col-auto" v-if="config && config.buttonText">
            <BaseButton @click="openFormsFinder">{{
              config.buttonText
            }}</BaseButton>
          </div>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <Table 
        v-if="rows && rows.length"
        hidePagination
        :headers="headers"
        :rows="rows"
        rowId="index"
        @action-DELETE="openModalDeleteFond"
      />

      <DeletePositionModal
        ref="deleteModal"
        :position="positionToDelete"
        @delete="doDeletePosition()"
      ></DeletePositionModal>
    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import BaseButton from "@/components/core/BaseButton.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import Table from "@/components/table2/Table.vue";
import { TextColumn, ActionColumn, SimpleAction } from "@/components/table2/table_util.js";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import { mapGetters } from "vuex";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import { PhBank } from "phosphor-vue";
import WertpapierorderAnteileInfo from "@/components/wertpapierorder/WertpapierorderAnteileInfo.vue";

export default {
  mixins: [antragNavigationMixin, antragMixin],
  props: {
    label: {},
    antragData: {},
    config: {},
    antragTyp: {},
    antragId: {},
    comboboxSelections: {},
    categoryId: {},
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseButton,
    ComboBox,
    Table,
    DeletePositionModal,
    PhBank,
    WertpapierorderAnteileInfo,
  },
  data() {
    return {
      positionToDelete: {},
    };
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
    }),
    headers() {
      const result = {
        lockedLeft: [
          TextColumn("isin", "ISIN"),
          TextColumn("fondsName", "Fondsname", 600),
        ],
        center: [],
        lockedRight: [
          ActionColumn("actions", ""),
        ]
      }
        return result
    },
    rows() {
      const actions = [
        SimpleAction("DELETE", '', "Löschen"),
      ];
      return (this.positions || []).map((row, index) => ({
          ...row, 
          actions,
          index,
          lagerstelle: 'CAPITALBANK',
          fondsName: this.getFondsName(row, this.positionInfo),
      }));
    },
    positions() {
      if (
        this.positionsAll &&
        this.antragId &&
        this.categoryId &&
        this.positionsAll[this.antragId] &&
        this.positionsAll[this.antragId][this.categoryId]
      ) {
        const pos = this.positionsAll[this.antragId][this.categoryId].filter(
          (p) =>
            p.aenderungTyp == this.config.aenderungTyp ||
            p.aenderungTyp == undefined
        );
        if (pos != undefined && pos.length > 0) {
          if (this.config.indexierung) {
            pos.map((x) => (x.aenderungTyp = "SPARPLAN_INDEXIERUNG"));
          } else if (this.config.isAuszahlung) {
            pos.map((x) => (x.aenderungTyp = "ENTNAHMEPLAN_ENDE"));
          } else {
            pos.map((x) => (x.aenderungTyp = "SPARPLAN_ENDE"));
          }
        }
        return pos;
      }
    },
    vorhandeneDepots() {
      return this.comboboxSelections && this.comboboxSelections['INPUT_VORHANDENE_KONTEN'] || []
    },
    vorhandeneDepotsAuswahl() {
      return this.vorhandeneDepots.map(depot => ({
        label: depot.label,
        value: depot.isin || depot.value
      }))
    },
    selectedDepot() {
      return this.vorhandeneDepots.find(depot => depot.isin && depot.isin === this.selectedDepotValue)
    },
    selectedDepotKey() {
      return this.config.beenden ? 'INPUT_VORHANDENE_KONTEN_BEENDIGUNG' : 'INPUT_VORHANDENE_KONTEN_INDEXIERUNG'
    },
    selectedDepotValue() {
      return this.antragData[this.selectedDepotKey]
    },
  },

  methods: {
    updateStore(componentId, value) {
      const payload = {
        id: this.antragId,
        data: {
          [componentId]: value,
        },
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload);
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open();
    },
    doDeletePosition() {
      this.deletePosition(
        this.antragId,
        this.categoryId,
        this.positionToDelete
      );
      this.positionToDelete = null;
    },
    addOrUpdatePosition(position) {
      const payload = {
        id: this.antragId,
        categoryId: this.config.isAuszahlung ? 'ENTNAHMEPLAN' : 'SPARPLAN',
        additionalIdentifier: ["aenderungTyp"],
        fonds: [{
          ...position,
          aenderungTyp: this.config.aenderungTyp,
        }],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);  
    },
    addToPositions(posInfo) {
      const pos = this.addPositionCBAenderingVermoegensplaene(posInfo,this.config.aenderungTyp)
      this.addOrUpdatePosition(pos)
    },
    openFormsFinder() {
      let posInfo = this.selectedDepotValue !== "NICHT_VERWENDEN" ? this.selectedDepotValue : null

      if (posInfo != null) {
        this.addToPositions(
          this.vorhandeneDepotsAuswahl.find((p) => p.value == posInfo)
        );
      } else {
        this.goToFormsFinder(
          this.categoryId,
          this.config,
          this.suppressFilterLagerstelle
        );
      }
    },
  },
};
</script>

<style scoped>
.input-forms__label-content--bigger {
  font-size: 1.5rem;
}
.input-forms__label-content--bigger > i {
  font-size: 2rem;
}
</style>