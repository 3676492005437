<template>
  <div>
    <!-- HEADLINE -->
    <div class="container topline" v-if="title || selected || visibleTitleActions.length">
      <InputCheckBoxItem
        v-if="selected"
        :value="areAllSelected"
        :indeterminate="areOnlySomeSelected"
        @input="onSelectAll"
        style="margin-right: 4px; display: inline-block"
      />

      <div class="box__title ellipsis" style="flex: 1 1">{{ title }}</div>
 
      <transition name="header-actions-animation" v-if="visibleTitleActions.length">
        <BaseContextMenu :tid="'tidOverview'" class="table--header-actions" :key="titleActionsKey">
          <ContextMenuItem v-for="action in visibleTitleActions" :key="action.key">
            <component
                :is="action.component"
                :tid="'tidOverview' + action.key"
                v-bind="action"
                @action="onAction" 
            />
          </ContextMenuItem>
        </BaseContextMenu>
      </transition>
    </div>

    <div v-for="row in records" :key="row.id" class="topline" style="display: flex">
        <InputCheckBoxItem
          v-if="selected"
          :value="isCheckmarked(row.id)"
          @input="onSelect(row.id)"
          style="margin-right: 4px; display: inline-block"
        />
        <div class="topline-content" :class="{'checkbox-content' : selected}">
          <div class="container" :class="{'text-bold' : row.unread}">
            
            <slot name="title" v-bind="row" >
              <div class="ellipsis" :class="{'bold' : !row.unread}">
                <a style="color: inherit;" v-if="row.isTitleLink"  @click="$emit('title', row)">{{ row.title }}</a>
                <span v-else>{{ row.title }}</span>
              </div>
            </slot>

            <div class="topline-side-content">

              <ph-paperclip v-if="row.hasAttachment" :size="18" style="margin-right: 3px"/>

              <div class="overflow">{{dateToString(row.date) || dateTimeToString(row.dateTime)}}</div>

              <template v-if="someRowHasActions">
                <ActionCell v-if="row.actions && row.actions.length" :row="row" column="actions" tid="tidOverview" @action="onAction"/>
                <template v-else>
                  <div class="hide-on-small-screen" style="width:20px"></div>
                  <div class="hide-on-large-screen" style="width:24px"></div>
                </template>
              </template>

            </div>
          </div>
          <div class="container">
          <div class="subject ellipsis">
            <slot name="subject" v-bind="row">
              <a v-if="row.isSubjectLink"  @click="$emit('subject', row)">{{ row.subject }}</a>
              <span v-else>{{ row.subject }}</span>
            </slot>
          </div>
          <div class="status ellipsis">
            <slot name="status" v-bind="row">
              
            </slot>
          </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import { PhPaperclip } from "phosphor-vue";
import {toDateString, toDateTimeString} from '@/filters.js';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import ActionCell from '@/components/table2/cellTypes/ActionCell.vue';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';

export default {
  mixins: [],
  components: {
    PhPaperclip,
    InputCheckBoxItem,
    ActionCell,
    BaseContextMenu,
    ContextMenuItem,
  },
  model: {
      prop: 'selected',
      event: 'selected',
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    records: {
      type: Array,
      default: () => []
    },
    titleActions: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => null,
    },
    selectionAsBlacklist: {      // If this is set to true, all rows EXCEPT 'selected' will show a checkmark.
      type: Boolean,
      default: undefined
    },
  },
  computed: {
    areOnlySomeSelected() {
        return this.selected.length > 0 && !this.areAllSelected;
    },
    isSelected() {
        const result = {};
        if (this.selected) {
            this.selected.forEach(row => {
                result[row.id] = true;
            });
        }
        return result;
    },
    areAllSelected() {
        return this.selectionAsBlacklist && !this.selected.length
            || !this.selectionAsBlacklist && this.selected.length == this.rowCount;
    },
    visibleTitleActions() {
      return this.titleActions?.filter(act => !act.visible || act.visible?.())
    },
    titleActionsKey() {
        return this.titleActions
            .map(act => [!act.visible || act.visible?.(), !act.disabled || act.disabled?.()].join('-'))
            .join(':');
    },
    someRowHasActions() {
        return this.records.some(record => record.actions && record.actions.length)
    },
  },
  methods: {
    isCheckmarked(id) {
        return !this.isSelected[id] !== !this.selectionAsBlacklist;
    },
    onAction({key, row}) {
      this.$emit("action-" + key, row);
      this.$emit("action", {key, row});
    },
    dateToString(date) {
      return toDateString(date)
    }, 
    dateTimeToString(date) {
      return toDateTimeString(date)
    },
    onSelect(id) {
        let isSelected = {...this.isSelected};
        if (isSelected[id])
            delete isSelected[id];
        else
            isSelected[id] = true;
        this.emitSelected(isSelected);
    },
    emitSelected(isSelected) {
        const result = this.records
            .filter(row => isSelected[row.id]);
        this.$emit("selected", result);
    },
    onSelectAll(value) {
        if (this.selectionAsBlacklist !== undefined) {
          this.$emit("selectionAsBlacklist", value);
          this.$emit("selected", []);
        } else {
          let isSelected = {};
          if (value) {
              this.records.filter(row => !row.selectedDisabled && !row.selectedHidden).forEach(row => {
                  isSelected[row.id] = true;
              });
          }
          this.emitSelected(isSelected);
        }
      }
  },
}
</script>

<style scoped>
.bold {
  font-weight: 500;
}
.container {
  display: flex;
  justify-content: space-between;
}
.overflow {
  overflow: hidden;
}
.topline-side-content {
  display: flex;
  font-size: 0.9em;
  max-width: 50%;
  min-inline-size: fit-content;
}
.subject {
  max-width: 95%;
  font-size: 0.9em;
}
.topline {
  padding: 4px 4px;
  border-bottom: 1px solid #C4C4C4;
}
.topline:last-child { border-bottom: none; }
.topline-content {
    flex: 1 1 auto;
    max-width: 100%;
}
.table--header-actions {
    animation: header-actions-animation 0.8s both;
}

.header-actions-animation-leave-active {
    display: none;
}

@keyframes header-actions-animation {
    0%,
    100% {
        transform: rotate(0) scale(1);
        transform-origin: center center;
    }
    15% {
        transform: rotate(8deg) scale(1.2);
    }
    30% {
        transform: rotate(-8deg) scale(1.2);
    }
    45% {
        transform: rotate(6deg) scale(1.2);
    }
    60% {
        transform: rotate(-6deg) scale(1.2);
    }
    75% {
        transform: rotate(0);
    }
}
</style>