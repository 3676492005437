<template>
  <div class="box__container">
    <div class="box__title">Zusätzliche Informationen</div>
    <div class="row">
      <div class="col-12">
        <div v-if="isEditable" class="mb-8px">
          <ComboBox 
            :isComponentHalfSize="true"
            :values="customerDataConfig.zusaetzlicheInfos" 
            v-model="zusaetzlicheInfosCombo" 
            :sortComboboxValues="false" 
            @change="resetZusaetzlicheInfosField()" />
          <InputField 
            :isComponentHalfSize="true" 
            v-model="zusaetzlicheInfosField" 
            @input="resetZusaetzlicheInfosCombo()"/>
          <BaseButton :disabled="!isValid" @click="addZusaetzlicheInfos()">Hinzufügen</BaseButton>
        </div>

        <div>
            <Table v-if="rows.length"
                tableId="2bab20e1-b1e1-44ad-aad2-ebacbb3eb51c"
                :headers="headers"
                :rows="rows"
                :rowsPerPage="10"
                rowId="zusaetzlicheInfos"
                @action-DELETE="removeZusaetzlicheInfos($event.zusaetzlicheInfos)"
            />
            <NoData v-else noIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import NoData from '@/components/core/NoData.vue';

import persoenlicheDatenMixin from '@/components/persoenliche-daten/persoenliche-daten-mixin';



export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
        ...this.initialState(),
        zusaetzlicheInfosCombo: '',
        zusaetzlicheInfosField: '',
        headers: {
            lockedLeft: [
                TextColumn("zusaetzlicheInfos", "Zusätzliche Informationen"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ]
        },
    };
  },
  computed: {
    rows() {
        const actions = [
            SimpleAction("DELETE", '', "Löschen"),
        ];
        return (this.zusaetzlicheInfos || []).map(zusaetzlicheInfos => ({
            zusaetzlicheInfos,
            actions: this.isEditable ? actions : null,
        }))
    },
    currentValue() {
      return (this.zusaetzlicheInfosCombo || this.zusaetzlicheInfosField || '').trim();
    },
    isValid() {
      return !!this.currentValue;
    },
  },
  methods: {
    initialState() {
      return {
        zusaetzlicheInfos: [],
      };
    },
    resetZusaetzlicheInfosField() {
      this.zusaetzlicheInfosField = '';
    },
    resetZusaetzlicheInfosCombo() {
      this.zusaetzlicheInfosCombo = '';
    },
    updateGroupsIntoData() {
      this.updateListIntoData('zusaetzlicheInfos');
    },
    addZusaetzlicheInfos() {
      const zusaetzlicheInfo = this.currentValue;
      if (zusaetzlicheInfo && this.zusaetzlicheInfos.findIndex(v => v?.toLowerCase?.() === zusaetzlicheInfo?.toLowerCase?.()) < 0) {
        this.zusaetzlicheInfos.unshift(zusaetzlicheInfo);
        this.addCustomerDataEdited('zusaetzlicheInfos', this.zusaetzlicheInfos);
      }

      this.resetZusaetzlicheInfosField();
      this.resetZusaetzlicheInfosCombo();
    },
    removeZusaetzlicheInfos(zusaetzlicheInfos) {
      const i = this.zusaetzlicheInfos.findIndex((v)=> v === zusaetzlicheInfos);
      this.zusaetzlicheInfos.splice(i, 1);
      this.addCustomerDataEdited('zusaetzlicheInfos', this.zusaetzlicheInfos);
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  components: {
    Table,
    InputField,
    ComboBox,
    BaseButton,
    NoData,
  },
}
</script>
