<template>
    <div class="mobile-table__container">
        <PageHeaderTitleNavigation 
            v-if="selectionActive || title || titleActionsIntern.length" 
            isSecondary 
            asPrimaryOnly 
            :actions="titleActionsIntern" 
            @action="onHeaderAction" 
        >
            <template #title>
                <!-- SELECT ALL -->
                <InputCheckBoxItem
                    v-if="hasRows && selectionActive && !singleSelection"
                    :value="areAllSelected"
                    :indeterminate="areOnlySomeSelected"
                    :disabled="areAllHiddenDisabled"
                    :tid="_generateTidFromString(tableId + ':__SELECT__')"
                    @input="onSelectAll"
                    style="margin: 0px 8px"
                />

                <!-- TITLE -->
                <div class="mobile-table__title">
                    {{ title }}
                </div>
            </template>
        </PageHeaderTitleNavigation>

        <NoData v-if="!rows.length" :noIcon="!noDataIcon" :content="noDataContent" />
        <template v-else>
            <!-- ROWS -->
            <div v-for="({ row, id, customRowStyle }, index) in visibleRows" :key="id+index" class="row-container d-flex">
                <!-- CHECKBOX -->
                <template v-if="selectionActive">
                    <InputRadioBoxItem
                        name="tableSelection"
                        v-if="singleSelection && !row.selectedHidden"
                        :disabled="row.selectedDisabled"
                        :value="id"
                        :checked="isSelected[id]"
                        @change="selectRow(row, id)"
                        style="align-self: center;margin: 0px 8px"
                    />
                    <InputCheckBoxItem
                        v-else-if="!row.selectedHidden"
                        :disabled="row.selectedDisabled"
                        :value="isCheckmarked(id)"
                        @input="selectRow(row, id)"
                        style="align-self: center;margin: 0px 8px"
                    />
                    <div v-else style="width: 37px"></div>
                </template>

                <DownloadLink
                    :style="getWidthForIndent()"
                    :disabled="!row.downloadLinkOptions || clickRowDisabled(row)"
                    asLabel
                    asLabelNoDisable
                    v-bind="row.downloadLinkOptions"
                >
                    <div class="d-flex" v-on="!row.downloadLinkOptions && !clickRowDisabled(row) ? { click: (event) => clickRow(event, row, id) } : {}">
                        <div :style="{...getWidthForRow(row), ...customRowStyle}">
                            <!-- ROW TITLE -->
                            <div :class="{ellipsis: true, ['row-title']: !row.isSubrow, ['subrow-title']: row.isSubrow}">
                                <slot v-if="cardTitleKey" :name="cardTitleKey" :row="row" :id="id" :mobileTableContext="true">
                                    {{ row[cardTitleKey] }}
                                </slot>
                            </div>

                            <!-- CONTENT -->
                            <template v-for="{ key, label, rowElement, props, colorFn} in cardHeaders">
                                <div :key="key" v-if="visibleInCard(key, row)" class="row-line">

                                    <!-- HEADER -->
                                    <div class="row-line-label">
                                        <slot :name="'header_' + key" v-bind="{ key }">
                                            <span>{{ label }}</span>
                                        </slot>
                                    </div>
                                    
                                    <!-- VALUE -->
                                    <div class="row-line-value">
                                        <slot v-if="!rowElement" :name="key" :row="row" :id="id" :mobileTableContext="true"/>
                                        <component
                                            v-else
                                            :is="rowElement"
                                            :key="key"
                                            :column="key"
                                            :row="row"
                                            :tid="tableId + ':' + key + ':' + id + ':'"
                                            v-bind="props"
                                            @action="onAction"
                                            :class="colorFn && colorFn(row)"
                                        />
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div v-if="!clickRowDisabled(row)" style="flex-shrink: 0" class="ml-1">
                            <ph-file-arrow-down v-if="row.downloadLinkOptions" :size="20"/>
                            <ph-caret-right v-else :size="20"/>
                        </div>
                    </div>
                </DownloadLink>
            </div>

            <!-- FOOTER -->
            <template v-if="footer">
                <div v-for="{ key, label, rowElement, props, } in footerHeaders" :key="key" class="row-container header-row-line">
                    <!-- FOOTER HEADER -->
                    <div class="ellipsis">
                        Summe {{ label }}
                    </div>
                    
                    <!-- FOOTER VALUE -->
                    <component
                        :is="rowElement"
                        :key="key"
                        :column="key"
                        :row="footer.row"
                        :tid="tableId + ':' + key + ':' + footer.id + ':'"
                        v-bind="props"
                    />
                </div>
            </template>
        </template>

		<MobileTableModal
            v-if="!customRowClick"
            ref="editModal"
            :headersFlat="headersFlat"
            :row="openRow"
            :tableId="tableId"
            :title="openRow[cardTitleKey]"
            @onAction="onAction"
            @onClick="clickCell"
		>

            <template v-for="header in headersFlatLabelNull" #[header.headerKey]>
                <slot :name="header.headerKey" v-bind="header"/>
            </template>
            <template v-for="{key} in headersFlat" #[key]>
                <slot :name="key" :row="openRow" :id="openRow.id"/>
            </template>
        
        </MobileTableModal>

        <ModalColumnConfig
            v-if="mobileTableId"
            ref="columnsConfig"
            isMobileTable
            :title="title ? (title + ' - Einstellungen') : 'Einstellungen'" 
            :tableId="mobileTableId"
            :tableHeaders="configurableHeaders"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG from '@/configs/fcConfig.js'

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

import { PhCaretRight, PhFileArrowDown } from "phosphor-vue";
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import InputRadioBoxItem from '@/components/core/forms/radiobox/InputRadioBoxItem';
import tableMixin from '@/components/table2/table-mixin.js';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import NoData from '@/components/core/NoData.vue';
import ModalColumnConfig from '@/components/table/ModalColumnConfig.vue'
import MobileTableModal from '@/components/table2/core/MobileTableModal.vue';

const TABLE_ACTIONS_PREFIX = 'TABLE__';
const MOBILE_TABLE_ID_PREFIX = 'm_';

export default {
    mixins: [InteractiveHelpCommonsMixin, tableMixin],
    components: {
        PageHeaderTitleNavigation,
        PhCaretRight,
        PhFileArrowDown,
        InputCheckBoxItem,
        InputRadioBoxItem,
        DownloadLink,
        NoData,
        ModalColumnConfig,
        MobileTableModal,
    },
    props: {
        tableId: {
            // tableId must be unique. This Id is being used to save the column config into backend
            // a UUID randomly generated is enough. https://www.uuidgenerator.net/version4 or any other generator.
            type: String,
        },
        title: {
            type: String,
            default: "",
        },
        rows: {
            type: Array,
            default: () => []
        },
        headers: { 
            type: Object,
            default: () => {},
            required: true,
        },
        titleActions: {
            type: Array,
            default: () => []
        },
        selected: {
            type: Array,
            default: () => null,
        },
        rowId: { 
            type: String,
            default: "id",
        },
        /**
         * The following properties have uses:
         * 
         * title: the headerKey for the title that's used in each row
         * headers: the headerKey for the data that's used in each row
         * selectionActive: always enables the selection
         * disableClickRow: nothing happens when clicking a row
         * singleSelection: enables single selection
         */
        config: {
            type: Object,
            default: () => null,
        },
        noDataIcon: {
            type: Boolean,
            default: false,
        },
        noDataContent: {
            type: String,
        },
        selectionAsBlacklist: {      // If this is set to true, all rows EXCEPT 'selected' will show a checkmark.
            type: Boolean,
            default: undefined, // If this prop isn't set, there will be no checkbox in the selection column's header
        },
    },
	data() {
		return {
			openRow: {},
            editModus: false,
		};
	},
    computed: {
        ...mapGetters({
            storedColumnsConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_TABLE_COLUMN,
            isConfigurationReadOnly: FC_CONFIG_TYPES.GETTERS.IS_CONFIGURATION_READ_ONLY,
        }),
        headersFlat() {
            // this doesn't guarantee the correct order. but the order doesn't matter where it's used.
            return Object.values(this.headers).flat();
        },
        headersFlatLabelNull() {
            return  this.headersFlat
                .filter(h => h.label == null && h.key?.charAt(0) != "_")
                .map(h => ({...h, headerKey: 'header_' + h.key}));
        },
        configuredCardHeaders() {
            let headers = this.headersFlat.filter(h => !h.hideOnModalSheet && h.key !== this.cardTitleKey)

            // check if a saved configuration can be found
            if (this.mobileTableId && this.storedColumnsConfig) {
                let columnsConfig = null;
                let savedConfig = this.storedColumnsConfig[this.mobileTableId]?.content;

                if (savedConfig) {
                    columnsConfig = Object.values(JSON.parse(savedConfig));
                }

                if (columnsConfig) {
                    // sort out title header because 'storedColumnsConfig' may include them
                    columnsConfig = columnsConfig.filter(conf => headers.some(header => header.key == conf.key) && !conf.hideOnModalSheet);
                    
                    let i = 0;
                    return headers.map(header => {
                        if (columnsConfig.find(c => c.key == header.key)) { // don't change headers that haven't been configured before
                            header = {
                                ...this.getHeader(columnsConfig[i].key),
                                visible: columnsConfig[i].visible,
                            };
                            i++;
                        }
                        return header;
                    })
                }
            }

            if (this.config?.hasOwnProperty('headers')) {
                return headers.map(header => ({...header, visible: this.config.headers.includes(header.key)}))
            } else {
                return headers.map(header => ({...header, visible: header.locked}))
            }
        },
        cardHeaders() {
            return this.configuredCardHeaders.filter(h => h.visible)
        },
        cardTitleHeader() {
            // we can get the titleHeader from the config
            let titleHeader = null

            if (this.config?.hasOwnProperty('title')) {
                titleHeader = this.headersFlat.find(header => header.key === this.config.title)
            } else {
                // if no title is given we estimate the first locked header
                titleHeader = this.headersFlat.find(header => header.locked && header.key !== '__SELECT__');
            }

			return titleHeader;
        },
		cardTitleKey() {
            return this.cardTitleHeader?.key
		},
        configurableHeaders() {
            return this.configuredCardHeaders.filter(header => header.label).sort(function(x, y) { return y.visible - x.visible });
        },
        footerHeaders() {
            return this.cardHeaders.filter(header => header.footer)
        },
        footer() {
            const headersWithFooter = this.headersFlat.filter(header => header.footer)

            if (headersWithFooter.length) {
                let footer = {
                    id: "__FOOTER__",
                    row: {
                        __FOOTER__: true,
                    },
                };
                headersWithFooter.forEach(header => {

                    if (header.footer.fn && typeof(header.footer.fn) === "function") {
                        const reducedValue = this.rows
                            .map(row => row.row[header.key])
                            .reduce(header.footer.fn, header.footer.initialValue);
                        footer.row[header.key] = `${reducedValue} ${header.footer.symbol || ''}`;

                        if (header?.footer?.constant && typeof(header?.footer?.constant) === 'function') {
                            footer.row.constants = {[header.key] : header.footer.constant(reducedValue)};
                        }
                    }

                    if (!header.footer.fn && header?.footer?.constant) {
                        footer.row.constants = {[header.key] : header.footer.constant};
                        footer.row[header.key] = header.footer.constant;
                    }
                })
                return footer;
            }
            return null;
        },
        visibleRows() {
            return this.rows.slice().flatMap(row => ([
                row,
                ...(row.row.__sub_rows || []).map((subRow, i) => ({
                    row: {
                        ...subRow,
                        selectedHidden: true,
                        isSubrow: true,
                    },
                    id: row.id + "+" + i,
                    customRowStyle: { 
                        ...row.customRowStyle || {},
                        ...subRow.customRowStyle || {},
                    },
                })),
            ]))
        },
        titleActionsIntern() {
            const hasSelection = this.useSelected && !this.isSelectionEnabledAlways
            return [
                PageHeaderSimpleAction(`${TABLE_ACTIONS_PREFIX}CONFIG-EDIT`, 'Zeilen anpassen')
                    .withVisible(() => this.showOpenColumnsConfig),
                PageHeaderSimpleAction(`${TABLE_ACTIONS_PREFIX}TOGGLE_EDIT_MODUS`, this.selectionActive ? 'Abbrechen' : 'Bearbeiten')
                    .withVisible(() => hasSelection),
                ...(this.titleActions || []).map(act => act.withVisible(() => !hasSelection || this.selectionActive))
            ]
        },
        selectionActive() {
            return this.useSelected && (this.editModus || this.isSelectionEnabledAlways)
        },
        isSelectionEnabledAlways() {
            return !!this.config?.selectionActive
        },
        hasRows() {
            return this.rows?.length > 0;
        },
        areAllSelected() {
            return this.selectionAsBlacklist && !this.selected.length
                || !this.selectionAsBlacklist && this.selected.length == this.rowCount
            // return this.selected?.length > 0 && this.selected?.length == this.rows.length;
        },
        areOnlySomeSelected() {
            return this.selected?.length > 0 && !this.areAllSelected;
        },
        areAllHiddenDisabled() {
            return !this.rows.some(s => !s.selectedDisabled && !s.selectedHidden)
        },
        isSelected() {
            const result = {};
            if (this.selected) {
                this.selected.forEach(row => {
                    result[this.constructRowId(row)] = true;
                });
            }
            return result;
        },
        customRowClick() {
            return !!this.$listeners['clickRow']
        },
        singleSelection() {
            return !!this.config?.singleSelection
        },
        showOpenColumnsConfig() {
            return !!this.tableId && !this.isConfigurationReadOnly;
        },
        mobileTableId() {
            return this.tableId ? MOBILE_TABLE_ID_PREFIX + this.tableId : null
        },
    },
    watch: {
        rows() {
            this.closeEditModalIfNeeded();
        },
    },
    mounted() {
        if (this.mobileTableId) {
            this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, {
                configId: this.mobileTableId,
                configType: FC_CONFIG.TABLE_COLUMN_CONFIG,
            });
        }
    },
    methods: {
        getHeader(key) {
            return this.headersFlat
                .find(header => header.key == key);
        },
        isCheckmarked(id) {
            return !this.isSelected[id] !== !this.selectionAsBlacklist;
        },
        clickRowDisabled(row) {
            if (row.downloadLinkOptions?.disabled) {
                return true
            }

            const disableClickRow = this.config?.disableClickRow

            if (typeof disableClickRow === 'function') {
                return this.config.disableClickRow(row)
            } else {
                return disableClickRow
            }

        },
        selectRow(row, id) {
            if (this.selectionActive && !row.selectedDisabled && !row.selectedHidden) {

                if (this.singleSelection) {
                    this.onSingleSelect(id)
                } else {
                    this.onSelect(id)
                }
            }
        },
        onSingleSelect(id) {
            let isSelected = {
                [id]: true
            };
            this.emitSelected(isSelected);
        },
        onSelect(id) {
            let isSelected = {...this.isSelected};
            if (isSelected[id])
                delete isSelected[id];
            else
                isSelected[id] = true;
            this.emitSelected(isSelected);
        },
        onSelectAll(value) {
            if (this.selectionAsBlacklist != null) {
                this.$emit("selectionAsBlacklist", value);
                this.$emit("selected", []);
            } else {
                let isSelected = {};
                if (value) {
                    this.rows.filter(row => !row.selectedDisabled && !row.selectedHidden).forEach(row => {
                        isSelected[row.id] = true;
                    });
                }
                this.emitSelected(isSelected);
            }
        },
        emitSelected(isSelected) {
            const result = this.rows
                .filter(idRow => isSelected[idRow.id])
                .map(idRow => idRow.row);
            this.$emit("selected", result);
        },
        onAction({key, row}) {
            this.$emit("action-" + key, row);
            this.$emit("action", {key, row});
        },
        onHeaderAction({key, value}) {
            switch (key) {
                case `${TABLE_ACTIONS_PREFIX}TOGGLE_EDIT_MODUS`:
                    this.onSelectAll(false)
                    this.editModus = !this.editModus
                    break;
                case `${TABLE_ACTIONS_PREFIX}CONFIG-EDIT`:
                    this.openColumnsConfig();
                    break;
                default:
                    this.$emit('headerAction', { key, value, });
                    this.$emit(`headerAction-${key}`, value);
                    break;
            }
        },
        openColumnsConfig() {
            this.$refs.columnsConfig.open()
        },
		visibleInCard(headerKey, row) {
			return (
				headerKey !== "_v" &&
				headerKey !== "__SELECT__" &&
				headerKey in row &&
                row[headerKey] !== undefined && row[headerKey] !== null && row[headerKey] !== ""
			)
		},
		clickRow(event, row, id) {
            event.preventDefault();

            if (!this.clickRowDisabled(row) && !row.downloadLinkOptions) {
                if (this.customRowClick) {
                    this.$emit("clickRow", row);
                } else {
                    this.openRow = row;
                    this.$refs.editModal.open()
                }
            }
		},
        closeEditModalIfNeeded() {
            const { 
                openRow = {}, 
                rows = [],
            } = this;

            // close if openRow.id is not available on rows
            if (Object.keys(openRow || {}).length > 0 
                && !rows.some(row => row.id == openRow.id)) {
                this.$refs.editModal.close();
                this.openRow = {};
            }
        },
        clickCell(row, columnKey) {
            this.$emit("clickCell", {columnKey, row});
        },
        getWidthForIndent() {
            let indent = 0

            if (this.selectionActive) {
                indent += 37
            }

            return {
                width: `calc(100% - ${indent}px)`
            }
        },
        getWidthForRow(row) {
            let indent = 23.5

            if (this.clickRowDisabled(row)) {
                indent -= 23.5
            }

            return {
                width: `calc(100% - ${indent}px)`
            }
        },
    },
    activated() { // recovered from "KeepAliveOnBackNavigation"
        if (Object.keys(this.openRow || {}).length > 0) {
            requestAnimationFrame(() => this.$refs.editModal.open());
        }
    },
}
</script>

<style scoped>

.row-container {
    padding: 10px 0;
    border-bottom: 1px solid #c4c4c4;
}

.row-container:first-child {
	padding-top: 0;
}

.row-container:last-child {
	border-bottom: none;
	padding-bottom: 0;
}

.row-title:not(:empty) {
    margin-bottom: 6px;
	font-weight: bold;
	font-size: 1.1em;
}

.row-title:not(:empty):last-child {
    margin-bottom: 0px;
}

.subrow-title:not(:empty) {
    margin-bottom: 6px;
	font-weight: 600;
	font-size: 1em;
}

.subrow-title:not(:empty):last-child {
    margin-bottom: 0px;
}

.row-line {
	display: flex;
	justify-content: space-between;
	margin-bottom: 4px;
}

.row-line:last-child {
	margin-bottom: 0;
}

.header-row-line {
    display: flex;
	justify-content: space-between;
	font-weight: bold;
	font-size: 1.1em;
	margin-bottom: 6px;
}

.header-row-line:last-child {
	margin-bottom: 0;
}

.row-line-label {
	margin-right: 8px;
	font-weight: 600;
}

.row-line-value {
    text-align: right;
}
</style>