var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c("HeaderLogin", {
        on: {
          backButtonPressed: function($event) {
            return _vm.goToLogin()
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "login-content",
          style: { backgroundImage: _vm.loginContentBackground },
          attrs: { "data-app-content": "" }
        },
        [
          _c("div", { staticClass: "login-box mb-4" }, [
            _c("div", { staticClass: "login-logo-box" }, [
              _c("img", {
                attrs: { src: _vm.logoLoginBoxBackground, alt: "Makler Logo" }
              })
            ]),
            _c("hr", { staticClass: "login-logo__bottom--spacer" }),
            _c("div", { staticClass: "login-logo__box-title" }, [
              _vm._v(" Neues Pass­wort setzen ")
            ]),
            _c(
              "div",
              { staticClass: "login__form" },
              [
                _c("InputField", {
                  attrs: {
                    id: "username",
                    label: "Login&shy;nummer",
                    autocomplete: "username",
                    trimValue: ""
                  },
                  model: {
                    value: _vm.form.loginName,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "loginName", $$v)
                    },
                    expression: "form.loginName"
                  }
                }),
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      disabled:
                        _vm.form.loginName.length == 0 ||
                        _vm.tokenButtonsDisabled,
                      isSecondary: ""
                    },
                    on: { click: _vm.requestCodes }
                  },
                  [
                    _vm._v(
                      "Sicherheitscodes " +
                        _vm._s(_vm.codesRequested ? "erneut " : "") +
                        "anfordern"
                    )
                  ]
                ),
                _vm.codesRequested
                  ? _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _c("InputField", {
                          attrs: {
                            id: "emailCode",
                            label:
                              "Sicherheits&shy;code aus <nobr>E-Mail</nobr>",
                            inputmode: "numeric",
                            autocomplete: "one-time-code",
                            trimValue: ""
                          },
                          model: {
                            value: _vm.form.emailCode,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "emailCode", $$v)
                            },
                            expression: "form.emailCode"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            id: "smsCode",
                            label:
                              "Sicherheits&shy;code (aus SMS oder Aktivierungs&shy;brief)",
                            inputmode: "numeric",
                            autocomplete: "one-time-code",
                            trimValue: ""
                          },
                          model: {
                            value: _vm.form.smsCode,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "smsCode", $$v)
                            },
                            expression: "form.smsCode"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            id: "new-password",
                            label: "Neues Passwort",
                            type: "password",
                            allowPlainPassword: "",
                            allowPasswordRules: "",
                            autocomplete: "new-password"
                          },
                          model: {
                            value: _vm.form.newPassword,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "newPassword", $$v)
                            },
                            expression: "form.newPassword"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            id: "new-password-again",
                            label: "Neues Passwort bestätigen",
                            type: "password",
                            allowPlainPassword: "",
                            allowPasswordRules: "",
                            validateUntouched: "",
                            autocomplete: "new-password"
                          },
                          model: {
                            value: _vm.form.newPassword2,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "newPassword2", $$v)
                            },
                            expression: "form.newPassword2"
                          }
                        }),
                        _c("PasswordPolicy", { attrs: { isCollapsable: "" } }),
                        _c(
                          "BaseButton",
                          {
                            attrs: {
                              disabled:
                                !_vm.validation.valid ||
                                !_vm.arePasswordsFilled ||
                                _vm.submitPasswordChangeDisabled
                            },
                            on: { click: _vm.submit }
                          },
                          [_vm._v("Passwort ändern")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            !_vm.isBackToLoginHidden
              ? _c(
                  "div",
                  [
                    _c(
                      "BaseButton",
                      {
                        staticClass: "mr-16px mt-3",
                        attrs: { isLink: "" },
                        on: {
                          click: function($event) {
                            return _vm.goToLogin()
                          }
                        }
                      },
                      [_vm._v("Zurück zur Anmeldung")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }