<template>
  <div>

    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu" >
    </OptionMenu>

    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :id="$appNavigation.currentOptionMenuId"
      :actions="actions"
      @action-NEW="chooseGoal()">
    </PageHeaderTitleNavigation>

    <div>
      <div class="box__container">

        <Table v-if="!loading && rows.length"
            tableId="7cc3927e-1cfd-4027-863a-808f524ff456"
            :title="TABLE_TITLE"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="10"
            :mobileConfig="{}"
            @click-name="navigateTo('meineziele/view/' + $event.data.id)"
            @action-DELETE="openDeleteModal"
        >
            <template #chart="row">
                <PieChart 
                    style="width: 40px"
                    :chartData="eChartData(row.data)" 
                    :isTooltip="false"
                    height="40px"
                    doughnut
                />
            </template>
        </Table>
        <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
        <NoData v-else :title="TABLE_TITLE" />
      </div>
    </div>
    <BaseModal
      ref="confirmDeleteModal"
      labelButtonConfirm="Ja"
      labelButtonCancel="Nein"
      @onConfirmButton="deleteGoal(goalToDelete)"
      modalTitle="Möchten Sie dieses Ziel wirklich löschen?">
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MY_GOALS_TYPES from '@/store/myGoals/types';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, SlotColumn, CurrencyColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue';
import dayjs from 'dayjs';
import FinancialCalculator from '../retirementScenario/financialCalculator';
import PieChart from '@/components/charts/echarts/PieChart.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'Meine Ziele Liste';

export default {
  props: {
  },
  data() {
    return {
        TABLE_TITLE,
        loading: false,
        goalToDelete: null,
        headers: {
            lockedLeft: [
                TextColumn("name", "Bezeichnung").makeLink(),
                SlotColumn("chart", "Zielerreichung"),
                CurrencyColumn("goal", "Sparziel"),
            ],
            center: [
                CurrencyColumn("rate", "Sparrate"),
                DateColumn("date", "Ziel-Datum"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        },
    }
  },
  computed: {
    ...mapGetters({
        goals: MY_GOALS_TYPES.GETTERS.GOALS,
    }),
    rows() {
        const actions = [
            SimpleAction("DELETE", '', "Löschen"),
        ];
        return this.goals.map(item => ({
            id: item.myGoal.id,
            date: this.getEndDate(item),
            rate: this.getPaymentEachPeriod(item),
            goal: item.myGoal.type === 'retirement' ? item.monthlyRetirementWanted : item.futureValue,
            chart: '1',
            name: item.myGoal.title,
            data: item,
            actions,
        }));
    },
    actions() {
      return [
        PageHeaderSimpleAction('NEW', 'Neu')
      ]
    }
  },
  mounted: function() {
    this.getGoals();
  },
  methods: {
    async getGoals() {
      try {
        this.loading = true;
        await this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.GET_GOALS);
      } finally {
        this.loading = false;
      }
    },
    getPaymentEachPeriod(goalCalc) {
      return goalCalc.paymentEachPeriod;
    },
    getEndDate(goalCalc) {
      const futureValue = new Date();
      futureValue.setDate(1);
      if (goalCalc.myGoal.startDate) {
        if (goalCalc.myGoal.startDate instanceof Date) {
          futureValue.setMonth(goalCalc.myGoal.startDate.getMonth());
          futureValue.setFullYear(goalCalc.myGoal.startDate.getFullYear());
        } else {
          const stDate = dayjs(goalCalc.myGoal.startDate, 'DD.MM.YYYY').toDate();
          futureValue.setMonth(stDate.getMonth());
          futureValue.setFullYear(stDate.getFullYear());
        }
        const num = futureValue.getMonth() + Math.round(goalCalc.numberOfPayments);
        if (goalCalc && goalCalc.numberOfPayments) {
          futureValue.setMonth(num);
          return dayjs(futureValue).format('DD.MM.YYYY');
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    
    chooseGoal() {
        this.navigateTo('meineziele/select');
    },

    deleteGoal(goal) {
      if (goal && goal.id) {
        this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.DELETE_GOAL, {id: goal.id});
      }
    },
    openDeleteModal(row) {
        this.goalToDelete = row;
        this.$refs.confirmDeleteModal.open();
    },
    eChartData(item) {
      let percent = 0;
      if (item.myGoal.type === 'retirement') {
        percent = ((item.monthlyRetirementPrivate + item.monthlyRetirementProx) / item.monthlyRetirementWanted) * 100;
      } else {
        const payments = -FinancialCalculator.calculateFutureValue(
          item.presentValue,
          (dayjs(item.myGoal.startDate, 'DD.MM.YYYY').diff(new Date(), 'month')),
          item.paymentEachPeriod
        ) || 0;
        percent = ((item.presentValue + payments) / item.futureValue) * 100;
      }
      let resultData = [];
      resultData.push({
        label: '.',
        value: percent,
      })
      resultData.push({
        label: '.',
        value: 100 - percent,
      })
      return resultData;
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Meine Ziele', 
      to,
      from,
    });

    next();
  },
  components: {
    Table,
    BaseModal,
    PieChart,
    GhostLoading,
    NoData,
    PageHeaderTitleNavigation,
    OptionMenu
  },
};
</script>
