<template>
    <PaginatedTable v-if="!isLoading && hasData"
        ref="InsuranceGroupTable"
        :tableId="tableId"
        :title="title"
        :headers="headers"
        :pages="computedPages"
        :pageCount="pageCount"
        :rowCount="count"
        :dragnDropFilesOnRow="dragnDropFilesOnRow"
        :menuRowsPerPage="menuRowsPerPage"
        :pageSize="pageSize"
        :exportConfig="exportConfig"
        :selected="(isByPass || isBroker) ? selected : null"
        :selectionAsBlacklist="selectionAsBlacklist"
        :page="page"
        :headerActions="headerActions"
        :sorted="sortConfig"
        :thHoverDisabled="true"
        :mobileConfig="mobileConfig"
        enableToggleHorizontalScrollingConfig
        @rowsPerPage="$emit('rowsPerPage', $event);"
        @selected="$emit('selected', $event)"
        @selectionAsBlacklist="$emit('selectionAsBlacklist', $event)"
        @page="page=$event"
        @requestPage="$emit('requestPage', $event)"
        @sort="sortDataByColumn($event)"
        @files="$emit('row-files', $event)"
        @click-bezeichnung="editItemDetails"
        @click-maklernr="openBroker"
        @action="$emit('action', $event)"
        @headerAction="handleHeaderAction"
        @onScroll="$emit('onScroll', $event)"
    >
        <template #zurdnenAktion="row">
            <div class="insurance__table--company" title="Interne Nr. für die Zuordnung übernehmen">
              <PhArrowFatRight  class="clickable"  @click="$emit('zuordnenAktion', {key: 'ZUORDNEN_AKTION', row})" :size="24"/>

            </div>
        </template> 
        <template #statusMapped="row">
          <DropDownCell 
                :options="[
                  {label: 'aktiv', value: 'AKTIV', state: 'SUCCESS'}, 
                  {label: 'beantragt', value: 'BEANTRAGT', state: 'INFO'}, 
                  {label: 'gekündigt Mandant', value: 'GEKUENDIGT_MANDANT', state: 'DEFAULT'},
                  {label: 'gekündigt Partner', value: 'GEKUENDIGT_PARTNER', state: 'DEFAULT'}, 
                  {label: 'storniert', value: 'STORNIERT', state: 'DEFAULT'},
                  ]"
                  @selectOption="selectOption($event, row)"
                 v-model="row.statusMapped"/>           
        </template>   
        <template #gesellschaft="row">
            <div class="insurance__table--company" v-if="row.gesellschaft">
                <BaseImage v-if="row.gesellschaft.logoUrl"
                    class="insurance__table--company__logo"
                    :src="`${APP_API}/${row.gesellschaft.logoUrl}`"
                    :placeholder="imagePlaceholder"
                />
                <span>{{getGesellschaft(row)}} </span>
            </div>
        </template>
    </PaginatedTable>
    <GhostLoading v-else-if="isLoading" type="table" :title="title" />
    <NoData v-else :title="title" />
</template>

<script>
import PaginatedTable from "@/components/table2/PaginatedTable.vue";
import BaseImage from '@/components/core/BaseImage.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import { 
  MAP_STATUS_TO_PILL_TYPE,
} from '@/views/versicherungen/insurance-group/insuranceSearchConstants';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { SimpleAction } from '@/components/table2/table_util';
import ROLES from '@/router/roles';
import { maxLengthOrEllipsis } from '@/helpers/utils-helper.js'
import { PhArrowFatRight } from 'phosphor-vue';
import DropDownCell from "../bigTable/DropDownCell.vue";
import VERTRAG_STATUS_TYPES from "@/store/vertragStatus/types";
import dayjs from 'dayjs';

const APP_API = `${process.env.VUE_APP_API}/../`;
const categoryNetto = 'VORSORGE';
function getRate(value, period) {
  switch (period) {
    case 'monatlich':
      return value * 12;
    case 'vierteljährlich':
      return value * 4;
    case 'halbjährlich':
      return value * 2;
  }
  return value;
}

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    tableId: {
        type: String,
    },
    title: {
        type: String,
        default: "Versicherungsliste",
    },
    headers: {
        type: Object,
        required: true,
    },
    pages: {
        type: Object,
        required: true,
    },
    pageSize: {
        type: Number,
        default: 25,
    },
    dragnDropFilesOnRow: {
        type: Boolean,
        default: false,
    },
    mitZuordnenAktion: {
        type: Boolean,
        default: false,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
    count: {
        type: Number,
        required: true,
    },
    pageCount: {
        type: Number,
        required: true,
    },
    selected: {
        type: Array,
        default: null,
    },
    selectionAsBlacklist: {      // If this is set to true, all rows EXCEPT 'selected' will show a checkmark.
        type: Boolean,
        default: undefined, // If this prop isn't set, there will be no checkbox in the selection column's header
    },
    backAction: {
        type: Object,
        default: null,
    },
    headerActions: {
        type: Array,
        default: () => [],
    },
    exportConfig: {
      type: Object,
      default: () => null,
    },
    menuRowsPerPage: {
      type: Array,
      default: () => null,
    },
    insuranceCancelingViewEnabled: {
      type: Boolean,
      default: false,
    },
    mobileConfig: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters({
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isOriginalUserBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      userId: CORE_TYPES.GETTERS.GET_USER_ID,
    }),
    hasData() {
      return Object.keys(this.pages).some(pageIndex => this.pages?.[pageIndex]?.length > 0);
    },
    computedPages() {
        const result = {};
        Object.keys(this.pages).forEach(pageIndex => {
            result[pageIndex] = this.pages[pageIndex]?.map(row => {
                let actions = [
                    SimpleAction("OPEN-IN-NEW-TAB", '', "Vertrag in neuem Tab öffnen"),
                    SimpleAction("EMAIL_GESELLSCHAFT",'' , "E-Mail an Gesellschaft"),
                    SimpleAction('EMAIL_KUNDE', '', 'E-Mail an Kunde'),
                ];

                if (this.hasRoles([ROLES.ALLOW_VERS_SCHADEN])) {
                  const isLeistungsabrechnung = row?.kategorie == 'GESUNDHEIT' || row?.kategorie == 'VORSORGE';
                  actions.push(SimpleAction("SCHADENMELDEN", '', isLeistungsabrechnung ? 'Leistungsabrechnung' : 'Schaden melden'));
                }

                if (this.isOriginalUserIntern || this.isOriginalUserBroker) {
                  actions.push(SimpleAction("EMAIL_BRIEF", '', "Brief erstellen"))
                }

                actions.push(SimpleAction("ADD_ACTIVITY", '', "Aufgabe hinzufügen"));

                if('nicht definiert' === row?.bezeichnung) {
                  actions.unshift(SimpleAction("VERTRAG_OFFNEN", '', "Vertrag öffnen"));
                }
                actions.push(SimpleAction("DECKBLATT", '', "Deckblatt zum Einscannen anlegen"));

                if (this.backAction) {
                  actions.push(this.backAction);
                }
                if(row.isDeeplinkAvailable){
                  actions.push(SimpleAction("Deeplink", '', "Versicherungsportal"));
                }
                actions.push(SimpleAction("DELETE", '', "Löschen"));
                
                if (this.insuranceCancelingViewEnabled && (this.isFA || (!this.isFA && row.nummer))) {
                    actions.push(SimpleAction("KUNDIGUNG", '', "Kündigen"));
                }
                if (this.isBrokerOrBypass) {
                    actions.push(SimpleAction("VERTRAG_VEREINIGEN", '', "Diesen Vertrag vereinigen"));
                }
                let annual = '';
                if (row.kategorie === categoryNetto) {
                    annual = getRate(row.praemiento_zahlweise, row.zahlrhythm)
                } else {
                    annual = getRate(row.praemiebto_zahlweise, row.zahlrhythm)
                }

                return {
                    ...row,
                    praemiebto_zahlweise: row.praemiebto_zahlweise || 0,
                    praemiento_zahlweise: row.praemiento_zahlweise || 0,
                    weitereSparten: row.weitereSparten || '',
                    bestand: {
                        label: row.isEigenerVertrag ? 'Eigenbestand' : 'Fremdbestand',
                        type: row.isEigenerVertrag ? 'success' : 'info',
                    },
                    gesellschaftText: row.gesellschaft?.shortName || '',
                    annual,
                    selectedHidden: this.insuranceCancelingViewEnabled && row.status === "storniert",
                    vertragsinhaber:  row?.kundennr !== this.userId ? `${row?.kundeName}, ${row?.kundeVorname}` : null,
                    statusMapped: {label: row.status, value: row.statusEnum, state: row.state},
                    actions,
                };

            });
        })
        return result;
    },
  },
  data() {
    return {
        page: 0,
      APP_API,
      imagePlaceholder: {
        width: '40px',
        height: '24px',
      },
      sortConfig: {},
    };
  },
  methods: {
    selectOption(option, row) {
      const vertragStatus = {
        status: option?.label,
        gueltigab: dayjs().format('DD.MM.YYYY'),
        datumEingelesen: dayjs().format('DD.MM.YYYY'),
      };

      const payload = {vertragId: row.id, vertragStatus  };

      this.$store.dispatch(VERTRAG_STATUS_TYPES.ACTIONS.SAVE_VERTRAG_STATUS, payload)
        .then(response => {
          // TODO: display success/error dialog

        });

    },
    openColumnsConfig() {
      this.$refs.insuranceGroupTable.openColumnsConfig();
    },
    handlerTest($event) {
      console.log($event);
    },
    editItemDetails(row) {
      this.$emit('item-selected', row);
    },
    sortDataByColumn({key, sortDirection}){
        this.sortConfig = {key, sortDirection};
        switch (key) {
            case "kundennr": key = "customerID"; break;
            case "kundeName": key = "name"; break;
            case "kundeVorname": key = "vorname"; break;
            case "gesellschaft": key = "gesellschaftText"; break;
            case "bestand": key = "bestandText"; break;
            case "statusMapped": key = "statusText"; break;
        }
        this.$emit('onSort', {key, sortDirection});
    },
    getGesellschaft(row) {
        return row?.gesellschaft?.zweigstelleName || row?.gesellschaft?.shortName;
    },
    handleHeaderAction({ key, value, }) {
      this.$emit('headerAction', { key, value, })
      this.$emit(`headerAction-${key}`, value)
    },
    openBroker({maklernr}) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId: maklernr })
    },
  },
  components: {
    PaginatedTable,
    BaseImage,
    GhostLoading,
    NoData,
    PhArrowFatRight,
    DropDownCell,
  },
}
</script>

<style scoped>
.insurance__table--company {
  display: flex;
  align-items: center;
}
.insurance__table--company__logo {
  margin: 0 8px 0 0;
  max-width: 40px;
  height: auto;
}
</style>
