<template>
  <div v-if="hasAlert" class="header-alert__container" :class="headerAlertColorClass" v-resize-observer="setStyleProperties">
    <template v-if="isKundenzugang">
      Kundensicht
    </template>
    <template v-else-if="isMitarbeiterzugang">
      Mitarbeitersicht<span v-if="isReadOnly"> - Nur lesender Zugang</span>
    </template>
    <template v-else-if="isMaklerzugang">
      Maklersicht<span class="ml-3" v-if="isInaktiv">- Vermittler ist inaktiv</span><span class="ml-3" v-if="hatKeinenVertrag">- Vermittler hat keinen Vertrag</span>
    </template>
    <template v-else-if="isInaktiv">
      Vermittler ist inaktiv
    </template>
    <template v-else-if="hatKeinenVertrag">
      Vermittler hat keinen Vertrag
    </template>
    <template v-else-if="isReadOnly">
      Nur lesender Zugang
    </template>
    <template v-else-if="isInaktivCustomer">
      Kunde ist inaktiv
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

export default {
  computed: {
    ...mapGetters({
      isKundenzugang: CORE_TYPES.GETTERS.IS_KUNDENZUGANG,
      isMaklerzugang: CORE_TYPES.GETTERS.IS_MAKLERZUGANG,
      isMitarbeiterzugang: CORE_TYPES.GETTERS.IS_MITARBEITERZUGANG,
      inaktiv: CORE_TYPES.GETTERS.IS_INAKTIV,
      hatVertrag: CORE_TYPES.GETTERS.HAT_VERTRAG,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isOriginalUserIntern:CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      inaktivAllowed: CORE_TYPES.GETTERS.IS_RIGHT_SET_MAKLER_INAKTIV,
    }),
    hasAlert() {
      return [
        this.isKundenzugang,
        this.isMitarbeiterzugang,
        this.isMaklerzugang,
        this.isInaktiv,
        this.hatKeinenVertrag,
        this.isInaktivCustomer,
        this.isReadOnly,
      ]
      .some(v => v === true);
    },
    isInaktiv() {
      return this.isInaktivVisible && this.inaktiv
    },
    isInaktivVisible() {
      return this.isOriginalUserIntern || this.inaktivAllowed
    },
    // Currently only for employees
    isReadOnly() {
      return this.loginData?.rights?.employeeRights?.isReadOnly
    },
    isInaktivCustomer() {
      return this.loginData?.rights?.isInaktivCustomer || false;
    },
    hatKeinenVertrag(){
      return !this.hatVertrag && this.isBroker && this.isOriginalUserIntern
    },
    headerAlertColorClass() {
      if(this.isInaktiv || this.isInaktivCustomer || this.isReadOnly){
        return 'header-alert__danger'
      }
      else if (this.hatKeinenVertrag) {
        return 'header-alert__keinVertrag'
      }
      else {
        return 'header-alert__warning'
      }
    },
  },
  methods: {
    setStyleProperties() {
      requestAnimationFrame(() => {
        const containerEl = this.$el;
        const { offsetHeight=0 } = containerEl ?? {};
        document.documentElement.style.setProperty('--header-alert-height', `${offsetHeight}px`);
      });
    },
  },
  mounted() {
    this.$nextTick(this.setStyleProperties);
  },
}
</script>

<style scoped>
.header-alert__container {
  color: var(--color-box);
  padding: 4px 0;
  text-align: center;
}
.header-alert__warning {
  background: var(--color-warning);
}
.header-alert__danger {
  background: var(--color-danger);
}
.header-alert__keinVertrag {
  background: #ED9121;
}
</style>
