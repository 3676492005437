import CORE_TYPES from '@/store/core/types'
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import TAN_TYPES from "@/store/tan/types";
import STORNO_WARNUNG_TYPES from '@/store/stornoWarnung/types';
import LOG_TYPES from '@/store/log/types'
import VUBLOCK_TYPES from "@/store/vuBlock/types";
import {SimpleAction, Icon, DownloadLinkAction} from "@/components/table2/table_util.js";
import {sanitize} from '@/helpers/string-helper.js';
import BROKERDATA_TYPES from '@/store/brokerData/types';
import { mapGetters } from 'vuex';
import { replaceUmlaute, replaceAccentChars } from '@/helpers/commonfunctions';

const mixin = {
    data() {
        return {
            infoText: null,
            errorStr: null,
            ROWS_PER_PAGE_DEFAULT: 25,
        }
    },
    computed: {
        ...mapGetters({
            tablePageSize: BROKERDATA_TYPES.GETTERS.GET_BROKER_PAGE_TABLE_SIZE,
        }),
    },
    methods: {
        onPageSizeChange(value) {
            this.$store.commit(BROKERDATA_TYPES.MUTATIONS.PUT_BROKER_PAGE_TABLE_SIZE, value);
        },
        openLink(storno) {
            if (storno?.url) {
                switch (storno.url) {
                    case 'kundendaten':
                        this.openCustomerNewTab(storno);
                        break;
                    case 'vertragdaten':
                        this.openInsurancesNewTab(storno);
                        break;
                    case 'versschadendaten':
                        this.openSchadenNewTab(storno);
                        break;
                    case 'vublock':
                        this.openVersUebertragBlock(storno);
                        break;
                    case 'beratunguebersicht':
                        // to change
                        this.openCustomerNewTab(storno);
                        break;
                    default: break;
                }
            }
        },
        openCustomerNewTab(storno, params = {}) {
            if (storno?.kundennr) {
                this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, { data: 
                    { customerID: storno.kundennr,
                        person: {personalDataAddress: {firstName: storno.kundenVorname, lastName: storno.kundenName }} }});
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, Object.assign({}, { customerId: storno.kundennr}, params));
            }
        },
        openInsurancesNewTab(storno, stepKey) {
            let path = `/home/versicherungen/insurance-group/overview/${storno.versId || storno.vertragId}`;
            if (stepKey) {
                path += `?stepKey=${stepKey}`;
            }
            this.openCustomerNewTab(storno, { path: path });
        },
        openSchadenNewTab(storno) {
            this.openCustomerNewTab(storno, { 
                path: `/home/versicherungen/insurance-group/schadensmeldung/${storno.versId}/${storno.versSchadenId}`});
        },
        openKundenSeite(storno, was) {
            let path = null;
            if (was == 'Immobilien') {
                if (storno.foreignId) {
                    path = `/home/immobilien/edit/${storno.foreignId}/produktdaten`
                } else {
                    path = `/home/immobilien/search`
                }
            } else if (was == 'Kredite') {
                if (storno.foreignId) {
                    path = `home/verbindlichkeiten/credit/edit/${storno.foreignId}/vertragsdaten`
                } else {
                    path = `home/verbindlichkeiten/credit`
                }
            } else if (was == 'Multibanking') {
                path = `home/multibanking-account/overview`
            }
            if (path) {
                this.openCustomerNewTab(storno, {path});
            }
        },
        openZugangssperre(storno) {
            this.openCustomerNewTab(storno, {path: '/persoenlichedaten/settings/access-management'});
        },
        openVermoegenNewTab(storno) {
            this.openCustomerNewTab(storno, { 
            path: '/home/vermogensubersicht/depot'});
        },
        openGesellschaftNewTab(storno){
            this.$store.commit(GESELLSCHAFT_TYPES.MUTATIONS.RESET_STATE);
            this.$router.push({ 
                path: `/beratung/gesellschaften/${storno.gesellschaftArt || 'NONE'}/${storno.gesellschaftId}`,
            });
        },
        openVersUebertragBlock(storno) {
            this.$store.commit(VUBLOCK_TYPES.MUTATIONS.RESET_STATE);
            this.$router.push({
                path: `/intern/vublock/bearbeiten/${storno.versUebertragBlockId}`                
            });
        },
        openMailSettings(storno){
            this.$router.push({
                path: `/intern/email-access/ausgang`
            });
        },
        openDocument(row) {
            if (row.docType === 'info') {
                this.infoText = row.bemerkung;
                this.$refs.info.open();
            }
        },
        async createEmail(storno, kundennr) {
            const type = typeof storno?.versId == 'number' ? 'mailcomposer-insurance' : 'mailcomposer-single' ;
            this.$addBreadcrumb({
                label: `zurück zu den wichtigen Warnungen`,
                fullPath: this.$route.fullPath,
                breadcrumb: 'Mailcomposer',
              });
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {type, action: 'clear',});
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {type, action: 'clear',});

            if (storno.stornoId) {
                const data = kundennr ? await this.$store.dispatch(STORNO_WARNUNG_TYPES.ACTIONS.GET_EMAIL_DATA_KUNDE, storno)
                    : await this.$store.dispatch(STORNO_WARNUNG_TYPES.ACTIONS.GET_EMAIL_DATA_BEARBEITENDER, storno);

                const toEmail = (data.to || '').replace('>', '').split(' <')
                this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
                    sender: [{value: data.from, label:data.from}],
                    receiver: [{value: kundennr || data.to, label:data.to, email: toEmail && toEmail[1] || ''}],
                    subject: data.subject,
                    receiverCC: data.cc,
                    receiverBCC: data.bcc,
                    getComputedText: data.text,
                });
                if (storno?.versId) {
                    this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
                        data: [storno.versId],
                        navigateToAfterSendMail: {
                            path: this.$route.path,
                            query: this.$route.query,
                        }
                    });
                }
                this.navigateTo('/communication/' + type)
            }
        },
        navigateTo(event) {
          this.$router.push({ path: `${event}` });
        },
        createChat(data) {
            this.$router.push({name: 'new-ticket', params: {thema: data.betreff, bezugId: data.beteiligterId}});
        },
        buildPage(page, isOverview = false) {
            if (!page)
                return [];
            return page
                .filter(storno => storno)
                .map(storno => {
                    const actions = [];
                    if (!storno.bestaetigt && storno.art == 'comdirect TAN Freigabe notwendig'){
                        actions.push(SimpleAction("tan", 'PhCheck', "Auftrag freigeben via comdirect TAN"));
                    }
                    if (storno.chatData)
                        actions.push(SimpleAction("chat", 'PhChat', "An die vertragsbearbeitende Stelle eine Chat Nachricht schicken"));
                    if (storno.hasEmailDataBearbeitender)
                        actions.push(SimpleAction("emailBearbeitender", 'PhEnvelopeSimple', "An die vertragsbearbeitende Stelle eine E-Mail schicken"));
                    if (storno.url)
                        actions.push(SimpleAction("vertrag", 'PhArrowRight', "Vertrag öffnen"));
                    if (storno.depotnr)
                        actions.push(SimpleAction("vermoegen", 'PhArrowRight', "Vermögensübersicht öffnen"));
                    if (storno.hasEmailDataKunde)
                        actions.push(SimpleAction("emailKunde", 'PhEnvelopeSimple', "Diesem Kunden eine E-Mail schicken"));

                    if (storno.docType && storno.dokId) {
                        actions.push(DownloadLinkAction("dokument", 'PhFileArrowDown', storno.tooltip, storno.fileName, '/docId', {docId: storno.dokId}));
                    } else if (storno.docType && storno.fileId) {
                        actions.push(DownloadLinkAction("dokument", 'PhFileArrowDown', storno.tooltip, storno.fileName, '/get_simple_file', {fileId: storno.fileId}));
                    } else if (storno.docType === 'xls') {
                        const filename = `${replaceAccentChars(replaceUmlaute(storno.art))} ${storno.kundennr}.xls`;
                        actions.push(DownloadLinkAction("dokument", 'PhFileArrowDown', storno.tooltip, filename, '/getStornoXlsDocument', {stornoId: storno.stornoId}));
                    } else if (storno.docType) {
                        actions.push(SimpleAction("dokument", 'PhFileArrowDown', storno.tooltip));
                    }

                    if (storno.art == 'Zugangssperre Kunde') {
                        actions.push(SimpleAction("zugangssperre", 'PhArrowRight', "Zugangsverwaltung öffnen"));
                    } else if (storno.art == 'Loginfehler beim E-Mail Versand via Microsoft Konto'){
                        actions.push(SimpleAction("mailsettings", 'PhArrowRight', "Postausgangsserver-Einstellungen öffnen"))
                    } else if (storno.art == 'Immobilien') {
                        actions.push(SimpleAction("Immobilien", 'PhArrowRight', "Immobilien öffnen"));
                    } else if (storno.art == 'Kredite') {
                        actions.push(SimpleAction("Kredite", 'PhArrowRight', "Kredite öffnen"));
                    } else if (storno.art == 'Multibanking') {
                        actions.push(SimpleAction("Multibanking", 'PhArrowRight', "Multibanking öffnen"));
                    }

                    if (!storno.bestaetigt) {
                        actions.push(SimpleAction("confirm", 'PhCheck', "Position bestätigen"));
                    }

                    if (storno.freigabe) {
                        actions.push(SimpleAction("freigabe", storno.freigabe === 'FREIGABE_KUNDE_VORHANDEN' ? 'PhEye' : 'PhEyeSlash', 
                            storno.freigabe === 'FREIGABE_KUNDE_VORHANDEN' ? 'für Kunden freigeschaltet' : 'für Kunden verborgen'));
                    }
                    if (storno.fileId || storno.dokId) {
                        actions.push(SimpleAction("sign", 'PhNotePencil', "Dokument unterschreiben / abschließen"));
                    }
                    let bestaetigt = [];
                    if (storno.bestaetigt)
                        bestaetigt.push(Icon('PhCheck', "Ja", 20))

                    var div = document.createElement("div");
                    div.innerHTML = storno.beschreibung;
                    var beschreibung = div.textContent || div.innerText || ""

                    if (storno.chatData) {
                        // prepare the initial thema for a new ticket; if ticket not new, TicketContent will use thema from existing ticket:
                        var newTicketSubject = storno.kundenName + ", " + storno.kundenVorname + "; Kundennr: " + storno.kundennr + "; ";
                        if (storno.kontoNr) {
                            newTicketSubject += "Konto-/Depot-/VSNR: " + storno.kontoNr;
                        } else if (storno.deptoNr) {
                            newTicketSubject += "Konto-/Depot-/VSNR: " + storno.depotNr;
                        } else if (storno.versNr) {
                            newTicketSubject += "Konto-/Depot-/VSNR: " + storno.verstNr;
                        };
                        storno.chatData.betreff = newTicketSubject;
                    }
                    
                    return {
                        id: storno.stornoId,
                        ...storno,
                        selectedDisabled: !storno.stornoId,
                        kundenLinkName: !storno.kundenVorname && !storno.kundenName ? '' :  (storno.kundenName || '') + ", " + ((storno.kundenVorname || '')),
                        beschreibungShort: storno.beschreibungKurz,
                        beschreibung,
                        bestaetigt,
                        status: {
                            type: storno.status === 'WARNUNG' ? 'danger' : 'info',
                            label: storno.status === 'WARNUNG' ? 'Warnung' : 'Info',
                        },
                        url_or_depotnr: storno.url || storno.depotnr,
                        actions,
                    };
                });
        },
        async comdirectTan(storno, isOverview = false){
            const result = await this.$store.dispatch(TAN_TYPES.ACTIONS.ACTIVATE_COMDIRECT_TAN);
            if (result?.success){
                this.$store.dispatch(STORNO_WARNUNG_TYPES.ACTIONS.SEND_COMDIRECT_DOCUMENTS, { stornoId: storno?.stornoId, isOverview: isOverview });
            } else if (result?.message) {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, result.message)
            }
        },
        dokumentFreigeben(data, isOverview = false) {
            if (data?.fileId && data?.kundennr && data?.versId) {
                this.$store.dispatch(STORNO_WARNUNG_TYPES.ACTIONS.EXECUTE_FREIGABE, {
                        fileId: data?.fileId, kundennr: data?.kundennr, versId: data?.versId, stornoId: data?.stornoId, isOverview: isOverview
                    }).then(response => {
                        if (response?.errorStr) {
                            this.errorStr = response.errorStr;
                            this.$refs.hatFehler.open();
                        }
                });
            }
        },
        signPdf(storno) {
            if (storno?.stornoId) {
                this.$store.commit(STORNO_WARNUNG_TYPES.MUTATIONS.SET_STORNO_SELECTED, storno);
                this.openCustomerNewTab(storno, { path: `/communication/documents/openSignView/${storno.stornoId}` });
            }
        },
        sanitize(htmlString) {
            return sanitize(htmlString);
        },
    },
    watch: {
        tablePageSize: {
            handler: function() {
                if (this.tablePageSize > 0) {
                    this.ROWS_PER_PAGE_DEFAULT = this.tablePageSize;
                }
            },
            immediate: true
        }
    }
}

export default mixin;