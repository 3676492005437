import {parse, formatNumber, isNumber} from '@/helpers/number-formatter.js';

const wrapFloat = (float) => {
  return parseFloat(float);
};

/**
 * Class to calculate financilal formulas.
 * Usage:
 * ```
 * import { FinancialCalculator } from 'utils/financialCalculator'
 * const result = FinancialCalculator.method(params);
 * ```
 *
 * @export
 * @class FinancialCalculator
 */
export class FinancialCalculator {
  /**
   * Calculate future value of invevestment.
   *
   * @static
   * @param {number} initialValue
   * @param {number} interest
   * @param {number} period
   * @returns {number}
   * @memberof FinancialCalculator
   */
  static futureValue(initialValue, interest, period) {
    const x = 1 + interest / 100;
    return wrapFloat(initialValue * Math.pow(x, period));
  }

  static monthsToAchieveFutureValue(initialValue = 1, futureValue = 0, rendite = 0) {
    const x = 1 + rendite;
    const y = futureValue / initialValue;
    const jahre = initialValue == 0 || rendite == null ? 0 : (Math.log(y) / Math.log(x) || 0);
    return wrapFloat(jahre * 12);
  }

  /**
   * Accept feature value and retrun initila value of investment.
   *
   * @static
   * @param {number} fvalue
   * @param {number} interest
   * @param {number} period
   * @returns {number}
   * @memberof FinancialCalculator
   */
  static initialValue(fvalue, interest, period) {
    const x = 1 + interest / 100;
    return wrapFloat(fvalue / Math.pow(x, period));
  }

  /**
   *
   * Calculate number of payments
   * @static
   * @param {*} rate
   * @param {*} per
   * @param {*} pmt
   * @param {*} pv
   * @param {*} fv
   * @returns
   * @memberof FinancialCalculator
   */
  static numberOfPayments(
    rate,
    per,
    pmt,
    initialValue,
    futureValue
  ) {
    let nperValue = 0;
    const ratePerPeriod = rate / (per * 100);
    if (rate === 0) {
      nperValue = -(futureValue + initialValue) / pmt;
    } else {
      nperValue =
        Math.log((-futureValue * ratePerPeriod + pmt) / (pmt + ratePerPeriod * initialValue)) /
        Math.log(1 + ratePerPeriod);
    }
    return wrapFloat(nperValue);
  }

  /**
   *
   *
   * @static
   * @param {number} fv future value = target amount
   * @param {number} pv present value = initial amount
   * @param {number} rate interest > 0
   * @param {number} freq 12, if montly
   * @param {number} term hole period / freg
   * @returns {number} monthly payment
   * @memberof FinancialCalculator
   */
  static monthlyPayment(fv, pv, rate, freq, term) {
    const mounthRate = rate / 100 / freq;
    const x = Math.pow(1 + mounthRate, freq * term);
    return wrapFloat(((fv - pv * x) * mounthRate) / (x - 1));
  }

  /**
   *
   * @param {number} pv present value = initial amount
   * @param {number} pmt payments (montly or yearly)
   * @param {number} rate interest > 0
   * @param {number} freq  12, if montly
   * @param {number} term whole period
   * @returns {number} terget amount
   */
  static compoundFutureValue(
    pv,
    pmt,
    rate,
    freq,
    term
  ) {
    const monthInterest = rate / 100 / freq;
    const x = Math.pow(1 + monthInterest, freq * term);
    const fv = pv * x + pmt * ((x - 1) / monthInterest) * x;
    return wrapFloat(fv);
  }
  /**
   *
   *
   * @static
   * @param {number} pv
   * @param {number} pmt
   * @param {number} rate
   * @param {number} freq
   * @param {number} term
   * @returns {number}
   * @memberof FinancialCalculator
   */
  static compaundFutureValues(
    pv,
    pmt,
    rate,
    freq,
    term
  ) {
    const monthlyRate = rate / 100 / freq;
    const fvs = [pv];
    let fv = pv;
    for (let i = 1; i < term * freq; i++) {
      fv = (fv + pmt) * (1 + monthlyRate);
      fvs.push(fv);
    }
    return fvs.map(i => wrapFloat(i));
  }

  /**
   * @static
   * @param {string} str
   * @returns {number}
   * @memberof FinancialCalculator
   */
  static convertStringToNumber(str) {
    if (str === undefined || str === null) {
      return null;
    }
    if (typeof(str) === 'number') {
      return str;
    }
    if (str) {
      str = `${str}`;
      if(str.indexOf(',') >= 0) { // It can be in double format already
        str = str.replace(/\./g, ''); // remove all thousand points
        str = str.replace(',', '.'); // change german comma to english decimal point
      }
      let value = Number(str);
      if (isNaN(value))
        value = parseFloat(str);
      return value;
    } else {
      return null;
    }
  }

  /**
   * change english decimal point to german comma
   * @static
   * @param  {number} num
   * @returns {number}
   * @memberof FinancialCalculator
   */
  static convertNumberToStr(num, hideZero = true) {
    if (num) {
      return this.formatNumber(num.toFixed(2).replace('.', ','));
    } else if (!hideZero) {
      return '0,00';
    }
  }

  /** German Number Format with 2 Digits after 0 */
  static toGermanFormat(num, precision = 2) {
    if (isNumber(num)) {
      return num.toLocaleString('de-DE', {minimumFractionDigits: precision, maximumFractionDigits: precision})
    }
    
    return num
  }

  /**
   * Formats string as a german decimal with thousand points (.) and 0 from 2 decimals after comma (,)
   * @param {string} value
   * @return {string}
   */
  static formatNumber(value, hasZeroCent = true) {
    if (!value || value.length === 0) {
      return '';
    }
    let cent = '';
    const index = value.indexOf(',');
    if (index >= 0) {
      cent = value.substring(index + 1);
      if (!hasZeroCent) {
        cent = (cent === '00' || cent === '0') ? '' : cent;
      }
      value = value.substring(0, index) === '' ? '0' : value.substring(0, index);
    }
    if (value.indexOf('.') >= 0) {
      value = value.replace(/\./g, '');
    }
    let minus = '';
    if (value.startsWith('-')) {
      minus = '-';
      value = value.substring(1);
    }
    let newValue = '';
    let lastPointIndex = -1;
    for (let i = value.length - 3; i > 0; i = i - 3) {
      newValue = '.' + value.substring(i, i + 3) + newValue;
      lastPointIndex = i;
    }
    newValue = newValue ? value.substring(0, lastPointIndex) + newValue : value;
    if (cent) {
      newValue = newValue + ',' + cent;
    }
    return minus + newValue;
  }

  /**
   * Calculates accumulated value based on cash, single payment and/or withdrawal and a recurring rate.
   *
   * @param cashSum Cash to be calculated
   * @param singlePayment Single payment to cash
   * @param singleWithdrawal Single withdrawal off cash
   * @param rate Value of recurring rate
   * @param countRates Payment intervall of rate
   * @param year Period to calculate accumulated value for
   * @param interestYield Yield
   * @param tollfreeShare Toll free share
   * @returns accumulated Value
   * @memberof SmallRetirementComponent
   */
  static calculateAccumulatedValue = (cashSum, singlePayment, singleWithdrawal, rate, countRates, year, interestYield, tollfreeShare)  => {
    interestYield /= 100;
    tollfreeShare /= 100;
    if (interestYield == 0) {
        return ((rate * tollfreeShare * countRates * year) + (singlePayment * tollfreeShare + cashSum + singleWithdrawal) * (Math.pow(1, year)));
    } else {
        return (
            rate * tollfreeShare * countRates * (1 + 0.5 * interestYield) * ((Math.pow((1 + interestYield), year) - 1) / interestYield) +
            (singlePayment * tollfreeShare + cashSum + singleWithdrawal) * (Math.pow((1 + interestYield), year))
        );
    }
  }

  /** Get The Period or Number of Payments you have to do to get your future Value
   * @param presentValue the current available amount of money
   * @param futureValue the desired amount of money you want to have
   * @param paymentEachPeriod how much money you can afford for each payment period e.g. 100$ Each month
   * @param rate the percentage of your interest rate in decimal for a whole payment period e.g. 0.02 for a 2% rate each year.
   * @param paymentPeriod whats the period of payments e.g. 12 if it's every month
  */
  static calculateNumberOfPayments(presentValue, futureValue, paymentEachPeriod, rendite, paymentPeriod = 12){
    var tmpPresentValue = presentValue || 0;
    var numberPayments = 0;

    // const rate = rendite / paymentPeriod;
    const sparrate = paymentEachPeriod * paymentPeriod;

    if (futureValue && paymentEachPeriod){
      if (!rendite){
        numberPayments = (futureValue - tmpPresentValue) / sparrate;
      } else {
          // numberPaymentsPeriod = (1 * Math.log((2 * futureValue * rate + paymentEachPeriod * (2 + rate) * paymentPeriod) /
          //     (2 * tmpPresentValue * rate + paymentEachPeriod * (2 + rate) * paymentPeriod))) / Math.log(1 + rate);
          
        numberPayments = (1 * Math.log((2 * futureValue * rendite + sparrate * (2 + rendite)) /
          (2 * tmpPresentValue * rendite + sparrate * (2 + rendite)))) / Math.log(1 + rendite);
      }
    }

    return numberPayments * paymentPeriod;
  }

  /** Like Excel RMZ(ENglish PMT), Calculate the amount to pay each time to get your desired future amount
   * @param presentValue the current available amount of money
   * @param futureValue the desired amount of money you want to have
   * @param numberOfPayments period or how often to pay to get to your future value
   * @param rate the percentage of your interest rate in decimal for a whole payment period e.g. 0.02 for a 2% rate each year.
   * @param paymentPeriod whats the period of payments e.g. 12 if it's every month
  */
  static calculatePaymentEachPeriod(presentValue = 0, futureValue = 0, numberOfPayments, rendite, paymentPeriod = 12) {
    var rate = rendite ? parse(rendite) : 0.000000000001;
    let numberOfPaymentsPerPeriod = this.getNumberOfPaymentsPerPaymentPeriod(numberOfPayments, paymentPeriod);
    if (numberOfPaymentsPerPeriod && futureValue){
      const result = (+futureValue - +presentValue * Math.pow((1 + rate), numberOfPaymentsPerPeriod)) /
      (paymentPeriod * (1 + 0.5 * rate) * ((Math.pow((1 + rate), numberOfPaymentsPerPeriod) - 1) / rate));
      return parseInt(result);
    }
}

  /** Get Future Value
   * @param presentValue the current available amount of money
   * @param numberOfPayments period or how often to pay to get to your future value
   * @param paymentEachPeriod how much money you can afford for each payment period e.g. 100$ Each month
   * @param rate the percentage of your interest rate in decimal for a whole payment period e.g. 0.02 for a 2% rate each year.
   * @param paymentPeriod whats the period of payments e.g. 12 if it's every month
  */
  static calculateFutureValue(presentValue, numberOfPayments, paymentEachPeriod, rate = 0.02, paymentPeriod /*= 12*/){
    let numberOfPaymentsPerPeriod = this.getNumberOfPaymentsPerPaymentPeriod(numberOfPayments, paymentPeriod);
    if (numberOfPaymentsPerPeriod){
      if (numberOfPaymentsPerPeriod === 1) {
        return (paymentEachPeriod || 0) + (presentValue || 0);
      } else if (!rate || rate == 0) {
        return (paymentEachPeriod * numberOfPayments) + presentValue;
      }
      else {
        const result = paymentEachPeriod * paymentPeriod * (1 + 0.5 * rate) *
        ((Math.pow(1 + rate, numberOfPaymentsPerPeriod) - 1) / rate) +
            presentValue * Math.pow(1 + rate, numberOfPaymentsPerPeriod);
        return result;
      }
    }
    return presentValue || 0;
  }

  static calculateSparrate(endkapital, startkapital = 0, anzahlRaten = 1, rendite, periode = 12) {
    let result = 0;
    if (!rendite) {
      result = ((endkapital || 0) - (startkapital || 0)) / anzahlRaten;
    } else {
      const q = 1 + rendite/periode;
      const n = anzahlRaten || 1;
      result = (endkapital - startkapital * Math.pow(q, n)) * (q - 1) / (Math.pow(q, n) - 1) ;
    }
    return result;
  }

  static calculateEndkapital(startkapital = 0, anzahlRaten = 1, sparrate = 0, rendite, periode = 12) {
    let result = 0;
    if (!rendite) {
      result = startkapital + sparrate * anzahlRaten;
    } else {
      const q = 1 + rendite / periode;
      const n = anzahlRaten || 1; 
      result = startkapital * Math.pow(q, n) + sparrate * ((Math.pow(q, n) - 1) / (q - 1));
    }
    return result;
  }

  static calculateStartValue(futureValue, numberOfPayments, paymentEachPeriod, rate = 0.02, paymentPeriod = 12){
    let numberOfPaymentsPerPeriod = this.getNumberOfPaymentsPerPaymentPeriod(numberOfPayments, paymentPeriod);
    if (numberOfPaymentsPerPeriod){
      if (numberOfPaymentsPerPeriod === 1) {
        return paymentEachPeriod || 0;
      } else if (!rate || rate == 0) {
        return (paymentEachPeriod * numberOfPayments) + futureValue;
      }
      else {
        // const result = paymentEachPeriod * paymentPeriod * (1 + 0.5 * rate) *
        // ((Math.pow(1 + rate, numberOfPaymentsPerPeriod) - 1) / rate) +
        //     presentValue * Math.pow(1 + rate, numberOfPaymentsPerPeriod);
        const result = (futureValue - (paymentEachPeriod * paymentPeriod * (1 + rate) * ((Math.pow(1 + rate, numberOfPaymentsPerPeriod) - 1) / rate)))
         / (Math.pow(1 + rate, numberOfPaymentsPerPeriod));
        return result;
      }
    }
    return presentValue || 0;
  }

  /** Number of Payments per Period. The default is numberOfpayments per year for future Calculation
   * @param numberOfPayments period or how often to pay to get to your future value
   * @param paymentPeriod whats the period of payments e.g. 12 if it's every month
  */
  static getNumberOfPaymentsPerPaymentPeriod(numberOfPayments, paymentPeriod /*= 12*/){
    if (numberOfPayments && paymentPeriod){
      return numberOfPayments / paymentPeriod;
    }
    return 1;
  }

  /** Calculates the Proximate Monthly Retirement
   * @param bruttoIncomeMonthly brutto income in months
   * @param yearsTillRetirement how much years you still have to work till retirement
   * https://www.finanzrechner.org/finanztipps/rententipps-1-die-eigene-rente-berechnen/
   */
  static calculateMonthlyRetirementProx(bruttoIncomeMonthly, yearsTillRetirement){
    return (bruttoIncomeMonthly / 100) * yearsTillRetirement;
  }

  static calculateMonatlicheRenteOhneKapitalverzehr(rendite = 0, periode=1, kapital) {
    const q = 1 + rendite / 100 / 12;
    const qn = Math.pow(q, periode * 12);
    const result = (kapital * qn) * ((q-1) / (qn - 1));
    return result;
  }
  /**
   * Calc Present value
   * @param {*} rate Interest i.e. 0.02
   * @param {*} per period
   * @param {*} nper total number of periods
   * @param {*} pmt payment made each period
   * @param {*} fv future value
   * @returns
   * @memberof FinancialCalculator
   */
  static presentValue(rate, per, nper, pmt, fv) {
    let pv_value;
    nper = parseFloat(nper);
    pmt = parseFloat(pmt);
    fv = parseFloat(fv);
    rate = eval((rate) / (per * 100));
    if ((pmt === 0) || (nper === 0)) {
        return (0);
    }
    if (rate === 0) { // Interest rate is 0
        pv_value = -(fv + (pmt * nper));
    } else {
        const x = Math.pow(1 + rate, -nper);
        const y = Math.pow(1 + rate, nper);
        pv_value = -(x * (fv * rate - pmt + y * pmt)) / rate;
    }
    pv_value = conv_number(pv_value, 2);
    return (pv_value);
  }

}

function conv_number(expr, decplaces) {
  let str = '' + Math.round(eval(expr) * Math.pow(10, decplaces));
  while (str.length <= decplaces) {
      str = '0' + str;
  }

  const decpoint = str.length - decplaces;
  return (str.substring(0, decpoint) + '.' + str.substring(decpoint, str.length));
}

export const manLifeYears = [78.36, 77.64, 76.66, 75.67, 74.68, 73.69, 72.69, 71.70, 70.71, 69.71, 68.72, 67.72, 66.73, 65.73, 64.74, 63.75, 62.75, 61.77, 60.79, 59.81, 58.83, 57.86, 56.88, 55.91, 54.93, 53.96, 52.98, 52.01, 51.03, 50.06, 49.09, 48.12, 47.15, 46.18, 45.21, 44.25, 43.29, 42.32, 41.37, 40.41, 39.45, 38.50, 37.55, 36.61, 35.66, 34.73, 33.80, 32.87, 31.95, 31.03, 30.13, 29.23, 28.34, 27.46, 26.59, 25.73, 24.88, 24.05, 23.22, 22.42, 21.62, 20.83, 20.05, 19.29, 18.54, 17.80, 17.07, 16.35, 15.64, 14.94, 14.25, 13.57, 12.89, 12.23, 11.58, 10.93, 10.30, 9.68, 9.08, 8.49, 7.92, 7.38, 6.86, 6.38, 5.91, 5.47, 5.07, 4.69, 4.34, 4.01, 3.70, 3.42, 3.16, 2.94, 2.72, 2.55, 2.40, 2.27, 2.14, 2.02];
export const womanLifeYears = [83.18, 82.44, 81.46, 80.47, 79.48, 78.49, 77.49, 76.50, 75.50, 74.51, 73.51, 72.52, 71.52, 70.53, 69.54, 68.54, 67.55, 66.56, 65.57, 64.58, 63.60, 62.61, 61.62, 60.63, 59.64, 58.65, 57.66, 56.67, 55.69, 54.70, 53.71, 52.73, 51.75, 50.77, 49.78, 48.80, 47.82, 46.85, 45.87, 44.89, 43.92, 42.95, 41.98, 41.01, 40.05, 39.09, 38.13, 37.18, 36.23, 35.29, 34.35, 33.42, 32.49, 31.56, 30.65, 29.74, 28.83, 27.94, 27.05, 26.16, 25.28, 24.42, 23.56, 22.70, 21.85, 21.00, 20.17, 19.34, 18.53, 17.72, 16.91, 16.12, 15.34, 14.56, 13.79, 13.03, 12.27, 11.53, 10.81, 10.10, 9.42, 8.77, 8.15, 7.56, 6.99, 6.45, 5.95, 5.48, 5.04, 4.63, 4.26, 3.92, 3.61, 3.34, 3.09, 2.88, 2.71, 2.54, 2.40, 2.24];

export default FinancialCalculator;