<template>
  <div>
    <GhostLoading v-if="loading" type="table" :title="title" />
    <Table
      v-else
      :title="title"
      :headers="headers"
      :rows="rows"
      :headerActions="tableHeaderActions"
      @headerAction-ADD="openAddKundeHonorarModal"
      @action-EDIT="editKundeHonorar"
      @action-DELETE="deleteKundeHonorar"
    />

    <AddKundeHonorarModal ref="addKundeHonorarModal" />
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import FEE_TYPES from '@/store/fee/types';

import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from '@/components/table2/Table.vue';
import { TextColumn, DateColumn, CurrencyColumn, PercentageColumn, ActionColumn, SimpleAction, ConfirmedAction, } from '@/components/table2/table_util.js';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

import AddKundeHonorarModal from './AddKundeHonorarModal.vue';

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      kundeHonorare: FEE_TYPES.GETTERS.KUNDE_HONORARE,
    }),
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('ADD', 'hinzufügen').withAsPrimary(() => true),
      ];
    },
    headers() {
      return {
        center: [
          DateColumn('datum', 'Datum'),
          TextColumn('text', 'Text'),
          TextColumn('stundenDauer', 'Stunden'),
          CurrencyColumn('betrag', 'Betrag', 2),
          PercentageColumn('mwstSatz', 'MwSt. Satz', 1),
        ],
        lockedRight: [
          ActionColumn('actions', 'Aktionen'),
        ],
      };
    },
    rows() {
      return [ ...this.kundeHonorare || [] ].map(row => {
        const actions = [
          SimpleAction('EDIT', '', 'Bearbeiten'),
          ConfirmedAction('DELETE', '', 'Löschen', `Soll die Honorargebühr vom ${row.datum} wirklich gelöscht werden?`, 'Honorar löschen'),
        ];
        return {
          ...row,
          actions: row.editable ? actions : [],
        };
      });
    },
  },
  methods: {
    async findKundeHonorare() {
      try {
        this.loading = true;
        await this.$store.dispatch(FEE_TYPES.ACTIONS.FIND_KUNDE_HONORARE);
      } finally {
        this.loading = false;
      }
    },
    openAddKundeHonorarModal(row) {
      this.$refs.addKundeHonorarModal.open(row);
    },
    editKundeHonorar(row) {
      this.openAddKundeHonorarModal(row);
    },
    async deleteKundeHonorar(row) {
      await this.$store.dispatch(FEE_TYPES.ACTIONS.DELETE_KUNDE_HONORARE, row);
    },
  },
  mounted() {
    this.findKundeHonorare();
  },
  components: {
    GhostLoading,
    Table,
    AddKundeHonorarModal,
  },
}
</script>
