import GRAPHICAL_COURSE_TYPES from './types';
import {utils} from './helper';

export default {
  [GRAPHICAL_COURSE_TYPES.MUTATIONS.CONFIG](state, payload) {
    state.config = {...state.config, ...payload };
    state.config.kindDetails = payload.defaultDetails;
    state.config.beginTime = parseInt(payload.beginTime, 10);
    if (payload.minTransaktionenDatum && payload.berechnungsbeginn) {
      let part = payload.minTransaktionenDatum.split(/\D/).map(p => parseInt(p, 10));
      const dtTran = new Date(part[2], part[1]-1, part[0]);
      part = payload.berechnungsbeginn.split(/\D/).map(p => parseInt(p, 10));
      const dtBeg = new Date(part[2], part[1]-1, part[0]);
      state.config.grafNachTransakt = (dtTran.getTime() < dtBeg.getTime())
    } else {
      state.config.grafNachTransakt = false;
    }
    const beginYear = new Date(state.config.beginTime).getFullYear();
    const year = new Date().getFullYear();
    if (year - 1 <= beginYear) {
      state.config.ranges = [{value: 1, label: 'von Anfang an'}];
    } else {
      state.config.ranges = [{value: 1, label: '1 Jahr'}];
      if ( year - 3 > beginYear ) {
        state.config.ranges.push({value: 3, label: '3 Jahre'});
      }
      state.config.ranges.push({value: year - beginYear, label: 'von Anfang an'});
    }
    state.config.charts = [
      {value: 'aggregate', label: 'Grafischer Verlauf'},
      {value: 'perf_chart', label: 'Wertentwicklung'},];
    if (payload.hasDetails) {
      state.config.charts.push(
        { value: 'pos_value', label: 'Depotpositionen: Wertentwicklung' },
        { value: 'funds_course', label: 'Depotpositionen: Kursentwicklung' },
        { value: 'performance', label: 'Depotpositionen: Kursperformance' }
      );
    }
    state.sessions.sites.forEach(site => {
      state.sessions[site].ranges = state.config.ranges;
      state.sessions[site].charts = state.config.charts;
      state.sessions[site].grafNachTransakt = !!state.config.grafNachTransakt;
    });
  },

  [GRAPHICAL_COURSE_TYPES.MUTATIONS.SMOOTH](state, payload) {
    state.sessions[payload.site].smooth = payload.smooth;
    this.commit(GRAPHICAL_COURSE_TYPES.MUTATIONS.DEPOT);
  },

  [GRAPHICAL_COURSE_TYPES.MUTATIONS.KIND](state, payload) {
    state.sessions[payload.site].kind = payload.kind;
    this.commit(GRAPHICAL_COURSE_TYPES.MUTATIONS.DEPOT);
  },

  [GRAPHICAL_COURSE_TYPES.MUTATIONS.DEPOTID](state, payload) {
    if (payload?.depotid == -1) {
      payload.depotid = 'ALLE_DEPOTS_ANZEIGEN';
    }
    state.sessions[payload.site].depot = payload.depotid;
    // if (state.sessions[payload.site].zoom ) {
    //   state.sessions[payload.site].zoom = { start: 0, end: 0, startZoom: null, endZoom: null };
    // }
    state.sessions[payload.site].series = [];
    state.sessions[payload.site].xLabels = [];
    if (payload.site) {
      this.dispatch(GRAPHICAL_COURSE_TYPES.ACTIONS.DEPOT, { site: payload.site });
    }
  },

  [GRAPHICAL_COURSE_TYPES.MUTATIONS.ZOOM](state, payload) {
      
    if (payload.dateBegin !== undefined) {
      try {
        const session = state.sessions[payload.site];
        session.beginDate = payload.dateBegin; 
        session.endDate = payload.dateEnd;
        session.kind = payload.kind;
        if (payload.smooth != null) {
          session.smooth = payload.smooth;
        }
        const kind = session.kind;
        const source = state[kind] || state.details;
        if ( source && source[session.depot]) {
          session.performancePA = !!state.config.performancePA;
          session.grafNachTransakt = !!state.config.grafNachTransakt;
          const beginTime = Date.UTC(payload.dateBegin.getFullYear(), payload.dateBegin.getMonth(), payload.dateBegin.getDate(), 0, 0, 0);
          const endTime = Date.UTC(payload.dateEnd.getFullYear(), payload.dateEnd.getMonth(), payload.dateEnd.getDate(), 0, 0, 0);
          utils.refreshIntervall(source[session.depot], session, beginTime, endTime);
        }
      } catch (e) { console.error(e); }
    }
  },

  [GRAPHICAL_COURSE_TYPES.MUTATIONS.DEPOT](state) {
    state.sessions.sites.forEach(site => {
      const session = state.sessions[site];
      const depot = session.depot;
      const beginDate = session.beginDate;
      const endDate = session.endDate;
      const kind = session.kind;
      // const source = kind === 'aggregate' ? state.aggregate[depot] : state.details[depot];
      const source = state[kind] ? state[kind][depot] : state.details[depot];
      
      if ( source?.data && source.beginDate.getTime() >= beginDate.getTime() ) {
        if (source.data.options && source.data.options.len === 0) {
          session.xLabels = ['Keine Daten.'];
        } else {
          try {
            session.performancePA = !!state.config.performancePA;
            session.grafNachTransakt = !!state.config.grafNachTransakt;
            let endTime = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0);
            const startTime = Date.UTC(beginDate.getFullYear(), beginDate.getMonth(), beginDate.getDate(), 0, 0, 0);
            utils.refreshIntervall(source, session, startTime, endTime);
            console.log(session)
          } catch (e) { console.error(e); }
        }
      }
    })
  },
}