<template>
    <div>
      <OptionMenu 
        :id="$appNavigation.parentOptionMenuId" 
        :defaultMenu="$appNavigation.parentOptionMenu"
          />
  
      <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Zuordnung Gesellschaften zu BiPRO Zugängen" :actions="headerActions" @action-ADD="addNewGesellschaft"  />
  
      <BaseFilter 
        filterId="0c53a5a6-dbe7-4070-891f-74b68f585555"
        title="Bipro Kategorien filtern" 
        :metadata="filterMetadata" 
        :defaultOptions="defaultOptionsData"
        v-if="gesellschaftOptions && gesellschaftOptions.length"
        @onFilter="filterForm($event)">
      </BaseFilter>
      <GhostLoading v-else-if="!gesellschaftOptions || !gesellschaftOptions.length" type="block" :config="{ block: { height: 100, }, }" useBoxContainer />
  
      <div class="box__container" tid="3c93767d-4367-4793-9c1c-2ef36529b20e">
  
        <BigTable 
          tableId="a36f8c8e-9800-40c8-9d4c-ec44e59e7e94"
          :title="TABLE_TITLE"
          class="table-container"
          v-if="!loading && rows.length"
          :headers="headers"
          :rows="rows"
          :exportConfig="{roottext: TABLE_TITLE}"
          @action="executeAction($event)"
          :page="page"
          :sortingState="sortingState"
          :headerActions="headerActions"
          :initialPageSize="minNumRows"
          @onClickHeader="onClickHeader"
          @action-REMOVE_ITEM="confirmItemRemoval"

        >
          
        </BigTable>
        <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
        <NoData v-else :title="TABLE_TITLE" />
      </div>    
  
      <BaseModal @onConfirmButton="removeItem()" ref="removeItemConfirmation" modalTitle="Warnung" labelButtonConfirm="Löschen">
        <template v-if="selectedRow">
          Wollen Sie die Gesellschaft {{selectedRow.gesellschaft}} wirklich gelöscht werden?
        </template>
      </BaseModal>
  
    </div>
      
  </template>
  
  <script>
  import ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES from "../../store/zuordnungGesellschaftenBipro/types";
  import { mapGetters } from "vuex";
  import GhostLoading from '@/components/core/loading/GhostLoading.vue';
  import NoData from '@/components/core/NoData.vue';
  import Table from "@/components/table2/Table.vue";
  import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
  import BaseModal from '@/components/core/BaseModal.vue';
  import BaseFilter from "@/components/core/BaseFilter.vue";
  import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
  import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
  import validator from '@/mixins/validator'
  import InputField from '@/components/core/forms/InputField.vue'
  import ComboBox from '@/components/core/forms/ComboBox.vue'
  import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
  import BigTable, {  } from '@/components/bigTable/BigTable.vue';
  import {STRING_COLUMN, ACTION_COLUMN, FIXED_LEFT} from '@/components/bigTable/BigTable.vue';

  
  const TABLE_TITLE = 'Zuordnung Gesellschaften zu BiPRO Zugängen';
  const DEFAULT_GESELLSCHAFT = 'Adam Riese';
  
  export default {
    mixins: [validator],
    validators: {},
    components: {
      Table,
      GhostLoading,
      NoData,
      BaseModal,
      BaseFilter,
      OptionMenu,
      PageHeaderTitleNavigation,
      InputField,
      ComboBox,
      BigTable,
    },
    data: function () {
        return {
          TABLE_TITLE,
          loading: true,
          savedFilters: {},
          loadingGesellschaften: false,
          extZugangBezeichnung: null,
          headers: [
              { name: "Gesellschaft ID", label: "Gesellschaft ID", key: "gesellschaftId", width: 600, type: STRING_COLUMN, position: FIXED_LEFT },
              { name: "Gesellschaft Name", label: "Gesellschaft Name", key: "gesellschaftName", width: 600, type: STRING_COLUMN, visible: true },
              { name: "Bafin Kennun (VuNr.)", label: "Bafin Kennun (VuNr.)", key: "vuNrBafin", width: 600, type: STRING_COLUMN, visible: true },
              { name: "Aktionen", label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },
          ],
          sortingState: { key: "gesellschaftId", direction: "desc" },
          minNumRows: 100,
          page: 0,
          rows: [], 

        }
    },
    computed: {
      ...mapGetters({
        gesellschaftKennungen: ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.GETTERS.GESELLSCHAFT_KENNUNGEN,
        selectedRow: ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.GETTERS.SELECTED_ROW,
        searchResult: ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.GETTERS.SEARCH_RESULT,
      }),
      headerActions() {
          return [
            PageHeaderSimpleAction('ADD', 'Gesellschaft hinzufügen')
          ];
      },    
      gesellschaftOptions() {
        return this.gesellschaftKennungen?.gesellschaftOptions || [];
      },
      defaultOptionsData() {
        return {
            versicherungsgesellschaftZugang: {
                value: "",
            },
          }; 
      }, 
      filterMetadata() {
           return [
              {
                type: "group",
                key: "allgemein",
                label: "Allgemein",
                menuItems: [
                  {
                    type: 'combobox',
                    key: 'versicherungsgesellschaftZugang',
                    label: 'Versicherungsgesellschaft Zugang',
                    comboOptions: this.gesellschaftOptions || [],
                    emptyDenied: false,
                  },                   
                ],
              },     
            ];  
      },
    },
    methods: {
      async saveOrUpdate() {
        this.loading = true;
        try {
          await this.$store.dispatch(ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.ACTIONS.SAVE_BIPRO_CATEGORY, this.form);
          await this.retrieveData(this.savedFilters || {});
        } catch (error) {
          console.error("An error occurred while saving or updating:", error);
        } finally {
          this.loading = false;
          this.$refs.editModal.close();
        }
      },
      executeAction(actionData) {
        switch (actionData.key) {
          case 'REMOVE_ITEM':
            this.confirmItemRemoval(actionData.row);
            break;     
        }
      },   
      confirmItemRemoval(row) {
        this.$store.commit(ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.MUTATIONS.SELECT_ROW, row);
        this.$refs.removeItemConfirmation.open();
      },
      async removeItem() {
        this.loading = true;
        await this.$store.dispatch(ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.ACTIONS.REMOVE_ROW, {extZugangBezeichnung: this.selectedRow?.extZugangBezeichnung, gesellschaft: this.selectedRow?.gesellschaft});
        await this.retrieveData(this.savedFilters || {});  
        this.loading = false;
      },
      async retrieveData(extZugangBezeichnung) {
        this.loading = true;
        await this.$store.dispatch(ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.ACTIONS.GET_GESELLSCHAFT_KENNUNGEN, {extZugangBezeichnung});

        const fullActions = [
            SimpleAction("REMOVE_ITEM", '', "Löschen"),
        ];
  
        this.rows =  this.gesellschaftKennungen?.rows?.map(data => ({
          ...data,
            actions: fullActions,
        }));

        
        this.loading = false;
      },
      addNewGesellschaft() {
        this.$router.push({path: "/intern/gesellschaft-hinzufuegen", query: { extZugangBezeichnung: this.extZugangBezeichnung }});
      },
      async filterForm(filterConfig) {
        const getValueOrNull = (key) => {
        const value = filterConfig.find(fc => fc.key == key)?.value;
          return value === "" ? null : value;
        };

        this.extZugangBezeichnung = getValueOrNull("versicherungsgesellschaftZugang");

        if (this.extZugangBezeichnung !== null) {
          this.savedFilters.versicherungsgesellschaftZugang = this.extZugangBezeichnung;

          await this.retrieveData(this.extZugangBezeichnung);
        }
      },
      onClickHeader(headerMetadata) {
          this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
          // TODO: save the sorting state
      }, 
  
    },
    mounted() {
      this.retrieveData(DEFAULT_GESELLSCHAFT);  
    },
    async beforeRouteLeave(to, from, next) {
      this.$addBreadcrumb({
        label: 'zurück zu Zuordnung Gesellschaften zu BiPRO Zugängen', 
        fullPath: from.fullPath,
        breadcrumb: to?.meta?.breadcrumb?.label,
      });
  
      next();
    },
  
  
      
  }
  </script>