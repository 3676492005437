<template>
<div class="box__container-wrap account-list-card__container">
    <div class="box__container-rows">
      <Table
        class="account-list-card__table"
        :headers="headers"
        :rows="rows"
        rowId="displayName"
        @action-AUTHENTICATE_ACCOUNT="authenticateAccount"
        hidePagination
      >
        <template #displayName="row">
            <AccountTypeIcon :accountType="row.accountType" v-if="!isMobileNativeContext"/>
            <span :class="{'mobile-font': isMobileNativeContext}">{{row.displayName}}</span>
        </template>
      </Table>
    </div>

    <div class="bottom-row text-right mt-3" v-if="!isMobileNativeContext">
      <router-link to="/home/multibanking-account">
        Übersicht
      </router-link>
    </div>
    
    <BaseModal
      modalTitle="Kontoaktualisierung"
      :showCancelButton="false"
      ref="dataWillBeUpdated"
      labelButtonConfirm="Schließen">
      Daten werden aktualisiert
    </BaseModal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import CORE_TYPES from '@/store/core/types'
import Table from "@/components/table2/Table.vue";
import {ActionColumn, CurrencyColumn, SlotColumn, SimpleAction, PillColumn} from "@/components/table2/table_util.js";
import AccountTypeIcon from '@/components/multiBanking/AccountTypeIcon'
import BaseModal from '@/components/core/BaseModal.vue'
import BRIDGE_TYPES from '@/store/bridge/types';

export default {
  data() {
    return {
        requestedAccountToAuthenticateAgain: null,
    }
  },
  computed: {
    ...mapGetters({
      accounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    anyAuthNeedeed() {
      return this.accounts?.some?.(acc => acc.authNeeded)
    },
    headers() {
      return {
        lockedLeft: [
                SlotColumn("displayName", this.isMobileNativeContext ? '' : "Kontoname", 200).makeAlwaysVisible(),
                CurrencyColumn("totalAmount", this.isMobileNativeContext ? '' : "Kontostand").makeAlwaysVisible().withSumFooter(),
            ],
            center: [
                PillColumn("status", ""),
            ],
            lockedRight: [
                ActionColumn("actions", ""),
            ],
      }
    },
    rows() {
        return (this.accounts || []).map(row => {
            const actions = [];
            if (this.showUpdateAccountButton && row.authNeeded)
                actions.push(SimpleAction("AUTHENTICATE_ACCOUNT", '', "aktualisieren"));
            return {
                ...row,
                status: row.authNeeded ? {label: "Daten nicht aktualisiert", type: "danger"} : null,
                actions,
            }
        });
    },
    showUpdateAccountButton() {
      return !this.isBrokerOrBypass
    },
  },
  methods: {
    authenticateAccount(account) {
        this.requestedAccountToAuthenticateAgain = account
        this.$refs.dataWillBeUpdated.open()
        const relativePath = this.$route.path
        const callbackPath = window.location.href.substring(0, window.location.href.indexOf(relativePath)) + '/home/multibanking-account'
        this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.AUTHENTICATE_ACCOUNT, {
            callbackPath,
            providerId: account.providerId,
        })
    },
  },
  mounted() {
    this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_ALL_MULTIBANKING_DATA)
  },
  components: {
    Table,
    AccountTypeIcon,
    BaseModal
  }
}
</script>

<style scoped>
  .account-list-card__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .account-list-card__table {
    flex: 1 0 auto;
  }

  .mobile-font {
    font-weight: 500;
  }
</style>