<template>
  <div :style="iconContainerStyle">
    <SoscompIcon
      v-if="isSoscompFontIcon"
      :size="size" 
      :icon="authenticationType" />
    <PhScan
      class="native-auth-icon--iris"
      :size="size" 
      v-else-if="authenticationType === 'AUTH-IRIS'"/>
  </div>
</template>

<script>
import SoscompIcon from '@/components/icons/soscomp-font-icons/SoscompIcon.vue';
import { mapGetters } from 'vuex';
import BRIDGE_TYPES from '@/store/bridge/types';
import { PhScan } from 'phosphor-vue';

const SOSCOMP_ICON = ['AUTH-FINGERPRINT', 'AUTH-FACE', 'FACE-ID', 'TOUCH-ID']

export default {
  props: {
    size: {
      default: 32,
      type: Number
    }
  },
  computed: {
    ...mapGetters({
      authenticationType: BRIDGE_TYPES.GETTERS.GET_AUTHENTICATION_TYPE
    }),
    isSoscompFontIcon() {
      return SOSCOMP_ICON.includes(this.authenticationType);
    },
    sizePx() {
      return this.size + 'px';
    },
    iconContainerStyle() {
      return { 
        width: this.sizePx, 
        height: this.sizePx, 
      }
    },
  },
  components: {
    SoscompIcon,
    PhScan,
  }
}
</script>

<style scoped>
.native-auth-icon--iris {
  vertical-align: top;
}
</style>