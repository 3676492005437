<template>
  <div class="other-products-search">
    <template v-if="!hideNavigation">
      <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
      <PageHeaderTitleNavigation
        :id="$appNavigation.currentOptionMenuId"
        :title="pageMetadata.title"
        :actions="headerActions"
        @action-ADD="addNewOtherProduct()"
      />
    </template>

    <BaseFilter
      :title="pageMetadata.searchTitle"
      :metadata="searchMetadata"
      hasSmartSearch
      :configFilter="configFilter"
      :showSaveButton="false"
      :openFilterOnMount="false"
      @onFilter="search($event)" 
    />
    <HandleTablePin v-if="pinName" :keyOfPIN="pinName" />
    <div v-if="filtered" class="box__container">
        <Table v-if="!loading && rows.length"
            tableId="ad362da0-1e00-4c36-a734-1455868eb98e"
            :title="pageMetadata.title"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="25"
            :exportConfig="exportConfig"
            :mobileConfig="mobileConfig"
            @click-produktartText="editOtherProduct($event)"
            @clickRow="editOtherProduct($event)"
            @action-EDIT="editOtherProduct($event)"
            @action-DELETE="openDeleteModal"
        />
        <GhostLoading v-else-if="loading" type="table" :title="pageMetadata.title" />
        <NoData v-else :title="pageMetadata.title" />
    </div>

    <BaseModal
      ref="otherProductDeleteModal"
      size="sm"
      modalTitle="Vertrag löschen"
      @onConfirmButton="deleteOtherProduct()"
    >
      <template #default>
        Wollen Sie das Produkt {{ otherProductToDelete.produktbez || '' }} {{ otherProductToDelete.sprodNr || '' }} wirklich löschen?
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';

import BaseFilter from '@/components/core/BaseFilter.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, CurrencyColumn, ActionColumn, LoadingAction, SimpleAction} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import NoData from '@/components/core/NoData.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import { isRolesIncludes } from '@/router/guards';
import ROLES, { VIEW_ROLES } from '@/router/roles';
import { searchMenu, prepareFilters, } from './otherProductsSearchConstants.js';
import { 
  MAP_TYPE_TO_HOME_PATH,
  MAP_TYPE_TO_TITLE,
  MAP_TYPE_TO_TITLE_SINGULAR,
  WEITERE_VERMOEGEN_TYPE,
} from './otherProductsUtils.js';
import CORE_TYPES from '@/store/core/types';
import HandleTablePin from '@/components/core/HandleTablePin.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import BRIDGE_TYPES from '@/store/bridge/types';

const FREIE_EINGABE_TEXT = 'Freie Eingabe';
const TYPE_IMMOBILIE = 'Immobilie';

export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    hideNavigation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      filtered: false,
      deletingId: null,
      otherProductToDelete: {},
      configFilter: {
        placeholderForDefSearchInput: 'Produktnummer, Gesellschaft, Produktart, Produktbezeichnung',
        checkDefaultSearchInput: false,
      },
      mobileConfig: {
        headers: ['begin', 'aktWert', 'bezugssumme','company'],
      }
    };
  },
  computed: {
    ...mapGetters({
        filterCombos: OTHER_PRODUCTS_TYPES.GETTERS.FILTER_COMBOS,
        otherProducts: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCTS,
        sprodRights: OTHER_PRODUCTS_TYPES.GETTERS.RIGHTS,
        currentType: OTHER_PRODUCTS_TYPES.GETTERS.CURRENT_TYPE,
        isIntern: CORE_TYPES.GETTERS.IS_INTERN,
        isBroker: CORE_TYPES.GETTERS.IS_BROKER,
        pinName: OTHER_PRODUCTS_TYPES.GETTERS.CURRENT_PIN_NAME,
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
        isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
        isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    isSuperCustomer() {
      return this.hasRoles(ROLES.SUPER_CUSTOMER);
    },
    isWeitereVermoegen() {
      return this.type === WEITERE_VERMOEGEN_TYPE;
    },
    pageMetadata() {
      const title = MAP_TYPE_TO_TITLE[this.type];
      const titleSingular = MAP_TYPE_TO_TITLE_SINGULAR[this.type];
      const editPath = this.isMobileNativeContext ? `${MAP_TYPE_TO_HOME_PATH[this.type]}/overview` : `${MAP_TYPE_TO_HOME_PATH[this.type]}/edit`
      return {
        title,
        searchTitle: `${title} suchen`,
        newTitle: `${titleSingular} hinzufügen`,
        editPath,
      };
    },
    headerActions() {
      const { isCustomer, pageMetadata, isSuperCustomer} = this;
      if (isCustomer  && !isSuperCustomer) {
        return [
          PageHeaderSimpleAction('ADD', pageMetadata.newTitle)
        ];
      }

      return [];
    },
    searchMetadata() {
      return searchMenu(this.filterCombos, this.sprodRights);
    },
    headers() {
        const headers = {
            lockedLeft: [
                TextColumn("produktartText", "Produktart").makeLink().makeAlwaysVisible(),
            ],
            center: [],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        }
        if (isRolesIncludes([VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN])) {
            headers.lockedLeft.unshift(TextColumn("id", "Int.Nr.").makeAlwaysVisible());
        }
        if (this.currentType !== TYPE_IMMOBILIE) {
          headers.lockedLeft.push(TextColumn("produktbezText", "Bezeichnung"));
        }

        // center
        if (this.currentType === TYPE_IMMOBILIE) {
            headers.center.push(TextColumn("gebaeudetyp", "Gebäudetyp"));
            headers.center.push(DateColumn("begin", "Anschaffungsdatum"));
            headers.center.push(CurrencyColumn("aktWert", "akt. Verkehrswert").withSumFooter());
        } else {
            headers.center.push(TextColumn("company", "Gesellschaft"));
            headers.center.push(TextColumn("sprodNr", "Produktnummer"));
            headers.center.push(DateColumn("begin", "Beginn"));
            headers.center.push(DateColumn("ende", "Ende"));
            headers.center.push(TextColumn("zahlrhythm", "Zahlrythmus"));
            headers.center.push(CurrencyColumn("beitrag", "Beitrag"));
            headers.center.push(CurrencyColumn("aktWert", "akt. Wert").withSumFooter());
        }
        headers.center.push(CurrencyColumn("bezugssumme", this.currentType === TYPE_IMMOBILIE ? "Kaufpreis" : "Anschaffungswert").withSumFooter());
        headers.center.push(TextColumn("bearbeitungsstatus", "Status"));
        headers.center.push(TextColumn("bemerkung", "Beschreibung / Bemerkung"));
        headers.center.push(TextColumn("kundennr", "Kundennr"));
        headers.center.push(TextColumn("maklernr", "Vermittlernummer"));
        headers.center.push(TextColumn("maklername", "Vermittlername"));
        headers.center.push(TextColumn("kundenLastName", "Kundenname"));
        headers.center.push(TextColumn("kundenFirstName", "Vorname"));

        return headers
    },
    rows() {
        return (this.otherProducts || []).map(o => ({
            ...o,
            kundenFirstName: o?.kundenFullName.split(' ').slice(0, -1).join(' '),
            kundenLastName: o?.kundenFullName.split(' ').slice(-1).join(' '),
            produktbezText: o?.produktbez || o?.anlageArtenImmobilie,
            produktartText: this.getProduktartText(o),
            actions: ((otherProduct) => {
                const actions = [];
                if(!otherProduct?.produktart) { // adds edit action if there is no produktart content to click
                    actions.push(SimpleAction("EDIT", '', "Bearbeiten"));
                }
                if(otherProduct?.deletable) { // adds delete action if it is allowed
                    actions.push(LoadingAction("DELETE", '', "Löschen", undefined, (row) => row.id == this.deletingId));
                }
                return actions;
            })(o),
        }));
    },
    exportConfig() {
      if (this.pageMetadata?.title == "Weiteres Vermögen") {
        return {
          roottext: this.pageMetadata.title,
          dispatch: this.exportAsFile,
        }
      }
      return {
        roottext: this.pageMetadata.title
      }
    },
  },
  methods: {
    init() {
      this.$store.commit(OTHER_PRODUCTS_TYPES.MUTATIONS.SET_CURRENT_TYPE, this.type);
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.GET_FILTER_COMBOS);
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.GET_COMBOS);
      if (!this.isBroker && !this.isIntern) {
        this.search();
      }
    },
    async search($event) {
      try {
        this.loading = true;
        this.filtered = true;
        const filters = prepareFilters($event);
        await this.findOtherProducts(filters);
      } finally {
        this.loading = false;
      }
    },
    async findOtherProducts(filters) {
      await this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_OTHER_PRODUCTS, { filters, });
    },
    addNewOtherProduct() {
      this.$router.push(`${this.pageMetadata.editPath}/neu`);
    },
    editOtherProduct(sprod) {
      if(this.isBroker || this.isIntern) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, 
          { customerId: sprod?.kundennr, insurances: true, path: `${this.pageMetadata.editPath}/${sprod?.id}` });
      } else {
        this.$router.push(`${this.pageMetadata.editPath}/${sprod?.id}`);
      }
    },
    openDeleteModal(otherProduct) {
      this.otherProductToDelete = { ...otherProduct };
      this.$refs.otherProductDeleteModal.open();
    },
    async deleteOtherProduct() {
      const sprodId = this.otherProductToDelete?.id;
      this.deletingId = sprodId;
      await this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.DELETE_OTHER_PRODUCT, { sprodId, });
      this.deletingId = null;
      this.findOtherProducts();
    },
    getProduktartText(otherProduct) {
      if(!otherProduct?.produktart) return '';

      if(otherProduct.produktart.startsWith(FREIE_EINGABE_TEXT)) {
        return `${otherProduct.produktart.replace(FREIE_EINGABE_TEXT, '')} ${otherProduct.freieEingabeProduktart || ''}`.trim();
      }

      return otherProduct.produktart;
    },
    exportAsFile(fileEnding, params) {
      if (fileEnding == 'pdf') {
        params.create = 'pdfListe';
        params.lastRowSumme = true;
        params.nichtLeer = true;
      }
      return this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params);
    }
  },
  mounted() {
    this.init();
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      to,
      from,
      label: this.pageMetadata.title,
    });

    next();
  },
  components: {
    BaseFilter,
    Table,
    GhostLoading,
    BaseModal,
    NoData,
    OptionMenu,
    PageHeaderTitleNavigation,
    HandleTablePin,
  },
}
</script>
