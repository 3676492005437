import RUHESTANDSPLANUNG_TYPES from './types';
import axios from 'axios';
import { getFilenameFromHeaders } from '@/helpers/utils-helper';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

const config = {
  defaultSpinner: true,
}

export default {
  [RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_SCENARIO_LIST]({ commit }) {
    axios.get(`${process.env.VUE_APP_API}/ruhestandsplanung/getRuhestandsplanungList`, config).then(response => {
      if (response?.data) {
        commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIO_LIST_SUCCESS, response.data);
      }
    })
  },
  async [RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_SCENARIO]({ commit }, id) {
    await axios.get(`${process.env.VUE_APP_API}/ruhestandsplanung/getRuhestandsplanung?id=${id}`, config).then(response => {
      if (response?.data) {
        commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIO_SUCCESS, response.data);
        commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.SCENARIO_URSPRUNG, response.data);
      }
    })
  },
  async [RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_VERMOEGEN]({ commit }) {
    await axios.get(`${process.env.VUE_APP_API}/ruhestandsplanung/getVermoegen`, config).then(response => {
      if (response?.data) {
        commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_VERMOEGEN_SUCCESS, response.data);
      }
    })
  },
  [RUHESTANDSPLANUNG_TYPES.ACTIONS.SAVE_SCENARIO]({ commit, state}) {
    if (state.dataHasChanged || state.scenario?.id === 'new') {
      if (state.scenario?.title) {
        return axios.post(`${process.env.VUE_APP_API}/ruhestandsplanung/saveScenario`, state.scenario, config).then(response => {
          if (response?.data) {
            commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIO_SUCCESS, response.data);
          }
          return response.data
        })
      }
    }
  },
  [RUHESTANDSPLANUNG_TYPES.ACTIONS.SAVE_GOAL_SCENARIO]({ commit, state}, {goal}) {
    if (goal && state.scenario?.id) {
      goal.scenarioId = state.scenario.id;
      return axios.post(`${process.env.VUE_APP_API}/ruhestandsplanung/saveScenarioEvent`, goal, config).then(response => {
        if (response?.data) {
          commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIO_SUCCESS, response.data);
        }
      })
    }
  },
  [RUHESTANDSPLANUNG_TYPES.ACTIONS.REMOVE_GOAL_SCENARIO]({ commit}, id) {
    if (id) {
      return axios.delete(`${process.env.VUE_APP_API}/ruhestandsplanung/deleteScenarioEvent?id=${id}`, config).then(response => {
        if (response?.data) {
          commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIO_SUCCESS, response.data);
        }
      })
    }
  },
  [RUHESTANDSPLANUNG_TYPES.ACTIONS.DELETE_SCENARIO]({ commit }, id) {
    if (id) {
      return axios.delete(`${process.env.VUE_APP_API}/ruhestandsplanung/deleteScenario?id=${id}`, config).then(response => {
        if (response?.data) {
          commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIO_LIST_SUCCESS, response.data);
        }
      })
    }
  },
  async [RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_PDF]({ }, {id, data}) {
    if (id) {
      await axios.post(`${process.env.VUE_APP_API}/ruhestandsplanung/get_pdf?id=${id}`, data, {
        defaultSpinner: true,
        responseType: 'blob',
      }).then(response => {
        let filename = getFilenameFromHeaders(response.headers)
        if (response.data) {
          viewDocument({
            filename, 
            data: response.data, 
            contentType: 'application/pdf',
          }, true);
        }
      })
    }
  },
}
