<template>
  <div>
    <InputRadioBoxGroup 
      title="Ich handle auf"
      v-model="rechnungEigenFremd" 
      :validateUntouched="true"
      :values="[
          {value: 'EIGEN', label: 'eigene Rechnung'},
          {value: 'FREMD', label: 'fremde Rechnung'}
      ]"
      :disabled="isDisabled"
      @input="updateStore(getCategory(), 'rechnungEigenFremd', $event)"/>
    <InputRadioBoxGroup 
      title="Besteht ein Wohnsitz im Ausland?"
      v-model="hasResidenceCountry" 
      :validateUntouched="true"
      :values="[
          {value: '0', label: 'Nein'},
          {value: '1', label: 'Ja, um welches Land handelt es sich?'}
      ]"
      :disabled="isDisabled"
      @input="updateResidenceCountry($event)"/>
      <ComboBox v-if="hasResidenceCountry === '1'"
        v-model="residenceCountry" 
        :values="countries"
        :disabled="isDisabled"
        isComponentHalfSize
        @change="updateStore(getCategory(), 'residenceCountry', $event)">
      </ComboBox>
  </div>
</template>

<script>

import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import validator from "@/mixins/validator";

export default {
  mixins: [anlegerprofilMixin, validator],
  validators: {},
  components: {
    InputRadioBoxGroup,
    ComboBox,
  },
  data() {
    return {
      rechnungEigenFremd: 'EIGEN',
      residenceCountry: '',
      hasResidenceCountry: '0',
    };
  },
  watch: {
    personType(newVal) {
      if (newVal && newVal === 'person2') {
        this.patchForm()
      }
    }
  },
  computed: {
    countries() {
      return this.data?.extraData?.countries || [];
    }
  },
  mounted() {
    this.patchForm();
    this.updateWarnings();
  },
  methods: {
    patchForm() {
      const data = this.getPerson(this.data, this.getPersonType);
      if (data) {
        this.rechnungEigenFremd = data.rechnungEigenFremd;
        this.residenceCountry = data.residenceCountry ||  this.countries?.length && this.countries[0] || '';
        this.hasResidenceCountry = data.residenceCountry ? '1' : '0';
      }
    },
    updateResidenceCountry(value) {
      this.updateStore(this.getCategory(), 'hasResidenceCountry', value);
      this.updateStore(this.getCategory(), 'residenceCountry', value === '0' ? null : this.residenceCountry);
    }
  },
}
</script>

<style scoped>
</style>