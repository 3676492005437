<template>
    <div>
        <OptionMenu
            :id="$appNavigation.currentOptionMenuId"
            :defaultMenu="$appNavigation.currentOptionMenu" >
        </OptionMenu>

        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.label"
            :subtitle="subtitle"
            :id="$appNavigation.currentOptionMenuId"
            :actions="actions"
            @action-PDF="getPdf"
            @action-EDIT="$refs.einstellungenModal.open()"
        >
        </PageHeaderTitleNavigation>

        <div>
            <div class="box__container">
                <div class="box__title"><CurrencyLabel label="Vermögen heute:" showInlineLabel :value="gesamtStartVermoegenCurrentScenario"/></div>
                <div class="font-bold">Alter bei Beginn Ruhestand</div>
                <InputSlider v-if="alterList && alterList.length"
                    :value="scenario.alterRente + ''"
                    :values="alterList"
                    :disabled="isCalculating"
                    @input="doChanges('alterRente', +$event)"
                />
                <div class="box__title" v-if="!scenario.ohneKapitalverzehr"><CurrencyLabel label="Wunschrente (zu Rentenbeginn berüksichtig Inflation):" showInlineLabel :value="wunschrenteMitInflation(scenario)"/></div>
                <div class="box__title" v-else>Monatliche Rente aus Kapitalvermögen (sieh Zahlungsplan)</div>
            </div>
                

            <div class="dashboard-panel__widgets">
                <div class="box__container dashboard-panel-widget">
                    <div class="layout__negative-margin--8 pb-3 text-right">
                        <BaseButton isLink @click="openEventModal({zuflussType: 'AUSGABE'})" :disabled="isCalculating">
                            Ausgabe hinzufügen
                        </BaseButton>
                        <BaseButton isLink @click="openEventModal({zuflussType: 'EINNAHME'})" :disabled="isCalculating">
                            Einnahme hinzufügen
                        </BaseButton>
                    </div>
                    <Table v-if="rowsEvents && rowsEvents.length"
                        title="Meine Einahmen und Verbindlichkeiten"
                        rowId="id"
                        :headers="headersMyEvents"
                        :rows="rowsEvents"
                        :rowsPerPage="10"
                        hidePagination
                        :mobileConfig="{}"
                        @selected="onSelectedEvent"
                        :selected="selectedEvents"
                        @action-EDIT="editEvent"
                        @action-DELETE="deleteAction"
                        @click-title="editEvent"
                    >
                        <template #chart="row">
                            <PieChart 
                                style="width: 40px"
                                :chartData="eChartData(row)" 
                                :customColors="eChartColors"
                                :isTooltip="false"
                                height="40px"
                                doughnut
                            />
                        </template>
                    </Table>
                    <NoData v-else title="Meine Ziele" />

                    <GesamtVermoegen v-if="hasVermoegenOrAusgaben"/>
                </div>
            
                <div class="box__container dashboard-panel-widget">
                    <div class="mt-3 mb-3">
                        <RetirementChart
                            :data="{
                                data: apexSeriesCurrentScenario,
                                series: apexSeriesCurrentScenario,
                                xaxis: apexXaxisCurrentScenario,
                                annotations: apexAnnotationsCurrentScenario,
                                markers: apexMarkers,
                                dataLabels: {enabled: false},
                                stroke: {curve: 'smooth'},
                                autoUpdateSeries: true,
                            }"
                        />
                    </div>
                </div>

                <div class="box__container dashboard-panel-widget">
                    <div class="box__title">Ihre Einstellungen</div>
                    <Table
                        ref="scenarioOverviewSettingsTable"
                        tableId="dac58861-120a-4f32-0cc6-f55f487e86a4"
                        rowId="name"
                        hidePagination
                        :headers="headersOverview"
                        :rows="rowsSettings"
                    >
                        <template v-slot:value="row">
                            <div class="font-bold text-right">{{ formatValue(row.value, row.type) }}</div>
                        </template>
                    </Table>
                </div>

                <div class="box__container dashboard-panel-widget" v-if="!isRenteOk">
                    <div class="box__title">Aktuelle Situation</div>
                    <div v-html="aktuelleSituationText1"></div>
                    <div class="mb-2" v-html="aktuelleSituationText2"></div>
                </div>

                <div class="box__container dashboard-panel-widget">
                    <Table
                        ref="scenarioOverviewCalcTable"
                        tableId="scenarioOverviewCalcTable"
                        rowId="name"
                        hidePagination
                        :headers="headersOverview"
                        :rows="rowsCalc"
                    >
                        <template v-slot:value="row">
                            <div class="font-bold text-right">{{ formatValue(row.value, row.type, 0) }}</div>
                        </template>
                    </Table>
                </div>

                <div class="box__container dashboard-panel-widget" v-if="!isRenteOk">
                    <div class="box__title">Lösungsmöglichkeit 1: Höhere Sparrate</div>
                    <div v-html="loesungSparrateText"></div>
                </div>
                
                <div class="box__container dashboard-panel-widget" v-if="!isRenteOk">
                    <div class="box__title">Lösungsmöglichkeit 2: Höhere Rendite</div>
                    <div v-html="loesungRenditeText"></div>
                </div>
            </div>

            <div class="box__container"> 
                <BaseCollapsable :showSection="showExpanded1">
                    <template v-slot:title>
                    <div class="box__title">
                        Berechnung benötiges Vermögen
                    </div>
                    </template>
                    <template v-slot:content>
                    <Table v-if="tilgungsplanVermoegenBenoetigt"
                        rowId="jahr"
                        :headers="headersTilgungsplan"
                        :rows="tilgungsplanVermoegenBenoetigt"
                        :rowsPerPage="50"
                        :mobileConfig="{}"
                    >
                    </Table>
                    </template>
                </BaseCollapsable>
            </div>  

            <div class="box__container"> 
                <BaseCollapsable :showSection="showExpanded2">
                    <template v-slot:title> <div class="box__title"> Zahlungsplan</div></template>
                    <template v-slot:content>
                    <Table v-if="tilgungsplanRowsCurrentScenario"
                        rowId="jahr"
                        :headers="headersTilgungsplan"
                        :rows="tilgungsplanRowsCurrentScenario"
                        :rowsPerPage="50"
                        :mobileConfig="{}"
                    >
                    </Table>
                    </template>
                </BaseCollapsable>
            </div>  

            <BaseModal
                :modalTitle="goalModalTitle"
                ref="goalModal"
                labelButtonConfirm="Speichern"
                :confirmDisabled="!canSaveEvent"
                @onConfirmButton="saveEvent"
            >
                <EditForm ref="editForm"
                    :goal="selectedEvent"
                    :comboboxEventTypes="comboboxEventTypes"
                    @isValid="updateCanSaveEvent"
                />
            </BaseModal>

            <BaseModal
                modalTitle="Einstellungen"
                ref="einstellungenModal"
                labelButtonConfirm="Speichern"
                :showCancelButton="false"
                @close="saveFinancialPlaning"
            >
                <Einstellungen/>
            </BaseModal>

            
        </div>
    </div>
</template>

<script>
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import mixin from '@/views/ruhestandsplanung/mixin.js';
import RUHESTANDSPLANUNG_TYPES from '@/store/ruhestandsplanung/types';
import MY_GOALS_TYPES from '@/store/myGoals/types';
import {formatNumber} from '@/helpers/number-formatter.js';
import RetirementChart from '@/components/retirementScenario/RetirementChart.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import PieChart from '@/components/charts/echarts/PieChart.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, SlotColumn, IconColumn, PercentageColumn, CurrencyColumn, PillColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import EditForm from '@/views/ruhestandsplanung/EditForm.vue';
import Einstellungen from '@/views/ruhestandsplanung/Einstellungen.vue';
import GesamtVermoegen from '@/views/ruhestandsplanung/GesamtVermoegen.vue';
import {PhPencilLine, PhTrash} from 'phosphor-vue';
import BaseCollapsable from '@/components/core/BaseCollapsable.vue'
import NoData from '@/components/core/NoData.vue';
import InputSlider from '@/components/core/forms/InputSlider.vue';
import { range } from '@/components/brokerSearch/brokerSearchConstants.js';
import {sanitize} from '@/helpers/string-helper.js';

const ALTER_BEGINN_SLIDER = 45; 

export default {
    mixins: [mixin],
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        RetirementChart,
        CurrencyLabel,
        PieChart,
        Table,
        BaseModal,
        BaseButton,
        EditForm,
        BaseCollapsable,
        NoData,
        Einstellungen,
        GesamtVermoegen,
        InputSlider,
    },
    data() {
        return {
            showExpanded1: false,
            showExpanded2: false,
            selectedEvent: null,
            canSaveEvent: false,
            showOriginalWert: false,
            headersMyEvents: {
                lockedLeft: [
                    PillColumn("typeDescPill", "Typ"),
                    TextColumn("title", "Bezeichnung").makeAlwaysVisible().makeLink(),
                    CurrencyColumn("betrag", "Betrag", 0).makeAlwaysVisible(),
                ],
                center: [
                    DateColumn("startDate", "Start"),
                    DateColumn("endDate", "Ende"),
                    TextColumn("frequenzDesc", "Frequenz"),
                    PercentageColumn("rendite", "Rendite")
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            },
            headersTilgungsplan: {
                lockedLeft: [
                    TextColumn("jahr", "Jahr"),
                ],
                center: [
                    CurrencyColumn("depotwert", "Vermögen"),
                    CurrencyColumn("einnahmen", "Einnahmen"),
                    CurrencyColumn("ausgaben", "Ausgaben"),
                    PercentageColumn("rendite", "Rendite"),
                ],
                lockedRight: [
                    CurrencyColumn("summe", "Endwert"),
                ],
            },
            headersOverview: {
                lockedLeft: [
                    TextColumn("name", ""),
                    SlotColumn("value", ""),
                    IconColumn("action", "").makeLink(),
                ],
                center: [],
                lockedRight: [],
            },
        }
    },
    async mounted() {
        this.$store.commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.RESET_SCENARIO);
        await this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_SCENARIO, this.id);
        await this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_VERMOEGEN);
        await this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.GET_GOALS);
    },
    computed: {
        ...mapGetters({
            comboboxEventTypes: RUHESTANDSPLANUNG_TYPES.GETTERS.COMBOBOX_GOAL_TYPES,
        }),
        hasVermoegenOrAusgaben() {
            return this.vermoegen?.length || this.ausgaben?.length;
        },
        alterList() {
            return range(this.lebenserwartung(this.scenario), this.alterBeginnSlider).sort((a,b) => (a?.label || 0) - (b.label || 0)) || [];
        },
        alterBeginnSlider() {
            const alter = (this.alterAktuell(this.scenario) + 1) || 0;
            return alter < ALTER_BEGINN_SLIDER ? ALTER_BEGINN_SLIDER : alter;
        },
        subtitle() {
            return `<b>${this.scenario.title || ''}</b>`;
        },
        goalModalTitle() {
            return this.selectedEvent?.zuflussType === 'AUSGABE' ? 'Ausgabe erfassen' : 'Einnahmen erfassen'
        },
        rowsEvents() {
            const goals = this.goals?.map((row, index) => {
                return {
                    ...row,
                    betrag: row.zielsumme,
                    typeDescPill: {
                        label: row.typeDesc,
                        type: 'danger',
                    },
                    goalType: 'MY_GOAL',
                    actions: [SimpleAction("EDIT", PhPencilLine, "Bearbeiten")],
                }
            }) || [];
            const actions = [SimpleAction("EDIT", PhPencilLine, "Bearbeiten"), SimpleAction("DELETE", PhTrash, "Löschen")];
            goals.push(...this.scenario.scenarioEventList?.map((row, index) => {
                return {
                    ...row,
                    typeDescPill: {
                        label: row.typeDesc,
                        type: row.zuflussType === 'EINNAHME' ? 'success' : 'danger',
                    },
                    goalType: 'SCENARIO_EVENT',
                    rendite: row.rendite || '',
                    actions: actions,
                }
            }) || []);
            return goals;
        },
        actions() {
            const actions = [];
            if (this.scenario) {
                actions.push(PageHeaderSimpleAction('PDF', 'PDF').withDisabled(() => this.isCalculatiing));
                actions.push(PageHeaderSimpleAction('NEW_TICKET', 'Nachricht an Betreuer'));
                actions.push(PageHeaderSimpleAction('EDIT', 'Daten anpassen').withDisabled(() => this.isCalculatiing));
            }
            return actions;
        },
        rowsSettings() {
            const rows = [
                { name: 'Aktuelles Alter', value: this.currentYear - this.geburtsjahr(this.scenario), key: 'alter', type: 'age'},
                { name: 'Geplannter Ruhestandsbeginn', value: this.alterRente, key: 'alterRenteBeginn', type: 'age' },
                { name: 'Angenommene Lebenserwartung', value: this.lebenserwartung(this.scenario), key: 'lebenserwartung',type: 'age' },
                { name: 'Wunschrente (mit Inflation)', value: this.wunschrenteMitInflation(this.scenario), key: 'wunschRente', type: 'currency' },
                { name: 'Erwartete Rente', value: this.scenario.renteMonatlichErwartet, key: 'renteEffektiv',type: 'currency' },
                { name: 'Für den Ruhestand eingeplantes Vermögen', value: this.vermoegenGeplant, key: 'vermoegenGeplant',type: 'currency'},
                { name: 'Derzeitige Sparrate für den Ruhestand', value: this.sparrate, key: 'sparrate',type: 'currency' },
                //{ name: 'Angenommene, durchschnittliche Rendite (mit Inflation)', value: this.renditeDepotGewichtetCurrentScenario * 100, key: 'rendite',type: 'percent' },
                { name: 'Inflationsrate', value: this.scenario.inflation, key: 'inflation',type: 'percent' },
            ];
            return rows;
        },
        rowsCalc() {
            const rows = [
                { name: 'Versorgungslücke aus heutiger Sicht', 
                    value: this.isRenteOk ? 'keine' : this.vermoegenLuecke, 
                    key: 'vermoegenLuecke', 
                    type: this.isRenteOk ? '' : 'currency'},
                { name: 'Vermögen zum Ruhestandsbeginn', value: this.vermoegenGeplant, key: 'vermoegenRenteBeginn', type: 'currency' },
                { name: 'Vermögen reicht bis Alter', value: this.alterVermoegenEnde, key: 'alterVermoegenEnde',type: 'age' },
                { name: 'Benötigtes Vermögen zum Ruhestandsbeginn', value: this.vermoegenBenoetigtRenteBeginn, key: 'vermoegenBenoetigtRenteBeginn', type: 'currency' },
                { name: 'Geplantes Restkapital', value: this.scenario.restKapital || '', key: 'vermoegenBenoetigtRenteBeginn', type: 'currency' },
            ];
            return rows;
        },
        aktuelleSituationText1() {
            return this.restKapitalEffektiv < 0 
                ? 'Mit Ihrer derzeitigen Sparrate und dem bereits vorhandenen Vermögen werden Sie <span class="font-strong">nicht</span> genügend Geld '
                + 'zum Ruhestandsbeginn anhäufen, um während der gesamten Ruhestandspfase ausreichend versorgt zu sein. '
                
                : 'Mit Ihrer derzeitigen Sparrate und dem bereits vorhandenen Vermögen werden Sie nicht genügend Restkapital haben.';
        },
        aktuelleSituationText2() {
            return this.restKapitalEffektiv < 0 
                ? sanitize(`Durch Ihre aktuellen Sparanstrengungen von <span class="font-strong">${formatNumber(this.sparrate, 0)} €</span>
                 werden Sie bei Ruhestandsbeginn auf ein <span class="font-strong>Vermögen von 
                ${formatNumber(this.vermoegenGeplant, 0)} €</span> kommen. Da Sie monatlich ab Ruhestandsbeginn von Ihrem Vermögen einen Betrag von 
                <span class="font-strong">${formatNumber(this.wunschrenteMitInflation(this.scenario), 0)} €</span> 
                entnehmen müssen, wird das Kapital nur <span class="font-strong">bis zum Alter von ca. ${this.alterVermoegenEnde || 0} Jahre</span>
                 reichen - anstatt bis zu 
                der von Ihnen erwarteten <span class="font-strong">Lebensdauer von ${this.lebenserwartung(this.scenario)} Jahren.</span>`)
                
                : sanitize(`Durch Ihre aktuellen Sparanstrengungen von <span class="font-strong">${formatNumber(this.sparrate, 0)} €</span>
                 werden Sie bei Ruhestandsbeginn auf ein <span class="font-strong>Vermögen von 
                ${formatNumber(this.vermoegenGeplant, 0)} €</span> kommen. Da Sie monatlich ab Ruhestandsbeginn von Ihrem Vermögen einen Betrag von 
                <span class="font-strong">${formatNumber(this.wunschrenteMitInflation(this.scenario), 0)} €</span> 
                entnehmen müssen, werden Sie an der von Ihnen erwarteten 
                <span class="font-strong">Lebensdauer von ${this.lebenserwartung(this.scenario)} Jahren</span> das Restkapital von nur
                <span class="font-strong">${formatNumber(this.restKapitalEffektiv, 0)} €</span> haben statt gewünschte  
                <span class="font-strong">${formatNumber(this.scenario.restKapital || 0, 0)} €</span>.`);
        },
        loesungSparrateText() {
            return sanitize(`Um das <span class="font-strong">notwendige Kapital von ${formatNumber(this.vermoegenBenoetigtRenteBeginn, 0)} €</span> zu erhalten, muss 
            <span class="font-strong">die Sparrate um 
                ${formatNumber(this.sparrateErhoehungCalc)}
                €</span> 
            erhöht werden. Die <span class="font-strong">gesammte Sparrate</span> würde somit ca. <span class="font-strong"> 
                 ${formatNumber(this.sparrateCalc)}
            €</span> betragen.`);
            // ${formatNumber(this.sparrateErhoehungCalc, 0)}
            // ${formatNumber(this.sparrateCalc, 0)}
        },
        loesungRenditeText() {
            return sanitize(`Wenn monatlich nicht mehr gespart werden kann, muss das vorhandene Vermögen, sowie die Sparrate höher verzinst werden. 
            Um das <span class="font-strong">notwendige Vermögen von ${formatNumber(this.vermoegenBenoetigtRenteBeginn, 0)} €</span> ohne weitere Sparrate zu erreichen, 
            muss das Kapital mit <span class="font-strong">${formatNumber(this.renditeCalc, 2)} %</span> (plus Inflationsrate)
            statt mit <span class="font-strong">${formatNumber(this.renditeDepotGewichtetCurrentScenario, 2) || 0} %</span> verzinst werden.`);
        },
        vermoegenLuecke() {
            return this.isRenteOk ? 0 : (this.vermoegenBenoetigtRenteBeginn - this.vermoegenGeplant);
        },
        selectedEvents() {
            return (this.scenario?.myGoalList || []).concat(this.scenario.scenarioEventList?.filter(goal => goal.selected) || []);
        },
    },
    methods: {
        async getPdf() {
            await this.saveFinancialPlaning();
            const data = {
                apexSeries: this.apexSeriesCurrentScenario?.[0]?.data || [], 
                apexAnnotations: this.apexAnnotationsCurrentScenario?.xaxis?.map(elem => ({ x: elem?.x || 0, label: elem?.label?.text || ''})),
                vermoegenBenoetigtRenteBeginn: formatNumber(this.vermoegenBenoetigtRenteBeginn, 0),
                wunschrenteMitInflation: formatNumber(this.wunschrenteMitInflation(this.scenario), 0),
                restKapitalEffektiv: formatNumber(this.restKapitalEffektiv, 0),
                sparrate: formatNumber(this.sparrate, 2),
                vermoegenGeplant: formatNumber(this.vermoegenGeplant, 0),
                alterVermoegenEnde: formatNumber(this.alterVermoegenEnde, 0),
                renditeCalc: formatNumber(this.renditeCalc, 2),
                renditeDepotGewichtet: formatNumber(this.renditeDepotGewichtetCurrentScenario, 2),
                sparrateErhoehungCalc: formatNumber(this.sparrateErhoehungCalc),
            }
            this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_PDF, {id: this.scenario.id, data})
        },
        onSelectedEvent(selection = []) {
            this.rowsEvents.forEach(row => this.$store.commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_SELECTED_GOALS, {
                goal: Object.assign(row, {selected: selection?.some(sel => sel.id === row.id)})}));  
        },
        async openEventModal(goal) {
            await this.saveFinancialPlaning();  
            this.selectedEvent = goal;
            this.$refs.goalModal.open();
        },
        saveEvent() {
            const goal = this.$refs.editForm.getData();
            if (goal?.betrag && goal?.type && goal?.title) {
                delete goal.typeDescPill;
                delete goal.actions;
                delete goal.goalType;
                this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.SAVE_GOAL_SCENARIO, { goal: goal });
            }
        },
        updateCanSaveEvent(value) {
            this.canSaveEvent = value;
        },
        deleteAction(goal) {
            if (goal?.id) {
                this.$confirmModal({
                    title: `${goal.zuflussType === 'EINNAHME' ? 'Einnhame' : 'Ausgabe'} löschen`, 
                    message: `Wollen Sie die ${goal.zuflussType === 'EINNAHME' ? 'Einnhame' : 'Ausgabe'} ${goal.title} wirklich löschen?`, 
                    labelButtonConfirm: 'Löschen',
                }).then(() => {
                    this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.REMOVE_GOAL_SCENARIO, goal.id);
                });
            }
        },
        async editEvent(goal) {
            if (goal?.id) {
                if (goal.goalType === 'MY_GOAL' && this.goals?.find(g => g.id === goal.id)) {
                    this.$addBreadcrumb({
                        label: 'zurück',
                        fullPath: this.$route.fullPath,
                    });
                    this.$router.push(`${this.$route.path.startsWith('/service') ? '/service' : '/beratung'}/meineziele/${goal.id}/preview`);
                } else {
                    this.openEventModal(goal);
                }
            }
        },
    },
    async beforeRouteLeave(to, from, next) {
        if (this.canSaveScenario && this.id !== 'new') {
            await this.saveFinancialPlaning();
        }
        next();
    },
}
</script>

<style lang="scss" scoped>
.cards__item--inputs {
  margin: 20px -8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > * {
        width: calc(30% - 1rem);
        flex-basis: auto;
        margin: 0 20px;
    }
}
.dashboard-panel__widgets {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.dashboard-panel-widget {
  margin: 0 12px 24px;
  width: calc(50% - 24px);
  &.box__container {
    .dashboard-panel-widget__content {
      .box__container,
      .box__shadow {
        background: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
</style>