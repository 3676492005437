<template>
  <div>
    <PageHeaderTitleNavigation
      title="Vermögensverwaltung"
      subtitle="Strategien"
      :defaultMenu="$appNavigation.currentTabMenu"
      :actions="headerActions"
      @action-NEW="openNewStrategieModal()"
    />

    <BaseFilter
      filterId="searchVVStrategien"
      ref="baseFilter"
      title="Hier finden Sie die Vermögensverwaltungen, die Ihnen zur Verfügung stehen"
      showSaveButton
      hasSmartSearch
      :defaultOptions="defaultOptions"
      :metadata="searchMenu"
      :configFilter="configFilter"
      :predefinedFilter="null"
      @onFilter="handleSearch($event)"
      @onFilterZurucksetzen="$emit('onFilterZurucksetzen')"
    >
      <slot></slot>
    </BaseFilter>

    <div class="box__container">
      <Table
        v-if="rows && rows.length"
        tableId="9734b8b7-ac53-4017-b77c-4c84e6c651be"
        :title="TABLE_TITLE"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="100"
        rowId="schemaId"
        @action-factsheet="openGenerierteFactsheet"
        @action-delete="deleteStrategie"
        @action="handleActions"
        @click-name="openStrategie"
      >
        <template v-slot:isZielmarktVerlassen="row">
          <div v-if="row.isZielmarktVerlassen === '1'">
            <PhWarning
              :size="24"
              style="color: orangered"
              v-fc-tooltip="
                'Zielmarktdaten der zugelassenen Variante sind überschritten'
              "
            />
          </div>
          <div v-else></div>
        </template>
        <template v-slot:stati="row">
          <div v-if="row.stati && row.stati.length">
            <div v-for="(stat, index) in row.stati" :key="index">
              <Pill :label="stat.label" :type="stat.value"></Pill>
            </div>
          </div>
          <div v-else></div>
        </template>
        <template v-slot:wpVerteilung="row">
          <BaseCollapsable :showSection="showExpanded[row.index]">
            <template v-slot:title>
              <div>
                {{
                  row.wpVerteilung && row.wpVerteilung.length
                    ? row.wpVerteilung[0]
                    : ""
                }}
              </div>
            </template>
            <template v-slot:content>
              <div
                v-for="(strategie, index) in row.wpVerteilung &&
                row.wpVerteilung.length > 1
                  ? row.wpVerteilung.slice(1)
                  : []"
                :key="index"
              >
                <div>{{ strategie }}</div>
              </div>
            </template>
          </BaseCollapsable>
        </template>
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>

    <BaseModal
      ref="newVVStrategieModal"
      labelButtonConfirm="Strategie anlegen"
      :showCancelButton="true"
      size="sm"
      modalTitle="Strategie anlegen"
      @onConfirmButton="createNewStrategie"
    >
      <InputField label="Name" v-model="newVVStrategieName" type="text" />
    </BaseModal>
  </div>
</template>

<script>
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import LOG_TYPES from "@/store/log/types";
import CORE_TYPES from "@/store/core/types";

import { mapGetters } from "vuex";
import { PhWarning, PhFile } from "phosphor-vue";
import { buildMessage } from "@/helpers/log-message-helper";

import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  NumberColumn,
  PercentageColumn,
  CurrencyColumn,
  SlotColumn,
  SimpleAction,
  ActionColumn,
} from "@/components/table2/table_util.js";
import NoData from "@/components/core/NoData.vue";
import BaseFilter from "@/components/core/BaseFilter.vue";
import BaseCollapsable from "@/components/core/BaseCollapsable.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import InputField from "@/components/core/forms/InputField.vue";
import Pill from "@/components/core/Pill.vue";
import { viewDocument } from "@/components/core/download/DownloadLink.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import { PageHeaderSimpleAction } from "@/components/core/header-title-navigation/page-header-utils";
import mixin from "@/mixins/limit/limit-mixin.js";

const TABLE_TITLE = "Liste der verfügbaren Vermögensverwaltungen";
const FILE_PREFIX = "file";

export default {
  mixins: [mixin],

  components: {
    Table,
    PhWarning,
    OptionMenu,
    PageHeaderTitleNavigation,
    NoData,
    BaseFilter,
    BaseCollapsable,
    BaseModal,
    InputField,
    Pill,
    GhostLoading,
  },

  data() {
    return {
      TABLE_TITLE,

      loading: false,
      configFilter: {
        placeholderForDefSearchInput: "Titel",
        defaultSearchInputKeys: ["PARAM_SUCH_TITEL"],
        noResetOnDefaultSearchInputExit: true,
      },

      initialFilters: {
        // INPUT_CHK_NUR_AKTIV_GENEHMIGT: true, // aktiv: man sieht auch seine gerade eben neu angelegte strategie nicht
        INPUT_CHK_ANZEIGE_FREMDVV: true,
      },

      showExpanded: {},

      errorStr: "",
      newVVStrategieName: "",
    };
  },

  beforeMount() {},

  mounted() {
    if (!this.strategien?.length) {
      this.loading = true;
      this.$store
        .dispatch(VV_TYPES.ACTIONS.GET_STRATEGIEN_FILTER_ITEMS)
        .then(this.resetFilters())
        .then(() => this.$refs.baseFilter?.recreateRows())
        .then(() => this.getStrategien())
        .finally(() => (this.loading = false));
    }
  },

  computed: {
    ...mapGetters({
      strategien: VV_TYPES.GETTERS.STRATEGIEN,
      strategienEditRights: VV_TYPES.GETTERS.STRATEGIEN_EDIT_RIGHTS,
      savedFilters: VV_TYPES.GETTERS.STRATEGIEN_FILTERS,
      filterItems: VV_TYPES.GETTERS.STRATEGIEN_MENU_ITEMS,
      hatAdvisorRechte: VV_TYPES.GETTERS.HAT_ADVISOR_RECHTE,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    basePath() {
      return this.$route.path.split('/')[1]
    },
    headerActions() {
      return this.hatAdvisorRechte
        ? [PageHeaderSimpleAction("NEW", "Neue Strategie erstellen")]
        : [];
    },

    headers() {
      return {
        lockedLeft: [
          ...(this.strategienEditRights
            ? [
                // TextColumn("advisorMaklernr", "Advisor"), FIXME: Intern schon
                SlotColumn("stati", "Status"),
              ]
            : []),
          SlotColumn("isZielmarktVerlassen", ""),
        ],
        center: [
          TextColumn("name", "Name").makeLink().makeAlwaysVisible(),
          TextColumn("erstellerName", "Anbieter/Ersteller"),
          NumberColumn("sriLabel", "SRI", 0),
          CurrencyColumn("depotwertLabel", "Depotwert"),
          PercentageColumn("perfQ", "Perf. Q", 2).setColorFn((row) => {
            return row?.perfQ && +row.perfQ < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perf1M", "Perf. 1M", 2).setColorFn((row) => {
            return row?.perf1M && +row.perf1M < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perf3M", "Perf. 3M", 2).setColorFn((row) => {
            return row?.perf3M && +row.perf3M < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perf6M", "Perf. 6M", 2).setColorFn((row) => {
            return row?.perf6M && +row.perf6M < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perfYTD", "Perf. YTD", 2).setColorFn((row) => {
            return row?.perfYTD && +row.perfYTD < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perf1J", "Perf. 1J", 2).setColorFn((row) => {
            return row?.perf1J && +row.perf1J < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perf2J", "Perf. 2J", 2).setColorFn((row) => {
            return row?.perf2J && +row.perf2J < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perf3J", "Perf. 3J", 2).setColorFn((row) => {
            return row?.perf3J && +row.perf3J < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perf4J", "Perf. 4J", 2).setColorFn((row) => {
            return row?.perf4J && +row.perf4J < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perf5J", "Perf. 5J", 2).setColorFn((row) => {
            return row?.perf5J && +row.perf5J < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perfJahrMinus3", "Perf. " + this.getJahr(-3), 2).setColorFn((row) => {
            return row?.perfJahrMinus3 && +row.perfJahrMinus3 < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perfJahrMinus2", "Perf. " + this.getJahr(-2), 2).setColorFn((row) => {
            return row?.perfJahrMinus2 && +row.perfJahrMinus2 < 0 ? "color-danger" : "";
          }),
          PercentageColumn("perfJahrMinus1", "Perf. " + this.getJahr(-1), 2).setColorFn((row) => {
            return row?.perfJahrMinus1 && +row.perfJahrMinus1 < 0 ? "color-danger" : "";
          }),
          PercentageColumn("vola", "Volatilität", 2).setColorFn((row) => {
            return row?.vola && +row.vola < 0 ? "color-danger" : "";
          }),
        ],
        lockedRight: [ActionColumn("actions", "")],
      };
    },

    rows() {
      if (!this.strategien) return [];

      return this.strategien.map((elem, index) => {
        const actions = [];

        if (this.strategienEditRights && elem.kannAendern) {
          if (elem.deletable) {
            actions.push(
              SimpleAction("delete", '', "Vermögensverwaltung löschen")
            );
          }
        }

        if (elem.hatAktiveVersion) {
          actions.push(
            SimpleAction("factsheet", '', "Factsheet")
          );
        }

        elem.files.forEach(file => actions.push(SimpleAction(FILE_PREFIX + file.id, '', file.bezeichnung)))

        return {
          ...elem,
          name: elem.name || "ohne Name",
          index: index,
          actions: actions,
        };
      });
    },

    defaultOptions() {
      return this.initialFilters;
    },
    filters() {
      return this.savedFilters || {};
    },
    searchMenu() {
      return [
        {
          type: "group",
          key: "allgemein",
          label: "Allgemein",
          allowMultipleSearch: true,
          menuItems: [
            {
              type: "text",
              label: "Titel",
              key: "PARAM_SUCH_TITEL",
              emptyDenied: true,
            },
            ...(this.isIntern
              ? [
                  {
                    type: "text",
                    label: "Advisor (Name, Nr.)",
                    key: "PARAM_SUCH_ADVISOR",
                    emptyDenied: true,
                  },
                ]
              : []),
            ...this.filterItems,
          ],
        },
      ];
    },
  },

  methods: {
    getJahr(offset) {
      return new Date().getFullYear() + offset;
    },
    handleSearch(filterArr = []) {
      const filters = {};
      filterArr.forEach((filter) => (filters[filter.key] = filter.value));
      this.$store.commit(VV_TYPES.MUTATIONS.SET_STRATEGIEN_FILTERS, filters);
      this.getStrategien();
    },

    getStrategien() {
      this.loading = true;
      return this.$store
        .dispatch(VV_TYPES.ACTIONS.GET_STRATEGIEN)
        .finally(() => (this.loading = false));
    },

    resetFilters() {
      this.$store.commit(
        VV_TYPES.MUTATIONS.SET_STRATEGIEN_FILTERS,
        this.initialFilters
      );
    },
    handleActions(action) {
      if (action.key.startsWith(FILE_PREFIX)) {
        this.showDocument({id: action.key.substring(4)})
      }
    },
    showDocument(file) {
      if (file.id) {
        this.$store
          .dispatch(
            VV_TYPES.ACTIONS.OPEN_SCHEMA_DOCUMENT_PDF,
            file.id
          )
          .then((response) => {
            if (response?.data?.bytes) {
              viewDocument({
                filename: response.data.fileName || "unbenannt",
                data: response.data.bytes,
                contentType: response.data.contentType || "application/pdf",
              });
            } else {
              this.$store.dispatch(
                LOG_TYPES.ACTIONS.ADD_MESSAGE,
                buildMessage("Fehler: Datei nicht vorhanden", "danger")
              );
            }
          });
      }
    },
    openGenerierteFactsheet(row) {
      if (row?.schemaId) {
        this.$store
          .dispatch(
            VV_TYPES.ACTIONS.GET_GENERIERTE_FACTSCHEET_PDF,
            row.schemaId
          )
          .then((response) => {
            if (response?.data?.errorStr) {
              this.$store.dispatch(
                LOG_TYPES.ACTIONS.ADD_MESSAGE,
                buildMessage(response.data.errorStr, "danger")
              );
            } else if (response?.data?.bytes) {
              viewDocument({
                filename: response.data.fileName || "Factsheet.pdf",
                data: response.data.bytes,
                contentType: "application/pdf",
              });
            }
          });
      }
    },

    openStrategie(row) {
      if (row?.schemaId) {
        this.$addBreadcrumb({
          label: `zurück zu den Strategien`,
          fullPath: this.$route.fullPath,
          breadcrumb: `Vermögensverwaltung ${row.name}`,
        });

        const path = row.kannAendern
          ? `vv-schema-editor/${row.schemaId}`
          : `vv-schema-viewer/${row.schemaId}`;
        this.$router.push({ path: `/${this.basePath}/vv/${path}` });
      }
    },

    openNewStrategieModal() {
      this.$refs.newVVStrategieModal.open();
    },

    createNewStrategie() {
      this.$store
        .dispatch(VV_TYPES.ACTIONS.CREATE_STRATEGIE, {
          name: this.newVVStrategieName,
        })
        .then((schemaId) => {
          if (schemaId) {
            this.$addBreadcrumb({
              label: `zurück zu den Strategien`,
              fullPath: this.$route.fullPath,
              breadcrumb: `Neue Vermögensverwaltung`,
            });

            this.$router.push({
              path: `/${this.basePath}/vv/vv-schema-editor/${schemaId}`,
            });
          }
        });
    },

    deleteStrategie(row) {
      if (row?.schemaId) {
        this.$confirmModal({
          title: "Strategie löschen",
          message: `Wollen Sie die Strategie ${row.name} wirklich löschen?`,
          labelButtonConfirm: "Löschen",
        }).then(() => {
          this.$store.dispatch(VV_TYPES.ACTIONS.DELETE_STRATEGIE, row.schemaId);
        });
      }
    },
  },
};
</script>

<style>
</style>