<template>
  <div class="box__container">
    <BaseButton @click="openAddModal()" v-if="!isSmallScreen">Neue Anpassung</BaseButton>

    <Table v-if="!loading && rows.length"
        tableId="5d753192-f0fe-426d-82d3-32c8080572e9"
        :headers="headers"
        :rows="rows"
        :mobileConfig="{title: 'Anpassungen', headers: ['reason', 'date', 'aktWert'], disableClickRow: false}"
        :rowsPerPage="10"
        @click-reason="openEditModal"
        @action-DELETE="openDeleteModal"
    />
    <div v-else-if="loading" class="text-centered"><AnimatedSpinner /></div>
    <div v-else>Keine Daten</div>

    <BaseModal
      ref="anpassungDataModal"
      size="sm"
      modalTitle="Wert anpassen"
      :actions="baseModalActions"
       @action-SPEICHERN="actionSpeichern()"
      :showDefaultButtons="false"
    >
      <template #default>
        <InputField
          label="Anpassungsgrund"
          v-model="update.reason"
          :validateUntouched="true"
        />
        <DatePickerField
          isValueAsString
          label="Anpassungsdatum"
          v-model="update.date"
          :validateUntouched="true"
        />
        <InputField
          :label="aktWertLabel"
          type="currency"
          v-model="update.aktWert"
          :validateUntouched="true"
        />
      </template>
      <template #footer>
        <BaseButton isSecondary @click="closeAnpassungDataModal()" class="mr-3">Abbrechen</BaseButton>
        <BaseButton :disabled="validation.isInvalid('update')" @click="saveUpdate(); closeAnpassungDataModal();">Speichern</BaseButton>
      </template>
    </BaseModal>

    <BaseModal
      ref="anpassungDeleteModal"
      size="sm"
      modalTitle="Anpassung löschen"
      @onConfirmButton="deleteUpdate()"
    >
      <template #default>
        Soll die Anpassung wirklich gelöscht werden?
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';

import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, CurrencyColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';

import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';
import CORE_TYPES from '@/store/core/types';

import EVENT_BUS, { ANPASSUNGEN_NEUE_ANPASSUNG } from '@/event-bus';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

export default {
  mixins: [validator],
  data() {
    return {
      loading: false,
      update: {},
      updateToDelete: {},
    };
  },
  computed: {
    ...mapGetters({
      otherProduct: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT,
      updates: OTHER_PRODUCTS_TYPES.GETTERS.UPDATES,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
    }),
    baseModalActions() {
        const actions =  [];

        if (this.isSmallScreen) {
          actions.push(new BaseModalSimpleAction('SPEICHERN', 'Speichern'));
        }

        return actions;
      },    
    aktWertLabel() { // TODO test this logic - CHECK WITH BORYS
      if(this.otherProduct?.produktart === 'Immobilie') {
        return 'aktueller Verkehrswert';
      } else if(this.otherProduct?.produktTyp === 'WeitereVerbindlichkeiten') {
        return 'aktueller Stand';
      } else {
        return 'aktueller Wert';
      }
    },
    headers() {
        return {
            lockedLeft: [
                TextColumn("reason", "Anpassungsgrund").makeLink(),
            ],
            center: [
                DateColumn("date", "Datum"),
                CurrencyColumn("aktWert", this.aktWertLabel),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        };
    },
    rows() {
        const actions = [
            SimpleAction("DELETE", '', "Löschen"),
        ];
        return (this.updates || []).map(row => ({
            ...row,
            actions,
        }));
    },
  },
  methods: {
    actionSpeichern() {
      this.saveUpdate(); 
      this.closeAnpassungDataModal();
    },    
    registerEventBus() {
      EVENT_BUS.$on(ANPASSUNGEN_NEUE_ANPASSUNG, () => {
          this.openAddModal();
      });

    },
    unregisterEventBus() {
      EVENT_BUS.$off(ANPASSUNGEN_NEUE_ANPASSUNG);
    },    
    openAnpassungDataModal() {
      this.$refs.anpassungDataModal.open();
    },
    closeAnpassungDataModal() {
      this.$refs.anpassungDataModal.close();
    },
    openAddModal() {
      this.update = {};
      this.openAnpassungDataModal();
    },
    openEditModal(update) {
      this.update = { ...update };
      this.openAnpassungDataModal();
    },
    async saveUpdate() {
      await this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.SAVE_UPDATE, this.update);
    },
    openDeleteModal(update) {
      this.updateToDelete = { ...update };
      this.$refs.anpassungDeleteModal.open();
    },
    deleteUpdate() {
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.DELETE_UPDATE, { updateId: this.updateToDelete?.id, });
    },
  },
  mounted() {
    this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_UPDATES);
    this.registerEventBus();
  },
  destroyed() {
      this.unregisterEventBus();
  },    
  validators: {
    update: {
      reason: [required()],
      date: [required()],
    }
  },
  components: {
    BaseButton,
    Table,
    AnimatedSpinner,
    BaseModal,
    InputField,
    DatePickerField,
  },
}
</script>
