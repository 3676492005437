<template>
  <div>
    <div class="participants-input">
      <div class="w-100">
        <div class="label-content">
          <span class="pr-3">{{label}}</span>
          <span class="color-danger" v-if="warning">{{warning}}</span>
        </div>
        <template v-if="!isUsersPreview || combinedDisplay">
          <div v-if="multipleReceivers">
            <InputChips
              v-model="receiver"
              @inputText="searchReceiverUsers($event)"
              @clickItem="clickReceiveUsers($event)"
              @removeItem="deleteReceiveUsers($event)"
              @addItem="$emit('addItem', $event)"
              :availableOptions="availableUsers"
              :supportCustomValue="true"
              :warning="warning"
              :disableInput="disabled"
              :isClickable="isReceiveUserClickable"
              :maxItemsVisible="maxItemsVisible"
              :isMaklerOnly = "isMaklerOnly"
              notRemovableItem="..."
              redFlagWhenDifferentLabelValue
              :loading="loading"
            />
          </div>
          <div v-else>
            <InputField
              v-model="receiverStr"
              :supportCustomValue="true"
            />
          </div>
        </template>
      </div>

      <div>
        <div class="participants-actions">
          <BaseDropdownMenu placement="bottom-right" v-if="!combinedDisplay || isUserSelect">
            <template #hook-target >
              <ph-dots-three-vertical @click="showDropdown = !showDropdown" :size="24" class="clickable"/>
            </template>
            <template #default>
              <div class="dropdown-content">
                <div class="pr-2 clickable" v-if="isUserSelect && !isMaklerOnly" @click="$emit('createManualReceiver')">
                  <PhUserPlus size="20" />
                  <span class="pl-2" v-if="!isMaklerOnly">Empfänger hinzufügen</span>
                </div>
                <div class="pr-2 clickable" v-if="isUserSelect && !isMaklerOnly" @click="handleAddUsers">
                  <PhBookOpen size="20" />
                  <span class="pl-2">Empfängersuche</span>
                </div>
                <div class="pr-2 clickable" v-if="!isUserSelect && isMaklerOnly" @click="handleAddMakler">
                  <PhBookOpen size="20" />
                  <span class="pl-2">Maklersuche</span>
                </div>
                <div class="clickable" v-if="!isTableViewOnly && !combinedDisplay">
                  <div @click="toggleUserPreview()">
                    <PhRows size="20" v-if="!isUsersPreview && !isMaklerOnly"  />
                    <span class="pl-2" v-if="!isUsersPreview && !isMaklerOnly">zur Tabellenansicht wechseln</span>
                  </div>
                  <div @click="toggleUserPreview()">
                    <PhColumns size="20" v-if="isUsersPreview" />
                    <span class="pl-2" v-if="isUsersPreview">zur Einfügeansicht wechseln</span>
                  </div>
                </div>
                <div class="pr-2 clickable" v-if="isUserSelect || isMaklerOnly" @click="handleDeleteAllUsers">
                  <PhXCircle size="20" />
                  <span class="pl-2">Empfängerliste leeren</span>
                </div>
              </div>
            </template>
          </BaseDropdownMenu>
        </div>
      </div>
    </div>

    <div v-if="isUsersPreview || combinedDisplay" class="mt-4">
        <Table
            :hidePagination="hidePagination"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="maxItemsVisible"
            @click-name="clickReceiveUsers($event.data)"
            @action-EDIT="handleEdit"
            @action-DELETE="handleDelete"
            @action-COPY_ADDRESS="handleCopyAddress"
            @action-SEND_EMAIL="handleSendEmail"
            @action-SEND_EMAIL_SINGLE="handleSendEmailSingle"
            @action-SET_ADDRESS="handleSetAddress"
            @click-handleSendEmailSingle="handleSendEmailSingle"
            @click-handleSetAddress="handleSetAddress"
        >
            <template v-slot:role="row">
                <ComboBox
                    :disabled="row.data.disabled"
                    label=""
                    @change="onChange(row, $event, 'rolle')"
                    :value="row.rolle"
                    :values="rolleOptions"
                    :firstEmpty="false"
                />
            </template>
            <template v-slot:canChange="row">
                <InputCheckBoxItem
                    label="Ändern"
                    @input="onChange(row, $event, 'canChange')"
                    :value="row.canChange"
                />
            </template>
            <template v-slot:alarmMsc="row">
                <InputCheckBoxItem
                    label="anzeigen"
                    @input="onChange(row, $event, 'alarmMsc')"
                    :value="row.data.alarmMsc"
                    :disabled="!row.canChangeValues"
                />
            </template>
            <template v-slot:alarmMail="row">
                <InputCheckBoxItem
                    label="versenden"
                    @input="onChange(row, $event, 'alarmMail')"
                    :value="row.alarmMail"
                    :disabled="!row.canChangeValues"
                />
            </template>
            <template v-slot:alarmDailyMail="row">
                <InputCheckBoxItem
                    label="versenden"
                    @input="onChange(row, $event, 'alarmDailyMail')"
                    :value="row.alarmDailyMail"
                    :disabled="!row.canChangeValues"
                />
            </template>
        </Table>
    </div>
    <BaseModal ref="editModal"
      modalTitle=""
      :showDefaultButtons="false">
      <InputField
        label="Name"
        placeholder="Name"
        v-model="form.label"
        type="text"
      ></InputField>
      <InputField
        :label="!isFax ? 'E-Mail' : 'Fax'"
        :placeholder="!isFax ? 'E-Mail' : 'Fax'"
        v-model="form.email"
        type="text"
      ></InputField>
      <div class="modal-footer">
        <BaseButton @click="saveParticipant" type="submit">Ok</BaseButton>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputChips from '@/components/core/input-chips/InputChips.vue';
import InputField from '@/components/core/forms/InputField.vue';
import {PhUserPlus, PhRows, PhColumns, PhBookOpen, PhDotsThreeVertical, PhXCircle, PhHouseSimple,PhEnvelopeSimple} from 'phosphor-vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, SlotColumn, IconColumn, Icon} from "@/components/table2/table_util.js";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import CALENDAR_TYPES from "@/store/calendar/types";
import BaseModal from "@/components/core/BaseModal.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import BaseDropdownMenu from "@/components/core/BaseDropdownMenu.vue";
import { buildMessage } from '../../helpers/log-message-helper';
import LOG_TYPES from '@/store/log/types';
import CORE_TYPES from '@/store/core/types'
import ROLES from '@/router/roles';

const MAX_ITEMS_VISIBLE = 15;

export default {
  components: {
    InputChips,
    InputField,
    PhUserPlus, PhRows, PhColumns, PhBookOpen, PhDotsThreeVertical, PhXCircle, PhHouseSimple, PhEnvelopeSimple,
    Table,
    ComboBox,
    InputCheckBoxItem,
    BaseModal,
    BaseButton,
    BaseDropdownMenu,
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    warning: {
      type: String,
      default: ''
    },
    isUserSelect: {
      type: Boolean,
      default: true
    },
    isRole: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    availableUsers: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multipleReceivers: {
      type: Boolean,
      default: true
    },
    isReceiveUserClickable: {
      type: Function,
    },
    maxItemsVisible: {
      type: Number,
      default: MAX_ITEMS_VISIBLE
    },
    isTableViewOnly: {
      type: Boolean,
      default: false
    },
    combinedDisplay: {
      type: Boolean,
      default: false
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    showMailAndAddressActions: {
      type: Boolean,
      default: false
    },
    isFax: {
      type: Boolean,
      default: false
    },
    isMaklerOnly: {
      type: Boolean,
      default: false
    },
    bereich: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isUsersPreview: false,
      form: {
        label: '',
        email: '',

      },
      selectedParticipant: 0,
      showDropdown: false,
      isMultipleBesitzerRolles: false,
    }
  },
  mounted() {
    this.isUsersPreview = this.isTableViewOnly;
    if (this.isRole) {
      this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_APPOINTMENT_COMBOS);
    }
  },
  methods: {
    onChange(row, value, field) {
      if(field === 'rolle') {
        row.data.rolle = value;
        switch (value) {
          case 'BESITZER':
          case 'BEARBEITER':
            row.data.canChange = true;
            row.data.alarmMail = true;
            row.data.alarmDailyMail = true;
            row.data.alarmMsc = true;
            break;
          case 'TEILNEHMER':
            if(this.bereich === 'Termin') {  
              row.data.canChange = true;
              row.data.alarmMail = true;
              row.data.alarmDailyMail = true;
              row.data.alarmMsc = true;
            } else { 
              row.data.canChange = false;
              row.data.alarmMail = false;
              row.data.alarmDailyMail = false;
              row.data.alarmMsc = false;
            }
            break;
          default:
            row.data.canChange = false;
            row.data.alarmMail = false;
            row.data.alarmDailyMail = false;
            row.data.alarmMsc = false;
            break;
        }
        this.$emit('onChange', {
        field,
        value,
        id: row?.data?.id,
        data: {
          ...row?.data,
        }
      })
      return;
      }
      this.isMultipleBesitzerRolles = value === 'BESITZER' && this.value.some(em =>
        em.rolle === 'BESITZER'
      )
      this.$emit('setBesitzer', this.isMultipleBesitzerRolles);
      if (value === 'BESITZER') {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.UPDATE_BETEILIGTE, {
          ...row?.data,
          rolle: 'BESITZER'
        });
        return
      }

      this.$emit('onChange', {
        field,
        value,
        id: row?.data?.id,
        data: {
          ...(row?.data?.beteiligte || row?.data),
          [field]: value
        }
      })
    },
    clickReceiveUsers(e) {
      if (e.value === '...') {
        this.isUsersPreview = true;
      } else {
        this.$emit('clickReceiveUsers', e)
      }
    },
    deleteReceiveUsers(e) {
      this.$emit('deleteReceiveUsers', e)
    },
    searchReceiverUsers(e) {
      this.$emit('searchReceiverUsers', e)
    },
    handleAddUsers(e) {
      this.$emit('handleAddUsers', e)
    },
    handleAddMakler(e) {
      this.$emit('handleAddMakler', e)
    },
    handleDeleteAllUsers() {
      this.$emit('deleteAllReceiveUsers', this.receiver)
    },
    handleEdit(row) {
      let index = row.index
      if (row.nummer) {
        index = this.receiver.findIndex(a => a.value === row.nummer)
      }
      this.selectedParticipant = index;
      this.form = {
        label: (this.receiver[index].label || '').trim(),
        email: (this.receiver[index].email || '').trim(),
      }
      
      this.$refs.editModal.open();
    },
    handleDelete(row) {
      let index = row.index

      if (index > -1) {
        let deleted = this.receiver[index];
        this.receiver.splice(index, 1);
        this.deleteReceiveUsers(deleted)
      }
    },
    handleCopyAddress(row) {
      this.$emit('copyAddress', row);
    },
    handleSendEmail(row) {
      this.$emit('sendEmail', row);
    },
    handleSendEmailSingle(row) {
      this.$emit('sendEmailSingle', [row?.data?.beteiligte])
    },
    handleSetAddress(row) {
      this.$emit('setAddress', row?.data?.address);
    },
    saveParticipant() {
      let label = this.form.label || '';
      if (!label) {
        label = this.form.email;
      }
      let participant = {
        ...this.value[this.selectedParticipant],
        data: {
          ...this.value[this.selectedParticipant].data,
          name: this.form.label,
        },
        label: label,
        name: label,
        email: this.form.email,
        value: this.value[this.selectedParticipant].value || '',
       // primaryObjectSerialized:this.form.primaryObjectSerialized,
      // tempFileId:this.form.tempFileId
      }
      if(participant.value && participant.value.indexOf('@') !== -1){
        participant.value=participant.email
      }
      this.value[this.selectedParticipant] = participant
      const value = [...this.value]
      this.$emit('input', value);

      this.$emit('onChange', {
        data: {
          ...participant,
          index: this.selectedParticipant,
        }
      })

      this.$refs.editModal.close();
    },
    toggleUserPreview() {
      this.isUsersPreview = !this.isUsersPreview;
      this.$emit('userPreview', this.isUsersPreview)
    }
  },
  computed: {
    ...mapGetters({
      getAppointmentCombos: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_COMBOS,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    headers() {
      const headers = {
        lockedLeft: [
            TextColumn("nummerText", "Nummer"),
            TextColumn("name", "Name").makeConditionalLink("isReceiveUserClickable"),
            ...(this.showMailAndAddressActions ? [
              IconColumn('handleSetAddress', '').makeLink(),
              IconColumn('handleSendEmailSingle', '').makeLink(),
            ] : []),
        ],
        center: [
            TextColumn("email", !this.isFax ? 'E-Mail' : 'Fax'),
        ],
        lockedRight: [
            ActionColumn("actions", ""),
        ],
      };
      if (this.isRole) {
        headers.center.push(SlotColumn("role", "Rolle"));
        headers.center.push(SlotColumn("canChange", "Ändern"));
        headers.center.push(SlotColumn("alarmMsc", "Nachricht Popup"));
        headers.center.push(SlotColumn("alarmMail", "Nachricht E-Mail"));
        headers.center.push(SlotColumn("alarmDailyMail", "E-Mail aktueller Tag"));
      }
      return headers;
    },
    rows() {
      const actions = [
        SimpleAction("EDIT", '', "Bearbeiten"),
        SimpleAction("DELETE", '', "Löschen"),
        ...(this.showMailAndAddressActions ? [
            SimpleAction("SET_ADDRESS", '', "Anschrift als Ort übernehmen"),
            SimpleAction("SEND_EMAIL_SINGLE", '', "Eine Terminbestätigung-E-Mail schicken"),
          ] : []),
      ];
      if(this.hasRoles(ROLES.TEST_USER && this.$listeners.copyAddress && this.$listeners.sendEmail)){
        actions.splice(1,0,SimpleAction("COPY_ADDRESS", '', "Anschrift als Ort verwenden"));
        actions.splice(1,0,SimpleAction("SEND_EMAIL", '', "Eine Terminbestätigungs-E-Mail schicken"));
      }
      return (this.value || []).map((v, i) => ({
        index: i,
        id: v.value + ' -- ' + v.label + ' -- ' + v.email,
        nummer: v.value,
        nummerText: v.nummerText || v.value,
        name: v.name || v.label,
        email: v.email || (v.label === v.value ? v.value : '') || v.fax,
        rolle: v.beteiligte?.rolle || v.rolle,
        data: v,
        canChange: v.canChange !== undefined ? v.canChange : v.value !== this.loginData?.loginUserid,
        canChangeValues: v.canChange || v.value !== this.loginData?.loginUserid,
        alarmMail: v.alarmMail,
        alarmMsc: v.alarmMsc,
        alarmDailyMail: v.alarmDailyMail,
        isReceiveUserClickable: this.isReceiveUserClickable?.(v),
        address: v.address,
        handleSetAddress: Icon(PhHouseSimple, 'Anschrift als Ort übernehmen', 20),
        handleSendEmailSingle: Icon(PhEnvelopeSimple, 'Eine Terminbestätigung-E-Mail schicken', 20),
        actions,
      }))
    },
    receiver: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    receiverStr: {
      get() {
        return typeof(this.value) === 'string' ? this.value : (this.value?.length && this.value[0]?.label || '');
      },
      set(value) {
        this.$emit('input', [value]);
      }
    },
    rolleOptions() {
      if (this.getAppointmentCombos && this.getAppointmentCombos.rolleCombo) {
        return Object.keys(this.getAppointmentCombos.rolleCombo).map(key => ({
          value: key,
          label: this.getAppointmentCombos.rolleCombo[key]
        })) || []
      }
      return []
    },
  },
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}
.d-flex .grow-1:last-child {
  padding-right: 0;
}
.grow-1 {
  flex-grow: 1;
  padding-right: 0.5em;
}
.participants-input {
  display: flex;
  align-items: flex-end;
}

.no-label {
  padding: 1.5rem 0 0 1rem;
  min-width: 40px;
  box-sizing: border-box;
  cursor: pointer;
}
.table-container {
  margin-bottom: 16px;
}
.participants-actions {
  display: flex;
  padding: 0.2rem;
}
.participants-actions div {
  flex: 0 0 auto;
  padding-right: 0.5rem;
}
.text-danger {
  color: var(--color-danger);
}

.dropdown-content {
  display: flex;
  flex-direction: column;

}
.label-content {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
}

.mt-4 {
  margin-top: 4rem;
}
</style>