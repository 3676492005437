<template>
  <div>
    <BaseModal
      ref="zusaetzlichenModal"
      modalTitle="Zusätzliche Daten"
      :showDefaultButtons="false"
      @close="resetModalState()"
    >

      <template v-slot:modalTitle>
        Zusätzliche Daten
      </template>
      <div>
          <Table v-if="mainTableRowsCalculated.length"
              tableId="289595ed-2dca-40af-90d6-ff9330d88005"
              :headers="mainTableHeaders"
              :rows="mainTableRowsCalculated"
              :rowsPerPage="5"
              @click-kundennr="loadSubtable"
              @action-delete="deleteMainTableRow($event)"
          />
          <NoData v-else-if="rows.length === 0" /> 


          <template v-if="!showingKundenZusaetzlicheInformationen">
            <p v-if="!selectedCustomer">Kunden Zusätzliche Informationen (für aktuell ausgewählten Kunden)</p>
            <p v-else>Kunden Zusätzliche Informationen (für Kunde {{selectedCustomer}})</p>

            <Table v-if="secondaryTableRowsCalculated.length"
                tableId="6490907e-d880-42c3-b5ca-a91c93ccabea"
                :headers="secondaryTableHeaders"
                :rows="secondaryTableRowsCalculated"
                :rowsPerPage="5"
                @action-delete="deleteSecondaryTableRow($event)"
            />
            <NoData v-else-if="rows.length === 0" :noIcon="true"/> 

            <InputField
              
              type="text"
              placeholder="Neuen Eintrag anlegen"
              v-model="inputZusaetzlicheDaten"
            />     
          
          </template>

          <template v-if="showingKundenZusaetzlicheInformationen">
            <p>Kunden zusätzliche Informationen</p>

            <p>Achtung: Es können nur Informationen hinzugefügtwerden, es werden keine Einträge beim Kunden gelöscht</p>

            <Table v-if="alleStrukturZusaetzlicheInfosCalculated.length"
                tableId="2f85b4a9-f5a2-4c5d-9914-62b5bd023210"
                :headers="kundenZusaetzlicheInformationenHeaders"
                :rows="alleStrukturZusaetzlicheInfosCalculated"
                :rowsPerPage="5"
                :selected="selectedStrukturZusaetzlicheInfos"
                @selected="onSelect"
            />
            <NoData v-else-if="rows.length === 0" :noIcon="true"/> 

            <InputField
              
              type="text"
              placeholder="Neuen Eintrag anlegen"
              v-model="inputZusaetzlicheDaten"
            />     
          
          </template>          

          

      </div>
      <template v-slot:footer>
        <div class="col-auto">
          <BaseButton @click="hinzufuegen()">Hinzufügen</BaseButton>
        </div>
        <div class="col-auto">
          <BaseButton isSecondary @click="abbrechen()">Abbrechen</BaseButton>
        </div>
        <div class="col-auto">
          <BaseButton @click="anwenden()" v-if="selectedStrukturZusaetzlicheInfos.length">Anwenden</BaseButton>
        </div>
        <div class="col-auto">
          <BaseButton @click="zusaetzlicheInformationenAuswaehlen()">{{showingKundenZusaetzlicheInformationen ? 'Zurück' : 'ausgewählten Zusätzliche Informationen'}}</BaseButton>
        </div>

      </template>

    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/core/BaseModal.vue";
import { mapGetters } from 'vuex';
import ZUSAETZLICHE_INFORMATIONEN_TYPES from '@/store/zusaetzlicheInformationen/types';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from "@/components/core/forms/InputField.vue";

export default {
  computed: {
    ...mapGetters({
      mainTableRows: ZUSAETZLICHE_INFORMATIONEN_TYPES.GETTERS.MAIN_TABLE_ROWS,
      secondaryTableRows: ZUSAETZLICHE_INFORMATIONEN_TYPES.GETTERS.SECONDARY_TABLE_ROWS,
      zusaetzlicheDaten: ZUSAETZLICHE_INFORMATIONEN_TYPES.GETTERS.ZUSAETZLICHE_DATEN,
      alleStrukturZusaetzlicheInfos: ZUSAETZLICHE_INFORMATIONEN_TYPES.GETTERS.ALLE_STRUKTUR_ZUSAETZLICHE_INFOS,
    }), 
    mainTableHeaders() {
        return {
              lockedLeft: [
                  TextColumn("kundennr", "Kundennr").makeLink(),
                  TextColumn("vorname", "Vorname"),
                  TextColumn("nachname", "Name"),
              ],
              center: [
                  TextColumn("plz", "PLZ"),
                  TextColumn("strasse", "Straße"),
                  TextColumn("ort", "Ort"),
              ],
              lockedRight: [
                  ActionColumn("actions", "Aktionen"),
              ],
          };
    },
    mainTableRowsCalculated() {
      const actions = [SimpleAction("delete", '', "Löschen")];

      return this.mainTableRows.map(mtr => {
        return {
          ...mtr,
          actions
        }
      });

    },
    secondaryTableHeaders() {
        return {
              lockedLeft: [
                TextColumn("id", "Id").makeHidden(),
                TextColumn("item", "Item"),
              ],
              lockedRight: [
                  ActionColumn("actions", "Aktionen"),
              ],
          };
    },
    secondaryTableRowsCalculated() {
      const actions = [SimpleAction("delete", '', "Löschen")];

      if (this.zusaetzlicheDaten?.secondaryTableRows) {
        return this.zusaetzlicheDaten.secondaryTableRows.map( (item, id) => {
          return {
            id,
            item, 
            actions
          }
        });
      }

      return [];

    },
    kundenZusaetzlicheInformationenHeaders() {
        return {
              lockedLeft: [
                TextColumn("id", "Id").makeHidden(),
                TextColumn("item", "Item"),
              ],
          };
    },  
    alleStrukturZusaetzlicheInfosCalculated() {

      if (this.alleStrukturZusaetzlicheInfos?.alleStrukturZusaetzlicheInfos) {
        return this.alleStrukturZusaetzlicheInfos?.alleStrukturZusaetzlicheInfos.map( (item, id) => {
          return {
            id,
            item, 
          }
        });
      }

      return [];

    },      
  },
  components: {
    BaseModal,
    Table,
    NoData,
    BaseButton,
    InputField,
  },
  data() {
    return {
      customers: null,
      changingPosition: false,
      selectedCustomers: [],
      selectedInfos: [],
      rows: [],
      selectedCustomer: null,
      inputZusaetzlicheDaten: null,
      showingKundenZusaetzlicheInformationen: false,
      selectedStrukturZusaetzlicheInfos: [],
    };
  },
  methods: {
    open() {
      this.$refs.zusaetzlichenModal.open();
    },
    abbrechen() {
      this.$refs.zusaetzlichenModal.close();
    },
    deleteMainTableRow(row) {
      this.$store.commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.REMOVE_MAIN_TABLE_ROW, row.kundennr);

    },
    deleteSecondaryTableRow(row) {
      const kundennr = this.selectedCustomer;
      const zusaetzlicheInfo = row?.item;
   
      const payload = {
        zusaetzlicheInfo,
        kundennr,
      }

      this.$store.dispatch(ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.DELETE_ZUSAETZLICHE_DATEN, payload).then(() => {
          this.loadSubtable({kundennr})

      });


    },    
    zusaetzlicheInformationenAuswaehlen() {

      if (!this.showingKundenZusaetzlicheInformationen) {
        this.$store.dispatch(ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.GET_ALLE_STRUKTUR_ZUSAETZLICHE_INFOS, {}).then(() => {
            this.showingKundenZusaetzlicheInformationen = true;
            this.inputZusaetzlicheDaten = null;
        });
      } else {
        this.selectedStrukturZusaetzlicheInfos = [];
        this.showingKundenZusaetzlicheInformationen = false;
      }

      
    },
    loadSubtable(row) {
      // row.customRowStyle = "outline: 3px solid black";
      this.selectedCustomer = row?.kundennr;
      const customers = [row?.kundennr];
   
      const payload = {
        tableType: 'SECONDARY_TABLE',
        customers,
      }

      this.$store.dispatch(ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.GET_ZUSAETZLICHE_DATEN, payload).then(() => {
          this.$refs.zusaetzlichenModal.open();

      });

    },
    hinzufuegen() {
      let customers = this.mainTableRows.map(r => r.kundennr);

      if (this.showingKundenZusaetzlicheInformationen) {
        const payload = {
          zusaetzlicheInfos: [...this.selectedStrukturZusaetzlicheInfos.map(z => z.item), this.inputZusaetzlicheDaten],
          customers,
        }

        this.$store.dispatch(ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.UPDATE_OBJECT_ZUSAETZLICHE_TABELLE, payload).then(() => {
          this.$store.dispatch(ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.GET_ALLE_STRUKTUR_ZUSAETZLICHE_INFOS, {}).then(() => {});
        });

      } else if (this.inputZusaetzlicheDaten) {
        const payload = {
          inputZusaetzlicheDaten: this.inputZusaetzlicheDaten,
          customers,
        }

        const result = this.$store.dispatch(ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.NEW_ZUSAETZLICH_DATEN, payload);
        if (result) {
          result.then(a => {
            this.inputZusaetzlicheDaten = '';
          })
        }
      }



    },
    anwenden() {
      const payload = {
        zusaetzlicheInfos: this.selectedStrukturZusaetzlicheInfos.map(z => z.item),
        customers: this.mainTableRows.map(r => r.kundennr),
      }

      this.$store.dispatch(ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.UPDATE_OBJECT_ZUSAETZLICHE_TABELLE, payload).then(() => {
        if (this.selectedCustomer) {
          this.loadSubtable({kundennr: this.selectedCustomer});
          this.selectedStrukturZusaetzlicheInfos = [];
          this.showingKundenZusaetzlicheInformationen = false;
        }
      });

    },
    onSelect(rows) {
      this.selectedStrukturZusaetzlicheInfos = rows;
    },
    resetModalState()  {
      this.selectedStrukturZusaetzlicheInfos = [];
      this.showingKundenZusaetzlicheInformationen = false;
      this.selectedCustomer = null;
    },
  },
  mounted() {
  }
}
</script>

<style>


</style>
