<template>
  <div>
    <DragnDropArea
      hoverText="Neues Dokument hochladen"
      :disabled="!canUpdateDocumentArchive || listViewOnly"
      @files="openDocumentUploadModal($event)" 
      class="simple-additional-documents-table__dropzone" 
    >
      <div>
        <GhostLoading v-if="isLoadingInternal" :title="title" type="table" />
        <Table v-else
            tableId="b363a7c6-0140-4607-b86e-b630ba53c9f8"
            :title="title"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="10"
            :rowId="customDocuments ? 'fileId' : 'id'"
            :headerActions="tableHeaderActions"
            @click-name="showDocument"
            @action-DELETE="deleteDocument"
            @action-DOKUMENT_LOSCHEN="deleteDocument"
            @action-DOKUMENT_BEARBEITEN="openExternesDokumentModal"
            @action-KREDIT_PROHYP_UPLOAD="uploadProhypDocument"
            @headerAction-DOCUMENT-UPLOAD="openDocumentUploadModal([])"
        >
        </Table>
      </div>

      <DocumentsUploadModal
        v-if="files && additionalUploadInputs" 
        :files="files" 
        @close="files=null" 
        :additionalInputs="additionalUploadInputs"
        :parentId="nodeId" 
        :uploadType="uploadType" 
        :documentString="fileIdEncoded"
        :multipleFileUpload="multipleFileUpload"
        :customUpload="customUpload"
      />
    </DragnDropArea>

    <BaseModal
      ref="externesDokumenteModal"
      :modalTitle="''"
      labelButtonConfirm="Speichern"
      :showCancelButton="true"
      @onConfirmButton="saveExternesDokument()">
      <div v-if="exfile">
          <InputField
              label="Datei"
              v-model="exfile.filename"
              :disabled="true"
              >
          </InputField>
          <InputField
              label="Bezeichnung"
              v-model="exfile.bezeichnung"
              >
          </InputField>
          <InputField
              label="Bemerkung"
              v-model="exfile.bemerkung"
              >
          </InputField>
          <DatePickerField
              label="Stand"
              dateFormat="DD.MM.YYYY"
              v-model="exfile.stand"
              >
          </DatePickerField>
      </div>
    </BaseModal>  

      <ProhypDokumenteUpload
        ref="prohypDokumenteUploadModal"
        :nodeId="nodeId"
        :documentId="fileIdEncoded"
        @selectionDone="retrieveAdditionalDocuments"
      />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';
import CORE_TYPES from '@/store/core/types';
import DOCUMENT_TYPES from '@/store/documents/types';

import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, DateColumn, SimpleAction, ConfirmedAction} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputField from '@/components/core/forms/InputField.vue';

import { VIEW_ROLES, } from '@/router/roles'

import { UPLOAD_TYPE } from '@/views/documents/AdditionalDocuments.vue';
import dayjs from "dayjs";
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import ProhypDokumenteUpload from '@/components/documents/ProhypDokumenteUpload.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const UPLOAD_INPUTS_CONFIG_DEFAULT = {
  hidden: [],
};


export default {
  props: {
    title: {
      type: String,
      default: 'Dokumente',
    },
    nodeId: {
      type: [String, Number],
      default: null,
    },
    fileId: {
      type: String,
      default: '',
    },
    customUpload: {
      type: Function,
    },
    listViewOnly: {
      type: Boolean,
      default: false,
    },
    customDocuments: {
      type: Array,
      default: null,
    },
    uploadInputsConfig: {
      type: Object,
      default: () => (UPLOAD_INPUTS_CONFIG_DEFAULT),
    },
    uploadType: {
      type: String | Number,
      default: UPLOAD_TYPE.CUSTOM,
    },
    multipleFileUpload: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
        files: null,
        loading: false,
        exfile: null,
        currentActionData: null,
    };
  },
  computed: {
    ...mapGetters({
      additionalUploadInputsData: DOKUMENTENARCHIV_TYPES.GETTERS.ADDITIONAL_UPLOAD_INPUTS,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      additionalDocuments: DOKUMENTENARCHIV_TYPES.GETTERS.ADDITIONAL_DOCUMENTS,
      attachmentLinkMaker: DOCUMENT_TYPES.GETTERS.GET_ANTRAG_SCANS_LINK_MAKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    rights() {
      return this.loginData?.rights || {};
    },
    canUpdateDocumentArchive() {
      return this.rights?.updateDocumentArchive || this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN]);
    },
    isLoadingInternal() {
      return this.loading || this.isLoading;
    },
    fileIdEncoded() {
      return encodeURIComponent(this.fileId);
    },
    additionalUploadInputs() {
        const hidden = this.uploadInputsConfig?.hidden || [];
        const inputs = this.customDocuments
                    ? this.additionalUploadInputsData?.additionalUploadInputs
                    : this.additionalDocuments?.additionalUploadInputs;
        return (inputs || []).map(input => ({
            ...input,
            visible: hidden.indexOf(input.key) >= 0 ? { type: 'CONSTANT_BOOL', value: false, } : null,
        }));
    },
    isCredit() {
      return !this.customDocuments
    },
    headers() {
        const headers = {
            lockedLeft: [
                TextColumn("name", "Name"),
            ],
            center: [
                TextColumn("bezeichnung", "Bezeichnung"),
                TextColumn("bemerkung", "Bemerkung"),
                DateColumn("erstellt_am", "Stand"),
            ],
            lockedRight: [],
        };
        if (this.rights?.showDocumentArchive && !this.listViewOnly) {
            headers.lockedLeft[0].makeLink();
            headers.lockedRight.push(ActionColumn("action", "Aktionen"));
        }
        return headers
    },
    rows() {
        let rows = this.customDocuments || this.additionalDocuments?.tableRows || []
        const action = this.canUpdateDocumentArchive && !this.isCredit ? [
            ConfirmedAction("DELETE", '', "Löschen", `Soll das Dokument wirklich gelöscht werden?`, 'Dokument löschen', "Löschen"),
            SimpleAction("DOKUMENT_BEARBEITEN", '', "Bearbeiten"),
        ] : [];

        return rows.map(row => ({
            ...row,
            action: this.isCredit ? this.makeActions(row) : action,
        }));
    },
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('DOCUMENT-UPLOAD', 'Datei hochladen')
          .withAsPrimary(() => true)
          .withVisible(() => this.canUpdateDocumentArchive && !this.listViewOnly),
      ];
    },
  },
  mounted() {
    if (!this.customDocuments) {
        this.retrieveAdditionalDocuments();
    }
  },
  methods: {
    makeActions(row) {
        if (row.actions) {
            return row.actions.filter(action => action.legend?.key !== 'DOKUMENT_ANZEIGEN').map(action => {
                return action.confirmationMessage 
                  ? ConfirmedAction(action.legend.key, action.legend.icon, action.legend.label, action.confirmationMessage, action.confirmationTitle)
                  : SimpleAction(action.legend.key, action.legend.icon, action.legend.label)
            });
        };
    },
    async retrieveAdditionalDocuments() {
      if(!this.nodeId)
        return;

      this.loading = true;
      await this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_DOCUMENTS, {
        nodeId: this.nodeId,
        fileId: this.fileIdEncoded,
      });
      this.loading = false;
    },
    initExternalFile(row){
      const externalFileAction = row?.actions.filter(a => a?.specificAction == 'DOKUMENT_BEARBEITEN');

      if (externalFileAction) {
        const specificAction = externalFileAction[0];

        this.currentActionData = specificAction;

        this.exfile = {
          filename: specificAction.sendData[4].value,
          bezeichnung: specificAction.sendData[1].value,
          bemerkung: specificAction.sendData[2].value,
          stand: specificAction.sendData[3].value,
        }
      }

    },
    openExternesDokumentModal(row) {
        this.initExternalFile(row)
        this.$refs.externesDokumenteModal.open();
    },
    deleteDocument(row) {
        if (this.customDocuments) {
            this.$emit('deleteDocument', row);
        } else {
            const data = { 
                sendData: this.findSendData(row.actions, "DOKUMENT_LOSCHEN"), 
                legend: { key: "DOKUMENT_LOSCHEN", }, 
            };
            this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.ADDITIONAL_DOCUMENTS_ACTIONS, {
                nodeId: this.nodeId,
                fileId: this.fileIdEncoded,
                data,
            })
            .then(() => this.retrieveAdditionalDocuments());
        }
    },
    showDocument(row) {
        if (this.customDocuments) {
            this.$emit('showDocument', row);
        } else {
            viewDocument({
                href: this.makeLink(row),
                filename: row.name || row.filename || 'unbenannt',
            });
        }
    },
    findSendData(actions, actionKey) {
        if(!actions?.length || !actionKey)
            return;
        return actions.find(act => act.legend?.key === actionKey)?.sendData || [];
    },
    makeLink(row) {
      const filename = row?.filename || row?.name;
      let sendList = []
      const sendData = this.findSendData(row?.actions, 'DOKUMENT_ANZEIGEN');
      if (sendData?.length) {
        sendData.forEach(x => {
          sendList.push(x.key)
          sendList.push(x.value)
        })
      }
      return this.attachmentLinkMaker(filename, this.nodeId, sendList)
    },
    async openDocumentUploadModal(files) {
      if(!this.additionalUploadInputs?.length) {
        await this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_UPLOAD_INPUTS, { nodeId: this.nodeId });
      }

      this.files = files;
    },
    saveExternesDokument() {
      this.currentActionData.sendData[1].value = this.exfile.bezeichnung
      this.currentActionData.sendData[2].value = this.exfile.bemerkung
      let date;
      if (dayjs(this.exfile.stand).isValid()) {
        date = dayjs(this.exfile.stand).format("DD.MM.YYYY");
        this.currentActionData.sendData[3].value = date
      }
      let fileId = this.currentActionData.sendData.find(sd => sd.key=='PARAM_FILEID');
      this.$store.dispatch(
        DOKUMENTENARCHIV_TYPES.ACTIONS.EXECUTE_EDIT_FILE,
        {
          fileId: fileId?.value,
          action: this.currentActionData,
        }).then(() => {
          if (this.uploadType == UPLOAD_TYPE.DOKUMENTE_HINZUFUGEN) {
            this.retrieveAdditionalDocuments();
          }
        });
    },
    uploadProhypDocument(row) {
      // fileId is in row.data
      this.$refs.prohypDokumenteUploadModal.open(row.data)
    },
  },
  beforeDestroy() {
    this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RESET_DATA);
  },
  components: {
    DocumentsUploadModal,
    DragnDropArea,
    Table,
    BaseModal,
    GhostLoading,
    InputField,
    DatePickerField,
    ProhypDokumenteUpload,
  },
}
</script>

<style scoped>
.simple-additional-documents-table__dropzone {
  display: flow-root;
}
</style>
