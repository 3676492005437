<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Dokumente für den Antrag" 
      :subtitle="formLabel" 
      :actions="headerActions" 
      @action-ADD="createDocumentGroup()" 
    />

      <SortableList :items="documentGroups" @orderChanged="onOrderChanged($event)">
        <ul class="list-bordered" data-sortable-container>
          <li v-for="documentGroup of documentGroups" :key="documentGroup.id" data-sortable-item>
            <div class="box__container">
              <div class="group__bar">
                <div @click="documentGroup.visible = !documentGroup.visible" class="mr-3">
                  <PhCaretDown size="20" v-if="documentGroup.visible" weight="bold"/>
                  <PhCaretUp size="20" v-else  weight="bold" />
                </div>
                <div class="group__title ellipsis">{{ documentGroup.bezeichnung}}{{getDocumentTypeLabel(documentGroup)}}</div>
                <Pill :label="getPillLabelForGroup(documentGroup)" :type="getPillTypeForGroup(documentGroup)"></Pill>
                
                <div class="group__bar__right">
                  <BaseDropdownMenu placement="bottom-right" class="mr-2">
                    <template v-slot:hook-target>
                      <ph-dots-three-vertical  class="clickable" size="24" weight="bold" />
                    </template>
                    <div class="action-texts">
                      <template v-for="action in getGroupActions(documentGroup)">
                        <div class="btn btn-clean clickable" :key="action.key" @click="handleGroupAction(action, documentGroup)">
                          <component :is="action.icon" :size="24" class="mr-1"></component>{{action.label}}</div>
                      </template>
                    </div>
                  </BaseDropdownMenu>
                  <div data-sortable-item-handler><ph-list :size="28" weight="bold" /></div>
                </div>
              </div>

              <Table 
                v-if="rows(documentGroup).length && documentGroup.visible"
                hidePagination
                :headers="headers"
                :rows="rows(documentGroup)"
                :mobileConfig="{title: null, headers: ['version', 'status']}"
                @action="handleTableAction($event, documentGroup)">
              </Table>
              <NoData content="Keine Versionen vorhanden" v-else-if="documentGroup.visible"/>
            </div>
          </li>
        </ul>
      </SortableList> 

    <BaseModal ref="releaseModal"  
      labelButtonConfirm="Veröffentlichen"
      :confirmDisabled="isDateBeforeNow(createDocumentForm.releaseDate)" 
      @onConfirmButton="onConfirmReleaseModal">
      <template v-slot:modalTitle>
        Dokument veröffentlichen
      </template>
      <InputField
          label="Bezeichnung"
          :value="selectedGroup && selectedGroup.bezeichnung"
          isComponentHalfSize
          disabled />
      <InputField
          label="Version"
          v-model="createDocumentForm.version"
          isComponentHalfSize
          disabled />
      <InputTextArea
        label="Bemerkung"
        v-model="createDocumentForm.bemerkung" />
      <div class="box__title">Veröffentlichungsdatum</div>
      <InputToggleSwitch
        label="sofort"
        isValueAsString
        v-model="createDocumentForm.releaseNow"/>
      <DatePickerField
        v-if="!createDocumentForm.releaseNow"
        label="oder am"
        isValueAsString
        v-model="createDocumentForm.releaseDate"/>
      <div class="fc-form-danger" v-if="isDateBeforeNow(createDocumentForm.releaseDate)">Datum darf nicht in der Vergangenheit sein</div>
      <div class="fc-form-danger" v-else>Nach der Veröffentlichung eines Dokuments sind keine Änderungen mehr möglich</div>
    </BaseModal>

    <BaseModal ref="createDocumentGroupModal"  
      :labelButtonConfirm="createDocumentGroupData.id ? 'Speichern' : 'Erstellen'"
      :modalTitle="createDocumentGroupData.id ? 'Dokument umbenennen' : 'Dokument erstellen'"
      :confirmDisabled="!createDocumentGroupData.bezeichnung"
      @onConfirmButton="onConfirmCreateDocumentGroupModal">
      <InputField
          label="Bezeichnung"
          :renderDanger="!createDocumentGroupData.bezeichnung"
          v-model="createDocumentGroupData.bezeichnung"
        />
      <ComboBox
          label="Dokumenttyp"
          :values="documentTypeValues"
          v-model="createDocumentGroupData.documentType"
        />
    </BaseModal>

    <BaseModal ref="disclaimerModal"  labelButtonCancel="Abbrechen" labelButtonConfirm="Akzeptieren" @onConfirmButton="confirmDisclaimer">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Wichtiger Hinweis
      </template>
      <div v-html="selectedGroup && sanitize(selectedGroup.disclaimerText)"></div>
    </BaseModal>
  </div>
</template>

<script>
import DYNAMIC_DOCUMENT_TYPES from '@/store/dynamicDocument/types';
import LOG_TYPES from '@/store/log/types';
import CORE_TYPES from '@/store/core/types';
import axios from 'axios';
import dayjs from "dayjs";

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PhList, PhDotsThreeVertical, PhCaretUp, PhCaretDown, PhWarning} from 'phosphor-vue';
import InputField from '@/components/core/forms/InputField.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import Table from "@/components/table2/Table.vue";
import BaseModal from '@/components/core/BaseModal.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import NoData from '@/components/core/NoData.vue'
import BaseDropdownMenu from "@/components/core/BaseDropdownMenu.vue";
import Pill from '@/components/core/Pill.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import SortableList from '@/components/core/SortableList.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import {sanitize} from '@/helpers/string-helper.js';
import { downloadLinkMaker, makeQueryParam } from '@/helpers/utils-helper';
import {TextColumn, ActionColumn, SimpleAction, PillColumn} from "@/components/table2/table_util.js";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    InputField,
    BaseModal,
    InputTextArea,
    DatePickerField,
    InputToggleSwitch,
    PhCaretUp,
    PhCaretDown,
    PhDotsThreeVertical,
    PhWarning,
    PhList,
    BaseButton,
    NoData,
    BaseDropdownMenu,
    Pill,
    ComboBox,
    SortableList,
  },
  data() {
    return {
      currentAction: null,
      selectedRow: null,
      createDocumentForm: {
        releaseNow: true,
        version: null,
        releaseDate: null,
        bemerkung: null,
      },
      createDocumentGroupData: {
        bezeichnung: null,
        documentType: 'KEINE_AUSWAHL',
      },
      selectedGroup: {},
      formLabel: '',
      documentGroups: [],
      loading: false,
      documentTypeValues: [],
      groupOrderChanged: false,
    }
  },
  computed: {
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Neues Dokument erstellen').withDisabled(() => this.loading),
      ];
    },
    headers() {
        return {
            lockedLeft: [
                TextColumn("version", "Version"),
                PillColumn("status", "Status"),
            ],
            center: [
                TextColumn("dateRelease", "Veröffentlichung"),
                TextColumn("dateLastModified", "Stand"),
                TextColumn("bemerkung", "Bemerkung"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        };
    },
    highestAvailableVersion() {
      let highestAvailableVersion = 0
      if (this.selectedGroup && this.selectedGroup.documents?.length) {
        this.selectedGroup.documents.forEach(item => {
          if (item.version > highestAvailableVersion) {
            highestAvailableVersion = item.version
          }
        })
      }
      
      return highestAvailableVersion
    },
  },
  async mounted() {
    await this.retrieveDocumentTypeValues()
    this.initialize()
  },
  methods: {
    onOrderChanged(sortedList) {
      this.documentGroups = sortedList
      this.groupOrderChanged = true
    },
    getPillLabelForGroup(documentGroup) {
      if (documentGroup.aktiv) {
        return documentGroup.documents?.length && documentGroup.documents.some(doc => doc.aktuell) ? 'aktiv' : 'aktiv (keine aktiven Versionen vorhanden)'
      }
      return 'inaktiv'
    },
    getPillTypeForGroup(documentGroup) {
      if (documentGroup.aktiv) {
          return documentGroup.documents?.length && documentGroup.documents.some(doc => doc.aktuell) ? 'success' : 'warning'
      }
      return 'danger'
    },
    getDocumentTypeLabel(documentGroup) {
      if (documentGroup.documentType !== "KEINE_AUSWAHL") {
          return ` (${this.documentTypeValues.find(type => type.value === documentGroup.documentType).label})`
      }
      return ''
    },
    getGroupActions(documentGroup) {
      let groupActions = [
        {
          label: 'Neue Version erstellen',
          key: 'create',
          icon: '',        
        },
        {
          label: 'bearbeiten',
          key: 'edit',
          icon: '',        
        },
      ]

      if (documentGroup.aktiv) {
        groupActions.push({
          label: 'deaktivieren',
          key: 'toggle_aktiv',
          icon: '',    
        })
      } else {
          groupActions.push({
          label: 'aktivieren',
          key: 'toggle_aktiv',
          icon: '',    
        })
      }

      if (!documentGroup.documents?.length) {
        groupActions.push({
          label: 'Löschen',
          key: 'delete',
          icon: '',    
        })
      }


      return groupActions
    },
    handleGroupAction(action, documentGroup) {
      this.selectedRow = {}
      this.selectedGroup = documentGroup
      
      switch(action.key) {
        case 'edit':
          this.createDocumentGroupData = {
            id: documentGroup.id,
            bezeichnung: documentGroup.bezeichnung,
            documentType: documentGroup.documentType,
          }
          this.$refs.createDocumentGroupModal.open()
          break;
        case 'create':
          this.currentAction = 'create'
          this.$refs.disclaimerModal.open()
          break;
        case 'delete':
          this.deleteDocumentGroup(documentGroup)
          break;
        case 'toggle_aktiv':
          this.toggleAktivGroup(documentGroup)
          break;
        default: 
          this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, "NOCH NICHT IMPLEMENTIERT: " + action.key);
        break;
      }
    },
    createDocumentGroup() {
      this.createDocumentGroupData = {
        id: null,
        bezeichnung: null,
        documentType: 'KEINE_AUSWAHL',
      }
      this.$refs.createDocumentGroupModal.open()
    },
    toggleAktivGroup(documentGroup) {
      if (!documentGroup.id) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, "Fehler: Keine Id");
        return
      }

      this.loading = true

      axios.post(`${process.env.VUE_APP_API}/dynamicdocuments/toggleAktivDocument?groupId=${documentGroup.id}`, {}, config).then(response => {
        if (response && response.status === 200) {
          this.initialize()
        }      
      }).catch(() => this.loading = false)
    },
    deleteDocumentGroup(documentGroup) {
      if (!documentGroup.id) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, "Fehler: Keine Id");
        return
      }

      this.loading = true

      axios.delete(`${process.env.VUE_APP_API}/dynamicdocuments/deleteDocument?groupId=${documentGroup.id}`, config).then(response => {
        if (response && response.status === 200) {
          this.initialize()
        }      
      }).catch(() => this.loading = false)
    },
    onConfirmCreateDocumentGroupModal() {
      this.loading = true

      const payload = {
        ...this.createDocumentGroupData,
        formId: this.$route.params.formId,
      }
      
      axios.post(`${process.env.VUE_APP_API}/dynamicdocuments/createOrEditDocument`, payload, config).then(response => {
        if (response && response.status === 200) {
          this.initialize()
        }
      }).catch(() => this.loading = false)
    },
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    isDateBeforeNow(stringDate) {
      if (!stringDate)
        return false

      let nowDateString = dayjs().format('DD.MM.YYYY')
      let nowDate = dayjs(nowDateString, 'DD.MM.YYYY', true)
      let date= dayjs(stringDate, 'DD.MM.YYYY', true)
      return date.isBefore(nowDate)
    },
    isDateAfterNow(stringDate) {
      if (!stringDate)
        return false

      let nowDateString = dayjs().format('DD.MM.YYYY')
      let nowDate = dayjs(nowDateString, 'DD.MM.YYYY', true)
      let date= dayjs(stringDate, 'DD.MM.YYYY', true)
      return date.isAfter(nowDate)
    },
    rows(documentGroup) {
      if(!documentGroup || !documentGroup.documents) {
        return [];
      }

      const documents = documentGroup.documents

      let records = documents.map(row => {
        // const child =  Object.assign({}, row);
        let actions = this.makeActions(row);
        
        let status = null;
        if (row.aktuell)
          status = {label: "aktiv", type: "success"};
        else if (!row.dateRelease || (row.dateRelease && dayjs().isBefore(dayjs(row.dateRelease, 'DD.MM.YYYY', true)))) 
          status = {label: "in Bearbeitung", type: "warning"};
        else
          status = {label: "inaktiv", type: "danger"};

        return {...row, status, actions};
      });

      return records
    },
    confirmDisclaimer() {
      if (this.currentAction) {
        if (this.currentAction === 'create') {
          this.createDocument()
        } else {
          axios.post(`${process.env.VUE_APP_API}/dynamicdocuments/acceptDisclaimer?documentId=${this.selectedRow.id}`, {}, config).then(response => {
            if (response && response.status === 200) {
              if (this.currentAction === 'edit') {
                this.editDocument()
              } else if (this.currentAction === 'release') {
                let row = this.selectedGroup.documents?.find(document => document.id === this.selectedRow.id)
                if (row) {
                  row.disclaimerAccepted = true
                  this.openReleaseModal()
                } else {
                  this.initialize()
                }

              }
            }
          })
        }
      }
    },
    initialize() {
      this.loading = true
      const params = makeQueryParam({formId: this.$route.params.formId})

      axios.get(`${process.env.VUE_APP_API}/dynamicdocuments/list?${params}`, config).then(response => {
        if (response && response.data) {
          this.formLabel = response.data.formLabel
          this.documentGroups = response.data.docGroups.map(group => {
            return {
              ...group,
              visible: group.documents?.length > 0,
            }
          })
        }
      }).finally(() => this.loading = false)
    },
    handleTableAction(actionData, documentGroup) {
      this.selectedRow = actionData.row
      this.selectedGroup = documentGroup
      this.currentAction = actionData.key
      
      switch(actionData.key) {
        case 'open_pdf':
          this.openPDF()
          break;
        case 'edit':
          if (this.selectedRow && this.selectedRow.disclaimerAccepted) {
            this.editDocument()
          }
          else {
            this.$refs.disclaimerModal.open()
          }
          break;
        case 'create':
          this.$refs.disclaimerModal.open()
          break;
        case 'release':
          if (this.selectedRow && this.selectedRow.disclaimerAccepted) {
            this.openReleaseModal()
          } else {
            this.$refs.disclaimerModal.open()
          }
          break;
        case 'delete':
          this.deleteDocument()
          break;
        default: 
          this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, "NOCH NICHT IMPLEMENTIERT: " + actionData.key);
        break;
      }
    },
    retrieveDocumentTypeValues() {
      this.loading = true

      return axios.get(`${process.env.VUE_APP_API}/dynamicdocuments/retrieveComboboxSelections`, config).then(response => {
        if (response && response.data) {
          this.documentTypeValues = response.data
        }
      }).catch(() => this.loading = false)
    },
    openPDF() {
      const href = downloadLinkMaker(this.$store.getters, '/dynamicdocuments/openPDF', null, { documentId: this.selectedRow.id });
      viewDocument({ href, filename: 'Document.pdf' })
    },
    createDocument() {
      this.loading = true

      const params = makeQueryParam({formId: this.$route.params.formId})

      const payload = {
        ...this.selectedRow,
        groupId: this.selectedGroup.id,
        status: undefined,
        actions: undefined,
        highestAvailableVersion: this.highestAvailableVersion,
      }

      axios.post(`${process.env.VUE_APP_API}/dynamicdocuments/createDynamicDocument?${params}`, payload, config).then(response => {
        if (response && response.status === 200) {
          this.initialize()
        }
      }).catch(() => this.loading = false)
    },
    makeActions(child) {
      const actions = [
        SimpleAction("open_pdf", '', "PDF anzeigen"),
        SimpleAction("create", '', "Neue Version erstellen"),
      ];

      let hasDelete = false
      if (!child.aktuell && (!child.dateRelease || (child.dateRelease && dayjs().isBefore(dayjs(child.dateRelease, 'DD.MM.YYYY', true))))) {
        actions.push(SimpleAction("edit", '', "bearbeiten"))
        hasDelete = true
      }
      if (!child.aktuell && !(child.dateRelease && !this.isDateAfterNow(child.dateRelease))) {
        actions.push(SimpleAction("release", '', "veröffentlichen"))
      }

      if (hasDelete) {
        actions.push(SimpleAction("delete", '', "Löschen"))
      }

      return actions;
    },
    editDocument() {
      this.$store.commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RETRIEVE_DYNAMIC_DOCUMENT_SUCCESS, {})
      this.$router.push(`/intern/maklerauftrag/${this.$route.params.formId}/${this.selectedRow.id}/${this.selectedRow.version}`)
    },
    deleteDocument() {
      this.loading = true

      const params = makeQueryParam({documentId: this.selectedRow.id })
      
      axios.delete(`${process.env.VUE_APP_API}/dynamicdocuments/deleteDynamicDocument?${params}`, config).then(response => {
          if (response && response.status === 200) {
            this.initialize()
          }
      }).catch(() => this.loading = false)
    },
    openReleaseModal() {      
      this.createDocumentForm.version = this.selectedRow.version
      if (this.selectedRow.dateRelease) {
        this.createDocumentForm.releaseNow = false
        this.createDocumentForm.releaseDate = dayjs(this.selectedRow.dateRelease, 'DD.MM.YYYY', true)
      } else {
        this.createDocumentForm.releaseDate = dayjs().format('DD.MM.YYYY')
      }
      this.$refs.releaseModal.open()
    },
    onConfirmReleaseModal() {
      this.loading = true
      const payload = {
        id: this.selectedRow.id,
        dateRelease: this.createDocumentForm.releaseNow ? dayjs().format('DD.MM.YYYY') : this.createDocumentForm.releaseDate,
        bemerkung: this.createDocumentForm.bemerkung,
      }

      axios.post(`${process.env.VUE_APP_API}/dynamicdocuments/setRelease`, payload, config).then(response => {
        if (response && response.status === 200) {
          this.createDocumentForm = {
            releaseNow: true,
            version: null,
            releaseDate: null,
            bemerkung: null,
          }
          this.initialize()
        }      
      }).catch(() => this.loading = false)
    },
    saveGroupOrder() {
      if (!this.groupOrderChanged)
        return

      this.loading = true
    
      const params = makeQueryParam({groupId: this.documentGroups.map(group => {
        return group.id
      })})

      return axios.post(`${process.env.VUE_APP_API}/dynamicdocuments/setGroupOrder?${params}`, {}, config).then(() => {    
      }).finally(() => {
        this.groupOrderChanged = false
        this.loading = false
      })
    }
  },
  async beforeRouteLeave (to, from, next) {
    await this.saveGroupOrder()
    
    const breadcrumb = this.selectedGroup?.bezeichnung || 'Unbekannt';

    this.$addBreadcrumb({
      label: `zurück zu den Dokumenten`, 
      to,
      from,
      breadcrumb,
    });

    next()
  },
}
</script>

<style scoped>
  .group__bar {
    display: flex;
    align-items: center;
    background-color: var(--color-background);
    padding: 8px;
  }
  .group__bar__right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .group__title {
    color: var(--color-text);
    font-size: 1rem;
    font-weight: 700;
  }
</style>