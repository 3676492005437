var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TeleportToBody",
    [
      _c(
        "ResizableModal",
        {
          ref: "resizablePopup",
          attrs: {
            modalTitle: _vm.modalTitle,
            initialWidth: _vm.initialWidth,
            initialHeight: _vm.initialHeight,
            sizeIsPercentage: _vm.sizeIsPercentage,
            startPosition: _vm.startPosition,
            id: _vm.id,
            fullClientHeight: _vm.fullClientHeight,
            saveLastPosition: _vm.shouldSaveLastPosition,
            withBackdropLayer: _vm.withBackdropLayer
          },
          on: {
            onRestore: _vm.onRestore,
            onMinimize: _vm.onMinimize,
            onMaximize: _vm.onMaximize,
            onResize: _vm.resizeObjectContainer,
            onClose: _vm.cleanUpMemory
          },
          scopedSlots: _vm._u([
            {
              key: "toolbar-buttons-left",
              fn: function() {
                return [
                  _vm.usePDFJs && !_vm.isMinimized
                    ? [
                        _c(
                          "button",
                          {
                            ref: "triggerThumbnails",
                            staticClass: "base-modal__header--button clickable",
                            attrs: {
                              type: "button",
                              disabled: _vm.errorLoading
                            },
                            on: {
                              click: function($event) {
                                return _vm.toggleThumbnailsLayer()
                              }
                            }
                          },
                          [
                            _c("PhList", {
                              attrs: { size: 24, alt: "Thumbnails" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "base-modal__header--button clickable",
                            attrs: {
                              type: "button",
                              disabled: _vm.errorLoading
                            },
                            on: {
                              click: function($event) {
                                return _vm.zoomIn()
                              }
                            }
                          },
                          [
                            _c("PhMagnifyingGlassPlus", {
                              attrs: { size: 24, alt: "Icon zum Vergrößern" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "base-modal__header--button clickable",
                            attrs: {
                              type: "button",
                              disabled: _vm.errorLoading
                            },
                            on: {
                              click: function($event) {
                                return _vm.zoomOut()
                              }
                            }
                          },
                          [
                            _c("PhMagnifyingGlassMinus", {
                              attrs: { size: 24, alt: "Icon zum Verkleinern" }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "toolbar-buttons-center",
              fn: function() {
                return [
                  _vm.usePDFJs && !_vm.isMinimized
                    ? [
                        _vm.pageCount > 1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "base-modal__header--button navigator clickable",
                                attrs: {
                                  type: "button",
                                  disabled:
                                    _vm.isProcessingPDF ||
                                    _vm.pageNum <= 1 ||
                                    _vm.errorLoading
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.prevPage()
                                  }
                                }
                              },
                              [
                                _c("PhCaretLeft", {
                                  attrs: {
                                    size: 24,
                                    alt:
                                      "Icon zum Klicken auf die vorherige Seite"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.pageCount > 1
                          ? _c("span", { staticClass: "current__pdf__page" }, [
                              _vm._v(_vm._s(_vm.pageNum))
                            ])
                          : _vm._e(),
                        _vm.pageCount > 1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "base-modal__header--button navigator clickable",
                                attrs: {
                                  type: "button",
                                  disabled:
                                    _vm.isProcessingPDF ||
                                    _vm.pageNum >= _vm.pageCount ||
                                    _vm.errorLoading
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.nextPage()
                                  }
                                }
                              },
                              [
                                _c("PhCaretRight", {
                                  attrs: {
                                    size: 24,
                                    alt:
                                      "Icon zum Klicken auf die nächste Seite"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "toolbar-buttons-right",
              fn: function() {
                return [
                  _vm.usePDFJs && !_vm.isMinimized
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "base-modal__header--button clickable",
                            attrs: {
                              type: "button",
                              disabled: _vm.errorLoading
                            },
                            on: {
                              click: function($event) {
                                return _vm.printPDF()
                              }
                            }
                          },
                          [_c("PhPrinter", { attrs: { size: 24 } })],
                          1
                        ),
                        _vm.signatureUrlMetadata
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "base-modal__header--button clickable",
                                attrs: { type: "button" }
                              },
                              [
                                _c(
                                  "DownloadLink",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      rel: "noopener noreferer",
                                      filename:
                                        _vm.signatureUrlMetadata.fileName,
                                      disabled: _vm.errorLoading,
                                      downloadServicePath:
                                        _vm.signatureUrlMetadata
                                          .downloadServicePath,
                                      isSecondary: "",
                                      queryParams:
                                        _vm.signatureUrlMetadata.queryParams
                                    }
                                  },
                                  [_c("PhDownload", { attrs: { size: 24 } })],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", {
            ref: "thumbnails",
            staticClass: "thumbnails-layer",
            class: { visible: _vm.showThumbnails },
            attrs: { id: "thumbnails" }
          }),
          (_vm.isProcessingPDF || !_vm.objectUrl) && !_vm.errorLoading
            ? _c(
                "GhostLoading",
                [
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } }),
                  _c("Block", { attrs: { type: "paragraph" } })
                ],
                1
              )
            : _vm._e(),
          _vm.errorLoading
            ? _c(
                "div",
                { staticClass: "bug__icon__div" },
                [
                  _c("h1", [_vm._v("Ups!")]),
                  _c("p", [_vm._v(" Da ist etwas schiefgelaufen! ")]),
                  _c("UndrawLocationSearch")
                ],
                1
              )
            : _vm._e(),
          !_vm.errorLoading
            ? _c(
                "div",
                {
                  ref: "objectContainer",
                  staticClass: "object-container",
                  on: { scroll: _vm.handleScroll, wheel: _vm.handleWheel }
                },
                [
                  _vm.usePDFJs
                    ? _c("canvas", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isProcessingPDF,
                            expression: "!isProcessingPDF"
                          }
                        ],
                        ref: "pdfCanvas"
                      })
                    : _c(
                        "iframe",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isProcessingPDF,
                              expression: "!isProcessingPDF"
                            }
                          ],
                          attrs: {
                            src: _vm.objectUrl,
                            width: "100%",
                            height: "100%"
                          },
                          on: {
                            load: function($event) {
                              _vm.loading = false
                            }
                          }
                        },
                        [
                          _vm._v(
                            " Der Browser unterstützt das Format nicht. Bitte laden Sie die Datei herunter: "
                          ),
                          _c("a", { attrs: { href: _vm.objectUrl } }, [
                            _vm._v("Herunterladen")
                          ]),
                          _vm._v(". ")
                        ]
                      )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }