import { mapGetters } from 'vuex';
import RUHESTANDSPLANUNG_TYPES from '@/store/ruhestandsplanung/types';
import CORE_TYPES from '@/store/core/types';
import MY_GOALS_TYPES from '@/store/myGoals/types';
import {formatNumber, parse} from '@/helpers/number-formatter.js';
import dayjs from 'dayjs';
import { chartColorScheme } from '@/components/retirementScenario/RetirementChart.vue';
import {DatePickerUtils} from '@/components/core/forms/DatePicker/date-picker-utils.js';
import FinancialCalculator, { manLifeYears, womanLifeYears } from '@/components/retirementScenario/financialCalculator.js';

const LEBENSERWARTUNG_MANN = 79;
const LEBENSERWARTUNG_FRAU = 83;

const mixin = {
  data() {
    return {
      // selectedGoals: [],
      apexMarkers: {},
      now: new Date(),
      dataPoints: [],
      isCalculating: false,
    }
  },
  computed: {
    ...mapGetters({
      scenarioStore: RUHESTANDSPLANUNG_TYPES.GETTERS.SCENARIO,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      screenSize: CORE_TYPES.GETTERS.SCREEN_WIDTH,
      vermoegen: RUHESTANDSPLANUNG_TYPES.GETTERS.VERMOEGEN,
      ausgaben: RUHESTANDSPLANUNG_TYPES.GETTERS.AUSGABEN,
      goals: MY_GOALS_TYPES.GETTERS.GOALS,
      scenarioUrsprungStore: RUHESTANDSPLANUNG_TYPES.GETTERS.SCENARIO_URSPRUNG,
    }),
    id() {
      return this.$route.params?.id || '';
    },
    isNewScenario() {
      return this.id === 'new' || false;
    },
    path() {
      return this.$route.matched[1]?.path || '/service/ruhestandsplanung';
    },
    scenario() {
      return this.scenarioStore || {};
    },
    scenarioUrsprung() {
      return !this.isNewScenario ? this.scenarioUrsprungStore : null;
    },
    lifeLength() {
      // const mapLifeYears = this.scenario.gender === 'm' ? manLifeYears.map(v => Math.round(v)) : womanLifeYears.map(v => Math.round(v));
      // return mapLifeYears[this.getAgeTo(new Date().getFullYear())] + this.getAgeTo(new Date().getFullYear()) || 70;
      return this.scenario.gender === 'm' ? LEBENSERWARTUNG_MANN : LEBENSERWARTUNG_FRAU;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    dauerRenteInMonaten() {
      return (this.lebenserwartung(this.scenario) - this.alterRente) * 12 || 0;
    },
    alterRente() {
      return this.scenario.alterRente || 67;
    },
    renditeDurchschnitlich() {
      return 0; //4 / 100;
    },
    sparrate() {
      return this.scenario.scenarioEventList?.filter(event => this.isSparrate(event) && event.selected)?.map(event => 
        parseInt(event.betrag / (event.frequenz === 'JAEHRLICH' ? 12 : 1)))?.reduce(
        (acc, curr) => acc + curr, 0);
    },
    tilgungsplanRowsCurrentScenario() {
      return this.tilgungsplanRows(this.scenario) || [];
    },
    apexSeriesCurrentScenario() {
      return this.apexSeries(this.scenario);
    },
    apexXaxisCurrentScenario() {
      return this.apexXaxis(this.scenario);
    },
    apexAnnotationsCurrentScenario() {
      return this.apexAnnotations(this.scenario);
    },
    retirementYearCurrentScenario() {
      return this.retirementYear(this.scenario);
    },
    renditeDepotGewichtetCurrentScenario() {
      return this.renditeDepotGewichtet(this.scenario);
    },
    renditeSparrateErhoehungCalc() {
      let sparraten = this.scenario.scenarioEventList?.filter(event => event?.type === 'sparquote' && event.selected);
      if (!sparraten?.length) {
        sparraten = this.scenario.scenarioEventList?.filter(event => event?.type === 'periodisch_einnahme' && event.selected);
      }
      return sparraten?.length ? (sparraten[0].rendite || 0) : this.renditeDepotGewichtet(this.scenario);
    },
    ausgabenCurrentScenario() {
      return this.chartAusgaben(this.scenario);
    },
    monatlicheRenteAusKapitalvermögen() {
      if (this.scenario.ohneKapitalverzehr) {
        const ausgaben = this.ausgabenCurrentScenario;
        // Rente ohne Kapitalverzehr ist nicht in der Ausgabentabelle vorhanden, sie wird in tilgungsplanRows() zu den Ausgaben summiert
        const renteList = []
        const arr = this.tilgungsplanRowsCurrentScenario?.filter(row => row.jahr >= this.retirementYearCurrentScenario)
          .map(row => ({ jahr: row.jahr, betrag: row.ausgaben - (ausgaben[row.jahr] || 0), count: 1}))
          .reduce((a, b) => {
            a[b.betrag] = (a[b.betrag] || 0) + b.count
            return a
          }, {});
        const renten = this.tilgungsplanRowsCurrentScenario?.filter(row => row.jahr >= this.retirementYearCurrentScenario)
          .map(row => ({ jahr: row.jahr, rente: row.ausgaben - (ausgaben[row.jahr] || 0)}))
          .reduce((a, b) => {
            a[b.rente] = b.jahr
            return a }, {});
        const test = Object.keys(renten).map(key => ({rente: key, jahr: renten[key]}));
        const result = Object.keys(renten).map(key => ({rente: key, jahr: renten[key]}))
          .sort((a, b) => a?.jahr < b.jahr).map(row => ({alter : row.jahr - this.geburtsjahr(this.scenario), rente: parseInt(row.rente) * -1 || 0}));
        return '';
      }
    },
    sparrateCalc() {
      return this.isRenteOk ? 0 : parseInt((this.sparrate + this.sparrateErhoehungCalc) || 0);
    },
    restKapitalEffektiv() {
        return this.tilgungsplanRowsCurrentScenario.length && (this.tilgungsplanRowsCurrentScenario[this.tilgungsplanRowsCurrentScenario.length - 1]?.summe || 0);
    },
    renditeCalc() {
      let rendite = this.renditeDepotGewichtetCurrentScenario;
      if (!this.isRenteOk) {
        const startkapital = this.gesamtStartVermoegenCurrentScenario < 0 ? 0 : this.gesamtStartVermoegenCurrentScenario;
        const vermoegenBenoetigtRenteBeginn = this.vermoegenBenoetigtRenteBeginn + (this.gesamtStartVermoegenCurrentScenario < 0 ? this.gesamtStartVermoegenCurrentScenario : 0);
        let vermoegenTemp = this.vermoegenGeplant;
        this.isCalculating = true;
        while(vermoegenTemp && vermoegenTemp < vermoegenBenoetigtRenteBeginn) {
          vermoegenTemp = FinancialCalculator.calculateFutureValue(startkapital, 
          (this.retirementYearCurrentScenario - this.currentYear) * 12, 
          this.sparrate, 
          rendite, 
          12);
          rendite = rendite + 0.01;
        }
        this.isCalculating = false;
      }
      return rendite === this.renditeDepotGewichtetCurrentScenario ? 0.000001 : rendite + 0.000001;
    },
    isRenteOk() {
      return this.restKapitalEffektiv >= (this.scenario.restKapital || 0);
    },
    vermoegenBenoetigtRenteBeginn() {
      return this.tilgungsplanVermoegenBenoetigt?.[0]?.depotwert || 0;
    },
    vermoegenGeplant() {
      return parseInt(this.tilgungsplanRowsCurrentScenario?.find(row => row.jahr === this.retirementYearCurrentScenario - 1)?.summe);
    },
    alterVermoegenEnde() {
      const jahr = this.tilgungsplanRowsCurrentScenario?.filter(row => row.jahr >= this.retirementYearCurrentScenario).find(row => row.summe <= 0)?.jahr;
      return jahr && (jahr - 1) > this.geburtsjahr(this.scenario) ? ((jahr - 1) - this.geburtsjahr(this.scenario)) : this.lebenserwartung(this.scenario);
    },
    tilgungsplanVermoegenBenoetigt() {
      const rows = [];
      const retirementYear = this.retirementYearCurrentScenario;
      const lebenserwartungJahr = this.lebenserwartungJahr(this.scenario);
      const ausgaben = this.ausgabenCurrentScenario || {};
      const einnahmen = this.einnahmen(this.scenario);

      let einnahmenProJahr = (einnahmen[lebenserwartungJahr] || []).reduce((acc, curr) => acc + (curr?.sum || 0), 0);
      let sum = (this.scenario.restKapital || 0);
      let renditeGewichtetMitInflation = (this.scenario.renditeRuhestand || 0) - (this.scenario.inflation || 0);
      let depotwert = parseInt(sum * renditeGewichtetMitInflation - einnahmenProJahr - (ausgaben[lebenserwartungJahr] || 0));

      rows.push({jahr: lebenserwartungJahr, depotwert: depotwert || 0, einnahmen: einnahmenProJahr, ausgaben: ausgaben[lebenserwartungJahr] || 0, 
        summe: sum, rendite: renditeGewichtetMitInflation});

      for (let jahr = lebenserwartungJahr-1; jahr >= retirementYear; jahr--) {   
        sum = depotwert;
        const einnahmenProJahr = (einnahmen[jahr] || []).reduce((acc, curr) => acc + (curr?.sum || 0), 0);
        depotwert = parseInt(sum - einnahmenProJahr - (ausgaben[lebenserwartungJahr] || 0));
        if (depotwert > 0) {
          depotwert -= parseInt(depotwert * renditeGewichtetMitInflation / 100);
        }
        rows.unshift({jahr: jahr, depotwert: depotwert, einnahmen: einnahmenProJahr, 
          ausgaben: ausgaben[jahr] || 0, summe: sum, rendite: renditeGewichtetMitInflation});
      }
      return rows;
    },
    canSaveScenario() {
      return !!this.scenario.title
    },
    gesamtStartVermoegenCurrentScenario() {
      return this.gesamtStartVermoegen(this.scenario);
    },
    sparrateErhoehungCalc() {          
      let mankoMonatlich = 0;

      if (!this.isRenteOk) {
        let manko = this.tilgungsplanVermoegenBenoetigt?.length && this.tilgungsplanVermoegenBenoetigt[0]?.depotwert || 0;
        manko += (this.scenario.restKapital || 0);
        
        mankoMonatlich =  FinancialCalculator.calculatePaymentEachPeriod(
          0, 
          manko, 
          (this.retirementYearCurrentScenario - this.currentYear) * 12, 
          this.renditeSparrateErhoehungCalc / 100, 
          12)

        let scenario = Object.assign({}, 
          this.scenario, 
          { scenarioEventList: this.scenario.scenarioEventList?.concat([{
          betrag: parseInt(mankoMonatlich),
          frequenzType: "PERIODISCH",
          selected: true,
          startDate: dayjs(new Date()).format('MM.YYYY'),
          rendite: this.renditeSparrateErhoehungCalc,
          type: "sparquote",
          zuflussType: "EINNAHME"}]) 
          || null});

        const plan = this.tilgungsplanRows(scenario);
        let diff = plan?.length && plan[plan.length - 1]?.summe || 0;

        mankoMonatlich = this.calculateMankoMonatlich(diff, parseInt(mankoMonatlich), diff < (this.scenario.restKapital || 0));
      }
      return parseInt(mankoMonatlich < 1 ? 1 : mankoMonatlich);
    },
  },
  methods: {
    onSelectedVermoegen(selection = []) {
      this.vermoegen?.forEach(verm => verm?.name && this.updateVermoegenAusgaben( Object.assign(verm, {selected: selection?.some(sel => sel.name === verm.name)}), 'vermoegen'));
    },
    onSelectedAusgaben(selection = []) {
      this.ausgaben?.forEach(verm => verm?.name && this.updateVermoegenAusgaben( Object.assign(verm, {selected: selection?.some(sel => sel.name === verm.name)}), 'ausgaben'));
    },
    updateVermoegenAusgaben(row, type) {
      if (row && type) {
        if (type === 'ausgaben' && Object.keys(row)?.includes('value') && row.value > 0) {
          row.value *= -1;
        }
        this.$store.commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_VERMOEGEN_AUSGABEN, {
          id: this.id, 
          type: type,
          row: row
        });
      }
    },
    lebenserwartung(scenario = {}) {
      return scenario.lebenserwartung || this.lifeLength;
    },
    gesamtStartVermoegen(scenario = {}) {
      const result = (scenario.vermoegen?.filter(v => v.selected) || [])?.reduce((acc, curr) => {
        acc = parseFloat(acc) + (parseFloat(curr?.value) || 0);
        return acc;
      }, 0.0) 
        + (scenario.ausgaben?.filter(v => v.selected) || [])?.reduce((acc, curr) => {
        acc = parseFloat(acc) + (parseFloat(curr?.value) || 0);
        return acc;
      }, 0.0)
      return result;
    },
    wunschrenteMitInflation(scenario = {}) {
      /* Wenn der Switcher "ohneKapitalverzehr" ist abgewählt, dann
        Rente kommt vom Feld "monatlicher Bedarf in Euro in Rente (nach heutigem Geldwert)"
        Sonst wird die Rente später calculiert auf Basis vom Depotwert im Jahr von Ruhestandsbeginn, hier ist der Wert 0 */
      const jahreBisRente = this.retirementYear(scenario) - this.currentYear - 1;
      const rente = scenario.bedarfMonatlich && !scenario.ohneKapitalverzehr
        ? FinancialCalculator.calculateFutureValue(scenario.bedarfMonatlich, this.retirementYear(scenario) - this.currentYear - 1, 0, scenario.inflation/100, 1) : 0;
      return parseFloat(parseFloat(rente).toFixed(0))
    },
    lebenserwartungJahr(scenario = {}) {
      return this.geburtsjahr(scenario) + this.lebenserwartung(scenario);
    },
    retirementYear(scenario = {}) {
      return this.geburtsjahr(scenario) + (scenario.alterRente || 67);
    },
    geburtsjahr(scenario = {}) {
      const geburtstag = dayjs(scenario.geburtsdatum || '', 'DD.MM.YYYY').toDate();
      return geburtstag?.getFullYear() || 0;
    },
    alterAktuell(scenario) {
      return this.currentYear - this.geburtsjahr(scenario);
    },
    chartAusgaben(scenario = {}) {
      const ausgaben = {};
      const retirementYear = this.retirementYear(scenario);
      const wunschrenteMitInflation = this.wunschrenteMitInflation(scenario) * 12;
      this.goals?.filter(goal => scenario.myGoalList?.some(g => g.id === goal.id))?.filter(goal => goal.endDate && goal.zielsumme)?.forEach(goal => {
        let jahr = dayjs(goal.endDate, 'MM.YYYY', true).toDate().getFullYear(); 
        ausgaben[jahr] = (ausgaben[jahr] || 0) - goal.zielsumme;
      });
      scenario.scenarioEventList?.filter(goal => goal.selected && goal.zuflussType === 'AUSGABE')?.forEach(goal => {
        const endDate = dayjs(goal.endDate, 'MM.YYYY', true).toDate().getFullYear() || (this.lebenserwartungJahr(scenario) - 1);
        if (this.isPeriodischeTransaktion(goal)) {
          const startDate = dayjs(goal.startDate, 'MM.YYYY', true).toDate().getFullYear() || null;  
          for(let jahr = startDate; jahr <= endDate; jahr++) {
            ausgaben[jahr] = (ausgaben[jahr] || 0) - goal.betrag * (goal.frequenz === 'JAEHRLICH' ? 1 : 12) ;
          }
        } else {
          if (endDate) {
            ausgaben[endDate] = (ausgaben[endDate] || 0) - goal.betrag;
          }
        }
      })
    
      if (wunschrenteMitInflation) {
        for (let j = retirementYear; j <= this.lebenserwartungJahr(scenario); j++) {
          ausgaben[j] = ( ausgaben[j] || 0) - wunschrenteMitInflation;
        }
      }
      return ausgaben;
    },
    chartEinnhamen(scenario = {}) {
      const einnahmen = {};
      scenario.scenarioEventList?.filter(event => event.selected && event.zuflussType === 'EINNAHME')?.forEach(event => {
        let endDate = dayjs(event.endDate, 'MM.YYYY', true).toDate().getFullYear(); 
        const rendite = event.rendite ? (1 + event.rendite / 100) : 1;
        if (this.isPeriodischeTransaktion(event)) {
          endDate = endDate || (this.lebenserwartungJahr(scenario) - 1);
          const startDate = dayjs(event.startDate, 'MM.YYYY', true).toDate().getFullYear(); 
          for(let jahr = startDate; jahr <= endDate; jahr++) {
            einnahmen[jahr] = (einnahmen[jahr] || 0) + event.betrag * (event.frequenz === 'JAEHRLICH' ? 1 : 12) * rendite;
          }
        } else {
          if (endDate) {
            einnahmen[endDate] = (einnahmen[endDate] || 0) + (event.betrag * rendite);
          }
        }
      })
      for (let j = this.retirementYear(scenario); j <= this.lebenserwartungJahr(scenario); j++) {
        einnahmen[j] = (einnahmen[j] || 0) + (scenario.renteMonatlichErwartet || 0) * 12;
      }
      return einnahmen;
    },
    einnahmen(scenario = {}) {
      const einnahmen = {};
      scenario.scenarioEventList?.filter(event => event.selected && event.zuflussType === 'EINNAHME')?.forEach(event => {
        let endDate = dayjs(event.endDate, 'MM.YYYY', true).toDate().getFullYear(); 
        if (this.isPeriodischeTransaktion(event)) {
          endDate = event?.type === 'sparquote' ? this.retirementYear(scenario) : (endDate || (this.lebenserwartungJahr(scenario) - 1));
          const startDate = dayjs(event.startDate, 'MM.YYYY', true).toDate().getFullYear(); 
          for(let jahr = startDate; jahr <= endDate; jahr++) {
            einnahmen[jahr] =(einnahmen[jahr] || []).concat([{ sum: event.betrag * (event.frequenz === 'JAEHRLICH' ? 1 : 12), rendite: event.rendite }]);
          }
        } else {
          if (endDate) {
            einnahmen[endDate] = (einnahmen[endDate] || []).concat([{ sum: event.betrag, rendite: event.rendite }]);
          }
        }
      })
      for (let j = this.retirementYear(scenario); j <= this.lebenserwartungJahr(scenario); j++) {
        einnahmen[j] = (einnahmen[j] || []).concat([{ sum: (scenario.renteMonatlichErwartet || 0) * 12}]);
      }
      return einnahmen;
    },
    renditeDepotGewichtet(scenario = {}) {
      const vermoegenList = scenario.vermoegen?.filter(curr => curr.selected && curr.value);
      const summe = vermoegenList?.reduce((acc, curr) => acc + curr.value, 0);
      const rendite = summe === 0 ? 0 : vermoegenList?.reduce((acc, curr) => acc + (curr.rendite || 0) * curr.value / summe, 0) || 0;
      const test1 = formatNumber(rendite || 0.0, 2)
      const test = parse(formatNumber(rendite || 0.0, 2))
      return parse(formatNumber(rendite || 0.0, 2));
    },
    renditeEinnahmeGewichtet(einnahmen = [], depotwert, depotrendite) {
      const summe = einnahmen?.reduce((acc, curr) => acc + curr.sum, depotwert || 0);
      const rendite = einnahmen?.reduce((acc, curr) => acc + (curr.rendite || 0) * curr.sum / summe, depotrendite || 0) || 0;
      return parse(formatNumber(rendite, 2));
    },
    apexXaxis(scenario = {}) {
      const jahre = []
      let jahr = this.alterAktuell(scenario);
      for (jahr; jahr <= this.lebenserwartung(scenario); jahr++) {
        jahre.push(jahr);
      }
      // jahre.push(jahr)
      return {
        categories: jahre,
      }
    },
    apexSeries(scenario = {}) {  
      const data = this.tilgungsplanRows(scenario);
      return [{
        data: (data?.map(row => row.depotwert || 0))
        // ?.concat(data[data.length-1]?.summe || 0) || []
      }];
    },
    apexAnnotations(scenario = {}) {
      const events = {};
      events[scenario.alterRente || 67] = {
        x: scenario.alterRente || 67,
        label: {
          text: 'Renteneintritt'
        }
      };
      events[this.lebenserwartung(scenario)] = {
        x: this.lebenserwartung(scenario),
        label: {
          text: 'Lebenserwartung'
        }
      };
      scenario.myGoalList?.concat(...scenario.scenarioEventList?.filter(goal => goal.selected && !this.isPeriodischeTransaktion(goal)) || [])
        .filter(goal => goal.endDate)
        .forEach(goal => {
          const jahr = dayjs(goal.endDate, 'MM.YYYY', true).toDate().getFullYear();
          const alter = jahr - this.geburtsjahr(scenario)
          if (jahr && !events[alter]?.label?.text?.endsWith('...')) {
            events[alter] = {
              x: alter + 1,
              label: {
                text: events[alter]?.label?.text && !events[alter]?.label?.text.endsWith('...') ? (events[alter]?.label?.text + ' und weitere ...') : goal.title
              }
            };
          }
        });
      const annotations = { xaxis: Object.values(events)};
      return annotations;
    },
    hatNeueEinnahmen(einnahmen = {}, jahr) {
      if (jahr && jahr > this.currentYear) {
        const currentEinnahmen = einnahmen[jahr];
        const einnahmenLetztesJahr = einnahmen[jahr - 1];
        const result =  currentEinnahmen?.length !== einnahmenLetztesJahr?.length 
          || !currentEinnahmen?.every((value, index) => value?.sum === einnahmenLetztesJahr?.[index]?.sum && value?.rendite === einnahmenLetztesJahr?.[index]?.rendite);
          return result
      }
    },
    tilgungsplanRows(scenario = {}) {
      const rows = [];
      let depotwert = parseInt(this.gesamtStartVermoegen(scenario));
      const retirementYear = this.retirementYear(scenario);
      const lebenserwartungJahr = this.lebenserwartungJahr(scenario);
      const renditeDepotGewichtet = this.renditeDepotGewichtet(scenario);
      const ausgaben = this.chartAusgaben(scenario) || {};
      const einnahmen = this.einnahmen(scenario);

      let einnahmenProJahr = (einnahmen[this.currentYear] || []).reduce((acc, curr) => acc + (curr?.sum || 0), 0);
      let sum = parseInt(depotwert + (ausgaben[this.currentYear] || 0) + einnahmenProJahr);
      let renditeGewichtet = this.renditeEinnahmeGewichtet(einnahmen[this.currentYear], depotwert, renditeDepotGewichtet);
      let renditeGewichtetMitInflation = renditeGewichtet - (scenario.inflation || 0);
      var renteAuszahlungIfOhneKapitalverzehr = 0;

      rows.push({jahr: this.currentYear, depotwert: depotwert, einnahmen: einnahmenProJahr, ausgaben: ausgaben[this.currentYear] || 0, 
        summe: sum, rendite: renditeGewichtetMitInflation});

      for (let jahr = this.currentYear + 1; jahr <= lebenserwartungJahr; jahr++) {   
        if (this.hatNeueEinnahmen(einnahmen, jahr)) {      
          renditeGewichtet = jahr >= retirementYear ? (this.scenario.renditeRuhestand || 0) 
            : this.renditeEinnahmeGewichtet(einnahmen[jahr], sum, renditeGewichtet);
          renditeGewichtetMitInflation = renditeGewichtet - (scenario.inflation || 0);
        }
        depotwert = sum;
        
        if (scenario.ohneKapitalverzehr && jahr >= retirementYear) {
          // jährliche Rente ergibt sich dann aus der Rendite des angesparten Kapitals pro Jahr plus "geschätzte Renteneinnahmen pro Monat"
          renteAuszahlungIfOhneKapitalverzehr = (depotwert > 0  ? (sum - depotwert) : 0) - (scenario.renteMonatlichErwartet || 0) * 12;
        }

        const einnahmenProJahrMitRendite = (einnahmen[jahr] || []).filter(einnahme => !!einnahme?.rendite).reduce((acc, curr) => acc + (curr?.sum || 0), 0);
        const einnahmenProJahrOhneRendite = (einnahmen[jahr] || []).filter(einnahme => !einnahme?.rendite).reduce((acc, curr) => acc + (curr?.sum || 0), 0);
        einnahmenProJahr = einnahmenProJahrMitRendite + einnahmenProJahrOhneRendite;

        sum = parseInt(depotwert + (einnahmenProJahrMitRendite * (1 + renditeGewichtetMitInflation / 100)) + einnahmenProJahrOhneRendite
        + ((ausgaben[jahr] || 0) + renteAuszahlungIfOhneKapitalverzehr));
        if (sum > 0) {
          sum = parseInt(sum * (1 + renditeGewichtetMitInflation / 100));
        }
        rows.push({jahr: jahr, depotwert: depotwert, einnahmen: einnahmenProJahr, 
          ausgaben: ((ausgaben[jahr] || 0) + renteAuszahlungIfOhneKapitalverzehr), summe: sum, rendite: renditeGewichtetMitInflation});
      }
      return rows;
    },
    async saveScenario() {
      await this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.SAVE_SCENARIO, this.id).then(response => {
        if (this.id === 'new' && response?.id) {
          this.$router.push(`${this.path}/${response.id}/${this.stepKey}`)
        }
      });
    },
    async saveFinancialPlaning() {
      if (this.canSaveScenario) {
        const response = await this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.SAVE_SCENARIO, this.id)
        return response?.id
      }
    },
    isPeriodischeTransaktion(event) {
      return event?.frequenzType === 'PERIODISCH';
    },
    isSparrate(event) {
      return event?.type === 'sparquote' || event.type === 'periodisch_einnahme';
    },
    formatValue(value = '', type = '') {
        switch (type) {
            case 'percent':
                return !value ? '' : (formatNumber(value) + ' %');
            case 'currency': 
                return !value ? '' : (formatNumber(value) + ' €');
            case 'age': 
                return !value ? '' : (value + ' Jahre');
            default:
                return value;
        }
    },
    getAgeTo(year) {
        if (!this.geburtsjahr(this.scenario) || !year) {
          return;
        }
        const yearVal = year.split && year.split('.');
        const theYear = yearVal && yearVal[2] ? yearVal[2] : year;
        return theYear - this.geburtsjahr(this.scenario) || 0;
    },
    getColor(index) {
        return chartColorScheme[index % chartColorScheme.length];
    },
    getMonthsFromCurrent(date) {
      if (date === undefined || date === null) {
        return 0;
      }
      if (date instanceof Date) {
        if (date < this.now) {
          return 0;
        }
        return DatePickerUtils.safeDate(date).getMonth() - DatePickerUtils.safeDate(this.now).getMonth() +
          (12 * (DatePickerUtils.safeDate(date).getFullYear() - DatePickerUtils.safeDate(this.now).getFullYear()));
      } else {
        const stDate = DatePickerUtils.getDateFromString(date);
        if (stDate < this.now) {
          return 0;
        }
        return stDate.getMonth() - DatePickerUtils.safeDate(this.now).getMonth() +
          (12 * (stDate.getFullYear() - this.now.getFullYear()));
      }
    },
    calculateMankoMonatlich(manko = 0, mankoMonatlichStartValue = 1) {
      this.isCalculating = true;
      let result = this.iterrateSparrate(manko, mankoMonatlichStartValue);
      result = this.iterrateSparrate(result.diff, result.mankoMonatlich, 1);
      this.isCalculating = false;
      return result.mankoMonatlich < 1 ? 1 : result.mankoMonatlich;
    },
    iterrateSparrate(diff, mankoMonatlich, plusOne) {
      const restKapital = this.scenario.restKapital || 0;
      const toIncrement = diff < restKapital;
      let valueToAdd = plusOne ? 1 : 0;
      while((toIncrement ? diff < restKapital : diff > restKapital) && diff > mankoMonatlich && ((diff - restKapital) > mankoMonatlich)) {
        if (!plusOne) {
          valueToAdd = parseInt((diff - restKapital) / mankoMonatlich);
          if (valueToAdd > mankoMonatlich) {
            valueToAdd = parseInt(mankoMonatlich / 2);
          }
        }
        mankoMonatlich = toIncrement ? (mankoMonatlich + valueToAdd) : (mankoMonatlich - valueToAdd);

        const scenario = Object.assign({}, 
        this.scenario, 
        { scenarioEventList: this.scenario.scenarioEventList?.concat([{
          betrag: mankoMonatlich,
          frequenzType: "PERIODISCH",
          selected: true,
          startDate: dayjs(new Date()).format('MM.YYYY'),
          rendite: this.renditeSparrateErhoehungCalc,
          type: "sparquote",
          zuflussType: "EINNAHME"}]) 
        || null});

        const plan = this.tilgungsplanRows(scenario);
        diff = plan?.length && plan[plan.length - 1]?.summe || 0;
      }
      return { mankoMonatlich: (mankoMonatlich || 0) + (!toIncrement ? 1 : 0), diff: diff || 0 };
    },
    doChanges(componentId, value, id = '') {
      this.$store.commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_STORE, { value: { [componentId]: value}, id: id || this.id });
    },
  },
}

export default mixin;