<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="input-forms__label-content input-forms__label-content--bigger" v-if="title">
            {{title}}
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <BaseButton @click="deleteAllPositions()" isSecondary v-if="tableData.records && tableData.records.length" :disabled="disabled">
            Alle Positionen löschen
          </BaseButton>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <div v-if="tableData && tableData.records && tableData.records.length">
        <div>
          <Table
            tableId="982380e1-dfba-49a1-a32b-ad852331a768"
            :headers="headers"
            :rows="rows"
            rowId="index"
            hidePagination
            @action-edit="openPosition($event.index)"
            @action-delete="deletePosition($event.index)"
          >
            <template v-slot:Gesellschaft="row">
              <ph-warning :size="16" class="color-danger" v-if="getWarningMessages(row)" v-fc-tooltip="getWarningMessages(row)" />
              <label>{{row.Gesellschaft}}</label>
            </template>
          </Table>
        </div>
      </div>
      <div v-else>
        <div>Depotauswahl ist leer</div>
      </div>
    </div>
    <hr>
    <div class="row"><div class="col">
      Übertrag eines Depots
    </div></div>
    <div class="row">
      <div class="col">
        <ComboBox :values="config.depots" v-model="depot" firstEmpty :disabled="disabled" />
      </div>
      <div class="col">
        <BaseButton @click="openPositionForDepot()" :disabled="!depot || disabled">{{ btnTxtFormularDepot }}</BaseButton>
      </div>
    </div>
    <div v-if="!config.isIntern" class="row"><div class="col">
      Übertrag von Gesellschaft (falls das Depot nicht aufgeführt wird)
    </div></div>
    <div v-if="!config.isIntern" class="row">
      <div class="col">
        <ComboBox :disabled="disabled" :values="config.gesellschaften" v-model="gesellschaft" firstEmpty/>
      </div>
      <div class="col">
        <BaseButton @click="openPositionForGesellschaft()" :disabled="!gesellschaft || disabled">{{ btnTxtFormularGesellschaft }}</BaseButton>
      </div>
    </div>
    <div  v-if="!config.isIntern" class="row"><div class="col">
      Freies Übertragsformular (Hier können Sie alle Daten selbst eingeben.)
    </div></div>
     <div  v-else class="row"><div class="col">
     Neues Formular für FFB erstellen
    </div></div>
    <div class="row">
      <div class="col">
      </div>
      <div class="col">
        <BaseButton :disabled="disabled" @click="openNewPosition({ isManuell: true })">{{ btnTxtFormularFree }}</BaseButton>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from "@/store/customerData/types.js";
import BaseButton from '@/components/core/BaseButton.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import ComboBox from '@/components/core/forms/ComboBox.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import validator from '@/mixins/validator'
import { PhTrash, PhPencilLine, PhWarning } from 'phosphor-vue'

export default {
  mixins: [antragNavigationMixin, validator],
  validators: {},
  components: {
    BaseButton,
    ComboBox,
    Table,
    PhPencilLine,
    PhTrash,
    PhWarning,
  },
  props: {
    title: {
    },
    config: {
    },
    antragData: {
    },
    categoryId: {
      type: String,
    },
    antragId: {
      type: String,
      required: true,
    },
    warnings: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      depot: null,
      gesellschaft: null,
    }
  },
    computed: {
        ...mapGetters({
            customerData: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA,
            additionalPersons: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS,
        }),
        headers() {
            return {
                lockedLeft: [
                    SlotColumn("Gesellschaft", "Gesellschaft"),
                    TextColumn("Depotnummer", "Depotnummer").makeAlwaysVisible(),
                ],
                center: [
                    TextColumn("Menge", "Positionen"),
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            };
        },
        rows() {
            if (!this.antragData || !this.antragData['INPUT_DATA_POSITIONEN_KSC'])
                return [];
            const isinIndices = []
            for (let i = 1; i <= this.config.anzahlFonds; i++) {
                isinIndices.push(i);
            }
            const mapping = this.antragData && this.antragData['INPUT_DATA_POSITIONEN_KSC_MAPPING'] || {};
            return this.antragData['INPUT_DATA_POSITIONEN_KSC']
                .map((position, index) => {
                    return {
                        index,
                        Gesellschaft: position[mapping['mappingWertpapiername'] || 'bisherManuellName'],
                        Depotnummer: position[mapping['mappingDepotnr'] || 'bishernummer' || 'bisherDepotnr'] || position['bisherDepotnr'],
                        Menge: (isinIndices.find(i => !position["isin" + i]) || (this.config.anzahlFonds + 1)) - 1,
                        actions: this.disabled ? [] : [
                            SimpleAction("edit", '', "Bearbeiten"),
                            SimpleAction("delete", '', "Löschen"),
                        ],
                    };
                }) || [];
        },
      tableData() {
      const tableData = {
        headers: this.headers,
        records: this.rows
      };
      return tableData;
    },
    persons() {
      const additionalPersons = (this.additionalPersons || []);
      return [this.customerData, ...additionalPersons];
    },
    btnTxtFormularGesellschaft() {
      return this.config?.btnTxtFormularGesellschaft || 'Formular für Gesellschaft erstellen';
    },
    btnTxtFormularFree() {
      return this.config?.btnTxtFormularFree || 'Formular erstellen';
    },
    btnTxtFormularDepot(){
      return this.config?.btnTxtFormularDepot || 'Formular für Depot erstellen'
    }
  },
  mounted() {
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS);
  },
  methods: {
    openPositionForDepot() {
      let depot = {}
        if (this.depot != null) {
          depot = this.config.depots.find(depot => depot.value === this.depot);
          if (depot && depot.data) {
            this.openNewPosition(depot.data);
          }
        }
    },
    openPositionForGesellschaft() {
      let gesell = {}
      if (this.gesellschaft != null) {
        gesell = this.config.gesellschaften.find(g => g.value == this.gesellschaft);
        if (gesell) {
          const param = encodeURIComponent(gesell.value);
          const data = Object.assign(gesell.data, { isManuell: true });
          this.openNewPosition(data); //{ filterGesellschaft: param }
        }
      }
    },
    openNewPosition(data = {}, query = {}) {
      for (let i = 1; i <= 2; i++) {
        const personId = this.antragData && this.antragData["INPUT_PERSONEN_INHABER" + i]
        if (!personId) {
          continue
        }
        const person = this.persons.find(person => person && (person.personId+'') === (personId+''))
        if (person) {
          const mapping = this.antragData && this.antragData['INPUT_DATA_POSITIONEN_KSC_MAPPING'] || {};
          if (mapping['personId' + i]) { data[mapping['personId' + i]] = personId || ''; }
          if (mapping['anrede' + i]) { data[mapping['anrede' + i]] = person.personalDataAddress && person.personalDataAddress.title; }
          if (mapping['name' + i]) { data[mapping['name' + i]] = person.personalDataAddress && person.personalDataAddress.lastName; }
          if (mapping['vorname' + i]) { data[mapping['vorname' + i]] = person.personalDataAddress && person.personalDataAddress.firstName; }
          if (mapping['strasse' + i]) { data[mapping['strasse' + i]] = person.personalDataAddress && person.personalDataAddress.street; }
          if (mapping['plz' + i]) { data[mapping['plz' + i]] = person.personalDataAddress && person.personalDataAddress.zip; }
          if (mapping['ort' + i]) { data[mapping['ort' + i]] = person.personalDataAddress && person.personalDataAddress.city; }
          if (mapping['country' + i]) { data[mapping['country' + i]] = person.personalDataAddress && person.personalDataAddress.country; }
          if (mapping['gebDatum' + i]) { data[mapping['gebDatum' + i]] = person.personalDataBirth && person.personalDataBirth.dayOfBirth; }
          if (mapping['id' + i]) { data[mapping['id' + i]] = person.personalDataTax && person.personalDataTax.taxID; }
        }
      }
      const positions = this.antragData && this.antragData[this.categoryId] ? this.antragData[this.categoryId].slice() : []
      positions.push(data);
      this.$emit('input', positions)
      this.openPosition(positions.length - 1, query)
    },
    openPosition(index, query = {}) {
      this.$router.push({ path: `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}/${this.$route.params.step}/depotuebertragung/depotdaten/${this.categoryId}/${index}`, query})
    },
    deletePosition(index) {
      const positions = this.antragData && this.antragData['INPUT_DATA_POSITIONEN_KSC'] || [];
      if (positions.length && index >= 0 && index < positions.length) {
        positions.splice(index, 1);
        this.$emit('change', { INPUT_DATA_POSITIONEN_KSC: positions });
      }
    },
    deleteAllPositions() {
      const positions = this.antragData && this.antragData['INPUT_DATA_POSITIONEN_KSC'] || [];
      if (positions.length) {
        this.$emit('change', { INPUT_DATA_POSITIONEN_KSC: [] });
      }
    },
    getWarningMessages(row) {
      const warnings = this.warnings && this.warnings.positionWarnings 
        && this.warnings.positionWarnings.filter(warn => 
          warn.id && this.categoryId && warn.id.includes(this.categoryId)
          && (warn.posGrpId ? (warn.posGrpId === row.Depotnummer || warn.posGrpId === row.Gesellschaft) 
          : !(row.Depotnummer || row.Gesellschaft)));
      return warnings && warnings.map(warn => warn.message).join('<hr class="my-0 py-0">');
    },
  }
}
</script>

<style scoped>
  .input-forms__label-content--bigger {
    font-size: 1.5rem;
  }
  .input-forms__label-content--bigger > i {
    font-size: 2rem;
  }
</style>