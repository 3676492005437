<template>
<div>
  <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
  <PageHeaderTitleNavigation 
    :id="$appNavigation.currentOptionMenuId" 
    :title="$appNavigation.currentMenu.label" 
    :actions="headerActions" 
    @action-ADD="openDocumentUploadModal()" 
  />

  <div class="box__container">
    <div class="row">
      <div class="col-12">
        <span>Für welchen Quartalsbericht soll der Marktbericht verwendet werden?</span>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-sm-6 mb-2">
            <ComboBox
            label="Quartal"
            v-model="marktberichtUploaden.quartal"
            :values="fieldsDefinition.quartal.value"
            :disabled="fieldsDefinition.quartal.disabled"
          />
          </div>
          <div class="col-12 col-sm-6 mb-2">
            <ComboBox
            label="Jahr"
            v-model="marktberichtUploaden.year"
            :values="fieldsDefinition.year.value"
            :disabled="fieldsDefinition.quartal.disabled"
          />
          </div>
        </div>
      </div>
    </div>
  </div>
  <HinweiseUndFehler :hints="hinweisen" />

  <div class="box__container">
    <div class="row">
      <div class="col-12">
        <Table v-if="rows.length"
            tableId="28be5c8b-3311-4c3e-9532-021a7ec708de"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="10"
            :rowId="'datei'"
            @click-datei="showDocument"
            @action-DELETE="openDeleteDocumentConfirmModal"
        />
      </div>
    </div>
  </div>

  <BaseModal 
    ref="documentUploadModal"
    modalTitle="Datei hochladen"
    labelButtonConfirm="Hochladen"
    :showDefaultButtons="false"
    @onCloseButton="cancelDocumentUpload()"
    @onEscButton="cancelDocumentUpload()"
  >
    <template #default>
      <div class="row">
        <div class="col-12 mb-3">
          <BaseFileSelect @files="fileList=$event"/>
        </div>
        <div class="col-12 mb-2" v-if="fileToUpload && fileToUpload.name">
          <span style="font-weight: bold">Dateiname: </span>
          <span>{{fileToUpload.name}}</span>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <BaseButton isSecondary @click="cancelDocumentUpload">Abbrechen</BaseButton>
      <BaseButton @click="uploadDocument" :disabled="!canUpload">Hochladen</BaseButton>
    </template>
  </BaseModal>

  <BaseModal 
    ref="deleteDocumentConfirmModal"
    modalTitle="Löschen"
    labelButtonConfirm="Löschen"
    @onCloseButton="cancelDeleteDocument()"
    @onCancelButton="cancelDeleteDocument()"
    @onEscButton="cancelDeleteDocument()"
    @onConfirmButton="deleteDocument()"
  >
    <template #default>
      Wollen Sie das Dokument wirklich löschen?
    </template>
  </BaseModal>

</div>
</template>

<script>
import { mapGetters } from "vuex";
import MARKTBERICHTUPLOADEN_TYPES from "@/store/marktberichtUploaden/types";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ComboBox from "@/components/core/forms/ComboBox.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import dayjs from 'dayjs';
import HinweiseUndFehler from '@/components/core/HinweiseUndFehler.vue'
import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, DateColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseModal from "@/components/core/BaseModal.vue";
import {uploadFileChunked} from '@/helpers/upload-helper'
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
 components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    ComboBox,
    BaseButton,
    HinweiseUndFehler,
    DocumentsUploadModal,
    Table,
    BaseModal,
    BaseFileSelect,
  },
  data() {
    return {
      CUSTOM_UPLOAD: 3,
      loading: false,
      marktberichtUploaden: {
        quartal: '1',
        year: dayjs().get('year').toString(),
      },
      hinweisen: [{title:'',message:'Es können nur TEXT-Dateien verarbeitet werden. Eventuell vorhandene Berichte werden überschrieben.'}],
      documentToDelete: null,
      fileList: [],
    }
  },
  computed: {
    ...mapGetters({
      fieldsDefinition: MARKTBERICHTUPLOADEN_TYPES.GETTERS.FIELDS_DEFINITION,
      documents: MARKTBERICHTUPLOADEN_TYPES.GETTERS.FIND_DOCUMENTS,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Hochladen'),
      ];
    },
    headers() {
      const headers = {
        lockedLeft: [
            TextColumn("datei", "Datei").makeLink(),
        ],
        center: [
            TextColumn("size", "Größe"),
            TextColumn("erstellungsdatum", "Erstellungsdatum"),
        ],
        lockedRight: [ActionColumn("actions", "Aktionen")],
      };
      return headers
    },
    rows() {
      const actions = [
        SimpleAction("DELETE", '', "Löschen"),
      ];

      if(this.isVVUploadModus) {
        actions.push(SimpleAction("EDIT_DOKUMENT", '', "Bearbeiten"))
      }

      return this.documents?.map(row => ({...row , actions} ));
    },
    isVVUploadModus(){
      return 'VV_UPLOAD_MODUS' === this.$route.meta.modus;
    },
    canUpload() {
      return !!this.fileList.length;
    },
    fileToUpload() {
      return this.fileList[0] || {};
    },
  },
  methods: {
    openDocumentUploadModal() {
      this.$refs.documentUploadModal.open();
    },
    cancelDocumentUpload() {
      this.fileList = [];
      this.$refs.documentUploadModal.close();
    },
    uploadDocument() {
      if(!!Object.keys(this.fileToUpload)) {
        uploadFileChunked(this.fileToUpload).then((tempFileId) => {
          const tempFile = {
            tempFileId: tempFileId,
            isVVUploadModus: this.isVVUploadModus,
            ...this.marktberichtUploaden,
          }
          this.$store.dispatch(MARKTBERICHTUPLOADEN_TYPES.ACTIONS.UPLOAD_DOCUMENT, tempFile)
          .then(() => {
            this.findDocuments();
          });
        })
        .finally(() => (this.cancelDocumentUpload()))
      }
    },
    async findDocuments() {
      await this.$store.dispatch(MARKTBERICHTUPLOADEN_TYPES.ACTIONS.FIND_DOCUMENTS, {modusVVUpload: this.isVVUploadModus});
    },
    showDocument(row) {
      this.$store.dispatch(MARKTBERICHTUPLOADEN_TYPES.ACTIONS.DOWNLOAD_DOCUMENT, {...row, modusVVUpload: this.isVVUploadModus});
    },
    openDeleteDocumentConfirmModal(row) {
      this.documentToDelete = row;
      this.$refs.deleteDocumentConfirmModal.open();
    },
    deleteDocument() {
      this.$store.dispatch(MARKTBERICHTUPLOADEN_TYPES.ACTIONS.DELETE_DOCUMENT, {fileName: this.documentToDelete.datei,  modusVVUpload: this.isVVUploadModus })
      .then(() => this.findDocuments())
      .finally(() => (this.cancelDeleteDocument()))
    },
    cancelDeleteDocument() {
      this.documentToDelete = null;
    },
  },
  async mounted() {
    this.$store.dispatch(MARKTBERICHTUPLOADEN_TYPES.ACTIONS.GET_FIELDS_DEFINITION);
    try {
      this.loading = true;
      await this.findDocuments();
    } finally {
      this.loading = false;
    }
  }
}
</script>
