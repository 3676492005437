var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Vermittler Statistiken",
          subtitle: _vm.selectedStatistics.title,
          defaultMenu: _vm.tabMenus
        }
      }),
      _vm.selectedStatistics.title == "Versicherung"
        ? _c("div", { staticClass: "box__container" }, [
            _vm.selectedStatistics.title == "Versicherung"
              ? _c("div", { staticClass: "warning" }, [
                  _c("div", { staticClass: "font-bold" }, [
                    _vm._v("Hinweise für Statistik nach Vertragsanzahl:")
                  ]),
                  _c("div", { staticClass: "small" }, [
                    _vm._v(
                      'Auf Grundlage des im MSC definierten Erfassungsdatums des Vertrages (ersichtlich in der Vertragsmaske) wird die Anzahl der Versicherungsverträge in der Statistik dargestellt. (Bsp. "Vertragsanzahl nach Gesellschaft pro Jahr" VHV - 2018 – 55 – es wurden innerhalb des Jahres 2018 (Erfassungsdatum) 55 Verträge im MSC erfasst)'
                    )
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.selectedStatistics.title == "Versicherung"
        ? _c("div", { staticClass: "box__container" }, [
            _c(
              "div",
              { staticClass: "checkbox-input__container" },
              [
                _c("InputCheckBoxItem", {
                  attrs: { label: "Eigenbestand" },
                  model: {
                    value: _vm.eigenbestand,
                    callback: function($$v) {
                      _vm.eigenbestand = $$v
                    },
                    expression: "eigenbestand"
                  }
                }),
                _c("InputCheckBoxItem", {
                  attrs: { label: "Fremdbestand" },
                  model: {
                    value: _vm.fremdbestand,
                    callback: function($$v) {
                      _vm.fremdbestand = $$v
                    },
                    expression: "fremdbestand"
                  }
                }),
                _c("InputCheckBoxItem", {
                  attrs: { label: "nur aktive Verträge" },
                  model: {
                    value: _vm.aktiv,
                    callback: function($$v) {
                      _vm.aktiv = $$v
                    },
                    expression: "aktiv"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.selectedStatistics.title != null &&
      _vm.selectedStatistics.title != "Mitarbeiter"
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputToggleSwitch", {
                attrs: {
                  label:
                    "Daten inklusive aller Vermittler der Struktur anzeigen",
                  inLineLabel: ""
                },
                model: {
                  value: _vm.struktur,
                  callback: function($$v) {
                    _vm.struktur = $$v
                  },
                  expression: "struktur"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          !_vm.loadingTitles && _vm.statList.length
            ? _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c("Table", {
                    attrs: {
                      tableId: "4b2ce043-7354-47f4-8f15-b2ade56de5f3",
                      title: _vm.selectedStatistics.title,
                      headers: _vm.headers,
                      rows: _vm.rows
                    },
                    on: {
                      "click-bezeichnung": function($event) {
                        return _vm.onClickBtn($event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm.loadingTitles
            ? _c("GhostLoading", {
                attrs: {
                  type: "table",
                  title: _vm.selectedStatistics.title,
                  useBoxContainer: ""
                }
              })
            : _c("NoData", {
                attrs: {
                  title: _vm.selectedStatistics.title,
                  useBoxContainer: ""
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }