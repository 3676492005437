<template>
  <div class="base-image__container no-line-height">
    <img v-show="src && imgLoaded" class="base-image" :class="[`image-${fit}`]" :src="src" @load="onImgLoad" alt="Unternehmenslogo"/>
    <div v-if="!imgLoaded" class="base-image-placeholder" :style="placeholderStyle"></div>
  </div>
</template>

<script>
export function createPlaceholderSize(width = 100, height = 100) {
  const addPxIfNeeded = value => /^[0-9]+$/.test(value) 
    ? `${value}px` 
    : value;

  return {
    width: addPxIfNeeded(width),
    height: addPxIfNeeded(height),
  };
}

const DEFAULT_PLACEHOLDER_SIZE = createPlaceholderSize(100, 100);

const FIT_VALIDS = ['cover', 'cover-vertically', 'cover-horizontally', 'contain', 'fill'];

export default {
  name: 'BaseImage',
  props: {
    src: {
      type: String,
      default: '',
    },
    fit: {
      type: String,
      default: 'cover',
      validator(value) {
        return FIT_VALIDS.indexOf(value) >= 0;
      },
    },
    placeholder: {
      type: Object,
      default: () => DEFAULT_PLACEHOLDER_SIZE,
    },
  },
  data() {
    return {
      imgLoaded: false,
    }
  },
  computed: {
    placeholderStyle() {
      return {
        width: `${this.placeholder?.width}`, 
        height: `${this.placeholder?.height}`,
      };
    },
  },
  methods: {
    onImgLoad() {
      this.imgLoaded = true;
    }
  }
}
</script>

<style scoped>
.base-image-placeholder {
  background: #c4c4c4;
  max-height: 100%;
  max-width: 100%;
}
</style>
