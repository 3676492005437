<template>
  <div class="carousel-item__container" :class="containerClass" @click="handleAction($event, item)">
    <GhostLoading v-if="item.loading" type="custom" :width="112">
      <Block :height="52" />
      <Block />
    </GhostLoading>
    <template v-else>
      <div class="carousel-item__icon" :style="iconStyle">
        <component :is="item.component" v-bind="item.componentProps"/>
        <BaseBadgeCounter :count="item.badgeCount" class="carousel-item__icon--badge__counter" />
      </div>
      <div class="carousel-item__label" :style="labelStyle" v-html="sanitize(item.label)" />
    </template>
  </div>
</template>

<script>
import {sanitize} from '@/helpers/string-helper.js';
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import BaseBadgeCounter from '@/components/core/BaseBadgeCounter.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import ROLES from '@/router/roles';

const TOTAL_ICON_PADDING = 16;
const TOTAL_LABEL_HEIGHT = 28;

export default {
  components: {
    BaseBadgeCounter,
    Block,
    GhostLoading,
  },
  props: {
    item: {
    },
    settings: {
      type: Object,
      default: () => {}
    },
    asGrid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notAllowed: false,
    };
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    containerClass() {
      const { item, notAllowed, asGrid } = this;
      return {
        'disabled': item?.disabled,
        'not-allowed': item?.disabled && notAllowed,
        'clickable': !item?.disabled,
        'carousel-item__as-grid': asGrid,
        'item-active': item?.textBold,
        'is-loading': item?.loading,
        [item?.classes || '']: true,
      };
    },
    iconStyle() {
      const iconWidth = this.settings?.icon?.width || (this.settings.item.width - TOTAL_ICON_PADDING);
      const iconHeight = this.settings?.icon?.height || (this.settings.item.height - TOTAL_LABEL_HEIGHT - TOTAL_ICON_PADDING);
      return {
        width: `${iconWidth}px`,
        flexBasis: `${iconHeight}px`,
      };
    },
    labelStyle() {
      return {
        maxWidth: `${this.settings.item.width}px`,
        color: this.hasRoles(ROLES.TEST_USER) && this.item?.hasTestUserRole ? '' : undefined, // temporarely remoce red label for test features var(--color-danger)
      };
    },
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    handleAction(event, item) {
      if(event.target.closest('.carousel-scrolling') || item.loading) {
        return;
      }

      if (item.disabled) {
        this.notAllowed = true;
        setTimeout(() => {
          this.notAllowed = false;
        }, 300);
      } else if (item.action) {
        item.action();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-item__container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: center; 
  background-color: var(--color-background-secondary);
  margin: 0 12px;
  padding: 5px;
  transition: opacity .3s ease;



  &.is-loading {
    margin: 0 2px;
  }

  &.carousel-item__as-grid {
    padding: 8px 0;
    margin: 2px 2px;
  }
}
.carousel-item__icon {
  flex: 0 0 48px;
  height: 48px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;
}
.carousel-item__icon >>> .base-image__container {
  display: flex;
  justify-content: center;
}
.carousel-item__icon--badge__counter {
  position: absolute;
  top: 0;
  right: 8px;
}
.carousel-item__label {
  color: var(--color-text);
  font-size: #{"min(16px, .9em)"};
  flex: 0 0 auto;
  text-align: center;
  line-height: 1.2em;
  margin: 4px 0 0;
  min-width: 68px;
  width: min-content;
}

.item-active .carousel-item__label {
  text-shadow: 0 0 1px var(--color-text);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.not-allowed {
  animation: vibration 0.1s infinite;
}

@keyframes vibration {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}

</style>

