<template>
    <div>
        <Table v-if="records.length"
            tableId="f168f001-d40a-4a9a-ba2b-aa8d3ce74e30"
            :headers="headers"
            :rows="records"
            rowId="index"
            hidePagination
            @action-DELETE="deletePosition"
        >
            <template v-slot:anteile="row">
                <div>
                    <ComboBox v-if="hasGesamtbestand"
                        :isEmbedded="true" 
                        :value="gesamtbestand(row)"
                        :values="anteileValues"
                        @change="onChange('alle', $event, row)"
                    />
                    <InputField v-if="!hasGesamtbestand || isVisible(row)"
                        :isEmbedded="true" 
                        :value="row.anteile" 
                        @change="onChange('anteile', $event, row)"
                    />
                </div>
            </template>
        </Table>

        <div class="mt-3" >
            <BaseButton @click="$emit('fondsfinder')" :disabled="disabled || (!records && resords.length <= anzahlFonds)">Positionen hinzufügen</BaseButton>
        </div>
        <FormLabel class="col-12 mt-3" :config="{'small': true}" :label="'(Maximal ' + anzahlFonds + ' Positionen)'"/>
    </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SlotColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import { PhTrash } from 'phosphor-vue';

export default {
    mixins: [antragMixin],
    props: {
        hasGesamtbestand: {
            type: Boolean,
            default: false
        },
        titleAnteile: {
            type: String,
            default: 'Anteile',
        },
        titleIsin: {
            type: String,
            default: 'ISIN',
        },
        positions: {
        },
        anzahlFonds: {
            type: Number,
            default: 4,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        BaseButton,
        Table,
        InputField,
        ComboBox,
        FormLabel,
        PhTrash,
    },
    data() {
        return {
            anteileValues: [{ label: 'Gesamtbestand', value: 'alle'}, {label: 'Anteile', value: 'anteile'}],
        }
    },
    computed: {
        headers() {
            return {
                center: [
                    TextColumn("isin", this.titleIsin),
                    TextColumn("wertpapiername", "Wertpapiername"),
                    SlotColumn("anteile", this.titleAnteile, 100),
                ],
                lockedRight: [
                    ActionColumn("actions", ""),
                ],
            }
        },
        records() {
            const records = [];
            let existPositionsCount = 0;
            for (let i = 0; i <= this.anzahlFonds; i++) {
                if (!this.positions['isin' + (i + 1)]) {
                    existPositionsCount = i;
                    break;
                }
            }
            const actions = [
                SimpleAction("DELETE", '', "Löschen"),
            ];
            for (let i = 1; i <= existPositionsCount; i++) {
                records.push({
                    isin: this.positions['isin' + i],
                    wertpapiername: this.positions['fondsname' + i],
                    anteile: this.positions['anteile' + i],
                    alle:  this.positions['alle' + i],
                    index: i,
                    actions,
                });
            }
            return records;
        },
    },
    methods: {
        deletePosition(row) {
            if(row) {
                const positionList = this.getPositionsÜbertragung(this.positions, this.anzahlFonds);
                if (positionList.length) {
                    positionList.splice(row.index-1, 1);
                    this.positions = Object.assign(this.positions, this.setPositionsÜbertragung(positionList, this.anzahlFonds));
                    this.$emit('save', this.positions);
                }
            }
        },
        onChange(id, value, row) {
            if (row && row.index) {
                if (id === 'alle') {
                    this.positions = Object.assign(this.positions, {['alle' + row.index]: (value === 'alle' ? true : false )});
                } else {
                    this.positions[id + row.index] = value;
                }
                this.$emit('save', this.positions);
            }
        },
        isVisible(row) {
            return !row.alle;
        },
        gesamtbestand(row) {
            return row.alle ? 'alle' : 'anteile';
        }
    }
}
</script>

<style>

</style>