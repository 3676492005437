<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row">
        <div class="col">
          <div class="input-forms__label-content input-forms__label-content--bigger" v-if="title">
            <PhBank :size="16" />&nbsp;{{title}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ComboBox
            :value="portfolioCombo" 
            :values="values"
            @input="getPortfolioStruktur($event)"
            isEmbedded/>
        </div>
        <div class="col-auto">
          <BaseButton @click="openFormsFinder" :disabled="portfolioCombo !== 'freie Eingabe:'">Wertpapier hinzufügen</BaseButton>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
        <Table v-if="rows.length"
            :headers="headers"
            :rows="rows"
            rowId="index"
            hidePagination
            @action-DELETE="openModalDeleteFond"
        >
            <template v-slot:prozent="row">       
                <InputField type="percent" :precission="6" :value="row.prozent" :disabled="isMusterDepot"
                    @input="onChangeProzent($event, row)"
                />
            </template>
        </Table>
        <div v-else-if="errorMessage">{{errorMessage}}</div>
        <div v-else>Wertpapierauswahl ist leer</div>
    </div>

    <DeletePositionModal ref="deleteModal" :position="positionToDelete"
      @delete="deletePosition()"></DeletePositionModal>
  </div>
</template>

<script>
import ANTRAG_TYPES from '@/store/antrag/types';
import BaseButton from '@/components/core/BaseButton.vue';
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SlotColumn, SimpleAction} from "@/components/table2/table_util.js";
import AntragFormsFondsFinder from '@/components/antrag/fondsfinder/AntragFormsFondsFinder.vue'
import { PhBank } from 'phosphor-vue';

const VALUE_FREIE_EINGABE = 'freie Eingabe:';
const NICHT_VERWENDEN = 'NICHT_VERWENDEN';
const INPUT_PORTFOLIO_LISTE = 'INPUT_PORTFOLIO_LISTE';
const DESERIALIZED_LENGTH = 3;

export default {
  mixins: [antragNavigationMixin],
  components: {
    BaseButton,
    ComboBox,
    InputField,
    Table,
    DeletePositionModal,
    AntragFormsFondsFinder,
    PhBank,
  },
  props: {
    title: {
    },
    antragData: {
    },
    config: {
    },
    id: {
    },
    antragId: {
    },
    antragTyp: {
    },
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false
    },
    values: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
        positionToDelete : {},
        headers: {
            lockedLeft: [
                TextColumn("isin", "ISIN").makeAlwaysVisible(),
                TextColumn("fondsName", "Fondsname"),
                SlotColumn("prozent", "Proz. Gewichtung", 60).makeAlwaysVisible().withSumFooter(),
            ],
            lockedRight: [
                ActionColumn("actions", ""),
            ]
        }
    }
  },
  computed: {
    isMusterDepot() {
      return this.portfolioCombo && this.portfolioCombo !== NICHT_VERWENDEN && this.portfolioCombo !== VALUE_FREIE_EINGABE;
    },
    rows() {
        const actions = this.isMusterDepot ? []
          : [SimpleAction("DELETE", '', "Löschen")];
        return this.deserialize.map(row => ({
            ...row,
            actions,
        }));
    },
    deserialize() {
      const data = this.antragData && this.antragData[INPUT_PORTFOLIO_LISTE];
      const arr = [];
			if (data && data.length) {
				const splitData = data.split('&=');
        const arrLengt = splitData.length / DESERIALIZED_LENGTH;
        for (let i = 0; i < arrLengt; i++) {
          const pos = {
            isin: (splitData[i*DESERIALIZED_LENGTH] || '').split('=').join(''),
            fondsName: (splitData[i*DESERIALIZED_LENGTH + 1] || '').split('+').join(' ').replace('=', '').replace('%28', '(').replace('%29', ')'),
            prozent: (splitData[i*DESERIALIZED_LENGTH + 2] || '').split('=').join('').replace('%2C', ',') || 0,
            index: i,
          }
          arr.push(pos);
        }
      }
      return arr;
    },
    errorMessage() {
      return this.antragData?.errrorMessage;
    },
    portfolioCombo() {
      const value = this.antragData && this.id && this.antragData[this.id];
      return value;
    },
  },
  methods: {
    openFormsFinder() {
      this.goToFormsFinder(INPUT_PORTFOLIO_LISTE, Object.assign({}, this.config, {query: '{"saveType": "serialize"}'}), this.suppressFilterLagerstelle);
    },
    getPortfolioStruktur($event) { 
      this.$emit('change', { [this.id]: $event })
      if ($event !== NICHT_VERWENDEN && $event !== VALUE_FREIE_EINGABE) {
        this.$store.dispatch(ANTRAG_TYPES.ACTIONS.PORTFOLIO_STRUKTUR, { 
          portfolioId: $event, antragId: this.antragId, componentId: INPUT_PORTFOLIO_LISTE
        });
      } else if ($event === NICHT_VERWENDEN) {
        this.updateStore(INPUT_PORTFOLIO_LISTE, null);
      }
    },
    updateStore(componentId, value) {
      const payload = {
        id: this.antragId,
        data: {
          [componentId]: value
        }
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload);
    },
    onChangeProzent($event, position) { 
      const data = this.antragData && this.antragData[INPUT_PORTFOLIO_LISTE];
			if (data && data.length) {
				const splitData = data.split('&=');
        splitData[position.index*DESERIALIZED_LENGTH + 2] = $event;
        const serializedStr = this.serialize(splitData);
        this.updateStore(INPUT_PORTFOLIO_LISTE, serializedStr);
      }
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.positionToDelete.fondsname = fond.fondsName;
      this.$refs.deleteModal.open()
    },
    deletePosition() {
      if (this.positionToDelete) {
        const data = this.antragData && this.antragData[INPUT_PORTFOLIO_LISTE];
        if (data && data.length) {
          const splitData = data.split('&=');
          if (splitData.length >= (this.positionToDelete.index * DESERIALIZED_LENGTH + 3)) {
            splitData.splice(this.positionToDelete.index * DESERIALIZED_LENGTH, 3);
            const serializedStr = this.serialize(splitData);
            this.updateStore(INPUT_PORTFOLIO_LISTE, serializedStr);
          }
        }
      }
    },
    serialize(splitData = []) {
      let serializedStr = splitData.join('&=');
      if (serializedStr?.length && !serializedStr.startsWith('=')) {
        serializedStr = '=' + serializedStr;
      }
      return serializedStr;
    }
  }
}
</script>

<style scoped>

  .input-forms__container {
    margin-bottom: 20px;
  }
</style>