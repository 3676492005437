var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Transaktionen"
        }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "Transaktionenfilter",
          filterId: "8d6f9099-9423-4c02-99db-82877e030bd5",
          hasSmartSearch: "",
          showSaveButton: "",
          metadata: _vm.searchMetadata,
          configFilter: _vm.configFilter,
          predefinedFilter: null,
          defaultOptions: _vm.defaultOptions,
          immidiateSearch: ""
        },
        on: {
          onFilter: function($event) {
            return _vm.submit($event)
          },
          changeSubFilter: _vm.changeSubFilter
        }
      }),
      _c("HandleTablePin", { attrs: { keyOfPIN: "TABELLE_TRANSAKTIONEN" } }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "5b5f0386-f56f-49bb-b156-e1d2316ded87" }
        },
        [
          !_vm.loading && _vm.rows.length
            ? _c("Table", {
                ref: "tblTransakt",
                attrs: {
                  tableId: "1448ac4f-556d-4def-a8fc-ccb5f6dc2926",
                  title: _vm.TABLE_TITLE,
                  rowId: "index",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20,
                  exportConfig: _vm.exportConfig,
                  mobileConfig: {
                    title: "Wertpapiername",
                    headers: ["Umsatzart", "Datum", "ISIN", "Kundennr"]
                  }
                },
                on: { "click-Wertpapiername": _vm.navigateToDepotposition }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "maxWidthPDFCol",
          attrs: {
            modalTitle: "Maximale Breite einer PDF-Spalte",
            showCancelButton: true,
            labelButtonConfirm: "PDF-Transaktionen erzeugen"
          },
          on: {
            onConfirmButton: _vm.sendPdfData,
            onCancelButton: _vm.cancelSendPdfData
          }
        },
        [
          _c("ComboBox", {
            staticClass: "mt-1",
            attrs: {
              label: "",
              values: _vm.maxWidthPDFOpt,
              sortComboboxValues: false
            },
            model: {
              value: _vm.maxWidthPDF,
              callback: function($$v) {
                _vm.maxWidthPDF = $$v
              },
              expression: "maxWidthPDF"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }