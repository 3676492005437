<template>
    <div>
        <DragnDropArea
            hoverText="Neues Dokument hochladen"
            @files="openDocumentUploadModal($event)"
        >
            <div class="box__container">
                <div class="box__title">Dokumente und Prospekte</div>
                <Table
                    tableId="vvstrategienfiles"
                    :headers="headers"
                    :rows="rows"
                    :rowsPerPage="10"
                    rowId="fileId"
                    @click-bezeichnung="showDocument"
                    @action-DELETE="deleteDocument"
                    @action-EDIT="openEditModal"
                    hidePagination
                />
           </div>
        </DragnDropArea>
        
        <DocumentsUploadModal
            ref="uploadForm"
            :files="files"
            :additionalInputs="uploadFields"
            :uploadType="3"
            :multipleFileUpload="true"
            :openOnMount="false"
            :customUpload="upload"
            @close="files=null"
        />

        <BaseModal
            ref="editModal"
            modalTitle="Datei hochladen"
            labelButtonConfirm="Ok"
            labelButtonCancel="Abbrechen"
            @onConfirmButton="saveFile()"
        >
            <InputField v-model="editFile.fileName" :disabled="true" label="Dateiname" />
            <ComboBox v-model="editFile.typ" :values="typValues" label="Typ" />
            <InputField v-model="editFile.bezeichnung" label="Bezeichnung" />
            <InputTextArea v-model="editFile.bemerkung" label="Bemerkung" />
        </BaseModal>
    </div>
</template>

<script>
import InputTextArea from "@/components/core/forms/InputTextArea.vue"
import VV_TYPES from "@/store/vermoegensverwaltung/types"
import DocumentsUploadModal from "@/components/fileUpload/DocumentsUploadModal.vue"
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue"
import Table from "@/components/table2/Table.vue"
import {TextColumn, ActionColumn, SimpleAction, ConfirmedAction} from "@/components/table2/table_util.js"
import {viewDocument} from "@/components/core/download/DownloadLink.vue"
import ComboBox from "@/components/core/forms/ComboBox.vue"
import InputField from "@/components/core/forms/InputField.vue"
import BaseModal from "@/components/core/BaseModal.vue"
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js"
import LOG_TYPES from "@/store/log/types"
import {buildMessage} from "@/helpers/log-message-helper"

import EVENT_BUS, { VV_UPLOAD_DOCUMENT } from '@/event-bus';

export default {
    mixins: [mixin],
    components: {
        InputTextArea,
        ComboBox,
        InputField,
        Table,
        DocumentsUploadModal,
        DragnDropArea,
        BaseModal,
    },
    data() {
        return {
            factsheetEmail: "",
            factsheetInternet: "",
            marktKommentarFactsheet: "",
            anlageStrategieFactsheet: "",
            strategieBeraterFactsheet: "",
            portfolioKommentarFactsheet: "",
            schlusssatzPortfoliokommentar: "",
            files: null,
            typValues: [
                {label: "Dokument für Vermögensverwaltung", value: "VV_SONSTIGES"},
                {label: "Factsheet für Vermögensverwaltung", value: "VV_PROSPEKT"},
            ],
            editFile: {
                bezeichnung: "",
                bemerkung: "",
                typ: "",
                fileName: "",
            },
            changeAllMarktkommentarText: "",
        }
    },
    mounted() {
        this.registerEventBus();
    },
    destroyed() {
        this.unregisterEventBus();
    },
    computed: {
        headers() {
            return {
                lockedLeft: [
                    TextColumn("bezeichnung", "Name").makeLink(),
                    TextColumn("bemerkung", "Bemerkung"),
                    TextColumn("typStr", "Typ")
                ],
                center: [],
                lockedRight: [ActionColumn("actions", "Aktionen")],
            }
        },
        rows() {
            return (this.schemaData?.files || []).map((row) => {
                const actions = [SimpleAction("EDIT", "PhPencil", "Bearbeiten")]
                if (row.canDelete) {
                    actions.push(ConfirmedAction("DELETE", 'PhTrash', "Löschen", row.deleteText, 'Datei löschen', "Löschen"),)
                }
                return {
                    ...row,
                    actions,
                }
            })
        },
        uploadFields() {
            return [
                {
                    key: "typ",
                    label: "Typ",
                    type: "COMBOBOX",
                    value: "VV_SONSTIGES",
                    options: this.typValues,
                },
                {key: "bezeichnung", label: "Bezeichnung", type: "STRING", value: ""},
                {key: "bemerkung", label: "Bemerkung", type: "TEXT", value: ""},
            ]
        },
    },
    methods: {
        registerEventBus() {
            EVENT_BUS.$off(VV_UPLOAD_DOCUMENT);
            EVENT_BUS.$on(VV_UPLOAD_DOCUMENT, () => {
                this.openDocumentUploadModal([])
            });
        },
        unregisterEventBus() {
            EVENT_BUS.$off(VV_UPLOAD_DOCUMENT);
        },
        showDocument(file) {
            if (file?.schemaFileId) {
                this.$store.dispatch(VV_TYPES.ACTIONS.OPEN_SCHEMA_DOCUMENT_PDF, file.schemaFileId).then((response) => {
                    if (response?.data?.errorMessage) {
                        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, "danger"))
                    } else {
                        viewDocument({
                            filename: file.fileName || "unbenannt",
                            data: response?.data?.bytes,
                            contentType: response?.data?.contentType || "application/pdf",
                        })
                    }
                })
            }
        },
        deleteDocument(row) {
            if (row.schemaFileId) {
                this.$store.dispatch(VV_TYPES.ACTIONS.DELETE_SCHEMA_DOCUMENT, row.schemaFileId)
            }
        },
        openEditModal(file) {
            this.editFile.bezeichnung = file?.bezeichnung || ""
            this.editFile.bemerkung = file?.bemerkung || ""
            this.editFile.typ = file?.typ || ""
            this.editFile.fileName = file?.fileName || ""
            this.editFile.schemaFileId = file?.schemaFileId
            this.editFile.fileId = file?.fileId
            this.$refs.editModal.open()
        },
        saveFile() {
            if (this.editFile) {
                this.$store.dispatch(VV_TYPES.ACTIONS.UPLOAD_SCHEMA_DOCUMENT, {
                    schemaFileId: this.editFile.schemaFileId,
                    formData: this.editFile,
                    fileId: this.editFile.fileId,
                })
            }
        },
        openDocumentUploadModal(files) {
            this.files = files
            this.$refs.uploadForm.open()
        },
        upload(data) {
            if (data?.id && data?.formData) {
                const formData = data.formData
                formData.schemaId = this.schemaId
                this.$store.dispatch(VV_TYPES.ACTIONS.UPLOAD_SCHEMA_DOCUMENT, {
                    schemaFileId: "",
                    formData: formData,
                    fileId: data.id,
                })
            }
        },
    },
}
</script>