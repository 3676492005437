var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Layout bearbeiten",
          actions: _vm.headerActions
        },
        on: {
          "action-SAVE": function($event) {
            return _vm.save()
          },
          "action-META": function($event) {
            _vm.showMetaTagManager = true
          },
          "action-DOMAIN": function($event) {
            _vm.showDomainsManager = true
          },
          "action-RESET": function($event) {
            return _vm.loadDefault(_vm.selectedSchema)
          },
          "action-TOGGLE-ADVANCED-VIEW": function($event) {
            _vm.advancedView = !_vm.advancedView
          }
        }
      }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-3" }, [
          !_vm.loading
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c(
                      "BaseButton",
                      {
                        on: {
                          click: function($event) {
                            return _vm.onChangeCMSHasDifferentLayout(
                              !_vm.cmsHasDifferentLayout
                            )
                          }
                        }
                      },
                      [
                        _vm.cmsHasDifferentLayout
                          ? _c("span", [
                              _vm._v(" weiteres Layout für CMS entfernen ")
                            ])
                          : _vm._e(),
                        !_vm.cmsHasDifferentLayout
                          ? _c("span", [
                              _vm._v(" weiteres Layout für CMS hinzufügen ")
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c("InputRadioBoxGroup", {
                          attrs: {
                            title: "Layout zum bearbeiten:",
                            values: _vm.availableLayoutSchemas
                          },
                          on: {
                            input: function($event) {
                              return _vm.selectSchema()
                            }
                          },
                          model: {
                            value: _vm.selectedSchema,
                            callback: function($$v) {
                              _vm.selectedSchema = $$v
                            },
                            expression: "selectedSchema"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("InputField", {
                      attrs: { label: _vm.loginWelcomeTitle },
                      model: {
                        value: _vm.selectedLocalLayout.loginWelcomeTitle,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedLocalLayout,
                            "loginWelcomeTitle",
                            $$v
                          )
                        },
                        expression: "selectedLocalLayout.loginWelcomeTitle"
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.welcomeTitleFieldDescription) + " ")
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Primärfarbe" },
                      on: { input: _vm.primarySecondaryOrTextChanged },
                      model: {
                        value: _vm.selectedLocalLayout.colorPrimary,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedLocalLayout, "colorPrimary", $$v)
                        },
                        expression: "selectedLocalLayout.colorPrimary"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: {
                        label:
                          "Primärtextfarbe (Ist das Feld leer, wird die Standarfarbe gezeigt)",
                        previewValue: _vm.colorPrimaryTextOrCalculated
                      },
                      on: { input: _vm.primarySecondaryOrTextChanged },
                      model: {
                        value: _vm.selectedLocalLayout.colorPrimaryText,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedLocalLayout,
                            "colorPrimaryText",
                            $$v
                          )
                        },
                        expression: "selectedLocalLayout.colorPrimaryText"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Sekundärfarbe" },
                      on: { input: _vm.primarySecondaryOrTextChanged },
                      model: {
                        value: _vm.selectedLocalLayout.colorSecondary,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedLocalLayout,
                            "colorSecondary",
                            $$v
                          )
                        },
                        expression: "selectedLocalLayout.colorSecondary"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: {
                        label:
                          "Sekundärtextfarbe (Ist das Feld leer, wird die Standarfarbe gezeigt)",
                        previewValue: _vm.colorSecondaryTextOrCalculated
                      },
                      on: { input: _vm.primarySecondaryOrTextChanged },
                      model: {
                        value: _vm.selectedLocalLayout.colorSecondaryText,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedLocalLayout,
                            "colorSecondaryText",
                            $$v
                          )
                        },
                        expression: "selectedLocalLayout.colorSecondaryText"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Text" },
                      on: { input: _vm.primarySecondaryOrTextChanged },
                      model: {
                        value: _vm.selectedLocalLayout.colorText,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedLocalLayout, "colorText", $$v)
                        },
                        expression: "selectedLocalLayout.colorText"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.advancedView,
                        expression: "advancedView"
                      }
                    ],
                    staticClass: "box__container"
                  },
                  [
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Seiten Hintergrund" },
                      model: {
                        value: _vm.selectedLocalLayout.colorBackground,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedLocalLayout,
                            "colorBackground",
                            $$v
                          )
                        },
                        expression: "selectedLocalLayout.colorBackground"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Card Hintergrund" },
                      model: {
                        value: _vm.selectedLocalLayout.colorBox,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedLocalLayout, "colorBox", $$v)
                        },
                        expression: "selectedLocalLayout.colorBox"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.advancedView,
                        expression: "advancedView"
                      }
                    ],
                    staticClass: "box__container"
                  },
                  [
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Akzentfarbe Info" },
                      model: {
                        value: _vm.selectedLocalLayout.colorInfo,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedLocalLayout, "colorInfo", $$v)
                        },
                        expression: "selectedLocalLayout.colorInfo"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Akzentfarbe Success" },
                      model: {
                        value: _vm.selectedLocalLayout.colorSuccess,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedLocalLayout, "colorSuccess", $$v)
                        },
                        expression: "selectedLocalLayout.colorSuccess"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Akzentfarbe Warning" },
                      model: {
                        value: _vm.selectedLocalLayout.colorWarning,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedLocalLayout, "colorWarning", $$v)
                        },
                        expression: "selectedLocalLayout.colorWarning"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Akzentfarbe Danger" },
                      model: {
                        value: _vm.selectedLocalLayout.colorDanger,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedLocalLayout, "colorDanger", $$v)
                        },
                        expression: "selectedLocalLayout.colorDanger"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.advancedView,
                        expression: "advancedView"
                      }
                    ],
                    staticClass: "box__container"
                  },
                  [
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Statusleise Hintergrundfarbe" },
                      model: {
                        value: _vm.selectedLocalLayout.colorTopNavBackground,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedLocalLayout,
                            "colorTopNavBackground",
                            $$v
                          )
                        },
                        expression: "selectedLocalLayout.colorTopNavBackground"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Statusleiste Textfarbe" },
                      model: {
                        value: _vm.selectedLocalLayout.colorTopNavText,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedLocalLayout,
                            "colorTopNavText",
                            $$v
                          )
                        },
                        expression: "selectedLocalLayout.colorTopNavText"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Link Farbe" },
                      model: {
                        value: _vm.selectedLocalLayout.colorLink,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedLocalLayout, "colorLink", $$v)
                        },
                        expression: "selectedLocalLayout.colorLink"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Arbeitsbereich Hintergrundfarbe" },
                      model: {
                        value:
                          _vm.selectedLocalLayout.colorWorkspacesNavBackground,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedLocalLayout,
                            "colorWorkspacesNavBackground",
                            $$v
                          )
                        },
                        expression:
                          "selectedLocalLayout.colorWorkspacesNavBackground"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Arbeitsbereich Textfarbe" },
                      model: {
                        value: _vm.selectedLocalLayout.colorWorkspacesNavText,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedLocalLayout,
                            "colorWorkspacesNavText",
                            $$v
                          )
                        },
                        expression: "selectedLocalLayout.colorWorkspacesNavText"
                      }
                    }),
                    _c("InputTextColorPickerFull", {
                      attrs: { label: "Arbeitsbereich Button Aktiv" },
                      model: {
                        value: _vm.selectedLocalLayout.colorWorkspacesNavActive,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedLocalLayout,
                            "colorWorkspacesNavActive",
                            $$v
                          )
                        },
                        expression:
                          "selectedLocalLayout.colorWorkspacesNavActive"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.advancedView,
                        expression: "advancedView"
                      }
                    ],
                    staticClass: "box__container"
                  },
                  [
                    _c("InputField", {
                      attrs: { label: "Logo URL" },
                      model: {
                        value: _vm.selectedLocalLayout.logoUrl,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedLocalLayout, "logoUrl", $$v)
                        },
                        expression: "selectedLocalLayout.logoUrl"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Hintergrundbild Login URL" },
                      model: {
                        value: _vm.selectedLocalLayout.imageBackgroundLoginUrl,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.selectedLocalLayout,
                            "imageBackgroundLoginUrl",
                            $$v
                          )
                        },
                        expression:
                          "selectedLocalLayout.imageBackgroundLoginUrl"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.advancedView,
                        expression: "advancedView"
                      }
                    ],
                    staticClass: "box__container"
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("InputChips", {
                            attrs: {
                              supportCustomValue: "",
                              value:
                                _vm.generalChartColorWrapper.colorInputChips,
                              label: "Standard Chart Farben"
                            },
                            on: {
                              addItem: function($event) {
                                return _vm.generalChartColorWrapper.add($event)
                              },
                              removeItem: function($event) {
                                return _vm.generalChartColorWrapper.remove(
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "layout__negative-margin--8 edit-layout__color-preview-box"
                          },
                          _vm._l(_vm.generalChartColorWrapper.colors, function(
                            colorPreview,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: colorPreview + index,
                                staticClass: "edit-layout__color-preview",
                                style: { "background-color": colorPreview }
                              },
                              [_vm._v("   ")]
                            )
                          }),
                          0
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("InputChips", {
                            attrs: {
                              supportCustomValue: "",
                              label: "Farbverlauf für Charts",
                              value:
                                _vm.gradientChartColorWrapper.colorInputChips
                            },
                            on: {
                              addItem: function($event) {
                                return _vm.gradientChartColorWrapper.add($event)
                              },
                              removeItem: function($event) {
                                return _vm.gradientChartColorWrapper.remove(
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "layout__negative-margin--8 edit-layout__color-preview-box"
                          },
                          _vm._l(_vm.gradientChartColorWrapper.colors, function(
                            colorPreview,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: colorPreview + index,
                                staticClass: "edit-layout__color-preview",
                                style: { "background-color": colorPreview }
                              },
                              [_vm._v("   ")]
                            )
                          }),
                          0
                        )
                      ])
                    ])
                  ]
                )
              ])
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("GhostLoading", { attrs: { type: "input" } }),
                    _c("GhostLoading", { attrs: { type: "input" } })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("GhostLoading", { attrs: { type: "input" } }),
                    _c("GhostLoading", { attrs: { type: "input" } })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("GhostLoading", { attrs: { type: "input" } }),
                    _c("GhostLoading", { attrs: { type: "input" } }),
                    _c("GhostLoading", { attrs: { type: "input" } }),
                    _c("GhostLoading", { attrs: { type: "input" } })
                  ],
                  1
                )
              ])
        ]),
        _c(
          "div",
          { staticClass: "col-9" },
          [
            _c("LayoutColorsPreview", {
              attrs: {
                loading: _vm.loading,
                layoutColors: _vm.selectedLocalLayout
              }
            })
          ],
          1
        )
      ]),
      _vm.showMetaTagManager
        ? _c("MetaTagManager", {
            on: {
              close: function($event) {
                _vm.showMetaTagManager = false
              }
            }
          })
        : _vm._e(),
      _vm.showDomainsManager
        ? _c("DomainsManager", {
            on: {
              close: function($event) {
                _vm.showDomainsManager = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }