<template>
	<div>		
		<WertpapierSearch  v-if="mainParameters"
			name="AnlageempfehlungSuche" type="I" ref="wp_filter"
			:lagerstelle="lagerstelle"
			:respond="3" 
			isSelectable
			@selected="selectedFunds"
		/>
		
		<div class="box__container">
			<div class="box__title">Anlageempfehlungsliste</div>
			<GhostLoading v-if="loading">
				<Block height="200" />
			</GhostLoading>
			<Table v-else-if="showedRows.length"
				:headers="headers"
				:rows="showedRows"
				rowId="isin_id"
				hidePagination
				@action-DELETE="delRow"
				@action-FACTSHEET="viewDocument($event.FACTSHEET)"
				@action-FACTSHEET_KAG="viewDocument($event.FACTSHEET_KAG)"
				@action-PRIIP_BIB="viewDocument($event.PRIIP_BIB_URL)"
        @click-name="gotoFondsinfo"
			>
				<template #header_active>
					<InputCheckBoxItem
							:value="areAllSelected"
							:indeterminate="areOnlySomeSelected"
							@input="changeStatus"
							style="margin-left: 3px"
					/>
				</template>
				<template #active="row">
					<InputCheckBoxItem :value="row.active" @input="(checked) => onChangeActive(row.isin, checked)" />
				</template>
			</Table>
      <NoData :noIcon="true" v-else />
		</div>
	</div>
</template>
<script>
import StepMixin from "./StepMixin";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import StepAllocation from "./StepAllocation";
import WertpapierSearch from '@/components/wertpapierinfo/WertpapierSearch.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import NoData from '@/components/core/NoData.vue';

export default {
	data() {
		return {
			stepAllocation: [
				new StepAllocation(INVESTMENT_ADVICE.QUELLE.MANUELLESUCHE, null) // INVESTMENT_ADVICE.PURPOSE.INPUT
			],
			step: INVESTMENT_ADVICE.STEPS.FONDSFINDER,
			rows: [],
			headers: {
        lockedLeft: [
          SlotColumn('active', null, 80),
        ],
        center: [
          TextColumn('isin', 'ISIN'),
          TextColumn('name', 'Name').makeAlwaysVisible().makeLink(),
          TextColumn('Frs', 'SRI', 50, 0),
          TextColumn('Kategoriee', 'Kategorie'),
        ],
        lockedRight: [
          ActionColumn('actions', '')
        ]
			}
	}
  },
	mixins: [StepMixin],
	computed: {
    areAllSelected() {
      return this.rows.every(row => row.active);
    },
    areOnlySomeSelected() {
      return this.rows.some(row => row.active) && this.rows.some(row => !row.active);
    },
		showedRows() {
			return this.rows.filter(row => !row.isDeleted)
		},
    mainParameters() {
			const data = this.parameters(INVESTMENT_ADVICE.STEPS.MAIN)?.data || {};
			return data;
    },
    lagerstelle() {
			const data = this.mainParameters?.parameterData?.ziellagerstelle || '';
			return data;
    },
	},
	components: {
		WertpapierSearch, Table, InputCheckBoxItem, GhostLoading, Block, NoData
	},
  watch: {
    stepParameters: function(params) {
			if (params.data?.rows) {
				const rows = [];
				params.data.rows.forEach(row => {
					row.actions = [SimpleAction('DELETE', '', 'Löschen')];
					row.changed = false;
					row.isin_id = row.isin + '_' + row.id;
					rows.push(row);
				})
				this.rows = rows;
			}
		},
	},
	methods:{
    onChangeActive(isin, checked) {
      let row = this.rows.find( r => r.isin === isin);
      if (row){
        row.active = checked;
				row.changed = true;
				this.modified = true;
      }
    },
    changeStatus(checked) {
      this.rows.forEach(row => {
        this.onChangeActive(row.isin, checked);
      });
    },
    delRow(row) {
      const index = this.rows.findIndex(f => f.isin === row.isin)
      if (index !== -1) {
				if (this.rows[index].delete) {
					this.rows[index].isDeleted = true;
					this.rows[index].changed = true;
					this.modified = true;
				} else {
					this.rows.splice(index, 1);
				}
      }
			this.rows = [].concat(this.rows);
      if (this.$refs.wp_filter?.$data?.selected) {
        const rows = this.$refs.wp_filter?.$data?.selected.filter(f => f.isin !== row.isin);
        this.$refs.wp_filter.onSelect(rows);
      }
    },
    gotoFondsinfo(row) {
      const path = `/shared/vermogensubersicht/fondsinfo/${row.isin}`;
      this.$router.push({ path });
    },
    selectedFunds(selected) {
    	if (!selected?.length ) {
				return;
			}
			const rows = [].concat(this.rows);
			selected.forEach(sel => {
      	const idx = rows.findIndex(r => r.isin === sel.isin)
				if (idx != -1){
					if (rows[idx].isDeleted) {
						rows[idx].isDeleted = false;
						row.changed = true;
						this.modified = true;
					}
					if (!rows[idx].active) {
						rows[idx].active = true;
						row.changed = true;
						this.modified = true;
					}
				} else {
					rows.push({
						active: true,
						Frs: sel.sri_zielmarkt,
						Kategoriee: sel.schwerpunkt,
						actions: [SimpleAction('DELETE', '', 'Löschen')],
						isin: sel.isin,
						name: sel.fondsname,
						changed: true,
						addpos: true,
						isin_id: sel.isin,
					})
					this.modified = true;
				}
			})
			this.rows = rows;
		},
		save(makePersist, source) {
			if (this.loading) {
				return Promise.resolve();
			}
			this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.RESET_POSITIONS_OUTPUT)
			return this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SAVE_PARAMETERS, { 
				adviceId: this.id,
				parameters: {
					step: this.step,
					quelle: this.stepAllocation[this.currentAsset].quelle,
					rows: this.rows        
				}
			});
		},
	}
}
</script>