var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row mt-0" }, [
        _c("div", { staticClass: "col" }, [
          _vm.title
            ? _c(
                "div",
                {
                  staticClass:
                    "input-forms__label-content input-forms__label-content--bigger"
                },
                [
                  _c("ph-bank", { attrs: { size: 16 } }),
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]),
    _vm.config.weitereDatenFields
      ? _c(
          "div",
          [
            _c("span", [_vm._v(_vm._s(_vm.weitereDatenGruppenIndex))]),
            _vm._l(_vm.weitereDatenGruppenIndex, function(gruppenIndex) {
              return _c(
                "div",
                [
                  _vm.config &&
                  _vm.config.buttonText &&
                  _vm.componentType === "AUSWAHL_WEITERE_DATEN"
                    ? _c(
                        "div",
                        { staticClass: "col-auto" },
                        [
                          _c(
                            "BaseButton",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.deleteWeitereDatenGruppe(
                                    gruppenIndex
                                  )
                                }
                              }
                            },
                            [_vm._v("Schließen")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.config.weitereDatenFields, function(
                    component,
                    index
                  ) {
                    return _c("div", { key: index }, [
                      !_vm.isComponentHidden(component, gruppenIndex)
                        ? _c(
                            "div",
                            {
                              key: _vm.refreshKey,
                              staticClass: "mt-2",
                              class: {
                                indented:
                                  component.config && component.config.indented
                              }
                            },
                            [
                              _c("AntragComponent", {
                                key: component.id + "-" + gruppenIndex,
                                attrs: {
                                  component: component,
                                  antragId: _vm.antragId,
                                  data:
                                    _vm.data[
                                      _vm.componentId + "-" + gruppenIndex
                                    ],
                                  comboboxSelection: _vm.getComboboxSelection(
                                    component
                                  ),
                                  isComponentHalfSizeEnabled: "",
                                  disabled: _vm.disabled
                                },
                                on: {
                                  updateStore: function($event) {
                                    return _vm.updateData(
                                      $event.data,
                                      component,
                                      gruppenIndex
                                    )
                                  },
                                  change: function($event) {
                                    return _vm.$emit(
                                      "change",
                                      _vm.data[
                                        _vm.componentId + "-" + gruppenIndex
                                      ]
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  })
                ],
                2
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm.config &&
    _vm.config.buttonText &&
    _vm.componentType === "AUSWAHL_WEITERE_DATEN" &&
    _vm.weitereDatenGruppenIndex < _vm.maxAnzahl
      ? _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c(
              "BaseButton",
              {
                on: {
                  click: function($event) {
                    return _vm.openNewWeitereDatenGruppe()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.config.buttonText))]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }