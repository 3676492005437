<template>
  <div class="page-header-title-nav__container" v-sticky="isSticky"
    :class="{
      'is-title-wrapped':  isTitleWrapped,
      'hide-primary-action': hidePrimaryAction,
      'is-secondary': isSecondary,
    }"
  >
    <div class="page-header-title-nav__body">
      <PageHeaderTitleNavigationTitle v-bind="$props" v-on="$listeners">
        <template v-if="$slots.title || title" #title><slot name="title" /></template>
        <template v-if="$slots.subtitle || subtitle" #subtitle><slot name="subtitle" /></template>
      </PageHeaderTitleNavigationTitle>

      <PageHeaderTitleNavigationTitle v-bind="$props" v-on="$listeners" isWrapped>
        <template v-if="$slots.title || title" #title><slot name="title" /></template>
        <template v-if="$slots.subtitle || subtitle" #subtitle><slot name="subtitle" /></template>
      </PageHeaderTitleNavigationTitle>

      <div class="page-header-title-nav__action-container no-line-height">
        <div v-if="hasNavigationValues && !showNavigationInActionsContext" class="page-header-title-nav__navigation-container">
          <BaseButtonsContainer class="page-header-title-nav__buttons-container" primaryClass=".nav-item-bold" btnAsLink :minItemsRequired="1">
            <PageHeaderTitleNavigationNavItem 
              v-for="(navItem, index) of navigationValues" 
              :key="navItem.path + '-' + index" 
              :navItem="navItem"
              class="page-header-title-nav--item" 
            />
          </BaseButtonsContainer>
        </div>

        <div 
          v-if="showNavigationInActionsContext || hasActions" 
          class="page-header-title-nav__action-button-container" 
          :class="{ 
            'no-primary-action': noPrimaryAction, 
          }"
        >
          <div v-if="hasPrimaryAction" class="page-header-title-nav__primary-action">
            <component
              v-if="primaryAction.component"
              isMainAction
              v-bind="primaryAction"
              :is="primaryAction.component"
              :tid="_generateTidFromString(id + '-' + primaryAction.key)"
              @action="handlePageHeaderAction">
            </component>
          </div>

          <BaseContextMenu 
            v-show="showNavigationInActionsContext || hasRemainActions || showPrimaryActionInActionsContext" 
            :key="actionsKey" 
            class="page-header-title-nav__context-menu page-header-title-nav__remain-actions"
          >
            <ContextMenuGroup v-if="showNavigationInActionsContext" title="Menü">
              <ContextMenuItem v-for="(navItem, index) of navigationValues" :key="navItem.path + '-' + index" tabindex="-1">
                <PageHeaderTitleNavigationNavItem :navItem="navItem" />
              </ContextMenuItem>
            </ContextMenuGroup>

            <ContextMenuGroup 
              v-if="hasRemainActions || showPrimaryActionInActionsContext" 
              :title="(showNavigationInActionsContext ? 'Aktionen' : '')"
              class="page-header-title-nav__context-menu--actions"
            >
              <template v-for="action of visibleActions">
                <component 
                  v-if="action.component && (showPrimaryActionInActionsContext || action !== primaryAction)" 
                  :key="action.key" 
                  :is="(action.asGroup ? 'ContextMenuGroup' : 'ContextMenuItem')" 
                  tabindex="-1"
                >
                  <component
                    v-bind="action"
                    :is="action.component"
                    :tid="_generateTidFromString(id + '-' + action.key)"
                    @action="handlePageHeaderAction"
                  >
                    <template :slot="`action-${action.actionKey}`"><slot :name="`action-${action.actionKey}`" /></template>
                  </component>
                </component>
              </template>
            </ContextMenuGroup>
          </BaseContextMenu>
        </div>
      </div>
    </div>

    <hr class="baseline" />
  </div>
</template>

<script>
import LOG_TYPES from '@/store/log/types';

import * as phosphor from 'phosphor-vue';
import { PhCaretDown, } from 'phosphor-vue';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue'
import BaseButtonsContainer from '@/components/core/BaseButtonsContainer.vue'
import PageHeaderTitleNavigationTitle from './PageHeaderTitleNavigationTitle.vue';
import PageHeaderTitleNavigationNavItem from './PageHeaderTitleNavigationNavItem.vue';
import ContextMenuGroup from '@/components/core/base-context-menu/ContextMenuGroup.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import CORE_TYPES from '@/store/core/types'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

import { navigateResettingBreadcrumb, } from '@/router/breadcrumb/index';
import { isRouteActive } from '@/router';
import { parseBoolean } from '@/helpers/utils-helper';

const NAV_BODY_SELECTOR = '.page-header-title-nav__body';

const TITLE_NO_WRAPPED_CONTAINER_SELECTOR = `.page-header-title-nav__title-container.no-wrapped`;
const TITLE_IS_WRAPPED_CONTAINER_SELECTOR = `.page-header-title-nav__title-container.is-wrapped`;

const ACTION_CONTAINER_SELECTOR = '.page-header-title-nav__action-container';
const NAVIGATION_MORE_BUTTON_SELECTOR = '.page-header-title-nav__navigation-container .base-buttons--dropdown';
const ACTION_BUTTON_CONTAINER_SELECTOR = '.page-header-title-nav__action-button-container';
const PRIMARY_ACTION_SELECTOR = '.page-header-title-nav__primary-action';
const CONTEXT_MENU_SELECTOR = '.page-header-title-nav__context-menu';

const MEDIA_QUERY_HIDE_PRIMARY_ACTION = 'screen and (max-width: 480px)';

function marginHorizontal(el) {
  if (!el) return 0;
  const computedStyle = window.getComputedStyle(el);
  return parseFloat(computedStyle?.['marginLeft'] || 0) + parseFloat(computedStyle?.['marginRight'] || 0);
}

function paddingHorizontal(el) {
  if (!el) return 0;
  const computedStyle = window.getComputedStyle(el);
  return parseFloat(computedStyle?.['paddingLeft'] || 0) + parseFloat(computedStyle?.['paddingRight'] || 0);
}

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    id: {
      type: String,
      default: '',
    },
    defaultMenu: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: () => [],
    },
    /**
     * Moves the primary action button to the remain actions
     */
    noPrimaryAction: {
      type: Boolean,
      default: false,
    },
    /**
     * adds only a primary action if it is marked with `.withAsPrimary` and returns true
     */
    asPrimaryOnly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Title'
    },
    subtitle: {
      type: String,
      default: ''
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: [],
  data() {
    return {
      loading: false,
      isTitleWrapped: false,
      hidePrimaryAction: false,
    };
  },
  computed: {
    navigationValues() {
      return this.defaultMenu
        ?.map((item) => this.toNavigationValues(item))
        ?.filter(item => !!item?.label); // only show items with label
    },
    hasNavigationValues() {
      return !!this.navigationValues?.length;
    },
    showNavigationInActionsContext() {
      return this.hasNavigationValues && this.$isSmallScreen;
    },
    visibleActions() {
      return this.actions?.filter(act => !act.visible || act.visible?.())
    },
    hasActions() {
      return !!this.visibleActions?.length;
    },
    primaryAction() {
      if(this.noPrimaryAction) return undefined;

      const { visibleActions = [], asPrimaryOnly = false, } = this;

      // find action marked as primary
      const primaryAction = visibleActions.find(action => action.asPrimaryFn?.());

      // find first action
      const [firstElem] = (asPrimaryOnly ? [] : visibleActions);

      // marked as primary has priority over first action
      return primaryAction || firstElem;
    },
    hasPrimaryAction() {
      return !!this.primaryAction && !this.noPrimaryAction;
    },
    showPrimaryActionInActionsContext() {
      return this.hasActions && (!this.hasPrimaryAction || this.hidePrimaryAction);
    },
    hasRemainActions() {
      const minLength = (this.hasPrimaryAction ? 1 : 0);
      return this.visibleActions?.length > minLength;
    },
    actionsKey() {
      return this.actions
        .map(act => [
          !act.visible || act.visible?.(), 
          !act.disabled || act.disabled?.(), 
          !act.asPrimaryFn || act.asPrimaryFn?.(), 
        ].join('-'))
        .join(':');
    },
  },
  updated() {
    this.$nextTick(() => requestAnimationFrame(this.prepareContent));
  },
  methods: {
    onResize() {
      requestAnimationFrame(this.prepareContent);
    },
    // be careful updating state variables here
    // it can lead to infinite calls due to it is being called from the 'updated' hook
    prepareContent() {
      const rootEl = this.$el;
      if(!rootEl) return;

      const { 
        hasPrimaryAction, 
        showNavigationInActionsContext, 
        hasRemainActions,
      } = this;
      const isContextMenuVisibleWithoutPrimaryAction = showNavigationInActionsContext || hasRemainActions;
      const shouldHidePrimaryAction = matchMedia(MEDIA_QUERY_HIDE_PRIMARY_ACTION)?.matches;

      // find elements
      const navBodyEl = rootEl.querySelector(NAV_BODY_SELECTOR);
      const primaryActionEl = rootEl.querySelector(PRIMARY_ACTION_SELECTOR);

      const calcActionContainerMinWidth = () => {
        const actionContainerEl = rootEl.querySelector(ACTION_CONTAINER_SELECTOR);
        const navigationMoreButtonEl = rootEl.querySelector(NAVIGATION_MORE_BUTTON_SELECTOR);
        const actionButtonContainerEl = rootEl.querySelector(ACTION_BUTTON_CONTAINER_SELECTOR);
        const contextMenuEl = rootEl.querySelector(CONTEXT_MENU_SELECTOR);

        const actionContainerSize = marginHorizontal(actionContainerEl);
        const navigationMoreButtonSize = (navigationMoreButtonEl?.clientWidth || 0) + marginHorizontal(navigationMoreButtonEl);
        const actionButtonContainerSize = marginHorizontal(actionButtonContainerEl);
        const primaryActionSize = (primaryActionEl?.clientWidth || 0) + marginHorizontal(primaryActionEl);
        const contextMenuSize = isContextMenuVisibleWithoutPrimaryAction ? (contextMenuEl?.clientWidth || 0) + marginHorizontal(contextMenuEl) : 0;

        return actionContainerSize + navigationMoreButtonSize + actionButtonContainerSize + primaryActionSize + contextMenuSize;
      };

      // set common sizes
      const navBodySize = navBodyEl.clientWidth - paddingHorizontal(navBodyEl);
      const actionContainerMinWidth = calcActionContainerMinWidth();

      const isTitleWrappedCheck = () => {
        const titleNoWrappedContainerEl = rootEl.querySelector(TITLE_NO_WRAPPED_CONTAINER_SELECTOR);
        const titleContainerSize = titleNoWrappedContainerEl.clientWidth;
        return !(navBodySize > (titleContainerSize + actionContainerMinWidth));
      };

      const hidePrimaryActionCheck = () => {
        if (!hasPrimaryAction || !shouldHidePrimaryAction) return false;

        const titleIsWrappedContainerEl = rootEl.querySelector(TITLE_IS_WRAPPED_CONTAINER_SELECTOR);
        const titleContainerSize = titleIsWrappedContainerEl.clientWidth;
        return !(navBodySize > (titleContainerSize + actionContainerMinWidth));
      }

      rootEl.style.setProperty('--ph--actionContainer-min-width', `${actionContainerMinWidth}px`);
      const isTitleWrapped = isTitleWrappedCheck();
      const hidePrimaryAction = hidePrimaryActionCheck();

      if (primaryActionEl) {
        // makes primary action visible if it fits - it prevents blink when calculating
        primaryActionEl.style.opacity = hidePrimaryAction ? 0 : 1;
      }

      this.isTitleWrapped = isTitleWrapped;
      this.hidePrimaryAction = hidePrimaryAction;
    },
    defaultNavigationActions(item) {
      if (item.disabled) {
        return;
      }
      if(this.$route.path === item.path) return;

      const found = this.defaultMenu?.find(d => d.id == item.id && d.path === item.path)

      if (this.isSiblingOptions && found) {
        this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE, { trackUrl: item.path })
      }

      navigateResettingBreadcrumb(item.path, { ignoreReset: true });
    },
    toNavigationValues(item) {
      return {
        label: item.label,
        disabled: item.disabled,
        component: item.component,
        componentProps: item.componentProps,
        action: item.action && !item.disabled ? item.action : () => this.defaultNavigationActions(item),
        hasTestUserRole: item.hasTestUserRole,
        textBold: isRouteActive(item.path) || this.isTextBold(item),
        path: item.path,
        iconComponent: typeof item.icon === 'string' ? phosphor[item.icon] : item.icon,
      };
    },
    isTextBold(item) {
      if(typeof item.textBold === 'function') {
        return item.textBold();
      } else {
        return item.textBold;
      }
    },
    handleNavigationAction(item) {
      if (item.action && !item.disabled) {
        item.action();
      }
    },
    handlePageHeaderAction({ key, value, }) {
      this.$emit('action', { key, value, })
      this.$emit(`action-${key}`, value)
    },
  },
  mounted() {
    requestAnimationFrame(() => this.prepareContent());
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  components: {
    BaseContextMenu,
    PhCaretDown,
    BaseButtonsContainer,
    PageHeaderTitleNavigationTitle,
    PageHeaderTitleNavigationNavItem,
    ContextMenuGroup,
    ContextMenuItem,
  }
}

</script>

<style scoped>
.page-header-title-nav__container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

/deep/ .page-header-title-nav__container,
/deep/ .page-header-title-nav__container * {
  font-size: 1em;
  line-height: 1.2;
}

.stuck {
  pointer-events: none;
}

.stuck .page-header-title-nav__body {
  background: var(--color-box);
  box-shadow: 0px 4px 16px rgba(85, 85, 85, 0.1);
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  pointer-events: all;
}

.stuck .baseline {
  display: none;
}

.stuck .page-header-title-nav__body:before,
.stuck .page-header-title-nav__body:after {
  background: var(--color-box);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 15px;
  height: 100%;
}

.stuck .page-header-title-nav__body:before {
  box-shadow: -10px 4px 16px rgba(85, 85, 85, 0.1);
  left: -15px;
}

.stuck .page-header-title-nav__body:after {
  box-shadow: 10px 4px 16px rgba(85, 85, 85, 0.1);
  right: -15px;
}

.page-header-title-nav__primary-action {
  opacity: 0; /** starts hiding it to prevent blink */
}

.hide-primary-action .page-header-title-nav__primary-action {
  opacity: 0;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

@media (min-width: 640px) {
  .stuck .page-header-title-nav__body:before,
  .stuck .page-header-title-nav__body:after {
    width: 30px;
  }

  .stuck .page-header-title-nav__body:before {
    left: -30px;
  }

  .stuck .page-header-title-nav__body:after {
    right: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .stuck {
    padding-top: var(--base-header-bar-height);
  }
}

@media (min-width: 960px) {
  .stuck .page-header-title-nav__body:before,
  .stuck .page-header-title-nav__body:after {
    width: 40px;
  }

  .stuck .page-header-title-nav__body:before {
    left: -40px;
  }

  .stuck .page-header-title-nav__body:after {
    right: -40px;
  }
}

.page-header-title-nav__body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 8px 8px;
  box-sizing: border-box;
}

.is-secondary .page-header-title-nav__body {
  padding: 0;
}

.page-header-title-nav__action-container {
  display: flex;
  flex: 0 1 auto;
  margin: 0 0 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-header-title-nav__navigation-container {
  display: flex;
  flex: 1;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/deep/ .page-header-title-nav__buttons-container .base-buttons--content {
  flex-wrap: nowrap;
  margin: 0;
}
/deep/ .page-header-title-nav__buttons-container .base-buttons--content .base-buttons--main > * {
  font-size: 1em;
  line-height: 1.5;
  margin: 0 8px;
}
/deep/ .page-header-title-nav__buttons-container .base-buttons--content .base-buttons--dropdown {
  font-size: 1em;
  margin: 0 0 0 8px;
}

.page-header-title-nav__action-button-container {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  margin: 0;
  padding: 2px;
}

.page-header-title-nav__navigation-container + .page-header-title-nav__action-button-container {
  margin-left: 8px;
}

.page-header-title-nav__action-button-container.no-primary-action {
  margin: 0;
}

/deep/ .page-header-title-nav__action-button-container > *,
/deep/ .page-header-title-nav__action-button-container button {
  margin: 0;
}

.page-header-title-nav--item {
  display: flex;
  align-items: center;
  position: relative;
}
.base-buttons--main .page-header-title-nav--item::after {
  background: #000;
  content: "";
  display: block;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  right: -9px;
  width: 2px;
  height: clamp(14px, 1.125em, 24px);
}
.base-buttons--main .page-header-title-nav--item:last-child::after {
  display: none;
}
.has-dropdown .page-header-title-nav--item:last-child::after {
  display: block;
}

.page-header-title-nav--item.nav-item-bold {
  text-shadow: 0 0 1px var(--color-text);
}

.page-header-title-nav--item .nav-item--icon {
  display: flex;
  margin: 2px 4px 0 0;
}

.page-header-title-nav__context-menu {
  animation: page-header-actions-animation 0.8s both;
  margin: 0 0 0 6px;
}

.page-header-title-nav__context-menu--title {
  color: #999;
  font-size: 0.8rem;
  margin: 4px 0;
}

/deep/ .page-header-title-nav__context-menu .base-content-menu--btn {
  display: flex;
  align-items: center;
}

.page-header-actions-animation-leave-active {
  display: none !important;
}

@keyframes page-header-actions-animation {
  0%,
  100% {
    transform: rotate(0) scale(1);
    transform-origin: center center;
  }
  15% {
    transform: rotate(8deg) scale(1.2);
  }
  30% {
    transform: rotate(-8deg) scale(1.2);
  }
  45% {
    transform: rotate(6deg) scale(1.2);
  }
  60% {
    transform: rotate(-6deg) scale(1.2);
  }
  75% {
    transform: rotate(0);
  }
}

.baseline {
  margin-top: 0px;
}

.is-secondary .baseline {
  border: none;
}

@media screen and (max-width: 768px) {
  .page-header-title-nav__navigation-container {
    display: none;
  }

  .page-header-title-nav__action-container {
    flex: 0 0 auto;
  }

  .page-header-title-nav__navigation-container {
    flex: 1 0 auto;
  }
}

@media screen and (max-width: 540px) {
  .page-header-title-nav__action-container {
    justify-content: flex-end;
  }

  .page-header-title-nav__navigation-container {
    flex: 0 0 auto;
  }
}

@media screen and (max-width: 480px) {
  .page-header-title-nav__action-button-container {
    margin: 0;
  }
}
</style>
