<template>

  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Vermittlerwechsel Status Übersicht" />

    <BaseFilter 
      filterId="37451699-a251-4bfa-87ef-214acc7c37a6"
      title="Vermittlerwechsel Status filtern" 
      :metadata="filterMetadata" 
      :configFilter="configFilter"
      :defaultOptions="defaultOptionsData"
      v-if="gesellschaften && gesellschaften.length"
      @onFilter="filterForm($event)">
    </BaseFilter>
    <GhostLoading v-else-if="!gesellschaften || !gesellschaften.length" type="custom" />

    <div class="box__container" tid="8d199f94-18f2-445e-b934-515fba3ccf84">

      <Table
        tableId="95b30d63-2bd2-4d05-bca1-a9ffab9d9828"
        class="table-container"
        v-if="!loading && tableRows.length"
        :title="TABLE_TITLE"
        :headers="tableHeaders"
        :rows="tableRows"
        :rowsPerPage="20"
        :count="resultCount"
        @action="executeAction($event)"
      >

        <template v-slot:status="row">
            <ComboBox
                :value="row.status" 
                :values="statuses"
                @change="updateStatus(row, $event)">
            </ComboBox>
        </template>      

        <template v-slot:depotnummer="row">
            <a :href="getPDFLink(row)">{{row.depotnummer}}</a>
        </template>          
        
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>    

    <BaseModal @onConfirmButton="removeItem()" ref="removeItemConfirmation" modalTitle="Eintrag löschen" labelButtonConfirm="Löschen">
      {{deleteMessage}}
    </BaseModal>


  </div>
    
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import VERMITTLERWECHSEL_STATUS_TYPES from "../../store/vermittlerwechselstatus/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, IconColumn, Icon, SlotColumn} from "@/components/table2/table_util.js";
import {PhPencil, PhTrash} from "phosphor-vue";
import BaseModal from '@/components/core/BaseModal.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";
import { VIEW_ROLES } from '@/router/roles';
import ComboBox from "@/components/core/forms/ComboBox.vue";
import { makeQueryParam } from '@/helpers/utils-helper';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

const DATE_FORMAT = 'DD.MM.YYYY';
const TABLE_TITLE = 'Vermittlerwechsel Status';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    GhostLoading,
    NoData,
    BaseModal,
    BaseFilter,
    ComboBox,
  },
  data: function () {
      return {
        TABLE_TITLE,
        resultCount: null,
        loading: true,
        configFilter: {
          noResetOnDefaultSearchInputExit: true,
          defaultFiltersNum: 2,
        },        
      }
  },
  computed: {
    ...mapGetters({
      vermittlerwechselStatusData: VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.VERMITTLERWECHSEL_STATUS,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
      selectedEintrag:  VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.SELECTED_EINTRAG,
      updateStatusResult:  VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.UPDATE_STATUS_RESULT,
    }),
    gesellschaften() {
      return this.vermittlerwechselStatusData?.gesellList?.map(s => {return {
        key: s.key,
        value: s.key,
        label: s.value,
        type: "default"
      }}) || [];      
    },
    statuses() {
      return this.vermittlerwechselStatusData?.statuses.map(s => {return {
        key: s.value,
        value: s.value,
        label: s.key,
        type: "default"
      }}) || [];
    },
    canSeeSpecialColumns() {
      return this.hasRoles([VIEW_ROLES.VIEW_INTERN, VIEW_ROLES.VIEW_BROKER_AS_INTERN]) ;
    },
    tableHeaders() {
      if (this.canSeeSpecialColumns) {
        return {
          lockedLeft: [
          SlotColumn("depotnummer", "Depotnummer"),
          SlotColumn("status", "Status").makeAlwaysVisible(),
          IconColumn("statusIcon", ""),
          TextColumn("kundenname", "Kundenname"),
          TextColumn("vorname", "-vorname"),
          TextColumn("nr", "-nr").makeAlwaysVisible(),
          TextColumn("gesellschaft", "Gesellschaft").makeAlwaysVisible(),
          TextColumn("bemerkung", "Bemerkung an Vermittler"),
          TextColumn("bemerkungIntern", "Bemerkung intern"),
          TextColumn("faxMail", "Fax/Mail"),
          TextColumn("datum", "Datum"),
          TextColumn("vermittler", "Vermittler"),
          ],
          lockedRight: [
              ActionColumn("actions", "Aktionen"),
          ],   
        };

      }

      return {
        lockedLeft: [
          SlotColumn("depotnummer", "Depotnummer"),
          TextColumn("status", "Status").makeAlwaysVisible(),
          IconColumn("statusIcon", ""),
          TextColumn("gesellschaft", "Gesellschaft").makeAlwaysVisible(),
          TextColumn("kundenname", "kundenname"),
          TextColumn("vorname", "-vorname"),
          TextColumn("nr", "-nr"),
          TextColumn("bemerkung", "Bemerkung"),
          TextColumn("datum", "Datum"),
        ],
        lockedRight: [],   
      };      
    },       
    tableRows () {
      if (!this.vermittlerwechselStatusData?.rows?.length ) {
        return [];
      }

      const fullActions = [
          SimpleAction("EDIT_ITEM", '', "Bearbeiten"),
          SimpleAction("REMOVE_ITEM", '', "Löschen"),
      ];


      return this.vermittlerwechselStatusData?.rows.map(data => ({
        status: data.status,
        statusIcon: [Icon(data?.jobStatusMedatadata?.find(i => i.key =='Icon')?.value, "", 16, "fill", data?.jobStatusMedatadata?.find(i => i.key =='Type')?.value)],
        kundenname: data.kundenname,
        vorname: data.vorname,
        nr: data.nr,
        gesellschaft: data.gesellschaft,
        depotnummer: data.depotnummer,
        bemerkung: data.bemerkung,
        bemerkungIntern: data.bemerkungIntern,
        faxMail: data.faxMail,
        datum: data.datum,
        vermittler: data.vermittler,
        id: data.id,
        actions: this.canSeeSpecialColumns ? fullActions : [],
        pdfMetadata: data.pdfMetadata,
        editActionMetadata: data.editActionMetadata,
        deleteActionMetadata: data.deleteActionMetadata,
      }));




    },
    defaultOptionsData() {
      return {
          letzten3Monaten: {
              value: true,
          },
          VALUE_ALLE: {
              value: true,
          },   
          WAITING_FOR_SEND: {
              value: true,
          },
          WAITING_FOR_CONFIRMATION_1: {
              value: true,
          },               
        }; 
    }, 
    filterMetadata() {
         return [
            {
              type: "group",
              key: "allgemein",
              label: "Allgemein",
              menuItems: [
                {
                  type: 'text',
                  key: 'kundennr',
                  label: 'Kunde',
                  inputmode: 'numeric',
                },                
                {
                  type: 'text',
                  key: 'vermittlernummer',
                  label: 'Vermittler',
                  inputmode: 'numeric',
                },
                {
                  type: "text",
                  key: "depotnummer",
                  label: "Depotnummer",
                }, 
                {
                  type: "default",
                  label: "unbekannte Depotnummer",
                  key: "mitDepotnrUnbekannt",
                },                                
              ],
            },   
            {
              type: "group",
              key: "abDatum",
              label: "Ab Datum",
              menuItems: [
                {
                  type: "default",
                  label: "letzte 3 Monate",
                  key: "letzten3Monaten",
                },
                {
                  type: "dateRange",
                  label: "Zeitraum",
                  key: "zeitraum",
                },

              ],
            },              
            {
              type: 'group',
              key: 'gesellschaften',
              label: 'Gesellschaften',
              menuItems: this.gesellschaften || []
            },     
            {
              type: "group",
              key: "mitStatus",
              label: "Zeige Einträge mit Status",
              menuItems: [...this.statuses, {label: 'nur Versandfehler', key: "nurVersandfehler", type: "default"}] || [],
            },                       
          ];  
    },
    deleteMessage() {
        return this.selectedEintrag?.deleteActionMetadata?.find(m => m.key=='Message')?.value;
    }
    
  },
  mounted() {
    this.retrieveData();  
  },
  methods: {
    executeAction(actionData) {
      switch (actionData.key) {
        case 'EDIT_ITEM':
          this.bearbeiten(actionData.row);
          break;
        case 'REMOVE_ITEM':
          this.confirmItemRemoval(actionData.row);
          break;     
      }
    },   
    confirmItemRemoval(row) {
      this.$store.commit(VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.SELECTED_EINTRAG, row);
      this.$refs.removeItemConfirmation.open();
    },
    removeItem() {
      this.$store.dispatch(VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.REMOVE_ROW, {id: this.selectedEintrag?.id}).then((response) => {
        this.retrieveData();  
      }); 
    },
    retrieveData(payload) {
      this.loading = true;

      const defaultPayload = {
        filterStatusPrefix: {
          "FILTER_STATUS_WAITING_FOR_SEND": true,
          "FILTER_STATUS_WAITING_FOR_CONFIRMATION_1": true,
        },
        filterVon: dayjs().subtract(3, "month").format("DD.MM.YYYY"),
        filterBis:  dayjs().format("DD.MM.YYYY"),
      };

      this.$store.dispatch(
              VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.RETRIEVE_VERMITTLERWECHSEL_STATUS, payload || defaultPayload
            ).then(() => {
                this.loading = false;
            });  

    },
    bearbeiten(row) {
        this.$store.commit(VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.SELECTED_EINTRAG, row);
        this.$store.commit(VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.STORE_GESELLSCHAFTEN, this.gesellschaften);
        this.$router.push({path: `/customer/vermittlerwechsel/eintragBearbeiten` });
    },
    filterForm(filterConfig) {
      const allgemein = filterConfig.filter(fc => fc.group=="allgemein");
      const gesellschaften = filterConfig.find(fc => fc.group=="gesellschaften");
      const mitStatus = filterConfig.filter(fc => fc.group=="mitStatus");
      const abDatum = filterConfig.filter(fc => fc.group=="abDatum");

      let filterKunde = null;
      let filterMakler = null;
      let filterGesell = null;
      let mitDepotnrUnbekannt = null;
      let filterDepotnr = null;
      let filterVon = null;
      let filterBis = null;
      let filterVersandFehler = null;
      let filterStatusPrefix = {};

      if (allgemein) {
        filterKunde = allgemein.find(fc => fc.key=="kundennr")?.value;
        filterMakler = allgemein.find(fc => fc.key=="vermittlernummer")?.value;
        filterDepotnr = allgemein.find(fc => fc.key=="depotnummer")?.value;
        mitDepotnrUnbekannt = allgemein.find(fc => fc.key=="mitDepotnrUnbekannt")?.value;

        let zeitraum = abDatum.find(fc => fc.key=="zeitraum");

        if (zeitraum?.value) {
          const parsedFilterVon = dayjs(zeitraum.value.find(v => v.key == 'min').value, DATE_FORMAT).toDate();
          const parsedFilterBis = dayjs(zeitraum.value.find(v => v.key == 'max').value, DATE_FORMAT).toDate();

          filterVon = dayjs(parsedFilterVon).format(DATE_FORMAT);
          filterBis = dayjs(parsedFilterBis).format(DATE_FORMAT);
        }

        let letzten3Monaten = abDatum.find(fc => fc.key=="letzten3Monaten");

        if (letzten3Monaten?.value) {
          filterVon = dayjs().subtract(3, "month").format("DD.MM.YYYY");
          filterBis = dayjs().format("DD.MM.YYYY");
        }

      }

      if (gesellschaften) {
        filterGesell = gesellschaften?.key;
      }

      if (mitStatus) {
        filterVersandFehler = mitStatus.find(fc => fc.key=="nurVersandfehler")?.value;

        const mitStatusAllgemein = mitStatus.filter(fc => fc.key!=="nurVersandfehler");
        
        if (mitStatusAllgemein) {
              const filterPrefix = "FILTER_STATUS_";
              mitStatusAllgemein.forEach(s => {
                filterStatusPrefix[`${filterPrefix}${s?.key}`] = true;
              });
        }


      }      

      this.retrieveData(
        {
          filterKunde, 
          filterMakler, 
          filterGesell, 
          filterDepotnr, 
          filterVon, 
          filterBis,
          mitDepotnrUnbekannt,
          filterVersandFehler,
          filterStatusPrefix
          }
        ) ;
    },
    getPDFLink(row) {

      const aesId = row?.pdfMetadata?.find(p => p.key == 'PARAMETER_AES_ID')?.value;
      const antragId = row?.pdfMetadata?.find(p => p.key == 'PARAMETER_ANTRAG_ID')?.value;

      const apiAddress = this.apiAddress
      const token = this.token

      const params = makeQueryParam({ aesId, antragId, token })

      const fileName = 'Vermittlerwechsel.pdf';
      
      return `${apiAddress}/download_service/getVermittlerwechselStatusPDF/${fileName}?${params}`;
    },
    updateStatus(row, newValue) {
      this.loading = true;

      const payload = {
        aesId: row?.pdfMetadata?.find(p => p.key == 'PARAMETER_AES_ID')?.value,
        status: newValue,
        filterDepotnr: row.depotnummer,
        bemerkung: row.bemerkung,
        bemerkungIntern: row.bemerkungIntern,

      };

      this.$store.dispatch(
              VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.UPDATE_STATUS, payload
            ).then((response) => {

              if (!response.length) {
                this.retrieveData();
              }
              this.loading = false;
            });       
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Vermittlerwechsel Status Übersicht', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    this.$store.commit(VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.RESET_LIST);

    next();
  },


    
}
</script>