<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
        <PageHeaderTitleNavigation :title="title" :id="$appNavigation.currentOptionMenuId" />
        <div class="flex-container mb-4">
            <div class="flex-table-item mr-4">
                <GhostLoading v-if="loading" type="block" :config="{ block: { height: 24, }, }"
                    :repeat="Object.keys(labels).length" />
                <div v-else>
                    <dl class="key-value--item">
                        <dt class="value--item-label">Testergebnis:</dt>
                        <dd class="value--item-value">
                            <span v-if="!this.suitabilityResult">-</span>
                            <span class="color-success" v-else-if="this.suitabilityResult.gesamt_test">Positiv</span>
                            <span class="color-danger" v-else>Negativ</span>
                        </dd>
                    </dl>
                    <dl class="key-value--item" v-for="(label, key) of labels" v-bind:key="key">
                        <dt class="value--item-label">{{ label + ': ' }}</dt>
                        <dd class="value--item-value">{{ values[key] ? values[key] : '-' }}</dd>
                    </dl>
                </div>
            </div>
            <div class="flex-legidocs-item">
                <GhostLoading v-if="loading" type="block" :config="{ block: { height: 100, }, }" />
                <div v-else>
                    <LegiDocsView :signatureAuthor="signatureAuthor" :kunde="kunde"
                        :zusaetzlichePersonen="zusaetzlichePersonen" />
                </div>
            </div>
        </div>
        <PageHeaderTitleNavigation title="Risikoprüfung 1" :id="$appNavigation.currentOptionMenuId"
            :showBackButton="false" />
        <div class="flex-container mb-4">
            <div class="risikopruefung-column">
                <h4>Finanzielle Verhältnisse</h4>
                <GhostLoading v-if="loading" type="block" :config="{ block: { height: 24, }, }" :repeat="fv.length" />
                <dl v-else class="key-value--item" v-for="field in fv" v-bind:key="field.label">
                    <dt class="value--item-label">{{ field.label }}</dt>
                    <dd class="value--item-value" :class="field.cssClass">{{ field.value }}</dd>
                </dl>
            </div>
            <div class="risikopruefung-column">
                <h4>Anlageziele</h4>
                <GhostLoading v-if="loading" type="block" :config="{ block: { height: 24, }, }"
                    :repeat="anlageziele.length" />
                <dl v-else class="key-value--item" v-for="field in anlageziele" v-bind:key="field.label">
                    <dt class="value--item-label">{{ field.label }}</dt>
                    <dd class="value--item-value" :class="field.cssClass">{{ field.value }}</dd>
                </dl>
            </div>
            <div class="risikopruefung-column">
                <h4>Risikobereitschaft</h4>
                <GhostLoading v-if="loading" type="block" :config="{ block: { height: 24, }, }"
                    :repeat="risikobereitschaft.length" />
                <dl v-else class="key-value--item" v-for="field in risikobereitschaft" v-bind:key="field.label">
                    <dt class="value--item-label">{{ field.label }}</dt>
                    <dd class="value--item-value" :class="field.cssClass">{{ field.value }}</dd>
                </dl>
            </div>
            <div class="risikopruefung-column">
                <h4> Verlusttragfähigkeit</h4>
                <GhostLoading v-if="loading" type="block" :config="{ block: { height: 24, }, }"
                    :repeat="verlustfaehigkeit.length" />
                <dl v-else class="key-value--item" v-for="field in verlustfaehigkeit" v-bind:key="field.label">
                    <dt class="value--item-label">{{ field.label }}</dt>
                    <dd class="value--item-value" :class="field.cssClass">{{ field.value }}</dd>
                </dl>
            </div>
        </div>
        <SignaturenVergleichCards @rejected="onRejected" @signatureAuthor="onNewSignatureAuthor($event)"
            class="mb-4" :loading="loadingSignatures" />
        <div>
            <PageHeaderTitleNavigation title="Überprüfung und Annahme" :id="$appNavigation.currentOptionMenuId"
                :showBackButton="false" />
            <div class="flex-container mb-4">
                <InputRadioBoxGroup title="Prüfungsstatus" v-model="pruefungsErgebnis" :values="pruefungsstatusValues"
                    labelClass="font-bold" class="flex-radiobox-item" />

                <InputTextArea v-if="pruefungsErgebnis === 'ABGELEHNT'" v-model="suitabilityResult.bemerkungText"
                    label="Begründung des negativen Testergebnisses" autoGrow class="flex-textarea-item" />
            </div>
            <div class="button-container">
                <InputToggleSwitch v-model="suitabilityResult.pruefungCopyMappe" v-if="pruefungsErgebnis === 'ABGELEHNT'"
                    label="Beratungsmappe für Nachbearbeitung kopieren" inLineLabel validateUntouched 
                    @change="rebuildCommentText" />
            </div>
        </div>
        <div class="button-container">
            <BaseButton label="Überprüfung abschließen" @click="save" :disabled="!!submitDisabled"/>
            <BaseButton label="Weiter" isSecondary @click="goToNextMappe" />
        </div>
    </div>
</template>

<script>
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { mapGetters } from 'vuex';
import VV_TYPES from '@/store/vermoegensverwaltung/types';
import LOG_TYPES from '@/store/log/types';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import SignaturenVergleichCards from "./SignaturenVergleichCards.vue";
import { base64ToArrayBuffer } from "@/helpers/download-helper";
import LegiDocsView from './LegiDocsView.vue';
import { buildMessageWith } from '@/helpers/log-message-helper';

export const SIGNATURE_AUTHOR_TYPES = {
    'ALLE': { label: 'Alle', key: 'ALLE' },
    'DEPOTINHABER_1': { label: '1. Depotinhaber', key: 'DEPOTINHABER_1' },
    'DEPOTINHABER_2': { label: '2. Depotinhaber', key: 'DEPOTINHABER_2' },
    'MAKLER': { label: 'Vermittler', key: 'MAKLER' },
};

export default {
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        InputRadioBoxGroup,
        InputTextArea,
        InputToggleSwitch,
        GhostLoading,
        BaseButton,
        SignaturenVergleichCards,
        LegiDocsView,
    },
    data() {
        return {
            mappenID: '',
            pruefungsErgebnis: 'AUSSTEHEND',
            hasDataChanged: false,
            loading: false,
            loadingSignatures: false,
            submitDisabled: false,
            signatureAuthor: SIGNATURE_AUTHOR_TYPES.ALLE,
            pruefungsstatusValues: [
                { label: 'Angenommen', value: 'ANGENOMMEN' },
                { label: 'Abgelehnt', value: 'ABGELEHNT' },
                { label: 'Keine Angabe', value: 'AUSSTEHEND' }
            ],
            rejectedFileIds: [],
            fehler:[],
        }
    },
    computed: {
        ...mapGetters({
            suitabilityResult: VV_TYPES.GETTERS.SUITABILITYRESULT,
            suitabilityResultList: VV_TYPES.GETTERS.SUITABILITYRESULTLIST,
            suitabilitySignatures: VV_TYPES.GETTERS.SUITABILITYSIGNATURES,
        }),
        title() {
            return `Suitability Test | ${this.suitabilityResult?.beratungsmappe.bezeichnung || ''}`;
        },
        headerActions() {
            return [
                PageHeaderSimpleAction('CHECK_SIGNATURES', 'Signaturen überpüfen')
            ]
        },
        labels() {
            return {
                maklerName: 'Makler',
                mappenName: 'Beratungsmappe',
                kundenname: 'Kunde',
                adresse: 'Adresse',
                dateCreation: 'Erstellt am',
                pruefungsstatus: 'Prüfungsstatus',
                erklaerungenProtokoll: 'Beratungsdokumentation Freitext',
                risikoNeigung: 'Risikoneigung Freitext'
            };
        },
        values() {
            if (this.suitabilityResult) {
                const values = {
                    ...this.suitabilityResult,
                    mappenID: this.mappenID,
                    kundenname: `${this.suitabilityResult.kunde.personalDataAddress?.firstName} ${this.suitabilityResult.kunde.personalDataAddress?.lastName}`,
                    adresse: `${this.suitabilityResult.kunde.personalDataAddress?.street}, ${this.suitabilityResult.kunde.personalDataAddress?.zip} ${this.suitabilityResult.kunde.personalDataAddress?.city}`,
                    dateCreation: this.timestampToDate(this.suitabilityResult.dateCreation),
                    dateEinreichung: this.timestampToDate(this.suitabilityResult.dateEinreichung),
                    datePruefung: this.timestampToDate(this.suitabilityResult.datePruefung),
                    pruefungsstatus: this.suitabilityResult.pruefungsstatus,
                    mappenName: this.suitabilityResult.beratungsmappe.bezeichnung,
                }
                return values;
            } else {
                return {};
            }
        },
        verlustfaehigkeit() {
            return [
                this.getPruefungLabel(this.suitabilityResult?.verlust_test)
            ];
        },
        fv() {
            return [
                { label: "Summe Neuanlage", value: this.getAsCurrency(this.suitabilityResult?.neuanlage_einmalig) },
                { label: "Summe ratierliche Zahlungen", value: this.getAsCurrency(this.suitabilityResult?.anlage_monatlich) },
                { label: "Verfügbares Kapital", value: this.getAsCurrency(this.suitabilityResult?.verfuegbar_einmalig) },
                { label: "Verfügbares monatliches Einkommen", value: this.getAsCurrency(this.suitabilityResult?.verfuegbar_monatlich) },
                { label: "Zusätzliche Hinweise", value: this.suitabilityResult?.fin_zus_hinweise },
                this.getPruefungLabel(this.suitabilityResult?.fv_test)
            ];
        },
        anlageziele() {
            return [
                { label: "Einmalanlage erlaubt", value: this.getAsCurrency(this.suitabilityResult?.anlageziel_einmalig) },
                { label: "Ratierliche Zahlung erlaubt", value: this.getAsCurrency(this.suitabilityResult?.anlageziel_monatlich) },
                this.getPruefungLabel(this.suitabilityResult?.anlageziel_test)
            ];
        },
        risikobereitschaft() {
            return [
                { label: "Maximale Risikokennzahl (nach bisherigen Anlagen)", value: this.suitabilityResult?.risikokennzahl },
                { label: "Risikokennzahl (nach Nutzerangaben)", value: this.suitabilityResult?.risikokennzahl_kundenangabe },
                { label: "Maximale Gesamtrisikokennzahl (GSRI)", value: this.suitabilityResult?.risikokennzahl_gsri },
                { label: "Anlegerprofil Risikokennzahltext", value: this.suitabilityResult?.risikokennzahl_text },
                this.getPruefungLabel(this.suitabilityResult?.risiko_test)
            ]
        },
        kunde() {
            return this.suitabilityResult?.kunde;
        },
        zusaetzlichePersonen() {
            return this.suitabilityResult ? this.suitabilityResult.zusaetzlichePersonen : [];
        },
        secondDepotinhaber() {
            return this.suitabilityResult?.zusaetzlichePersonen.find(p => {
                return p.relationship.extraDepotOwner;
            })
        },
    },
    mounted() {
        this.mappenID = this.$route.params.mappenID;
        this.loadData();
    },
    watch: {
        pruefungsErgebnis: {
            handler() {
                this.hasDataChanged = true;
            },
            once: true
        },
        suitabilityResult: {
            handler() {
                this.hasDataChanged = true;
            },
            once: true
        },
    },
    methods: {
        async loadData() {
            this.loading = true;
            this.loadingSignatures = true;
            this.resetData();

            try {
                    await this.$store.dispatch(VV_TYPES.ACTIONS.GET_SUITABILITYRESULT, { mappenID: this.mappenID })
                    this.setPruefungsstatus();
                    if (this.$route.query.legiSeperate)
                        this.openLegiImages();

                    this.loading = false;
                    await this.$store.dispatch(VV_TYPES.ACTIONS.GET_SUITABILITYSIGNATURES, { mappenID: this.mappenID });
                    this.loadingSignatures = false;
                    this.submitDisabled = false;

                    if (this.suitabilitySignatures["ERROR"])
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
                            message: "Es gibt Fehler in den unterschriebenen Dokumenten!",
                            type: 'danger',
                        }));
            } catch {
                    this.loading = false;
                    this.loadingSignatures = false;
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
                        message: "Es wurde ein Fehler festgestellt. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.",
                        type: 'danger',
                        timeout: 2500,
                    }));
            }
        },
        resetData() {
            this.fehler = [];
            this.rejectedFileIds = [];
          
            this.hasDataChanged = false;
            this.submitDisabled = false;

            this.pruefungsErgebnis = 'AUSSTEHEND';
        },
        openSuitabilityConfirmationModal() {
            this.$refs.suitabilityConfirmationModal.open({ bemerkungText: this.suitabilityResult.bemerkungText, pruefungsErgebnis: this.pruefungsErgebnis });
        },
        timestampToDate(timestamp) {
            return timestamp ? new Date(timestamp).toLocaleDateString('de-de') : '';
        },
        getIconColor(status) {
            if (!status) {
                return 'color-text';
            }
            switch (status.toUpperCase()) {
                case 'AUSSTEHEND':
                case 'NIE':
                    return 'color-warning';
                case 'JA':
                case 'ANGENOMMEN':
                    return 'color-success';
                case 'FEHLER':
                case 'ABGELEHNT':
                    return 'color-danger';

            }
        },
        getPruefungLabel(result) {
            return result ? { label: "Prüfung", value: 'Positiv', cssClass: 'color-success' } : { label: "Prüfung", value: 'Negativ', cssClass: 'color-danger' };
        },
        getAsCurrency(value) {
            if (!value) {
                value = 0;
            }
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
        },
        save() {
            const params = {
                mappenID: this.mappenID,
                bemerkungText: this.suitabilityResult.bemerkungText,
                rejectedFileIds: this.rejectedFileIds,
                pruefungCopyMappe: this.suitabilityResult.pruefungCopyMappe,
            }
            if (this.pruefungsErgebnis === 'ANGENOMMEN') {
                params.pruefungsErgebnis = true;
            } else if (this.pruefungsErgebnis === 'ABGELEHNT') {
                params.pruefungsErgebnis = false;
            }

            this.$store.dispatch(VV_TYPES.ACTIONS.POST_SUITABILITYRESULT, params).then(response => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
                    message: `Die Prüfung wurde erfolgreich durchgeführt. Neuer Status: ${response?.data?.status}`,
                    type: 'success',
                    timeout: 2500,
                }))
                
                response?.data?.messages?.forEach((message) => {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
                        message,
                        type: 'info',
                    }))
                })
            }).then(() => {
                // it should not be possible to immediately submit another check - every submission triggers a lot of background stuff that cannot be changed by quickly resubmitting changed data
                this.submitDisabled = true;
            })
        },
        onRejected(fehler, rejectedFileIds) {
            this.fehler = fehler;
            this.rejectedFileIds = rejectedFileIds

            if (fehler.length === 0) {
                this.pruefungsErgebnis = "AUSSTEHEND"
                this.suitabilityResult.pruefungCopyMappe = false
                this.suitabilityResultbemerkungText = ""
            } else {
                this.pruefungsErgebnis = "ABGELEHNT"
                this.suitabilityResult.pruefungCopyMappe = true // MSC-31917 we always require a copy
            }
            this.rebuildCommentText();
        },
        rebuildCommentText() {
            if (this.suitabilityResult && this.pruefungsErgebnis === "ABGELEHNT") {
                this.suitabilityResult.bemerkungText = ""

                if (this.fehler.length > 0) {
                    this.suitabilityResult.bemerkungText = "Folgende Unterschriften stimmen nicht überein:\n"
                    this.fehler.forEach((fehler) => this.suitabilityResult.bemerkungText += `- ${fehler}\n`)                    
                }

                if (this.suitabilityResult.pruefungCopyMappe) {
                    if (this.suitabilityResult.bemerkungText.length > 0)
                        this.suitabilityResult.bemerkungText += '\n'
                    this.suitabilityResult.bemerkungText += `Die Beratungsmappe wird für die Nachbearbeitung kopiert. Bitte prüfen und bearbeiten Sie diese und lassen Sie sie vom Kunden komplett neu unterschreiben.\n`
                }
            }
        },
        onNewSignatureAuthor(newAuthor) {
            this.signatureAuthor = newAuthor;
        },
        goToNextMappe() {
            const indexCurrentMappe = this.suitabilityResultList.findIndex((mappe) => {
                return this.mappenID === mappe.beratungsmappennr;
            });
            if (indexCurrentMappe > -1 && this.suitabilityResultList[indexCurrentMappe + 1]) {
                this.$router.push(`/intern/vv/suitability-test/mappe/${this.suitabilityResultList[indexCurrentMappe + 1].beratungsmappennr}`);
                // manually update mappenID because we stay in the same component and so the route seems to not be updated, although the url does
                this.mappenID = this.suitabilityResultList[indexCurrentMappe + 1].beratungsmappennr;
                this.loadData();

            } else {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
                    message: "Es liegen keine weiteren Mappen für die angegebenen Suchparameter vor.",
                    type: 'warning',
                    timeout: 2500,
                }));
            }
        },
        openLegiImages() {
            window.open(this.getImgSrcLegi(this.suitabilityResult.kunde.legitimation?.bildVorderseite));
            this.suitabilityResult.kunde.zusaetzlichePersonen.filter(p => {
                return p.relationship.extraDepotowner
            }).forEach(p => {
                window.open(this.getImgSrcLegi(p.legitimation?.bildVorderseite));
            })
        },
        openImage(legiDoc) {
            this.resizableModalOptions.title = legiDoc.label;
            this.resizableModalOptions.img = legiDoc.img;

            var img = new Image();
            img.src = legiDoc.img;
            this.resizableModalOptions.width = Math.min(img.width, 0.5 * window.innerWidth);
            this.resizableModalOptions.height = Math.min(img.height, 0.5 * window.innerHeight) + 44; //+44 to offset headerheight of resizable modal
            this.$refs.legitimationImageModal.showPopup();
        },
        getImgSrcLegi(image) {
            if (image) {
                let byteArray = base64ToArrayBuffer(image.bytes);
                const typedArray = new Uint8Array(byteArray);
                const blob = new Blob([typedArray], { type: image.contentType });
                return URL.createObjectURL(blob);
            }
            return '';
        },
        setPruefungsstatus() {
            if (this.suitabilityResult.gesamt_annahme === null || this.suitabilityResult.gesamt_annahme === null) {
                this.pruefungsErgebnis = 'AUSSTEHEND';
            } else if (this.suitabilityResult.gesamt_annahme && this.suitabilityResult.gesamt_annahme) {
                this.pruefungsErgebnis = 'ANGENOMMEN';
            } else {
                this.pruefungsErgebnis = 'ABGELEHNT';
            }
        }
    }
}
</script>
<style>
.flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.button-container {
    padding-bottom: 1em;
}

.flex-legidocs-item {
    flex: 2 0;
}

.flex-table-item {
    flex: 1 50px;
    border-right: 2px lightgray solid;
}

.flex-textarea-item {
    flex: 2;
    margin-left: 5px;
}

.value--item-label {
    width: 50%;
    min-width: 33%;
}

.risikopruefung-column {
    flex-basis: 100%;
    margin-right: 5px;
}
</style>