<template>
<div>
    <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Daten einlesen" />

    <BaseFilter
        title="Termine für geplante Interfaceläufe"
        filterId="Termine für geplante Interfaceläufe"
        :configFilter="filterConfig"
        :metadata="metadata"
        :defaultOptions="defaultOptions"
        showSaveButton
        :immidiateSearch="true"
        @onFilter="onFilter"
    >
        <BaseButton @click="newInterfaceSetting" isSecondary >
            Anlegen
        </BaseButton>
    </BaseFilter>

    <div class="box__container">
        <div v-if="loading || !rows.length">
            <span class="box__title">
                Termine für geplante Interfaceläufe
            </span>
            <br>
        </div>
        <GhostLoading v-if="loading" type="table"/>
        <Table v-else-if="rows.length"
            tableId="fd693e33-ef47-4c06-953e-1aea4b49b8b4"
            title="Termine für geplante Interfaceläufe"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="20"
            @action-EDIT="showLineDetails"
            @action-DELETE="deleteRow"
        />
        <NoData v-else/>
    </div>

    <BaseModal ref="modal"
        modalTitle="Termineingabe für Interfacelauf"
        labelButtonConfirm="Speichern"
        labelButtonCancel="Abbrechen"
        :autoClose="false"
        @onConfirmButton="saveInterface"
    >
        <InputField label="Interface Id" :value="modalData.interfaceId" disabled/>
        <InputTextArea label="Beschreibung" :value="modalData.description" disabled/>
        <ComboBox label="Gesellschaft" v-model="modalData.gesellschaft" @input="reloadComboboxes(); updateInterface();" :values="gesellschaftOptions"/>
        <ComboBox label="Interface" v-model="modalData.interfaceName" @input="updateInterface" :values="interfaceOptions"/>
        <InputToggleSwitch v-model="modalData.withInactiveInterfaces" @input="reloadComboboxes" label="Mit nicht aktiven Interfaces" inLineLabel/>
        <InputCheckBoxGroup 
            :values="[
                {label: 'Montag', path: 0},
                {label: 'Dienstag', path: 1},
                {label: 'Mittwoch', path: 2},
                {label: 'Donnerstag', path: 3},
                {label: 'Freitag', path: 4},
                {label: 'Samstag', path: 5},
                {label: 'Sonntag', path: 6},
            ]"
            v-model="modalData.days"
        />
        <TimePicker label="Bis Uhrzeit" v-model="modalData.time"/>

    </BaseModal>
</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FileImportLineDetails from '@/components/bipro/FileImportLineDetails.vue'
import FileImportLineEdit from '@/components/bipro/FileImportLineEdit.vue'
import BaseModal from "@/components/core/BaseModal.vue";
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import TimePicker from '@/components/core/forms/TimePicker.vue';

import {TextColumn, ActionColumn, SimpleAction, ConfirmedAction} from "@/components/table2/table_util.js";
import axios from 'axios';
import { mapGetters } from 'vuex';
import { buildMessage } from "@/helpers/log-message-helper";

const config = {
    defaultSpinner: true,
};

export default {
    mixins: [],
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        BaseFilter,
        Table,
        GhostLoading,
        NoData,
        BaseButton,
        FileImportLineDetails,
        FileImportLineEdit,
        BaseModal,
        InputField,
        InputTextArea,
        ComboBox,
        InputToggleSwitch,
        InputCheckBoxGroup,
        TimePicker,
    },
    data() {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        return {
            loading: false,
            gesellschaftOptions: [],
            interfaceOptions: [],
            metadata: [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Gesellschaft',
                            key: 'gesellschaft',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Interface',
                            key: 'interface',
                            emptyDenied: true,
                        },
                    ],
                },
            ],
            filterConfig: {
                checkDefaultSearchInput: false,
                cbDefSearchInput: null,
                hideFirstColumn: true,
                filterZurucksetzen: null,
                noResetOnDefaultSearchInputExit: true,
            },
            defaultOptions: {},
            filterParams: {},
            headers: {
                lockedLeft: [
                    TextColumn("gesellschaft", "Gesellschaft"),
                    TextColumn("interfaceName", "Interface"),
                ],
                center: [
                    TextColumn("zeitraum", "Zeitraum"),
                ],
                lockedRight: [
                    ActionColumn("actions"),
                ]
            },
            lines: null,
            lineDetails: null,
            lineEdit: null,
            rowToDelete: null,
            modalData: {},
        };
    },
    computed: {
        ...mapGetters({
        }),
        rows() {
            return (this.lines || []).map(row => {
                const actions = [
                    SimpleAction("EDIT", '', "Daten öffnen"),
                    ConfirmedAction("DELETE", '', "Daten löschen", "Wollen Sie die Daten wirklich löschen?"),
                ];
                return {
                    ...row,
                    actions,
                };
            });
        },
    },
    methods: {
        async onFilter(filterConfig) {
            this.filterParams = {};

            filterConfig.forEach(fc => {
                let value = fc.value;
                this.filterParams[fc.key] = value;
            });
            this.loadRows();
        },
        async loadRows() {
            this.loading = true;
            try {
                const response = await axios.get(`${process.env.VUE_APP_API}/gdv_import_monitor/planned_interface_runs?gesellschaft=${this.filterParams.gesellschaft || ''}&interface=${this.filterParams.interface || ''}`, config)
                this.lines = response.data.rows;
                //this.gesellschaftOptions = response.data.gesellschaften;
            } catch (_) {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Bei der Suche ist ein Fehler aufgetreten.', 'danger'));
            } finally {
                this.loading = false;
            }
        },
        reloadComboboxes() {
            axios.get(`${process.env.VUE_APP_API}/gdv_import_monitor/interface_comboboxes?withInactiveInterfaces=${this.modalData?.withInactiveInterfaces || false}&gesellschaft=${this.modalData?.gesellschaft || ''}`, config)
            .then(response => {
                this.gesellschaftOptions = response.data.gesellschaften;
                this.interfaceOptions = response.data.interfaces;
            })
        },
        updateInterface() {
            axios.get(`${process.env.VUE_APP_API}/gdv_import_monitor/get_interface_description?gesellschaft=${this.modalData?.gesellschaft || ''}&interface=${this.modalData?.interfaceName || ''}`, config)
            .then(response => {
                this.modalData = {
                    ...this.modalData,
                    ...response.data,
                };
            })
        },
        async showLineDetails(row) {
            axios.get(`${process.env.VUE_APP_API}/gdv_import_monitor/interface_setting?id=${row.id}`, config)
            .then(response => {
                this.modalData = response.data;
                this.reloadComboboxes();
                this.$refs.modal.open();
            })
        },
        async newInterfaceSetting() {
            this.modalData = {gesellschaft: "Allgemein", days: [false, false, false, false, false, false, false]};
            this.reloadComboboxes();
            this.$refs.modal.open();
        },
        saveInterface() {
            if (!this.modalData.gesellschaft || !this.modalData.interfaceName || !this.modalData.time) {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Gesellschaft, Interface und Uhrzeit müssen angegeben werden!', 'danger'));
                return;
            }
            axios.post(`${process.env.VUE_APP_API}/gdv_import_monitor/interface_setting`, this.modalData, config)
            .then(response => {
                this.modalData.id = response.data;
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Der Interfacelauf wurde erfolgreich gespeichert.', 'info'));
                this.loadRows();
            })
        },
        deleteRow(row) {
            axios.delete(`${process.env.VUE_APP_API}/gdv_import_monitor/interface_setting?id=${row.id}`, config)
            .then(_ => {
                this.loadRows();
            })
        },
    },
}
</script>
