<template>
  <div>
    <BaseButton @click="openAddModal()" v-if="editable">Neue</BaseButton>

    <Table v-if="!loading && rows.length"
        title="Courtagezubringer"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        v-model="selectedRows"
        @click-bereich="openEditModal"
        @action-DELETE="deleteCourtage($event)"
    />
    <GhostLoading v-else-if="loading" type="table" />
    <div v-else>Keine Daten</div>

    <BaseModal
      ref="courtageDataModal"
      size="md"
      modalTitle="Zubringercourtage bearbeiten"
      :showDefaultButtons="false"
    >
      <template #default>
        <p>Bitte tragen Sie hier den Prozentsatz ein den der Zubringer in Relation zu der Courtage des Vermittlers bekommen soll, dem der Kunde zugeordnet ist.</p>

        <div class="input-forms__container">
          <div class="col-md-3 col-12">
            <InputField
              label="Bereich"
              v-model="form.bereich"
              disabled
            />
          </div>
          <div class="row mt-0 mb-0">
            <div class="col-md-3 col-12">
              <InputField
                label="Zubringer"
                v-model="form.zubringer"
                validateUntouched
                @input="findZubringerDataDebounce($event)"
                @onBlur="checkIfZubringerDataIsValid()"
              />
            </div>

            <div class="col-md-9 col-12 mt-md-24px mt-3">
              <InputField
                :disabled="true"
                v-model="form.zubringerFullName"
              />
            </div>

            <div v-if="isZubringerInvalid" class="col-12 color-danger">
              Die Vermittlernummer des Zubringers ist nicht korrekt!
            </div>
          </div>
        </div>

        <template v-if="isIntern">
          <ComboBox
            label="Courtage"
            :values="absolutValues"
            v-model="form.absolut"
          />
        </template>

        <InputField
          label="Abschluss / Einmalige VV-Strategiegebühr"
          type="percent"
          v-model="form.provAbschluss"
          :precision="PRECISION"
        />

        <InputField
          label="Bestand"
          type="percent"
          v-model="form.provBestand"
          :precision="PRECISION"
        />

        <InputField
          label="MwSt"
          type="percent"
          v-model="form.mwst"
          validateUntouched
          :disabled="!insuranceCourtageConfig.hatZubringerOhneMwSt"
          :precision="2"
        />

        <p class="m-0">
          <b><u>Achtung:</u> Wenn Ihr Zubringer keine 34f/h Lizenz besitzt, muss er als Untervermittler unter Ihrer Vermittlernummer angelegt und unter "Vermittlerdaten - Zulassung" deklariert werden. Ansonsten kann keine Auszahlung erfolgen.</b>
        </p>
      </template>
      <template #footer>
        <BaseButton isSecondary @click="closeCourtageDataModal()" class="mr-3">Abbrechen</BaseButton>
        <BaseButton :disabled="!editable ||validation.isInvalid('form') || isZubringerInvalid" @click="saveForm(); closeCourtageDataModal();">Speichern</BaseButton>
      </template>
    </BaseModal>

    <BaseModal
      ref="courtageDeleteModal"
      size="sm"
      modalTitle="Courtage Zubringer löschen"
      labelButtonConfirm="Löschen"
      @onConfirmButton="deleteCourtageItem()"
    >
      <template #default>
        Soll die Zubringercourtage für den Vermittler {{ courtageItemToDelete.zubringer }} wirklich gelöscht werden?
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import CORE_TYPES from '@/store/core/types';

import BaseButton from '@/components/core/BaseButton.vue';
import Table from '@/components/table2/Table.vue';
import {TextColumn, PercentageColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';

import validator from '@/mixins/validator';
import { required, range } from '@/mixins/validator/rules';
import { debounce } from '@/helpers/commonfunctions.js';
import {PhTrash} from 'phosphor-vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

const MAKLERNR_LENGTH = 5;
const FORM_ZUBRINGERNR_PATH = 'form.zubringer';
const PRECISION = 4;

export default {
  mixins: [validator],
  components: {
    BaseButton,
    Table,
    BaseModal,
    ComboBox,
    InputField,
    PhTrash,
    GhostLoading,
  },
  props:{
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PRECISION,
      loading: false,
      isZubringerInvalid: false,
      findZubingerDataDebounce: null,
      absolutValues: [
        { label: 'absolut zur Eingangscourtage', value: true, },
        { label: 'relativ zur Courtage des Abschlussvermittlers', value: false, },
      ],
      form: {},
      courtageItemToDelete: {},
      headers: {
        lockedLeft: [
            TextColumn("bereich", "Bereich").makeLink(),
        ],
        center: [
            TextColumn("zubringerText", "Zubringer"),
            PercentageColumn("provAbschluss", "Abschluss / Einmalige VV-Strategiegebühr"),
            PercentageColumn("provBestand", "Bestand"),
            PercentageColumn("mwst", "MwSt"),
        ],
        lockedRight: [
            ActionColumn("actions", "Aktionen"),
        ],
      },
      selectedRows: null,
    };
  },
  computed: {
    ...mapGetters({
      courtage: VERSICHERUNG_TYPES.GETTERS.INSURANCE_COURTAGE,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      insuranceCourtageConfig: VERSICHERUNG_TYPES.GETTERS.INSURANCE_COURTAGE_CONFIG
    }),
    rows() {
      if (!this.courtage) {
          return [];
      }
      const actions = [
        SimpleAction("DELETE", '', "Löschen")];

      return [...this.courtage].map(r => {
        const record = {
          ...r,
          actions,
        };
        record.zubringerText = `${r.zubringerFullName || ''}/${r.zubringer || ''}`.trim();
        record.provAbschluss = (r.provAbschluss || 0).toFixed(PRECISION);
        record.provBestand = (r.provBestand || 0).toFixed(PRECISION);
        if(this.isIntern) {
          record.absolut = r.absolut === true ? 'Ja' : 'Nein';
        }
        return record;
      });
    },
  },
  methods: {
    openCourtageDataModal() {
      this.$refs.courtageDataModal.open();
    },
    closeCourtageDataModal() {
      this.$refs.courtageDataModal.close();
    },
    openAddModal() {
      this.form = {
        id: null,
        zubringer: null,
        zubringerFullName: null,
        absolut: false,
        bereich: 'Versicherungen',
        provAbschluss: null,
        provBestand: null,
        mwst: this.customerDataConfig?.mwstValue,
      };

      this.openCourtageDataModal();
    },
    openEditModal(courtageItem) {
      this.form = {
        ...courtageItem,
      };

      this.openCourtageDataModal();
    },
    openDeleteModal(courtageItem) {
      this.courtageItemToDelete = { 
        ...courtageItem,
      };

      this.$refs.courtageDeleteModal.open();
    },
    deleteCourtageItem() {
      if(this.courtageItemToDelete?.id) {
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_COURTAGE_DELETE, {
          courtageId: this.courtageItemToDelete?.id,
        });
      }
    },
    findZubringerDataDebounce: debounce(async function findZubringerData(maklernr) {
      this.form.zubringerFullName = null;

      try {
        if(maklernr?.length === MAKLERNR_LENGTH) {
          const zubringerData = await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_ZUBRINGER_DATA, { maklernr });
          this.form.zubringer = zubringerData?.maklernr;
          this.form.zubringerFullName = zubringerData?.fullName;
        }
      } catch(e) { }

      this.checkIfZubringerDataIsValid();
    }),
    deleteCourtage(event) {
      if(event){
        this.openDeleteModal(event);
      }
    },
    checkIfZubringerDataIsValid() {
      this.validation.reset(FORM_ZUBRINGERNR_PATH);
      this.isZubringerInvalid = false;

      if(!this.form?.zubringerFullName) {
        this.isZubringerInvalid = true;
        this.$pushErrors(FORM_ZUBRINGERNR_PATH);
      }
    },
    saveForm() {
      if(!this.editable) {
        return;
      }
      const form = {
        ...this.form,
        provAbschluss: this.form?.provAbschluss || 0,
        provBestand: this.form?.provBestand || 0,
        mwst: this.form?.mwst || 0,
        vertragId: this.$route.params.id,
      };
      if(form.id){
        
        if(form  && form.absolut && form.absolut.toLowerCase(form.absolut)=='ja'){
           form.absolut='true'
        }
        if(form  && form.absolut && form.absolut.toLowerCase(form.absolut)=='nein'){
            form.absolut='false'
        }
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_COURTAGE_UPDATE, form);
        return;
      }
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_COURTAGE_SAVE, form);
    },
  },
  mounted() {
    this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.FIND_ALL_INSURANCE_COURTAGE, {vertragId: this.$route.params.id});
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG);
    this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_COURTAGE_CONFIG);
  },
  validators: {
    form: {
      bereich: [required()],
      zubringer: [required()],
      mwst: [required(), range(0, 25)],
    }
  },
}
</script>
