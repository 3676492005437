<template>
  <div>
    <GesellschaftTitleNavigation/>

    <div class="box__container">
      <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
      <Table 
        v-else
        rowId="spartentypId"
        :title="TABLE_TITLE"
        :rows="rows"
        :headers="headers"
        :rowsPerPage="20"
        @click-bezeichnung="openEditVorgabeModal"
        @action-DELETE="deleteProvisionVorgabe"
      />
    </div>

    <BaseModal
      ref="editVorgabeModal"
      modalTitle="Provisionsvorgaben bearbeiten"
      labelButtonConfirm="Speichern"
      @onConfirmButton="saveProvisionVorgabe"
    >
      <div class="mb-2">
        Sparte: <span class="text-bold">{{editProvisionVorgabe.bezeichnung}}</span>
      </div>
      <div class="mb-2">
        Falls bei der Versicherung 'Vorgabe' gewählt wird, so  werden die Sätze/Berechnungsverfahren in folgender Priorität angewendet:
      </div>
      <div class="box__title">Prio 1: Prämie und Laufzeit mit Maximierung</div>
      <InputField
        label="Jahresprämie*Laufzeit / 1,04 in %o"
        type="percent"
        v-model="editProvisionVorgabe.provsatzLaufzeit"
      />
      <InputField
        label="Maximum in % der Jahresnettopämie / 1,04"
        type="percent"
        v-model="editProvisionVorgabe.provsatzDeckelung"
      />
      <div class="box__title">Prio 2: Provschema Abschluss</div>
      <InputField
        label="in % der Jahresnettoprämie"
        type="percent"
        v-model="editProvisionVorgabe.provsatz1"
      />
      <InputField
        label="in % der Präme je Zahlweise (Bestand)"
        type="percent"
        v-model="editProvisionVorgabe.provsatz1Bestand"
      />
      <div class="box__title">Prio 3: Provschema erhöhter Bestand</div>
      <InputField
        label="in % der Präme je Zahlweise"
        type="percent"
        v-model="editProvisionVorgabe.provsatz2"
      />
      <InputField
        label="Laufzeit Stornohaftung (in Monaten)"
        type="number"
        v-model="editProvisionVorgabe.laufzeitStornohaftung"
      />
    </BaseModal>  
  </div>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import { TextColumn, PercentageColumn, NumberColumn, ActionColumn, SimpleAction, } from "@/components/table2/table_util.js";

import InputField from '@/components/core/forms/InputField.vue'
import BaseModal from '@/components/core/BaseModal.vue';

const TABLE_TITLE = 'Vorgabe Provision';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    GesellschaftTitleNavigation,
    GhostLoading,
    Table,
    InputField,
    BaseModal,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      editProvisionVorgabe: {},
    }
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    headers() {
      return {
        lockedLeft: [
          TextColumn("bezeichnung", "Sparte").makeLink(),
          PercentageColumn("provsatzLaufzeit", "o Laufzeit"),
          PercentageColumn("provsatzDeckelung", "Max"),
          PercentageColumn("provsatz1", "Abschluss"),
          PercentageColumn("provsatz1Bestand", "Bestand b. Abschluss"),
          PercentageColumn("provsatz2", "erh. Bestand"),
          NumberColumn("laufzeitStornohaftung", "Laufzeit Stornorisiko"),
        ],
        center: [],
        lockedRight: [
          ActionColumn('actions')
        ],
      }
    },
    rows() {
      const provisionVorgaben = this.gesellschaft.provisionVorgaben || []
      return provisionVorgaben.map(prov => ({
        ...prov,
        actions: [
          ...(prov.id ? [SimpleAction('DELETE', '', 'Löschen')] : []),
        ]
      }))
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData(isReload = false) {
      this.loading = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_PROVISION_VORGABEN, { gesellschaftId: this.$route.params.gesellId, isReload })
      .finally(() => this.loading = false);
    },
    openEditVorgabeModal(row) {
      this.editProvisionVorgabe = {
        ...row,
        actions: undefined,
      }
      
      this.$refs.editVorgabeModal.open()
    },
    saveProvisionVorgabe() {
      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)
      const spartentypId = this.editProvisionVorgabe.spartentypId

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/provisionVorgabe?${params}`, this.editProvisionVorgabe, config)
        .then(response => {
          const index = this.gesellschaft.provisionVorgaben.findIndex(row => row.spartentypId === spartentypId);

          if (index >= 0) {
            this.$set(this.gesellschaft.provisionVorgaben, index, response.data)
          }
        })
    },
    deleteProvisionVorgabe({id}) {
      const params = makeQueryParam({ provisionVorgabeId: id })

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/provisionVorgabe?${params}`, config)
        .then(() => {
          const index = this.gesellschaft.provisionVorgaben.findIndex(row => row.id === id)

          if (index >= 0) {
            this.$set(this.gesellschaft.provisionVorgaben, index, {
              ...this.gesellschaft.provisionVorgaben[index],
              id: null,
              provsatzLaufzeit: null,
              provsatzDeckelung: null,
              provsatz1: null,
              provsatz1Bestand: null,
              provsatz2: null,
              laufzeitStornohaftung: null,
            })
          }
        })
    },
  },
}
</script>