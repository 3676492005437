<template>
  <div>
    <Table
      v-if="!isLoading && rows.length"
      tableId="579b1982-470b-4522-8482-1246152081a3"
      rowId="label"
      :headers="headers"
      :rows="rows"
      :rowsPerPage="rows.length"
      hidePagination
      @action-ITEM_LOESCHEN="removeItem"
    />
    <GhostLoading v-else-if="isLoading" type="table" />
    <NoData v-else noIcon />

    <BaseButton :disabled="saving || isLoading" @click="openMaklerKriterienAuswaehlenModal">Kriterien auswählen</BaseButton>

    <FormDivider/>

    <div>
      <InputField
        label=""
        v-model="maklerKriteriumNeu"
        :isComponentHalfSize="true"
        :disabled="saving || isLoading"
      />
      <BaseButton :disabled="maklerKriteriumNeu.length == 0 || saving || isLoading" @click="addNewMaklerKriterien">Neues Kriterium hinzufügen</BaseButton>
    </div>

    <MaklerKriterienAuswaehlenModal ref="maklerKriterienAuswaehlenModal" 
      :alleStrukturKriterien="alleStrukturKriterien" @save="saveMaklerKriterien" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BROKERDATA_TYPES from "@/store/brokerData/types";
import LOADING_TYPES from '@/store/loading/types';

import Table from "@/components/table2/Table.vue";
import { TextColumn, ActionColumn, SimpleAction, } from '@/components/table2/table_util';
import BaseButton from "@/components/core/BaseButton.vue";
import InputField from "@/components/core/forms/InputField.vue";
import FormDivider from '@/components/core/forms/FormDivider.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import MaklerKriterienAuswaehlenModal from '@/components/broker/MaklerKriterienAuswaehlenModal.vue';

const BROKER_DATA_LOADING_ID = 'mrsbrokerdataconfig';

export default {
  props: {
    maklerKriterien: {
      type: Array,
      default: () => [],
    },
    alleStrukturKriterien: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      maklerKriteriumNeu: "",
      saving: false,
    };
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters({
      isLoadingFn: LOADING_TYPES.GETTERS.IS_LOADING,
    }),
    isLoading() {
      return this.isLoadingFn(BROKER_DATA_LOADING_ID);
    },
    headers() {
      return {
        lockedLeft: [
          TextColumn('label', 'Makler Kriterien'),
        ],
        center: [],
        lockedRight: [
          ActionColumn('actions', 'Aktionen'),
        ],
      };
    },
    rows() {
      return this.maklerKriterien.map((m) => ({ 
        label: m,
        actions: [
          SimpleAction('ITEM_LOESCHEN', '', 'Löschen'),
        ],
      }));
    },
  },
  methods: {
    removeItem(row) {
      const maklerKriteriumLoeschen = row.label;
      const arr = this.maklerKriterien.filter((mk) => mk != maklerKriteriumLoeschen);
      this.saveMaklerKriterien(arr);
    },
    async saveMaklerKriterien(payload) {
      try {
        this.saving = true;
        await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.SAVE_UPDATE_MAKLERKRITERIEN, payload);
      } finally {
        this.saving = false;
      }
    },
    openMaklerKriterienAuswaehlenModal() {
      this.$refs.maklerKriterienAuswaehlenModal.open([ ...this.maklerKriterien, ]);
    },
    addNewMaklerKriterien() {
      const payload = this.maklerKriteriumNeu;
      this.saveMaklerKriterienNew(payload);
      this.maklerKriteriumNeu = "";
    },
    async saveMaklerKriterienNew(payload) {
      if(!payload) return;

      try {
        this.saving = true;
        const arr = [ ...this.maklerKriterien || [], payload, ];
        await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.SAVE_UPDATE_MAKLERKRITERIEN, arr);
      } finally {
        this.saving = false;
      }
    },
  },
  components: {
    Table,
    BaseButton,
    InputField,
    FormDivider,
    GhostLoading,
    NoData,
    MaklerKriterienAuswaehlenModal,
  },
};
</script>
