<template>
    <div class="row-content" v-on="isClickable ? { click: (event) => $emit('clickRow', event) } : {}">
        <slot :name="'label'">
            {{label}}
        </slot>

        <div :class="{'d-flex': isClickable}">
            <slot :name="'value'">
                {{value}}     
            </slot>
            <ph-caret-right v-if="isClickable" class="ml-2" weight="bold" style="flex-shrink: 0" :size="20"/>
        </div>

    </div>
</template>

<script>

import { PhCaretRight, } from "phosphor-vue";

export default {
    components: {
        PhCaretRight,
    },
    props: {
        label: {},
        value: {},
    },
  computed: {
    isClickable() {
      return !!this.$listeners['clickRow']
    },
  },
}
</script>

<style scoped>

.row-content {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #c4c4c4;
    	display: flex;
	justify-content: space-between;
	flex: 1 1 auto;
	font-weight: bold;
	font-size: 1.1em;
}

.row-content:last-of-type {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
}

</style>
