<template>
  <div>
    <PrivatStammdaten v-if="substep === 'stammdaten'" key="privat-stammdaten" />

    <div v-else-if="substep === 'adresse'" class="box__container" key="privat-adresse">
      <div class="box__title">Adresse</div>
      <div>
        <BaseButton
          v-if="showCopyAddress"
          :label="'Adresse kopieren'"
          @click="copyAddress"
        />
      </div>
      <div class="row">
        <div class="col-12">
          <InputZIP
            label="Postleitzahl"
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            v-model="personalDataAddress.zip"
            :land="personalDataAddress.country"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataAddress')"
            @city="setCity($event)"
            @country="setCountry($event)" />
          <InputField label="Ort" 
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            v-model="personalDataAddress.city"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataAddress')"/>
          <InputField label="Straße" 
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            v-model="personalDataAddress.street"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataAddress')"/>
          <ComboBox label="Land" 
            :isComponentHalfSize="true"
            :values="customerDataConfig.countries"
            v-model="personalDataAddress.country"
            :disabled="!isEditable"
            :sortComboboxValues="false"
            :firstEmpty="!personalDataAddress.country"
            @change="addCustomerDataEditedCombo('personalDataAddress', 'country', $event)"/>
          <ComboBox label="Bundesland"
            :isComponentHalfSize="true" 
            :values="stateComboValues"
            v-model="personalDataAddress.state"
            :disabled="!isEditable"
            :firstEmpty="!personalDataAddress.state"
            @change="addCustomerDataEditedCombo('personalDataAddress', 'state', $event)"/>
        </div>
      </div>
    </div>

    <Versandadresse v-else-if="substep === 'versandadresse'" key="privat-versandadresse" />

    <Anschriftfeld v-else-if="substep === 'anschriftfeld'" key="privat-anschriftfeld" />

    <div v-else-if="substep === 'geburtstag'" class="box__container" key="privat-geburtstag">
      <div class="box__title">Geburtstag und Staatsangehörigkeit</div>
      <div class="row">
        <div class="col-12">
          <DatePickerField label="Geburtsdatum"
            :labelClass="depoteroeffnungenLabelClass"
            isValueAsString
            emptyValueAsNull
            :isComponentHalfSize="true"
            v-model="personalDataBirth.dayOfBirth"
            :disabled="!isEditable"
            @input="addCustomerDataEdited('personalDataBirth')"/>

          <InputField label="Geburtsort" 
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            v-model="personalDataBirth.placeOfBirth"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataBirth')"/>

          <ComboBox label="Geburtsland" 
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            :values="customerDataConfig.countries"
            v-model="personalDataBirth.countryOfBirth"
            :sortComboboxValues="false"
            :disabled="!isEditable"
            :firstEmpty="!personalDataBirth.countryOfBirth"
            @change="addCustomerDataEditedCombo('personalDataBirth', 'countryOfBirth', $event)"/>

          <ComboBox label="Staatsangehörigkeit" 
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            :values="customerDataConfig.nationalities"
            v-model="personalDataBirth.nationality"
            :sortComboboxValues="false"
            :disabled="!isEditable"
            :firstEmpty="!personalDataBirth.nationality"
            @change="addCustomerDataEditedCombo('personalDataBirth', 'nationality', $event)"/>

          <InputField label="Geburtsname" 
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            v-model="personalDataBirth.nameAtBirth"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataBirth')"/>
        </div>
      </div>
    </div>

    <PrivatBeruf v-else-if="substep === 'beruf'" key="privat-beruf" />

    <div v-else-if="substep === 'legitimationsurkunde'" key="privat-legitimationsurkunde">
      <div class="box__container">
        <div class="box__title">Legitimationsurkunde</div>
        </div>
      <LegitimationImages :legitimation="legitimation" :disabled="isSavingCustomerData" @read-image-data="openDataConflictModalIfExistsConflict($event)" @addCustomerDataEdited="addCustomerDataEdited('legitimation')" />
      <div class="box__container">
        <div class="row">
          <div class="col-12">
            <ComboBox label="Art der Urkunde" 
              :labelClass="depoteroeffnungenLabelClass"
              :isComponentHalfSize="true"
              :values="customerDataConfig.legitimationTypes"
              :value="legitimation.legitimType"
              :disabled="!isLegitimationEditable"
              :firstEmpty="!hasValidComboValue('legitimation', 'legitimType', customerDataConfig.legitimationTypes)"
              @change="onLegitimTypeChange($event)"/>

            <InputField label="Ausweisnummer" 
              :labelClass="depoteroeffnungenLabelClass"
              :isComponentHalfSize="true"
              v-model="legitimation.legitimNumber"
              :disabled="!isLegitimationEditable || !hasLegitimType"
              @change="addCustomerDataEdited('legitimation')"/>

            <InputField label="Ausstellungsbehörde" 
              :labelClass="depoteroeffnungenLabelClass"
              :isComponentHalfSize="true"
              v-model="legitimation.authority"
              :disabled="!isLegitimationEditable || !hasLegitimType"
              @change="addCustomerDataEdited('legitimation')"/>

            <DatePickerField label="Ausstellungsdatum"
              :labelClass="depoteroeffnungenLabelClass"
              isValueAsString
              :isComponentHalfSize="true"
              v-model="legitimation.dateOfIssue"
              :disabled="!isLegitimationEditable || !hasLegitimType"
              validateUntouched
              @input="addCustomerDataEdited('legitimation'); handleDateOfIssueChange($event)"/>

            <DatePickerField label="Gültig bis"
                v-if="legitimation.legitimType !== 'Geburtsurkunde'"
              :labelClass="depoteroeffnungenLabelClass"
              isValueAsString
              :isComponentHalfSize="true"
              v-model="legitimation.validTo"
              :disabled="!isLegitimationEditable || !hasLegitimType"
              validateUntouched
              @input="addCustomerDataEdited('legitimation')"/>

            <DataConflictModal ref="dataConflictModal" @confirm="confirmDataConflict($event)" />
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="substep === 'steuerdaten'" class="box__container" key="privat-steuerdaten">
      <div class="box__title">Steuerdaten</div>
      <div class="row">
        <div class="col-12">
          <ComboBox label="Steuerlich in" 
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            :values="steuerlichInCountries"
            v-model="personalDataTax.taxResidence"
            :disabled="!isEditable"
            :sortComboboxValues="false"
            :firstEmpty="!hasValidComboValue('personalDataTax', 'taxResidence', steuerlichInCountries)"
            @change="addCustomerDataEditedCombo('personalDataTax', 'taxResidence', $event)"/>

          <ComboBox label="Güterstand" 
            :labelClass="depoteroeffnungenLabelClass"
            :isComponentHalfSize="true"
            :values="customerDataConfig.maritalStates"
            v-model="personalDataTax.maritalPropertyRegime"
            :disabled="!isEditable"
            :firstEmpty="!personalDataTax.maritalPropertyRegime"
            @change="addCustomerDataEditedCombo('personalDataTax', 'maritalPropertyRegime', $event)"/>

          <InputField label="Sozialversicherungsnummer" 
            :isComponentHalfSize="true"
            v-model="personalDataTax.socialInsuranceNumber"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataTax')"/>

          <InputField label="SteuerIdentNummer TIN/LEI" 
            :isComponentHalfSize="true"
            v-model="personalDataTax.taxID"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataTax')"/>

            <InputField label="Steuerklasse" 
            :isComponentHalfSize="true"
            v-model="personalDataTax.taxClass"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataTax')"/>

          <InputField label="Finanzamt" 
            :isComponentHalfSize="true"
            v-model="personalDataTax.taxOffice"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataTax')"/>

          <InputField label="Steuernummer" 
            :isComponentHalfSize="true"
            v-model="personalDataTax.taxNr"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataTax')"/>

          <InputField label="Kindergeldnummer" 
            :isComponentHalfSize="true"
            v-model="personalDataTax.childrenBenefitsNr"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataTax')"/>

          <InputField label="Familienkasse" 
            :isComponentHalfSize="true"
            v-model="personalDataTax.familyBenefitsOffice"
            :disabled="!isEditable"
            @change="addCustomerDataEdited('personalDataTax')"/>
          
          <InputToggleSwitch 
            label="FATCA - Foreign Account Tax Compliance Act" 
            :inLineLabel="true"
            v-model="fatca.aktiv" 
            :disabled="!isEditable" 
            @input="addCustomerDataEdited('fatca')" />

          <template v-if="fatca.aktiv">
            <InputField 
              label="Art des USA-Bezugs" 
              isComponentHalfSize
              v-model="fatca.text" 
              :disabled="!isEditable" 
              @input="addCustomerDataEdited('fatca')" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validator from '@/mixins/validator'
import { required, maxDate } from '@/mixins/validator/rules'
import stammdatenMixin from './stammdaten-mixin'
import { initialState } from './stammdaten-mixin'
import persoenlicheDatenMixin from '../persoenliche-daten-mixin'
import dayjs from "dayjs";

import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue'
import InputZIP from '@/components/core/forms/InputZIP.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import LegitimationImages from './LegitimationImages.vue'
import DataConflictModal from './DataConflictModal.vue'
import Anschriftfeld from './Anschriftfeld.vue';
import Versandadresse from './Versandadresse.vue';
import PrivatBeruf from './PrivatBeruf.vue';
import PrivatStammdaten from './PrivatStammdaten.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

import { LAST_NAME_REQUIRED_TEXT }  from '@/views/customer/customer-utils'
import CUSTOMERDATA_TYPES from "@/store/customerData/types";


export default {
  props: {
    substepProperty: {
      type: String,
      default: null
    },
  },  
  mixins: [validator, persoenlicheDatenMixin, stammdatenMixin],
  data() {
    return {
      showCopyAddress: false,
      ...initialState(),
    };
  },
  watch: {
    customerData() {
      Object.assign(this.$data, initialState());
      this.updateGroupsIntoData()
      
      if (this.personalDataTax && !this.personalDataTax.taxResidence) {
        const land = this.isFA ? 'Österreich' : 'Deutschland';
        this.personalDataTax.taxResidence = land; // MSC-23899
        this.addCustomerDataEditedCombo('personalDataTax', 'taxResidence', land);
      }
    },
    substep() {
      if (this.substep === 'steuerdaten' && this.personalDataTax?.taxResidence && this.validation.personalDataTax?.taxResidence?.valid === false) {
        this.validation.personalDataTax.taxResidence.watchCallback(this.personalDataTax.taxResidence);
      }
    },
    personId() {
      this.updateGroupsIntoData()
      this.findInitialStates()
    },
  },
  computed: {
    hasLegitimType() {
      return !!this.legitimation?.legitimType;
    },
    // go to stammdaten-mixin.js. Shared computed attributes for Privat.vue and Firma.vue will be there
  },
  methods: {
    async copyAddress() {
      this.navigateToAdresse(async () => {
        this.copying = true;
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.COPY_ADDRESS);
        this.copying = false;
      });
    },
    navigateToAdresse(onComplete) {
      const personIdParam = this.$route?.params?.personId;
      const currentSubstep = this.$route?.query?.substep;
      if(currentSubstep !== 'adresse') {
        this.$router.push(`/persoenlichedaten/person-data/${personIdParam}/stammdaten?substep=adresse`, () => setTimeout(onComplete));
      } else {
        onComplete();
      }
    },
    // go to stammdaten-mixin.js. Shared methods for Privat.vue and Firma.vue will be there
    setCity(data) {
      this.$set(this.personalDataAddress, 'city', data);
      this.addCustomerDataEdited('personalDataAddress')
    },
    setCountry(data) {
      this.$set(this.personalDataAddress, 'country', data);
      this.addCustomerDataEdited('personalDataAddress')
    },
  },
  created() {
    this.addDepoteroeffnungenHint()
  },
  mounted() {
    this.updateGroupsIntoData()
    this.findInitialStates()

    if(this.$route?.params?.personId)
      this.showCopyAddress = true;
  },
  validators: {
    personalDataAddress: {
      lastName: [required(LAST_NAME_REQUIRED_TEXT)],
    },
    legitimation: {
        dateOfIssue: [maxDate(dayjs().add(1, 'day'), "Das Ausstellungsdatum muss in der Vergangenheit liegen.")],
    },
  },
  components: {
    ComboBox,
    InputField,
    DatePickerField,
    InputZIP,
    BaseButton,
    LegitimationImages,
    DataConflictModal,
    Versandadresse,
    Anschriftfeld,
    PrivatBeruf,
    PrivatStammdaten,
    InputToggleSwitch,
  }
}
</script>
