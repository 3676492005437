var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Vermögensverwaltungen",
          subtitle: "Produktliste",
          defaultMenu: _vm.noHeaderMenu
            ? []
            : _vm.$appNavigation.currentTabMenu,
          actions: _vm.headerActions
        },
        on: { "action-NEW": _vm.openRegularFondsfinder }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm._m(0),
          _vm.isVVRM && !_vm.isSelectable
            ? _c("InputToggleSwitch", {
                attrs: { label: "Nur die Wertpapiere zur Prüfung zeigen" },
                on: {
                  input: function($event) {
                    return _vm.search()
                  }
                },
                model: {
                  value: _vm.showWertpapierForCheck,
                  callback: function($$v) {
                    _vm.showWertpapierForCheck = $$v
                  },
                  expression: "showWertpapierForCheck"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("WertpapierSearch", {
        attrs: {
          name: _vm.name,
          respond: _vm.isSelectable ? 1 : 2,
          isSelectable: _vm.isSelectable
        },
        on: {
          selected: function($event) {
            _vm.selectedRows = $event
          },
          onSearch: _vm.search,
          handleTableAction: _vm.handleTableAction,
          "confirm-selected": _vm.navigateToEditor
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " Für die Zusammenstellung Ihrer persönlichen Strategie benötigen Sie vom Vermögensverwalter überprüfte und bestätigte Investmentfonds. Unter den unten aufgeführten Reitern können Sie sich einen Überblick über bereits zertifizierte, beantrage oder abgelehnte Wertpapiere schaffen. Sollten die von Ihnen gewünschten Wertpapiere fehlen, bitten wir Sie diese über "
      ),
      _c("b", [_vm._v("„Neues Wertpapier vorschlagen“")]),
      _vm._v(
        " dem Vermögensverwalter zur Zertifizierung vorzulegen. Bitte beachten Sie, dass Sie den Status auf „Beantragt“ setzen. Der Status „in Vorbereitung“ dient ausschließlich Ihrem Auswahlverfahren. Bitte beachten Sie, dass der von Ihnen gewünschte Fonds ein Fondsvolumen von mindestens 30 Mio € und älter als drei Jahre sein sollte. Unter Bemerkungen können Sie weitere Informationen zum Fonds eingeben. Nach der Überprüfung des Wertpapiers, durch den Vermögensverwalter, erhalten Sie eine Benachrichtigung per Mail über den Status des Fonds. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }