<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-xl-8">
              <ComboBox
                label="Depotposition"
                v-model="depotPosition" 
                :values="valuesForDepot"
                :disabled="disabled">
              </ComboBox>
            </div>
            <div class="col-12 col-xl-4 mt-3 pl-2">
              <BaseButton :isEmbedded="true" :disabled="!depotPosition" @click="addPosition()">Übertragungsposition hinzufügen</BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="input-forms__input-container">
        <Table v-if="rows.length"
            tableId="46c30c0a-aa2b-4885-b3ef-cfedaeec59tr"
            :headers="headers"
            :rows="rows"
            rowId="isin"
            hidePagination
            @action-DELETE="deletePosition"
        >
            <template v-slot:umfang="row"> 
                <div>
                    <ComboBox 
                        :isEmbedded="true" 
                        :value="row.umfang"
                        :values="comboboxSelections && comboboxSelections['umfang'] || []" 
                        @change="onChangeUmfang($event, row)"
                    />
                    <InputField v-if="positions[row.index].umfang === '3'"
                        type="number"
                        :isEmbedded="true" 
                        :value="positions[row.index].anteile" 
                        :id="'anteile' + row.index"
                        :validateUntouched="true"
                        @change="onChangeAnteile($event, row)"
                    />
                </div>
            </template>
        </Table>
    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from '@/store/antrag/types';
import BaseButton from '@/components/core/BaseButton.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SlotColumn, SimpleAction} from "@/components/table2/table_util.js";
import validator from '@/mixins/validator';
import { required, } from "@/mixins/validator/rules";
const DESERIALIZED_LENGTH = 8;

export default {
  mixins: [antragNavigationMixin, validator],
  validators: {},
  props: {
    antragData: {
    },
    config: {
    },
    antragId: {
    },
    comboboxSelections: {
    },
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false
    },
    warnings: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    BaseButton,
    ComboBox,
    InputField,
    Table,
  },
  data() {
    return {
        depotPosition: '',
        positions: [],
        headers: {
            lockedLeft: [
                TextColumn("position", "Positionsnummer"),
                TextColumn("isin", "ISIN"),
                TextColumn("fondsname", "Fondsname"),
                SlotColumn("umfang", "Umfang")
            ],
            lockedRight: [
                ActionColumn("actions", ),
            ],
        },
    }
  },
  watch: {
    antragData(value) {
      this.updatePositions;
    },
    warnings(value) {
      this.updateWarnings();
    }
  },
  mounted() {
    this.updatePositions;
    this.updateWarnings();
  },
  computed: {
    rows() {
        const actions = [
            SimpleAction("DELETE", '', "Löschen"),
        ];
        return this.positions.map(row => ({
            ...row,
            actions,
        }));
    },
    valuesForDepot() {
      return !this.antragData['INPUT_DEPOTNR_QUELLE'] ? [] : this.comboboxSelections && this.comboboxSelections.depotPositions 
        && this.comboboxSelections.depotPositions[this.antragData['INPUT_DEPOTNR_QUELLE']];
    },
    updatePositions() {
      if (!this.depotPosition) {
        this.depotPosition = this.valuesForDepot.length ? this.valuesForDepot[0].value : '';
      }
      this.positions = this.deserialize(this.antragData['INPUT_DATA_POSITIONEN']);
      // const val = {};
      // this.POSITIONS.FOREACH((pos, index) => val['anteile' + index] = required());
    }
  },
  methods: {
    updateWarnings() {
      if (this.warnings && this.warnings.length && this.positions && this.positions.length) {
        const val = {};
        this.warnings.filter(warn => warn.posGrpId && warn.message)
        .forEach(warn => {
          const index = this.positions.findIndex(item => item.position === warn.posGrpId);
          val[(warn.message.includes('Nennwert') ? 'anteile' : 'umfang') + index] = required(warn.message);
        });
        this.$configureValidators(val);
      }
    },
    addPosition() {
      if (this.depotPosition) {
        this.updateStore((this.antragData['INPUT_DATA_POSITIONEN'] ? this.antragData['INPUT_DATA_POSITIONEN'] + '&' : '') 
          + this.depotPosition);
      }
    },
    deserialize() {
      const data = this.antragData['INPUT_DATA_POSITIONEN'];
      const arr = [];
			if (data && data.length) {
				const splitData = data.split('&');
        const arrLengt = splitData.length / DESERIALIZED_LENGTH;
        for (let i = 0; i < arrLengt; i++) {
          const pos = {
            position: (splitData[i*DESERIALIZED_LENGTH + 1] || '').replace('=', ''),
            isin: (splitData[i*DESERIALIZED_LENGTH + 2] || '').replace('=', ''),
            fondsname: (splitData[i*DESERIALIZED_LENGTH + 7] || '').split('+').join(' ').replace('=', ''),
            umfang: this.getUmfang(splitData[i*DESERIALIZED_LENGTH + 3], splitData[i*DESERIALIZED_LENGTH + 4], splitData[i*DESERIALIZED_LENGTH + 5]),
            anteile: (splitData[i*DESERIALIZED_LENGTH + 3] || '').replace('=', '').replace('%2C', ','),
            index: i,
          }
          arr.push(pos);
        }
      }
      return arr;
    },
    getUmfang(anteile, komplett, aufloesen) {
      if(anteile.replace('=', '') || (komplett.includes('0') && aufloesen.includes('0'))) {
        return '3';
      } else if (komplett.includes('1') && aufloesen.includes('0')) {
        return '2';
      } else {
        return '1';
      }
    },
    onChangeUmfang($event, position) {
      if (position && position.index >= 0 && this.positions.length >= position.index) {
        const data = this.antragData['INPUT_DATA_POSITIONEN'] || '';
        const splitData = data.split('&');
        let komplett = '=', aufloesen = '=';
        switch($event) {
          case '1':
            komplett += '1';
            aufloesen += '1';
            break;
          case '2':
            komplett += '1';
            aufloesen += '0';
            break;
          case '3':
            komplett += '0';
            aufloesen += '0';
            break;
        }
        splitData[position.index*DESERIALIZED_LENGTH + 3] = '=';
        splitData[position.index*DESERIALIZED_LENGTH + 4] = komplett;
        splitData[position.index*DESERIALIZED_LENGTH + 5] = aufloesen;
        this.updateStore(splitData.join('&'));
      }
    },
    onChangeAnteile(value, position) {
      if (position && position.index >= 0 && this.positions.length >= position.index) {
        const data = this.antragData['INPUT_DATA_POSITIONEN'] || '';
        const splitData = data.split('&');
        let anteileStr = '';
        if (value) {
          const arr = ('' + value).split('.');
          if (arr.length > 2) {
            arr.splice(2);
          }
          if (arr.length == 2) {
            let str = arr[1];
            const maxLength = 6
            if (str.length > maxLength) {
              arr[1] = str.substring(0, maxLength);
            } else if (str.length < maxLength) {
              for (let i=str.length; i<maxLength; i++) {
                arr[1] += '0';
              }
            }
          } else {
            arr.push('00000');
          }
          anteileStr = arr.join('%2C')
        }
        splitData[position.index*DESERIALIZED_LENGTH + 3] = '=' + anteileStr;
        this.updateStore(splitData.join('&'));
      }
    },
    deletePosition(position) {
      if (position && position.index >= 0 && this.positions.length >= position.index) {
        const data = this.antragData['INPUT_DATA_POSITIONEN'] || '';
        const splitData = data.split('&');
        const newData = splitData.length == DESERIALIZED_LENGTH ? '' : splitData.splice(position.index*DESERIALIZED_LENGTH, DESERIALIZED_LENGTH).join('&');
        this.updateStore(newData);
      }
    },
    updateStore(data) {
      const payload = {
        id: this.antragId,
        data: {
          INPUT_DATA_POSITIONEN: data || ''
        }
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload);
    },
  }
}
</script>

<style scoped>
  .input-forms__label-content--bigger {
    font-size: 1.5rem;
  }
  .input-forms__label-content--bigger > i {
    font-size: 2rem;
  }
</style>