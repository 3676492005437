<template>
    <div class="input-forms__container">
      <div class="input-forms__label-container">
        <div class="row mt-0">
          <div class="col">
            <div class="input-forms__label-content input-forms__label-content--bigger" v-if="title">
              <ph-bank :size="16" />&nbsp;{{title}}
            </div>
          </div>
        </div>
      </div>

    <div v-if="config.weitereDatenFields">
        <span>{{ weitereDatenGruppenIndex }}</span>
        <div v-for="gruppenIndex in weitereDatenGruppenIndex">
            <div class="col-auto" v-if="config && config.buttonText && (componentType === 'AUSWAHL_WEITERE_DATEN')">
            <BaseButton @click="deleteWeitereDatenGruppe(gruppenIndex)">Schließen</BaseButton>
            <!-- :disabled="disabled" -->
            </div>
            <div v-for="(component, index) in config.weitereDatenFields" :key="index">
                <div class="mt-2" v-if="!isComponentHidden(component, gruppenIndex)"
                :key="refreshKey" 
                :class="{'indented' : component.config && component.config.indented}">
                <!-- :data="data[componentId][componentId + '-' + gruppenIndex][component.id]"-->
                    <AntragComponent
                        :key="component.id + '-' + gruppenIndex"
                        :component="component"
                        :antragId="antragId"
                        :data="data[componentId + '-' + gruppenIndex]"
                        :comboboxSelection="getComboboxSelection(component)"
                        isComponentHalfSizeEnabled
                        :disabled="disabled"
                        @updateStore="updateData($event.data, component, gruppenIndex)"
                        @change="$emit('change', data[componentId + '-' + gruppenIndex])">
                    </AntragComponent>
                    <!-- @change="updateData($event.data, component.id, gruppenIndex)" -->
                    <!-- $emit('change', $event.data) -->
                </div>
            </div>
        </div>
    </div>
    <div class="col-auto" v-if="config && config.buttonText && (componentType === 'AUSWAHL_WEITERE_DATEN') && (weitereDatenGruppenIndex < maxAnzahl)">
        <BaseButton @click="openNewWeitereDatenGruppe()">{{config.buttonText}}</BaseButton>
        <!-- :disabled="disabled" -->
    </div>
  
        <!-- 
        </div>
         <BaseModal
          ref="fehlerModal"
          labelButtonCancel="Ok"
          :showConfirmButton="false"
        >
          <template v-slot:modalTitle>
            <span>
               <ph-warning :size="16" class="color-danger mr-2" />
              <div class="info-modal--content">{{ textInfo }}</div>
           </span>
          </template>
        </BaseModal> -->
      <!-- </div> -->
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import BaseButton from '@/components/core/BaseButton.vue';
  import WertpapierAuswahlPositions from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositions.vue';
  import WertpapierAuswahlPositionFields from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue';
  import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
  import antragMixin from '@/mixins/antrag/antrag-mixin.js';
  import { PhBank, PhWarning } from 'phosphor-vue';
  import BaseModal from "@/components/core/BaseModal.vue";
  import AntragComponent from '@/components/antrag/AntragComponent.vue';
  import { componentValidatorToRule, isHiddenDisabled } from '@/components/antrag/antrag-utils';


  
  export default {
    mixins: [antragNavigationMixin, antragMixin],
    props: {    
      title: {
      },
      antragData: {
      },
      config: {
      },
      categoryId: {
      },
      antragId: {
      },
      componentId: {
      },
      antragTyp: {
      },
      componentType: {
        type: String,
        default: 'WERTPAPIER_AUSWAHL',
      },
      suppressFilterLagerstelle: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      comboboxSelections:{
      }
    },
    data(){
      return{
         textInfo: null,
         weitereDatenMaxGruppenIndex: 0,
         maxAnzahl: 5,
         data: {},
         refreshKey: 0
      }
    },
    components: {
      BaseButton,
      WertpapierAuswahlPositions,
      WertpapierAuswahlPositionFields,
      PhBank,
      PhWarning,
      BaseModal,
      AntragComponent
    },
    computed: {
      ...mapGetters({
      }),

      configType() {
        return this.config && this.config.type
      },
      weitereDatenGruppenIndex(){    
        return this.weitereDatenMaxGruppenIndex
      },
    },
    watch:{
    },
    methods: {
     openNewWeitereDatenGruppe(){
      if(this.weitereDatenMaxGruppenIndex < this.maxAnzahl){
        this.weitereDatenMaxGruppenIndex = this.weitereDatenMaxGruppenIndex + 1;
        let groupKey = this.componentId + "-" + this.weitereDatenMaxGruppenIndex;
        if(!this.data[groupKey]){
            this.data[groupKey] = {}; 
        }

      }
     },
     deleteWeitereDatenGruppe(groupIndex){
        let groupKey = this.componentId + "-" + groupIndex
        if(this.data[groupKey]){
            delete this.data[groupKey]
            Object.keys(this.data).filter((key) => key.includes(this.componentId + "-")).forEach((key) => {
                let keyNumber = Number(key.split("-")[1]);
                if(keyNumber > groupIndex){
                    let newKeyNumber = keyNumber - 1
                    this.data[this.componentId + "-" + newKeyNumber] = this.data[this.componentId + "-" + keyNumber]
                    delete this.data[this.componentId + "-" + keyNumber]
                }
            });
            this.weitereDatenMaxGruppenIndex = this.weitereDatenMaxGruppenIndex - 1;
        }
     },
     isComponentHidden(component, groupIndex) {
      return component && component.hidden && isHiddenDisabled(component.hidden, this.data[this.componentId + "-" + groupIndex])
    },
    getComboboxSelection(component) {
      if (this.comboboxSelections && component && component.id && this.comboboxSelections[component.id]) {
        return this.comboboxSelections[component.id]
      }
      return []
    },
    updateData(value, subComponent, groupIndex) {
        let groupKey = this.componentId + "-" + groupIndex

        if(!this.data[groupKey]){
            this.data[groupKey] = {}; 
        }
        if(!this.data[groupKey][subComponent.id]){
            this.data[groupKey][subComponent.id] = null
        }
        this.data[groupKey][subComponent.id] = value[subComponent.id];
        this.data[this.componentId + "-Anzahl"] = this.weitereDatenGruppenIndex;
        //this.$emit('change', this.data[groupKey])
           this.refreshKey += 1



                // if(!this.data[this.componentId]){
        //   this.data[this.componentId] = {}; 
        // }
        // if(!this.data[this.componentId][groupKey]){
        //     this.data[this.componentId][groupKey] = {}; 
        // }
        // if(!this.data[this.componentId][groupKey][subComponentId]){
        //     this.data[this.componentId][groupKey][subComponentId] = null
        // }
        // this.data[this.componentId][groupKey][subComponentId] = value[subComponentId];
        // this.data[this.componentId]["anzahl"] = this.weitereDatenGruppenIndex;
        
        // this.data[subComponentId + "-" + groupIndex] = value[subComponentId];
        // this.data[this.componentId + "-Anzahl"] = this.weitereDatenGruppenIndex;
    },
    },
    mounted(){
        // let components = this.data
        // if(Object.keys(components).filter(key => key.includes(this.componentId + "-")).length){
        //     this.weitereDatenMaxGruppenIndex = Object.keys(components).filter(key => key.includes(this.componentId + "-")).length
        //     this.weitereDatenMaxGruppenIndex = this.data[this.componentId + "-Anzahl"]
        // }
        this.data = this.antragData
        this.weitereDatenMaxGruppenIndex = this.data[this.componentId + "-Anzahl"] || 0
        if(this.config.maxAnzahl){
          this.maxAnzahl = this.config.maxAnzahl
        }
    }
  }
  </script>
  
  <style scoped>
  
  </style>