var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseDropdownMenu", {
    attrs: {
      placement: "bottom-right",
      containerClass: "global-search-help--dropdown",
      closeWithInnerClick: false
    },
    scopedSlots: _vm._u([
      {
        key: "hook-target",
        fn: function() {
          return [
            _c(
              "button",
              {
                staticClass: "btn-clear clickable no-line-height",
                attrs: { type: "button" }
              },
              [_c("PhQuestion", { attrs: { size: 20 } })],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "global-search-help--text" },
              [
                _vm.isCustomerOnly
                  ? [
                      _c("p", [
                        _vm._v(
                          "Hier haben Sie Zugriff auf viele Bereiche der FinanceCloud."
                        )
                      ])
                    ]
                  : [
                      _c("p", [
                        _vm._v(
                          " Hier haben Sie Zugriff auf viele Bereiche des smartMSC. Sie können z. B. nach " +
                            _vm._s(_vm.availableFeatures) +
                            " suchen. "
                        )
                      ])
                    ],
                _c(
                  "div",
                  { staticClass: "global-search-help--info text-small" },
                  [
                    _c(
                      "div",
                      { staticClass: "global-search-help--info-icon" },
                      [_c("PhInfo", { attrs: { size: 18 } })],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "global-search-help--info-text" },
                      [
                        _vm._v(
                          " Die Suche ist interaktiv und beginnt bereits nach wenigen Zeichen die verschiedenen Bereiche zu durchsuchen. "
                        )
                      ]
                    )
                  ]
                )
              ],
              2
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }