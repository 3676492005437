var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button forms__input--padding",
      class: _vm.classObject,
      attrs: { "aria-label": "button", type: _vm.type, disabled: _vm.disabled },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _vm._t("default", function() {
        return [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.$sanitize(_vm.label)) }
          })
        ]
      }),
      _vm.animated ? _c("AnimatedSpinner", { staticClass: "ml-1" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }