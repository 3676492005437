<template>
    <BaseModal ref="banksapiwebcomponentmodal" :showDefaultButtons="false" :modalTitle="modalTitle" size="lg" :esc="false"
        @opened="injectUrl()" :showCloseButton="false">
        <div class="bareg__container">
            <ba-regprotect ref="baRegElement" @subscribed="handleEvent" />
        </div>
    </BaseModal>
</template>
<script>
import '@/components/multiBanking/ba-regprotect_e5724172-606a-434a-99cf-7fa507ccac6f_2_0_0.js';
import BaseModal from '@/components/core/BaseModal.vue';


export default {
    components: {
        BaseModal
    },
    data: {
        loading: false,
    },
    computed: {
    },
    props: { //currently callee has to ensure, that the link is available upon mounting this webcomponent
        banksapiURL: {
            type: String | null, //link is usually null before Modal is being shown
            required: true,
        },
        modalTitle: {
            type: String,
            default: "Multibanking"
        }
    },
    emits: ['subscribed'],
    methods: {
        async open() {
            this.$refs.banksapiwebcomponentmodal.open();

        },
        injectUrl() {
            this.$refs.baRegElement.sessionLink = this.banksapiURL;
        },
        handleEvent(event) {
            this.$emit('subscribed', event);
            this.closeModal();
        },
        closeModal() {
            this.$refs.banksapiwebcomponentmodal.close();
        }
    }
}
</script>
<style lang="scss">
.bareg__container {
    background-color: #FFFFFF;
    color: #424242; //Color that banksapi uses for text
    padding: 1rem;
}
</style>