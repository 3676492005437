export const MUTATION_PREFIX = 'AFFILIATE: ';
export const ACTIONS_PREFIX = 'AFFILIATE_ACTIONS_';
export const GETTERS_PREFIX = 'AFFILIATE_GETTERS_';

export default {
  MUTATIONS: {
    GET_ANTRAG_SUCCESS: MUTATION_PREFIX + 'GET_ANTRAG_SUCCESS',
  },
  ACTIONS: {
    GET_ANTRAG: ACTIONS_PREFIX + 'GET_ANTRAG',
    SAVE_ANTRAG: ACTIONS_PREFIX + 'SAVE_ANTRAG'
  },
  GETTERS: {
    ANTRAG: GETTERS_PREFIX + 'ANTRAG',
  }
}
