var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          id: "scenario.title",
          label: "Name des Scenarios",
          value: _vm.scenario.title,
          validateUntouched: ""
        },
        on: {
          input: function($event) {
            return _vm.doChanges("title", $event)
          }
        }
      }),
      _c("InputTextArea", {
        attrs: { label: "Notizen", value: _vm.scenario.bemerkung, rows: 5 },
        on: {
          input: function($event) {
            return _vm.doChanges("bemerkung", $event)
          }
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          value: _vm.scenario.ohneKapitalverzehr,
          label: "Rente ohne Kapitalverzehr",
          inLineLabel: ""
        },
        on: {
          change: function($event) {
            return _vm.doChanges("ohneKapitalverzehr", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Monatlicher Bedarf in Euro in Rente (nach heutigem Geldwert)",
          value: _vm.scenario.bedarfMonatlich,
          type: "currency",
          disabled: _vm.scenario.ohneKapitalverzehr
        },
        on: {
          change: function($event) {
            return _vm.doChanges("bedarfMonatlich", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Geschätzte Renteneinnahmen pro Monat",
          value: _vm.scenario.renteMonatlichErwartet,
          type: "currency"
        },
        on: {
          change: function($event) {
            return _vm.doChanges("renteMonatlichErwartet", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Rendite im Ruhestand",
          value: _vm.scenario.renditeRuhestand,
          type: "percent"
        },
        on: {
          change: function($event) {
            return _vm.doChanges("renditeRuhestand", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Restkapital bei Tod",
          value: _vm.scenario.restKapital,
          type: "currency",
          precision: 0,
          disabled: _vm.scenario.ohneKapitalverzehr
        },
        on: {
          change: function($event) {
            return _vm.doChanges("restKapital", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Annahme durchschnittliche Inflation",
          value: _vm.scenario.inflation,
          type: "percent"
        },
        on: {
          change: function($event) {
            return _vm.doChanges("inflation", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }