import { PrimaryMenu, MenuItem, GroupMenu, TabMenu, } from './../menu-utils';

import { PhUsers } from 'phosphor-vue';

import UndrawLogin from '@/components/icons/undraw/UndrawLogin.vue'
import UndrawDetailedAnalysis from '@/components/icons/undraw/UndrawDetailedAnalysis.vue'
import UndrawRunnerStart from '@/components/icons/undraw/UndrawRunnerStart.vue'
import UndrawCreativity from '@/components/icons/undraw/UndrawCreativity.vue'
import UndrawGoogleAnalytics from '@/components/icons/undraw/UndrawGoogleAnalytics.vue'
import UndrawSecureServer from '@/components/icons/undraw/UndrawSecureServer.vue'
import UndrawWallet from '@/components/icons/undraw/UndrawWallet.vue'
import UndrawContainerShip from '@/components/icons/undraw/UndrawContainerShip.vue'

export default PrimaryMenu('/service', 'Service', PhUsers, [
  MenuItem('/service/externe-zugaenge', 'Externe Zugänge', UndrawLogin),
  MenuItem('/service/fondsvergleich', 'FondsVergleich', UndrawDetailedAnalysis),
  MenuItem('/service/wertpapierinfo', 'Wertpapierinfo'),
  MenuItem('/service/meineziele', 'Meine Ziele', UndrawRunnerStart),
  // MenuItem('/service/ruhestandsplanung-old', 'Ruhestandsplanung alte Version', UndrawGoogleAnalytics),
  MenuItem('/service/ruhestandsplanung-alt', 'Financial planning alte Version', UndrawGoogleAnalytics),
  MenuItem('/service/financial-planing', 'Financial planning', UndrawGoogleAnalytics),
  GroupMenu('/service/gesellschaften', 'Gesellschaften', UndrawCreativity, [
    MenuItem('/service/gesellschaften/FONDSGESELLSCHAFTEN', 'Fondsgesellschaften', UndrawWallet),
    MenuItem('/service/gesellschaften/BETEILIGUNGEN', 'Beteiligung', UndrawContainerShip),

    MenuItem('/service/gesellschaften/VERSICHERUNGEN_FP_SERVICE_GMBH', 'FP+ Service GmbH Versicherungen', UndrawSecureServer),
    MenuItem('/service/gesellschaften/VERSICHERUNGEN_FP_GMBH', 'FP+ GmbH Versicherungen', UndrawSecureServer),
    MenuItem('/service/gesellschaften/VERSICHERUNGEN_FP_GEWERBE_GMBH', 'FP+ Gewerbe GmbH Versicherungen', UndrawSecureServer),

    MenuItem('/service/gesellschaften/VERSICHERUNGEN', 'Versicherungen', UndrawSecureServer),

    MenuItem('/service/gesellschaften/BANKEN_PLATTFORMEN', 'Banken/Plattformen', UndrawWallet),
  ]),
  MenuItem('/service/limit', 'Limits', null, [
    TabMenu('/service/limit/depotlimit', 'Depotlimit'),
    TabMenu('/service/limit/depotpositionlimits', 'Depotpositionslimits'),
    TabMenu('/service/limit/automatic-depotpositionlimits', 'Automatische Depotpositionslimits'),
    TabMenu('/service/limit/risikoklassenlimit', 'Risikoklassenlimit'),
    TabMenu('/service/limit/wertpapierkategorie-limits', 'Wertpapierkategorie Limits'),
  ]),
  MenuItem('/service/berichte/steuer-daten', 'Steuerliche Daten'),
  MenuItem('/service/vertragsspiegel', 'Vertragsspiegel'),
  MenuItem('/service/datenschutzkundenaenderungen', 'Änderungshistorie Datenschutz-Erklärung'),
]);
