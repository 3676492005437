<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="col">
        <div
          class="input-forms__label-content input-forms__label-content--bigger"
        >
          <ph-bank :size="24" />&nbsp; Tausch Von:
        </div>
      </div>
      
      <ComboBox
        class="mt-3 pl-2"
        isComponentHalfSize
        :value="antragData['INPUT_DEPOTPOSITION_AUSWAHL']"
        :values="depots"
        :sortComboboxValues="false"
        @input="setDepotpositionVerkauf($event)"
      />

      <div class="col">
        <div
          class="input-forms__label-content input-forms__label-content--bigger"
        >
          <ph-bank :size="24" />&nbsp; Tausch In:
        </div>
      </div>

      <ComboBox
        class="mt-3 pl-2"
        isComponentHalfSize
        v-model="bgsNrKauf"
        :values="depots"
        :sortComboboxValues="false"
      />
    </div>

      <BaseButton
        class="mt-3"
        label="Tausch hinzufügen"
        :disabled="!canAddPosition || bgsNrKauf == '' || bgsNrKauf === depotVerkauf.value"
        @click="addTausch"
      />
      <BaseButton
        class="mt-3"
        label="zur Fondsauswahl"
        :disabled="!canAddPosition"
        @click="openFormsFinder"
      />
      <BaseButton
        class="mt-3"
        label="Neue Depotposition"
        :disabled="!canAddPosition"
        @click="splitPosition"
      />

    <div class="input-forms__input-container mt-3">
      <Table
        v-if="rows && rows.length"
        :headers="headers"
        :rows="rows"
        rowId="index"
        hidePagination
        @action-DELETE="openModalDeleteFond"
      >
        <template v-slot:betrag="row">
          <InputField
            type="currency"
            :precision="2"
            :value="row.betrag"
            :id="'betrag' + (row.posGrpId || row.index)"
            @change="onChangeInputField($event, row, 'betrag')"
          />
        </template>
        <template v-slot:bemerkung="row">
          <InputField
            :value="row.bemerkung"
            :id="'bemerkung' + row.index"
            @change="onChangeInputField($event, row, 'bemerkung')"
          />
        </template>
      </Table>
      <div v-if="config && config.positionFields && positions && positions.length">
        <WertpapierAuswahlPositionFields
          :config="config"
          :categoryId="categoryId"
          :antragId="antragId"
          :disabled="disabled"
        />
      </div>
    </div>

    <DeletePositionModal
      ref="deleteModal"
      :position="positionToDelete"
      @delete="doDeletePosition()"
    ></DeletePositionModal>
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import BaseButton from "@/components/core/BaseButton.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import BaseModal from "@/components/core/BaseModal.vue";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  ActionColumn,
  SlotColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import WertpapierAuswahlPositionFields from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import { mapGetters } from "vuex";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import { PhWarning, PhBank } from "phosphor-vue";
import validator from "@/mixins/validator";

export default {
  mixins: [antragMixin, antragNavigationMixin, validator],
  validators: {},
  props: {
    title: {},
    antragData: {},
    config: {},
    antragTyp: {},
    antragId: {},
    comboboxSelections: {},
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false,
    },
    positionWarnings: [],
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      positionToDelete: null,
      headers: {
        lockedLeft: [
          TextColumn("nameVerkauf", "Depotposition"),
          TextColumn("isinVerkauf", "ISIN"),
          SlotColumn("betrag", "Betrag", 100),
        ],
        center: [
          TextColumn("isin", "ISIN").makeHidden,
          TextColumn("isinKauf", "Tausch in"),
          TextColumn("nameKauf", "Fondsname"),
          SlotColumn("bemerkung", "Bemerkung"),
        ],
        lockedRight: [ActionColumn("actions", "")],
      },
      categoryId: 'KAUF', 
      additionalIdentifier: ['isinVerkauf', 'bgsNrVerkauf', 'bgsNrKauf'],
      bgsNrKauf: '',
    };
  },
  components: {
    BaseButton,
    BaseModal,
    ComboBox,
    InputField,
    Table,
    DeletePositionModal,
    WertpapierAuswahlPositionFields,
    PhWarning,
    PhBank,
  },
  watch: {
    positionWarnings(newValue) {
      this.updateWarnings();
    }
  },
  mounted() {
    this.updateWarnings();
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
    }),
    depots() {
      return this.antragData
        ? JSON.parse(this.antragData["INPUT_DEPOTPOSTIONEN"])
        : [];
    },
    positions() {
      if (
        this.positionsAll &&
        this.antragId &&
        this.positionsAll[this.antragId]
      ) {
        let result = this.positionsAll[this.antragId]["SPARPLAN"] || [];
        result = result.concat(this.positionsAll[this.antragId]["KAUF"] || []);

        if (result.length) {
          result.map((pos, index) => {
            Object.assign(pos, {
              index: index,
              isin: this.getIsin(pos),
              isinKauf: this.getIsin(pos),
              nameKauf: this.getFondsName(pos),
              fondsname: this.getFondsName(pos),
              betrag: pos.betrag,
              isinVerkauf:
                pos.isinVerkauf ||
                this.isinVerkauf ||
                "",
              nameVerkauf: this.getNameVerkauf(pos),
              lagerstelle: "EBASE",
            });
          });
        }
        return result;
      }
    },
    rows() {
      const actions = [SimpleAction("DELETE", "", "Löschen")];
      return (this.positions || []).map((row) => ({
        ...row,
        actions,
      }));
    },
    depotVerkauf() {
      return this.depots.find((x) => x.value == this.antragData["INPUT_DEPOTPOSITION_AUSWAHL"]) || {}
    },
    isinVerkauf() {
      return this.depotVerkauf.isin || '';
    },
    canAddPosition() {
      return !this.disabled && this.isinVerkauf !== '' && this.rows.length < 5
    },
  },
  methods: {
    openFormsFinder() {
      this.config.SINGLE_SELECTION = true;
      if (!this.canAddPosition) {
        return
      }

      this.goToFormsFinder(
        'TAUSCH',
        Object.assign(this.config || {}, {query: JSON.stringify({
          additionalIdentifier: this.additionalIdentifier, 
          isSparplan: true,
          isinVerkauf: this.depotVerkauf?.isin,
          bgsNrVerkauf: this.depotVerkauf?.value,
        })}),
        this.suppressFilterLagerstelle
      );
    },
    addTausch() {
      const depotKauf = this.depots.find((x) => x.value == this.bgsNrKauf) || {}
      const depotVerkauf = this.depotVerkauf

      if (!depotKauf || !depotVerkauf || depotKauf.value === depotVerkauf.value) {
        return
      }

      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        additionalIdentifier: this.additionalIdentifier,
        fonds: [
          {
            isin: depotKauf.isin,
            art: this.categoryId,
            isinKauf: depotKauf.isin,
            isinVerkauf: depotVerkauf.isin,
            bgsNrKauf: depotKauf.value,
            bgsNrVerkauf: depotVerkauf.value,
            istVWL: false,
            lagerstelle: "EBASE",
            nameKauf: depotKauf.fondsname,
            nameVerkauf: depotVerkauf.fondsname,
            fondsname: null,
            typ: "EBASE_FONDSUMSCHICHTUNG",
            bemerkung: null,
            betrag: null,
            anlagezielZeile: null,
          },
        ],
      };

      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    splitPosition() {
      const depotVerkauf = this.depotVerkauf

      if (!depotVerkauf) {
        return
      }

      const isin = depotVerkauf.isin;
      const fondsname = depotVerkauf.fondsname;

      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        additionalIdentifier: this.additionalIdentifier,
        fonds: [
          {
            isin: isin,
            art: this.categoryId,
            isinKauf: isin,
            isinVerkauf: isin,
            bgsNrVerkauf: depotVerkauf.value,
            istVWL: false,
            lagerstelle: "EBASE",
            nameKauf: fondsname,
            nameVerkauf: fondsname,
            fondsname: fondsname,
            typ: "EBASE_FONDSUMSCHICHTUNG",
            bemerkung: null,
            betrag: null,
            anlagezielZeile: null,
          },
        ],
      };

      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    getFondsName(pos) {
      return (
        pos.nameKauf ||
        (this.positionInfo &&
          this.positionInfo[pos.isin] &&
          this.positionInfo[pos.isin].wertpapiername) ||
        pos.fondsname ||
        pos.wertpapiername
      );
    },
    getIsin(pos) {
      return (
        pos.isinKauf ||
        (pos &&
          this.positionInfo &&
          this.positionInfo[pos.isin] &&
          (this.isWkn ? this.positionInfo[pos.isin].wkn : pos.isin))
      );
    },
    getNameVerkauf(pos) {
      return pos.nameVerkauf || this.depotVerkauf.fondsname
    },
    setDepotpositionVerkauf(bgsNr) {
      const payload = {
        id: this.antragId,
        data: {
          INPUT_DEPOTPOSITION_AUSWAHL: bgsNr,
        },
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload);
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          {
            ...positionChange,
            isin,
          },
        ],
        additionalIdentifier: this.additionalIdentifier,
      };

      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    onChangeInputField(value, position, field) {
      const positionChange = { ...position, [field]: value === "" ? null : value };
      this.commitUpdatePositionsFromEvent(positionChange, position.isin);
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open();
    },
    doDeletePosition() {
      if (this.positionToDelete) {
        const deletePayload = {
          id: this.antragId,
          categoryId: this.categoryId,
          isin: this.positionToDelete.isin,
          posGrpId: this.positionToDelete.posGrpId,
          isinVerkauf: this.positionToDelete.isinVerkauf,
          bgsNrVerkauf: this.positionToDelete.bgsNrVerkauf,
          bgsNrKauf: this.positionToDelete.bgsNrKauf,
          additionalIdentifier: this.additionalIdentifier,
        }
        this.$store.dispatch(ANTRAG_TYPES.ACTIONS.DELETE_POSITION, deletePayload);
      }

      this.positionToDelete = null;
    },
    updateWarnings() {
      this.positionWarnings?.forEach(warn =>  {
        const position = this.positions.find(pos => warn.posGrpId === pos.posGrpId);       
        if (position?.posGrpId) {            
          const path = `betrag${position.posGrpId}`;
          this.$pushErrors(path, ['Betrag ist zu groß']);
        }
      });
    },
  },
};
</script>
