<template>
    <div>
        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.parent.label"
            :subtitle="$appNavigation.currentMenu.label"
            :defaultMenu="$appNavigation.currentTabMenu"
            :actions="actions"
            @action-RESET="doConfirm('zurücksetzen')"
        />

        <div v-if="!loading && limits && limits.length">
            <div v-for="(limit, index) of limits" :key="'limit' + index" class="box__container">
                <div class="box__title" v-if="limit.depot">{{limit.depot}}</div>
                    <div v-if="!isCustomerView">
                        <a @click="openCustomerNewTab(limit)"><span>{{limit.kundenLinkName}}</span>
                        <span> (Kundennr {{limit.kundennr}})</span></a>
                    </div>

                <template>
                    <Table
                        tableId="11fcafaf-5332-48db-8ba2-9e5ba7259281"
                        :headers="headers"
                        :rows="limit.rows"
                        :rowsPerPage="20"
                        :mobileConfig="{title: 'kategorieStr', headers: ['status', 'wert', 'unteresLimit', 'oberesLimit']}"
                        rowId="id"
                        hidePagination
                        @action-new="editLimit(limit)"
                        @action-edit="editLimit(limit)"
                        @action-delete="selectedLimit=limit; $refs.confirmDelete.open()"
                        @action-reset="selectedLimit=limit; $refs.confirmReset.open()"
                    >
                    </Table>
                </template>
            </div>
        </div>
        <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" useBoxContainer />
        <NoData v-else :title="TABLE_TITLE" useBoxContainer />

        <BaseModal ref="confirmDelete" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onCancelButton="selectedLimit = null" 
            @onCloseButton="selectedLimit = null" 
            @onConfirmButton="removeLimit()"
        >
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>Löschen
            </template>
            <div>Wollen Sie wirklich das Limit löschen?</div>
        </BaseModal>
        <BaseModal ref="confirmReset" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein"
            @onCancelButton="selectedLimit = null" 
            @onCloseButton="selectedLimit = null" 
            @onConfirmButton="resetLimit()"
        >
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>Zurücksetzen
            </template>
            <div>Wollen Sie wirklich das Limit zurücksetzen?</div>
        </BaseModal>
    </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, PercentageColumn, CurrencyColumn, DateColumn, IconColumn, Icon, SimpleAction} from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import LIMIT_TYPES from '@/store/limit/types';
import mixin from '@/mixins/limit/limit-mixin.js';
import { PhCheck, PhWarning } from "phosphor-vue";
import BaseModal from '@/components/core/BaseModal.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'

const TABLE_TITLE = 'Wertpapierkategorie Limits';

export default {
    mixins: [mixin],
    components: {
        Table,
        BaseModal,
        PhWarning,
        PageHeaderTitleNavigation,
        GhostLoading,
        NoData,
        PageHeaderSimpleAction,
    },
    mounted() {
        this.getList();
    },
    data() {
        return {
            TABLE_TITLE,
            loading: false,
            selectedLimit: null,
        }
    },
    computed: {
        ...mapGetters({
            wertpapierkategorieLimits: LIMIT_TYPES.GETTERS.WERTPAPIERKATEGORIE_LIMIT_LIST,
        }),
        actions() {
            if (this.isCustomerOrigin) {
                return [];
            }
            return [
                // PageHeaderSimpleAction('REMOVE', 'Überschrittene Limits löschen'),
                PageHeaderSimpleAction('RESET', 'Überschrittene Limits zurücksetzen'),
            ]
        },
        headers() {
            const headers = { 
                lockedLeft: [
                    IconColumn("status", "Status", 80).makeSortable(cell => cell?.length && cell[0].title),
                    TextColumn("kategorieStr", "Wertpapierkategorien").makeAlwaysVisible(),
                    CurrencyColumn("wert", "akt. Wert", 2).withSumFooter(),
                ],
                center: [
                    PercentageColumn("verteilung", "Aktuelle Verteilung", 4),
                    PercentageColumn("ausgangswert", "Ausgangswert in %", 2),
                    PercentageColumn("abweichungUnten", "Untere Abweichung", 2),
                    PercentageColumn("abweichungOben", "Obere Abweichung", 2),
                    CurrencyColumn("unteresLimit", "Unteres Limit", 2).setColorFn((row) => { return row?.wert < row?.unteresLimit ? 'color-danger' : ''}),
                    CurrencyColumn("oberesLimit", "Oberes Limit", 2).setColorFn((row) => { return row?.wert > row?.oberesLimit ? 'color-danger' : ''}),
                    DateColumn("dateOutOfLimit", "Datum der Überschreitung"),
                ],
                lockedRight: [
                    ActionColumn("actions", ""),
                ],
            };
            return headers;
        },
        limits() {
            const result = !this.wertpapierkategorieLimits ? [] : this.wertpapierkategorieLimits.map((limit) => {
                const actionsExist = [
                    SimpleAction("edit", '', "Limit bearbeiten"), 
                    SimpleAction("reset", '', "Limit zurücksetzen"),
                    SimpleAction("delete", '', "Limit löschen")];
                const actionsNew = [
                    SimpleAction("new", '', "Limit anlegen")]
                return {
                    ...limit,
                    rows: limit.rows?.map((row, index) => {
                        return {...row,
                            status: row.statusStr && [row.statusStr === 'ok'
                                ? Icon(PhCheck, "Ok", 20, 'bold', 'color-success')
                                : Icon(PhWarning, "Warnung", 20, 'bold', 'color-danger')
                            ] || '',
                            id: index,
                            actions: row.statusStr ? actionsExist : actionsNew,
                        }
                    }),
                };
            });
            return result;
        },
    },
    methods: {
        editLimit(event) {
            this.$addBreadcrumb({
                label: `zurück zu den Wertpapierkategorie Limits`,
                fullPath: this.$route.fullPath,
                breadcrumb: 'Wertpapierkategorie Limit',
            });
            this.$router.push(`${this.mainPath}/wertpapierkategorie-limit?kundennr=${event?.kundennr  || ''}&depotId=${event?.depotId  || ''}&kontoNr=${event?.kontoNr  || ''}`)
        },
        async resetLimit() {
            if (this.selectedLimit) {
                await this.$store.dispatch(LIMIT_TYPES.ACTIONS.RESET_WERTPAPIERKATEGORIE_LIMIT, 
                    { kundennr: this.selectedLimit.kundennr || '', depotId: this.selectedLimit.depotId || '', kontoNr: this.selectedLimit.kontoNr || ''});
                
                this.selectedLimit = null;
                this.getList();
            }
        },
        async removeLimit() {
            if (this.selectedLimit) {
                await this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_WERTPAPIERKATEGORIE_LIMIT, 
                    { kundennr: this.selectedLimit.kundennr || '', depotId: this.selectedLimit.depotId || '', kontoNr: this.selectedLimit.kontoNr || ''});
                
                this.selectedLimit = null;
                this.getList();
            }
        },
        async getList() {
            try {
                this.loading = true;
                await this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_WERTPAPIERKATEGORIE_LIMIT_LIST);
            } finally {
                this.loading = false;
            }
        },
        async doConfirm(exec) {
            await this.doConfirmAction(exec, 'wertpapierkategorieLimitList');
        },
    }
}
</script>
