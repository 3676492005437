var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          subtitle: _vm.subtitle,
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.actions
        },
        on: {
          "action-PDF": _vm.getPdf,
          "action-EDIT": function($event) {
            return _vm.$refs.einstellungenModal.open()
          }
        }
      }),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c(
                "div",
                { staticClass: "box__title" },
                [
                  _c("CurrencyLabel", {
                    attrs: {
                      label: "Vermögen heute:",
                      showInlineLabel: "",
                      value: _vm.gesamtStartVermoegenCurrentScenario
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "font-bold" }, [
                _vm._v("Alter bei Beginn Ruhestand")
              ]),
              _vm.alterList && _vm.alterList.length
                ? _c("InputSlider", {
                    attrs: {
                      value: _vm.scenario.alterRente + "",
                      values: _vm.alterList,
                      disabled: _vm.isCalculating
                    },
                    on: {
                      input: function($event) {
                        return _vm.doChanges("alterRente", +$event)
                      }
                    }
                  })
                : _vm._e(),
              !_vm.scenario.ohneKapitalverzehr
                ? _c(
                    "div",
                    { staticClass: "box__title" },
                    [
                      _c("CurrencyLabel", {
                        attrs: {
                          label:
                            "Wunschrente (zu Rentenbeginn berüksichtig Inflation):",
                          showInlineLabel: "",
                          value: _vm.wunschrenteMitInflation(_vm.scenario)
                        }
                      })
                    ],
                    1
                  )
                : _c("div", { staticClass: "box__title" }, [
                    _vm._v(
                      "Monatliche Rente aus Kapitalvermögen (sieh Zahlungsplan)"
                    )
                  ])
            ],
            1
          ),
          _c("div", { staticClass: "dashboard-panel__widgets" }, [
            _c(
              "div",
              { staticClass: "box__container dashboard-panel-widget" },
              [
                _c(
                  "div",
                  { staticClass: "layout__negative-margin--8 pb-3 text-right" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: { isLink: "", disabled: _vm.isCalculating },
                        on: {
                          click: function($event) {
                            return _vm.openEventModal({
                              zuflussType: "AUSGABE"
                            })
                          }
                        }
                      },
                      [_vm._v(" Ausgabe hinzufügen ")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        attrs: { isLink: "", disabled: _vm.isCalculating },
                        on: {
                          click: function($event) {
                            return _vm.openEventModal({
                              zuflussType: "EINNAHME"
                            })
                          }
                        }
                      },
                      [_vm._v(" Einnahme hinzufügen ")]
                    )
                  ],
                  1
                ),
                _vm.rowsEvents && _vm.rowsEvents.length
                  ? _c("Table", {
                      attrs: {
                        title: "Meine Einahmen und Verbindlichkeiten",
                        rowId: "id",
                        headers: _vm.headersMyEvents,
                        rows: _vm.rowsEvents,
                        rowsPerPage: 10,
                        hidePagination: "",
                        mobileConfig: {},
                        selected: _vm.selectedEvents
                      },
                      on: {
                        selected: _vm.onSelectedEvent,
                        "action-EDIT": _vm.editEvent,
                        "action-DELETE": _vm.deleteAction,
                        "click-title": _vm.editEvent
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "chart",
                            fn: function(row) {
                              return [
                                _c("PieChart", {
                                  staticStyle: { width: "40px" },
                                  attrs: {
                                    chartData: _vm.eChartData(row),
                                    customColors: _vm.eChartColors,
                                    isTooltip: false,
                                    height: "40px",
                                    doughnut: ""
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        197734938
                      )
                    })
                  : _c("NoData", { attrs: { title: "Meine Ziele" } }),
                _vm.hasVermoegenOrAusgaben ? _c("GesamtVermoegen") : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box__container dashboard-panel-widget" },
              [
                _c(
                  "div",
                  { staticClass: "mt-3 mb-3" },
                  [
                    _c("RetirementChart", {
                      attrs: {
                        data: {
                          data: _vm.apexSeriesCurrentScenario,
                          series: _vm.apexSeriesCurrentScenario,
                          xaxis: _vm.apexXaxisCurrentScenario,
                          annotations: _vm.apexAnnotationsCurrentScenario,
                          markers: _vm.apexMarkers,
                          dataLabels: { enabled: false },
                          stroke: { curve: "smooth" },
                          autoUpdateSeries: true
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "box__container dashboard-panel-widget" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Ihre Einstellungen")
                ]),
                _c("Table", {
                  ref: "scenarioOverviewSettingsTable",
                  attrs: {
                    tableId: "dac58861-120a-4f32-0cc6-f55f487e86a4",
                    rowId: "name",
                    hidePagination: "",
                    headers: _vm.headersOverview,
                    rows: _vm.rowsSettings
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "value",
                      fn: function(row) {
                        return [
                          _c("div", { staticClass: "font-bold text-right" }, [
                            _vm._v(_vm._s(_vm.formatValue(row.value, row.type)))
                          ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            !_vm.isRenteOk
              ? _c(
                  "div",
                  { staticClass: "box__container dashboard-panel-widget" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Aktuelle Situation")
                    ]),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.aktuelleSituationText1)
                      }
                    }),
                    _c("div", {
                      staticClass: "mb-2",
                      domProps: {
                        innerHTML: _vm._s(_vm.aktuelleSituationText2)
                      }
                    })
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "box__container dashboard-panel-widget" },
              [
                _c("Table", {
                  ref: "scenarioOverviewCalcTable",
                  attrs: {
                    tableId: "scenarioOverviewCalcTable",
                    rowId: "name",
                    hidePagination: "",
                    headers: _vm.headersOverview,
                    rows: _vm.rowsCalc
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "value",
                      fn: function(row) {
                        return [
                          _c("div", { staticClass: "font-bold text-right" }, [
                            _vm._v(
                              _vm._s(_vm.formatValue(row.value, row.type, 0))
                            )
                          ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            !_vm.isRenteOk
              ? _c(
                  "div",
                  { staticClass: "box__container dashboard-panel-widget" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Lösungsmöglichkeit 1: Höhere Sparrate")
                    ]),
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.loesungSparrateText) }
                    })
                  ]
                )
              : _vm._e(),
            !_vm.isRenteOk
              ? _c(
                  "div",
                  { staticClass: "box__container dashboard-panel-widget" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Lösungsmöglichkeit 2: Höhere Rendite")
                    ]),
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.loesungRenditeText) }
                    })
                  ]
                )
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                attrs: { showSection: _vm.showExpanded1 },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v(" Berechnung benötiges Vermögen ")
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _vm.tilgungsplanVermoegenBenoetigt
                          ? _c("Table", {
                              attrs: {
                                rowId: "jahr",
                                headers: _vm.headersTilgungsplan,
                                rows: _vm.tilgungsplanVermoegenBenoetigt,
                                rowsPerPage: 50,
                                mobileConfig: {}
                              }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                attrs: { showSection: _vm.showExpanded2 },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v(" Zahlungsplan")
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _vm.tilgungsplanRowsCurrentScenario
                          ? _c("Table", {
                              attrs: {
                                rowId: "jahr",
                                headers: _vm.headersTilgungsplan,
                                rows: _vm.tilgungsplanRowsCurrentScenario,
                                rowsPerPage: 50,
                                mobileConfig: {}
                              }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "goalModal",
              attrs: {
                modalTitle: _vm.goalModalTitle,
                labelButtonConfirm: "Speichern",
                confirmDisabled: !_vm.canSaveEvent
              },
              on: { onConfirmButton: _vm.saveEvent }
            },
            [
              _c("EditForm", {
                ref: "editForm",
                attrs: {
                  goal: _vm.selectedEvent,
                  comboboxEventTypes: _vm.comboboxEventTypes
                },
                on: { isValid: _vm.updateCanSaveEvent }
              })
            ],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "einstellungenModal",
              attrs: {
                modalTitle: "Einstellungen",
                labelButtonConfirm: "Speichern",
                showCancelButton: false
              },
              on: { close: _vm.saveFinancialPlaning }
            },
            [_c("Einstellungen")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }