<template>
  <div class="box__container">
    <BaseButton @click="openAddModal()" v-if="!isSmallScreen">Neue Anpassung</BaseButton>

    <Table
        v-if="!loading && rows.length"
        tableId="55fdd0ca-5612-45dd-901e-293393ce8cbc"
        hidePagination
        :headers="headers"
        :rows="rows"
        @click-datum="openEditModal"
        @action-DELETE="deleteUpdate"
        :mobileConfig="{}"
    />
    <GhostLoading v-else-if="loading" type="table"/>
    <NoData v-else />

    <BaseModal
      ref="anpassungDataModal"
      size="sm"
      modalTitle="Wert anpassen"
      :actions="baseModalActions"
       @action-SPEICHERN="actionSpeichern()"    
      :showDefaultButtons="false"
    >
      <template #default>
        <DatePickerField
          isValueAsString
          label="Datum"
          v-model="update.datum"
          :validateUntouched="true"
        />
        <InputField
          label="akt. Darlehenssumme"
          type="currency"
          v-model="update.aktWert"
          :validateUntouched="true"
        />
        <InputTextArea
          label="Bemerkung"
          v-model="update.bemerkung"
          :validateUntouched="true"
        />
      </template>
      <template #footer>
        <BaseButton isSecondary @click="closeAnpassungDataModal()" class="mr-3">Abbrechen</BaseButton>
        <BaseButton :disabled="validation.isInvalid('update')" @click="saveUpdate(); closeAnpassungDataModal();">Speichern</BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CREDIT_TYPES from '@/store/credit/types';

import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, CurrencyColumn, ActionColumn, ConfirmedAction} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';

import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';
import CORE_TYPES from '@/store/core/types';

import EVENT_BUS, { ANPASSUNGEN_NEUE_ANPASSUNG } from '@/event-bus';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';


export default {
  mixins: [validator],
  data() {
    return {
      loading: false,
      update: {},
    };
  },
  computed: {
    ...mapGetters({
      updates: CREDIT_TYPES.GETTERS.UPDATES,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
    }),
    baseModalActions() {
        const actions =  [];

        if (this.isSmallScreen) {
          actions.push(new BaseModalSimpleAction('SPEICHERN', 'Speichern'));
        }

        return actions;
    },
    headers() {
        return { 
            lockedLeft: [
                DateColumn('datum', 'Datum').makeLink(),
                TextColumn('bemerkung', 'Bemerkung'),
                CurrencyColumn('aktWert', 'akt. Darlehenssumme'),
            ],
            center: [
            ],
            lockedRight: [
              ActionColumn('actions', 'Aktionen')
            ],
        }
    },
    rows() {
        return this.updates.map(row => ({
          ...row,
          actions: [ConfirmedAction("DELETE", '', "Löschen", "Soll die Anpassung wirklich gelöscht werden?", "Anpassung löschen", "Löschen"),]
        }));
    },
  },
  methods: {
    actionSpeichern() {
      this.saveUpdate(); 
      this.closeAnpassungDataModal();
    },      
    registerEventBus() {
      EVENT_BUS.$on(ANPASSUNGEN_NEUE_ANPASSUNG, () => {
          this.openAddModal();
      });

    },
    unregisterEventBus() {
      EVENT_BUS.$off(ANPASSUNGEN_NEUE_ANPASSUNG);
    },    
    async findUpdates() {
      this.loading = true;
      await this.$store.dispatch(CREDIT_TYPES.ACTIONS.FIND_UPDATES);
      this.loading = false;
    },
    openAnpassungDataModal() {
      this.$refs.anpassungDataModal.open();
    },
    closeAnpassungDataModal() {
      this.$refs.anpassungDataModal.close();
    },
    openAddModal() {
      this.update = {};
      this.openAnpassungDataModal();
    },
    openEditModal(update) {
      this.update = { ...update };
      this.openAnpassungDataModal();
    },
    async saveUpdate() {
      await this.$store.dispatch(CREDIT_TYPES.ACTIONS.SAVE_UPDATE, this.update);
    },
    deleteUpdate({id}) {
      this.$store.dispatch(CREDIT_TYPES.ACTIONS.DELETE_UPDATE, { 
        updateId: id, 
      });
    },
  },
  mounted() {
    this.findUpdates();
    this.registerEventBus();
  },
  destroyed() {
      this.unregisterEventBus();
  },  
  validators: {
    update: {
      datum: [required()],
      bemerkung: [required()],
    }
  },
  components: {
    BaseButton,
    Table,
    GhostLoading,
    NoData,
    BaseModal,
    InputField,
    DatePickerField,
    InputTextArea,
  },
}
</script>
