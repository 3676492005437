/**
 * These are the possible pending actions. 
 * 
 * The key (e.g checkPrivacy, changePassword) is tested
 */
export default {
  checkPrivacy: {
    name: 'Check privacy terms',
    description: 'You need to check the privacy terms',
    path: '/privacy-terms'
  },
  changePassword: {
    name: 'Change password',
    description: 'You need to change the password',
    path: '/change-password'
  },
  kundeInteressenkonflikt: {
    name: 'Zusatzinformation Interessenkonflikt',
    description: 'You need to confirm this terms',
    path: '/conflict-of-interest'
  },
  negativerklaerung: {
    name: 'Zuverlässigkeitserklärung',
    messageBody: 'Zuverlässigkeitserklärung für den Geschäftsführer/Berater',
    description: 'You need to confirm this terms',
    path: '/negativerklaerung'
  },
  FAjahrescheckliste: {
    name: 'Jahrescheckliste',
    messageBody: 'Sehr geehrter Vertriebspartner, die Jahrescheckliste steht zum Ausfüllen zur Verfügung. Sie haben ein Monat Zeit, dies abzuschließen, da sonst alle Funktionen im smartMSC deaktiviert werden.',
    description: 'Jahrescheckliste',
    path: '/jahrescheckliste'
  },
  facsimile: {
    name: 'Fehlendes Facsimile',
    messageBody: 'Fehlendes Facsimile',
    description: 'You need to confirm this terms',
    path: '/intern/ansprechpartner-daten?stepKey=facsimile'
  },
  kundenzugangUndKundenApp: {
    name: 'Kundenzugang und Kunden-App versenden',
    description: 'Erleichtern Sie sich den Arbeitsaufwand beim Verschicken der Kundenzugänge an Ihre Kunden.',
    messageBody: 'Erleichtern Sie sich den Arbeitsaufwand beim Verschicken der Kundenzugänge an Ihre Kunden.',
    path: '/kundenzugang-und-kundenapp'
  },
  kundendatenAusfuellen: {
    name: 'auszufüllende Kundendaten',
    description: 'auszufüllende Kundendaten',
    messageBody: 'Es gibt Kundendaten, die ausgefüllt werden müssen. Bitte klicken Sie hier, um die Stammdaten zu öffnen.',
    path: '/persoenlichedaten/customer-data/steps/stammdaten'
  },
  marketingCampaigns: {
    name: 'Marketing Campaign',
    messageBody: 'You have a message',
    path: '/home/show-marketing-campaign'
  },
  haftungserklaerung: {
    name: 'Haftungserklärung',
    messageBody: 'Haftungserklärung',
    description: 'You need to confirm this terms',
    path: '/intern/haftungserklaerung'
  },
}
