<template>
  <div class="checkbox__container" :class="{'checkbox__check--disabled': disabled}">
    <!-- Only the checkbox input and check visual are wrapped by a label -->
    <label aria-label="Checkbox" class="checkbox__clickable">
      <span class="checkbox__body">
        <input type="checkbox" 
               :indeterminate="indeterminate"
               :checked="checked"
               :disabled="disabled"
               :id="id"
               @input="onChange($event)">
        <span class="checkbox__check"></span>
      </span>
    </label>
    <!-- The label text is rendered outside the clickable area -->
    <span v-if="label" class="checkbox__label" v-html="sanitize(label)"></span>
  </div>
</template>

<script>
import { getBooleanValue } from '@/helpers/mapping'
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import { sanitize } from '@/helpers/string-helper.js';

export default {
  mixins: [validatorComponentUtils],
  name: 'InputCheckbox',
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Boolean, String, Object, Array],
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    internalValue: null
  }),
  computed: {
    checked() {
      const value = this.internalValue;
      const isPositiveRegex = /(1|j[a]?)/gi;
      return [1, true].some(available => available === value) || isPositiveRegex.test(value);
    }
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    onChange(event) {
      const checked = event.target.checked;
      const newValue = getBooleanValue(this.internalValue, checked);
      this.internalValue = newValue;
      
      this.$emit('input', this.internalValue);
      this.$runValidator(this.internalValue);
      this.$setDirty();
    },
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
      this.$runValidator(this.internalValue);
    },
    disabled() {
      if (this.disabled) {
        this.$reset();
      } else { 
        this.$runValidator(this.internalValue);
      }
    }
  },
  mounted() {
    this.internalValue = this.value;
    this.$runValidator(this.internalValue, true);
  }
}
</script>

<style scoped>
.checkbox__container {
  box-sizing: border-box;
  display: flex;
}

/* Styling for the clickable part of the checkbox */
.checkbox__clickable {
  display: flex;
  margin: .0625em .375em 0 0;
}

.checkbox__body input {
  opacity: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.checkbox__check {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.125em;
  height: 1.125em;
  border: 1px solid var(--color-text);
  border-radius: 4px;
}

.checkbox__body input:focus + .checkbox__check,
.checkbox__body input:focus-visible + .checkbox__check,
.checkbox__body input:focus:not([tabindex^='-']) + .checkbox__check {
  outline: var(--focus-outline);
  outline-offset: 1px;
}

.checkbox__body {
  flex: 0 0 1.125em;
  width: 1.125em;
  height: 1.125em;
  position: relative;
}

.checkbox__check:before {
  display: none;
  content: "";
  border: solid var(--color-text);
  margin-left: auto;
  margin-right: auto;
}

.checkbox__body input[indeterminate] + .checkbox__check:before {
  margin-top: calc(50% - .0625em);
  width: .5em;
  height: .125em;
  border-width: 2px 0 0 0;
}

.checkbox__body input:checked + .checkbox__check:before {
  margin-top: calc(50% - (.25em + 2px));
  width: .1875em;
  height: .5em;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox__body input:checked + .checkbox__check:before,
.checkbox__body input[indeterminate] + .checkbox__check:before {
  display: block;
}

.checkbox__body input:focus + .checkbox__check,
.checkbox__body input:enabled + .checkbox__check:hover {
  outline: none;
}

.checkbox__check--disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>