<template>
  <div class="box__container">
    <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
    <Table 
      v-else
      :title="TABLE_TITLE"
      :rows="rows"
      :headers="headers"
      :rowsPerPage="25"
      @click-subject="editVorgang"
      @action-DELETE_BERICHT="deleteBericht"
      @action-COPY_BESUCHSBERICHT="copyBericht"
      @action-DELETE_BEMERKUNG="deleteBemerkung"
      @action-EDIT="editVorgang"
      @action-PDF="showPDF"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import CORE_TYPES from '@/store/core/types';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import { TextColumn, DateColumn, ActionColumn, SimpleAction, ConfirmedAction, PillColumn} from "@/components/table2/table_util.js";
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';
import vermittlerDatenMixin from "@/views/intern/vermittler-daten-mixin";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

const TABLE_TITLE = 'Besuchsberichte und Bemerkungen';
const VALUE_TYP = "makler";
const config = {
  defaultSpinner: true
};

export default {
  mixins: [vermittlerDatenMixin],
  components: {
    GhostLoading,
    Table,
  },
  data() {
    return {
      loading: false,
      TABLE_TITLE,
    }
  },
  computed: {
    ...mapGetters({
      besuchsberichte: BROKERDATA_TYPES.GETTERS.BESUCHSBERICHTE,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
    }),
    headers() {
      return {
        lockedLeft: [
          PillColumn("type", "Typ"),
          DateColumn("startzeit", "Datum"),
          TextColumn("uhrzeit_von", "Von"),
          TextColumn("uhrzeit_bis", "Bis"),
          TextColumn("subject", "Thema").makeLink(),
        ],
        center: [
          TextColumn("ort", "Ort"),
          TextColumn("text", "Text"),
          TextColumn("anwesende", "Anwesende"),
          TextColumn("erstellerId", "Ersteller Id"),
          TextColumn("erstellerTyp", "Ersteller Typ"),
        ],
        lockedRight: [
          ActionColumn('actions')
        ],
      }
    },
    rows() {
      const besuchsberichte = this.besuchsberichte || []
      return besuchsberichte.map(row => ({
        ...row,
        subject: row.thema || "[Kein Betreff]",
        text: this.getCleanText(row.text || '')?.trim(),
        type: {
          label: row.typ === 'BEMERKUNG' ? 'Bemerkung' : 'Besuchsbericht',
          type: 'info',
        },
        actions: this.makeActions(row),
      }))
    },
  },
  mounted() {
    this.loadBesuchsberichte();
  },
  methods: {
    makeActions(row) {
      let actions = []
      if (row.typ === 'BEMERKUNG') {
        if (row.id) {
          actions.push(SimpleAction('PDF', '', 'PDF anzeigen'))
          actions.push(SimpleAction('EDIT', '', 'Bearbeiten'))
        }
        if (row.deletable) {
          actions.push(ConfirmedAction('DELETE_BEMERKUNG', '', 'Löschen', `Soll die Bemerkung "${row.betreff || '[kein Thema]'}" wirklich gelöscht werden?`, 'Bemerkung löschen', 'Löschen'))
        }
      } else {
        actions = [
          SimpleAction('COPY_BESUCHSBERICHT', '', 'Kopieren'),
          SimpleAction('EDIT', '', 'Bearbeiten'),
          ConfirmedAction('DELETE_BERICHT', '', 'Löschen', `Soll der Besuchsbericht "${row.thema || '[Kein Betreff]'}" wirklich gelöscht werden?`, 'Besuchsbericht löschen', 'Löschen')
        ]
      }

      return actions
    },
    async loadBesuchsberichte() {
      try {
        this.loading = true;
        await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_BESUCHSBERICHTE);
      } finally {
        this.loading = false;
      }
    },
    editVorgang(row) {
      if (row.typ === 'BEMERKUNG') {
        this.$router.push(this.$route.path + `/bemerkung/${row.id}?typ=${VALUE_TYP}`)
      } else {
        this.$router.push(this.$route.path + `/besuchsbericht/${row.id}`)
      }
    },
    async deleteBemerkung({ id }) {
      const params = makeQueryParam({ id, typ: VALUE_TYP });
      const resp = await axios.delete(`${process.env.VUE_APP_API}/postfachBemerkung/bemerkung?${params}`, config);
      if (resp.statusText?.charAt(0) == '2') {
        this.delRow(id);
      }
    },
    async deleteBericht({ id }) {
      const params = makeQueryParam({ id })
      const resp = await axios.delete(`${process.env.VUE_APP_API}/berichte/bericht?${params}`, config);
      if (resp.statusText?.charAt(0) == '2') {
        this.delRow(id);
      }
    },
    delRow(id) {
        const index = this.besuchsberichte.findIndex(record => record.id === id)
        if (index >= 0) {
          this.besuchsberichte.splice(index, 1)
        }
    },
    showPDF({id}) {
      const href =`${process.env.VUE_APP_API}/postfachBemerkung/bemerkung-pdf?id=${id}&typ=${VALUE_TYP}&token=${this.loginData.token}`;
      viewDocument({href, filename: 'Bemerkung.pdf', contentType: 'application/pdf'});
    },
    copyBericht({ id }) {
      const params = makeQueryParam({ id })

      axios.get(`${process.env.VUE_APP_API}/berichte/copyBericht?${params}`, config)
      .then(() => this.loadBesuchsberichte())
    },
    getCleanText(text) {
      var div = document.createElement("div");
      div.innerHTML = text;
      return div.textContent || div.innerText || "";
    },
  },
};
</script>
