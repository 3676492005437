<template>
<div>
    <Table v-if="rows.length"
        tableId="2544554d-72be-493f-993a-4ec9defi7010"
        :title="title"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        :exportConfig="exportConfig"
        :mobileConfig="{title: 'name', headers: ['vorname', 'typ', 'unternr']}"
        rowId="personId"
        @click-name="editPersonenDaten($event)"
        @action-EDIT="editPersonenDaten"
        @action-OPEN="openPersonInNewTab"
        @action-DELETE="deletePerson"
    />
    <NoData v-else :title="title" />
  </div>
</template>

<script>
import BROKERDATA_TYPES from '@/store/brokerData/types'
import CORE_TYPES from '@/store/core/types'

import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, PillColumn, ActionColumn, SimpleAction, PhoneColumn} from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';

import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

export default {
    mixins: [InteractiveHelpCommonsMixin],
    props:{
        title: {
            type: String,
        },
        maklerAnsprechpartner: {
            type: Array,
            default: () => [],
        },
    },
    components: {
      Table,
      NoData,
    },
    data() {
        return {
            headers: {
                lockedLeft: [
                    TextColumn("unternr", "Nr"),
                    TextColumn("typ", "Typ"),
                    TextColumn("name", "Name").makeLink().makeAlwaysVisible(),
                    TextColumn("vorname", "Vorname"),
                    PillColumn("status", "Status"),
                ],
                center: [
                    PhoneColumn("telefon", "Telefon", 'Untermakler', 'personId'),
                    PhoneColumn("mobil", "Mobil", 'Untermakler', 'personId'),
                    TextColumn("beschreibung", "Beschreibung"),
                    TextColumn("gutBeratenId", "Gut beraten ID"),
                    DateColumn("zuverlaessigkeitserklaerungsdatum", "Zuverlässigkeitserklärung"),
                    TextColumn("telefonpasswort", "Telefonpasswort"),
                ],
                lockedRight: [
                    ActionColumn("actions"),
                ]
            },
            exportConfig: {
              pdf: true, 
              xls: true, 
              name: 'Ansprechpartner', 
              title: 'Ansprechpartner',
              dispatch: null,
            },
        }
    },
    computed: {
        rows() {
            return (this.maklerAnsprechpartner || []).map(row => {
              const actions = [
                SimpleAction("EDIT", '', "Mitarbeiter editieren"),
                SimpleAction("OPEN", '', "Mitarbeiter öffnen"),
              ]
              if (row.deleteAllowed) {
                actions.push(SimpleAction("DELETE", '', "Löschen"))
              }

              const status = row.inaktiv ? {label: "inaktiv", type: "danger"} : {label: "aktiv", type: "info"}

              return {
                  ...row,
                  status,
                  name: row.name || 'Unbekannt',
                  actions
              }
            })
        },
  },
  methods: {
    editPersonenDaten(person) {
      const { personId, } = person || {};
      if(!personId) return;

      let path = `/intern/ansprechpartner/${personId}`;

      if (this.$route.path !== path) {
        this.$router.push({
          path: path,
        });
      }
    },
    openPersonInNewTab(person) {
      const { unternr, } = person;
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_MITARBEITERZUGANG, { unternr, });
    },
    deletePerson(row) {
      this.$confirmModal({
          title: 'Ansprechpartner löschen',
          message: `Soll der Ansprechpartner '${((row.vorname || '') + ' ' + (row.name || '')).trim()}' wirklich gelöscht werden?`,
          labelButtonCancel: 'Abbrechen',
          labelButtonConfirm: 'Löschen',
        }).then(() => {
          this.$emit('delete', row.personId)
        })
    },
  },
}
</script>