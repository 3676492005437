<template>
    <DragnDropArea hoverText="Datei hier ablegen" :disabled="isSomethingLoading" @files="dropFiles($event)">
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

        <PageHeaderTitleNavigation
            :id="$appNavigation.currentOptionMenuId"
            :actions="headerActions"
            title="Besuchsbericht"
            @action-UPLOAD_DOCUMENT="uploadNewDocument"
            @action-SEND="sendBericht()"
            @action-CREATE_TASK="editOrCreateAppointment"
        />

        <StepperForm
            ref="stepperForm"
            stepType="tab"
            stepperName="customer-data"
            :stepperMediator="tabsStepperMediator"
            :hasFertig="false"
            :selectedStepKey="currentStep"
            @set-step="setStep($event)"
        >
            <template #contentTemplate>
                <GhostLoading v-if="loading.init" useBoxContainer type="table" />
                <template v-else>
                    <template v-if="currentStep === 'BERICHT'">
                        <div class="box__container">
                            <div class="box__title">Besucher</div>
                            <div class="besucher__grid">
                                <InputCheckboxItem
                                    v-for="bs in besucherSelection"
                                    :key="bs.value"
                                    :id="bs.value"
                                    :label="bs.label"
                                    :value="selectedBesucher.includes(bs.value)"
                                    @input="updateBericht(bs.value, $event)"
                                />
                            </div>
                        </div>
                        <div class="box__container">
                            <DatePickerField
                                label="Datum"
                                isComponentHalfSize
                                isValueAsString
                                v-model="besuchsbericht.datum"
                            />
                            <TimePicker label="Uhrzeit" v-model="besuchsbericht.startTime" />
                            <TimePicker label="bis" v-model="besuchsbericht.endTime" />
                            <InputField label="Ort" v-model="besuchsbericht.ort" />
                            <InputField label="Anwesende" v-model="besuchsbericht.anwesende" />
                            <InputField label="Thema/Betreff" v-model="besuchsbericht.thema" />
                            <template v-if="!isGesellschaft">
                                <InputRadioBoxGroup
                                    title="Kontakt"
                                    :values="[
                                        {label: 'Erst', value: 'Erstkontakt'},
                                        {label: 'Folge', value: 'Folgekontakt'},
                                    ]"
                                    v-model="besuchsbericht.kontakt"
                                />
                                Termin
                                <InputToggleSwitch
                                    label="Telefon"
                                    inLineLabel
                                    v-model="besuchsbericht.isTelefontermin"
                                />
                                <InputToggleSwitch label="Außen" inLineLabel v-model="besuchsbericht.isAussentermin" />
                                <InputToggleSwitch label="Online" inLineLabel v-model="besuchsbericht.isOnlinetermin" />
                                <div class="parameter__grid">
                                    <InputCheckboxItem label="Makler §93" v-model="besuchsbericht.para_93_makler" />
                                    <InputCheckboxItem label="§34 c GewO" v-model="besuchsbericht.para_34_c_gew" />
                                    <InputCheckboxItem
                                        label="§34 f Abs.1 Satz 1 Nr.1"
                                        v-model="besuchsbericht.para_34_f_Abs1"
                                    />
                                    <InputCheckboxItem
                                        label="§34 f Abs.1 Satz 1 Nr.2"
                                        v-model="besuchsbericht.para_34_f_Abs2"
                                    />
                                    <InputCheckboxItem
                                        label="§34 f Abs.1 Satz 1 Nr.3"
                                        v-model="besuchsbericht.para_34_f_Abs3"
                                    />
                                    <InputCheckboxItem label="§34 h GewO" v-model="besuchsbericht.para_34_h_gew" />
                                    <InputCheckboxItem label="Mehrfachagent §84" v-model="besuchsbericht.para_84_mfa" />
                                    <InputCheckboxItem label="§34 d Abs.1" v-model="besuchsbericht.para_34_gew" />
                                    <InputCheckboxItem label="§34 d Abs.2" v-model="besuchsbericht.para_34_d_abs2" />
                                    <InputCheckboxItem label="§32 KWG" v-model="besuchsbericht.para_32_kwg" />
                                </div>
                                <InputCheckboxItem
                                    label="Interesse haftungsdach/Bereits bei"
                                    v-model="besuchsbericht.interesse_hd"
                                />
                                <InputField
                                    v-if="besuchsbericht.interesse_hd"
                                    v-model="besuchsbericht.interesse_hd_bei"
                                />
                            </template>

                            <!-- This text area is leftover from old entries. Due to it not being used anymore it still shows the information when filled but cannot be used for new entries. -->
                            <InputTextArea
                                v-if="besuchsbericht.aufgaben"
                                label="Aufgaben"
                                v-model="besuchsbericht.aufgaben"
                            />

                            <div>
                                <GhostLoading v-if="loading.init" type="table" :config="{table: {rows: 10}}" />
                                <PaginatedTable
                                    v-else
                                    title="Aufgaben"
                                    tableId="015eb9cf-6e4f-4dca-b9e3-52f0b49736bd"
                                    rowId="rowid"
                                    v-model="selectedRows"
                                    :headers="tasksHeaders"
                                    :page="selectedPage"
                                    :pages="pages"
                                    :pageCount="pageCount"
                                    :rowCount="rowCount"
                                    :headerActions="taskTableHeaderActions"
                                    :exportConfig="exportConfig"
                                    :mobileConfig="mobileConfig"
                                    @page="selectedPage = $event"
                                    @requestPage="loadPage"
                                    @sort="onSearchSort"
                                    :sorted="searchSort"
                                    @onScroll="onScroll"
                                    @scrollLoading="scrollLoading = $event"
                                    :selectionAsBlacklist="invertSelection"
                                    @selectionAsBlacklist="invertSelection = $event"
                                    @click-subject="editOrCreateAppointment"
                                    @click-bereich="clickBereich"
                                    @action-DONE="doneRow"
                                    @action-DELETE="deleteRow"
                                    @headerAction-BULK_DELETE="deleteSelected"
                                    @headerAction-BULK_DONE="doneSelected"
                                    @headerAction-CREATE_TASK="editOrCreateAppointment"
                                >
                                    <template v-slot:participants="row">
                                        <ul style="padding: 0; margin: 0">
                                            <li
                                                style="display:inline-block;"
                                                v-for="(participant, index) in row.participants"
                                                :key="participant.id"
                                            >
                                                <a
                                                    v-if="
                                                        participant.isKunde &&
                                                            canOpenCustomer(participant) &&
                                                            !row.mobileTableContext
                                                    "
                                                    @click="openCustomerNewTab(participant.nummer)"
                                                    >{{ participant.name }}</a
                                                >
                                                <span v-else>{{ participant.name }}</span>
                                                <span v-if="index < row.participants.length - 1">;&nbsp;</span>
                                            </li>
                                        </ul>
                                    </template>
                                </PaginatedTable>
                            </div>

                            <InputTextArea label="Kurzbericht" v-model="besuchsbericht.text" :rows="8" />
                        </div>
                    </template>
                    <template v-else-if="currentStep === 'UNTERNEHMEN'">
                        <div class="box__container">
                            <InputField label="Partner-Nr" v-model="besuchsbericht.partner_nr" />
                            <InputField label="Gesprächspartner" v-model="besuchsbericht.gespraechspartner" />
                            <InputField label="Funktion" v-model="besuchsbericht.funktion" />
                        </div>
                        <div class="box__container">
                            <div class="box__title">Hintergrundinformationen zum Unternehmen</div>
                            <InputField label="Anzahl Innendienst-Mitarbeiter" v-model="besuchsbericht.anzahl_innen" />
                            <InputField label="Anzahl Außendienst-Mitarbeiter" v-model="besuchsbericht.anzahl_aussen" />
                        </div>
                        <div class="box__container">
                            <div class="box__title">Geschäftsschwerpunkte</div>
                            <InputToggleSwitch label="Investment" inLineLabel v-model="besuchsbericht.sp_investment" />
                            <InputField
                                v-if="besuchsbericht.sp_investment"
                                label="Bisher Eingereicht bei"
                                v-model="besuchsbericht.sp_investment_bei"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_investment"
                                label="Bestandshöhe"
                                type="number"
                                :precision="2"
                                v-model="besuchsbericht.sp_investment_hoehe"
                            />

                            <InputToggleSwitch
                                label="Versicherungen"
                                inLineLabel
                                v-model="besuchsbericht.sp_versicher"
                            />

                            <InputToggleSwitch
                                label="Sachgeschäft"
                                inLineLabel
                                v-model="besuchsbericht.sp_sachgeschf"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_sachgeschf"
                                label="Bisher Eingereicht bei"
                                v-model="besuchsbericht.sp_sachgeschf_bei"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_sachgeschf"
                                label="Bestandshöhe"
                                type="number"
                                :precision="2"
                                v-model="besuchsbericht.sp_sachgeschf_hoehe"
                            />

                            <InputToggleSwitch
                                label="Personengeschäft"
                                inLineLabel
                                v-model="besuchsbericht.sp_persongeschf"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_persongeschf"
                                label="Bisher Eingereicht bei"
                                v-model="besuchsbericht.sp_persongeschf_bei"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_persongeschf"
                                label="Bestandshöhe"
                                type="number"
                                :precision="2"
                                v-model="besuchsbericht.sp_persongeschf_hoehe"
                            />

                            <InputToggleSwitch
                                label="Geschlossene Beteiligungen"
                                inLineLabel
                                v-model="besuchsbericht.sp_geschlbeteil"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_geschlbeteil"
                                label="Bisher Eingereicht bei"
                                v-model="besuchsbericht.sp_geschlbeteil_bei"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_geschlbeteil"
                                label="Bestandshöhe"
                                type="number"
                                :precision="2"
                                v-model="besuchsbericht.sp_geschlbeteil_hoeh"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_geschlbeteil"
                                label="jährl. Zeichnungssumme"
                                v-model="besuchsbericht.sp_geschlbeteil_sum"
                            />

                            <InputToggleSwitch label="Finanzierungen" inLineLabel v-model="besuchsbericht.sp_finanz" />
                            <InputField
                                v-if="besuchsbericht.sp_finanz"
                                label="Bisher Eingereicht bei"
                                v-model="besuchsbericht.sp_finanz_bei"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_finanz"
                                label="Bestandshöhe"
                                type="number"
                                :precision="2"
                                v-model="besuchsbericht.sp_finanz_hoehe"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_finanz"
                                label="jährl. Finanzierungsvolumen"
                                v-model="besuchsbericht.sp_finanz_summ"
                            />

                            <InputToggleSwitch label="Immobilien" inLineLabel v-model="besuchsbericht.sp_immob" />
                            <InputField
                                v-if="besuchsbericht.sp_immob"
                                label="Schwerpunkte"
                                v-model="besuchsbericht.sp_immob_sp"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_immob"
                                label="jährl. vermittelt"
                                v-model="besuchsbericht.sp_immob_jvermittelt"
                            />

                            <InputToggleSwitch label="Bausparen" inLineLabel v-model="besuchsbericht.sp_bauspar" />
                            <InputField
                                v-if="besuchsbericht.sp_bauspar"
                                label="Bisher Eingereicht bei"
                                v-model="besuchsbericht.sp_bauspar_bei"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_bauspar"
                                label="Bestandshöhe"
                                type="number"
                                :precision="2"
                                v-model="besuchsbericht.sp_bauspar_hoehe"
                            />
                            <InputField
                                v-if="besuchsbericht.sp_bauspar"
                                label="jährl. vermittelte BSS"
                                v-model="besuchsbericht.sp_bauspar_jbss"
                            />

                            <InputToggleSwitch
                                label="Übertragungsschreiben"
                                inLineLabel
                                v-model="besuchsbericht.uebertragung"
                            />
                            <InputField
                                v-if="besuchsbericht.uebertragung"
                                label="ausgehändigt für:"
                                v-model="besuchsbericht.uebertragungaushand"
                            />

                            <InputToggleSwitch
                                label="Interesse an Vermögensverwaltung/dachfonds"
                                inLineLabel
                                v-model="besuchsbericht.interesseverm"
                            />

                            <InputToggleSwitch label="VSH" inLineLabel v-model="besuchsbericht.vshchekc" />
                            <InputField v-if="besuchsbericht.vshchekc" label="bei" v-model="besuchsbericht.vsh" />
                        </div>
                        <div class="box__container">
                            <div class="box__title">Schulung MSC</div>
                            <InputRadioBoxGroup
                                title="Kontakt"
                                :values="[
                                    {label: 'Komplett', value: 'Komplett'},
                                    {label: 'Teilbereich', value: 'teilbereich'},
                                ]"
                                v-model="besuchsbericht.schulungmsc"
                            />
                            <InputField label="andere gewünschte Schulungen" v-model="besuchsbericht.schulungand" />
                            <InputField label="genutzte Tools" v-model="besuchsbericht.genutztool" />
                            <InputField label="Interessen/Hobby des Partners" v-model="besuchsbericht.hobby" />
                            <InputField label="Wechselgrund zu FondsKonzept" v-model="besuchsbericht.wechselgrund" />
                            <InputField label="Verbesserungsvorschläge" v-model="besuchsbericht.verbessvsl" />
                            <InputField label="Einschätzung Potential" v-model="besuchsbericht.einschaetz" />
                            <InputTextArea label="Persönlicher Eindruck" v-model="besuchsbericht.eindruck" />
                            <InputRadioBoxGroup
                                style="margin-bottom: 4px"
                                title="Folgetermin vereinbart:"
                                :values="[{label: 'Ja, am', value: 'Ja'}]"
                                v-model="besuchsbericht.terminvb"
                            />
                            <DatePickerField
                                v-if="besuchsbericht.terminvb === 'Ja'"
                                label="Stand"
                                isValueAsString
                                v-model="besuchsbericht.termin"
                            />
                            <InputRadioBoxGroup
                                :values="[
                                    {label: 'Nein', value: 'Nein'},
                                    {label: 'Besuchsfrequenz', value: 'Besuchsfrequenz'},
                                ]"
                                v-model="besuchsbericht.terminvb"
                            />
                        </div>
                    </template>
                    <template v-else-if="currentStep === 'DOKUMENTE'">
                        <div class="box__container">
                            <InputToggleSwitch
                                label="Gelöschte anzeigen"
                                inLineLabel
                                :value="showDeleted"
                                @input="toggleShowDeleted"
                            />
                            <Table
                                title="Dokumente Verträge"
                                :headers="headers"
                                :rows="rows"
                                :rowsPerPage="10"
                                @action-DELETE="deleteDocument"
                            >
                                <template v-slot:filename="row">
                                    <ph-trash
                                        :size="16"
                                        class="color-danger mr-1 icon"
                                        style="vertical-align: sub"
                                        v-if="row.deleted"
                                    />
                                    <DownloadLink
                                        :title="row.filename"
                                        :filename="row.filename"
                                        downloadServicePath="/get_simple_file"
                                        :queryParams="{
                                            fileId: row.id,
                                        }"
                                    />
                                </template>
                            </Table>
                        </div>
                    </template>
                </template>
            </template>
        </StepperForm>

        <DokumentUploadModal
            ref="uploadModal"
            disableMultifile
            disableFotoUpload
            v-model="files"
            @upload="uploadDocuments"
        >
            <InputField label="Bezeichnung" v-model="fileData.bezeichnung" />
            <InputTextArea label="Bemerkung" v-model="fileData.bemerkung" />
            <DatePickerField label="Stand" isValueAsString v-model="fileData.dateStand" />
        </DokumentUploadModal>
    </DragnDropArea>
</template>

<script>
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue"
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue"
import {
    PageHeaderConfirmedAction,
    PageHeaderSimpleAction,
} from "@/components/core/header-title-navigation/page-header-utils"

import Table from "@/components/table2/Table.vue"
import GhostLoading from "@/components/core/loading/GhostLoading.vue"
import {PhTrash} from "phosphor-vue"

import axios from "axios"
import {
    TextColumn,
    SlotColumn,
    ActionColumn,
    ConfirmedAction,
    DateColumn,
    CurrencyColumn,
    PillColumn,
} from "@/components/table2/table_util.js"
import {StepperMediator} from "@/components/stepper/utils"
import StepperForm from "@/components/stepper/StepperForm.vue"
import InputField from "@/components/core/forms/InputField.vue"
import InputTextArea from "@/components/core/forms/InputTextArea.vue"
import TimePicker from "@/components/core/forms/TimePicker.vue"
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue"
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue"
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue"

import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue"
import DokumentUploadModal from "@/views/gesellschaft/intern/DokumentUploadModal.vue"
import DownloadLink from "@/components/core/download/DownloadLink.vue"
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue"
import dayjs from "dayjs"
import {BASE_AXIOS_CONFIG as config} from "@/configs/http-request-interceptor"
import {buildMessage} from "@/helpers/log-message-helper"
import LOG_TYPES from "@/store/log/types"
import CALENDAR_TYPES from "@/store/calendar/types"
import COMMUNICATION_TYPES from "@/store/communication/types"
import postfachTableMixin from "@/views/communication/postfach/tables/postfach-table-mixin.js"
import PaginatedTable from "@/components/table2/PaginatedTable.vue"

const LINK_ACTIONS = ["VersVertrag", "Kunde", "Makler"]
const ROWS_PER_PAGE_DEFAULT = 20

export default {
    mixins: [postfachTableMixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        Table,
        GhostLoading,
        StepperForm,
        InputField,
        InputTextArea,
        TimePicker,
        DatePickerField,
        DokumentUploadModal,
        DownloadLink,
        DragnDropArea,
        InputRadioBoxGroup,
        InputToggleSwitch,
        InputCheckboxItem,
        PhTrash,
        PaginatedTable,
    },
    data() {
        return {
            loading: {
                init: false,
                save: false,
            },
            currentStep: "BERICHT",
            besuchsbericht: {},
            originalBesuchsbericht: {},
            fileData: {},
            files: [],
            showDeleted: false,
            besucherSelection: [],

            mailboxLabel: "Aufgabe", // This is used for the postfach mixing
            category: "aufgaben",
            mobileConfig: {
                title: "name",
                headers: ["subject", "date", "participants", "type", "status"],
            },
            searchSort: {},
            invertSelection: false,
            pageList: {},
            rowCount: 0,
            ROWS_PER_PAGE_DEFAULT,
        }
    },
    computed: {
        tabs() {
            return [
                {stepKey: "BERICHT", label: "Besuchsbericht", totalProgress: 1},
                ...(!this.isGesellschaft
                    ? [{stepKey: "UNTERNEHMEN", label: "Unternehmensdaten", totalProgress: 2}]
                    : []),
                {stepKey: "DOKUMENTE", label: "Dokumente", totalProgress: 3},
            ]
        },
        headers() {
            return {
                lockedLeft: [SlotColumn("filename", "Name", 250, 1).makeAlwaysVisible()],
                center: [
                    TextColumn("bezeichnung", "Bezeichnung"),
                    TextColumn("bemerkung", "Bemerkung"),
                    DateColumn("dateStand", "Stand"),
                    DateColumn("dateCreated", "Erstellt am"),
                ],
                lockedRight: [ActionColumn("actions", "Aktionen")],
            }
        },
        rows() {
            let rows = this.besuchsbericht.attachmentFiles || []

            return rows.map((row) => ({
                ...row,
                actions: this.makeActions(row),
            }))
        },
        tabsStepperMediator() {
            return new StepperMediator(this.tabs)
        },
        headerActions() {
            return [
                PageHeaderSimpleAction("SEND", "Versenden"),
                PageHeaderSimpleAction("UPLOAD_DOCUMENT", "Dokument hochladen"),
                PageHeaderSimpleAction("CREATE_TASK", "Neue Aufgabe"),
            ]
        },
        tasksHeaders() {
            // IMPORTANT note: The keys of the headers are used to compare in PostfachTerminService.getComparatorBySortKey
            return {
                lockedLeft: [
                    DateColumn("date", "Datum"),
                    PillColumn("wvStatus", ""),
                    TextColumn("subject", "Betreff").makeLink(),
                ],
                center: [
                    TextColumn("name", "Besitzer", 250),
                    SlotColumn("participants", "Teilnehmer", 80, 1),
                    TextColumn("bereich", "Bereich", 400)
                        .makeConditionalLink("hasOpenAction")
                        .addCellProps({lineClamp: 2}),
                    TextColumn("text", "Text", 400).addCellProps({lineClamp: 4}),
                    TextColumn("art", "Art"),
                    TextColumn("telefon", "Telefon"),
                    TextColumn("mobil", "Mobil"),
                    DateColumn("dateCreated", "Erstellungsdatum"),
                    CurrencyColumn("geschaeftwert", "Geschätzter Geschäftswert"),
                    TextColumn("produkt", "Produkt"),
                    TextColumn("verkaufphase", "Verkaufphase"),
                    TextColumn("status", "Status"),
                ],
                lockedRight: [ActionColumn("actions", "Aktionen")],
            }
        },
        taskTableHeaderActions() {
            return [
                PageHeaderSimpleAction("CREATE_TASK", "Neue Aufgabe"),
                PageHeaderConfirmedAction(
                    "BULK_DELETE",
                    "Markierte löschen",
                    "Sollen die markierten Einträge wirklich gelöscht werden?",
                    "Markierte löschen",
                    "Löschen",
                ).withDisabled(() => !this.selectedRows.length),
                PageHeaderSimpleAction("BULK_DONE", "Markierte als Erledigt markieren").withDisabled(
                    () => !this.selectedRows.length,
                ),
            ]
        },
        exportableHeaders() {
            return Object.values(this.headers)
                .flat()
                .filter((header) => header.exportFn != null && header.visible)
        },
        exportConfig() {
            return {
                roottext: "Postfach",
                dispatch: this.exportAsFile,
            }
        },
        isSomethingLoading() {
            return Object.values(this.loading).some((action) => action)
        },
        isGesellschaft() {
            return this.besuchsbericht.id && !!this.besuchsbericht.gesellId
        },
        selectedBesucher() {
            return this.besuchsbericht.besucher || []
        },
        pages() {
            const result = {}
            Object.entries(this.pageList).forEach(([page, records]) => {
                result[page] = records.map((record) => ({
                    ...record,
                    wvStatus: this.getWVStatus(record.wvStatus),
                    hasOpenAction:
                        !this.isCustomerOnly &&
                        Object.keys(record.bereichOeffnen || {}).length &&
                        LINK_ACTIONS.includes(record.bereichOeffnen.bereich),
                    rowid: "" + record.commId + record.date,
                    subject: record.subject || "[kein Betreff]",
                    actions: this.makeActions(record),
                }))
            })
            return result
        },
        pageCount() {
            return Math.ceil(this.rowCount / ROWS_PER_PAGE_DEFAULT)
        },
    },
    mounted() {
        this.loadBericht()
    },
    methods: {
        loadBericht() {
            this.loading.init = true

            const params = {
                id: this.$route.params.id,
                gesellId: this.$route.params.gesellId,
                showDeleted: this.showDeleted,
            }

            axios
                .get(`${process.env.VUE_APP_API}/berichte/bericht`, {...config, params})
                .then((response) => {
                    this.besuchsbericht = response.data.bericht
                    this.originalBesuchsbericht = JSON.parse(JSON.stringify(this.besuchsbericht))
                    this.besucherSelection = response.data.besucher
                    this.besucherSelection.sort((a, b) => a.label.localeCompare(b.label))

                    this.records = response.data.tasks || []
                    this.$set(this.pageList, 0, this.records)
                    this.rowCount = this.records.length
                })
                .finally(() => (this.loading.init = false))
        },
        async saveBericht() {
            if (JSON.stringify(this.originalBesuchsbericht) === JSON.stringify(this.besuchsbericht)) {
                return
            }

            this.loading.save = true
            const params = {
                id: this.besuchsbericht.id,
                gesellId: this.besuchsbericht.gesellId,
                showDeleted: this.showDeleted,
            }

            try {
                const response = await axios.post(`${process.env.VUE_APP_API}/berichte/bericht`, this.besuchsbericht, {
                    ...config,
                    params,
                })
                this.besuchsbericht = response.data
                this.originalBesuchsbericht = JSON.parse(JSON.stringify(this.besuchsbericht))
            } finally {
                this.loading.save = false
            }
        },
        async sendBericht() {
            await this.saveBericht()

            const params = {
                id: this.besuchsbericht.id,
            }

            try {
                await axios.post(`${process.env.VUE_APP_API}/berichte/sendBericht`, this.besuchsbericht, {
                    ...config,
                    params,
                })
                this.$store.dispatch(
                    LOG_TYPES.ACTIONS.ADD_MESSAGE,
                    buildMessage("Der Besuchsbericht wurde erfolgreich versendet", "success"),
                )
            } catch (e) {
                // empty block
            }
        },
        async setStep(newStep) {
            await this.saveBericht()
            this.currentStep = newStep
        },
        makeActions(row) {
            let actions = []

            if (row.deletable && !row.deleted) {
                const message = `Soll das Dokument "${row.bezeichnung || row.filename}" wirklich gelöscht werden?`
                actions.push(ConfirmedAction("DELETE", "", "Löschen", message, "Dokument löschen", "Löschen"))
            }

            return actions
        },
        getWVStatus(value) {
            switch (value) {
                case "green":
                    return {
                        label: "erledigt",
                        type: "success",
                    }
                case "blue":
                    return {
                        label: "wird versendet",
                        type: "info",
                    }
                case "yellow":
                    return {
                        label: "Handlung notwendig",
                        type: "warning",
                    }
                default:
                    return ""
            }
        },
        loadPage(event) {
            this.selectedPage = event
        },
        onScroll(onScrollEnd) {
            this.onScrollEnd = onScrollEnd
            this.loadPage(this.selectedPage + 1)
        },
        onSearchSort(searchSort) {
            this.searchSort = searchSort || {}
            this.pageList = {}
            this.loadPage(0)
        },
        makeActions(row) {
            const article = row.category === "Termin" ? "der" : "die"

            return [
                ConfirmedAction(
                    "DONE",
                    "PhCheck",
                    "Erledigt",
                    `Soll die Aufgabe "${row.subject || "[kein Betreff]"}" wirklich als erledigt markiert werden?`,
                    `Aufgabe als Erledigt markieren`,
                    "Erledigt",
                ),
                ConfirmedAction(
                    "DELETE",
                    "PhTrash",
                    "Löschen",
                    `Soll ${article} ${row.category} "${row.subject || "[kein Betreff]"}" wirklich gelöscht werden?`,
                    `${row.category} löschen`,
                    "Löschen",
                ),
            ]
        },
        clickBereich(row) {
            const action = row.bereichOeffnen

            if (action) {
                switch (action.bereich) {
                    case "VersVertrag":
                        this.openInsurance(action)
                        return
                    case "Kunde":
                        this.openCustomerNewTab(action.bereichId)
                        return
                    case "Makler":
                        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {brokerId: action.bereichId})
                        return
                    default:
                        return
                }
            }
        },
        editOrCreateAppointment(row) {
            if (row?.commId) {
                this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, row.commId)
            } else {
                this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: true})
                this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_ACTIVITY)
            }

            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
                title: row?.commId ? "Aufgabe bearbeiten" : "Aufgabe erstellen",
                isActivity: true,
                bereich: "Besuchsbericht",
                bereichId: this.besuchsbericht.id,
                back: this.$route.fullPath,
            })

            this.$addBreadcrumb({
                fullPath: this.$route.fullPath,
                breadcrumb: "Aufgaben",
            })

            this.$router.push({path: `/communication/appointment`})
        },
        deleteRow(row) {
            this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.DELETE_FROM_POSTFACH, {id: row.commId, url: this.category})
        },
        async deleteSelected() {
            const payload = (await this.getSelectedIds())
                .map((v) => ({
                    id: v,
                    category: this.mailboxLabel,
                }))
            axios.post(`${process.env.VUE_APP_API}/postfach/deleteList`, payload, {defaultSpinner: true}).then(() => {
                this.loadBericht()
            })
        },
        uploadNewDocument() {
            this.fileData = {
                dateStand: dayjs().format("DD.MM.YYYY"),
            }
            this.files = []
            this.$refs.uploadModal.open()
        },
        dropFiles(files) {
            this.fileData = {
                dateStand: dayjs().format("DD.MM.YYYY"),
            }
            this.files = files
            this.$refs.uploadModal.open()
        },
        deleteDocument({id}) {
            this.loading.save = true

            const params = {
                fileId: id,
                id: this.besuchsbericht.id,
            }

            axios
                .delete(`${process.env.VUE_APP_API}/berichte/berichtAttachment`, {...config, params})
                .then((response) => {
                    if (response && response.status === 200 && !this.showDeleted) {
                        this.besuchsbericht.attachmentFiles = this.besuchsbericht.attachmentFiles.filter(
                            (row) => row.id !== id,
                        )
                        this.originalBesuchsbericht.attachmentFiles = JSON.parse(
                            JSON.stringify(this.besuchsbericht.attachmentFiles),
                        )
                    }
                })
                .finally(() => (this.loading.save = false))
        },
        async uploadDocuments({tempFileId}) {
            this.loading.save = true

            const params = {id: this.besuchsbericht.id}

            const payload = {
                ...this.fileData,
                tempFileId,
            }

            axios
                .post(`${process.env.VUE_APP_API}/berichte/berichtAttachment`, payload, {...config, params})
                .then((response) => {
                    if (response && response.data) {
                        this.besuchsbericht.attachmentFiles.unshift(response.data)
                        this.originalBesuchsbericht.attachmentFiles = JSON.parse(
                            JSON.stringify(this.besuchsbericht.attachmentFiles),
                        )
                    }
                })
                .finally(() => (this.loading.save = false))
        },
        updateBericht(bs, event) {
            if (event) {
                this.besuchsbericht.besucher.push(bs)
            } else {
                const index = this.besuchsbericht.besucher.indexOf(bs)
                this.besuchsbericht.besucher.splice(index, 1)
            }
        },
        toggleShowDeleted(value) {
            this.showDeleted = value
            this.loadAttachments()
        },
        loadAttachments() {
            this.loading.init = true

            const params = {
                id: this.besuchsbericht.id,
                showDeleted: this.showDeleted,
            }

            axios
                .get(`${process.env.VUE_APP_API}/berichte/berichtAttachments`, {...config, params})
                .then((response) => {
                    this.$set(this.besuchsbericht, "attachmentFiles", response.data)
                    this.originalBesuchsbericht.attachmentFiles = JSON.parse(
                        JSON.stringify(this.besuchsbericht.attachmentFiles),
                    )
                })
                .finally(() => (this.loading.init = false))
        },
    },
    async beforeRouteLeave(to, from, next) {
        await this.saveBericht()
        next()
    },
}
</script>

<style scoped>
.besucher__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;
    overflow-wrap: anywhere;
}

.parameter__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-gap: 16px;
    overflow-wrap: anywhere;
}
</style>
