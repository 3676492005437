<template>
<div>
  <OptionMenu
    :id="$appNavigation.currentOptionMenuId"
    :defaultMenu="$appNavigation.currentOptionMenu" >
  </OptionMenu>

  <PageHeaderTitleNavigation
    :title="$appNavigation.currentMenu.label"
    :id="$appNavigation.currentOptionMenuId"
    :actions="actions"
    @action-NEW="openInitModal">
  </PageHeaderTitleNavigation>

    <div>

      <div v-if="!scenariosList">
        <div class="row">
          <div class="col col-md-6">
            <GhostLoading type="block" useBoxContainer :config="{ block: { height: '250px', }, }" />
          </div>
          <div class="col col-md-6">
            <GhostLoading type="block" useBoxContainer :config="{ block: { height: '250px', }, }" />
          </div>
        </div>
      </div>

      <div class="row" v-else-if="scenariosList.length">
        <div class="col col-md-6" v-for="(scenario, index) of scenariosList" :key="index">
          <div class="box__container">

            <PageHeaderTitleNavigation 
              :id="scenario.id" 
              :actions="scenarioHeaderActions" 
              noPrimaryAction
              :showBackButton="false"
              @action-REMOVE="deleteScenario(scenario)"
            >
              <template #title><slot name="title">
                <div class="cards__item--header ">
                  <a @click="goToScenario(scenario.id)">
                    {{scenario.title}} | {{scenario.updateDate || scenario.startDate}}
                  </a>
                </div></slot>
              </template>
            </PageHeaderTitleNavigation>
            <div>
              <RetirementChart
                :data="{
                  data: apexSeries(scenario),
                  series: apexSeries(scenario),
                  xaxis: apexXaxis(scenario),
                  annotations: apexAnnotations(scenario),
                  markers: apexMarkers,
                  dataLabels: {enabled: false},
                  stroke: {curve: 'smooth'},
                  autoUpdateSeries: true,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <NoData v-else class="box__container"/>
    </div>

    <BaseModal
      modalTitle="Financial planning erstellen"
      ref="initModal"
      labelButtonConfirm="Erstellen"
      :confirmDisabled="!canSaveScenario"
      @onConfirmButton="save"
    >
      <InputField id="scenario.title"
        label="Name des Scenarios"
        :value="scenario.title"
        validateUntouched
        @input="doChanges('title', $event, 'new')"
      />
      <InputTextArea
        label="Notizen"
        :value="scenario.bemerkung"
        @input="doChanges('bemerkung', $event, 'new')"
        :rows="5"
      />
      <DatePickerField v-if="!scenario.geburtsdatum"
        label="Geburtstag"
        placeholder="DD.MM.JJJJ"
        isValueAsString
        :value="scenario.geburtsdatum"
        @change="doChanges('geburtsdatum', $event, 'new')"
      />
      <InputField
        label="Lebenserwartung"
        :value="scenario.lebenserwartung"
        @input="doChanges('lebenserwartung', $event, 'new')"
      />
      <InputField
        label="Monatlicher Bedarf in Euro in Rente (nach heutigem Geldwert)"
        :value="scenario.bedarfMonatlich"
        type="currency"
        :disabled="scenario.ohneKapitalverzehr"
        @change="doChanges('bedarfMonatlich', $event, 'new')"
      />
      <InputField
        label="Geschätzte Renteneinnahmen pro Monat"
        :value="scenario.renteMonatlichErwartet"
        type="currency"
        @change="doChanges('renteMonatlichErwartet', $event, 'new')"
      />
    </BaseModal>
  </div>
</template>

<script>
import RUHESTANDSPLANUNG_TYPES from '@/store/ruhestandsplanung/types';
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import RetirementChart from '@/components/retirementScenario/RetirementChart.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import mixin from '@/views/ruhestandsplanung/mixin.js';
import { mapGetters } from 'vuex';
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import BaseModal from '@/components/core/BaseModal.vue';
import { required } from "@/mixins/validator/rules";
import validator from "@/mixins/validator";

export default {
  mixins: [mixin, validator],
  validators: {
        scenario: {
            title: [required('Bitte geben Sie einen Name')],
            geburtsdatum: [required('Bitte geben Sie das Geburtsdatum ein')],
        }
    },
  components: {
    NoData,
    GhostLoading,
    RetirementChart,
    PageHeaderTitleNavigation,
    OptionMenu,
    DatePickerField,
    InputField,
    InputTextArea,
    BaseModal,
  },
  computed: {
    ...mapGetters({
      scenarios: RUHESTANDSPLANUNG_TYPES.GETTERS.SCENARIO_LIST,
    }),
    scenariosList() {
      return this.scenarios || null;
    },
    actions() {
      return [
        PageHeaderSimpleAction('NEW', 'Neue Ruhestandsplanung')
      ]
    },
    scenarioHeaderActions() {
      return [PageHeaderSimpleAction('REMOVE', 'Ruhestandsplanung löschen')];
    },
  },
  mounted() {
    this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_SCENARIO_LIST);
  },
  methods: {
    goToScenario(id) {
        this.$router.push({ path: id });
    },
    deleteScenario(scenario) {
      this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.DELETE_SCENARIO, scenario?.id);
    },
    initStartValues() {
      this.doChanges('lebenserwartung', this.lifeLength, 'new');
    },
    async openInitModal() {
      await this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_SCENARIO, 'new');
      this.initStartValues();
      this.$refs.initModal.open();
    },
    async save() {
      this.saveFinancialPlaning().then(id => {
        this.goToScenario(id)
      }
      );
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Ruhestandsplanung', 
      to,
      from,
    });

    next();
  },
};
</script>

<style scoped>
.btn-group button {
  margin-right: 10px;
}
.hidden {
  display: none;
}
</style>