<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div
            class="input-forms__label-content input-forms__label-content--bigger"
            v-if="title"
          >
            <ph-bank :size="16" />&nbsp;{{ title }}
          </div>
        </div>
        <div class="col-auto" v-if="config && config.buttonText">
          <BaseButton @click="openFormsFinder">{{
            config.buttonText
          }}</BaseButton>
        </div>
      </div>
    </div>
    <div
      class="input-forms__input-container"
      v-if="positions && positions.length"
    >
      <Table
        v-if="rows.length"
        :headers="headers"
        :rows="rows"
        rowId="index"
        hidePagination
        @action-DELETE="openModalDeleteFond"
      >
        <template v-slot:betragEinmal="row">
          <InputField
            type="currency"
            :precision="2"
            :value="row.betragEinmal"
            :id="'einmalBetrag' + row.index"
            @change="onChangeInputField($event, row, 'betragEinmal')"
          />
        </template>

        <template v-slot:betragPlanEuro="row">
          <InputField
            type="currency"
            :precision="2"
            :value="row.betragPlanEuro"
            :id="'betragPlanEuro' + row.index"
            @change="onChangeInputField($event, row, 'betragPlanEuro')"
          />
        </template>

        <template v-slot:betragPlanAnteile="row">
          <InputField
            :value="row.betragPlanAnteile"
            :id="'betragPlanAnteile' + row.index"
            @change="onChangeInputField($event, row, 'betragPlanAnteile')"
          />
        </template>
      </Table>

      <WertpapierAuswahlPositionFields
        class="mt-3"
        v-if="positions && positions.length && config && config.positionFields"
        :config="config"
        :categoryId="categoryId"
        :antragId="antragId"
      />
    </div>
    <div v-else>
      <div>Wertpapierauswahl ist leer</div>
    </div>

    <DeletePositionModal
      ref="deleteModal"
      :position="positionToDelete"
      @delete="doDeletePosition()"
    ></DeletePositionModal>
      <BaseModal
      ref="fehlerModal"
      labelButtonCancel="Ok"
      :showConfirmButton="false"
    >
      <template v-slot:modalTitle>
        <PhWarning :size="16" class="color-danger mr-2" />
        <span class="color-danger">Fehler</span>
      </template>

      Fehler: Sie dürfen nur 6 Positionen wählen.
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ANTRAG_TYPES from "@/store/antrag/types";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  ActionColumn,
  SimpleAction,
  SlotColumn,
} from "@/components/table2/table_util.js";
import InputField from "@/components/core/forms/InputField.vue";
import WertpapierAuswahlPositionFields from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue";
import WertpapierAuswahlPositionsButtons from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsButtons.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import validator from "@/mixins/validator";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import BaseModal from "@/components/core/BaseModal.vue";
import { PhBank, PhWarning } from "phosphor-vue";

export default {
  mixins: [antragNavigationMixin, antragMixin, validator],
  validators: {},
  components: {
    Table,
    InputField,
    WertpapierAuswahlPositionFields,
    WertpapierAuswahlPositionsButtons,
    BaseButton,
    FormLabel,
    InputRadioBoxGroup,
    BaseModal,
    DeletePositionModal,
    PhBank,
    PhWarning,
  },
  props: {
    title: {},
    categoryId: {},
    antragId: {},
    antragTyp: {},
    config: {},
  },
  mounted() {},
  data() {
    return {
      headers: {
        lockedLeft: [
          TextColumn("isin", "ISIN"),
          TextColumn("fondsname", "Fondsname"),
          SlotColumn("betragEinmal", "Einmalanlage"),
          SlotColumn("betragPlanEuro", "Spar / Entnahmeplan"),
          SlotColumn("betragPlanAnteile", "Anteile(nur bei Entnahmeplan)"),
        ],
        lockedRight: [ActionColumn("actions", "")],
      },
      positionToDelete: {},
    };
  },
  watch: {
    positionWarnings: {
      immediate: true,
      handler(newValue) {
        if (newValue.length > 6) {
          this.$refs.fehlerModal.open();
        }
      },
    },
    
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
    }),
    rows() {
      const actions = [SimpleAction("DELETE", "", "Löschen")];
      return this.positions.map((row) => ({
        ...row,
        actions,
      }));
    },
    positions() {
      if (
        this.positionsAll &&
        this.antragId &&
        this.positionsAll[this.antragId]
      ) {
        let positions = this.positionsAll[this.antragId]["ENTNAHMEPLAN"] || [];
        if (
          this.positionsAll[this.antragId]["SPARPLAN"] &&
          this.positionsAll[this.antragId]["SPARPLAN"].length > 0
        ) {
          positions = positions.concat(
            this.positionsAll[this.antragId]["SPARPLAN"]
          );
        }
        positions.map((pos, index) =>
          Object.assign(pos, {
            index: index,
            fondsname: this.getFondsName(pos),
          })
        );

        return positions;
      }
    },
    positionWarnings() {
      return (
        (this.antraege &&
          this.antraege[this.antragId] &&
          this.antraege[this.antragId].warnings &&
          this.antraege[this.antragId].warnings.positionWarnings) ||
        []
      );
    },
  },
  methods: {
    getFondsName(pos) {
      return (
        (pos &&
          this.positionInfo &&
          this.positionInfo[pos.isin] &&
          this.positionInfo[pos.isin].wertpapiername) ||
        pos.fondsname ||
        pos.wertpapiername
      );
    },
    openFormsFinder() {
       if (this.positions && this.positions.length >= 6) {
        this.$refs.fehlerModal.open();
      } else {
        this.goToFormsFinder(
          this.categoryId,
          this.config,
          this.suppressFilterLagerstelle
        );
      }
    },
    commitUpdatePositionsFromEvent(positionChange, isin, isSparplan) {
      const payload = {
        id: this.antragId,
        categoryId: isSparplan ? "SPARPLAN" : "ENTNAHMEPLAN",
        fonds: [
          {
            ...positionChange,
            isin,
          },
        ],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    onChangeInputField($event, position, field) {
      if ($event == "") {
        $event = null;
      }
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(
        positionChange,
        position.isin,
        position.isSparplan
      );
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open();
    },
    doDeletePosition() {
      this.deletePosition(
        this.antragId,
        this.categoryId,
        this.positionToDelete
      );
      this.positionToDelete = null;
    },
  },
};
</script>

<style scoped>
.warning {
  color: var(--color-danger);
  font-size: smaller;
}
</style>