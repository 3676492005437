var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "customer-picture__container",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm.imageUrl
        ? [
            _c(
              "div",
              {
                staticClass: "no-line-height",
                class: {
                  "image-cropper--small": _vm.size === "small",
                  "image-cropper--large": _vm.size === "large"
                }
              },
              [
                _c("img", {
                  class: {
                    "rounded--small": _vm.size === "small",
                    "rounded--large": _vm.size === "large"
                  },
                  attrs: { src: _vm.imageUrl, alt: _vm.ariaDescription }
                })
              ]
            )
          ]
        : [
            _c(
              "div",
              {
                staticClass:
                  "customer-picture__image-placeholder customer-picture__container-img",
                class: {
                  "customer-picture__container-img--small":
                    _vm.size === "small",
                  "customer-picture__container-img--large": _vm.size === "large"
                }
              },
              [_c("ph-user", { attrs: { size: 24 } })],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }