<template>
    <div>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
import CORE_TYPES from "../../store/core/types";
  
  export default {
    data() {
      return {
        maklernr: null,
        code: null,
        email: null,
      }
    },
    components: {},
    mounted() {
      // TODO Add these attributes to url 
      this.maklernr = this.$route.query.maklernr;
      this.code = this.$route.query.code;
      this.email = this.$route.query.email;
      this.$store.dispatch(CORE_TYPES.ACTIONS.GET_BROKER_LAYOUT, {broker: this.maklernr});
      //this.$store.dispatch(CORE_TYPES.ACTIONS.GET_BROKER_LAYOUT, {});

    },
  }
  </script>
  
  <style scoped>
  
  </style>