<template>
  <div class="row mt-0">
    <div class="col-12 col-sm-6">
      <div class="row mt-0 align-items-center">
        <div class="col-3 col-sm-4 col-md-3 col-lg-3 pr-0">
          <span>Von:</span>
        </div>
        <div class="col-9 col-sm-8 col-md-9 col-lg-9 pl-0">
          <SplitedDatePicker
            v-model="internalValue.von"
            :disabled="disabled"
            @input="updateDateRange({von: $event})"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="row mt-0 align-items-center">
        <div class="col-2 col-sm-3 col-md-3 col-lg-3 pr-0">
          <span>Bis:</span>
        </div>
        <div class="col-10 col-sm-9 col-md-9 col-lg-9 pl-0">
          <SplitedDatePicker
            v-model="internalValue.bis"
            :disabled="disabled"
            @input="updateDateRange({bis: $event})"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SplitedDatePicker from '@/components/core/forms/splitedDatePicker/SplitedDatePicker.vue';
export default {
  components: {
    SplitedDatePicker,
  },
  data() {
    return {
      internalValue: {
        bis: '',
        von: ''
      },
    }
  },
  methods: {
    updateDateRange(event) {
      const value = Object.assign(this.internalValue, event)
      this.$emit('input', value);
    }
  }

}
</script>