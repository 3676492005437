<template>
  <div>
    <GesellschaftTitleNavigation
      :headerActions="headerActions"
      @action-CREATE_BESUCHSBERICHT="editOrCreateBesuchsbericht"
      @action-CREATE_BEMERKUNG="editOrCreateBemerkung"
    />

    <div class="box__container">
      <ComboBox
        label="Betreuungsstatus" 
        :sortComboboxValues="false"
        isComponentHalfSize
        :values="betreuungsstatusValues"
        :value="betreuungsstatus"
        @input="updateBetreuungsstatus"
      />
    </div>


    <BaseFilter 
      :title="TABLE_TITLE + ' Filter'" 
      :metadata="metadata" 
      :configFilter="configFilter"
      hasSmartSearch
      @onFilter="doFilter"
    />

    <div class="box__container">
      <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
      <Table 
        v-else
        :title="TABLE_TITLE"
        :rows="rows"
        :headers="headers"
        :rowsPerPage="20"
        @click-subject="editBemerkungOrBesuchsbericht"
        @action-DELETE_BEMERKUNG="deleteBemerkung"
        @action-DELETE_BERICHT="deleteBericht"
        @action-COPY_BESUCHSBERICHT="copyBericht"
      />
    </div>
  </div>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import { TextColumn, DateColumn, PillColumn, ActionColumn, SimpleAction, ConfirmedAction, } from "@/components/table2/table_util.js";
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import ComboBox from '@/components/core/forms/ComboBox.vue'

const TABLE_TITLE = 'Bemerkungen / Besuchsberichte';
const VALUE_TYP = 'gesellschaft';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    GesellschaftTitleNavigation,
    BaseFilter,
    GhostLoading,
    Table,
    ComboBox,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      configFilter: {
          placeholderForDefSearchInput: 'Suche nach Thema',
          defaultSearchInputKeys: ['betreff'],
          noResetOnDefaultSearchInputExit: true,
          checkDefaultSearchInput: true,
      },
      dataHasChanged: false,
    }
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    betreuungsstatus() {
      return this.gesellschaft.bemerkungenData?.betreuungsstatus
    },
    headers() {
      return {
        lockedLeft: [
          PillColumn("type", "Typ"),
          DateColumn("startzeit", "Datum"),
          TextColumn("uhrzeit_von", "Von"),
          TextColumn("uhrzeit_bis", "Bis"),
          TextColumn("subject", "Thema").makeLink(),
        ],
        center: [
          TextColumn("ort", "Ort"),
          TextColumn("text", "Text"),
          TextColumn("anwesende", "Anwesende"),
        ],
        lockedRight: [
          ActionColumn('actions')
        ],
      }
    },
    rows() {
      const bemerkungen = this.gesellschaft.bemerkungenData?.bemerkungen || []
      return bemerkungen.map(row => ({
        ...row,
        subject: row.thema || "[Kein Betreff]",
        text: this.getCleanText(row.text || '')?.trim(),
        type: {
          label: row.typ === 'BEMERKUNG' ? 'Bemerkung' : 'Besuchsbericht',
          type: 'info',
        },
        actions: this.makeActions(row)
      }))
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('CREATE_BESUCHSBERICHT', 'Neuer Besuchsbericht'),
        PageHeaderSimpleAction('CREATE_BEMERKUNG', 'Neue Bemerkung'),
      ]
    },
    metadata() {
      return [
        {
          type: "group",
          key: "allgemein",
          label: "Allgemein",
          menuItems: [
            {
              type: "text",
              label: "Thema",
              key: "betreff",
            },
            {
              type: "default",
              label: "ohne Bemerkungen",
              key: "ohneBemerkungen",
            },
            {
              type: "default",
              label: "ohne Besuchsberichte",
              key: "ohneBesuchsberichte",
            },
            {
              type: "datepicker",
              label: "Datum ab",
              key: "datumVon",
            },
            {
              type: "datepicker",
              label: "Datum bis",
              key: "datumBis",
            },
            {
              type: "dateRange",
              label: "Zeitraum",
              key: "zeitraum",
            },
          ],
        },
      ]
    },
    betreuungsstatusValues() {
      return this.gesellschaft.comboboxSelections?.betreuungsstatusValues || []
    },
  },
  mounted() {
    this.doSearch()
  },
  methods: {
    doFilter(filterParams) {
      let params = {};

      filterParams.forEach(fc => {
        if (fc.key === "datumVon" || fc.key === "datumBis") {
          params[fc.key] = DatePickerUtils.toDateStr(fc.value)
        } else if (fc.key === "zeitraum") {
          params["datumVon"] = DatePickerUtils.toDateStr(fc.value.find(val => val.key === 'min').value)
          params["datumBis"] = DatePickerUtils.toDateStr(fc.value.find(val => val.key === 'max').value)
        } else {
          params[fc.key] = fc.value
        }
      })

      this.filterParams = params
      this.doSearch(true)
    },
    doSearch(isReload = false) {
      this.loading = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_BEMERKUNGEN, {
        isReload, 
        payload: { gesellschaftId: this.$route.params.gesellId, ...this.filterParams }
      })
      .finally(() => this.loading = false);
    },
    makeActions(row) {
      let isBemerkung = row.typ === 'BEMERKUNG'
      let actions = []

      if (!isBemerkung) {
        actions.push(SimpleAction("COPY_BESUCHSBERICHT", '', "Kopieren"))
      }

      if (row.deletable) {
        if (isBemerkung) {
          actions.push(ConfirmedAction("DELETE_BEMERKUNG", '', "Löschen", `Soll die Bemerkung "${row.thema || "[Kein Betreff]"}" wirklich gelöscht werden?`, 'Bemerkung löschen', "Löschen"))
        } else {
          actions.push(ConfirmedAction("DELETE_BERICHT", '', "Löschen", `Soll der Besuchsbericht "${row.thema || "[Kein Betreff]"}" wirklich gelöscht werden?`, 'Besuchsbericht löschen', "Löschen"))
        }
      }

      return actions
    },
    getCleanText(text) {
      var div = document.createElement("div");
      div.innerHTML = text;
      return div.textContent || div.innerText || "";
    },
    editOrCreateBesuchsbericht(row) {
      // is needed to reload the table if something was edited
      this.$store.commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_BEMERKUNGEN_SUCCESS, {})

      this.$addBreadcrumb({
        fullPath: this.$route.fullPath,
        breadcrumb: 'Bemerkung',
      });

      let serviceUrl = this.$route.path + `/besuchsbericht`

      if (row && row.id) {
        serviceUrl += `/${row.id}`
      }

      this.$router.push(serviceUrl);
    },
    editOrCreateBemerkung(row) {
      // is needed to reload the table if something was edited
      this.$store.commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_BEMERKUNGEN_SUCCESS, {})

      this.$addBreadcrumb({
        fullPath: this.$route.fullPath,
        breadcrumb: 'Bemerkung',
      });

      let serviceUrl = this.$route.path + `/bemerkung`

      if (row && row.id) {
        serviceUrl += `/${row.id}`
      }

      this.$router.push({path: serviceUrl, query: {typ: VALUE_TYP}});
    },
    editBemerkungOrBesuchsbericht(row) {
      if (row.typ === 'BEMERKUNG') {
        this.editOrCreateBemerkung(row)
      } else if (row.typ === 'BERICHT') {
        this.editOrCreateBesuchsbericht(row)
      }
    },
    deleteBemerkung({ id }) {
      const params = makeQueryParam({ id, typ: VALUE_TYP })

      axios.delete(`${process.env.VUE_APP_API}/postfachBemerkung/bemerkung?${params}`, config)
      .then(() => {
        const index = this.gesellschaft.bemerkungenData.bemerkungen.findIndex(record => record.id === id)

        if (index >= 0) {
          this.gesellschaft.bemerkungenData.bemerkungen.splice(index, 1)
        }
      })
    },
    deleteBericht({ id }) {
      const params = makeQueryParam({ id })

      axios.delete(`${process.env.VUE_APP_API}/berichte/bericht?${params}`, config)
      .then(() => {
        const index = this.gesellschaft.bemerkungenData.bemerkungen.findIndex(record => record.id === id)

        if (index >= 0)
          this.gesellschaft.bemerkungenData.bemerkungen.splice(index, 1)
      })
    },
    copyBericht({ id }) {
      const params = makeQueryParam({ id })

      axios.get(`${process.env.VUE_APP_API}/berichte/copyBericht?${params}`, config)
      .then(() => this.doSearch())
    },
    updateBetreuungsstatus(value) {
      this.dataHasChanged = true
      this.$set(this.gesellschaft.bemerkungenData, 'betreuungsstatus', value)
    },
    async saveBemerkungen() {
      if (!this.dataHasChanged) {
        return
      }

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      const payload = {
        betreuungsstatus: this.betreuungsstatus
      }

      return axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/bemerkungen?${params}`, payload, config)
      .then(() => this.dataHasChanged = false)
    },
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveBemerkungen();
    next();
  }
}
</script>