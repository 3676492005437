<template>
  <div>

    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :id="$appNavigation.currentOptionMenuId"
      :actions="actions"
      @action-CREATE="openCreateBeratungsmappeModal"
    />

    <BaseFilter 
      filterId="790ca065-681f-4a9f-952f-ec8c993ca818"
      :showSaveButton="isBroker"
      title="Beratungsmappen filtern" 
      :saveKey="isBroker ? 'beratungsmappeSearchAdvanced' : null" 
      :metadata="filterMetadata" 
      :hasSmartSearch="isBroker"
      @onFilter="filterForm($event)"
      immidiateSearch
      :defaultOptions="isBroker ? defaultOptions : {}">
    </BaseFilter>
    
    <div class="box__container" tid="c646c2ab-ba83-4271-b654-ed7c43d189bb">
        <Table v-if="!loading.list && rows.length"
            tableId="cc8c185e-0263-4401-b654-903010d11f8e"
            :title="TABLE_TITLE"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="20"
            :exportConfig="{roottext: 'Beratungsmappen'}"
            :mobileConfig="mobileConfig"
            @clickRow="editBeratungsmappe($event.data)"
            @click-kunde="editBeratungsmappe($event.data)"
            @click-date_expired="showInfoDateExpired($event.data)"
            @click-art="editBeratungsmappe($event.data)"
            @action-COPY="copyBeratungsmappe($event.data)"
            @action-DELETE="deleteBeratungsmappe($event.data)"
        />
        <GhostLoading v-else-if="loading.list" type="table" :title="TABLE_TITLE" />
        <NoData v-else-if="rows.length === 0" :title="TABLE_TITLE" />
    </div>

    <EditBeratungsmappeModal 
      ref="createBeratungsmappeModal"
      @confirm="createBeratungsmappe"
    />

    <BaseModal 
      ref="ablaufInfoModal" 
      modalTitle="Ablaufdatum"
      labelButtonConfirm="Schließen"
      :showConfirmButton="true"       
      :showCancelButton="false"
      :showCloseButton="false"
    >
      <div>
        <span v-if="ablaufInfoModalData.ablaufDate">
          Die Anträge der Beratungsmappe müssen vor dem {{ablaufInfoModalData.ablaufDate}} eingereicht werden. 
        </span>

        <div v-if="ablaufInfoModalData.list">
          <ul class="row" v-for="(row, index) of ablaufInfoModalData.list" :key="index">
              <li class="col mb-2">{{row.datum}}: {{row.grund}}</li>
          </ul>
        </div>

        <div v-id="ablaufInfoModalData.hinweis">
          {{ablaufInfoModalData.hinweis}}
        </div>

      </div>
    </BaseModal>

  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import BERATUNGSMAPPE_TYPES from "../../store/beratungsmappe/types";
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn, DateColumn, NumberColumn, IconColumn, Icon, ActionColumn, ConfirmedAction, SimpleAction} from "@/components/table2/table_util.js";
import BaseFilter from "@/components/core/BaseFilter.vue";
import dayjs from "dayjs";
import axios from 'axios';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import { makeQueryParam } from '@/helpers/utils-helper';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderLoadingAction } from '@/components/core/header-title-navigation/page-header-utils'
import EditBeratungsmappeModal from "@/components/beratungsmappe/EditBeratungsmappeModal.vue";
import BaseModal from "@/components/core/BaseModal.vue";

const TABLE_TITLE = 'Beratungsmappen Liste';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    PageHeaderTitleNavigation,
    OptionMenu,
    BaseFilter,
    GhostLoading,
    NoData,
    EditBeratungsmappeModal,
    BaseModal,
},
  data() {
    return {
      TABLE_TITLE,
      defaultOptions: {
        LETZTEN_3_MONATEN: {
          value: true
        },
      },
      filter: {},
      loading: {
        create: false,
        list: false,
      },
      ablaufInfoModalData: {},      
    };
  },
  computed: {
    ...mapGetters({
      beratungsmappeList: BERATUNGSMAPPE_TYPES.GETTERS.BERATUNGSMAPPELIST_DATA,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE,
    }),
    actions() {
      if (this.isSelectionOnly) {
        return [];
      }
      return [
        PageHeaderLoadingAction('CREATE', 'Neue Beratungsmappe', () => this.loading.create)
          .withVisible(() => !this.isBroker)
      ]
    }, 
    filterMetadata() {
      let result = [
          {
            type: "group",
            key: "abDatum",
            label: "Ab Datum",
            menuItems: [
              {
                type: "default",
                label: "Alle",
                key: "VALUE_ALLE",
              },
              {
                type: "default",
                label: "letzte 3 Monate",
                key: "LETZTEN_3_MONATEN",
              },
              {
                type: "dateRange",
                label: "Zeitraum",
                key: "ZEITRAUM",
              },
              {
                type: "integer",
                label: "Jahr",
                key: "jahr",
              },

            ],
          },
          {
            type: "group",
            key: "hinterlegtenOrderdokumenten",
            /* label: "Nur Beratungsmappen mit hinterlegten Orderdokumenten anzeigen", */
            label: "Beratungsmappen anzeigen",
            menuItems: [
              {
                type: "default",
                label: "Alle",
                key: "VALUE_ALLE",
              },
              {
                type: "default",
                label: "Nur mit hinterlegten Orderdokumenten",
                key: "NUR_HINTERLEGTEN_ORDERDOKUMENTEN",
              },

            ],
          },         
          {
            type: "group",
            key: "abDatumIst",
            label: "Ab Datum Ist",
            menuItems: [
              {
                type: "default",
                label: "Datum geschlossen",
                key: "VALUE_DATE_GESPERRT",
              },
              {
                type: "default",
                label: "Datum erstellt",
                key: "VALUE_DATE_CREATED",
              },


            ],
          },
          {
            type: "group",
            key: "abgeschlossen",
            label: "Abgeschlossen",
            menuItems: [
              {
                type: "default",
                label: "Alle",
                key: "VALUE_ALLE",
              },
              {
                type: "default",
                label: "nur offene",
                key: "VALUE_STATUS_OFFEN",
              },
              {
                type: "default",
                label: "nur gesicherte",
                key: "VALUE_STATUS_GESICHERT",
              },
              {
                type: "default",
                label: "nur abgeschlossene",
                key: "VALUE_STATUS_GESCHLOSSEN",
              },            

            ],
          },          
          {
            type: "group",
            key: "fehlerstatus",
            label: "Fehlerstatus",
            menuItems: [
              {
                type: "default",
                label: "Alle",
                key: "VALUE_ALLE",
              },
              {
                type: "default",
                label: "nur fehlerfreie",
                key: "VALUE_FEHLER_OK",
              },
              {
                type: "default",
                label: "nur fehlerhafte",
                key: "VALUE_FEHLER_ROT",
              },

            ],
          },          
          {
            type: "group",
            key: "ort",
            label: "Räumlichkeit",
            menuItems: [
              {
                type: "default",
                label: "Ohne",
                key: "Ohne",
              },
              {
                type: "default",
                label: "Wohnung des/der Kunden",
                key: "KundeWohnung",
              },
              {
                type: "default",
                label: "Büro des Beraters",
                key: "MaklerBuero",
              },
              {
                type: "default",
                label: "Sonstige Räumlichkeiten",
                key: "Sonstig",
              },
              {
                type: "default",
                label: "Die Beratung fand telefonisch statt",
                key: "Telefon",
              },
              {
                type: "default",
                label: "Die Beratung fand per E-Mail statt",
                key: "Mail",
              },

            ],
          },   
                
        ];

        if (this.isBroker) {
          result.push(
            {
              type: 'group',
              key: 'allgemein',
              label: 'Kunde',
              menuItems: [
                {
                  type: 'text',
                  label: 'Name',
                  key: 'lastName'
                },
                {
                  type: 'text',
                  label: 'Kundennummer',
                  key: 'customerId',
                  inputmode: 'numeric',
                },
                {
                  type: 'text',
                  label: 'Vorname',
                  key: 'firstName'
                },
              ]
            }, 
          );
        }

        return result;
    },
    isSelectionOnly() {
      return this.$route.path.indexOf('/beratung/auswahl') != -1;
    },
    rows() {
      if (!this.beratungsmappeList?.rows)
        return [];
      let rows = this.beratungsmappeList.rows.map(item => {
        const actions = [
          ConfirmedAction("COPY", '', "Kopieren", `Möchten Sie die Beratungsmappe ${item.beschreibung ? '"' + item.beschreibung + '"' : ''} kopieren?`, 'Beratungsmappe kopieren', "Kopieren"),
        ]

        if (item.closed_status && item.closed_status !== "IstGespert") {
          actions.push(ConfirmedAction("DELETE", '', "Löschen", `Soll die Beratungsmappe ${item.beschreibung ? '"' + item.beschreibung + '"' : ''} wirklich gelöscht werden?`, 'Beratungsmappe löschen', "Löschen"))
        }
        return {
          id: item.id,
          kunde: item.kunde,
          art: item.art,
          beschreibung: item.beschreibung,
          erstellt: item.datum_erstellt,
          geschlossen: item.datum_geschlossen,
          date_expired: item.date_expired,
          expired: item.expired,
          positionen: item.pos,
          status: [Icon(item.status === 'danger' ? 'PhWarning' : 'PhCheck', item.statusText, 20, undefined, `color-${item.status}`)],
          data: item,
          actions,
        };
      });
      if (this.isSelectionOnly && rows.length && Object.keys(this.$route.query).length) {
        rows = rows.filter(row => {
          return Object.keys(this.$route.query).every(key => this.$route.query[key].indexOf(row.data[key]) != -1 )
        })
      }
      return rows;
    },
    mobileConfig() {
      if (this.isBroker) {
        return {title: 'kunde', headers: ['art', 'beschreibung', 'status', 'erstellt', 'geschlossen']}
      } else {
        return {title: 'art', headers: ['beschreibung', 'status', 'erstellt', 'geschlossen']}
      }
    },
    headers() {
      if (this.isBroker) {
        return {
              lockedLeft: [
                  TextColumn("kunde", "Kunde").makeLink(),
                  TextColumn("art", "Art"),
                  IconColumn("status", "Status"),
                  TextColumn("beschreibung", "Beschreibung"),
              ],
              center: [
                  DateTimeColumn("erstellt", "erstellt"),
                  DateTimeColumn("geschlossen", "geschlossen"),
                  DateColumn("date_expired", "Ablaufdatum").setColorFn(row => row.expired ? 'color-danger' : null).makeLink(),
                  NumberColumn("positionen", "Positionen"),
              ],
              lockedRight: [
                  ActionColumn("actions", "Aktionen"),
              ],
          };
      }

      return {
            lockedLeft: [
                TextColumn("art", "Art").makeLink(),
                TextColumn("beschreibung", "Beschreibung"),
                IconColumn("status", "Status"),
            ],
            center: [
                DateTimeColumn("erstellt", "erstellt"),
                DateTimeColumn("geschlossen", "geschlossen"),
                DateColumn("date_expired", "Ablaufdatum").makeLink(),
                NumberColumn("positionen", "Positionen"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        };      

    },
    ablaufInfoModalHeader() {
      return {
            lockedLeft: [
                TextColumn("datum", "Datum"),
            ],
            center: [
                TextColumn("grund", "Grund"),
            ],
            lockedRight: [
            ],
        };   
    },
  },
  watch: {
    $route() {
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(BERATUNGSMAPPE_TYPES.MUTATIONS.RESET_DATA);

    this.$addBreadcrumb({
      label: 'zurück zu den Beratungsmappen', 
      to,
      from,
    });

    next();
  },
  methods: {
    init() {
      this.investementAdviceReferrer = false;
      const action = this.$route.query?.action ?? this.$route.meta?.action
      if (this.$route.query && action && !this.$route.query.backAction && this.$route.query.investementAdviceReferrer) {
        this.investementAdviceReferrer = true;
        if (this.$route.query.createNew === 'true') {
          this.createBeratungsmappe({mappeTyp: action}, this.$route.query.investementAdviceReferrer);
          return;
        }
      } else if (this.$route.query && action && !this.$route.query.backAction) {
        this.createBeratungsmappe({mappeTyp: action});   
        return;  
      } 
    },
    async retrieveBeratungsmappeList() {
      try {
        this.loading.list = true;
        await this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNGSMAPPELIST, this.filter);
      } finally {
        this.loading.list = false;
      }
    },

    async showInfoDateExpired(beratungsMappeData) {
      await axios.get(process.env.VUE_APP_API + `/beratungsmappe/getInfoDateExpired?beratungsMappeID=${beratungsMappeData.id}`, {defaultSpinner: true}).then(response => {
        if (response && response.data) {
          this.ablaufInfoModalData = response.data;
          this.$refs.ablaufInfoModal.open();
        }      
      })
    },

    editBeratungsmappe(beratungsMappeData) {
      if (this.isSelectionOnly) { // rows
        const item = this.backToPreviousData.pop();
        this.$router.push({path: item.fullPath, query: {
          mappe: encodeURI(JSON.stringify(beratungsMappeData)), 
          backAction: true}
        });
      } else {
        if (this.isBroker) {
          this.editBeratungsmappeKundenZugang(beratungsMappeData)
        } else {
          this.$router.push({path: '/beratung/beratung/bearbeiten', query: {
            id: beratungsMappeData.id, 
            investementAdviceReferrer: this.investementAdviceReferrer && !this.$route.query.backAction}});
        }

      }
    },

    editBeratungsmappeKundenZugang(beratungsMappeData) {
      const id = beratungsMappeData.id;
      const investementAdviceReferrer = this.investementAdviceReferrer && !this.$route.query.backAction;

      const params = makeQueryParam({ id, investementAdviceReferrer })

        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, 
          { customerId: beratungsMappeData.kundennr, beratungsmappe: true, path: `/beratung/beratung/bearbeiten/?${params}` });      
    },

    deleteBeratungsmappe(row) {
      this.$store.dispatch(
        BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_BERATUNGSMAPPE, { beratungsMappeID : row.id }
      ).then(() => this.retrieveBeratungsmappeList())
    },
    
    copyBeratungsmappe(row) {
      this.$store.dispatch(
        BERATUNGSMAPPE_TYPES.ACTIONS.COPY_BERATUNGSMAPPE, { beratungsMappeID : row.id }
      ).then(() => this.retrieveBeratungsmappeList())
    }, 

    openCreateBeratungsmappeModal() {        
      this.$refs.createBeratungsmappeModal.open()
    },
    createBeratungsmappe(mappeData, adviceId) {
      const payload = {
        mappeData,
        adviceId,
      }

      this.loading.create = true
      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.CREATE_BERATUNGSMAPPE, payload).then(data => {
        this.editBeratungsmappe({ id: data.beratungsMappeId, datum: data.datum})
      }).catch(() => this.loading.create = false)    
    },

    filterForm(filterConfig) {
      const kunde = filterConfig.filter(fc => fc.group=="allgemein");
      let kundeKey = null;
      let customerId = null;
      let firstName = null;
      let lastName = null;

      if (kunde) {

        kundeKey = filterConfig.find(fc => fc.key=="customerId");
        if(kundeKey) {
          customerId = kundeKey.value;
        }
        kundeKey = filterConfig.find(fc => fc.key=="firstName");
        if(kundeKey) {
          firstName = kundeKey.value;
        }
        kundeKey = filterConfig.find(fc => fc.key=="lastName");
        if(kundeKey) {
          lastName = kundeKey.value;
        }                
      }

      const abDatum = filterConfig.find(fc => fc.group=="abDatum");

      let fromDate = null;
      let toDate = null;

      if (abDatum && abDatum.key =='ZEITRAUM') {
        fromDate = abDatum.value.find(v => v.key == 'min').value;
        toDate = abDatum.value.find(v => v.key == 'max').value;
      }

      if (abDatum && abDatum.key === "LETZTEN_3_MONATEN") {
        fromDate = dayjs().subtract(3, "month").format("DD.MM.YYYY");
      }

      let filterDateJahr = null;

      if (abDatum && abDatum.key === "jahr") {
        filterDateJahr = abDatum.value;
      }      

      const filterModusDatum = filterConfig.find(fc => fc.group=="abDatumIst");
      const filterFehler = filterConfig.find(fc => fc.group=="fehlerstatus");
      let filterStatus = filterConfig.find(fc => fc.group=="abgeschlossen");
      const filterMitScan = filterConfig.find(fc => fc.group=="hinterlegtenOrderdokumenten");
      const ort = filterConfig.find(fc => fc.group=="ort");
      
      if (this.$route.query && this.$route.query.investementAdviceReferrer) {
        filterStatus = 'VALUE_STATUS_OFFEN';
      }

      this.filter = {
        firstName,
        lastName,
        filterKundennr: customerId,
        filterDateVon: fromDate,
        filterDateBis: toDate,
        filterModusDatum: filterModusDatum && filterModusDatum.key,
        filterWithScan: filterMitScan && filterMitScan.value,
        filterFehler: filterFehler && filterFehler.key,
        filterStatus: filterStatus && filterStatus.key,
        filterDateJahr: filterDateJahr,
        filterOrt: ort && ort.key,
      }

      this.retrieveBeratungsmappeList()
    },    
  },
};
</script>

<style scoped>

</style>