var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title:
            (_vm.visibleScreen === "eigener-zeitraum" && "Wertentwicklung") ||
            (_vm.visibleScreen === "historische-wertentwicklung" &&
              "Jahresperformance") ||
            (_vm.visibleScreen === "realisierte-gewinne" &&
              "realisierte Gewinne"),
          subtitle: _vm.depotTitle,
          actions: _vm.headerActions
        }
      }),
      _vm.visibleScreen === "eigener-zeitraum"
        ? _c("BaseFilter", {
            ref: "baseFilter",
            attrs: {
              title: "Filter",
              filterId: "performanceFilter",
              configFilter: {},
              metadata: _vm.searchMenu,
              defaultOptions: _vm.defaultOptions,
              immidiateSearch: _vm.visibleScreen === "eigener-zeitraum",
              showSaveButton: ""
            },
            on: { onFilter: _vm.onFilter }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "d8e5a1d0-1d5b-430e-9247-e5f520050690" }
        },
        [
          _c("div", { staticClass: "ibox-body" }, [
            _vm.visibleScreen === "eigener-zeitraum"
              ? _c("div", { staticClass: "row" }, [
                  _vm.isChartGCLoading ||
                  _vm.isChartGCDepotHasData ||
                  !_vm.isLoaded ||
                  _vm.performanceRows.length
                    ? _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          [
                            _vm.isChartGCLoading ||
                            _vm.isChartGCDepotHasData ||
                            _vm.isIntern
                              ? [
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col" },
                                        [
                                          _vm.isIntern
                                            ? _c(
                                                "BaseButton",
                                                {
                                                  attrs: { isSecondary: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.cacheLeeren()
                                                    }
                                                  }
                                                },
                                                [_vm._v("Cache leeren")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "historical-performance-chart-eigener-zeitraum"
                                            },
                                            [
                                              _c("ChartGraphicalCourse", {
                                                attrs: {
                                                  chartHeight: "400",
                                                  sparkline: false,
                                                  depotid: _vm.id,
                                                  isLoaded: _vm.isLoaded
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm.isLoaded
                                      ? _c("ChartMontecarlo", {
                                          attrs: {
                                            initialInvestment:
                                              _vm.montecarloInitValues
                                                .initialInvestment,
                                            perfManual:
                                              _vm.montecarloInitValues.perf,
                                            volaManual:
                                              _vm.montecarloInitValues.vola
                                          }
                                        })
                                      : _vm._e(),
                                    _c("hr")
                                  ]
                                ]
                              : _vm._e(),
                            _vm.isLoaded
                              ? [
                                  _vm.performanceRows.length
                                    ? _c("Table", {
                                        ref: "wertentwicklung",
                                        attrs: {
                                          tableId:
                                            "02901a3e-901c-48fc-9415-8a3d523b6632",
                                          headers: _vm.newPerformanceHeaders,
                                          rows: _vm.performanceRows,
                                          rowsPerPage: 0,
                                          showColumnsConfigEvenNoTitle: true,
                                          exportConfig:
                                            _vm.exportWertentwicklung,
                                          mobileConfig: {
                                            title: "Fondsname",
                                            headers: [
                                              "Wertentwicklung",
                                              "G_V_Saldo"
                                            ]
                                          }
                                        },
                                        on: {
                                          "click-Fondsname":
                                            _vm.navigateToFondsinfo,
                                          "action-OEFFNEN":
                                            _vm.navigateToFondsinfo,
                                          "action-ALT_KAUF": function($event) {
                                            return _vm.addDepotToWertpapierorder(
                                              $event,
                                              "ALT_KAUF"
                                            )
                                          },
                                          "action-ALT_VERKAUF": function(
                                            $event
                                          ) {
                                            return _vm.addDepotToWertpapierorder(
                                              $event,
                                              "ALT_VERKAUF"
                                            )
                                          },
                                          "action-ALT_TAUSCH": function(
                                            $event
                                          ) {
                                            return _vm.addDepotToWertpapierorder(
                                              $event,
                                              "ALT_TAUSCH"
                                            )
                                          },
                                          "action-ALT_SPARPLAN": function(
                                            $event
                                          ) {
                                            return _vm.addDepotToWertpapierorder(
                                              $event,
                                              "ALT_SPARPLAN"
                                            )
                                          },
                                          "action-ALT_ENTNAHMEPLAN": function(
                                            $event
                                          ) {
                                            return _vm.addDepotToWertpapierorder(
                                              $event,
                                              "ALT_ENTNAHMEPLAN"
                                            )
                                          },
                                          "action-LIMITS": _vm.actionLimit,
                                          "action-COURTAGE_BGS":
                                            _vm.handleCourtagehistorie,
                                          "action-TRANSAKTIONEN":
                                            _vm.navigateToTransactions,
                                          "action-DEPOT_DETAILS":
                                            _vm.getDepotDetails,
                                          "action-NEW_APPOIMENT":
                                            _vm.handleAddActivity,
                                          "action-FACTSHEET": function($event) {
                                            return _vm.openFactsheetFile(
                                              $event,
                                              "FACTSHEET"
                                            )
                                          },
                                          "action-KAG": function($event) {
                                            return _vm.openFactsheetFile(
                                              $event,
                                              "MR"
                                            )
                                          },
                                          "action-PRIIP-BIB": function($event) {
                                            return _vm.openFactsheetFile(
                                              $event,
                                              "KIID"
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "info",
                                              fn: function(row) {
                                                return [
                                                  row.info
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-centered"
                                                        },
                                                        [
                                                          _c("Pill", {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "fc-tooltip",
                                                                rawName:
                                                                  "v-fc-tooltip",
                                                                value: {
                                                                  content:
                                                                    row.info
                                                                      .tooltip,
                                                                  className:
                                                                    "info-tooltip"
                                                                },
                                                                expression:
                                                                  "{content: row.info.tooltip, className: 'info-tooltip'}"
                                                              }
                                                            ],
                                                            staticStyle: {
                                                              cursor: "default"
                                                            },
                                                            attrs: {
                                                              label:
                                                                row.info.label,
                                                              type:
                                                                row.info.type
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2529497126
                                        )
                                      })
                                    : _vm._e()
                                ]
                              : _c("GhostLoading", { attrs: { type: "table" } })
                          ]
                        ],
                        2
                      )
                    : _c("div", { staticClass: "col-12" }, [_c("NoData")], 1)
                ])
              : _vm._e(),
            _vm.visibleScreen === "historische-wertentwicklung"
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-lg-12" },
                    [
                      [
                        !_vm.historicalPerformanceLoading
                          ? [
                              _vm.historicalPerformance &&
                              _vm.historicalPerformance.data &&
                              _vm.historicalPerformance.data.records &&
                              _vm.historicalPerformance.data.records.length
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "historical-performance-chart app-vertical-bar"
                                        },
                                        [
                                          _c("BarChart", {
                                            attrs: {
                                              chartDataName: "Rendite",
                                              chartData: _vm.getHistoricalPerformanceChartData(
                                                _vm.historicalPerformance.data,
                                                true
                                              ),
                                              format: "percent",
                                              height: "350px"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ]
                          : _c("GhostLoading", {
                              attrs: {
                                type: "block",
                                config: { block: { height: 350 } }
                              }
                            }),
                        !_vm.historicalPerformanceLoading
                          ? [
                              _vm.jahresPerformanceRows.length
                                ? _c("Table", {
                                    attrs: {
                                      tableId:
                                        "d7502c09-312f-48bc-86d7-239c30947bfc",
                                      headers: _vm.newJahresPerformanceHeaders,
                                      rows: _vm.jahresPerformanceRows,
                                      rowId: "Jahr",
                                      rowsPerPage: 0,
                                      exportConfig: _vm.exportJahresperformance,
                                      hidePagination: "",
                                      mobileConfig: {
                                        title: "Jahr",
                                        headers: ["G_V", "Rendite"]
                                      }
                                    },
                                    on: { "action-DETAILS": _vm.showDetails }
                                  })
                                : _c("NoData")
                            ]
                          : _c("GhostLoading", { attrs: { type: "table" } })
                      ]
                    ],
                    2
                  )
                ])
              : _vm._e(),
            _vm.visibleScreen === "realisierte-gewinne"
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-12 col-12" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          !_vm.realisedProfitLoading
                            ? [
                                _vm.realisedProfitRows.length
                                  ? _c("Table", {
                                      attrs: {
                                        tableId:
                                          "c0bd6ed6-915d-4d9a-9ae2-69b3754fcf11",
                                        headers: _vm.newRealisedProfitHeaders,
                                        rows: _vm.realisedProfitRows,
                                        rowId: "ISIN",
                                        rowsPerPage: 0,
                                        exportConfig: _vm.exportGewinne,
                                        mobileConfig: {
                                          title: "Wertpapiername",
                                          headers: [
                                            "Status",
                                            "G_VBetrag",
                                            "G_VProzent"
                                          ]
                                        },
                                        hidePagination: ""
                                      },
                                      on: {
                                        "click-Wertpapiername":
                                          _vm.navigateToFondsinfo,
                                        "action-TRANSAKTIONEN":
                                          _vm.navigateToTransactions
                                      }
                                    })
                                  : _c("NoData")
                              ]
                            : _c("GhostLoading", { attrs: { type: "table" } })
                        ],
                        2
                      )
                    ])
                  ])
                ])
              : _vm._e()
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "notImplemented",
          attrs: {
            modalTitle: "Nicht implementiert",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_vm._v(" Formular noch nicht implementiert ")]
      ),
      _c(
        "BaseModal",
        {
          ref: "modalDepotDetails",
          attrs: {
            modalTitle: _vm.modalArg.title,
            showConfirmButton: _vm.modalArg.showConfirmButton,
            labelButtonConfirm: _vm.modalArg.labelButtonConfirm,
            labelButtonCancel: _vm.modalArg.labelButtonCancel
          },
          on: { onConfirmButton: _vm.modalArg.onConfirm }
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.modalArg.body) } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }