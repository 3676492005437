<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId"
      :title="visibleScreen === 'eigener-zeitraum' && 'Wertentwicklung' || visibleScreen === 'historische-wertentwicklung' && 'Jahresperformance' || visibleScreen === 'realisierte-gewinne' && 'realisierte Gewinne'"
      :subtitle="depotTitle"
      :actions="headerActions"
    />

    <BaseFilter
      v-if="visibleScreen === 'eigener-zeitraum'"
      ref="baseFilter"
      title="Filter"
      filterId="performanceFilter"
      :configFilter="{}"
      :metadata="searchMenu"
      :defaultOptions="defaultOptions"
      :immidiateSearch="visibleScreen === 'eigener-zeitraum'"
      showSaveButton
      @onFilter="onFilter"
    />
    
    <div class="box__container" tid="d8e5a1d0-1d5b-430e-9247-e5f520050690">
      <div class="ibox-body">

        <div class="row" v-if="visibleScreen === 'eigener-zeitraum'">
          <div class="col-12" v-if="isChartGCLoading || isChartGCDepotHasData || !isLoaded || performanceRows.length">
            <template>
              <template v-if="isChartGCLoading || isChartGCDepotHasData || isIntern">
                <template >
                  <div class="row">
                    <div class="col">
                      <BaseButton v-if="isIntern" isSecondary @click="cacheLeeren()">Cache leeren</BaseButton>
                      <div class="historical-performance-chart-eigener-zeitraum">
                        <ChartGraphicalCourse chartHeight="400" :sparkline="false" :depotid="id" :isLoaded="isLoaded" />
                      </div>
                    </div>
                    

                  </div>
                  <ChartMontecarlo v-if="isLoaded" :initialInvestment="montecarloInitValues.initialInvestment" :perfManual="montecarloInitValues.perf" :volaManual="montecarloInitValues.vola"/>
                  <hr />
                </template>
              </template>
              <template v-if="isLoaded">
                  <Table v-if="performanceRows.length" ref="wertentwicklung"
                      tableId="02901a3e-901c-48fc-9415-8a3d523b6632"
                      :headers="newPerformanceHeaders"
                      :rows="performanceRows"
                      :rowsPerPage="0"
                      :showColumnsConfigEvenNoTitle="true"
                      :exportConfig="exportWertentwicklung"
                      :mobileConfig="{title: 'Fondsname', headers: ['Wertentwicklung', 'G_V_Saldo']}"
                      @click-Fondsname="navigateToFondsinfo"
                      @action-OEFFNEN="navigateToFondsinfo"
                      @action-ALT_KAUF="addDepotToWertpapierorder($event, 'ALT_KAUF')"
                      @action-ALT_VERKAUF="addDepotToWertpapierorder($event, 'ALT_VERKAUF')"
                      @action-ALT_TAUSCH="addDepotToWertpapierorder($event, 'ALT_TAUSCH')"
                      @action-ALT_SPARPLAN="addDepotToWertpapierorder($event, 'ALT_SPARPLAN')"
                      @action-ALT_ENTNAHMEPLAN="addDepotToWertpapierorder($event, 'ALT_ENTNAHMEPLAN')"
                      @action-LIMITS="actionLimit"
                      @action-COURTAGE_BGS="handleCourtagehistorie"
                      @action-TRANSAKTIONEN="navigateToTransactions"
                      @action-DEPOT_DETAILS="getDepotDetails"
                      @action-NEW_APPOIMENT="handleAddActivity"
                      @action-FACTSHEET="openFactsheetFile($event, 'FACTSHEET')"
                      @action-KAG="openFactsheetFile($event, 'MR')"
                      @action-PRIIP-BIB="openFactsheetFile($event, 'KIID')"
                    >
                      <template v-slot:info="row">
                        <div v-if="row.info" class="text-centered">
                          <Pill :label="row.info.label" :type="row.info.type" style="cursor: default"
                              v-fc-tooltip="{content: row.info.tooltip, className: 'info-tooltip'}"  />
                        </div>
                      </template>
                  </Table>
              </template>
              <GhostLoading v-else type="table" />
            </template>
          </div>
          <div v-else class="col-12">
            <NoData />
          </div>
        </div>

        <div class="row" v-if="visibleScreen === 'historische-wertentwicklung'">
          <div class="col-12 col-lg-12">
            <!-- <div class="box__title">Jahresperformance</div> -->
            <template>
              <template v-if="!historicalPerformanceLoading">
                <div class="row" v-if="historicalPerformance && historicalPerformance.data && historicalPerformance.data.records && historicalPerformance.data.records.length">
                  <div class="col">
                    <div class="historical-performance-chart app-vertical-bar" >
                      <BarChart chartDataName="Rendite" 
                      :chartData="getHistoricalPerformanceChartData(historicalPerformance.data, true)" 
                      format="percent" 
                      height="350px" />
                    </div>
                  </div>
                </div>
              </template>
              <GhostLoading v-else type="block" :config="{ block: { height: 350, }, }" />
              <template v-if="!historicalPerformanceLoading">
                <Table v-if="jahresPerformanceRows.length"
                    tableId="d7502c09-312f-48bc-86d7-239c30947bfc"
                    :headers="newJahresPerformanceHeaders"
                    :rows="jahresPerformanceRows"
                    rowId="Jahr"
                    :rowsPerPage="0"
                    :exportConfig="exportJahresperformance"
                    hidePagination
                    :mobileConfig= "{title: 'Jahr', headers: ['G_V', 'Rendite']}"
                    @action-DETAILS="showDetails"
                />
                <NoData v-else />
              </template>
              <GhostLoading v-else type="table" />
            </template>
          </div>
        </div>

        <div class="row" v-if="visibleScreen === 'realisierte-gewinne'">
          <div class="col-lg-12 col-12">
            <div class="row">
              <div class="col-12">
                <template v-if="!realisedProfitLoading">
                    <Table v-if="realisedProfitRows.length"
                        tableId="c0bd6ed6-915d-4d9a-9ae2-69b3754fcf11"
                        :headers="newRealisedProfitHeaders"
                        :rows="realisedProfitRows"
                        rowId="ISIN"
                        :rowsPerPage="0"
                        :exportConfig="exportGewinne"
                        :mobileConfig="{title: 'Wertpapiername', headers: ['Status', 'G_VBetrag', 'G_VProzent']}"
                        @click-Wertpapiername="navigateToFondsinfo"
                        @action-TRANSAKTIONEN="navigateToTransactions"
                        hidePagination
                    />
                    <NoData v-else />
                </template>
                <GhostLoading v-else type="table" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BaseModal
      ref="notImplemented"
      modalTitle="Nicht implementiert"
      labelButtonConfirm="Ok"
      :showCancelButton="false"
      >
      Formular noch nicht implementiert
    </BaseModal>
    <BaseModal
      ref="modalDepotDetails"
      :modalTitle="modalArg.title"
      :showConfirmButton="modalArg.showConfirmButton"
      :labelButtonConfirm="modalArg.labelButtonConfirm"
      :labelButtonCancel="modalArg.labelButtonCancel"
      @onConfirmButton="modalArg.onConfirm" > 
        <div v-html="modalArg.body"></div>
    </BaseModal>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import PORTFOLIO_TYPES from '@/store/portfolio/types';
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";
import GRAPHICAL_COURSE_TYPES from '@/store/graphicalCourse/types';
import { mapGetters } from 'vuex';
import Table from '@/components/table2/Table.vue';
import {
  oldToNewColumns,
  ActionColumn,
  SimpleAction,
  SlotColumn,
  LinkAction,
} from "@/components/table2/table_util.js";
import BaseButton from '@/components/core/BaseButton.vue';
import axios from 'axios';
import dayjs from 'dayjs';
import ChartGraphicalCourse from '@/components/charts/ChartGraphicalCourse.vue';
import {DatePickerUtils} from '@/components/core/forms/DatePicker/date-picker-utils.js';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import BarChart from '@/components/charts/echarts/BarChart.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderPortfolioSelectorAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { formatNumber, parse } from '@/helpers/number-formatter.js';
import { ROLES, VIEW_ROLES } from '@/router/roles.js'
import BaseFilter from "@/components/core/BaseFilter.vue";
import Pill from '@/components/core/Pill.vue'
import BaseModal from "@/components/core/BaseModal.vue";
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import CALENDAR_TYPES from '@/store/calendar/types';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

import { openLink } from '@/link-resolvers';
import { ResolverId } from '@/link-resolvers/types';
import ChartMontecarlo from '../charts/ChartMontecarlo.vue';

const visibleScreenOptions = {
  customPeriod: 'eigener-zeitraum',
  fromDate: 'fromDate',
  historical: 'historische-wertentwicklung',
  profits: 'realisierte-gewinne',
  historicalAction: 'historische-action',
  profitsAction: 'realisierte-action',
}

export default {
    mixins: [InteractiveHelpCommonsMixin],
	data() {
		return {
      prevId: '',
      historicalPerformanceChartData: [],
      routeParams: null,
      visibleScreen: visibleScreenOptions.historical,
      realisedHeaders: {
        sum: {
          Einzahlungen: true,
          'Erlös': true,
          G_VBetrag: true,
        }
      },

      noInitAppoiment: false,
      modalArg: {
        title: '',
        showConfirmButton: false,
        labelButtonConfirm: '',
        labelButtonCancel: '',
        body: '',
        onConfirm: () => {}
      },

      dateBegin: new Date(1988, 0, 2),
      berechnungsbeginn: null,
      years: [],
      performanceFilteredRequest: {
        value: new Date().getFullYear(),
        modus: 'year',
        forceUpdate: false,
        id: this.id,
      },
      chartActionType: {
        dateBegin: new Date( new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()),
        dateEnd: new Date(),
      },
      exportWertentwicklung: {
        roottext: 'Wertentwicklung',
        dispatch: this.doExportWertentwicklung,
      },
      exportJahresperformance: {
        roottext: 'Jahresperformance',
      },
      exportGewinne: {
        pdf: {
          show: true,
          name: 'Realisierte GuV ' + dayjs(new Date()).format('DD-MMM-YYYY HH mm'),
          title: 'Realisierte Gewinne und Verluste - FULLNAME',
        },
        xls: {
          show: true,
          name: 'Realisierte GuV ' + dayjs(new Date()).format('DD-MMM-YYYY HH mm'),
          title: 'Realisierte Gewinne und Verluste - FULLNAME',
        }
      },
      historicalPerformanceLoading: false,
      realisedProfitLoading: false,
      chartVariant: 'aggregate',
      defaultOptions: {
        bybegin: {value: true},
        aggregate: {value: '1'},
      },
      isMounted: false,
    }
  },
  computed: {
    ...mapGetters({
      realisedProfit: PORTFOLIO_TYPES.GETTERS.REALISED_PROFIT,
      historicalPerformance: PORTFOLIO_TYPES.GETTERS.HISTORICAL_PERFORMANCE,
      performance: PORTFOLIO_TYPES.GETTERS.PERFORMANCE_FILTERED,
      // configData: PORTFOLIO_TYPES.GETTERS.DEPOT_CONFIG,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isLoading: CORE_TYPES.GETTERS.GLOBAL_LOADING_STATE_STATUS,
      id: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      depotTitle: DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_DEPOT_TITLE,
      isLoaded: PORTFOLIO_TYPES.GETTERS.IS_LOADED,
      isChartGCLoadingGetter: GRAPHICAL_COURSE_TYPES.GETTERS.IS_DEPOT_LOADING,
      isChartGCDepotHasDataGetter: GRAPHICAL_COURSE_TYPES.GETTERS.DEPOT_HAS_DATA,
      sessionData: GRAPHICAL_COURSE_TYPES.GETTERS.SITE,
      screenWidth: CORE_TYPES.GETTERS.SCREEN_WIDTH,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      configGC: GRAPHICAL_COURSE_TYPES.GETTERS.CONFIG,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
    }),
    montecarloInitValues() {
      console.log(this.sessionData('page'))
      if(this.isLoaded) {
        const series = this.sessionData('page').series?.find(series => series.name === 'Aktueller Wert' )
      console.log(series)
      if(series) {
        const historicDurationMonths = this.monthDiff(this.sessionData('page').beginDate, this.sessionData('page').endDate);
        const vola = (series.config.vola / Math.pow(Math.ceil(historicDurationMonths / 12.0), 0.5)) / 100.0; // convert to yearly vola
        const perf = Math.pow(1.0 + (series.config.we / 100.0), 1.0 / historicDurationMonths) - 1.0//convert to motnhly performance
        const initialInvestment = series.data[0];
        console.log(`vola: ${vola}, perf: ${perf}, startValue: ${initialInvestment}`)
        return {vola, perf, initialInvestment}
      }
      }

      return {vola: 0, perf: 0};
    },
    isChartGCLoading() {
      return this.isChartGCLoadingGetter(this.id);
    },
    isChartGCDepotHasData() {
      return this.isChartGCDepotHasDataGetter(this.id);
    },
    chartVariants() {
      const session = this.sessionData('page');
      if (session && session.charts?.length ) {
        return session.charts;
      }
      return [{ value: 'aggregate', label: 'Grafischer Verlauf' }];
    },
    historicalHeaders() {
      const digits_only = string => string ? [...string].every(c => '0123456789.,+-'.includes(c)) : false;
      return {
        sum: {
          Einzahlungen: true,
          Auszahlungen: true,
          Saldo: true,
          Wechsel: true,
          G_V: true,
        },
        autoSum: {
          Wertentw_pro_Jahr: digits_only(this.performance?.collection?.yearPerformance) ? parseFloat(this.performance?.collection?.yearPerformance): null,
          Wertentwicklung: digits_only(this.performance?.collection?.totalPerformance) ? parseFloat(this.performance?.collection?.totalPerformance): null,
        }
      }
    },
    newPerformanceHeaders() {
        if (!this.performance?.collection?.data)
            return {};
        return {
            lockedLeft: [SlotColumn('info', 'Info', 40, 0).makeAlwaysVisible()],
            center: oldToNewColumns(this.performance.collection.data.tableHeaders, this.performanceHeaders.sum, false, this.performanceHeaders.autoSum)
            .map(header => {
                if (header.key === 'Fondsname') {
                  header.makeLink();
                } else if (header.key === 'Wertentwicklung') {
                  header.label = 'Wertentwicklung (Ges' + (this.screenWidth == 'xs' ? '.' : 'amt') + ')' ;
                }
                return header;
            }),
            lockedRight: [
              ActionColumn("actions", "Aktionen"),
            ],
        };
    },
    performanceRows() {
      if (!this.performance?.collection?.data?.rows) {
        return [];
      }

      const mapActions = (record, index) => {
        const actions = [];

        actions.push(SimpleAction("OEFFNEN", '', "Öffnen"));

        if (this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS])) {
          const availableWPActions = record.wertpapierorderActions && Object.keys(record.wertpapierorderActions) || [];
          const wertpapierorderActions = [
            SimpleAction("ALT_KAUF", '', "Nachkaufen"),
            SimpleAction("ALT_VERKAUF", '', "Verkaufen"),
            SimpleAction("ALT_TAUSCH", '', "Tausch"),
            SimpleAction("ALT_SPARPLAN", '', "Sparplan verwalten"),
            SimpleAction("ALT_ENTNAHMEPLAN", '', "Entnahmeplan verwalten"),
          ];

          wertpapierorderActions.forEach(action => {
            if (availableWPActions.includes(action.actionKey)) {
              actions.push(action);
            }
          });

          actions.push(SimpleAction("COURTAGE_BGS", '', "Courtagehistorie (PDF)"));
        }

        actions.push(SimpleAction("LIMITS", '', "Limits"));

        record.transaktionActions?.forEach(act => {
          if (act?.href && act?.label) {
            actions.push(LinkAction("TRANSAKTIONEN_PDF", '', act.label, act.href));
          } else if (act?.label) {
            actions.push(SimpleAction("TRANSAKTIONEN", '', act.label));
          }
        });

        if (record.infoDepot && record.infoSparplan) {
          actions.push(SimpleAction("DEPOT_DETAILS", '', 'Details anzeigen'));
        }

        if (this.isBrokerOrBypass) {
          actions.push(SimpleAction("NEW_APPOIMENT", '', "Neue Aufgabe"));
        }

        if (record.urlFactsheet) {
          actions.push(SimpleAction("FACTSHEET", '', "Factsheet"));
        }

        if (record.urlKag || record.kag === 'pdf') {
          actions.push(SimpleAction('KAG', '', 'KAG'));
        }

        if (record.urlKid || record.kid === 'pdf') {
          actions.push(SimpleAction('PRIIP-BIB', '', 'PRIIP-BIB'));
        }

        return {
          ...record,
          id: `${record.isin}_${index}`,
          actions
        }
      }

      const rows = this.performance.collection.data.rows;
      return rows.map(mapActions);
    },
    performanceHeaders() {
      const digits_only = string => string ? [...string].every(c => '0123456789.,+-'.includes(c)) : false;

      return {
        sum: {
          Einzahlungen: true,
          Auszahlungen: true,
          Wechsel: true,
          G_V_Saldo: true,
          Anfangsbestand: true,
          Endbestand: true,
        },
        autoSum: {
          Wertentw_pro_Jahr: digits_only(this.performance?.collection?.yearPerformance) ? parseFloat(this.performance?.collection?.yearPerformance): null,
          Wertentwicklung: digits_only(this.performance?.collection?.totalPerformance) ? parseFloat(this.performance?.collection?.totalPerformance): null,
        }
      }
    },
    newRealisedProfitHeaders() {
        if (!this.realisedProfit?.data)
            return {};
        return {
            center: oldToNewColumns(this.realisedProfit.data.tableHeaders, this.realisedHeaders.sum, false, this.realisedHeaders.autoSum)
                .map(header => {
                    if (header.key === 'Wertpapiername')
                        header.makeLink();
                    return header;
                }),
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        };
    },
    realisedProfitRows() {
        if (!this.realisedProfit?.data)
            return [];
        const actions = [
            SimpleAction("TRANSAKTIONEN", '', "Transaktionen"),
        ];
        return this.realisedProfit.data.records.map(values => {
            const row = {
                actions,
            };
            values.forEach((v, i) => {
                if (this.realisedProfit.data.tableHeaders[i].dataType !== 'Action')
                    row[this.realisedProfit.data.tableHeaders[i].key] = v;
            });
            row.id = (row.Depotnummer || '') + row.Wertpapiername;
            return row;
        });
    },
    newJahresPerformanceHeaders() {
        if (!this.historicalPerformance?.data)
            return {};
        return {
            center: oldToNewColumns(this.historicalPerformance.data.tableHeaders, this.historicalHeaders.sum, false, this.historicalHeaders.autoSum)
              .map(col => {
                if (col.key == 'G_V') {
                  col.setColorFn(row => this.depotauszugGuvFarbe && this.computedGuvSumColor(row))
                }
                return col;
              }),
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        };
    },
    depotauszugGuvFarbe() {
      return this.hasRoles(ROLES.DEPOTAUSZUG_GUV_FARBE);
    },
    jahresPerformanceRows() {
        if (!this.historicalPerformance?.data)
            return [];
        return this.historicalPerformance.data.records.map(values => {
            const row = {};
            values.forEach((v, i) => {
                if (this.historicalPerformance.data.tableHeaders[i].dataType !== 'Action') {
                  row[this.historicalPerformance.data.tableHeaders[i].key] = v;
                }
                row.actions = [SimpleAction("DETAILS", '', "Details")]
            });
            return row;
        });
    },
    headerActions() {
      return [
        PageHeaderPortfolioSelectorAction('SELECT-DEPOT'),
      ];
    },
    beginLabel() {
      if (this.berechnungsbeginn && this.dateBegin && this.berechnungsbeginn.getTime() > this.dateBegin.getTime()) {
        return dayjs(this.berechnungsbeginn).format('DD MMM YYYY') + ' (Berechnungsbeginn G & V)';
      } else if (this.dateBegin) {
        return dayjs(this.dateBegin).format('DD MMM YYYY')
      }
      return ''
    },
    beginDate() {
      if (this.berechnungsbeginn && this.dateBegin && this.berechnungsbeginn.getTime() > this.dateBegin.getTime()) {
        return this.berechnungsbeginn;
      } else if (this.dateBegin) {
        return this.dateBegin
      }
      return null
    },
    searchMenu() {
      return [
        {
          type: "group",
          key: "allgemein",
          label: "Wertentwicklung",
          allowMultipleSearch: false,
          menuItems: [
            {
              type: "text",
              key: "bymonth",
              label: "der letzten Monate",
            },
            {
              type: "combobox",
              key: "byyear",
              label: "im Jahr",
              comboOptions: this.years.map(year => ({label: year, value: year}))
            },
            {
              type: "dateRange",
              key: "byrange",
              label: "im Zeitraum",
            },
            {
              type: "default",
              key: "bybegin",
              label: `seit Beginn (${this.beginLabel})`,
            },
          ]
        },
        {
          type: "group",
          key: "grafik",
          label: "Grafik",
          menuItems: this.chartVariants.map(v => {
            if (v.value ==='aggregate') {
              return {
                type: 'combobox',
                key: v.value,
                label: v.label,
                comboOptions: [
                  {
                    label: 'mit Glätten',
                    value: '1',
                  },
                  {
                    label: 'ohne Glätten',
                    value: '2',
                  },
                ]
              }
            }
            return {
              type: 'default',
              key: v.value,
              label: v.label,
            }
          })
        },
      ];
    },
  },
  watch: {
    '$route'(to, from) {
      const params = this.routeParams = to.params;
      this.visibleScreen = params['tab'] || 'eigener-zeitraum';
      if (to.name) {
        this.visibleScreen = to.name;
      }
    },
    'id': function (id) {
      this.retrieveData();
    },
    configGC: {
      handler() {
        if (this.configGC?.berechnungsbeginn) {
          const part = this.configGC.berechnungsbeginn.split('.').map(p => parseInt(p, 10));
          this.berechnungsbeginn = new Date(part[2], part[1] -1, part[0]);
          if (this.isMounted) { // nach mounted
            this.patchForm();
            this.emitFilterEvent()
          }
        }
      },
      immediate: true
    },
    beginDate() {
      this.years = [];
      if (this.beginDate) {
        for (let i = this.beginDate.getFullYear(); i <= new Date().getFullYear(); i++) {
          this.years.unshift(''+i);
        }
      }
    }
  },
  methods: {
    monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
},
    handleAddActivity(row) {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_ACTIVITY);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE, []);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA, {
        appointment: {
          appointment: {
            label: row ? row.wertpapiername : '',
            activity: true,
            bereich: 'Kunde',
            bereichDisplay: 'Kunde ' + this.loginData.fullname + ' (' + this.loginData.userid + ')',
            bereichId: this.loginData.userid,
          }
        }
      });
      this.noInitAppoiment = true;
      this.openAppointment();
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aufgabe bearbeiten',
        noInitialise: this.noInitAppoiment,
        attachCustomer: false,
        discardChanges: this.resetAppointment,
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    resetAppointment() {
      this.noInitAppoiment = false;
    },
    getDepotDetails(record) {
      if (record.infoDepot || record.infoSparplan) {
        this.modalArg.showConfirmButton = false;
        this.modalArg.labelButtonConfirm = '';
        this.modalArg.labelButtonCancel = 'Schließen';
        this.modalArg.body = `${record.infoDepot || ''}${record.infoSparplan || ''}`;
        this.modalArg.onConfirm = () => {};
        this.$refs.modalDepotDetails.open();
      }
    },
    addDepotToWertpapierorder(row, category) {
      const specificAction = row.wertpapierorderActions[category];
      const begleitscheinNr = row.begleitscheinNr;
      if (!specificAction) {
        this.$refs.notImplemented.open();
      } else {
        this.$router.push(`/beratung/formulare/antrag/${specificAction}initData=true&bgsNr=${begleitscheinNr}&category=${category}`).catch(() => {});
      }
    },
    handleCourtagehistorie(row) {
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.COURTAGE_BGS, {bgsnr: row.begleitscheinNr?.toString()})
        .then(data => {
          if (data?.pdf) {
            viewDocument({
              data: data.pdf,
              filename: 'Depotposition.pdf',
              contentType: 'application/pdf',
            }, false);
          }
        });
    },
    actionLimit(row) {
      this.navigateTo(`/home/vermogensubersicht/wertpapiere/limits/${row.isin}/${row.begleitscheinNr}`)
    },
    computedGuvSumColor(row) {
      const doubleValue = parse(row?.G_V);
      if (doubleValue > 0) {
        return "color-success";
      } else if (doubleValue < 0) {
        return "color-danger"
      }
      return "text-color";
    },
    showDetails(row) {
      if (row?.Jahr) {
        this.$addBreadcrumb({
          label: `zurück zur Jahresperformance`,
          fullPath: this.$route.fullPath,
          breadcrumb: 'Jaresperformance Details',
        });
        this.$store.dispatch(PORTFOLIO_TYPES.ACTIONS.GET_HISTORICAL_PERFORMANCE_DETAILS, {year: row.Jahr, depotId: this.id} );
        this.$router.push('/home/berichte/year-performance-details');
      }
    },
    retrieveData() {
      if (!this.prevId || this.prevId !== this.id) {
        this.retrieveHistoricalPerformance();
        this.retrieveRealisedProfit();
        if (this.prevId && this.prevId !== this.id) {

          this.doSearch({
            ...this.performanceFilteredRequest,
            forceUpdate: !!this.prevId,
            id: this.id
          })
        }
        this.prevId = this.id;
        axios.get(`${process.env.VUE_APP_API}/../mrsgetdepotmindate?depotid=${this.id}`).then(date => {
          this.setDateBegin(date.data);
        });
      }
    },
    async retrieveHistoricalPerformance() {
      try {
        this.historicalPerformanceLoading = true;
        await this.$store.dispatch(PORTFOLIO_TYPES.ACTIONS.GET_HISTORICAL_PERFORMANCE, { id: this.id });
      } finally {
        this.historicalPerformanceLoading = false;
      }
    },
    async retrieveRealisedProfit() {
      try {
        this.realisedProfitLoading = true;
        await this.$store.dispatch(PORTFOLIO_TYPES.ACTIONS.GET_REALISED_PROFIT, { id: this.id });
      } finally {
        this.realisedProfitLoading = false;
      }
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    setDateBegin(begin) {
      if (begin?.value != null) {
        begin = begin.value;
      }
      if (begin && begin.match) {
        const dateParts = begin.match(/(\d+)/g);
        this.dateBegin = DatePickerUtils.safeDate(new Date(dateParts[2], dateParts[1] - 1, dateParts[0]));
        
      }
    },
    indexOfRow(items, name) {
      return items.findIndex(item => item.key === name);
    },
    getHistoricalPerformanceChartData(tableData, showHistoricalPerformance = true) {
      if (tableData && tableData.isError || tableData && !tableData.records) {
        return [];
      }
      // if (this.historicalPerformanceChartData && this.historicalPerformanceChartData.length
      //     || (showHistoricalPerformance && !tableData.records.length)) {
      //     return this.historicalPerformanceChartData;
      // }
      const yearIndex = this.indexOfRow(tableData.tableHeaders, 'Jahr');
      const sumIndex = this.indexOfRow(tableData.tableHeaders, 'Rendite');
      let records = [...tableData.records];
      records = records.sort((a, b) => b[yearIndex] - a[yearIndex]);
      // tableData.records.length = tableData.records.length > 10 ? 10 : tableData.records.length;
      records = records.sort((a, b) => a[yearIndex] - b[yearIndex]);
      const historicalPerformanceChartData = {
        name: 'Rendite',
        data: records.map(row => {
          return {
          name: `${row[yearIndex]}`,
          value: row[sumIndex]
          };
        })
      }

      return historicalPerformanceChartData?.data || [];
    },
    createForm() {
      if (!this.years.length) {
        for (let i = this.dateBegin.getFullYear(); i <= new Date().getFullYear(); i++) {
          this.years.unshift(''+i);
        }
      }
      this.patchForm();
    },
    patchForm() {
      let key = null;
      let value = null;

      if (this.configGC?.depotauszugZeitraumArt) {
        switch (this.configGC.depotauszugZeitraumArt) {
          case 'letztenMonate':
            key = 'bymonth';
            value = {value: this.configGC.depotauszugZeitraumWert};
            break;
          case 'imJahr':
            key = 'byyear';
            value = {value: this.configGC.depotauszugZeitraumWert};
            break;
          case 'imZeitraum': 
            key = 'byrange';
            const dateFrom = this.convertStringToDate(this.configGC.depotauszugZeitraumWert);
            value = [{key: 'min', value: dateFrom}, {key: 'min', value: new Date()}]
            break;
          default:
            key ='bybegin'
            value = {value: true}
            break;
        }
      } else {
        key = 'bymonth'
        value = {value: 12}
      }

      this.defaultOptions = {
        [key]: value,
        aggregate: {value: '1'},
      }

      this.$refs.baseFilter?.recreateRows()
    },
    emitFilterEvent() {
      this.$refs?.baseFilter?.emitFilterEvent()
    },
    async onFilter(filterConfig) {
      let kind = null
      let smooth = null

      filterConfig.forEach(fc => {
        if (fc.group === 'grafik') {

          if (fc.key === 'aggregate') {
            smooth = fc.value == '1' ? true : false
          }

          kind = fc.key
        } else if (fc.group === 'allgemein') {
            let dateBegin = new Date();
            let dateEnd = new Date();
            let value = fc.value

            switch (fc.key) {
              case 'bymonth':
                if (!value) {
                  value = 12;
                }
                this.performanceFilteredRequest = {
                  modus: 'month',
                  value,
                };

                this.chartActionType = {
                  dateBegin: new Date( dateEnd.getFullYear(), dateEnd.getMonth() - value, dateEnd.getDate() + 1),
                  dateEnd: dateEnd,
                };
                break;
              case 'byyear':
                if (!value) {
                  value = dateEnd.getFullYear();
                }
                this.performanceFilteredRequest = {
                  modus: 'year',
                  value,
                };

                this.chartActionType = {
                  dateBegin: DatePickerUtils.safeDate(new Date(value, 0, 1)),
                  dateEnd: DatePickerUtils.safeDate(new Date(value, 11, 31)),
                };
                break;
              case 'bybegin':
                this.performanceFilteredRequest = {
                  modus: 'begin',
                };

                this.chartActionType = {
                  dateBegin: new Date(0),
                  dateEnd: dateEnd,
                };
                break;
              case 'byrange':
                let dateFrom = this.convertStringToDate(value.find(v => v.key == 'min').value)
                let dateTo = this.convertStringToDate(value.find(v => v.key == 'max').value)

                if (!dateFrom) {
                  dateFrom = new Date(dateEnd.getFullYear(), 0, 1);
                }
                if (!dateTo) {
                  dateTo = dateEnd;
                }

                dateBegin = DatePickerUtils.safeDate(dateFrom) || new Date();
                dateEnd = DatePickerUtils.safeDate(dateTo) || new Date();

                if (dateBegin.getTime() < this.beginDate.getTime()) {
                  dateBegin = this.beginDate;
                }

                this.performanceFilteredRequest = {
                  dateBegin: dayjs(dateBegin).format('DD.MM.YYYY'),
                  dateEnd: dayjs(dateEnd).format('DD.MM.YYYY'),
                };

                this.chartActionType = {
                  dateBegin: dateBegin,
                  dateEnd: dateEnd
                };
                break;
            }
        }
      })

      this.$store.commit(PORTFOLIO_TYPES.MUTATIONS.SET_IS_LOADED, false);
      await this.doSearch({
        ...this.performanceFilteredRequest,
        forceUpdate: false,
        id: this.id
      })
      this.chartActionType.site = 'page';
      this.$store.commit(GRAPHICAL_COURSE_TYPES.MUTATIONS.ZOOM, {...this.chartActionType, site: 'page', kind, smooth} );
    },
    doSearch(filterParams = this.performanceFilteredRequest) {
      return this.$store.dispatch(PORTFOLIO_TYPES.ACTIONS.GET_PERFORMANCE_FILTERED, filterParams);
    },
    navigateToFondsinfo(row) {
      if (row) {
        this.navigateTo(`/shared/vermogensubersicht/fondsinfo/${row.isin || row.Transaktionen}/${this.id}`);
      }
    },
    navigateToTransactions(row) {
      if (row) {
        const bodyParams = JSON.stringify(JSON.parse(`{"isin": "${row.isin || row.Transaktionen}"}`));
        const params = encodeURIComponent(bodyParams);
        this.navigateTo(`/home/berichte/transaktionen?params=${params}`);
      }
    },
    doExportWertentwicklung(fileEnding) {
      let headers
      if (this.$refs.wertentwicklung?.$refs?.sortableTable?.visibleHeadersFlat) {
        headers = this.$refs.wertentwicklung.$refs.sortableTable.visibleHeadersFlat.filter(header => header.key !== 'isin' && header.exportFn);
      } else {
        headers = this.newPerformanceHeaders.center.filter(header => header.key !== 'isin');
      }
      const title = headers.map(header => header.key == 'Wertentwicklung' ? 'Wertentwicklung' : header.label);
      let rows, footer=null;
      if (this.$refs.wertentwicklung?.$refs?.sortableTable?.exportedRows) {
        rows = this.$refs.wertentwicklung.$refs.sortableTable.exportedRows;
      } else {
        rows = this.performanceRows;
        footer = headers.map(header => {
          if (header.key == 'Fondsname') {
            return 'Summe';
          }
          if (header.footer != null) {
            let result = 0;
            if (typeof(header.footer) != "function") {
              result = header.footer;
            } else {
              result = this.performanceRows.reduce( (sum, row) => sum += parseFloat(row[header.key]), 0);
            }
            result = formatNumber(result, 2) + (header.props?.symbol ? header.props.symbol : '')
            return result;
          }
          return '';
        });
      }
      rows = rows.map(row => {
        return headers.map(header => {
          let cell = header.exportFn(row[header.key], row, header) || '';
          if (cell.indexOf('NaN') == 0) {
            cell = 'z.Zt. nicht möglich';
          }
          return cell;
        });
      });
      if (footer) {
        rows.push(footer);
      }
      
      const name = 'Wertentwicklung ' + dayjs(new Date()).format('DD-MMM-YYYY HH mm');
      let dbeg = (this.performanceFilteredRequest.modus == 'begin') ? this.dateBegin : this.chartActionType.dateBegin;
      if (this.performanceFilteredRequest.modus == 'begin' || this.performanceFilteredRequest.modus == 'year') {
        dbeg = new Date(dbeg.getTime() - 86400000);
      }
      let dend = this.chartActionType.dateEnd;
      if (dend.getTime() > new Date().getTime()) {
        dend = new Date();
      }
      const von = dayjs(dbeg).format('DD.MM.YYYY');
      const bis = dayjs(dend).format('DD.MM.YYYY');
      const params = {
          create: fileEnding,
          filename: name + '.' + fileEnding,
          title: `Wertentwicklung von ${von} bis ${bis} - ${this.fullname}`,
          columns: title,
          rows: rows,
          printMaklerLogo: '0',
      };
      return this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params);
    },
    cacheLeeren() {
      this.$store.dispatch(GRAPHICAL_COURSE_TYPES.ACTIONS.CACHE_LEEREN, this.id);
    },
    convertStringToDate(value) {
      if (!value)
        return
      if (Object.prototype.toString.call(value) === '[object Date]')
        return value

      const parts = value.split('.').map(p => parseInt(p, 10));
      if (parts.length != 3)
        return

      return new Date(parts[2], parseInt(parts[1]) - 1, parseInt(parts[0]));
    },
    openFactsheetFile(row, type) {
      openLink(ResolverId.Factsheet, {
        type,
        isin: row.isin,
      });
    },
  },
	mounted() {
    const params = this.routeParams = this.$route.params;
    this.visibleScreen = params['tab'] || 'eigener-zeitraum';
    if (this.$route.name) {
      this.visibleScreen = this.$route.name;
    }
    this.retrieveData();

    this.createForm();
    this.$store.dispatch(GRAPHICAL_COURSE_TYPES.ACTIONS.CONFIG);
    this.$store.dispatch(PORTFOLIO_TYPES.ACTIONS.GET_DEPOT_CONFIG, {});
    // this.$store.dispatch(GRAPHICAL_COURSE_TYPES.ACTIONS.DEPOT, {site: 'page', depotid: -1});
    this.isMounted = true
	},
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from});
    next();
  },
  components: {
    BaseModal,
    Table,
    OptionMenu,
    BaseButton,
    ChartGraphicalCourse,
    BarChart,
    GhostLoading,
    NoData,
    PageHeaderTitleNavigation,
    BaseFilter,
    Pill,
    ChartMontecarlo
	}
}
</script>

<style scoped>
  .historical-performance-chart {
    /* height: 550px; */
    width: 100%;
  }
</style>
