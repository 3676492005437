<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

        <PageHeaderTitleNavigation
            :id="$appNavigation.currentOptionMenuId"
            title="Aufgaben"
            :actions="headerActions"
            @action-NEW_AUFGABE="newTask"
        />

        <BaseFilter
            v-if="defaultOptions"
            title="Aufgaben Filter"
            filterId="PostfachTaskFilter"
            :isCache="hasFilterCache"
            :configFilter="filterConfig"
            :metadata="metadata"
            :defaultOptions="defaultOptions"
            showSaveButton
            hasSmartSearch
            immidiateSearch
            @onFilter="onFilter"
        />

        <div class="box__container">
            <GhostLoading v-if="loading && !scrollLoading" type="table" :config="{table: {rows: 10}}" />
            <PaginatedTable
                v-else
                title="Aufgaben"
                tableId="015eb9cf-6e4f-4dca-b9e3-52f0b49736bd"
                rowId="rowid"
                v-model="selectedRows"
                :headers="headers"
                :page="selectedPage"
                :pages="pages"
                :pageCount="pageCount"
                :rowCount="rowCount"
                :headerActions="tableHeaderActions"
                :exportConfig="exportConfig"
                :mobileConfig="mobileConfig"
                @page="selectedPage = $event"
                @requestPage="loadPage"
                @sort="onSearchSort"
                :sorted="searchSort"
                @onScroll="onScroll"
                @scrollLoading="scrollLoading = $event"
                :selectionAsBlacklist="invertSelection"
                @selectionAsBlacklist="invertSelection = $event"
                @click-subject="clickBetreff"
                @click-bereich="clickBereich"
                @action-DONE="doneRow"
                @action-DELETE="deleteRow"
                @headerAction-BULK_DELETE="deleteSelected"
                @headerAction-BULK_DONE="doneSelected"
            >
                <template v-slot:participants="row">
                    <ul style="padding: 0; margin: 0">
                        <li
                            style="display:inline-block;"
                            v-for="(participant, index) in row.participants"
                            :key="participant.id"
                        >
                            <a
                                v-if="participant.isKunde && canOpenCustomer(participant) && !row.mobileTableContext"
                                @click="openCustomerNewTab(participant.nummer)"
                                >{{ participant.name }}</a
                            >
                            <span v-else>{{ participant.name }}</span>
                            <span v-if="index < row.participants.length - 1">;&nbsp;</span>
                        </li>
                    </ul>
                </template>
            </PaginatedTable>
        </div>
    
    <BaseModal 
        ref="modalStatus"
        modalTitle="Aufgabe wiederholen?"
        :showConfirmButton="true"
        labelButtonConfirm="Aktuelle Aufgabe bestätigen"
        labelButtonCancel="Alle Aufgaben bestätigen"
        @onConfirmButton="doRepeate()"
        @onCancelButton="dontRepeate()"> 
          <div>
            Diese Aufgabe soll wiederholt werden. Alle Wiederholungen bestätigen?
          </div>
      </BaseModal>
    </div>
</template>

<script>
import CALENDAR_TYPES from "@/store/calendar/types"
import CORE_TYPES from "@/store/core/types"
import COMMUNICATION_TYPES from "@/store/communication/types"
import PaginatedTable from "@/components/table2/PaginatedTable.vue"
import {
    TextColumn,
    SlotColumn,
    ActionColumn,
    ConfirmedAction,
    DateColumn,
    CurrencyColumn,
    PillColumn,
} from "@/components/table2/table_util.js"
import postfachTableMixin from "@/views/communication/postfach/tables/postfach-table-mixin.js"
import axios from "axios"
import dayjs from "dayjs"
import {BASE_AXIOS_CONFIG as config} from "@/configs/http-request-interceptor"

import BaseFilter from "@/components/core/BaseFilter.vue"
import {mapGetters} from "vuex"
import {
    PageHeaderSimpleAction,
    PageHeaderConfirmedAction,
} from "@/components/core/header-title-navigation/page-header-utils"
import {VIEW_ROLES} from "@/router/roles"
import {makeQueryParam} from "@/helpers/utils-helper"
import BaseModal from "@/components/core/BaseModal.vue"

const LINK_ACTIONS = ["VersVertrag", "Kunde", "Makler", "Besuchsbericht"]
const ROWS_PER_PAGE_DEFAULT = 20

export default {
    mixins: [postfachTableMixin],
    components: {
        PaginatedTable,
        BaseFilter,
        BaseModal,
    },
    data() {
        return {
            currentMode: "",
            category: "aufgaben",
            mailboxLabel: "Aufgabe",
            defaultOptions: null,
            hasFilterCache: true,
            filterConfig: {
                placeholderForDefSearchInput: "Betreff, Besitzer, Teilnehmer",
                noResetOnDefaultSearchInputExit: true,
                checkDefaultSearchInput: false,
                hideFirstColumn: true,
            },
            mobileConfig: {
                title: "name",
                headers: ["subject", "date", "participants", "type", "status"],
            },
            searchSort: {},
            invertSelection: false,
            pageList: {},
            rowCount: 0,
            ROWS_PER_PAGE_DEFAULT,
            onScrollEnd: () => {},
            scrollLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            postfachDefaultFilters: COMMUNICATION_TYPES.GETTERS.POSTFACH_DEFAULT_FILTERS,
            getAppointmentCombos: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_COMBOS,
            fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
        }),
        headers() {
            // IMPORTANT note: The keys of the headers are used to compare in PostfachTerminService.getComparatorBySortKey
            return {
                lockedLeft: [
                    DateColumn("date", "Datum"),
                    PillColumn("wvStatus", ""),
                    TextColumn("subject", "Betreff").makeLink(),
                ],
                center: [
                    TextColumn("name", "Besitzer", 250),
                    SlotColumn("participants", "Teilnehmer", 80, 1),
                    TextColumn("bereich", "Bereich", 400)
                        .makeConditionalLink("hasOpenAction")
                        .addCellProps({lineClamp: 2}),
                    TextColumn("text", "Text", 400).addCellProps({lineClamp: 4}),
                    TextColumn("art", "Art"),
                    TextColumn("telefon", "Telefon"),
                    TextColumn("mobil", "Mobil"),
                    DateColumn("dateCreated", "Erstellungsdatum"),
                    CurrencyColumn("geschaeftwert", "Geschätzter Geschäftswert"),
                    TextColumn("produkt", "Produkt"),
                    TextColumn("verkaufphase", "Verkaufphase"),
                    TextColumn("status", "Status"),
                ],
                lockedRight: [ActionColumn("actions", "Aktionen")],
            }
        },
        pages() {
            const result = {}
            Object.entries(this.pageList).forEach(([page, records]) => {
                result[page] = records.map((record) => ({
                    ...record,
                    wvStatus: this.getWVStatus(record.wvStatus),
                    hasOpenAction:
                        !this.isCustomerOnly &&
                        Object.keys(record.bereichOeffnen || {}).length &&
                        LINK_ACTIONS.includes(record.bereichOeffnen.bereich),
                    rowid: "" + record.commId + record.date,
                    subject: record.subject || "[kein Betreff]",
                    actions: this.makeActions(record),
                }))
            })
            return result
        },
        pageCount() {
            return Math.ceil(this.rowCount / ROWS_PER_PAGE_DEFAULT)
        },
        metadata() {
            const statuses = this.getAppointmentCombos?.wvStatusFilter || []
            const arten = {
                ...(this.getAppointmentCombos?.artComboWV || []),
                KEINE_ANGABEN: "ohne Angabe",
            }
            const result = [
                {
                    type: "group",
                    key: "allgemein",
                    label: "Allgemein",
                    menuItems: [
                        {
                            type: "text",
                            label: "Betreff",
                            key: "subject",
                        },
                        {
                            type: "default",
                            label: "nur mich als Besitzer",
                            key: "isOwner",
                        },
                        {
                            type: "default",
                            label: "nur zukünftige Aufgaben",
                            key: "isFuture",
                        },
                        {
                            type: "text",
                            label: "Besitzer",
                            key: "owner",
                        },
                        {
                            type: "text",
                            label: "Teilnehmer",
                            key: "participant",
                        },
                        {
                            type: "dateRange",
                            label: "Zeitraum",
                            key: "zeitraum",
                        },
                        {
                            type: "datepicker",
                            label: "Datum",
                            key: "datum",
                        },
                        {
                            type: "combobox",
                            label: "Status",
                            key: "status",
                            comboOptions: statuses,
                        },
                        {
                            type: "combobox",
                            label: "Art",
                            key: "art",
                            comboOptions: Object.entries(arten)
                                .filter(([key, value]) => !!value)
                                .map(([key, value]) => ({
                                    label: value,
                                    value: key,
                                })),
                        },
                    ],
                },
            ]
            return result
        },
        headerActions() {
            return [PageHeaderSimpleAction("NEW_AUFGABE", "Neue Aufgabe")]
        },
        tableHeaderActions() {
            return [
                PageHeaderConfirmedAction(
                    "BULK_DELETE",
                    "Markierte löschen",
                    "Sollen die markierten Einträge wirklich gelöscht werden?",
                    "Markierte löschen",
                    "Löschen",
                ).withDisabled(() => !this.selectedRows.length),
                PageHeaderSimpleAction("BULK_DONE", "Markierte als Erledigt markieren").withDisabled(
                    () => !this.selectedRows.length,
                ),
            ]
        },
        exportableHeaders() {
            return Object.values(this.headers)
                .flat()
                .filter((header) => header.exportFn != null && header.visible)
        },
        exportConfig() {
            return {
                roottext: "Postfach",
                dispatch: this.exportAsFile,
            }
        },
    },
    mounted() {
        this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_APPOINTMENT_COMBOS)

        const {fromDate, toDate} = this.postfachDefaultFilters
        const query = this.$route.query

        this.defaultOptions = {
            zeitraum: {
                value: [
                    {
                        key: "min",
                        value: fromDate,
                    },
                    {
                        key: "max",
                        value: toDate,
                    },
                ],
            },
        }

        if (query.q) {
            this.defaultOptions.subject = {
                value: query.q,
            }
            this.hasFilterCache = false
        }
    },
    methods: {
        getWVStatus(value) {
            switch (value) {
                case "green":
                    return {
                        label: "erledigt",
                        type: "success",
                    }
                case "blue":
                    return {
                        label: "wird versendet",
                        type: "info",
                    }
                case "yellow":
                    return {
                        label: "Handlung notwendig",
                        type: "warning",
                    }
                default:
                    return ""
            }
        },
        onScroll(onScrollEnd) {
            this.onScrollEnd = onScrollEnd
            this.loadPage(this.selectedPage + 1)
        },
        onSearchSort(searchSort) {
            this.searchSort = searchSort || {}
            this.pageList = {}
            this.loadPage(0)
        },
        makeActions(row) {
            const article = row.category === "Termin" ? "der" : "die"

            return [
                ConfirmedAction(
                    "DONE",
                    "",
                    "Erledigt",
                    `Soll die Aufgabe "${row.subject || "[kein Betreff]"}" wirklich als erledigt markiert werden?`,
                    `Aufgabe als Erledigt markieren`,
                    "Erledigt",
                ),
                ConfirmedAction(
                    "DELETE",
                    "",
                    "Löschen",
                    `Soll ${article} ${row.category} "${row.subject || "[kein Betreff]"}" wirklich gelöscht werden?`,
                    `${row.category} löschen`,
                    "Löschen",
                ),
            ]
        },
        clickBetreff(row) {
            if (row.commId) {
                this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, row.commId)
                this.openAppointment()
                if (row.category === "Aufgabe") {
                    this.isActivity = true
                    this.attachCustomer = true
                    this.getCurrentMode("Aufgabe")
                    this.openAppointment()
                } else {
                    this.attachCustomer = true
                    this.isActivity = false
                    this.getCurrentMode("Termin")
                    this.openAppointment()
                }
            }
        },
        clickBereich(row) {
            const action = row.bereichOeffnen

            if (action) {
                switch (action.bereich) {
                    case "VersVertrag":
                        this.openInsurance(action)
                        return
                    case "Kunde":
                        this.openCustomerNewTab(action.bereichId)
                        return
                    case "Makler":
                        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {brokerId: action.bereichId})
                        return
                    case "Besuchsbericht":
                        this.openBesuchsbericht(action)
                        return
                    default:
                        return
                }
            }
        },
        openAppointment() {
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
                title: `${
                    this.currentMode === "Termin" || this.currentMode === "Aufgabe" ? this.currentMode : "Aufgabe"
                } bearbeiten`,
                mode: this.currentMode,
                isActivity: this.isActivity,
                attachCustomer: this.attachCustomer,

                back: this.$router.currentRoute.fullPath,
            })
            this.$router.push({path: `/communication/appointment`})
        },
        openInsurance(action) {
            if (this.$hasRoles([VIEW_ROLES.VIEW_INTERN, VIEW_ROLES.VIEW_BROKER])) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
                    customerId: action.customerId,
                    insurances: true,
                    path: `/home/versicherungen/insurance-group/overview/${action.bereichId}`,
                })
            } else {
                this.$router.push(`/home/versicherungen/insurance-group/overview/${action.bereichId}`)
            }
        },
        async openBesuchsbericht(action) {
            if (this.$hasRoles([VIEW_ROLES.VIEW_INTERN])) {
                axios
                    .get(`${process.env.VUE_APP_API}/berichte/bericht`, {
                        ...config,
                        params: {
                            id: action.bereichId,
                            showDeleted: true,
                        }
                    })
                    .then((response) => {
                        const gesellId = response.data.bericht.gesellId

                        this.$router.push(
                            `/intern/gesellschaften/${gesellId}/bemerkungen/besuchsbericht/${action.bereichId}`,
                        )
                    })
            }
        },
        newTask() {
            this.isActivity = true
            this.attachCustomer = true
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: false})
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_AUFGABE)
            this.getCurrentMode("Aufgabe")
            this.openAppointment(false)
        },
        newAppointment() {
            this.attachCustomer = true
            this.isActivity = false
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: false})
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_TERMIN)
            this.getCurrentMode("Termin")
            this.openAppointment(true)
        },
        getCurrentMode(bereich) {
            if (!bereich) {
                bereich = this.getSelectedEvent?.appointment?.bereich
            }

            this.currentMode = bereich ? (bereich === "Termin" || bereich === "Aufgabe" ? "Aufgabe" : "Aufgabe") : ""
        },
        doSearch(urlParams = this.filterParams, page = this.selectedPage) {
            this.loading = true

            const filterParams = {
                ...urlParams,
            }

            /*

      if(urlParams.fromDate || this.postfachDefaultFilters.fromDate) {
        params.fromDate = urlParams.fromDate || dayjs(this.postfachDefaultFilters.fromDate).format('DD.MM.YYYY')
      }
      if(urlParams.toDate || this.postfachDefaultFilters.toDate) {
        params.toDate = urlParams.toDate || dayjs(this.postfachDefaultFilters.toDate).format('DD.MM.YYYY')
      }*/

            const params = makeQueryParam({
                limit: ROWS_PER_PAGE_DEFAULT || 5,
                sortKey: this.searchSort.key,
                sortAscending: this.searchSort.sortDirection === "asc",
            })

            axios
                .post(`${process.env.VUE_APP_API}/postfachTermin/list/aufgaben?page=${page}&${params}`, filterParams, {
                    defaultSpinner: true,
                }) //TODO: Implement a vuex action for this request
                .then((response) => {
                    this.records = response.data?.records || []

                    this.$set(this.pageList, page, this.records)
                    this.rowCount = response.data.rowCount
                })
                .finally(() => {
                    this.selectedRows = []
                    this.loading = false
                })
        },
        exportAsFile(fileEnding) {
            const filterParams = {
                ...this.filterParams,
                fromDate:
                    this.filterParams.fromDate || dayjs(this.postfachDefaultFilters.fromDate).format("DD.MM.YYYY"),
                toDate: this.filterParams.toDate || dayjs(this.postfachDefaultFilters.toDate).format("DD.MM.YYYY"),
            }

            return axios
                .post(`${process.env.VUE_APP_API}/postfachTermin/list/aufgaben?${fileEnding}`, filterParams, {
                    defaultSpinner: true,
                })
                .then((response) => {
                    {
                        const columns = this.exportableHeaders.map((header) => header.label)
                        const rows = response.data.records.map((row) => {
                            return this.exportableHeaders.map((header) => {
                                return header.exportFn(row[header.key], row, header) || ""
                            })
                        })
                        const params = {
                            create: fileEnding,
                            filename: this.exportConfig.roottext + " " + dayjs(new Date()).format("DD-MMM-YYYY HH mm"),
                            title: this.exportConfig.roottext + " - " + this.fullname,
                            columns,
                            rows,
                            printMaklerLogo: "0",
                        }
                        return this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params)
                    }
                })
        },
    },
}
</script>
