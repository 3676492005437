var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: _vm.iconContainerStyle },
    [
      _vm.isSoscompFontIcon
        ? _c("SoscompIcon", {
            attrs: { size: _vm.size, icon: _vm.authenticationType }
          })
        : _vm.authenticationType === "AUTH-IRIS"
        ? _c("PhScan", {
            staticClass: "native-auth-icon--iris",
            attrs: { size: _vm.size }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }