<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title='Anlegerprofil'
      :subtitle="getSubtitle"
      :actions="headerActions" 
      :defaultMenu="personenOptionMenu"
      @action-PDF="openPDFErzeugen" 
      @action-SIGN="openPDFSignieren" 
      @action-CONFIRM="confirmAnlegerprofil" 
      @action-REFUSE="refuseButtonClicked" 
      @action-ANLAGEZIELE_PLANEN="anlagezielePlanen" 
      @action-COPY_FINANCE="copyFinance" 
      @action-TOGGLE-E-SIGN="doSendToElectronicSignature" 
    >
    </PageHeaderTitleNavigation>

    <div v-if="!isMobileNativeContext" class="box__container">
      <div class="d-flex">
        <div class="font-bold mr-3">Risikokennzahl: {{risikoKennzahl}}</div>
        <div class="font-bold">GSRI: {{gsri}}</div>
      </div>
    </div>

    <div class="row my-0" v-if="stepper && isSmallOrMediumScreen">
      <div class="d-lg-none col-12">
        <div class="antrag-step__container">
          <Stepper3
            stepType="step"
            :visible="true"
            :selectedStepKey="getStepKey"
            :stepperService="stepper"
            @setStep="setStepByKey"
            @setSubstep="setSubstepByKey"
            ref="pieStepper"
          ></Stepper3> 
        </div>
      </div>
    </div>


    <div class="row" v-if="!isSmallOrMediumScreen">
      <div class="col-12">
        <AnlegerprofilSidetext :hasFinanceSummary="hasFinanceSummary" :key="getStepKey" 
          :showExpanded="getStepKey === 'aktionen'"
          @goToWarning="goToWarning($event)" />
      </div>
    </div>


    
    <ContentWithStepper :stepperCollapsed="stepperCollapsed" v-if="!isSmallOrMediumScreen">
      <template #stepper v-if="stepper && getStepKey !== 'aktionen'">
        <div class="box__container">
          <VerticalStepper
            stepType="step"
            :visible="true"
            :selectedStepKey="getStepKey"
            :selectedSubstepKey="getSubstepKey"
            :stepperService="stepper"
            :showCollapse="true"
            @setStep="setStepByKey"
            @setSubstep="setSubstepByKey"
            @collapsed="stepperCollapsed = $event"
            ref="verticalStepper"
            class="responsive-stepper"
          ></VerticalStepper>
        </div>
      </template>
      <template #content>
        <div class="box__container">
          <div  v-if="getStepKey !== 'aktionen'">
            <div class="box__title">{{stepTitle}}</div>
            <hr>
            <router-view></router-view>
            <hr class="hide-on-small-screen">
          </div>

          <div class="antrag-bottom-buttons-container">
            <div class="row">
              <div class="col-12 hide-on-small-screen mb-3">
                <InputToggleSwitch  v-if="hasConfirmMoney"
                  :value="hasEnoughMoney" 
                  label="Ich bestätige die nötigen finanziellen Mittel für die Anlage zu besitzen. *" 
                  inLineLabel suppressValidationMessage
                  :disabled="isDisabled"
                  @input="updateStore('finance', 'confirmHasMoney', $event)"
                />
              </div>
              <div class="col-12 my-1 col-sm-auto hide-on-small-screen" v-if="hasPreviousStep">
                <BaseButton @click="prevStep()" :disabled="isSaving" isBlock isSecondary >
                  <span>Zurück</span>
                  <AnimatedSpinner v-if="showPreviousSpinner"/>
                </BaseButton>
              </div>
              <div class="col-12 my-1 col-sm-auto" v-if="hasNextStep">
                <BaseButton @click="nextStep()" :disabled="isSaving" isBlock isPrimary>
                  <span>Weiter</span>
                  <AnimatedSpinner v-if="showNextSpinner"/>
                </BaseButton>
              </div>

              <div class="col-12 my-1 col-sm-auto" v-if="getStepKey === 'aktionen' || !hasNextStep">
                <BaseButton
                  label="Schließen"
                  @click="goToLastAction"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </ContentWithStepper>

    <BaseModal ref="warnings" :showCancelButton="false" labelButtonConfirm="Ok"
      @onConfirmButton="$refs.warnings.close()" @onCloseButton="$refs.warnings.close()">
      <template v-slot:modalTitle>
        Information
      </template>
      <div class="col mb-2">Fehlerhafte Anlegerprofile können nicht zur e-Signatur vorgelegt werden</div>
    </BaseModal>
    
    <BaseModal ref="copySuccess" :showCancelButton="false" labelButtonConfirm="Ok">
      <template v-slot:modalTitle>
        Information
      </template>
      <div class="col mb-2">Finanzielle Verhältnisse wurden von persönlichen Daten erfolgreich kopiert.</div>
    </BaseModal>

    <BaseModal
        ref="editModal"
        :modalTitle="editModalTitle"
        size="lg"
        :showCancelButton="false"
        @onCloseButton="onCloseEditModal()"
        :actions="baseModalActions"
        :showConfirmButton="false"
        :autoCloseOnRouteNavigation="false"
        @action-WEITER="nextStep()"
        @action-ZURUCK="prevStep()"
        @action-REFUSE="refuseButtonClicked" 
        @action-ANLAGEZIELE_PLANEN="anlagezielePlanen" 
        @action-COPY_FINANCE="copyFinance" >

        <div class="modal__container" v-if="!loadingStep">

          <div  v-if="getStepKey !== 'aktionen'">
            <div class="box__title">{{stepTitle}}</div>
            <hr>
            <router-view></router-view>
          </div>
          <div v-else>
            <AnlegerprofilSidetext :hasFinanceSummary="hasFinanceSummary" :key="getStepKey" 
              :showExpanded="getStepKey === 'aktionen'"
                  @goToWarning="goToWarning($event)" />
          </div>
          <div class="row">
            <div class="col-12 mb-3">
              <InputToggleSwitch  v-if="hasConfirmMoney"
                :value="hasEnoughMoney" 
                label="Ich bestätige die nötigen finanziellen Mittel für die Anlage zu besitzen. *" 
                inLineLabel suppressValidationMessage
                :disabled="isDisabled"
                @input="updateStore('finance', 'confirmHasMoney', $event)"
              />
            </div>
          </div>
        </div>
        <GhostLoading v-else type="table" />

     

    </BaseModal> 


  </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import CORE_TYPES from "@/store/core/types";
import Stepper3 from '@/components/stepper/Stepper3.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import { mapGetters } from 'vuex';
import AnlegerprofilSidetext from './AnlegerprofilSidetext.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { openSignoViewDocument } from '@/components/core/download/SignoViewerLink.vue';
import axios from 'axios';
import BRIDGE_TYPES from '@/store/bridge/types';
import { PageHeaderLoadingAction, PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import BaseBackButtonBreadcrumb from '@/components/core/BaseBackButtonBreadcrumb.vue'

const STEPS_WITHOUT_PERSON_TITLE = ['auswahl','finanzen', 'anlageziele', 'risikoneigung', 'risikobereitschaft', 'geschlosseneInvest', 'nachhaltigkeit', 'eltif',
  'zusatz-dje'];
const STEPS_WITHOUT_PERSON_TITLE_SM = ['finanzen', 'anlageziele', 'risikoneigung', 'risikobereitschaft', 'geschlosseneInvest', 'nachhaltigkeit', 'eltif', 'zusatz-dje'];

export default {
  mixins: [anlegerprofilMixin],
  components: {
    BaseButton,
    BaseModal,
    Stepper3,
    VerticalStepper,
    AnlegerprofilSidetext,
    OptionMenu,
    PageHeaderTitleNavigation,
    AnimatedSpinner,
    ContentWithStepper,
    InputToggleSwitch,
    GhostLoading,
    BaseBackButtonBreadcrumb,
  },
  data() {
    return {
      showStepper: true,
      lastClickedButton: '',
      stepperCollapsed: false,
      errorStr: '',
      loadingConfirm: false,
      isInit: true,
      showingEditModal: false,
      editModalTitle: null,
      loadingStep: false,
    };
  },
  watch: {
    data(data) {
      if (data && Object.keys(data)?.length) {
        if (this.isInit) {
          this.$refs.pieStepper?.progress(this.getStepKey);
          this.$refs.verticalStepper?.updateStepList();
          this.isInit = false;
        }
      }
    },
  },
  mounted() {
    this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.RESET_STATE);
    this.updatePersonType('person1');
    this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_SCHIENE, this.schiene || '');
    this.loadData();
    this.$refs.pieStepper?.progress(this.getStepKey);
    this.$refs.verticalStepper?.updateStepList();
    this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.SET_BERATUNGS_MAPPE_ID, this.$route.query.beratungsMappeId || '');
  },
  computed: { 
    ...mapGetters({
      beratungsMappeId: ANLEGERPROFIL_TYPES.GETTERS.BERATUNGS_MAPPE_ID,
      isSaving: ANLEGERPROFIL_TYPES.GETTERS.SAVE_ANLEGERPROFIL_STATE,
      backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE,
      isOriginalUserBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      screenSize: CORE_TYPES.GETTERS.SCREEN_WIDTH,
    }),
    isSmallOrMediumScreen() {
      return this.isSmallScreen || this.isMediumScreen;
    },    
    isMediumScreen() {
      return this.screenSize === 'md';
    },
    headerActions() {
      const fromConfirm = this.$route?.query?.fromConfirm;
      const actions = [
        PageHeaderSimpleAction('REFUSE', this.getRefuseBtnText)
          .withDisabled(() => this.isSaving || this.isDisabled)
          .withVisible(() => this.hasRefuse),
        PageHeaderSimpleAction('ANLAGEZIELE_PLANEN', 'Anlageziele planen')
          .withDisabled(() => this.isRefusedPage || this.isDisabled)
          .withVisible(() => this.getStepKey === 'anlageziele'),
        PageHeaderSimpleAction('COPY_FINANCE', 'Daten von Depotinhaber(n) kopieren planen')
          .withDisabled(() => this.isRefusedPage || this.isDisabled)
          .withVisible(() => this.getSubstepKey === 'step3-1'),

        PageHeaderSimpleAction('CONFIRM', 'Anlegerprofil bestätigen')
          .withDisabled(() => this.isSaving || this.loadingConfirm)
          .withVisible(() => fromConfirm),
      ];
      if(!this.getBeratungsMappeId) {
        actions.push(... [PageHeaderLoadingAction('PDF', 'PDF', () => this.showPDFDownloadSpinner),

        PageHeaderSimpleAction('SIGN', 'PDF signieren')
          .withVisible(() => !fromConfirm),
        PageHeaderSimpleAction('TOGGLE-E-SIGN', this.isVorgelegt ? 'E-Signatur zurückziehen' : 'Zur e-Signatur vorlegen')
          .withVisible(() => !fromConfirm && (this.isOriginalUserBroker || this.isOriginalUserIntern))
        ]);
      }
      return actions;
    },
    isVorgelegt() {
      return this.data?.extraData?.IS_VORGELEGT || false;
    },
    getSubtitle(){
      return this.isMobileNativeContext ? 'RKZ: ' + this.risikoKennzahl + ' | GSRI: ' + this.gsri : '';
    },
    warnings() {
      this.stepper && this.stepper.updateWarnings({
        warnings: this._warnings, 
        personType: this.getPersonType,
        substepCurrentValue: 0, 
        checkAllSteps: this.$store.state.core.loginData.rights.isBroker || this.$store.state.core.loginData.rights.isBypSession
      });
      return this._warnings;
    },
    steps() {
      return this.data && this.stepper?.getCurrentStepList() || [];
    }, 
    hasFinanceSummary() {
       const substepKey = this.getSubstepKey;
      return this.getPersonType === 'person1' && ['step3-7'].includes(substepKey);
    },
    title() {
      return `Anlegerprofil${this.isWealthKonzept ? ' WealthKonzept' : ''}`;
    },
    stepTitle() {
      const step = this.stepper?.getCurrentStep(this.getStepKey);
       if (!step || step.stepKey === 'aktionen') {
        return 'Zusammenfassung' ;
      } 

      const substepKey = this.getSubstepKey;
      const substep = step && substepKey && step.substeps?.length && step.substeps.find(sub => sub.substepKey === substepKey);
      const stepsNoTitle = this.isMobileNativeContext ? STEPS_WITHOUT_PERSON_TITLE_SM : STEPS_WITHOUT_PERSON_TITLE;
      const personStr = stepsNoTitle.includes(step.stepKey) ? '' 
        : (this.getPersonStep(this.getPersonType).label + ': ' || '');

      if (this.isSmallOrMediumScreen) {
        return (substep?.title || step?.label);
      } else {
        return personStr + (substep?.title || step?.label);
      } 
    },
    getRefuseBtnText() {
      return this.isRefusedPage ? 'doch Angaben machen' : 'Ich möchte keine Angaben machen';
    },
    isRefusedPage() {
      return this._isRefusedPage(this.data, this.getStepKey);
    },
    getPersonenList() {
        return this.stepperPersonen.filter(step => !step.hidden);
    },
    personenOptionMenu() {
      return this.stepperPersonen?.filter(step => !step.hidden).map(person => ({
        icon: this.hasWarningsProPerson(person.stepKey) ? 'PhWarningCircle' : undefined,
        label: person.label,
        textBold: this.isPersonActive(person),
        action: () => this.setStepPerson(person.stepKey, true),
      }));
    },
    showPreviousSpinner() {
      return this.isSaving && this.lastClickedButton == 'prevButton';
    },
    showNextSpinner() {
      return this.isSaving && this.lastClickedButton == 'nextButton';
    },
    showRefuseSpinner() {
      return this.isSaving && this.lastClickedButton == 'refuse';
    },
    showPDFDownloadSpinner() {
      return this.lastClickedButton == 'PDFErzeugen';
    },
    getBeratungsMappeId() {
      return this.beratungsMappeId;
    },
    risikoKennzahl() {
      return this.data?.extraData?.risikoKennzahl;
    },
    gsri() {
      return this.data?.extraData?.gsri;
    },
    hasEnoughMoney() {
      return this.data?.finance?.confirmHasMoney || false;
    },
    breadcrumbFullPathQueryParams() {
      let query = this.beratungsMappeId ? ('?beratungsMappeId=' + this.beratungsMappeId) : '';
      if (this.schiene) {
        query += ((query.length ? '&' : '?') + ('schiene=' + this.schiene));
      }
      return query;
    },
    baseModalActions() {
      return [
        BaseModalSimpleAction('REFUSE', this.getRefuseBtnText)
          .withDisabled(() => this.isSaving || this.isDisabled)
          .withVisible(() => this.hasRefuse),
        BaseModalSimpleAction('ANLAGEZIELE_PLANEN', 'Anlageziele planen')
          .withDisabled(() => this.isRefusedPage || this.isDisabled)
          .withVisible(() => this.getStepKey === 'anlageziele'),
        BaseModalSimpleAction('COPY_FINANCE', 'Daten von Depotinhaber(n) kopieren planen')
          .withDisabled(() => this.isRefusedPage || this.isDisabled)
          .withVisible(() => this.getSubstepKey === 'step3-1'),
        BaseModalSimpleAction('ZURUCK', 'Zurück').withPrimary(() => false).withVisible(() =>  !this.isPersonenAuswahlStep(this.getStepKey)),
        BaseModalSimpleAction('WEITER', 'Weiter').withPrimary(() => true).withVisible(() =>  this.getStepKey !== 'aktionen'),
      ];
    },    
    hasPreviousStep() {
      return !this.isPersonenAuswahlStep(this.getStepKey);
    },
    hasNextStep() {
      return this.getStepKey !== 'aktionen';
    },
  },
  methods: {
    onCloseEditModal() {
      this.showingEditModal = false;
    },
    isPersonActive(person) {
      return this.getPersonType == person?.stepKey
    },
    loadData() {
      const payload = this.getRouteParams();
      this.$store.dispatch(ANLEGERPROFIL_TYPES.ACTIONS.GET_ANLEGERPROFIL, payload);
    },
    async setSubstepByKey(event) {
      this.showingEditModal = true;
      await this.navigateTo(event.stepKey, event.substepKey);
      this.editModalTitle = this.getPersonStep(this.getPersonType).label;
      if (this.isSmallOrMediumScreen) {
        this.$refs.editModal.open();
      }
    },
    async setStepByKey(stepKey, substepKey) {
      this.showingEditModal = true;
      await this.navigateTo(stepKey, substepKey);
      this.editModalTitle = this.getPersonStep(this.getPersonType).label;
      if (this.isSmallOrMediumScreen) {
        this.$refs.editModal.open();
      }
    },
    async goToWarning(event) {
      await this.doSave();

      const personType = this.isPersonenAuswahlStep(event?.stepKey) ? 'person1' : event.personType;
      this.updatePersonType(personType);
      this.navigateToStep(event.stepKey, event.substepKey, personType)
    },
    async navigateTo(stepKey, substepKey) {
      await this.doSave();

      if (!substepKey) {
        const step = this.stepper && this.stepper.getCurrentStep(stepKey);
        substepKey = step && step.substeps && step.substeps.length && step.substeps[0].substepKey || '0';
      }
      await this.navigateToStep(stepKey, substepKey, this.getPersonType);
    },
    async prevStep() {
      this.lastClickedButton = 'prevButton';
      const previous = this.stepper?.getPreviousSubstep(this.getStepKey, this.getSubstepKey);
      if (previous) {
        await this.doNextStep(previous.stepKey, previous.substepKey);
      } else {
        const prevPerson = this.getPreviousPersonStep(this.getPersonType);
        if (prevPerson?.stepKey) {
           // switch person
          this.doNextPersonStep(prevPerson.stepKey, false);
        }
      }
    },
    async nextStep() {
      this.loadingStep = true;
      this.lastClickedButton = 'nextButton';
      const next = this.stepper?.getNextSubstep(this.getStepKey, this.getSubstepKey); 
      if (next?.stepKey) {
        if (this.isPersonenAuswahlStep(this.getStepKey)) {
          this.$addBreadcrumb({
            label: 'zurück zur Personenauswahl', 
            fullPath: `${this.path}/person1/auswahl/personenauswahl${this.breadcrumbFullPathQueryParams}`, 
            breadcrumb: 'Anlegeprofil',
          });
        }

        await this.doNextStep(next.stepKey, next.substepKey);
      } else {
        // switch person
        const nextPerson = this.getNextPersonStep(this.getPersonType);
        if (nextPerson?.stepKey) {
          await this.doNextPersonStep(nextPerson.stepKey);
          
        }
      }
      this.editModalTitle = this.getPersonStep(this.getPersonType).label;
      this.loadingStep = false;
    },
    async doNextPersonStep(stepKey, selectFirstStep) {
      await this.setStepPerson(stepKey, selectFirstStep);
    },
    async doNextStep(stepKey, substepKey) {
      await this.navigateTo(stepKey, substepKey);
    },
    refuseButtonClicked() {
      if (!this.isWealthKonzept) {
        if (!this.isRefusedPage) {
          this.removeAllKenntnisse();
        }
        this.lastClickedButton = 'refuse';
        this.doRefuse({ category: this.getRefuseCategory(this.getStepKey), value: !this.isRefusedPage })
      }
    },
    async doRefuse(data) {
      if (data?.category) {
        await this.$store.dispatch(ANLEGERPROFIL_TYPES.ACTIONS.REFUSE_CATEGORY, data);
        this.lastClickedButton = '';
        /* the page was refused, button click permits the information */
        if (this.isRefusedPage) {
          this.stepper.markSubStepAsSuccess(this.getStepKey, this.getSubstepKey);
        } else {
          /* button click refuses the information and goes to the next page */
          this.stepper.markSubStepAsWarning(this.getStepKey, this.getSubstepKey);
        }
      }
    },
    close() {
      this.$router.push({ path: '/beratung' });
    },
    goToLastAction() {
      let previousData = this.backToPreviousData?.length && this.backToPreviousData[this.backToPreviousData.length - 1];
      if (previousData?.fullPath?.includes('anlegerprofil')) {
        if (this.backToPreviousData?.length > 1) {
          previousData = this.backToPreviousData[this.backToPreviousData.length - 2];
          this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
        } else {
          previousData = null;
        }
      }
      this.$router.push({ path: previousData?.fullPath || '/beratung/overview', query: { backAction: true}})
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
    },
    getPersonenLable(personen, index) {
      const personenListLength = this.getPersonenList.length;
      if(index == personenListLength-1){
        return personen.label;
      }
      return personen.label + " | ";
    },
    async openPDFSignieren() {
      this.lastClickedButton = "PDFSignieren"

      this.updateStoreCheckBoxGroup('startStep', {})
      await this.doSave();
      const response = await this.$store.dispatch(ANLEGERPROFIL_TYPES.ACTIONS.SIGN_PDF, { schiene: this.schiene });
      this.lastClickedButton = '';
      if (response?.uri) {
        openSignoViewDocument(response.uri, 'Anlegerprofil');
      }
    },
    async openPDFErzeugen() {
      this.lastClickedButton = "PDFErzeugen"
      this.updateStoreCheckBoxGroup('startStep', {})
      
      await this.doSave();
      const response = await this.$store.dispatch(ANLEGERPROFIL_TYPES.ACTIONS.DOWNLOAD_PDF, { schiene: this.schiene });
      this.lastClickedButton = '';

      viewDocument({
        data: response.bytes,
        filename: response.fileName,
        contentType: 'application/pdf',
      });
    },
    anlagezielePlanen() {
      this.doSave();
      this.$addBreadcrumb({
        label: 'zurück zum Anlegerprofil', 
        fullPath: `${this.path}/person1/anlageziele/anlageziele${this.breadcrumbFullPathQueryParams}`,
      });
      this.$router.push({ path: `${this.path}/anlageziele-planen${this.breadcrumbFullPathQueryParams}` });
    },
    async copyFinance() {
      await this.$store.dispatch(ANLEGERPROFIL_TYPES.ACTIONS.COPY_FINANCE_FROM_CUSTOMER_DATA);
      this.$refs.copySuccess.open();
    },
    async doSendToElectronicSignature() {
      if (!this.isVorgelegt && this.warnings?.length) {
        this.$refs.warnings.open();
        return
      } 
      this.errorStr = await this.$store.dispatch(ANLEGERPROFIL_TYPES.ACTIONS.SEND_TO_ELECTRONIC_SIGNATURE);
    },
    hasWarningsProPerson(personType) {
      if (personType) {
        return personType === 'person1' ? this.warnings?.some(warn => warn?.personType === personType || this.isPersonenAuswahlStep(warn?.stepKey))
          : this.warnings?.some(warn => warn?.personType === personType && !this.isPersonenAuswahlStep(warn?.stepKey));
      }
    },
    openDoneModal() {
      this.$confirmModal({
        title: 'Anlegerprofil akzeptiert',
        message: 'Vielen Dank!',
        labelButtonConfirm: 'Ok',
        showCancelButton: false,
      }).then(() => {})
      .catch(() => {})
      .finally(() => this.$router.push('/home'))
    },
    async confirmAnlegerprofil() {
      this.loadingConfirm = true
      await this.doSave();

      const config = {
        defaultSpinner: true
      };

      let serviceUrl = '/anlegerprofil/confirmedByKunde';
      axios.post(`${process.env.VUE_APP_API}/${serviceUrl}?apSchiene=${this.schiene}`, config)
        .then((response) => {
        if (response && response.status === 200) {
          this.openDoneModal()
        }
      }).finally(() => this.loadingConfirm = false)
    },
  },
  async beforeRouteLeave(to, from, next) {
    await this.doSave();
    next();
  },
}
</script>

<style scoped>
.modal__container {
  padding: 0px 24px;
}
.modal__button {
  display: flex;
  justify-content:center;
  width: 100%;
  padding: 4px 4px;
}
.modal__button > button {
  width: fit-content;
  
}
.antrag-action-button__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 1.25rem;
    font-weight: bold;
  }
.wrap {
  flex-wrap: wrap;
}
div > a {
  color: black;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .responsive-stepper {
    display: none;
  }
}
</style>