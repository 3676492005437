<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      :title="getTitle(websiteId)"
      :defaultMenu="websiteId === 'Franke & Bornberg fb-tools' ? customOptionMenu : []"/>

    <div class="box__container" v-if="websiteId === 'Nafi-KFZ'">
      <b>Wichtiger Hinweis: </b>
      <p>Eine Beantragung der <strong>vorläufigen Deckung im Bereich Kasko</strong> ist bei den nachfolgend aufgeführten Gesellschaften nicht über NAFI möglich:
        <ul>
          <li>DEVK</li>
          <li>HDI</li>
          <li>Nürnberger</li>
          <li>Württembergische</li>
          <li>VKB</li>
        </ul>
        Bitte gehen Sie direkt auf die Gesellschaft zu, wenn Kaskoschutz innerhalb der vorläufigen Deckung gewünscht ist. 
        <div class="mt-2">Außerdem kann eine EVB bei der <strong>DEVK</strong> nur in Verbindung mit einem Antrag generiert werden.</div>
      </p>
    </div>

    <div class="box__container" v-if="websiteId === 'Tarifrechner Nürnberger BT4All'">
      <div class="box__container" v-if="hasMaklerHauptnummer && !isBt4AllLoading">
        <div class="box__title">Auswahl der Vermittlernummer</div>
        <ComboBox
          isComponentHalfSize
          :disabled="!fremdnummerComboboxHasData"
          :value="this.fremdnummerComboboxSelectedData"
          :values="this.fremdnummerComboboxData"
          @change="fremdnummerChange($event)">
        </ComboBox>
        <span class="color-danger" v-if="!isHauptnummerSelected">Vermittlernummer ist erforderlich!</span>

        <div class="box__title">Auswahl der Versicherungsart</div>
        <ComboBox
          isComponentHalfSize
          :disabled="isSomeButtonLoading"
          :placeholder="'Bitte auswählen'"
          :value="this.bt4allComboboxSelection"
          :values="this.bt4AllComboboxSelections"
          @change="bt4AllChange($event)">
        </ComboBox>
        <span class="color-danger" v-if="!isBt4AllSelected">Versicherungsart ist erforderlich!</span>

        <br><br>

        <div>
          <BaseButton
            :disabled="!isBt4AllButtonEnabled"
            @click="loginToExternalWebsite()"
          >
            Login mit Kundendaten
            <AnimatedSpinner v-if="loadingState.external" />
          </BaseButton>
        </div>
      </div>

      <p v-if="isBt4AllLoading">
        Lade... Bitte warten...
      </p>

      <p v-if="!hasMaklerHauptnummer && !isBt4AllLoading">
        Es ist keine Vermittlernummer für die Nürnberger Allgemeine vorhanden.
      </p>

    </div>

    <div class="box__container" v-if="websiteId === 'Dialog Lebensversicherung'">
      <div class="box__container" v-if="hasMaklerHauptnummer && !isDialogLebenLoading">

        <div class="box__title">Versicherungsnehmer</div>
        <InputField v-model="hauptPersonDataLabel" disabled isComponentHalfSize />

        <div class="box__title">Auswahl der versicherten Person</div>
        <ComboBox
          isComponentHalfSize
          :disabled="!personenComboBoxHasData"
          :value="this.personenComboBoxSelectedValue"
          :values="this.personenComboBoxData"
          :placeholder="'Bitte auswählen'"
          @change="personenChange($event)">
        </ComboBox>

        <br><br>

        <div>
          <BaseButton
            @click="loginToExternalWebsite()"
          >
            Login mit Kundendaten
            <AnimatedSpinner v-if="loadingState.external" />
          </BaseButton>
        </div>
      </div>

      <p v-if="isDialogLebenLoading">
        Lade... Bitte warten...
      </p>

    </div>

    

    <template v-if="websiteId === 'Franke & Bornberg fb-tools'">
      <div class="box__container" v-if="modus === 'aktuell'">
        <div class="box__title">Auswahl der Versicherungsart</div>
        <ComboBox
          isComponentHalfSize
          firstEmpty
          :disabled="isSomeButtonLoading"
          :value="vnComponentData && vnComponentData.COMBO_VERSICHERUNGSART"
          :values="vnComboboxSelections ? vnComboboxSelections.COMBO_VERSICHERUNGSART : []"
          @change="updateInsuranceType({componentId: 'COMBO_VERSICHERUNGSART', value: $event })">
        </ComboBox>
        <span class="color-danger" v-if="vnComponentData && !vnComponentData.COMBO_VERSICHERUNGSART">Versicherungsart ist erforderlich!</span>
      </div>

      <div class="box__container" v-if="modus === 'archiv'">
        <div class="box__title">Auswahl der Versicherungsart</div>
        <ComboBox
          isComponentHalfSize
          :disabled="isSomeButtonLoading"
          :value="vnComponentData && vnComponentData.COMBO_VERSICHERUNGSART_ARCHIV"
          :values="vnComboboxSelections ? vnComboboxSelections.COMBO_VERSICHERUNGSART_ARCHIV : []"
          @change="updateInsuranceTypeArchiv({componentId: 'COMBO_VERSICHERUNGSART_ARCHIV', value: $event })">
        </ComboBox>
      </div>

      <StepperForm v-if="modus === 'aktuell'"
        ref="Contentstepper"
        stepType="tab"
        stepperName="ansprechpartner"
        :stepperMediator="tabsStepperMediator"
        :selectedStepKey="currentStep"
        :hasFertig="false"
        @set-step="setStep($event)"
        @handleOtherActions="handleOtherActions"
        :additionalActionsForMobile="baseModalActions"
        ignoreOpenModalOnMount
      >
        <template #contentTemplate>
            <template v-if="currentStep === 'insurancedata'">
              <div class="box__container">
                <GhostLoading v-if="loadingState.init" type="table" :title="uebertragenTitle" />
                <Table
                  v-else
                  :title="uebertragenTitle"
                  :headers="headers"
                  :rows="rows"
                  :rowsPerPage="10"
                  rowId="vertragId"
                  v-model="selectedRows"/>
              </div>
            </template>
            <template v-if="currentStep === 'vn-persondata'">
              <div class="row">
                <div class="col-12">
                  <ComponentList
                    :components="vnComponents"
                    :data="vnComponentData"
                    :comboboxValues="vnComboboxSelections"
                    @change="onChangePersonVN($event)"
                  ></ComponentList>
                </div>
              </div>
            </template>
            <template v-if="currentStep === 'vp-persondata'">
              <div class="row">
                <div class="col-12">
                  <ComponentList
                    :components="vpComponents"
                    :data="vpComponentData"
                    :comboboxValues="vpComboboxSelections"
                    @change="onChangePersonVP($event)"
                  ></ComponentList>
                </div>
              </div>
            </template>
        </template>
        <template #buttonsTemplate v-if="currentStep === 'insurancedata'">
          <div>
            <BaseButton 
              isPrimary
              @click="$refs.Contentstepper.nextStep()">
              Weiter
            </BaseButton>
            <BaseButton 
              :disabled="isSomeButtonLoading || isLinkDisabled || !hasSelectedRows" 
              @click="loginToExternalWebsite()"
              >
              Login mit Vertragsdaten
              <AnimatedSpinner v-if="loadingState.external" />
            </BaseButton>
            <BaseButton
              :disabled="isSomeButtonLoading || isLinkDisabled" 
              @click="loginToExternalWebsite(false)" isSecondary
              >
              Login mit Kundendaten
              <AnimatedSpinner v-if="loadingState.externalNoData" />
            </BaseButton>
          </div>
        </template>
      </StepperForm>

      <div class="box__container" v-if="modus === 'archiv'">
        <Table
          title="Archivdaten"
          :headers="headersArchiv"
          :rows="rowsArchiv"
          :rowsPerPage="10"
          @click-label="goToArchivedSession($event)"
          @action-DELETE="deleteArchiveData"
        />
      </div>

      <div v-if="isSmallOrMediumScreen && this.websiteId !== 'Tarifrechner Nürnberger BT4All'" class="box__container fb-tools-sm-button-actions">
        <div>
          <div class="layout__negative-margin--8">
            <BaseButton 
              :disabled="isSomeButtonLoading || isLinkDisabled || !hasSelectedRows" 
              @click="loginToExternalWebsite()"
              >
              Login mit Vertragsdaten
              <AnimatedSpinner v-if="loadingState.external" />
            </BaseButton>
            <BaseButton
              :disabled="isSomeButtonLoading || isLinkDisabled" 
              @click="loginToExternalWebsite(false)" isSecondary
              >
              Login mit Kundendaten
              <AnimatedSpinner v-if="loadingState.externalNoData" />
            </BaseButton>
          </div>
        </div>
      </div>

      <div class="box__container">
        <div class="box__title mt-3">Weitere kostenpflichtige Optionen buchen</div>

        <DownloadLink
                    downloadServicePath="/frankeUndBornbergBestellformular"
                    filename="Bestellformular_FrankeBornberg.pdf"
                    title="Bestellformular - Vergleich für alte und neue Tarife (Formular zurücksenden an Assekuranz@fondskonzept.ag)"
                >
        </DownloadLink>        

        <div class="mt-3">Durch das Feature können im Vergleichsrechner alte und neue Tarife hinzugefügt werden und mit den anderen Tarifen
           verglichen werden. Den Punkt finden Sie unterhalb des Reiters Tarifliste “Tarif hinzufügen”. Das Hinzufügen ist in allen
            Sparten möglich.</div>
      </div>

    </template>

    <template v-if="websiteId !== 'Franke & Bornberg fb-tools' && this.websiteId !== 'Innosystems' && this.websiteId !== 'Dialog Lebensversicherung'">

      <div class="box__container" v-if="this.websiteId !== 'Tarifrechner Nürnberger BT4All'">
        <GhostLoading v-if="loadingState.init" type="table" :title="uebertragenTitle" />
        <Table
          v-else
          :title="uebertragenTitle"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="10"
          rowId="vertragId"
          v-model="selectedRows"
          >

          <template #selected="row">
            <InputRadioBoxItem name="singleSelectRow"
              @change="onChange(row)"/>
          </template>
        </Table>

      </div>

      <div class="box__container" v-if="this.websiteId !== 'Tarifrechner Nürnberger BT4All'">
        <div>
          <div class="layout__negative-margin--8">
            <BaseButton 
              :disabled="!isSomeRowSelected || isSomeButtonLoading || isLinkDisabled" 
              @click="loginToExternalWebsite()"
              >
              Login mit Versicherungsdaten
              <AnimatedSpinner v-if="loadingState.external" />
            </BaseButton>
            <BaseButton v-if="websiteId == 'Nafi-KFZ' || websiteId == 'Finanzportal24'"
              :disabled="isSomeButtonLoading || isLinkDisabled" 
              @click="loginToExternalWebsite(false)"
              isSecondary
              >
              Login ohne Versicherungsdaten
              <AnimatedSpinner v-if="loadingState.external" />
            </BaseButton>
          </div>
        </div>
      </div>
    </template>

    <template v-if="websiteId === 'Innosystems'">
      <div>
        <div class="box__container">
          <div class="box__title">
            Vergleichsrechner Sachversicherung
          </div>

          <div class="innosystem-links__container">
            <a @click="clickInnosystemsButtom('BUTTON_BAUHERRENHAFTPFLICHT')">Bauherrenhaftpflicht</a>
            <a @click="clickInnosystemsButtom('BUTTON_GEWAESSERSCHADEN')">Gewässerschaden</a>
            <a @click="clickInnosystemsButtom('BUTTON_HAUSUNDGRUNDBESITZER')">Haus und Grundbesitzer</a>
            <a @click="clickInnosystemsButtom('BUTTON_HAUSRAT')">Hausrat</a>
            <a @click="clickInnosystemsButtom('BUTTON_HUNDEHAFTPFLICHT')">Hundehaftpflicht</a>
            <a @click="clickInnosystemsButtom('BUTTON_PFERDEHAFTPFLICHT')">Pferdehaftpflicht</a>
            <a @click="clickInnosystemsButtom('BUTTON_PRIVATHAFTPFLICHT')">Privathaftpflicht</a>
            <a @click="clickInnosystemsButtom('BUTTON_RECHTSSCHUTZ')">Rechtschutz</a>
            <a @click="clickInnosystemsButtom('BUTTON_UNFALL')">Unfall</a>
            <a @click="clickInnosystemsButtom('BUTTON_WOHNGEBAEUDE')">Wohngebäude</a>
          </div>
        </div>

        <div class="box__container">
          <div class="box__title">
            Vergleichsrechner Gewerbeversicherung
          </div>

          <div class="innosystem-links__container">
            <a @click="clickInnosystemsButtom('BUTTON_RECHTSSCHUTZSELBST')">Rechtsschutz für Selbstständige/ Firmen</a>
          </div>
        </div>

        <div class="box__container">
          <div class="box__title">
            Vergleichsrechner Krankenversicherung
          </div>

          <div class="innosystem-links__container">
            <a @click="clickInnosystemsButtom('BUTTON_KRANKENHAUSTAGEGELD')">Krankenhaustagegeld</a>
            <a @click="clickInnosystemsButtom('BUTTON_KRANKENTAGEGELD')">Krankentagegeld</a>
            <a @click="clickInnosystemsButtom('BUTTON_KRANKENVOLLVERSICHERUNG')">Krankenvollversicherung</a>
            <a @click="clickInnosystemsButtom('BUTTON_KRANKENZUSATZVERSICHERUNG')">Krankenzusatzversicherung</a>
          </div>
        </div>

        <div class="box__container">
          <div class="box__title">
            Vergleichsrechner Krankenzusatzversicherung Zahn
          </div>

          <div class="innosystem-links__container">
            <a @click="clickInnosystemsButtom('BUTTON_KRANKENZUSATZVERSICHERUNGZAHN')">Krankenzusatzversicherung Zahn</a>
          </div>
        </div>
        
        <div class="box__container">
          <div class="box__title">
            Vergleichsrechner PKW-Versicherung und Motorrad-Versicherung
          </div>

          <div class="innosystem-links__container">
            <a @click="clickInnosystemsButtom('BUTTON_PKW')">PKW und Motorrad (zum Auswahlmenu)</a>
            <a @click="clickInnosystemsButtom('BUTTON_EVBSERVICE')">EVB-Service</a>
          </div>
        </div>
      </div>
    </template>

    <BaseModal ref="errorMessageModal" modalTitle="Fehler" :showConfirmButton="true" :showCancelButton="false" labelButtonConfirm="Fertig">
      {{ errorMessage }}
    </BaseModal>
  </div>
</template>

<script>
import BERATUNG_VERSICHERUNG_TYPES from "@/store/beratungVersicherung/types";
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseModal from '@/components/core/BaseModal.vue';
import vergleichsrechnerMixin from '@/components/externeZugang/vergleichsrechner-mixin';
import StepperForm from '@/components/stepper/StepperForm.vue';
import ComponentList from "@/components/wertpapierorder/ComponentList.vue";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxItem from '@/components/core/forms/radiobox/InputRadioBoxItem.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import { StepperMediator } from '@/components/stepper/utils';
import { ActionColumn, TextColumn, SlotColumn, ConfirmedAction } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import axios from 'axios';
import CORE_TYPES from '@/store/core/types';
import EXTERNE_ZUGANG_TYPES from '@/store/externeZugang/types';
import { makeQueryParam } from '@/helpers/utils-helper';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [vergleichsrechnerMixin],
  components: {
    BaseButton,
    BaseModal,
    StepperForm,
    Table,
    ComponentList,
    ComboBox,
    InputField,
    InputRadioBoxItem,
    AnimatedSpinner,
    OptionMenu,
    PageHeaderTitleNavigation,
    DownloadLink,
    GhostLoading,
  },
  data() {
    return {
      fremdnummer: [],
      isBt4AllSelected: false,
      isHauptnummerSelected: false,
      fremdnummerComboboxData: null,
      fremdnummerComboboxSelectedData: null,
      fremdnummerComboboxHasData: false,
      bt4allSelectedItem: null,
      hasMaklerHauptnummer: false,
      isBt4AllButtonEnabled: false,
      isBt4AllLoading: true,
      isDialogLebenLoading: true,
      personenListe: [],
      hauptPersonListe: [],
      hauptPersonDataValue: null,
      hauptPersonDataLabel: null,
      personenComboBoxData: null,
      personenComboBoxSelectedValue: null,
      personenComboBoxHasData: false,
      modus: 'aktuell',
      currentStep: 'insurancedata',
      frankeBornbergArchiveData: [],
      currentInsuranceType: null,
      selectedRows: [],
      singleSelectedRow: [],
      errorMessage: null,
      windowConfig: {
        title: '',
        websiteId: '',
      },
      vnComponents: [],
      vpComponents: [],
      vnComponentData: null,
      vpComponentData: null,
      vnComboboxSelections: {},
      vpComboboxSelections: {},
      bt4AllComboboxSelections: [],
      bt4allComboboxSelection: null,
      bt4allButtonDisabled: true,
      loadingState: {
        init: false,
        external: false,
        externalNoData: false,
        other: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      insurancesCustomer: BERATUNG_VERSICHERUNG_TYPES.GETTERS.INSURANCES_CUSTOMER, 
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      externeZugangVergleichList: EXTERNE_ZUGANG_TYPES.GETTERS.EXTERNE_ZUGANG_VERGLEICH_LIST
    }),
    isSmallOrMediumScreen() {
      return this.$isSmallScreen || this.$isMediumScreen;
    },
    uebertragenTitle() {
      return `Übertragen zu ${this.windowConfig.title}`;
    },
    headers() {
        let headers = {
            lockedLeft: [
                this.websiteId !== 'Nafi-KFZ' ? null : SlotColumn("selected", " ", 80).makeAlwaysVisible(),
                TextColumn("vertragId", "Interne Nr"),
                TextColumn("gesellName", "Gesellschaft"),
            ],
            center: [
                TextColumn("polNr", "Vers. Nr."),
                TextColumn("spartenBezeichnung", "Sparte"),
                TextColumn("dPraemiebto_zahlweise", "Beitrag"),
                TextColumn("zahlrhythm", "Zahlweise"),
                TextColumn("vtgbeg", "Beginn"),
                TextColumn("vtgend", "End"),
                TextColumn("produktBezeichnung", "Produkt"),
            ],
        };
        return headers;
    },
    rows() {
      return this.insurancesCustomer || []
    },
    headersArchiv() {
        let headers = {
            lockedLeft: [
                TextColumn("label", "Versicherungsart").makeLink(),
                TextColumn("archivName", "Archivname"),
                TextColumn("dateCreated", "Erstellt"),
            ],
            center: [],
            lockedRight: [
              ActionColumn("actions", "Aktionen"),
            ]
        };
        return headers;
    },
    rowsArchiv() {
      return this.frankeBornbergArchiveData.map(row => ({
        ...row,
        actions: [
          ConfirmedAction("DELETE", '', "Löschen (nur im MSC)", `Soll der Archiveintrag "${row.archivName ? row.archivName : row.label} vom ${row.dateCreated}" wirklich gelöscht werden?`, 'Archiveintrag löschen', "Löschen"),
        ]
      }))
    },
    hasSelectedRows() {
      return this.selectedRows?.length > 0;
    },
    steps() {
      return [
        {
          stepKey: 'insurancedata',
          label: 'Versicherungsdaten'
        },
        {
          stepKey: 'vn-persondata',
          label: 'Daten Versicherungsnehmer'
        },
        {
          stepKey: 'vp-persondata',
          label: 'Daten (mit-)versicherte Person'
        },
      ]
    },
    tabsStepperMediator() {
      return new StepperMediator(this.steps);
    },
    websiteId() {
      return this.windowConfig && this.windowConfig.websiteId || '';
    },
    isLinkDisabled() {
      if (this.websiteId === 'Franke & Bornberg fb-tools') {
        return !this.vnComponentData || (this.vnComponentData && 
        (this.vnComponentData.INPUT_PERSONEN1 === ''|| 
        this.vnComponentData.COMBO_VERSICHERUNGSART === ''))
      } else if(this.websiteId === "Tarifrechner Nürnberger BT4All"){
        return false;
      }
      return false;
    },
    isSomeButtonLoading() {
      if(this.websiteId === "Tarifrechner Nürnberger BT4All" && this.websiteId !== 'Dialog Lebensversicherung')
        return false;

      return !!(Object.values(this.loadingState).filter(valueLoading => valueLoading).length)
    },
    isSomeRowSelected() {
      if((this.websiteId === 'Nafi-KFZ' && this.singleSelectedRow?.length) || this.websiteId === "Tarifrechner Nürnberger BT4All" || this.websiteId !== 'Dialog Lebensversicherung' || this.selectedRows?.length){
        return true;
      }
      return false;
    },
    baseModalActions() {
      const actions =  [];

      if (this.websiteId !== 'Franke & Bornberg fb-tools' && this.websiteId !== 'Innosystems' && this.websiteId !== 'Dialog Lebensversicherung') {
        actions.push(new BaseModalSimpleAction('LOGIN_MIT_VERSICHERUNGSDATEN', 'Login mit Versicherungsdaten')
          .withDisabled(() => !this.isSomeRowSelected || this.isSomeButtonLoading || this.isLinkDisabled));

          if (this.websiteId == 'Nafi-KFZ' || this.websiteId == 'Finanzportal24') {
            actions.push(new BaseModalSimpleAction('LOGIN_OHNE_VERSICHERUNGSDATEN', 'Login ohne Versicherungsdaten')
              .withDisabled(() => this.isSomeButtonLoading || this.isLinkDisabled));
          }
         
      }      

      return actions;
    },
    customOptionMenu() {
      return [
        {
          label: 'aktuelle Berechnungen',
          textBold: () => this.modus === 'aktuell',
          action: () => {
            this.modus = 'aktuell'
          }
        },
        {
          label: 'archivierte Berechnungen',
          textBold: () => this.modus === 'archiv',
          action: () => {
            this.modus = 'archiv'
          }
        },
      ]
    },
  },
  methods: {
    clickInnosystemsButtom(button) {
      const form = {
        innosystemsAction: button
      }

      this.setExterneZugang();
      this.askOptionsConfirmed(form);
    },
    setExterneZugang() {
      const externeZugang = this.externeZugangVergleichList?.find(item => item.path === this.windowConfig.path);
      if (externeZugang) {
        this.externeZugang = { ...externeZugang }
      }
    },
    goToArchivedSession(insurance) {
      this.loadingState.other = true

      const payload = {
        ...insurance,
        backURL: this.generateCallbackPath()
      }
      let serviceUrl = '/beratungVersicherung/retrieveArchiveLink';

      axios.post(`${process.env.VUE_APP_API}` + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
          setTimeout(() => {
            window.open(response.data, '_blank');
          }, 1000);
        }
      }).finally(() => {
        this.loadingState.other = false
      })
    },
    onChange(row) {
      this.singleSelectedRow = [row]
    },
    onChangePersonVN(event) {
      this.vnComponentData[event.componentId] = event.value
      if (event.componentId === "INPUT_PERSONEN1" && event.value !== '') {
        this.loadPersonalDataVN()
      }
    },
    onChangePersonVP(event) {
      this.vpComponentData[event.componentId] = event.value
      if (event.componentId === "INPUT_PERSONEN2" && event.value !== '') {
        this.loadPersonalDataVP()
      }
    },
    updateInsuranceType(event) {
      this.vnComponentData[event.componentId] = event.value
      this.retrieveInsurances(event.value)
    },
    updateInsuranceTypeArchiv(event) {
      this.vnComponentData[event.componentId] = event.value
      this.retrieveArchive(event.value)
      this.currentInsuranceType = event.value
    },
    setStep(newStep){
      this.currentStep = newStep
    }, 
    handleOtherActions(actionData) {
      if (actionData?.key == 'LOGIN_MIT_VERTRAGSDATEN') {
        this.loginToExternalWebsite();
      } else if (actionData?.key == 'LOGIN_MIT_KUNDENDATEN') {
        this.loginToExternalWebsite(false);
      } else if (actionData?.key == 'LOGIN_MIT_VERSICHERUNGSDATEN') {
        this.loginToExternalWebsite();
      }else if (actionData?.key == 'LOGIN_OHNE_VERSICHERUNGSDATEN') {
        this.loginToExternalWebsite(false);
      }
    },
    retrieveArchive(insuranceType) {
      this.loadingState.other = true

      const params = makeQueryParam({ insuranceType })
      let serviceUrl = '/beratungVersicherung/retrieveArchiveData';

      axios.get(`${process.env.VUE_APP_API}` + serviceUrl + `?${params}`, config).then(response => {
        if (response && response.data) {
          this.frankeBornbergArchiveData = response.data
        }
      }).finally(() => {
        this.loadingState.other = false
    })
    },
    deleteArchiveData({transactionId}) {
      this.loadingState.other = true
      const params = makeQueryParam({ transactionId })
      let serviceUrl = '/beratungVersicherung/deleteArchiveData';

      axios.delete(`${process.env.VUE_APP_API}` + serviceUrl + `?${params}`, config).then(() => {
        this.retrieveArchive(this.currentInsuranceType)
      }).finally(() => {
        this.loadingState.other = false
      })
    },
    async retrieveInsurances(insuranceType) {
      this.loadingState.init = true;
      await this.$store.dispatch(BERATUNG_VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCES_CUSTOMER, {websiteId: encodeURIComponent(this.websiteId), insuranceType: insuranceType})
      .finally(() => {
        this.loadingState.init = false
      })
    },
    async retrieveAdditionalData(isInit = false) {
      this.loadingState.other = true

      let serviceUrl = '/beratungVersicherung/loadInsurancesInfo';

      return axios.post(`${process.env.VUE_APP_API}` + serviceUrl, isInit ? [{}, {}] : [this.vnComponentData, this.vpComponentData], config).then(response => {
        if (response && response.data) {
          this.vnComponents = response.data.vnComponents
          this.vnComboboxSelections = response.data.vnComboboxValues
          this.vnComponentData = response.data.vnData
          this.vpComponents = response.data.vpComponents
          this.vpComboboxSelections = response.data.vpComboboxValues
          this.vpComponentData = response.data.vpData
        }
      }).finally(() => {
        this.loadingState.other = false
      })
    },
    async loadPersonalDataVN() {
      this.loadingState.other = true

      let serviceUrl = '/beratungVersicherung/loadPersonalData';

      const person = "vn"
      const payload = {...this.vnComponentData, person}

      return axios.post(`${process.env.VUE_APP_API}` + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
          this.vnComponentData = response.data.vnData
        }
      }).finally(() => {
        this.loadingState.other = false
      })
    },
    async loadPersonalDataVP() {
      this.loadingState.other = true

      let serviceUrl = '/beratungVersicherung/loadPersonalData';

      const person = "vp"
      const payload = {...this.vpComponentData, person}

      return axios.post(`${process.env.VUE_APP_API}` + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
          this.vpComponentData = response.data.vpData
        }
      }).finally(() => {
        this.loadingState.other = false
      })
    },
    savePersonalData() {
      this.loadingState.other = true
      // saving only if there are values to be saved
      if(this.vnComponentData && Object.values(this.vnComponentData).length) {
        var payload = [this.makeFrankeBornbergPojoVN(), this.makeFrankeBornbergPojoVP()]
        this.$store.dispatch(BERATUNG_VERSICHERUNG_TYPES.ACTIONS.SAVE_INSURANCE_CUSTOMER, payload)
        .finally(() => {
          this.loadingState.other = false
        })
      }
    },
    getTitle(websiteId) {
      switch (websiteId) {
        case "Franke & Bornberg fb-tools":
          return "Franke & Bornberg";
        case "FinanzPortal24":
        case "Nafi-KFZ":
        case "Innosystems": 
        default:
          return websiteId;
      }
    },
    async getMaklerHauptnummer(){
      return axios.get(`${process.env.VUE_APP_API}/beratungVersicherung/getMaklerHauptnummer`)
        .then(response => {
          if(response.data.length > 0){
            this.fremdnummer = response.data;
            this.hasMaklerHauptnummer = true;
          }else{
            this.fremdnummer = [];
            this.hasMaklerHauptnummer = false;
          }
        }).finally(() => {
          this.setFremdnummerComboboxData();
        });
    },
    async getHauptperson() {
      return axios.get(`${process.env.VUE_APP_API}/beratungVersicherung/getHauptperson`)
        .then(response => {
          if(response.data.length > 0) {
            this.hauptPersonListe = response.data;
          }
        }).finally(() => {
          this.setHauptPersonData();
        });   
    },
    async getPersonenListe(){
      return axios.get(`${process.env.VUE_APP_API}/beratungVersicherung/getPersonenListe`)
        .then(response => {
          if(response.data.length > 0) {
            this.personenListe = response.data;
          } else {
            this.personenListe = [];
          }
        }).finally(() => {
          this.setPersonenComboboxData();
        });
    },
    setFremdnummerComboboxData(){
      if(!this.fremdnummer)
        return [];

      let newList = [];
      this.fremdnummer.forEach(item => {
        if(item.itemNummer)
          newList.push(item.itemNummer);
      });

      this.fremdnummerComboboxHasData = newList.length > 1;
      this.fremdnummerComboboxData = newList;

      if(newList.length > 0)
        this.fremdnummerChange(newList[0]);
    },
    setHauptPersonData() {
      if(this.hauptPersonListe) {
        this.hauptPersonDataValue = this.hauptPersonListe[0].value;
        this.hauptPersonDataLabel = this.hauptPersonListe[0].label;
      } else {
        this.hauptPersonDataValue = null;
        this.hauptPersonDataLabel = null;
        }
    },
    setPersonenComboboxData(){
      if (!this.personenListe)
        return [];

      let newList = [];
      this.personenListe.forEach(item => {
        if (item)
          newList.push(item);
      });

      this.personenComboBoxHasData = newList.length > 1;
      this.personenComboBoxData = newList;
    },
    fremdnummerChange(value){
      if (this.fremdnummerComboboxData) {
        const selectedItem = this.fremdnummerComboboxData.find(item => item === value);
        if (selectedItem) {
          this.fremdnummerComboboxSelectedData = selectedItem;
          this.isHauptnummerSelected = true;
          this.isBt4AllButtonEnabled = !!this.bt4allComboboxSelection;
        }else{
          this.fremdnummerComboboxSelectedData = null;
          this.isHauptnummerSelected = false;
          this.isBt4AllButtonEnabled = false;
        }
      }
    },
    personenChange(person){
      if (this.personenComboBoxData) {
        const selectedItem = this.personenComboBoxData.find(item => item.value === person);
        if (selectedItem) {
          this.personenComboBoxSelectedValue = selectedItem.value;
        } else {
          this.personenComboBoxSelectedValue = null;
        }
      }
    },
    async getBT4AllInfo(){
      return axios.get(`${process.env.VUE_APP_API}/beratungVersicherung/getBT4AllPruefungInfo`)
        .then(response => {
          if (Array.isArray(response.data)) {
            let list = [];
            response.data.forEach(item => {
              list.push(item);
            });
            this.bt4AllComboboxSelections = list;
          }
        });
    },
    bt4AllChange(value) {
      if (this.bt4AllComboboxSelections) {
        const selectedItem = this.bt4AllComboboxSelections.find(item => item.label === value);
        if (selectedItem) {
          this.isBt4AllSelected = true;
          this.bt4allSelectedItem = selectedItem;
          this.bt4allComboboxSelection = selectedItem.label;

          this.isBt4AllButtonEnabled = !!this.fremdnummerComboboxSelectedData;

        }else{
          this.isBt4AllSelected = false;
          this.bt4allSelectedItem = null;
          this.bt4allComboboxSelection = null;
          this.isBt4AllButtonEnabled = false;
        }
      }
    },
    loginToExternalWebsite(hasVersList = true) {
      let loadingKey
      if (hasVersList) {
        loadingKey = 'external'
      } else {
        loadingKey = 'externalNoData'
      }
      this.loadingState[loadingKey] = true
      let versIdsList = this.singleSelectedRow;
      switch (this.websiteId) {
        case "Nafi-KFZ":
          if(!hasVersList){
            versIdsList = [];
          }
          this.openExterneZugangWithIdList(this.windowConfig.path, versIdsList)
            .finally(() => this.loadingState[loadingKey] = false)
          break;
        case "Finanzportal24":
          this.openExterneZugangWithIdList(this.windowConfig.path, this.selectedRows)
            .finally(() => this.loadingState[loadingKey] = false)
          break;
        case "Franke & Bornberg fb-tools":
          const payload = [this.makeFrankeBornbergPojoVN(), this.makeFrankeBornbergPojoVP()]
          this.openExterneZugangWithIdList(this.windowConfig.path, hasVersList ? this.selectedRows : null, payload)
            .finally(() => this.loadingState[loadingKey] = false)
          break;
        case "Tarifrechner Nürnberger BT4All":
          axios({
            method: "post",
            url: `${process.env.VUE_APP_API}/externe_zugang/get_bt4all_url`,
            data: {
              "backgroundColor" : this.bt4allSelectedItem.backgroundColor,
              "data": this.bt4allSelectedItem.data,
              "display": this.bt4allSelectedItem.display,
              "label": this.bt4allSelectedItem.label,
              "nullItem": this.bt4allSelectedItem.nullItem,
              "value": this.bt4allSelectedItem.value,
              "fremdnummer": this.fremdnummerComboboxSelectedData,
            }
          }).then(response => {
            if (response.data && response.data.url) {
              window.open(response.data.url, '_blank').focus();
              //this.openExterneZugang(response.data.url);
            }
          }).finally(() => {
            this.loadingState[loadingKey] = false;
          });
          break;
        case "Dialog Lebensversicherung":
          axios({
            method: "post",
            url: `${process.env.VUE_APP_API}/externe_zugang/get_dialog_url`,
            data: {
              "hauptPerson": this.hauptPersonDataValue,
              "secondPerson": this.personenComboBoxSelectedValue
            }
          }).then(response => {
            if (response.data && response.data.url) {
              window.open(response.data.url, '_blank').focus();
            }
          }).finally(() => {
            this.loadingState[loadingKey] = false;
          });
          break;
        default:
          break;
      }
    },
    generateCallbackPath() {
      const params = makeQueryParam({ ...this.$route.query, isDeepLink: true })
      const relativePath = this.$route.path + '?' + params
      return window.location.href.substring(0, window.location.href.indexOf(window.location.pathname)) + relativePath
    },
    makeFrankeBornbergPojoVN() {

      return {
        personId: this.vnComponentData?.INPUT_PERSONEN1,
        groesse: this.vnComponentData?.INPUT_GROESSE,
        gewicht: this.vnComponentData?.INPUT_GEWICHT,
        sRaucher: this.vnComponentData?.RADIO_RAUCHER,
        sKoerperlicheTaetigkeit: this.vnComponentData?.INPUT_KOERPERLICHE_TAETIGKEIT,
        sBueroTaetigkeit: this.vnComponentData?.INPUT_BUERO_TAETIGKEIT,
        bruttojahreseinkommen: this.vnComponentData?.INPUT_BRUTTOJAHRESEINKOMMEN,
        nettojahreseinkommen: this.vnComponentData?.INPUT_NETTOJAHRESEINKOMMEN,
        sBerufstatus: this.vnComponentData?.COMBO_BERUFSSTATUS,
        sPersonalverantwortung: this.vnComponentData?.INPUT_ANZAHL_PERSONALVERANTWORTUNG,
        sBildungsabschluss: this.vnComponentData?.COMBO_HOECHSTER_BILDUNGSABSCHLUSS,
        sReisetaetigkeit: this.vnComponentData?.INPUT_REISETAETIGKEIT,
        sTaetigkeitsstatus: this.vnComponentData?.COMBO_TAETIGKEITSSTATUS,
        sBeruf: this.vnComponentData?.INPUT_BERUFSBEZEICHUNG,
        sAkademischerAbschluss: this.vnComponentData?.COMBO_AKADEMISCHER_ABSCHLUSS,
        sProfessionalTraining: this.vnComponentData?.COMBO_PROFESSIONAL_TRAINING,
        sDefinitionProfessionalTraining: this.vnComponentData?.COMBO_DEFINITION_PROFESSIONAL_TRAINING,
        beamtenstatus: this.vnComponentData?.COMBO_BEAMTENSTATUS,
        afterTaxIncome: this.vnComponentData?.INPUT_NETTOJAHRESEINKOMMEN,
        versArt: this.vnComponentData?.COMBO_VERSICHERUNGSART,
        backURL: ''
      }
    },
    makeFrankeBornbergPojoVP() {

      return {
        personId: this.vpComponentData?.INPUT_PERSONEN2,
        groesse: this.vpComponentData?.INPUT_GROESSE2,
        gewicht: this.vpComponentData?.INPUT_GEWICHT2,
        sRaucher: this.vpComponentData?.RADIO_RAUCHER2,
        sKoerperlicheTaetigkeit: this.vpComponentData?.INPUT_KOERPERLICHE_TAETIGKEIT2,
        sBueroTaetigkeit: this.vpComponentData?.INPUT_BUERO_TAETIGKEIT2,
        bruttojahreseinkommen: this.vpComponentData?.INPUT_BRUTTOJAHRESEINKOMMEN2,
        nettojahreseinkommen: this.vpComponentData?.INPUT_NETTOJAHRESEINKOMMEN2,
        sBerufstatus: this.vpComponentData?.COMBO_BERUFSSTATUS2,
        sPersonalverantwortung: this.vpComponentData?.INPUT_ANZAHL_PERSONALVERANTWORTUNG2,
        sBildungsabschluss: this.vpComponentData?.COMBO_HOECHSTER_BILDUNGSABSCHLUSS2,
        sReisetaetigkeit: this.vpComponentData?.INPUT_REISETAETIGKEIT2,
        sTaetigkeitsstatus: this.vpComponentData?.COMBO_TAETIGKEITSSTATUS2,
        sBeruf: this.vpComponentData?.INPUT_BERUFSBEZEICHUNG2,
        sAkademischerAbschluss: this.vpComponentData?.COMBO_AKADEMISCHER_ABSCHLUSS2,
        sProfessionalTraining: this.vpComponentData?.COMBO_PROFESSIONAL_TRAINING2,
        sDefinitionProfessionalTraining: this.vpComponentData?.COMBO_DEFINITION_PROFESSIONAL_TRAINING2,
        beamtenstatus: this.vpComponentData?.COMBO_BEAMTENSTATUS2,
        afterTaxIncome: this.vpComponentData?.INPUT_NETTOJAHRESEINKOMMEN2,
        versArt: this.vnComponentData?.COMBO_VERSICHERUNGSART,
        backURL: ''
      }
    },
  },
  async mounted() {
    if (this.$route.params?.websiteId) {
      this.windowConfig.websiteId = decodeURIComponent(this.$route.params.websiteId)
      this.windowConfig.title = this.getTitle(decodeURIComponent(this.$route.params.websiteId));
      this.windowConfig.path = this.$route.query?.path;
    }

    if (this.websiteId === 'Innosystems') {
      // empty block
    } else if (this.websiteId === 'Franke & Bornberg fb-tools') {
      await this.retrieveAdditionalData(true)
      if (this.vnComponentData.COMBO_VERSICHERUNGSART && this.vnComponentData.COMBO_VERSICHERUNGSART_ARCHIV) {
        this.updateInsuranceType({componentId: 'COMBO_VERSICHERUNGSART', value: this.vnComponentData.COMBO_VERSICHERUNGSART});
        this.updateInsuranceTypeArchiv({componentId: 'COMBO_VERSICHERUNGSART_ARCHIV', value: this.vnComponentData.COMBO_VERSICHERUNGSART_ARCHIV});
      }
    } else if (this.websiteId === "Tarifrechner Nürnberger BT4All"){
      this.isBt4AllLoading = true;
      await Promise.all([
        await this.getBT4AllInfo(),
        await this.getMaklerHauptnummer()
      ]);
      this.isBt4AllLoading = false;
    } else if (this.websiteId === "Dialog Lebensversicherung"){
      this.isDialogLebenLoading = true;
      await Promise.all([
        await this.getMaklerHauptnummer(),
        await this.getHauptperson(),
        await this.getPersonenListe(),
      ]);
      this.isDialogLebenLoading = false;
    } else {
      await this.retrieveInsurances();
    }
    

    if (this.websiteId !== 'Nafi-KFZ') {
      this.selectedRows = this.rows || []
    } else if (this.websiteId === 'Franke & Bornberg fb-tools') {
      this.selectedRows = []
    } else {
      this.selectedRows = null
    }

  },
  beforeDestroy() {
    this.savePersonalData()
    this.$store.commit(BERATUNG_VERSICHERUNG_TYPES.MUTATIONS.RESET_DATA)
  },
  beforeRouteLeave(to, from, next) {
    this.savePersonalData()
    this.$store.commit(BERATUNG_VERSICHERUNG_TYPES.MUTATIONS.RESET_DATA)
    next();
  },
};
</script>

<style scoped>
  .antrag-action-button__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .innosystem-links__container > a{
    display: block;
    width: fit-content;
  }

  @media screen and (max-width: 420px) {
    .fb-tools-sm-button-actions .button {
      width: 100%;
    }
  }
</style>