var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "GhostLoading",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { useBoxContainer: "" }
        },
        [
          _c("Block", { attrs: { height: "50" } }),
          _c("Block", { attrs: { height: "400" } })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ]
        },
        [
          _c("div", { staticClass: "row settings-row" }, [
            _vm.hasManualAmount
              ? _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("InputField", {
                      attrs: { label: "Startbetrag", type: "currency" },
                      model: {
                        value: _vm.initialInvestment,
                        callback: function($$v) {
                          _vm.initialInvestment = $$v
                        },
                        expression: "initialInvestment"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("div", [_vm._v("Jahre")]),
                _c("ComboBox", {
                  attrs: {
                    value: _vm.timeSpan,
                    values: _vm.AMOUNT_YEARS_OPTIONS
                  },
                  on: { change: _vm.onChangeTimeSpan }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("div", [_vm._v("Simulationen")]),
                _c("InputField", {
                  model: {
                    value: _vm.amountSimulations,
                    callback: function($$v) {
                      _vm.amountSimulations = $$v
                    },
                    expression: "amountSimulations"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "button__container" },
              [
                _c(
                  "BaseButton",
                  {
                    staticStyle: { "max-height": "50px" },
                    on: {
                      click: function($event) {
                        return _vm.onClickSimulate()
                      }
                    }
                  },
                  [_vm._v("Simulieren")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "info__container" }, [
              _c(
                "a",
                [
                  _c("PhInfo", {
                    staticClass: "info__icon",
                    attrs: { size: 20 },
                    on: {
                      click: function($event) {
                        return _vm.openInfoModal()
                      }
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm.warnings
            ? _c(
                "div",
                { staticClass: "row" },
                [
                  _c("BaseCollapsable", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [_vm._v("Warnungen")]
                          },
                          proxy: true
                        },
                        {
                          key: "content",
                          fn: function() {
                            return [
                              _c(
                                "ul",
                                _vm._l(_vm.warnings, function(warning) {
                                  return _c("li", [
                                    _vm._v(" " + _vm._s(warning))
                                  ])
                                }),
                                0
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3090940180
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _c("hr"),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.chartSeries && _vm.chartSeries.length > 0,
                  expression: "chartSeries && chartSeries.length > 0"
                }
              ]
            },
            [
              _c("LineChart", {
                ref: "lineChart",
                attrs: {
                  chartSeries: _vm.chartSeries,
                  isArea: false,
                  customTooltipFormatter: _vm.customTooltipFormatter,
                  height: _vm.height,
                  format: _vm.format,
                  isLegend: false,
                  gridLeft: 75,
                  chartOptions: _vm.chartOptions
                }
              }),
              _vm.lineChart
                ? _c("ChartLegend", {
                    attrs: {
                      showAsTable: false,
                      chartSeries: _vm.chartSeriesWithoutDifferenzLine,
                      lineChart: _vm.lineChart
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("InfoModalMonteCarlo", { ref: "montecarloModal" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }