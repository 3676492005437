<template>
  <div ref="messageContainerEl" class="message__container">
    <transition-group name="slide-fade" tag="div">
      <div class="message boxes__corner--rounded boxes__shadow--smooth" 
          :class="{ 
            'message--success': message.type === 'success',
            'message--warning': message.type === 'warning',
            'message--primary': message.type === 'primary',
            'message--danger': message.type === 'danger',
          }"
          v-for="message in messages" v-bind:key="message.id">
        <div>
          <div class="row my-0 message-content">
            <div class="col text-left"><div v-html="sanitize(message.content)" class="message-content--text"></div></div>
          </div>
          <div class="row mt-2 mb-0" v-if="message.actions">
            <div class="col-auto">
              <div class="layout__negative-margin--8 no-line-height">
                <template v-for="(action, count) in message.actions">
                  <BaseButton 
                    :key="count" 
                    :label="action.label" 
                    :isPrimary="action.isPrimary" 
                    :isSecondary="action.isSecondary"
                    isSmall
                    @click="executeAction(message, action)"/>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="button__container">
          <PhXCircle class="clickable" :size="24" @click="closeMessage(message)" />
        </div>
      </div>
    </transition-group>
    <div v-if="hasMessages" class="close-all__container">
      <BaseButton class="close-all__link boxes__shadow--smooth" isPrimary @click="closeAll()">alle schließen</BaseButton>
    </div>
  </div>
</template>

<script>
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from 'vuex';
import { PhXCircle } from 'phosphor-vue'
import BaseButton from '@/components/core/BaseButton.vue'
import {sanitize} from '@/helpers/string-helper';

const HEADER_HEIGHT = 40;

export default {
  computed: {
    ...mapGetters({
      messages: LOG_TYPES.GETTERS.MESSAGES
    }),
    hasMessages() {
      return this.messages?.length
    }
  },
  methods: {
    closeMessage(message) {
      this.$store.dispatch(LOG_TYPES.ACTIONS.REMOVE_MESSAGE, message);
    },
    closeAll() {
      for (const message of this.messages) {
        this.closeMessage(message)
      }
    },
    executeAction(message, action) {
      if (action?.path) {
        this.$router.push({path: action.path, query: {isDeepLink: true}}).catch(() => {})
      } else if('handler' in action) {
        action.handler();
      }
      this.closeMessage(message)
    },
    onScroll() {
      const { messageContainerEl } = this.$refs;
      if(!messageContainerEl) return;

      const { scrollY } = window;
      if(scrollY > HEADER_HEIGHT) {
        messageContainerEl.classList.add('sticky');
      } else {
        messageContainerEl.classList.remove('sticky');
      }
    },
    sanitize(text) {
      return sanitize(text);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
  components: {
    PhXCircle,
    BaseButton,
  }
}
</script>

<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }

  .boxes__shadow--smooth {
    box-shadow: -2px 4px 8px rgba(85, 85, 85, 0.2);
  }

  .message__container {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    position: fixed;
    right: 0;
    top: 40px;
    width: 100%;
    z-index: 12000;
    transform: translate(0, 0);
    transition: transform .2s ease;
    will-change: transform;
    max-height: calc(100% - 40px);

    &.sticky {
      transform: translate(0, -40px);
      max-height: 100%;
    }
  }

  @media screen and (min-width: 768px) {
    .message__container {
      width: 650px;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    .message__container {
      max-height: calc(100% - 100px);

      &.sticky {
        max-height: calc(100% - 60px);
      }

      .message-content {
        max-height: calc(100vh - 240px);
        max-height: calc(100svh - 240px);
      }
    }
  }

  .close-all__container {
    display: flex;
    justify-content: flex-end;
    margin: 0;
  }

  .close-all__link {
    border-width: 0;
    margin: 4px 12px 16px;
    padding: .75em 1em;
  }

  .message {
    position: relative;
    display: flex;
    text-align: right;
    padding: 1em .75em 1em 1em;
    margin: 12px;
    justify-content: space-between;
  }

  .message-content {
    max-height: calc(100vh - 2em - 124px);
    max-height: calc(100svh - 2em - 124px);
    overflow: auto;
    word-break: break-word;
    line-height: 1.5;

    .message-content--text {
      overflow: hidden;
    }
  }

  .message--success {
    background-color: var(--color-success-background);
    color: var(--color-success);
  }

  .message--warning {
    background-color: var(--color-warning-background);
    color: var(--color-warning);
  }

  .message--primary {
    background-color: var(--color-info-background);
    color: var(--color-info);
  }

  .message--danger {
    background-color: var(--color-danger-background);
    color: var(--color-danger);
  }

  .button__container {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    color: inherit;
    margin: 0 0 0 .75em;
  }

  .button__container * {
    color: inherit;
  }

  .slide-fade-enter-active {
    transition: all .3s cubic-bezier(.1,.5,0,1);
  }
  .slide-fade-leave-active {
    transition: all .6s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(100px);
    opacity: 0;
  }

</style>

<!-- GLOBAL STYLE -->
<style>
.chat-notification__container p {
  margin: 0 0 0.25em;
}
.chat-notification__container img {
  width: auto;
  height: auto;
  max-height: 80px;
}
</style>
