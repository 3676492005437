<template>
  <div>
    <GhostLoading v-if="loading" type="table" />
    <NoData v-else-if="!hasData" />
    <Table
      v-else
      :headers="headers"
      :rows="rows"
      @action-EDIT="$emit('edit', $event)"
      @action-DELETE="$emit('remove', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EX_POST_MANUELLE_TYPES from "@/store/exPostManuelle/types";

import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import NoData from "@/components/core/NoData.vue";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  CurrencyColumn,
  ActionColumn,
  SimpleAction,
  ConfirmedAction,
} from "@/components/table2/table_util";

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      list: EX_POST_MANUELLE_TYPES.GETTERS.LIST,
    }),
    hasData() {
      return this.rows?.length > 0;
    },
    headers() {
      return {
        center: [
          TextColumn("lagerstelleName", "Lagerstelle"),
          TextColumn("depotnummer", "Depotnummer"),
          TextColumn("jahr", "Jahr"),
          TextColumn("artText", "Art"),
          CurrencyColumn("betrag", "Betrag", 2),
          CurrencyColumn("betragMakler", "Anteil Berater", 2),
        ],
        lockedRight: [ActionColumn("actions", "Aktionen")],
      };
    },
    rows() {
      return [...(this.list || [])].map((row) => {
        const actions = row.isEditable
          ? [
              SimpleAction("EDIT", '', "Bearbeiten"),
              ConfirmedAction(
                "DELETE",
                '',
                "Löschen",
                `Möchten Sie den Eintrag wirklich löschen?`,
                "Eintrag löschen"
              ),
            ]
          : [];
        return {
          ...row,
          actions,
        };
      });
    },
  },
  methods: {
    async findExPostManuelle() {
      try {
        this.loading = true;
        await this.$store.dispatch(EX_POST_MANUELLE_TYPES.ACTIONS.FIND_LIST);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.findExPostManuelle();
  },
  components: {
    GhostLoading,
    NoData,
    Table,
  },
};
</script>
