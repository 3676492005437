<template>
  <div>
    <div v-if="title" class="box__title">{{ title }}</div>

    <GhostLoading v-if="loading" type="table" />
    <Table
      v-else
      :headers="headers"
      :rows="rows"
      @action-ADD="saveBiproGesellschaft($event)"
      @action-DELETE="deleteBiproGesellschaft($event)"
    />
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import EXTERNE_ZUGANG_CONFIG_TYPES from '@/store/externeZugangConfig/types';

import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from '@/components/table2/Table.vue';
import { TextColumn, ActionColumn, SimpleAction, } from '@/components/table2/table_util.js';

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    gesellschaft: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      biproSiblingGesellschaften: EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.BIPRO_SIBLING_GESELLSCHAFTEN,
    }),
    headers() {
      const headers = {
        center: [
          TextColumn('gesellschaftId', 'Gesellschaft ID'),
          TextColumn('gesellschaftName', 'Gesellschaft Name'),
          TextColumn('vuNr', 'Bafin Kennung (VuNr.)'),
        ],
        lockedRight: [],
      };

      if(!this.disabled) {
        headers.lockedRight.push(ActionColumn('actions', 'Aktionen'));
      }

      return headers;
    },
    rows() {
      return [ ...this.biproSiblingGesellschaften?.[this.gesellschaft] || [], ].map(item => {
        const actions = [];

        if(item.selected) {
          actions.push(SimpleAction('DELETE', '', 'Fremdanlagen Kosten entfernen'));
        } else {
          actions.push(SimpleAction('ADD', '', 'Fremdanlagen Kosten setzen'));
        }

        return {
          ...item,
          actions,
        };
      });
    },
  },
  watch: {
    gesellschaft: {
      handler() {
        this.findBiproSiblingGesellschaften();
      },
      immediate: true,
    },
  },
  methods: {
    async findBiproSiblingGesellschaften() {
      try {
        this.loading = true;
        const bezeichnung = this.gesellschaft;
        await this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.FIND_BIPRO_SIBLING_GESELLSCHAFTEN, { bezeichnung, });
      } finally {
        this.loading = false;
      }
    },
    async saveBiproGesellschaft(row) {
      await this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.SAVE_BIPRO_GDV_PARAMETER, { ...row, });
      this.findBiproSiblingGesellschaften();
    },
    async deleteBiproGesellschaft(row) {
      await this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.DELETE_BIPRO_GDV_PARAMETER, { ...row, });
      this.findBiproSiblingGesellschaften();
    },
  },
  components: {
    GhostLoading,
    Table,
  },
}
</script>
