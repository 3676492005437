var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "autocomplete" },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onChange(true)
            }
          }
        },
        [
          _c("InputField", {
            attrs: {
              type: "text",
              label: _vm.label,
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
              autofocus: ""
            },
            on: {
              input: function($event) {
                return _vm.onChange(false)
              },
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onChange(true)
              }
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("SlideInInformation", { ref: "slideInInfo" }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showResults,
              expression: "showResults"
            }
          ],
          staticClass: "results-container"
        },
        [
          _vm._l(_vm.receivedData, function(item) {
            return _c("AutocompleteResult", {
              key: item.gruppe,
              attrs: {
                title: item.gruppe,
                results: item.results,
                navigationType: item.navigationType,
                showAdvancedSearch: !!item.showAdvancedSearch,
                loading: _vm.isLoading
              },
              on: {
                click: item.onclick,
                advancedSearch: function($event) {
                  return item.onadvancedSearch()
                }
              }
            })
          }),
          _vm.showResults &&
          _vm.isLoading.length === 0 &&
          _vm.noResult &&
          _vm.hasSearch
            ? _c("div", { staticClass: "results-container--no-results" }, [
                _vm._v(" Keine Treffer! ")
              ])
            : _vm._e(),
          _vm.isLoading.length !== 0
            ? _c("div", { staticClass: "results-container--loading" }, [
                _c(
                  "div",
                  { staticClass: "loading" },
                  [_c("AnimatedSpinner"), _vm._v(" Suche läuft... ")],
                  1
                )
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }