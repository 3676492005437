<template>
    <div class="dashboard-panel__widgets">
        <div class="box__container dashboard-panel-widget">
            <div class="box__title">Stammdaten</div>
            <InputField id="scenario.title"
                label="Name des Scenarios"
                :value="scenario.title"
                validateUntouched
                @input="doChanges('title', $event)"
            />
            <InputField
                label="Annahme durchschnittliche Inflation"
                :value="scenario.inflation"
                type="percent"
                @change="doChanges('inflation', $event)"
            />
            <InputTextArea
                label="Notizen"
                :value="scenario.bemerkung"
                @input="doChanges('bemerkung', $event)"
                :rows="5"
            />
            <!-- <DatePickerField
                label="Geburtstag"
                placeholder="DD.MM.JJJJ"
                isValueAsString
                :value="scenario.geburtsdatum"
                @change="doChanges('geburtsdatum', $event)"
            /> -->
        </div>
        <div class="box__container dashboard-panel-widget">
        <div class="box__title">Ruhestand</div>
            <InputField
                label="Alter bei Beginn Ruhestand"
                :value="scenario.alterRente"
                type="number"
                :precision="0"
                @change="doChanges('alterRente', $event)"
            />
            <InputToggleSwitch
                :value="scenario.ohneKapitalverzehr"
                label="Rente ohne Kapitalverzehr"
                inLineLabel
                @change="doChanges('ohneKapitalverzehr', $event)"
            />
            <InputField
                label="monatlicher Bedarf in Euro in Rente (nach heutigem Geldwert)"
                :value="scenario.bedarfMonatlich"
                type="currency"
                :disabled="scenario.ohneKapitalverzehr"
                @change="doChanges('bedarfMonatlich', $event)"
            />
            <InputField
                label="geschätzte Renteneinnahmen pro Monat"
                :value="scenario.renteMonatlichErwartet"
                type="currency"
                @change="doChanges('renteMonatlichErwartet', $event)"
            />
            <InputField
                label="Rendite im Ruhestand"
                :value="scenario.renditeRuhestand"
                type="percent"
                @change="doChanges('renditeRuhestand', $event)"
            />
            <InputField
                label="Restkapital bei Tod"
                :value="scenario.restKapital"
                type="currency"
                :precision="0"
                :disabled="scenario.ohneKapitalverzehr"
                @change="doChanges('restKapital', $event)"
            />
            <!-- <InputField
                label="Lebenserwartung in Jahren"
                :value="scenario.lebenserwartung"
                type="number"
                :precision="0"
                @change="doChanges('lebenserwartung', $event)"
            /> -->
        </div>
        
    </div>
</template>

<script>
import InputField from "@/components/core/forms/InputField.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import {parse} from '@/helpers/number-formatter.js';
import mixin from '@/views/ruhestandsplanung/mixin.js';
import RUHESTANDSPLANUNG_TYPES from '@/store/ruhestandsplanung/types';
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import Table from '@/components/table2/Table.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types.js";

export default {
    mixins: [mixin, validator],
    components: {
        InputField,
        InputTextArea,
        Table,
        CurrencyLabel,
        InputToggleSwitch,
    },
    validators: {
        scenario: {
            title: [required('Bitte geben Sie eine Bezeichnung.')],
            // geburtsdatum: [required('Bitte geben Sie den Geburtstag.')],
        }
    },
    async created() {
        if (this.isNewScenario && !this.customerData?.personalDataBirth && !this.scenario.geburtsdatum) {
            await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
        } 
        this.initStartValues();
    },
    computed: {
        ...mapGetters({
            customerDataStore: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA,
        }),
        customerData() {
            return this.customerDataStore;
        },
    },
    methods: {
        initStartValues() {
            // this.form = Object.assign(this.form, this.scenario);
            if (!this.scenario.lebenserwartung) {
                this.doChanges('lebenserwartung', this.lifeLength);
                // this.form.lebenserwartung = this.lifeLength;
            }
            if (this.customerData?.personalDataBirth?.dayOfBirth && !this.scenario.geburtsdatum) {
                this.doChanges('geburtsdatum', this.customerData.personalDataBirth.dayOfBirth);
            }
        },
        percentValue(value) {
            return (parse(value) || 0) * 100 || '';
        },
        doChanges(componentId, value) {
            this.$store.commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_STORE, { value: { [componentId]: value}, id: this.id });
        },
    },
}
</script>

<style lang="scss" scoped>
.dashboard-panel__widgets {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.dashboard-panel-widget {
  margin: 0 12px 24px;
  width: calc(50% - 24px);
}
</style>