import { VIEW_ROLES } from '@/router/roles';

export default [
        {
          path: '',
          redirect: 'landing',
        },
        { 
            path: 'login',
            component: () => import(/* webpackChunkName: "affiliate" */ '@/views/affiliate/AffiliateLogin.vue'),
        },
        {
          path: 'register',
          component: () => import(/* webpackChunkName: "affiliate" */ '@/views/affiliate/AffiliateRegister.vue'),
        },
        {
        path: 'application-details',
        component: () => import(/* webpackChunkName: "affiliate" */ '@/components/affiliate/AffiliateAntragComponent.vue'),
        children: [
            {
              path: 'landing',
              component: () => import(/* webpackChunkName: "affiliate" */ '@/components/affiliate/AffiliateAntragComponent.vue'),
            },
            {
                path: 'overview',
                //component: () => import(/* webpackChunkName: "affiliate" */ '@/components/affiliate/AffiliateAntragComponent.vue'),
              },   
          ]
        },
        {
            // if nothing matches, goes to HomeView.vue
            path: '*',
            redirect: 'landing'
          },
    ]
