<template>
  <div class="box__container">
    <BaseButton @click="openAddModal()">Neue</BaseButton>

    <Table v-if="!loading && rows.length"
        tableId="de53de84-95e7-42db-a20a-329c6c57ffe4"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        @click-bereich="openEditModal"
        @action-DELETE="openDeleteModal"
    />
    <GhostLoading v-else-if="loading" type="table" />
    <NoData v-else />

    <BaseModal
      ref="courtageDataModal"
      size="md"
      modalTitle="Zubringercourtage bearbeiten"
      :showDefaultButtons="false"
    >
      <template #default>
        <p>Bitte tragen Sie hier den Prozentsatz ein den der Zubringer in Relation zu der Courtage des Vermittlers bekommen soll, dem der Kunde zugeordnet ist.</p>

        <ComboBox
          label="Bereich"
          :values="customerDataConfig.courtageBereich"
          v-model="form.bereich"
          validateUntouched
        />

        <div class="input-forms__container">
          <div class="row mt-0 mb-0">
            <div class="col-md-3 col-12">
              <InputField
                label="Zubringer"
                v-model="form.zubringernr"
                validateUntouched
                @input="findZubringerDataDebounce($event)"
                @onBlur="checkIfZubringerDataIsValid()"
              />
            </div>

            <div class="col-md-9 col-12 mt-md-24px mt-3">
              <InputField
                :disabled="true"
                v-model="form.zubringerFullName"
              />
            </div>

            <div v-if="isZubringerInvalid" class="col-12 color-danger">
              Die Vermittlernummer des Zubringers ist nicht korrekt!
            </div>
          </div>
        </div>

        <template v-if="isIntern">
          <ComboBox
            label="Courtage"
            :values="absolutValues"
            v-model="form.absolut"
          />
        </template>

        <InputField
          label="Abschluss / Einmalige VV-Strategiegebühr"
          type="percent"
          v-model="form.provAbschluss"
          :precision="PRECISION"
        />

        <InputField
          label="Bestand"
          type="percent"
          v-model="form.provBestand"
          :precision="PRECISION"
        />

        <InputField
          label="Servicegebühr / VV-Vermittlungsgebühr"
          type="percent"
          v-model="form.provServicegebuehr"
          :precision="PRECISION"
        />

        <InputField
          label="MwSt"
          type="percent"
          v-model="form.mwst"
          validateUntouched
          :disabled="!customerDataConfig.hatZubringerOhneMwSt"
          :precision="2"
        />

      </template>
      <template #footer>
        <BaseButton isSecondary @click="closeCourtageDataModal()" class="mr-3">Abbrechen</BaseButton>
        <BaseButton :disabled="validation.isInvalid('form') || isZubringerInvalid" @click="saveForm(); closeCourtageDataModal();">Speichern</BaseButton>
      </template>
    </BaseModal>

    <BaseModal
      ref="courtageDeleteModal"
      size="sm"
      modalTitle="Courtage Zubringer löschen"
      @onConfirmButton="deleteCourtageItem()"
    >
      <template #default>
        Soll die Zubringercourtage für den Vermittler {{ courtageItemToDelete.zubringernr }} wirklich gelöscht werden?
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import CORE_TYPES from '@/store/core/types';

import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, PercentageColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import validator from '@/mixins/validator';
import { required, range } from '@/mixins/validator/rules';
import { debounce } from '@/helpers/commonfunctions.js';

const BEREICH_ALLE = 'Alle';
const MAKLERNR_LENGTH = 5;
const FORM_ZUBRINGERNR_PATH = 'form.zubringernr';
const PRECISION = 4;


export default {
  mixins: [validator],
  data() {
    return {
      PRECISION,
      loading: false,
      isZubringerInvalid: false,
      findZubingerDataDebounce: null,
      absolutValues: [
        { label: 'absolut zur Eingangscourtage', value: true, },
        { label: 'relativ zur Courtage des Abschlussvermittlers', value: false, },
      ],
      form: {},
      courtageItemToDelete: {},
    };
  },
  computed: {
    ...mapGetters({
      courtage: CUSTOMERDATA_TYPES.GETTERS.COURTAGE,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    headers() {
        const headers = {
            lockedLeft: [
                TextColumn("bereich", "Bereich").makeLink(),
            ],
            center: [
                TextColumn("zubringer", "Zubringer"),
                PercentageColumn("provAbschluss", "Abschluss / Einmalige VV-Strategiegebühr"),
                PercentageColumn("provBestand", "Bestand"),
                PercentageColumn("provServicegebuehr", "Servicegebühr / VV-Vermittlungsgebühr"),
                PercentageColumn("mwst", "MwSt."),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        };
        if(this.isIntern) {
            headers.center.splice(1, 0, TextColumn("absolutText", "absolut"));
        }
        return headers;
    },
    rows() {
        const actions = [
            SimpleAction("DELETE", '', "Löschen"),
        ];
        return (this.courtage || []).map(r => ({
            ...r,
            zubringer: `${r.zubringerFullName || ''}/${r.zubringernr || ''}`.trim(),
            provAbschluss: (r.provAbschluss || 0).toFixed(PRECISION),
            provBestand: (r.provBestand || 0).toFixed(PRECISION),
            provServicegebuehr: (r.provServicegebuehr || 0).toFixed(PRECISION),
            absolutText: r.absolut === true ? 'Ja' : 'Nein',
            actions,
        }));
    },
  },
  methods: {
    async findCourtage() {
      try {
        this.loading = true;
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_COURTAGE);
      } finally {
        this.loading = false;
      }
    },
    openCourtageDataModal() {
      this.$refs.courtageDataModal.open();
    },
    closeCourtageDataModal() {
      this.$refs.courtageDataModal.close();
    },
    openAddModal() {
      this.form = {
        id: null,
        zubringernr: null,
        zubringerFullName: null,
        absolut: false,
        bereich: BEREICH_ALLE,
        provAbschluss: null,
        provBestand: null,
        provServicegebuehr: null,
        mwst: this.customerDataConfig?.mwstValue,
      };

      this.openCourtageDataModal();
    },
    openEditModal(courtageItem) {
      this.form = {
        ...courtageItem,
      };

      this.openCourtageDataModal();
    },
    openDeleteModal(courtageItem) {
      this.courtageItemToDelete = { 
        ...courtageItem,
      };

      this.$refs.courtageDeleteModal.open();
    },
    deleteCourtageItem() {
      if(this.courtageItemToDelete?.id) {
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_COURTAGE, {
          courtageId: this.courtageItemToDelete?.id,
        });
      }
    },
    findZubringerDataDebounce: debounce(async function findZubringerData(maklernr) {
      this.form.zubringerFullName = null;

      try {
        if(maklernr?.length === MAKLERNR_LENGTH) {
          const zubringerData = await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_ZUBRINGER_DATA, { maklernr });
          this.form.zubringernr = zubringerData?.maklernr;
          this.form.zubringerFullName = zubringerData?.fullName;
        }
      } catch(e) { }

      this.checkIfZubringerDataIsValid();
    }),
    checkIfZubringerDataIsValid() {
      this.validation.reset(FORM_ZUBRINGERNR_PATH);
      this.isZubringerInvalid = false;

      if(!this.form?.zubringerFullName) {
        this.isZubringerInvalid = true;
        this.$pushErrors(FORM_ZUBRINGERNR_PATH);
      }
    },
    saveForm() {
      const form = {
        ...this.form,
        provAbschluss: this.form?.provAbschluss || 0,
        provBestand: this.form?.provBestand || 0,
        provServicegebuehr: this.form?.provServicegebuehr || 0,
        mwst: this.form?.mwst || 0,
      };
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_COURTAGE, form);
    },
  },
  mounted() {
    this.findCourtage();
  },
  validators: {
    form: {
      bereich: [required()],
      zubringernr: [required()],
      mwst: [required(), range(0, 25)],
    }
  },
  components: {
    BaseButton,
    Table,
    BaseModal,
    ComboBox,
    InputField,
    GhostLoading,
    NoData,
  },
}
</script>
