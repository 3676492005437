var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "registration-page flex flex-row",
      staticStyle: {
        "margin-left": "-40px",
        "margin-right": "-40px",
        "background-color": "whitesmoke"
      }
    },
    [
      _c("HeaderLogin", {
        on: {
          backButtonPressed: function($event) {
            return _vm.goToLogin()
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          staticStyle: {
            padding: "100px",
            "flex-basis": "40%",
            display: "flex",
            "flex-direction": "column",
            "align-items": "center"
          }
        },
        [
          _c("span", { staticStyle: { "font-size": "xx-large" } }, [
            _vm._v("Aktivieren Sie ihren Zugang")
          ]),
          _c(
            "div",
            {
              staticStyle: {
                display: "grid",
                "grid-template-columns": "1fr 3fr",
                "column-gap": "30px"
              }
            },
            [
              _c("div", {}, [_vm._v("Icon")]),
              _c("span", [
                _vm._v(_vm._s(this.maklerNr) + "Maximale Kaufkraft berechnen")
              ])
            ]
          ),
          _vm._m(0),
          _vm._m(1)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "registration-page__container",
          staticStyle: {
            "flex-basis": "60%",
            height: "100vh",
            "border-radius": "10px 0 0 10px"
          },
          style: { backgroundImage: _vm.loginContentBackground },
          attrs: { "data-app-content": "" }
        },
        [
          _c(
            "div",
            {
              staticClass: "box__container",
              staticStyle: {
                "background-color": "white",
                width: "75vh",
                padding: "30px"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "box__title",
                  staticStyle: {
                    "font-size": "x-large",
                    "justify-self": "center"
                  }
                },
                [_vm._v("Zugang zu ihrem Finanzierungsportal sichern")]
              ),
              _c(
                "form",
                {
                  staticStyle: { padding: "20px" },
                  attrs: { autocomplete: "off" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.checkDataAndProceed()
                    }
                  }
                },
                [
                  _c("InputField", {
                    attrs: {
                      id: "email",
                      label: "E-Mail*",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  }),
                  _c("InputField", {
                    attrs: {
                      id: "new-password",
                      label: "Passwort*",
                      type: "password",
                      allowPlainPassword: "",
                      allowPasswordRules: "",
                      autocomplete: "new-password"
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  }),
                  _c("InputField", {
                    attrs: {
                      id: "new-password-again",
                      label: "Passwort bestätigen*",
                      type: "password",
                      allowPlainPassword: "",
                      allowPasswordRules: "",
                      autocomplete: "new-password",
                      validateUntouched: ""
                    },
                    model: {
                      value: _vm.form.passwordConfirm,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "passwordConfirm", $$v)
                      },
                      expression: "form.passwordConfirm"
                    }
                  }),
                  _c("PasswordPolicy", {
                    staticClass: "change-password--policy is-collapsable",
                    attrs: { isCollapsable: "" }
                  }),
                  _c(
                    "BaseButton",
                    {
                      staticClass: "mr-16px",
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.goToLogin()
                        }
                      }
                    },
                    [_vm._v(" Zurück zur Anmeldung ")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        type: "submit",
                        disabled: _vm.isFormInvalid || _vm.loading
                      }
                    },
                    [
                      _vm._v(" Registrieren "),
                      _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "grid",
          "grid-template-columns": "1fr 3fr",
          "column-gap": "30px"
        }
      },
      [_c("div", {}, [_vm._v("Icon")]), _c("span", [_vm._v("t2")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "grid",
          "grid-template-columns": "1fr 3fr",
          "column-gap": "30px"
        }
      },
      [_c("div", {}, [_vm._v("Icon")]), _c("span", [_vm._v("t3")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }