<template>
  <div class="context-menu-group__container">
    <div v-if="title" class="context-menu-group--title box__title">{{ title }}</div>
    <div class="context-menu-group--content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.context-menu-group__container {
  border-top: 1px solid #ccc;
  line-height: 1.2;
  padding: 8px 0;

  &:first-child {
    border-top: none;
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .context-menu-group--title {
    color: #999;
    font-size: .8em;
    margin: 0 0 8px;
  }

  .context-menu-group--content {
    display: flex;
    flex-direction: column;
  }
}
</style>
