<template>
    <div>
      <GhostLoading v-if="configuringFlatMenu || configuringForms" type="custom" class="m-0">
        <Block type="form-input" />
      </GhostLoading>
      <form v-else class="global-search-form global-search-form-modal" v-on:submit.prevent="noop">
        <!-- v-on:keyup.enter="search($event)" -->
        <Autocomplete
          :label="label"
          placeholder="Zur Suche Text eingeben"
          :itemsCustomers="suggestCustomers[currentValue] || emptyArray"
          :itemsCompanies="suggestCompanies[currentValue] || emptyArray"
          :itemsRoutes="suggestRoutes[currentValue] || emptyArray"
          :itemsFonds="suggestFonds[currentValue] || emptyArray"
          :itemsBrokers="suggestBrokers[currentValue] || emptyArray"
          :itemsInsurances="suggestInsurances[currentValue] || emptyArray"
          :itemsForms="suggestForms[currentValue] || emptyArray"
          :itemsCustomerData="suggestCustomerData[currentValue] || emptyArray"
          :itemsDepots="suggestDepots[currentValue] || emptyArray"
          :itemsCustomerSearchCommands="customerSearchCommands[currentValue] || emptyArray"
          :itemsUnterdepotData="suggestUnterdepotData[currentValue] || emptyArray"
          :itemsCalendar="suggestCalendarData[currentValue] || emptyArray"
          :itemsExceptions="suggestExceptions[currentValue] || emptyArray"
          @input="autocompleteInput"
          @selected="selected"
          @gotosearch="gotoSearch"
          :flatMenuCustomer="flatMenuCustomer"
          :isLoading="lastRequestUrl"/>
      </form>

      <RecentMenuOpened v-show="!value && !isMobileNativeContext"  @clicked="$emit('recentMenuOpened')" />
    </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import MENU_TYPES from '@/store/menu/types';
import HTTP_REQ_TYPES from '@/store/http-requests/types'
import { mapGetters } from 'vuex';
import Autocomplete from "@/components/core/navbar-workspaces/Autocomplete.vue";
import axios from 'axios';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import RecentMenuOpened from '@/components/core/RecentMenuOpened.vue';
import { EMPLOYEE_ROLES, ROLES, VIEW_ROLES } from '@/router/roles';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import BRIDGE_TYPES from '@/store/bridge/types';
import ANTRAG_TYPES from '@/store/antrag/types';
import FC_CONFIG from '@/configs/fcConfig';
import FormUtil from '@/components/beratung/formsMenu/forms-menu';
import LOG_TYPES from '@/store/log/types';

const SPECIAL_CHAR_PATTERN = /&shy;/gi;
const EMPTYARRAY = [];

export default {
  name: 'GlobalSearch',
  props: {
    size: {
      default: 18
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      emptyArray: EMPTYARRAY, // emptyArray and not [] in template - reduce amount of props-watch-effects on Autocomplete component
      lastRequestUrl: [],
      value: '',
      lastValue: '',
      isinRegex: /^[A-Za-z]{2}[0-9]{2,10}$/,
      suggestCompanies: {},
      suggestCustomers: {},
      suggestRoutes: {},
      suggestFonds: {},
      suggestBrokers: {},
      suggestInsurances: {},
      suggestForms: {},
      suggestCustomerData: {},
      suggestDepots: {},
      suggestUnterdepotData: {},
      customerSearchCommands: {},
      suggestCalendarData: {},
      suggestExceptions: {},
      showModal: false,
      configuringFlatMenu: false,
      flatMenu: [],
      flatMenuCustomer: [],
      availableForms: [],
      configuringForms: false,
      isTestMakler: false,
    }
  },
  computed: {
    ...mapGetters({
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      antragListFormulare: ANTRAG_TYPES.GETTERS.ANTRAG_LIST_FORMULARE,
      hasAccess: CORE_TYPES.GETTERS.HAS_ACCESS,
      isTestUser: CORE_TYPES.GETTERS.IS_TEST,
    }),
    currentValue() {
      if (this.value.includes(this.lastValue) || this.lastValue.includes(this.value)) {
        return this.lastValue;
      }
      return this.value;
    },
    isBrokerOrIntern() {
      return this.isBroker || this.isIntern
    },
    isCompaniesEnabled() {
      return this.hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_INTERN]);
    },
    isFormulareEnabled() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]) && this.hasAccess('/beratung/formulare');
    },
  },
  methods: {
    async init() {
      await this.updateFlatMenus();

      if (this.isFormulareEnabled) {
        this.findAvailableForms();
      }
    },
    async updateFlatMenus() {
      try {
        this.configuringFlatMenu = true;
        const optionMenu = this.$store.getters[MENU_TYPES.GETTERS.SEARCHABLE_OPTION_MENU];

        // set flat menus
        const flatMenu = optionMenu.map(menu => ({
          path: menu.path,
          parentLabel: menu?.parent?.label || '',
          label: menu?.label || '',
          menu,
        }));
        this.flatMenu = flatMenu;
      } finally {
        this.configuringFlatMenu = false;
      }
    },
    async findAvailableForms() {
      this.configuringForms = true;

      const { flatMenu } = this;

      // find only forms groups available as a menu
      const lagerstelleList = [...this.antragListFormulare?.formGroups || []];
      const lagerstelleListVisible = lagerstelleList
        .filter(formGroup => {
          const formLink = `/beratung/formulare/antrag/${formGroup.path}`;
          return flatMenu.findIndex(menu => menu.path === formLink) >= 0;
        });

      try {
        // find formulare configs
        const fcConfigsPayload = lagerstelleListVisible.map(formGroup => ({
          configId: formGroup?.path,
          configType: FC_CONFIG.FORMULARE_MENU_PERMISSION_CONFIG,
        }));
        await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, fcConfigsPayload);

        // prepare forms for global search
        const availableForms = lagerstelleListVisible
          ?.flatMap(lagerstelle => {
            const isFormVisible = FormUtil.createIsFormVisibleCheck(lagerstelle.path);

            return FormUtil.getForms(lagerstelle)
              .filter(form => isFormVisible(form))
              .map(form => ({
                ...form,
                _lagerstelle: {
                  ...lagerstelle,
                  children: undefined,
                },
              }));
          });

        this.availableForms = availableForms;
      } finally {
        this.configuringForms = false;
      }
    },
    autocompleteInput(value) {
      this.getSuggest(value);
    },
    selected(value) {
      this.value = value;

      this.$emit('close');
    },
    gotoSearch(value) {
      this.value = value;
      if (isNaN(this.value)) {
        this.navigateTo('/customer/customer-search/' + this.value);
      }

      this.$emit('close');
    },
    async getSuggest(value) {
      let searchParams = value.split('/');

      // Doing this is a bit hacky but as the current search is build as is, it is the only way to get the correct behavior
      const newValue = value.replace("/", ">");

      this.lastValue = this.value;
      this.value = newValue;

      // Navigation
      const found = (this.isBrokerOrIntern && searchParams.length > 1)
        ? this.flatMenuCustomer.filter(v => (v.label.toLowerCase()).replace(SPECIAL_CHAR_PATTERN, '').includes(searchParams[1].toLowerCase()))
        : this.flatMenu.filter(v => (v.label.toLowerCase()).replace(SPECIAL_CHAR_PATTERN, '').includes(newValue.toLowerCase()));

      this.suggestRoutes = {
        ...this.suggestRoutes,
        [newValue]: found || [],
      }
      this.lastValue = newValue;

      if (this.isFormulareEnabled) { // Formulare / Antraege
        const tokenizedValue = newValue.toLowerCase().split(' ');
        const foundForms = this.availableForms
          ?.filter(form => {
            const labels = [
              form?.label,
              form?.friendlyName,
              form?._lagerstelle?.label,
            ];
            const joinedLabel = labels
              .map(label => label?.replace(SPECIAL_CHAR_PATTERN, '')?.toLowerCase?.() ?? '')
              .join(' ');

            return tokenizedValue.every(search => joinedLabel.includes(search));
          });

        this.suggestForms = {
          ...this.suggestForms,
          [newValue]: [...foundForms || []],
        }
      }

      if (this.lastRequestUrl.length > 0) {
        for (const url of this.lastRequestUrl) {
          await this.$store.dispatch(HTTP_REQ_TYPES.ACTIONS.KILL_SESSION_BY_URL, { url: url, });
          this.removeItemFromLastRequestUrlList(url);
        }
      }

      await this.fetchQuickSearchData(newValue);

    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    async fetchQuickSearchData(newValue) {
      try {
        let promiseArray = [];

        promiseArray.push(this.fetchData(newValue, "/QuickSearch/getPerson", "suggestCustomers"));
        promiseArray.push(this.fetchGesellschaften(newValue));
        promiseArray.push(this.fetchData(newValue, "/QuickSearch/getFonds", "suggestFonds"));
        promiseArray.push(this.fetchData(newValue, "/QuickSearch/getBroker", "suggestBrokers"));
        promiseArray.push(this.fetchData(newValue, "/QuickSearch/getInsurance", "suggestInsurances"));
        promiseArray.push(this.fetchData(newValue, "/QuickSearch/getDepot", "suggestDepots"));
        promiseArray.push(this.fetchData(newValue, "/QuickSearch/getCustomerData", "suggestCustomerData"));
        promiseArray.push(this.fetchData(newValue, "/QuickSearch/getCustomerDataSearchCommands", "customerSearchCommands"));
        promiseArray.push(this.fetchData(newValue, "/QuickSearch/getUnterdepot", "suggestUnterdepotData"));
        if (this.isIntern) {
          promiseArray.push(this.fetchData(newValue, "/QuickSearch/getException", "suggestExceptions"));
        }
        if (!this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_ONLY]]))
          promiseArray.push(this.fetchCalendarData(newValue));

        return await Promise.all(promiseArray);

      } catch (error) {
        await this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, error);
      }
    },
    startFetchProcess(newValue, API_Endpoint) {
      const valueEncoded = encodeURIComponent(newValue);
      const requestUrl = `${process.env.VUE_APP_API}${API_Endpoint}?searchParam=${valueEncoded}`;
      this.lastRequestUrl.push(requestUrl);
      return requestUrl;
    },
    async fetchData(newValue, request_URL, arrayToSaveInto) {
      const requestUrl = this.startFetchProcess(newValue, request_URL);

      return axios.get(requestUrl).then(response => {
        this[arrayToSaveInto] = {
          ...arrayToSaveInto,
          [newValue]: response.data || [],
        }

        this.signalFetchDone(newValue);

      }).catch(error => {
        this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, error);
      }).finally(() => {
        this.removeItemFromLastRequestUrlList(requestUrl);
      });
    },
    filterCalendarData(response) {
      let filteredResponse = [];
      response.data.forEach(item => {
        let addItem = true;
        filteredResponse.forEach(filteredItem => {
          if (filteredItem.id === item.id)
            addItem = false;
        });
        if (addItem)
          filteredResponse.push(item);
      });
      return filteredResponse;
    },
    async fetchCalendarData(newValue) {
      const requestUrl = this.startFetchProcess(newValue, "/QuickSearch/getCalendar");

      //Fetch the next 10 appointments
      return axios.get(requestUrl).then(async response => {
        let appointmentData = [];
        let promiseArray = [];

        const filteredResponse = this.filterCalendarData(response);

        //Fetch Appointment Data of each appointment
        filteredResponse.forEach(appointment => {
          const appointmentDataURL = `${process.env.VUE_APP_API}/calendarService/getAppointment/${appointment.id}`;
          this.lastRequestUrl.push(appointmentDataURL);

          // Track the fetches
          promiseArray.push(
            axios.get(appointmentDataURL).then(async response => {

              appointmentData.push(response.data);
              appointmentData = this.sortValueByDate(appointmentData);

              this.suggestCalendarData = {
                ...this.suggestCalendarData,
                [newValue]: appointmentData || [],
              }
              this.signalFetchDone(newValue);
            }).catch(error => {
              this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, error);
            }).finally(() => {
              this.removeItemFromLastRequestUrlList(appointmentDataURL);
            })
          );
        });

        // Wait for all fetches to finish
        await Promise.all(promiseArray);

        this.signalFetchDone(newValue);
      }).catch(error => {
        this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, error);
      }).finally(() => {
        this.removeItemFromLastRequestUrlList(requestUrl);
      });
    },
    async fetchGesellschaften(newValue) {
      const requestUrl = this.startFetchProcess(newValue, "/QuickSearch/getGesellschaften");

      return axios.get(requestUrl).then(response => {
        if (this.isCompaniesEnabled) {
          this.suggestCompanies = {
            ...this.suggestCompanies,
            [newValue]: response.data || [],
          }
        }
        this.signalFetchDone(newValue);
      }).catch(error => {
        this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, error);
      }).finally(() => {
        this.removeItemFromLastRequestUrlList(requestUrl);
      });
    },
    signalFetchDone(newValue) {
      this.lastValue = newValue;
      this.$emit('modalSizeChanged', 'md');
    },
    removeItemFromLastRequestUrlList(requestUrl) {
      this.lastRequestUrl = this.lastRequestUrl.filter(url => url !== requestUrl);
    },
    sortValueByDate(val) {
      val.sort((a, b) => {
        let dateA = this.parseDateString(a.appointment.begin);
        let dateB = this.parseDateString(b.appointment.begin);

        //Descending order
        //return dateB - dateA;

        //Ascending order
        return dateA - dateB;
      });
      return val;
    },
    parseDateString(dateString) {
      const parts = dateString.split(' ');
      const dateParts = parts[0].split('.');
      const timeParts = parts[1].split(':');

      return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1]);
    },
  },
  mounted() {
    this.init();
  },
  components: {
    Autocomplete,
    GhostLoading,
    Block,
    RecentMenuOpened,
  },
}
</script>

<style scoped>
.middle {
  display: flex;
}

.global-search__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.global-search-form {
  display: flex;
  flex-direction: row;
}

.workspaces-navbar__menu-item--large {
  margin-left: -29px;
  z-index: 1;
  margin-top: 4px;
}

.search-form-desktop {
  display: flex;
}

.search-form-mobile {
  display: none;
}

@media only screen and (max-width: 640px) {
  .search-form-desktop {
    display: none;
  }

  .search-form-mobile {
    display: flex;
  }
}

.global-search-form .input-forms__container {
  padding: 0;
}

.global-search-form-modal .autocomplete-results {
  position: relative;
}
</style>