<template>
  <div>
    <GhostLoading v-show="loading" useBoxContainer>
      <Block height="50" />
      <Block height="400"/>
    </GhostLoading>
    <div v-show="!loading">
    <div class="row settings-row">     
        <div class="col-4" v-if="hasManualAmount">
        <InputField 
          label="Startbetrag"
          v-model="initialInvestment"
          type="currency"
        />
      </div>
      <div class="col-2"> 
        <div>Jahre</div>
        <ComboBox
          :value="timeSpan"
          :values="AMOUNT_YEARS_OPTIONS"
          @change="onChangeTimeSpan"
        />
      </div>      
      <div class="col-2"> 
        <div>Simulationen</div>
        <InputField
        v-model="amountSimulations"
        />
      </div>

      <div class="button__container">
        <BaseButton
          @click="onClickSimulate()"
          style="max-height: 50px;"
          >Simulieren</BaseButton
        >
      </div>
      <div class="info__container">
      <a>
        <PhInfo :size="20" @click="openInfoModal()" class="info__icon"/>     
      </a>
    </div>
    </div>
    <div class="row" v-if="warnings">
      <BaseCollapsable>
      <template v-slot:title>Warnungen</template>
      <template v-slot:content>
        <ul>
          <li v-for="warning in warnings"> {{ warning }}</li>
        </ul>
      </template>
      </BaseCollapsable>
    </div>
    <hr />
    <div v-show="chartSeries && chartSeries.length > 0">
      <LineChart
        ref="lineChart"
        :chartSeries="chartSeries"
        :isArea="false"
        :customTooltipFormatter="customTooltipFormatter"
        :height="height"
        :format="format"
        :isLegend="false"
        :gridLeft="75"
        :chartOptions="chartOptions"
      />
      <ChartLegend v-if="lineChart"
        :showAsTable="false"
        :chartSeries="chartSeriesWithoutDifferenzLine"
        :lineChart="lineChart"
      />
    </div>
    <InfoModalMonteCarlo ref="montecarloModal"/>
  </div>
</div>
</template>


<script>
import { mapGetters } from 'vuex';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import dayjs from 'dayjs';
import { formatNumber } from '@/helpers/number-formatter.js';
import LineChart from '@/components/charts/echarts/LineChart.vue';
import ChartLegend from "./ChartLegend.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BaseButton from '../core/BaseButton.vue';
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import AreaChart from '@/components/charts/echarts/AreaChart.vue';
import {getColorOfActiveScheme} from '@/configs/color-config.js'
import BaseRoleForbidden from '@/views/core/BaseRoleForbidden.vue';
import { PhInfo } from 'phosphor-vue';
import InfoModalMonteCarlo from './InfoModalMonteCarlo.vue';
import GhostLoading from '../core/loading/GhostLoading.vue';
import Block from '../core/loading/ghost-loading/Block.vue';
import BaseCollapsable from '../core/BaseCollapsable.vue';

const SIMULATIONS_AMOUNT = 100;
const primaryColor = document.documentElement.style.getPropertyValue('--color-primary')
//TODO cleanup MONTECARLO_ERROR in store; remove if not needed anymore
export default {
  components: {
    LineChart,
    ComboBox,
    ChartLegend,
    InputField,
    BaseButton,
    AreaChart,
    BaseRoleForbidden,
    PhInfo,
    InfoModalMonteCarlo,
    GhostLoading,
    Block,
    BaseCollapsable
  },
  props: {
    adviceId: { //used only for anlageempfehlung
      type: Number,
    },
    depotId: { //used only for depots
      type: String
    },
    portfolioMakeup: {//see portfolioMakeup in MonteCarloSimulationDTO.java::portfolioMakeup
      type: Object,
    },
    perfManual: {
      type: Number,
    },
    volaManual: {
      type: Number,
    },
    initialInvestment: {
      type: Number,
      default: 10000
    },

  },
  data: function () {
    return {
      height: '450px',
      format: 'currency',
      timeSpan: 10,
      lineChart: undefined,
      chartOptions: {},
      amountSimulations: 100,
    }
  },
  watch: {
    depotId: function () {
      this.getChart();
    },
    depotInput: function () {
      this.getChart();
    }
  },
  computed: {
    ...mapGetters({
      chart: FONDSINFO_TYPES.GETTERS.FONDSINFO_MONTECARLO_CHART,
    }),
    /** The difference between günstige Preisentwicklung and ungünstige Preisentwicklung (called "Differenz") 
     * is sticked to ungünstige Preisentwicklung to display a funnel. 
     * So if you change a legend name change the name here too (for Differenz and ungünstige Preisentwicklung). 
     */
    chartData() {
      return this.chart?.chartItems;
    },
    warnings() {
      return this.chart?.warnings;
    },
    hasManualAmount() {
      return !this.adviceId && !this.depotId;
    },
    loading() {
      return !this.chartData?.length && !this.warnings?.length
    },
    chartSeries: function () {
      const today = new Date();
      return (this.chartData || []).map((series) => {
        let res = {
            color: series.color,
            data: series?.data?.map((value, index) => {
              const date = new Date(today);
              date.setMonth(today.getMonth() + index);
              return [date, value];
            }),
            highlight: false,
            hidden: false,
            name: series.label,
        };
        if(series.label == "Differenz"){
          Object.assign(res, {
            stack: 'x',                  
            stackStrategy: 'positive',
            lineStyle: {
              opacity: 0                 
            },
            symbol: 'none',              
            areaStyle: {
              color: primaryColor
            },
            tooltip: {
              show: false                
            },
          })
        } else if(series.label == "Ungünstige Preisentwicklung"){
          Object.assign(res, {
            type: 'line',
            stack: "x",
            areaStyle: {
              color: getColorOfActiveScheme('--color-box')
            }
          })
        } 
        return res;
      }) || [];
    },
    chartSeriesWithoutDifferenzLine() { //used for chart legend to hide display of auxiliary differenz line, which is only used to help display intervals lowerbound, since echarts doesnt have rangeArea line

      return this.chartSeries.filter((series) => series.name != 'Differenz')
    }
  },
  created() {
    this.chartOptions = {
      yAxis: {
        show: true,
        type: 'value',
        boundaryGap: false,
        axisLabel: {
          formatter: (value) => {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(value);
          },
          fontSize: 9,
        },
      },
    };
    this.AMOUNT_YEARS_OPTIONS = [5, 10, 20, 50];
    this.getChart();
  },
  mounted() {
    if(this.$refs.lineChart) {
      this.lineChart = this.$refs.lineChart
    }
  },
  methods: {
    onChangeTimeSpan(timeSpan) {
      this.timeSpan = timeSpan;
    },
    onClickSimulate() {
      this.getChart();
      //TODO: Indicate that generation is happening, maybe similar to kill session modal?
    },
    async getChart() {
      //reset chart
      this.$store.commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_MONTECARLO_CHART_SUCCESS, undefined);
      await this.$store.dispatch(
        FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_MONTECARLO_CHART,
        {
          portfolioMakeup: this.portfolioMakeup,
          timeSpan: this.timeSpan,
          amount: this.initialInvestment,
          quantitySimulation: this.amountSimulations,
          adviceId: this.adviceId,
          depotId: this.depotId,
          perfManual: this.perfManual,
          volaManual: this.volaManual,
        });

    },
    customTooltipFormatter(params) {
      if (Array.isArray(params)) {
        const name = dayjs(params[0].value[0]).format('MMM YYYY') || '';
        const html = params.map(p => {
          const { marker, seriesName, value } = p;
          let seriesNameTool = seriesName;
          if (this.toolTipTruncName && -1 !== seriesNameTool.indexOf(this.toolTipTruncName)) {
            seriesNameTool = seriesNameTool.substr(0, seriesNameTool.indexOf(this.toolTipTruncName));
          }
          let percentValue = value
          if (Array.isArray(value)) {
            percentValue = value[1]
          }
          if (!percentValue) {
            return ''
          }
          const formattedValue = formatNumber(percentValue || 0, 2) + (this.format === 'percent' ? ' %' : ' €');
          return `
            <div>
              <span>${marker}</span>
              <span style="white-space: normal;">${seriesNameTool}:</span> 
              <b>${formattedValue}</b>
            </div>`;
        });
        return `
          <div class="tooltip-series">
            <div><b>${name}</b></div>
            <div class="tooltip-series-table">${html.join('')}</div>
          </div>
        `;
      } else {
        const { marker, seriesName, name, value } = params;
        let seriesNameTool = seriesName;
        if (this.toolTipTruncName && -1 !== seriesNameTool.indexOf(this.toolTipTruncName)) {
          seriesNameTool = seriesNameTool.substr(0, seriesNameTool.indexOf(this.toolTipTruncName));
        }
        const formattedValue = formatNumber(value, 0) + (this.format === 'percent' ? ' %' : ' €');
        return `
          <div>
            <div><b>${seriesNameTool} test</b></div>
            <div><span>${marker}</span> <span>${name}:</span> <b>${formattedValue}</b></div>
          </div>`;
      }
    },
  openInfoModal() {
    this.$refs.montecarloModal.open();
  }
  },
}
</script>

<style scoped>
.years-container {
  display: flex;
  flex-direction: column;
  padding: 0 12px 16px 0;
}

.settings-row {
  align-items: flex-end;
  justify-content: flex-start;
}
.error__message {
  color: var(--color-danger);
}
.button__container {
  padding-right: 0;
}
.info__container {
  padding: 0;
}
.info__icon {
  cursor: pointer;
  margin: 4px 10px;
}
</style>