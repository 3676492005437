<template>
  <div :class="{ 'box__container-wrap': lastNews, }">
    <BaseFilter
      v-if="enableFilter"
      filterId="newsMSC"
      title="News Filter"
      :metadata="searchMetadata"
      :defaultOptions="defaultOptions"
      :configFilter="configFilter"
      @onFilter="handleSearch($event)"
    />
    <div class="box__container" :class="{ 'box__container-rows': lastNews, }">
        <GhostLoading v-if="loading" type="table" :title="tableTitle" :config="{ table: { rows: lastNews ? 5 : 3, }, }" />
        <NoData v-else-if="rows.length === 0" :noIcon="lastNews" :title="tableTitle" />
        <Table v-else
            :title="tableTitle"
            tableId="8769c462-fa11-4fdf-951d-1eb9c3ba4245"
            :headers="headers"
            :rows="rows"
            :selected="selectedNews"
            :rowsPerPage="lastNews ? 5 : 10"
            :hidePagination="lastNews"
            :mobileConfig="mobileConfig"
            @selected="$emit('selectedNews', $event)"
            @click-title="showNews"
            @action-EDIT="handleEdit($event.id)"
        />
    </div>

    <div v-if="!loading && lastNews && !$isSmallScreen" class="bottom-row text-right mt-3">
      <router-link :to="{name: 'news-msc'}">
        weitere...
      </router-link>
    </div>

    <BaseModal 
      ref="showNewsModal"
      labelButtonConfirm="Ok"
      :showCancelButton="false"
      size="xl"
      :modalTitle="modalTitle"> 
      <div
        v-html="sanitize(selectedNewsItem.body)">
      </div>

      <div class="my-4" v-if="selectedNewsItem.files && selectedNewsItem.files.length">
        <div class="box__title">Dateien</div>
        <div class="layout__negative-margin--8">
            <DownloadLink 
                v-for="(file) of selectedNewsItem.files"
                :key="file.id"
                :title="file.bezeichnung"
                :filename="file.bezeichnung"
                downloadServicePath="/get_simple_file"
                :queryParams="{
                  fileId: file.id
                }"/>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MSC_NEWS_TYPES from '@/store/mscnews/types'
import MENU_TYPES from '@/store/menu/types'
import CORE_TYPES from '@/store/core/types';
import Table from '@/components/table2/Table.vue'
import {TextColumn, DateColumn, NumberColumn, IconColumn, ActionColumn, SimpleAction, Icon} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue'
import dayjs from 'dayjs'
import { PhEnvelopeSimple, PhEnvelopeSimpleOpen, PhWarning } from 'phosphor-vue'
import BaseButton from '@/components/core/BaseButton.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import {sanitize} from '@/helpers/string-helper.js';
import DownloadLink from '../core/download/DownloadLink.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";
import NoData from '@/components/core/NoData.vue';

export default {
    model: {
        prop: 'selectedNews',
        event: 'selectedNews',
    },
    props: {
      lastNews: {
          type: Boolean,
          default: false
      },
      selectedNews: {
          type: Array,
          default: null,
      },
      enableFilter: {
          type: Boolean,
          default: false
      },
    },
    data() {
      return {
        selectedNewsItem: {},
        markedNews: [],
        loading: false,
        configFilter: {
          noResetOnDefaultSearchInputExit: true,
          filterType: 'newsMSC',
          hideFirstColumn: true,
          singleFilter: false,
        },
        defaultOptions: {
          INPUT_MONTH: {value: '4'}
        }
      }
    },
  watch: {
    mscNews: {
      handler() {
        if (this.selectedNews?.length)
            this.$emit('selectedNews', []);
      },
      deep: true
    }
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    async getMscNews(filter) {
      try {
        this.loading = true;
        let params = null;
        if(filter) {
          params = { lastNews: null, filter}
        } else {
          params = { lastNews: this.lastNews}
        }
        await this.$store.dispatch(MSC_NEWS_TYPES.ACTIONS.GET_MSC_NEWS, params);
      } finally {
        this.loading = false;
      }
    },
    handleEdit(id) {
      this.$router.push({ path: `/intern/news-msc-edit/${id}` })
    },
    async showNews(news) {
      this.selectedNewsItem = news
      await this.$store.dispatch(MSC_NEWS_TYPES.ACTIONS.MARK_AS_READ, news.id)
      if (!news.read) {
        this.$store.dispatch(MENU_TYPES.ACTIONS.LOAD_OPTIONS_MENU_BADGE, { 
          badgeName: 'NEWS_MSC_COUNT', 
        })
      }
      this.$refs.showNewsModal.open()
    },
    handleSearch(filter) {
      if(!filter) {return}

      let mappedFilter = null;

      filter.map(f => {
        const filterObj = f;
        let mappedFilterVal = {}
        if(filterObj.key === 'RADIOVALUE_FIXEDDATE') {
          mappedFilterVal = filterObj.value.reduce((acc, curr) => {
            return {...acc, [filterObj.key + '_' + curr.key]: curr.value}
          }, {});
        } else {
          mappedFilterVal = {[filterObj.key]: (filterObj.filterNot ? !filterObj.value : filterObj.value)}
        }
        mappedFilter = (mappedFilter == null) ? {
          ...mappedFilterVal
        } : {
          ...mappedFilter,
          ...mappedFilterVal
        };
      })
      this.getMscNews(mappedFilter);
    },
  },
  computed: {
    ...mapGetters({
      mscAllNews: MSC_NEWS_TYPES.GETTERS.MSC_NEWS,
      mscLastNews: MSC_NEWS_TYPES.GETTERS.MSC_LAST_NEWS,
      mscNewsCount: MSC_NEWS_TYPES.GETTERS.MSC_NEWS,
      isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
    }),
    tableTitle() {
      return this.lastNews ? '' : 'News Liste';
    },
    mscNews() {
        return (this.lastNews ? this.mscLastNews : this.mscAllNews) || [];
    },
    mscNewsCount() {
        return (this.mscNewsCount) || 0;
    },
    modalTitle() {
      return dayjs(this.selectedNewsItem.dateCreated).format('DD.MM.YYYY') + ' | ' + this.selectedNewsItem.title
    },
    headers() {
      return {
        lockedLeft: [
          DateColumn("dateCreated", "Datum").alignLeft(),
          TextColumn("title", "Titel").makeLink(),
          IconColumn("gelesen", "gelesen"),
          IconColumn("wichtig", "wichtig"),
        ],
        center: [
          NumberColumn("documentsAmount", "Anzahl Dokumente"),
        ],
        lockedRight: [
          ...(this.isOriginalUserIntern ? [ActionColumn("actions", "")] : [])
        ],
      }
    },
    rows() {
        return this.mscNews.map((news) => {
            return {
                ...news,
                gelesen: [news.gelesen
                    ? Icon(PhEnvelopeSimpleOpen, "Ja", 20)
                    : Icon(PhEnvelopeSimple, "Nein", 20, "fill"),
                ],
                read: news.gelesen,
                wichtig: [ ...news.wichtig
                    ? [Icon(PhWarning, "Wichtig", 20, undefined, 'color-danger')]
                    : [],
                ],
                isWichtig: news.wichtig,
                documentsAmount: news.files?.length || 0,
                actions: [
                  SimpleAction("EDIT", '', "Bearbeiten"),
                ],
                customRowStyle: {...(news.gelesen ? {} : { fontWeight: 'bold' }), },
            };
        });
    },
    mobileConfig() {
      return {
        title: null,
        headers: ['dateCreated', 'title', 'documentsAmount'],
      }
    },
    searchMetadata(){
      const filters =  [
        {
          type: 'group',
          key: 'allgemeine',
          label: 'Allgemeine',
          allowMultipleSearch: true,
          menuItems: [
            {
              type: 'text',
              label: 'Titel',
              key: 'PARAM_SUCH_TITEL',
              emptyDenied: true,
            },
            {
              type: 'default',
              label: 'gesamten Zeitraum',
              key: 'RADIOVALUE_ALLE',
            },
            {
              type: 'default',
              label: 'von diesem Jahr',
              key: 'RADIOVALUE_THISYEAR',
            },
            {
              type: 'integer',
              label: 'der letzten Monate',
              key: 'INPUT_MONTH',
              emptyDenied: true,
            },
            {
              type: 'dateRange',
              label: 'zwischen',
              key: 'RADIOVALUE_FIXEDDATE',
              emptyDenied: false,
            },
            {
              type: 'default',
              label: 'ungelesenen News',
              key: 'UNREAD_NEWS',
              deniable: true,
            },
            {
              type: 'default',
              label: 'wichtige News',
              key: 'MAJOR_NEWS',
              deniable: true,
            },
          ]
        },
      ];
      return filters;
    },
  },
  mounted() {
    const defaultFilter = {
      group: 'allgemeine',
      key: 'INPUT_MONTH',
      value: 4
    }
    this.handleSearch([defaultFilter])
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu News', 
      to,
      from,
    });
    next();
  },
  components: {
    Table,
    BaseModal,
    BaseButton,
    GhostLoading,
    DownloadLink,
    BaseFilter,
    NoData,
  }
}
</script>

<style scoped>
::v-deep p > img {
  max-width: unset;
}
</style>