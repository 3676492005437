var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasAlert
    ? _c(
        "div",
        {
          directives: [
            {
              name: "resize-observer",
              rawName: "v-resize-observer",
              value: _vm.setStyleProperties,
              expression: "setStyleProperties"
            }
          ],
          staticClass: "header-alert__container",
          class: _vm.headerAlertColorClass
        },
        [
          _vm.isKundenzugang
            ? [_vm._v(" Kundensicht ")]
            : _vm.isMitarbeiterzugang
            ? [
                _vm._v(" Mitarbeitersicht"),
                _vm.isReadOnly
                  ? _c("span", [_vm._v(" - Nur lesender Zugang")])
                  : _vm._e()
              ]
            : _vm.isMaklerzugang
            ? [
                _vm._v(" Maklersicht"),
                _vm.isInaktiv
                  ? _c("span", { staticClass: "ml-3" }, [
                      _vm._v("- Vermittler ist inaktiv")
                    ])
                  : _vm._e(),
                _vm.hatKeinenVertrag
                  ? _c("span", { staticClass: "ml-3" }, [
                      _vm._v("- Vermittler hat keinen Vertrag")
                    ])
                  : _vm._e()
              ]
            : _vm.isInaktiv
            ? [_vm._v(" Vermittler ist inaktiv ")]
            : _vm.hatKeinenVertrag
            ? [_vm._v(" Vermittler hat keinen Vertrag ")]
            : _vm.isReadOnly
            ? [_vm._v(" Nur lesender Zugang ")]
            : _vm.isInaktivCustomer
            ? [_vm._v(" Kunde ist inaktiv ")]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }