var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.rowsEmail.length
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              !_vm.loading && _vm.rowsEmail.length
                ? _c("Table", {
                    attrs: {
                      tableId: "9e599a44-66b5-4178-8fd7-6a98b658feb0",
                      title: "E-Mails",
                      headers: _vm.headers,
                      rows: _vm.rowsEmail,
                      rowId: "rowid",
                      rowsPerPage: 30,
                      selected: !_vm.isInsurance ? _vm.selectedRowsEmail : null,
                      exportConfig: { roottext: "Postfach" }
                    },
                    on: {
                      selected: _vm.selected,
                      "click-Subject": _vm.clickBetreff,
                      "action-WEITERLEITEN": _vm.handleActionWeiterleiten,
                      "action-WEITERLEITEN_USER":
                        _vm.handleActionWeiterleitenUser,
                      "action-WEITERLEITEN_COMPANY":
                        _vm.handleActionWeiterleitenCompany,
                      "action-DELETE": _vm.handleActionDelete,
                      "action-KUNDEN_OFFNEN": _vm.openCustomerNewTab,
                      "click-Name": _vm.openCustomerNewTab
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "Subject",
                          fn: function(row) {
                            return [
                              _vm._v(
                                " Von " +
                                  _vm._s(row.FROM) +
                                  " an " +
                                  _vm._s(row.TO) +
                                  " "
                              ),
                              row.category !== "Schriftverkehr"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "subject-column--content text-cell cell"
                                    },
                                    [
                                      row.category === "Fax"
                                        ? _c("DownloadLink", {
                                            attrs: {
                                              title: row.Subject,
                                              disabled: !row.commId,
                                              downloadServicePath:
                                                "/downloadFax",
                                              filename: row.Subject.includes(
                                                ".pdf"
                                              )
                                                ? row.Subject
                                                : row.Subject + ".pdf",
                                              queryParams: { id: row.commId }
                                            }
                                          })
                                        : _c(
                                            "a",
                                            {
                                              key: "Subject",
                                              attrs: {
                                                tid: _vm._generateTidFromString(
                                                  "9e599a44-66b5-4178-8fd7-6a98b658feb0:Subject:" +
                                                    row.id
                                                )
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.clickBetreff(row)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(row.Subject) + " "
                                              )
                                            ]
                                          ),
                                      row.status !== "success" &&
                                      row.status !== "Keine Angaben"
                                        ? [
                                            _c(
                                              "span",
                                              { staticClass: "mx-2" },
                                              [_vm._v(" | ")]
                                            ),
                                            _c("ph-warning", {
                                              class: "color-" + row.status,
                                              attrs: { size: 16 }
                                            })
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "subject-column--content text-cell cell"
                                    },
                                    [
                                      _c("DownloadLink", {
                                        attrs: {
                                          title: row.Subject,
                                          disabled: !row.commId,
                                          downloadServicePath:
                                            "/get_simple_file",
                                          filename: row.Subject.includes(".pdf")
                                            ? row.Subject
                                            : row.Subject + ".pdf",
                                          queryParams: {
                                            fileId: row.commId
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3332893021
                    )
                  })
                : _vm.loading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: "E-Mails" }
                  })
                : _c("NoData", { attrs: { title: "E-Mails" } })
            ],
            1
          )
        : _vm._e(),
      _vm.rowsAktivitaet.length
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              !_vm.loading && _vm.rowsAktivitaet.length
                ? _c("Table", {
                    attrs: {
                      tableId: "c390bddb-e0b9-49dc-a211-266555a47b94",
                      title: "Aufgaben",
                      headers: _vm.headers,
                      rows: _vm.rowsAktivitaet,
                      rowId: "rowid",
                      rowsPerPage: 30,
                      selected: !_vm.isInsurance
                        ? _vm.selectedRowsAktivitaet
                        : null,
                      exportConfig: { roottext: "Postfach" }
                    },
                    on: {
                      selected: _vm.selected,
                      "click-Subject": _vm.clickBetreff,
                      "action-WEITERLEITEN": _vm.handleActionWeiterleiten,
                      "action-WEITERLEITEN_USER":
                        _vm.handleActionWeiterleitenUser,
                      "action-WEITERLEITEN_COMPANY":
                        _vm.handleActionWeiterleitenCompany,
                      "action-DELETE": _vm.handleActionDelete,
                      "action-KUNDEN_OFFNEN": _vm.openCustomerNewTab,
                      "click-Name": _vm.openCustomerNewTab
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "Subject",
                          fn: function(row) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.getTitleForActivity(row)) + " "
                              ),
                              row.category !== "Schriftverkehr"
                                ? _c(
                                    "a",
                                    {
                                      key: "Subject",
                                      attrs: {
                                        tid: _vm._generateTidFromString(
                                          "9e599a44-66b5-4178-8fd7-6a98b658feb0:Subject:" +
                                            row.id
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.clickBetreff(row)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "subject-column--content text-cell cell"
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(row.Subject))
                                          ]),
                                          row.status !== "success" &&
                                          row.status !== "Keine Angaben"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "mx-2" },
                                                  [_vm._v(" | ")]
                                                ),
                                                _c("ph-warning", {
                                                  class: "color-" + row.status,
                                                  attrs: { size: 16 }
                                                })
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "subject-column--content text-cell cell"
                                    },
                                    [
                                      _c("DownloadLink", {
                                        attrs: {
                                          title: row.Subject,
                                          disabled: !row.commId,
                                          downloadServicePath:
                                            "/get_simple_file",
                                          filename: row.Subject.includes(".pdf")
                                            ? row.Subject
                                            : row.Subject + ".pdf",
                                          queryParams: {
                                            fileId: row.commId
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2093294162
                    )
                  })
                : _vm.loading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: "Aufgaben" }
                  })
                : _c("NoData", { attrs: { title: "Aufgaben" } })
            ],
            1
          )
        : _vm._e(),
      _c("BaseModal", {
        ref: "confirm",
        attrs: {
          modalTitle: _vm.confirmWarning,
          labelButtonConfirm: "Ok",
          labelButtonCancel: "Abbrechen"
        },
        on: { onConfirmButton: _vm.confirmDelete }
      }),
      _c(
        "BaseModal",
        {
          ref: "mailContacts",
          attrs: {
            modalTitle: "Kontakte",
            size: "lg",
            showCancelButton: false,
            labelButtonConfirm: "Auswahl übernehmen"
          },
          on: { onConfirmButton: _vm.confirmContactsToSend }
        },
        [
          _c("InsuranceCompanyMailContacts", {
            attrs: { records: _vm.mailUsers },
            on: { selectedRows: _vm.selectRowsContacts }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }