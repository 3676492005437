<template>
	<div class="box__container">
		<GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
		<UnexpectedError v-else-if="error"/>
		<NoData v-else-if="!rowCount" :title="TABLE_TITLE" />
		<div v-else>
			<Table 
				class="mt-2"
				tableId="d8abcb3f-4adb-4bc1-a013-531aa3a726ca"
				:title="TABLE_TITLE"
				:rowsPerPage="10"
				:headers="headers"
				:rows="rows"
				@action-SHOW_UNIFIABLE_PEOPLE="showUnifiablePeople"
			/>
		</div>
	</div>
</template>

<script>
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import UnexpectedError from '@/components/core/UnexpectedError.vue';
import Table from '@/components/table2/Table.vue';
import { TextColumn, NumberColumn, SimpleAction, ActionColumn } from '@/components/table2/table_util.js';
import MERGE_PEOPLE_TYPES from '@/store/merge-people/types.js';
import { mapGetters } from 'vuex';

const TABLE_TITLE = 'Personen werden als identisch erkannt, wenn die folgenden Daten übereinstimmen:';

export default {
	props: {
		loading: {
			default: false
		},
		error: {
			default: false,
		}
	},

	data() {
		return {
			TABLE_TITLE,
		}
	},

	computed: {
		...mapGetters({
			duplicatedPeople: MERGE_PEOPLE_TYPES.GETTERS.DUPLICATED_PEOPLE_LIST,
		}),
		rowCount() {
			return this.rows?.length;
		},
		headers() {
			return {
				center: [
					TextColumn('amountDuplicated', 'Treffer'),
					TextColumn('name', 'Name'),
					TextColumn('description', 'zusätzliche Daten'),
				],
				lockedRight: [
					ActionColumn("actions", "Aktionen"),
				],

			}
		},
		rows() {
			if (!this.duplicatedPeople) {
				return []
			}

			const actions = [
				SimpleAction("SHOW_UNIFIABLE_PEOPLE", '', 'Personen anzeigen'),
			]

			return this.duplicatedPeople?.map(row => ({
				...row,
				id: row.destinationPersonId,
				actions
			}))
		}
	},

	methods: {
		showUnifiablePeople(row) {
			this.$emit('showUnifiablePeople', row)
		}
	},

	components: {
		NoData,
		Table,
		GhostLoading,
		UnexpectedError,
	}
}
</script>

<style scoped>

</style>