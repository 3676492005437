<template>
  <button class="btn-clean clickable">
    <component :is="iconComponent" :size="size"/>
    <span v-if="expanded" class="pl-1">
      <SignoViewerLink
        :title="label"
        :href="href"
        :requestURL="requestURL"
        :extraParams="extraParams"
        :isDeepLink="isDeepLink"
      />
    </span>
</button>
</template>

<script>
import actionMixin from './action-mixin.js';
import SignoViewerLink from '@/components/core/download/SignoViewerLink.vue'

export default {
    mixins: [actionMixin],
    props: {
        href: {
          type: String,
        },
        requestURL: {
          type: Boolean,
          default: false,
        },
        extraParams: {
          type: String,
        },
        isDeepLink: {
          type: Boolean,
          default: false,
        },
    },
    components: {
      SignoViewerLink,
    }
}
</script>

<style scoped>
/deep/ .signo-link__container a {
  text-decoration: none;
}
</style>

<style src='./action.scss' lang='scss' scoped/>

