<template>
  <div v-if="showRecentMenuOpened" class="recent-menu-opened__container">
    <div class="recent-menu-opened--title">Zuletzt geöffnet</div>
    <ul class="recent-menu-opened--list">
      <li v-for="(menu, index) in lastRecentMenuOpened" :key="index" tabindex="0" 
        @click="navigateTo(menu.path)"
        @keydown.enter.prevent="navigateTo(menu.path)"
        @keydown.space.prevent="navigateTo(menu.path)"
      >
        <span class="menu-icon"><component :is="menu.component" v-bind="menu.componentProps" /></span>
        <span v-html="sanitize(menu.label)"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_TYPES from '@/store/menu/types';

import { sanitize, } from '@/helpers/string-helper.js';
import menuManager from '@/menu/menu-manager';

const MAX_ITEMS = 15;

export default {
  computed: {
    ...mapGetters({
      recentMenuOpened: MENU_TYPES.GETTERS.RECENT_MENU_OPENED,
    }),
    lastRecentMenuOpened() {
      const { recentMenuOpened } = this;
      return [ ...recentMenuOpened.slice(0, MAX_ITEMS), ];
    },
    showRecentMenuOpened() {
      return this.recentMenuOpened?.length > 0;
    },
  },
  methods: {
    sanitize(html) {
      return sanitize(html);
    },
    navigateTo(path) {
      menuManager.openRouteRestarting(path);
      this.$emit('clicked');
    },
  },
}
</script>

<style lang="scss" scoped>
.recent-menu-opened__container {
  margin: 12px 0 0;

  .recent-menu-opened--title {
    font-weight: bold;
    font-size: .7em;
    margin: 0 0 4px;
    text-transform: uppercase;
  }

  .recent-menu-opened--list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 4px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .menu-icon {
      display: flex;
      margin: 0 6px 0 0;
      width: 30px;
      height: auto;

      > * {
        flex: 1 0 auto;
      }

      > img,
      > div {
        border-radius: 100%;
        flex: 1 0 auto;
        max-width: 30px;
        max-height: 30px;
      }
    }
  }
}
</style>
