<template>
    <div>
        <div class="box__title"><CurrencyLabel label="Gesamtvermögen:" showInlineLabel :value="gesamtStartVermoegenCurrentScenario"/>
            </div>

        <Table v-if="rowsVermoegen && rowsVermoegen.length"
            tableId="vermoegenTableRuhestandsplanung"
            :headers="headersVermoegen"
            :rows="rowsVermoegen"
            rowId="name"
            hidePagination
            :mobileConfig="{}"
            @selected="onSelectedVermoegen"
            :selected="selectedVermoegen"
        >
            <template v-slot:rendite="row">
                <InputField
                    type="percent"
                    v-model="row.rendite"
                    :precision="2"
                    :disabled="isCalculating"
                    @change="updateVermoegenAusgaben({name: row.name, rendite: row.rendite}, 'vermoegen')"
                />
            </template>
            <template v-slot:value="row">
                <InputField
                    v-if="row.isAdjustable"
                    type="currency"
                    v-model="row.value"
                    :precision="2"
                    :disabled="isCalculating"
                    @change="updateVermoegenAusgaben({name: row.name, value: row.value}, 'vermoegen')"
                />
                <span v-else>
                    <CurrencyLabel :value="row.value"/>
                </span>
            </template>
        </Table>
        <Table v-if="rowsAusgaben && rowsAusgaben.length"
            tableId="ausgabenTableRuhestandsplanung"
            :headers="headersAusgaben"
            :rows="rowsAusgaben"
            rowId="name"
            hidePagination
            :mobileConfig="{}"
            @selected="onSelectedAusgaben"
            :selected="selectedAusgaben"
        >
            <template v-slot:value="row">
                <InputField
                    v-if="row.isAdjustable"
                    type="currency"
                    v-model="row.value"
                    :precision="2"
                    :disabled="isCalculating"
                    @change="updateVermoegenAusgaben({name: row.name, value: row.value}, 'ausgaben')"
                />
                <span v-else>
                    <CurrencyLabel :value="row.value"/>
                </span>
            </template>
        </Table>
    </div>
</template>

<script>
import mixin from '@/views/ruhestandsplanung/mixin.js';
import InputField from "@/components/core/forms/InputField.vue";
import Table from '@/components/table2/Table.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import {TextColumn, SlotColumn} from "@/components/table2/table_util.js";

export default {
    mixins: [mixin],
    components: {
        CurrencyLabel,
        Table,
        InputField,
    },
    data() {
        return {
            headersVermoegen: {
                lockedLeft: [
                    TextColumn("name", "Vermögen"), 
                ],
                center: [
                    SlotColumn("rendite", "Zinssatz Ansparphase"),
                ],
                lockedRight: [
                    SlotColumn("value", "Wert"),
                ]
            },
            headersAusgaben: {
                lockedLeft: [
                    TextColumn("name", "Verbindlichkeiten"), 
                ],
                center: [],
                lockedRight: [
                    SlotColumn("value", "Wert"),
                ]
            },
        }
    },
    computed: {
        rowsVermoegen() {
            return this.scenario.vermoegen?.length ? (this.vermoegen || []).map(verm => {
                return Object.assign(verm, this.scenario.vermoegen?.find(elem => elem.name === verm.name) || {});
            }) : (this.vermoegen || []);
        },
        rowsAusgaben() {
            return this.scenario.ausgaben?.length ? (this.ausgaben || []).map(aus => {
                return Object.assign(aus, this.scenario.ausgaben?.find(elem => elem.name === aus.name) || {});
            }) : (this.ausgaben || []);
        },
        selectedVermoegen() {
            if (!this.isCalculating) {
                return this.scenario?.vermoegen?.filter(vermoegen => vermoegen.selected) || [];
            }
        },
        selectedAusgaben() {
            if (!this.isCalculating) {
                return this.scenario?.ausgaben?.filter(ausgaben => ausgaben.selected) || [];
            }
        },
    },
}

</script>

<style>

</style>