import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import CORE_TYPES from '@/store/core/types'
import validator from '@/mixins/validator'
import LOG_TYPES from '@/store/log/types'
import { buildMessageWith } from '@/helpers/log-message-helper'

const HINT_GROUP = 'DEPOTEROEFFNUNGEN';

export function initialState(){
  return {
    personalDataAddress: {
      anredePronomen: '',
      title: '',
      firstName: '',
      lastName: '',
      street: '',
      zip: '',
      city: '',
      country: '',
      personalStatus: '',
      co: '',
      state: '',
      briefanredeDepot: '',
      briefanredePerson: '',
      personDeceased: false,
      dateOfDeath: null,
    },

    personalDataBirth: {
      nameAtBirth: '',
      nationality: '',
      countryOfBirth: '',
      placeOfBirth: '',
      dayOfBirth: null,
    },

    employment: {
      profession: '',
      industry: '',
      workingCondition: '',
      employees: [],
      lohnsumme: null,
      umsatz: null,
      anzahlMitarbeiterVollzeit: null,
      anzahlMitarbeiterTeilzeit: null,
      anzahlMitarbeiterMiniJob: null,
    },

    personalDataTax: {
      taxID: '',
      taxResidence: '',
      maritalPropertyRegime: '',
      socialInsuranceNumber: '',
      familyBenefitsOffice: '',
      childrenBenefitsNr: '',
      taxNr: '',
      taxOffice: '',
      taxClass: '',
    },

    legitimation: {
      legitimType: '',
      legitimNumber: '', 
      authority: '',
      dateOfIssue: '',
      validTo: '',
      bildVorderseite: null,
      bildRueckseite: null,
      bildMeldebescheinigung: null,
      unterlagenFirma: null,
      istBestaetigt: false,
      signatureInfo: {},
    },

    summary: {
      lohnsumme: '',
      umsatz: '',
      anzahlMitarbeiterVollzeit: '',
      anzahlMitarbeiterTeilzeit: '',
      anzahlMitarbeiterMiniJob: ''
    },

    fatca: {
      aktiv: false,
      text: '',
    },

    sonstiges: {
      maklervertragErstelleDatum: '',
      maklervertragGekuendigtDatum: '',
    },
  }
}

const stammdaten = {
  mixins: [validator],
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      additionalValidators: CUSTOMERDATA_TYPES.GETTERS.GET_ADDITIONAL_VALIDATORS,
      isMainPerson: CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON,
      personStates: CUSTOMERDATA_TYPES.GETTERS.PERSON_STATES,
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      addressToCopy: CUSTOMERDATA_TYPES.GETTERS.ADDRESS_TO_COPY,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    stateComboValues() {
      const comboValues = [{
        label: 'Keine Angaben',
        value: '',
      }];

      const states = this.personStates[this.personId] ?? [];
      return comboValues.concat(states.map((v) => ({
        label: v,
        value: v,
      })));
    },
    substep() {
      return this.$route?.query?.substep || this.substepProperty || 'stammdaten';
    },
    steuerlichInCountries() {
      return this.customerDataConfig?.countries?.filter(c => c!=='Keine Angaben') || [];
    }
    
  },
  watch: {
    additionalValidators: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.$configureValidators) {
          this.$configureValidators(newValue)
        }
      },
    },
    addressToCopy(address) {
      if(address) {
        this.personalDataAddress.street = address?.street;
        this.personalDataAddress.zip = address?.zip;
        this.personalDataAddress.city = address?.city;
        this.personalDataAddress.country = address?.country;
        this.personalDataAddress.state = address?.state;
        this.addCustomerDataEdited('personalDataAddress');
      }
    },
    '$route'(to, from) {
      this.checkRouteChange(to, from);
      this.saveIfIsNew();
    },
    'personalDataAddress.country'(country) {
      this.findPersonStates(country);
    },
    'personalDataAddress.state'() {
      this.resetSelectedStateIfNeeded();
    },
    personStates() {
      this.resetSelectedStateIfNeeded();
    },
  },
  methods: {
    initialState() {
      return initialState();
    },

    addDepoteroeffnungenHint() {
      if(this.isFK && this.isByPass) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Depoteröffnungen und Versicherungsanträge',
          message: ''
            + 'Felder deren <b>Bezeichnung fett gedruckt</b> ist, '
            + 'sind notwendig für Depoteröffnungen und Versicherungsanträge.'
        });
      }
    },

    // get the customerData and fill the $data in norder to be edited by the customer 
    updateGroupsIntoData() {
      this.updateGroupIntoData('personalDataAddress')
      this.updateGroupIntoData('personalDataBirth')
      this.updateGroupIntoData('employment')
      this.updateGroupIntoData('personalDataTax')
      this.updateGroupIntoData('legitimation')
      this.updateGroupIntoData('fatca')
    },

    findPersonStates(country) {
      const payload = {
        personId: this.personId,
      };
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.CLEAR_PERSON_STATES, payload);

      if(!country) {
        return ;
      }
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_PERSON_STATES, { 
        ...payload,
        country,
      });
    },

    resetSelectedStateIfNeeded() {
      const currentStates = this.personStates[this.personId];
      const selectedState = this.personalDataAddress?.state?.trim();
      if (currentStates && selectedState && !currentStates.find(state => state == selectedState)) {
        this.personalDataAddress.state = '';
        this.addCustomerDataEdited('personalDataAddress');
      }
    },

    findInitialStates() {
      if (this.personalDataAddress && !this.personalDataAddress.country) {
        if (this.isFK) {
          this.personalDataAddress.country = 'Deutschland'
        } else if (this.isFA) {
          this.personalDataAddress.country = 'Österreich'
        }
      }
      if(!this.isMainPerson) {
        this.findPersonStates(this.personalDataAddress?.country);
      }
    },

    checkConflicts(currentObj, newObj) {
      return Object.keys(currentObj).reduce((conflicts, key) => {
        if(newObj[key] && currentObj[key] != newObj[key]) {
          conflicts.push({
            key,
            currentValue: currentObj[key],
            newValue: newObj[key],
            invalid: this.checkIfDataIsInvalid(key, newObj[key]),
          });
        }

        return conflicts;
      }, []);
    },

    checkIfDataIsInvalid(key, value) {
      switch(key) {
        case 'validTo':
          return dayjs().isSameOrAfter(dayjs(value, 'DD.MM.YYYY', true));
        default:
          return undefined;
      }
    },

    openDataConflictModalIfExistsConflict(data) {
      const dataConflictBySection = {};
      if (this.customerData?.isEditable) {
        dataConflictBySection['personalDataAddress'] = this.checkConflicts(this.personalDataAddress, {
          ...data.personalausweis,
          title: (this.customerDataConfig?.genders?.indexOf(data.personalausweis?.title) >= 0 ? data.personalausweis?.title : null),
          zip: data.personalausweis?.zipCode,
          country: this.findCountry(this.customerDataConfig?.nationalityToCountry?.[data.personalausweis?.nationality?.trim()]) || '',
        });
        dataConflictBySection['personalDataBirth'] = this.checkConflicts(this.personalDataBirth, {
          ...data.personalausweis,
          dayOfBirth: this.dateStringFromResponse(data.personalausweis?.dateOfBirth),
          nameAtBirth: data.personalausweis?.lastName,
          nationality: this.findNationality((data.personalausweis?.nationality || "").toLowerCase()),
        });
      }

      dataConflictBySection['legitimation'] = this.checkConflicts(this.legitimation, {
        ...data.personalausweis,
        legitimNumber: data.personalausweis?.idNumber,
        dateOfIssue: this.dateStringFromResponse(data.personalausweis?.date),
        validTo: this.dateStringFromResponse(data.personalausweis?.validUntil),
      });

      const dataConflict = Object.keys(dataConflictBySection)
        .reduce((result, key) => {
          return result.concat(dataConflictBySection[key].map(item => ({ ...item, section: key, })));
        }, []);
      if(dataConflict?.length) {
        const nationalityConflict = dataConflict.find(conflictItem => conflictItem.key === 'nationality');

        if (nationalityConflict?.newValue === 'deutsch') {
          const content = `
          Bitte beachten Sie, dass die aktuelle nicht mit der ausgelesenen Staatsangehörigkeit übereinstimmt.<br>
          <b>Gespeicherte Staatsangehörigkeit:</b> ${nationalityConflict.currentValue}<br>
          <b>Erkannte Staatsangehörigkeit:</b> ${nationalityConflict.newValue}`

          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
            content,
            type: 'danger',
          }))
        }

        this.openDataConflictModal(dataConflict);
      }
    },

    findCountry(country) {
      return this.customerDataConfig?.countries?.find(v => v?.toLowerCase() === country?.toLowerCase());
    },

    findNationality(nationality) {
      return this.customerDataConfig?.nationalities?.find(v => v?.toLowerCase() === nationality?.toLowerCase());
    },

    dateStringFromResponse(date) {
      if (!date)
        return null;
      const parsedDate = `${(date.dayOfMonth+'').padStart(2, '0')}.${(date.monthValue+'').padStart(2, '0')}.${date.year}`;
      return dayjs(parsedDate, 'DD.MM.YYYY', true).isValid() ? parsedDate : null;
    },

    openDataConflictModal(dataConflict) {
      this.$refs.dataConflictModal?.open(dataConflict);
    },

    confirmDataConflict(data) {
      data.forEach(item => {
        this.$set(this[item.section], [item.key], item.newValue);
        this.addCustomerDataEdited(item.section);
      });

      setTimeout(() => this.saveChanges());
    },

    checkRouteChange(to, from) {
      const substep = from?.query?.substep || 'stammdaten';
      if(to?.query?.substep !== substep && substep === 'stammdaten' && !this.checkData()) {
        this.$router.push({
          path: from.path,
          query: {
            substep,
          },
        });
      }
    },

    checkData() {
      if(this.validation.isInvalid('personalDataAddress.lastName')) {
        return false;
      }
      return true;
    },

    async handleDateOfIssueChange(value) {
      if(!value || !this.legitimation || this.legitimation.legitimType === "Geburtsurkunde") return ;
      const validTo = await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_EXPIRATION_DATE, {
        dateOfIssue: value,
        legitimType: this.legitimation?.legitimType,
        dayOfBirth: this.personalDataBirth?.dayOfBirth,
      });
      this.legitimation.validTo = validTo;
      this.addCustomerDataEdited('legitimation');
    },

    onLegitimTypeChange(value) {
      const currentValue = this.customerData?.legitimation?.legitimType;
      this.$confirmModal({
        message: `
          <div>Sind Sie sicher, dass Sie das Feld "Art der Urkunde" ändern wollen?</div>
          <div class="text-centered text-bold my-2">${currentValue || 'Unbekannt'} &rarr; ${value}</div>
        `,
      }).then(() => {
        this.addCustomerDataEditedCombo('legitimation', 'legitimType', value);
        this.saveChanges();
      }).catch(() => {
        this.addCustomerDataEditedCombo('legitimation', 'legitimType', currentValue);
      });
    },

    async saveIfIsNew() {
      if(this.isNewPerson && this.checkData()) {
        await this.saveChanges();
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.REMOVE_HINTS, { group: HINT_GROUP, });
  },
}

export default stammdaten;