import { render, staticRenderFns } from "./AffiliateLogin.vue?vue&type=template&id=40fed258&scoped=true&"
import script from "./AffiliateLogin.vue?vue&type=script&lang=js&"
export * from "./AffiliateLogin.vue?vue&type=script&lang=js&"
import style0 from "../core/login.scss?vue&type=style&index=0&id=40fed258&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40fed258",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40fed258')) {
      api.createRecord('40fed258', component.options)
    } else {
      api.reload('40fed258', component.options)
    }
    module.hot.accept("./AffiliateLogin.vue?vue&type=template&id=40fed258&scoped=true&", function () {
      api.rerender('40fed258', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/affiliate/AffiliateLogin.vue"
export default component.exports