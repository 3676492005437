<template>
  <div class="box__container-wrap">
    <template v-if="contactPerson">
      <div class="box__container-rows">
        <div class="agent-contact__container" v-if="contactPerson">
          <div class="agent-contact--info">
            <div>{{contactPerson.name}}</div>
            <div><br/></div>
            <div>{{contactPerson.street}}</div>
            <div>{{contactPerson.zip}} {{contactPerson.city}}</div>
            <div><br/></div>
            <div v-if="contactPerson.phone">Telefon: {{contactPerson.phone}}</div>
            <div v-if="contactPerson.mobile && contactPerson.isMobilePublic">Mobil: {{contactPerson.mobile}}</div>
            <div v-if="contactPerson.email && getEmail(contactPerson.email)"><nobr>E-Mail</nobr>: {{getEmail(contactPerson.email)}}</div>
            <div v-if="contactPerson.webPage"><a :href="contactPerson.webPage">{{ contactPerson.webPage }}</a></div>
            <div><br/></div>
          </div>
          <div class="agent-contact--img my-3 ml-2">
            <UserPicture
              class="profile-menu-burger__customer--picture"
              size="large"
              useParamsImage
              :image="contactPictureUrl"
              ariaDescription="Profilbild des Maklers"/>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between agent-contact__actions">
        <BaseButton isLink @click="newTicket(); $emit('click')">Nachricht senden</BaseButton>
        <PhoneButton :showAsButton="false" @click="$emit('click')"/>
      </div>
    </template>
    <GhostLoading v-else-if="!contactPerson" type="table" />
    <NoData v-else noIcon />
  </div>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import UserPicture from '@/components/core/header/UserPicture.vue';
import { mapGetters } from 'vuex';
import BRIDGE_TYPES from '@/store/bridge/types';
import CORE_TYPES from '@/store/core/types';
import PhoneButton from '@/components/webrtc/PhoneButton.vue';

export default {
  components: {
    GhostLoading,
    NoData,
    BaseButton,
    UserPicture,
    PhoneButton,
  },
  computed: {
    ...mapGetters({
      contactPerson: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_CONTACT_PERSON_DATA,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
    }),
    contactPictureUrl() {
      return `${this.apiAddress}/customerContactPerson/contact_person_image?token=${this.token}`;
    },
  },
  mounted(){
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_CONTACT_PERSON_DATA);
  },
  methods: {
    newTicket() {
      this.$router.push({ name: 'new-ticket', params: { textInput: '', useConfiguredChatpartner: true }})
    },
    getEmail(value) {
      const arr = value.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
      return arr && arr[0] || ''
    },
  }

}
</script>

<style lang="scss" scoped>
.agent-contact__container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .agent-contact--info {
    flex: 1 1 160px;
  }

  .agent-contact--img {
    flex: 0 0 auto;
    width: 25%;
    min-width: 100px;
    max-width: 100%;
  }
}

.agent-contact__actions {
  flex-wrap: wrap;
}
</style>