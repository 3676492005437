<template>
  <div class="box__container">
    <Table
      title="Vorlieben"
      rowId="name"
      :rows="rows"
      :headers="headers"
      :headerActions="tableHeaderActions"
      @headerAction-NEUE_VORLIEBE="openCreateVorliebeModal"
      @action-DELETE="deleteVorliebe"
      :rowsPerPage="20"
    />

    <BaseModal 
      ref="createVorliebeModal"
      modalTitle="Neue Vorliebe"
      :confirmDisabled="!neueVorliebeValid"
      @onConfirmButton="addVorliebe(neueVorliebe)"
    > 
      <InputField 
        isComponentHalfSize
        :renderDanger="!neueVorliebeValid"
        v-model="neueVorliebe"
      />
      <div v-if="!neueVorliebeValid" class="fc-danger">Vorliebe existiert bereits</div>
    </BaseModal>
  </div>
</template>

<script>
import ANSPRECHPARTNER_TYPES from '@/store/ansprechpartner/types';
import { mapGetters } from "vuex";

import Table from "@/components/table2/Table.vue";
import { TextColumn, ActionColumn, SimpleAction, } from "@/components/table2/table_util.js";
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue'

export default {
  mixins: [],
  components: {
    Table,
    BaseModal,
    InputField,
  },
  data() {
    return {
      neueVorliebe: '',
    }
  },
  computed: {
    ...mapGetters({
      ansprechpartner: ANSPRECHPARTNER_TYPES.GETTERS.GET_ANSPRECHPARTNER,
    }),
    rows() {
      return (this.ansprechpartner.vorlieben || []).map((vorliebe, index) => ({
        name: vorliebe,
        index,
        actions: [
          SimpleAction("DELETE", '', "Löschen")
        ]
      }))
    },
    headers() {
      return {
        lockedLeft: [
          TextColumn("name", "Bezeichnung"),
        ],
        center: [],
        lockedRight: [
          ActionColumn('actions')
        ],
      }
    },
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('NEUE_VORLIEBE', 'Vorliebe hinzufügen')
      ];
    },
    neueVorliebeValid() {
      return this.ansprechpartner.vorlieben && !this.ansprechpartner.vorlieben.some(v => v.name === this.neueVorliebe)
    },
  },
  methods: {
    openCreateVorliebeModal() {
      this.neueVorliebe = ''
      this.$refs.createVorliebeModal.open()
    },
    addVorliebe(neueVorliebe) {
      this.$emit('change', 
      {
        fieldId: 'vorlieben',
        value: [...this.ansprechpartner.vorlieben, neueVorliebe],
      })
    },
    deleteVorliebe(row) {
      this.ansprechpartner.vorlieben.splice(row.index, 1)

      this.$emit('change', 
      {
        fieldId: 'vorlieben',
        value: this.ansprechpartner.vorlieben,
      })
    },
  },
}
</script>