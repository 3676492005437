<template>
  <div 
    class="page-header-title-nav__title-container" 
    :class="{ 
      'is-wrapped': isWrapped,
      'no-wrapped': !isWrapped,
    }"
  >
    <div class="title">
      <div class="d-flex align-items-center">
        <BackButtonNavigation class="back-icon hide-on-small-screen" v-on="$listeners" v-if="!isSecondary && showBackButton" />

        <slot name="title">
          <span v-html="sanitize(title)"></span>
        </slot>
      </div>
    </div>
    <template v-if="checkIfSlotHasContent($slots.subtitle) || hasSubtitleValue">
      <div v-if="!isWrapped" class="separator">&nbsp;</div>
      <div class="subtitle">
        <slot name="subtitle">
          <span v-html="sanitize(subtitle)"></span>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
import BackButtonNavigation from '@/components/core/header-title-navigation/BackButtonNavigation.vue';

import { sanitize } from '@/helpers/string-helper';

function checkIfVNodeHasContent(vNode) {
  return !!vNode?.tag && (vNode.children?.some?.(checkIfVNodeHasContent) || !!vNode.componentOptions) || !!vNode?.text?.trim?.();
}

export default {
  components: {
    BackButtonNavigation,
  },
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    subtitle: {
      type: String,
      default: ''
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    isWrapped: {
      type: Boolean,
      default: false,
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasSubtitleValue() {
      return !!this.subtitle?.trim?.();
    },
  },
  methods: {
    sanitize(html) {
      return sanitize(html);
    },
    checkIfSlotHasContent(vNodes) {
      return !!vNodes && vNodes.some(checkIfVNodeHasContent);
    },
  },
}
</script>

<style scoped>
.page-header-title-nav__title-container {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  word-break: break-word;
}

.page-header-title-nav__title-container.no-wrapped {
  flex-wrap: nowrap;
  white-space: nowrap;
}

.page-header-title-nav__title-container.is-wrapped {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: calc(100% - var(--ph--actionContainer-min-width));
}

.is-title-wrapped .page-header-title-nav__title-container.no-wrapped,
.page-header-title-nav__title-container.is-wrapped {
  opacity: 0;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.is-title-wrapped .page-header-title-nav__title-container.is-wrapped {
  opacity: 1;
  position: inherit;
  top: 0;
  left: 0;
}

.title {
  font-size: min(24px, 1.05em);
  line-height: 1.2;
  font-weight: bold;
}

.back-icon {
  margin: .125em .25em 0 -.25em;
  flex: 1 0 auto;
}

.back-icon svg {
  width: min(18px, 1.125em);
  height: min(18px, 1.125em);
}

.subtitle {
  font-size: min(22px, 1em);
  line-height: 1.2;
}

.separator {
  border-left: 1px solid var(--color-text);
  margin: 0 8px;
  width: 0px;
  height: min(24px, 1.125em);
}

.is-secondary .title,
.is-secondary .subtitle {
  font-size: 1em;
  line-height: 1.2;
}

@media screen and (max-width: 768px) {
  .page-header-title-nav__title-container.is-wrapped {
    flex: 0 1 auto;
    max-width: 100%;
  }
}
</style>
