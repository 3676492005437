const MESSAGES_TYPE = ['primary', 'warning', 'success', 'danger'];

export const MESSAGE_ACTIONS = {
  versicherungen: [
    {
      label: 'Versicherungen anzeigen',
      isSecondary: true,
      path: '/home/versicherungen/insurance-group'
    },
    {
      label: 'Versicherung hinzufügen',
      isPrimary: true,
      path: '/home/versicherungen/auswahl-vertragsart'
    }
  ],
  kredit: [
    {
      label: 'Kredite anzeigen',
      isSecondary: true,
      path: '/home/verbindlichkeiten/credit'
    },
    {
      label: 'Kredit hinzufügen',
      isPrimary: true,
      path: '/home/verbindlichkeiten/credit/edit/neu/vertragsdaten'
    }
  ],
  negativerklaerung: [
    {
      label: 'Zuverlässigkeits&shy;erklärung anzeigen',
      isPrimary: true,
      path: '/negativerklaerung'
    }
  ],
  facsimile: [
    {
      label: 'Facsimile anzeigen',
      isPrimary: true,
      path: '/intern/ansprechpartner-daten?stepKey=facsimile'
    }
  ],
  kundenzugangUndKundenApp: [
    {
      label: 'Kundenzugang und Kunden-App versenden anzeigen',
      isPrimary: true,
      path: '/kundenzugang-und-kundenapp'
    }
  ],
  kundendatenAusfuellen: [
    {
      label: 'auszufüllende Kundendaten',
      isPrimary: true,
      path: '/persoenlichedaten/customer-data/steps/stammdaten'
    }
  ],
  marketingCampaigns: [
    {
      label: 'Open Campaigns',
      isPrimary: true,
      path: '/home/campaign'
    }
  ],
  haftungserklaerung: [
    {
      label: 'Haftungs&shy;erklärung anzeigen',
      isPrimary: true,
      path: '/intern/haftungserklaerung'
    }
  ],
  missingCommunication:[
    {
    label: 'Daten ergänzen',
    isPrimary: true,
    path: '/persoenlichedaten/customer-data/steps/kommunikationsdaten'
    }
  ]
}

export function buildMessage(content, type, closeable = true, timeout, actions) {
  if (!MESSAGES_TYPE.some(m => m === type)) {
    type = 'primary';
  }
  return {
    id: Math.random().toString(36).substr(2, 10),
    content,
    closeable,
    type,
    timeout,
    actions: MESSAGE_ACTIONS[actions] || actions,
  }
}

export function buildMessageWith(options) {
  if (!options) {
    return
  }

  return buildMessage(
    options.content || options.message, 
    options.type, 
    options.closeable, 
    options.timeout, 
    options.actions)
}
