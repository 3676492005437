<template>
  <div>
    <MailContactsModal
      ref="mailContacts"
      :records="mailUsers"
      @select-contacts="confirmContactsToSend"
    />
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import LOG_TYPES from '@/store/log/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types'
import VERSICHERUNG_TYPES from '@/store/versicherungen/types';

import EVENT_BUS, { SEND_EMAIL, SEND_BRIEF, } from '@/event-bus';

import { mapGetters } from "vuex";
import MailContactsModal from '@/views/versicherungen/MailContactsModal.vue'

export default {  
  props: {
    unregisterEventsBeforeDestroy: {
      type: Boolean,
      default: true
    },
  },
  watch:{
    initMailData() {
      this.mailUsers = this.initMailData?.participants;
    },
  },
  components: {
    MailContactsModal,
  },
  data() {
    return {
      mailUsers: [],
      insuranceId: null,
      hasMaklerVollmachtDocuments: false,
      maklerVollmacht: null,
    };
  },
  computed: {
    ...mapGetters({
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isOriginalUserBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      initMailData: MAILCOMPOSER_TYPES.GETTERS.GET_INIT,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      configParameters: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ADD_PARAMETER,
    }),
    sendEmailToCompanyAutomaticallyConfigParameter() {
      const isPositiveRegex = /(1|j[a]?)/i
      let content = isPositiveRegex.test(this.configParameters['VERTRAG_SCHADENSMELDUNG_DIREKT']?.content);
      return content;
    },
  },
  mounted() {
    this.registerEventBus();
  },
  destroyed() {
    if(this.unregisterEventsBeforeDestroy) {
      this.unregisterEventBus();
    }
  },
  methods: {
    registerEventBus() {
      
      // Send Email
      // off is to prevent registering the event multiple times if there are multiple instances of this component
      EVENT_BUS.$off(SEND_EMAIL);
      EVENT_BUS.$on(SEND_EMAIL, (payload) => {
        this.sendEmail(payload)
      });

      // Send Brief
      EVENT_BUS.$off(SEND_BRIEF);
      EVENT_BUS.$on(SEND_BRIEF, (payload) => {
        this.sendBrief(payload)
      });
    },
    unregisterEventBus() {
      EVENT_BUS.$off(SEND_EMAIL);
      EVENT_BUS.$off(SEND_BRIEF);
    },
    initType(insuranceId, schadenId) {
        if (schadenId) {
            return 'mailcomposer-schadensmeldung'
        }

        if (insuranceId) {
            return 'mailcomposer-insurance'
        }

        return 'mailcomposer-single'
    },
    resetMail(type) {
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, null);
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE, null);
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
          type: type,
          action: 'clear',
        });
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
          type: type,
          action: 'clear',
        });
    },
    sendBrief({insuranceId, schadenId, attachments, toCompany}) {

      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: 'mailcomposer-brief-insurance',
        action: 'clear',
      });

      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: 'mailcomposer-brief-insurance',
        action: 'clear',
      });

      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, null);
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE, null);

      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
        data: [insuranceId],
      });

      this.$router.push({ path: '/communication/mailcomposer-brief-insurance' });
    },
    sendEmail({insuranceId, schadenId, attachments, isSelectedAllDocumentsInFolder, toCompany, hasMaklerVollmachtDocuments, maklerVollmacht, isCommingFromDocumentHeaderComponent, unselectedDocuments}) {
        // find out type
        let type = this.initType(insuranceId, schadenId)
        this.resetMail(type)
  
        if (schadenId) {
          // in schadensmeldung, emails attachments are built in back-end. Set the attachments in the store will duplicate the files on the email composer.
          this.sendEmailSchaden(schadenId, toCompany, attachments, isCommingFromDocumentHeaderComponent)
        } else if (insuranceId) {
          if (attachments?.length) {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, attachments.map(v => ({...v, preloaded: true})));
          }
          this.hasMaklerVollmachtDocuments = hasMaklerVollmachtDocuments
          this.maklerVollmacht = maklerVollmacht
          this.sendEmailInsurance(insuranceId, toCompany, isSelectedAllDocumentsInFolder, unselectedDocuments)
        } else if (this.$route.query && this.$route.query.back) {
          this.$router.push({ path: `${this.$route.query.back}${this.getBackOptions()}` });
        } else {
          if (attachments?.length) {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, attachments.map(v => ({...v, preloaded: true})));
          }
          this.$router.push({path: '/communication/mailcomposer-single'});
        }
    },
    sendEmailInsurance(insuranceId, toCompany, isSelectedAllDocumentsInFolder, unselectedDocuments = []) {
      this.insuranceId = insuranceId
      unselectedDocuments = unselectedDocuments.map(doc => doc?.dokId || doc);
      if (toCompany) {
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_INIT, {
          insurance: insuranceId,
          toCompany,
          isSelectedAllDocumentsInFolder,
          unselectedDocuments,
        }).then(response => {
          try {
            if (response?.data?.participants && response?.data?.participants?.length) {
              this.$nextTick(() => {
                this.$refs.mailContacts.open();
              });
            } else {
              this.direktInDieMailSpringen(insuranceId, toCompany);
            }

            if(response?.data?.attachments && response?.data?.attachments?.length) {
              this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, response?.data?.attachments);
            }
          } catch (error) {
            this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, error);
          }
        })
      } else {
        this.direktInDieMailSpringen(insuranceId, toCompany)
      }
    },
    async direktInDieMailSpringen(insuranceId, toCompany, participants) {
      await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_MAKLER_VOLLMACHT,
        {
          versVertrag: insuranceId,
        }
      );

      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
        data: [insuranceId],
        hasMaklerVollmacht: this.hasMaklerVollmachtDocuments,
        maklerVollmacht: this.maklerVollmacht,
        toCompany,
        participants: participants,
      });
      this.$router.push(`/communication/mailcomposer-insurance`);
    },
    async sendEmailSchaden(schadenId, toCompany, attachments, isCommingFromDocumentHeaderComponent) {
      // explicitly checked
      const withoutAttachments = attachments == null || attachments == undefined || attachments?.length === 0;

      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_SCHADENSMELDUNG, {
        withoutAttachments: withoutAttachments, // see MSC-19455
        schadenId,
        isMailingToCompany: toCompany,
        istFolgeEmail: !isCommingFromDocumentHeaderComponent,
        selectedDocumentsToAttach: isCommingFromDocumentHeaderComponent ? attachments.map(att => att.dokId) : [], // in case of action 'Markierte versenden' only selected documents must be attached
        }).then(response => {
          const customResponse = {
            ...response.data,
            toCompany,
          }

          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_SCHADENSMELDUNG, customResponse);
          //in case sending email from schadensmeldung page top button

          if(customResponse.attachments.length) {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
              type: 'mailcomposer-schadensmeldung',
              action: 'save-cache',
              sendEmailToCompanyAutomaticallyConfigParameter: this.sendEmailToCompanyAutomaticallyConfigParameter,
            });
          } else {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
              type: 'mailcomposer-schadensmeldung',
              action: 'save-cache',
              isDisableEncrypted: toCompany,
            });
          }

          if (customResponse.attachments.length) {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, customResponse.attachments.map(v => ({...v, preloaded: true})));
          }

          this.$router.push({path: `/communication/mailcomposer-schadensmeldung/${schadenId}`, query: {replaceContent: true, toCompany}});
        });
    },
    confirmContactsToSend(participants) {
      this.direktInDieMailSpringen(this.insuranceId, true, participants);
    },
    getBackOptions() {
      if (this.$route.query.back.includes('pruefstatus') || this.$route.query.back.includes('mailcomposer-insurance')) return '?backAction=true'
      if (this.$route.params.type && this.$route.params.type.includes('calendar')) return '?usersselected=true'
      return ''
    },
  },
}
</script>
