<template>
  <div>
    <div>
      <OptionMenu v-if="!isPendingAction" :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

      <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" :title="title" />

      <StepperForm
        ref="stepperForm"
        :stepType="'fixed'"
        :stepperMediator="tabsStepperMediator"
        :showSaveSpinner="showSaveSpinner"
        :selectedStepKey="currentStep"
        :selectedSubstepKey="currentSubstep"
        :hasFertig="false"
        @set-step="setStepByKey($event)"
        @set-substep="setSubstepByKey($event)"
        @next-step="saveData($event)"
      >
        <template #contentTemplate v-if="currentStep">
          <div class="row my-0">
            <div class="col-12">
              <div v-for="(component, index) of componentsForCurrentStep " :key="index">
                <div v-if="!isComponentHidden(component)" :key="index + component.id">
                  <AntragComponent
                    :component="component"
                    :comboboxSelection="(comboboxSelections && comboboxSelections[component.id]) || comboboxSelections || []"
                    :data="data || {}"
                    :disabled="disabledForCurrentStep"
                    isComponentHalfSizeEnabled
                    @change="updateData(component.id, $event)">
                  </AntragComponent>
                </div>
              </div> 
            </div>
          </div>
        </template>
        <template #buttonsTemplate v-else>
          <NoData noIcon />
        </template>
      </StepperForm>
    </div>
    <BaseModal
        ref="errorModal"
        modalTitle="Fehler"
        labelButtonConfirm="Ok"
        :showCancelButton="false">
        <label>{{errorMessage}}</label>
    </BaseModal> 
  </div>
</template>

<script>
import CORE_TYPES from "../../store/core/types";
import AFFILIATE_TYPES from "@/store/affiliate/types";
import { mapGetters } from "vuex";
import validator from "@/mixins/validator";
import axios from "axios";
import {
  componentValidatorToRule,
  isHiddenDisabled,
} from "@/components/antrag/antrag-utils";
import { makeQueryParam } from "@/helpers/utils-helper";
import { StepperMediator } from "@/components/stepper/utils";

import StepperForm from "@/components/stepper/StepperForm.vue";
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from "@/components/core/BaseButton.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import AntragComponent from "@/components/antrag/AntragComponent.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import NoData from '@/components/core/NoData.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import AntragBottomButtons from '@/components/antrag/AntragBottomButtons.vue'; 



const DEFAULT_TITLE = 'Immobilienfinanzierung'

export default {
  mixins: [validator],
  components: {
    BaseButton,
    OptionMenu,
    PageHeaderTitleNavigation,
    DownloadLink,
    InputToggleSwitch,
    AntragComponent,
    StepperForm,
    BaseModal,
    NoData,
    ContentWithStepper,
    AntragBottomButtons,

  },
  data() {
    return {
      stepperCollapsed: false,
      errorMessage: null,
      components: {},
      data: {},
      comboboxSelections: {},
      additionalData: {},
      buttonMap: {},
      currentStep: null,
      currentSubstep: null,
      steps: [],
      showSaveSpinner: false,
      refreshKey: false,
      stepCountToBeConfirmed: null,
      savedStep: null,
      currentStepDataChanged: false
    };
  },
  mounted() {
    console.log(this.$route.query.maklernr)
    this.$store.commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, { negativerklaerung: false });
    this.init();
  },
  watch: {
    dynamicValidators: {
      immediate: true,
      handler(newDynamicValidators) {
        for (const dynamicValidator of newDynamicValidators) {
          if (dynamicValidator && this.$configureValidators) {
            this.$configureValidators({
              [dynamicValidator.componentId]: dynamicValidator.validators,
            });
          }
        }
      },
    },
    steps(newSteps) {
      if (newSteps && newSteps.length) {
        this.setStepByKey(newSteps[0].stepKey)
      }
    },
  },
  computed: {
    ...mapGetters({
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
      antrag: AFFILIATE_TYPES.GETTERS.ANTRAG,
    }),
    tabsStepperMediator() {
      return new StepperMediator(this.steps);
    },
    dynamicValidators() {
      const dynamicValidators = this.componentsForCurrentStep ? this.componentsForCurrentStep
        .filter((component) => component.id && component.validators?.length)
        .map((component) => ({
          componentId: component.id,
          validators: component.validators.map(componentValidatorToRule),
        })) : []
      return dynamicValidators;
    },
    title() {
      return (this.additionalDataForCurrentStep && this.additionalDataForCurrentStep.title) || this.$appNavigation?.currentMenu?.label || DEFAULT_TITLE;
    },
    componentsForCurrentStep() {
      const routeStep = this.currentStep;
      if (routeStep && this.components && this.components[routeStep] && this.components[routeStep].length) {
        return this.components[routeStep]
      }
      const routeSubstep =  this.currentSubstep;
      if (routeSubstep && this.components && this.components[routeSubstep] && this.components[routeSubstep].length){
        return this.components[routeSubstep]
      }
      return []
    },
    buttonMapForCurrentStep() {
      const routeStep = this.currentStep;
      if (routeStep && this.buttonMap && this.buttonMap[routeStep]) {
        return this.buttonMap[routeStep]
      }
      const routeSubstep = this.currentSubstep;
      if (routeSubstep && this.buttonMap && this.buttonMap[routeSubstep]) {
        return this.buttonMap[routeSubstep]
      }
      return []
    },
    disabledForCurrentStep() {
      const routeStep = this.currentStep;
      if (routeStep && this.additionalData && this.additionalData[routeStep]) {
        return this.additionalData[routeStep] && this.additionalData[routeStep].IS_DISABLED
      }
      const routeSubstep = this.currentSubstep;
      if (routeSubstep && this.additionalData && this.additionalData[routeSubstep]) {
        return this.additionalData[routeSubstep] && this.additionalData[routeSubstep].IS_DISABLED
      }
      return false
    },
    // dataForCurrentStep() {
    //   const routeStep = this.currentStep;
    //   if (routeStep && this.data && this.data[routeStep]) {
    //     console.log(this.data[routeStep])
    //     return this.data[routeStep]
    //   }
    //   return []
    // },
    additionalDataForCurrentStep() {
      const routeStep = this.currentStep;
      if (routeStep && this.additionalData && this.additionalData[routeStep]) {
        return this.additionalData[routeStep]
      }
      const routeSubstep = this.currentSubstep;
      if (routeSubstep && this.additionalData && this.additionalData[routeSubstep]) {
        return this.additionalData[routeSubstep]
      }
      return []
    },
    dataForSavedStep() {
      const routeStep = this.savedStep;
      if (routeStep && this.data && this.data[routeStep]) {
        return this.data[routeStep]
      }
      return []
    },
    listOfComponents() {
        if (Array.isArray(this.componentsForCurrentStep) && this.componentsForCurrentStep.length) {
          const componentsGroup = []
          let temp = []
          componentsGroup.push(temp)
          
          for (const component of this.componentsForCurrentStep) {
              if (component.type === 'DIVIDER') {
                  temp = []
                  componentsGroup.push(temp)
              } else {
                  temp.push(component)
              }
          }
          return componentsGroup
        }
        return []
    },
    isPendingAction() {
      return this.$route.meta.isPendingAction !== false
    },
  },
  methods: {
    init() {
      this.$store.dispatch(AFFILIATE_TYPES.ACTIONS.GET_ANTRAG, {MAKLER_ID: "99999", AFFILIATE_CODE: "12345676XyZ", EMAIL: "test@test.de"}).then(response => {
        this.steps = this.antrag.steps
        this.components = this.antrag.components
        this.comboboxSelections = this.antrag.comboboxSelections
        this.currentStep = this.steps[0] && this.steps[0].stepKey || ''
        this.currentSubstep = ''
        if(this.currentStep && this.steps[0].substeps){
          this.currentSubstep = this.steps[0].substeps[0].substepKey || ''
        }
        this.data = this.antrag.data
        this.additionalData = this.antrag.additionalData
      })
    },
    logout() {
      //this.$store.dispatch(CORE_TYPES.ACTIONS.LOGOUT);
    },
    updateData(componentId, value) {
      this.currentStepDataChanged = true;
      if(value){
        this.data[componentId] = value;
      }      
      // this.dataForCurrentStep[componentId] = value;
      // this.data[this.currentStep] = this.dataForCurrentStep;
      // this.savedStep = this.currentStep;
      // console.log(this.savedStep)
    },
    isComponentHidden(component) {
      const result =
        component &&
        component.hidden &&
        isHiddenDisabled(component.hidden, this.data);
      if (!result) {
        return result;
      }
      return result;
    },
    setStepByKey(stepKey) {
        if (stepKey && this.currentStep !== stepKey) {
          this.persistUpdatedData()
          this.currentStep = stepKey
          this.currentSubstep = ''
        }
    },
    setSubstepByKey(step) {
        if (step && step.substepKey && this.currentSubstep != step.substepKey) {
          this.persistUpdatedData()
          this.currentStep = step.stepKey
          this.currentSubstep = step.substepKey
        }
    },
    saveData() {
      this.showSaveSpinner = true;
      const payload = {...this.data, MAKLER_ID: "99999", AFFILIATE_CODE: "12345676XyZ", EMAIL: "test@test.de"}
      this.$store.dispatch(AFFILIATE_TYPES.ACTIONS.SAVE_ANTRAG, payload).then(response => {
        if (response && response.data) {
          if (response.data.errorMessage) {
            this.errorMessage = response.data.errorMessage
            this.$refs.errorModal.open();
          } else {
            // if (this.stepCountToBeConfirmed && --this.stepCountToBeConfirmed === 0) {
            //   this.$router.push({ path: '/home' }).catch(() => {});
            // } else {
              let personId = this.additionalDataForCurrentStep && this.additionalDataForCurrentStep.PARAM_PERSONID
              if (personId) {
                this.tabsStepperMediator.markStepAsSuccess(personId);
                this.updateStepper()
              }
                this.additionalData[this.currentStep].IS_DISABLED = true;
                this.refreshKey = !this.refreshKey
             }

          }
        // this.steps = this.antrag.steps
        // this.components = this.antrag.components
        // console.log(this.steps)
        // this.currentStep = this.steps[0] && this.steps[0].stepKey || ''
      }).finally(() => this.showSaveSpinner = false);
      
    },
    persistUpdatedData(){
      if(this.currentStepDataChanged){
            this.saveData()
            this.currentStepDataChanged = false
      }
    },
    updateStepper() {
      this.$refs.stepperForm?.$refs?.stepper?.updateStepList();
    },
  },
  validators: {},
};
</script>

<style scoped>
.toggle_switch {
  padding-top: 3px;
}

.small {
  font-size: smaller;
}
</style>
