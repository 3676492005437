
<template>
  <div v-if="searchingPositions">
    <div class="row">
      <div class="col-12">
        <h2>Wertpapiersuche</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <FondsFinderSuchMaske ignoreLagerstelle/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <FondsFinderPositionsList @goBack="addFonds($event)" zurueckButtonText="Abbrechen" :addPositionsOnBack="false" ignoreLagerstelle/>
      </div>
    </div>
  </div>
  <div v-else class="antrag-components__container">
    <div class="box__container">

        <Table v-if="!positionData.all"
            tableId="f168f001-d40a-4a9a-ba2b-aa8d3ce74e30"
            :headers="headers"
            :rows="rows"
            rowId="index"
            hidePagination
            @action-DELETE="deleteFond($event.index - 1)"
        >
            <template #Anteile="row">
                <InputField v-model="positionData['anteile' + row.index]"/>
            </template>
        </Table>
      <BaseButton v-if="!positionData.all" @click="searchingPositions=true">Positionen hinzufügen</BaseButton>

      <FormDivider/>

    
      <InputToggleSwitch v-model="positionData.depotAufloesen" label="Darüber hinaus erteile ich/erteilen wir den Auftrag, alle bestehenden Sparpläne einzustellen und das Depot bei der o.g. Investmentgesellschaft/Bank zu schließen.\n(Eine Depotschließung kann bei einem Gemeinschaftsdepot nur durch alle Depotinhaber gemeinsam veranlasst werden.)"/>
      <InputToggleSwitch v-model="positionData.freiwiderruf" label="Hiermit widerrufe ich meinen/widerrufen wir unseren Freistellungsauftrag."/>
      <InputToggleSwitch v-model="positionData.freiaendern" label="Ich möchte meinen/Wir möchten unseren Freistellungsauftrag ändern. Bitte überlassen Sie/uns einen entsprechenden Vordruck."/>
      <InputToggleSwitch v-model="positionData.kontoAufloesen" label="Ich erteile/Wir erteilen der abgebenden Bank/Fondsgesellschaft den Auftrag, ein eventuell zugehöriges Konto zu löschen und ein bestehendes Guthaben auf u. g. Bankverbindung zu überweisen."/>

      <EntitySelector v-model="positionData.bankverbindungId" :values="bankverbindungSelection" label="Verwende Konto"  @new="editBankverbindung" 
      @edit="editBankverbindung" @change="$emit('save', { bankverbindungId: $event })"/>

      <div class="row">
        <div class="col">
          <BaseButton @click="$emit('back')" isSecondary>Zurück</BaseButton>
          <BaseButton @click="onBackButton">Zurück zum Formular</BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex';
import { calculateAntragID } from '@/components/antrag/antrag-utils';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import ANTRAG_TYPES from '@/store/antrag/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import FormDivider from '@/components/core/forms/FormDivider.vue';
import {PhTrash} from 'phosphor-vue';


export default {
  mixins: [antragNavigationMixin],
  components: {
    InputField,
    InputToggleSwitch,
    BaseButton,
    EntitySelector,
    FondsFinderSuchMaske,
    FondsFinderPositionsList,
    Table,
    FormDivider,
    PhTrash,
  },
  props: {
    warnings: {
    },
    bankverbindungSelection:{
    }
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
    antrag () {
      return this.antraege[this.antragId]
    },
    component() {
      return this.antrag.components[this.step].find(component => component.id == this.categoryId)
    },
    componentData() {
      return this.antraegeData[this.antragId][this.component.id]
    },
    rows() {
        if (!this.antrag)
            return [];
        const actions = [
            SimpleAction("DELETE", '', "Löschen"),
        ];
        const rows = [];
        for (let i = 1; i <= this.component.config.anzahlFonds; i++) {
            if (this.positionData["isin" + i]) {
                rows.push({
                    Wertpapiername: this.positionData["fondsname" + i],
                    ISIN: this.positionData["isin" + i],
                    Anteile: this.positionData["anteile" + i],
                    index: i,
                    actions,
                })
            }
        }
        return rows;
    },
  },
  data() {
    return {
      antragId: null,
      fondIndex: -1,
      categoryId: null,
      positionData: {
      },
      step: null,
      wertpapierConfig: {
        type: "FFBUEBERTRAGUNG",
        buttonText: "Wertpapier hinzufügen",
      },
      form: {},
      searchingPositions: false,
        headers: {
            lockedLeft: [
                TextColumn("Wertpapiername", "Wertpapiername"),
            ],
            center: [
                TextColumn("ISIN", "ISIN"),
                SlotColumn("Anteile", "Stückzahl"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        },
    }
  },
  mounted() {
    const antragsName = this.$route.params.antragsName
    this.antragId = calculateAntragID({antragsName: antragsName, lagerstelle: this.$route.params.lagerstelle})
    this.fondIndex = this.$route.params.fondIndex
    this.categoryId = this.$route.params.categoryId
    this.step = this.$route.params.step
    this.form = Object.assign({}, this.positionData);
    this.positionData = {
      ...this.componentData[this.fondIndex]
    }
    console.log(this.bankverbindungSelection)
  },
  methods: {
    onBackButton() {
      this.navigateBackToCurrentStep()
    },
    savePosition() {
      const newData = this.componentData.slice()
      newData[this.fondIndex] = this.positionData
      const payload = {
        id: this.antragId,
        data: {
          [this.component.id]: newData
        }
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
    },
    addFonds(fonds) {
      this.searchingPositions = false
      if (!fonds || fonds.length == 0)
        return
      // combine previously selected fonds with the new fonds
      const positionList = this.getPositions()
      fonds.forEach(fond => {
        positionList.push({
          isin: fond.isin,
          name: fond.wertpapiername,
          anteile: "alle",
        })
      })
      this.setPositions(positionList)
    },
    deleteFond(index) {
      const positionList = this.getPositions()
      positionList.splice(index, 1)
      this.setPositions(positionList)
    },
    getPositions() {
      const positionList = []
      for (let i = 1; i <= this.component.config.anzahlFonds; i++)
        if (this.positionData["isin" + i])
          positionList.push({
            isin: this.positionData["isin" + i],
            name: this.positionData["fondsname" + i],
            anteile: this.positionData["anteile" + i],
          })
      return positionList
    },
    setPositions(positionList) {
      for (let i = 1; i <= this.component.config.anzahlFonds; i++) {
        Vue.set(this.positionData, "isin" + i, positionList.length >= i ? positionList[i-1].isin : null)
        Vue.set(this.positionData, "fondsname" + i, positionList.length >= i ? positionList[i-1].name : null)
        Vue.set(this.positionData, "anteile" + i, positionList.length >= i ? positionList[i-1].anteile : null)
      }
    },
    editBankverbindung(event) {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG);
    },
  },
  beforeDestroy() {
    this.savePosition()
  }
}
</script>
<style scoped>

</style>