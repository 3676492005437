<template>
  <div>
    <Table
      title="Terminfreigabe für andere Personen"
      :headerActions="tableHeaderActions"
      :headers="headers"
      :rows="rows"
      hidePagination
      @headerAction-ADD="openAndereInternPersonenModal"
      @action-DELETE="deleteFreigabe"
    >
      <template #schreibrecht="row">
        <InputToggleSwitch :value="row.schreibrecht" @input="onSchreibrechtChange(row, $event)" />
      </template>
    </Table>

    <AndereInternPersonenModal ref="andereInternPersonenModal" @selected="onSelectedPersonen" />
  </div>
</template>

<script>
import Table from '@/components/table2/Table.vue';
import { TextColumn, SlotColumn, ActionColumn, SimpleAction, } from '@/components/table2/table_util';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import AndereInternPersonenModal from '@/components/calendar/settings/AndereInternPersonenModal.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    freigaben() {
      return [ ...this.value, ];
    },
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('ADD', 'andere Personen hinzufügen'),
      ];
    },
    headers() {
      return {
        lockedLeft: [TextColumn('anInternName', 'Name')],
        center: [],
        lockedRight: [
          SlotColumn('schreibrecht', 'Ändern'),
          ActionColumn('actions'),
        ],
      };
    },
    rows() {
      return [ ...this.freigaben || [], ].map(item => ({
        ...item,
        actions: [
          SimpleAction('DELETE', '', 'löschen'),
        ],
      }));
    },
  },
  methods: {
    openAndereInternPersonenModal() {
      const selectedPersonen = this.freigaben.map(item => item.anIntern);
      this.$refs.andereInternPersonenModal?.open([ ...selectedPersonen, ]);
    },
    onSchreibrechtChange(freigabe, value) {
      const freigaben = [ ...this.freigaben, ];
      const index = freigaben.findIndex(item => item.id === freigabe.id && item.anIntern === freigabe.anIntern);
      if(index >= 0) {
        const freigabe = { ...freigaben[index], };
        freigabe.schreibrecht = value;
        freigaben[index] = freigabe;
      }
      this.$emit('input', freigaben);
    },
    async deleteFreigabe(freigabe) {
      try {
        await this.$confirmModal({
          title: ' Terminfreigabe löschen',
          message: `Möchten Sie die Terminfreigabe für "${freigabe.anInternName}" wirklich löschen?`, 
        });

        const freigaben = [ ...this.freigaben, ];
        const index = freigaben.findIndex(item => item.id === freigabe.id && item.anIntern === freigabe.anIntern);
        if(index >= 0) {
          freigaben.splice(index, 1);
        }
        this.$emit('input', freigaben);
      } catch(e) {
        // empty block
      }
    },
    onSelectedPersonen(selectedPersonen) {
      if(!selectedPersonen?.length) return;

      const freigabenPersonen = selectedPersonen.map(person => ({
        anIntern: person.id,
        anInternName: person.name,
        schreibrecht: false,
      }));
      const freigaben = [ ...this.freigaben, ...freigabenPersonen, ];
      this.$emit('input', freigaben);
    },
  },
  components: {
    Table,
    InputToggleSwitch,
    AndereInternPersonenModal,
  },
}
</script>
