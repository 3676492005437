import { checkGettersDeclarationIntegrity } from '@/helpers/utils-helper.js'
import Vue from 'vue'
import Vuex from 'vuex'
import core from './core'
import httpRequests from './http-requests'
import communication from './communication'
import customer from './customer'
import documents from './documents'
import intern from './intern'
import financialCalculator from './financialCalculator'
import cms from './cms'
import customerFilters from './customerFilters'
import fileUpload from './fileUpload'
import bipro from './bipro'
import dashboard from '../components/dashboard/store'
import calendar from './calendar'
import calendarSettings from './calendarSettings'
import versicherungen from './versicherungen'
import schadensmeldungen from './schadensmeldung'
import dokumentenarchiv from './dokumentenarchiv'
import depotpositions from './depotpositions'
import retirementScenario from './retirementScenario'
import myGoals from './myGoals'
import wertentwicklung from './wertentwicklung'
import multiBanking from './multiBanking'
import graphicalCourse from './graphicalCourse'
import customerSearch from './customerSearch'
import steckbrief from '@/components/steckbrief/store'
import antrag from './antrag'
import antragEfonds from './antragEfonds'
import dynamicDocument from './dynamicDocument'
import anlegerprofil from './anlegerprofil'
import anlageziele from './anlageziele'
import ansprechpartner from './ansprechpartner'
import gesellschaft from './gesellschaft'
import persoenlicheDaten from './customerData'
import fondsfavoriten from './fondsfavoriten'
import fondsfinder from './fondsfinder'
import webrtc from './webrtc'
import log from './log'
import login from './login'
import wertpapierinfo from './wertpapierinfo'
import fondsvergleich from './fondsvergleich'
import virtualsubdepots from './virtualsubdepots'
import depotpositionenedit from './depotpositionenedit'
import balance from './balance-sheet'
import insurance from './insurance'
import portfolio from './portfolio'
import investmentAdvice from './investmentAdvice'
import wertpapierorder from './wertpapierorder'
import beratungsmappe from './beratungsmappe'
import mailcomposer from './mailcomposer'
import account from './account'
import marktbericht from './marktbericht'
import risikoverteilung from './risikoverteilung'
import beteiligungen from './beteiligungen'
import fondsinfo from './fondsinfo'
import expost from './expost'
import externeZugang from './externeZugang'
import credit from './credit'
import searchConfigs from './searchConfigs'
import sessionTime from './session-time'
import stornoWarnung from './stornoWarnung'
import mscNews from './mscnews'
import mergePeople from './merge-people/index'
import vertragWP from './vertragWP/index'
import brokerStatistics from './brokerStatistics'
import otherProducts from './otherProducts'
import vertragStatus from './vertragStatus'
import externeZugangBank from './externeZugangBank'
import brokerData from './brokerData'
import brokerPermissions from './brokerPermissions'
import transaktionVerlauf from './transaktionVerlauf'
import bridge from './bridge'
import sso from './sso'
import navigationLog from './navigation-log'
import beratungVersicherung from './beratungVersicherung'
import kennzahlen from './kennzahlen'
import customerCategory from './customerCategory'
import maklerauftraege from './maklerauftraege'
import maklerAntrag from './maklerAntrag'
import versicherungsantraege from './versicherungsantraege'
import abrechnungen from './abrechnungen'
import tags from './tags'
import emailNotRecognized from './emailNotRecognized'
import openSigns from './openSigns'
import insuranceBuchung from './insuranceBuchung'
import courtagetabelleVersicherung from './courtagetabelleVersicherung'
import vermittlernummern from './vermittlernummern'
import limit from './limit'
import quartalsberichte from './quartalsberichte'
import configDefinition from './configDefinition'
import versicherungstarife from './versicherungstarife'
import berichte from './berichte'
import process from './process'
import tapi from './tapi'
import menu from './menu'
import menuConfig from './menuConfig'
import menuStructure from './menuStructure'
import fcConfig from './fcConfig'
import loading from './loading'
import domainFC from './domainFC'
import versandProtokolle from './versandProtokolle'
import vermittlerwechsel from './vermittlerwechselstatus'
import vermoegensverwaltung from './vermoegensverwaltung'
import eingegangeneDokumente from './eingegangeneDokumente'
import fee from './fee'
import exPostManuelle from './exPostManuelle'
import vermittlerstruktur from './vermittlerstruktur'
import honorare from './honorare'
import risikoklassenDefinition from './risikoklassenDefinition'
import rundmailstatus from './rundmailstatus'
import courtagetabelleInvestment from './courtagetabelleInvestment'
import templatesEdit from './templatesEdit'
import makler34f from './makler34f'
import geldwaeschebelehrung from './geldwaeschebelehrung'
import maklerTexte from './maklerTexte'
import eingebundeneWertpapiere from './eingebundeneWertpapiere'
import externeZugangConfig from './externeZugangConfig'
import freistellungsauftrag from './freistellungsauftrag'
import deckblatt from './deckblattversicherungen'
import vertriebsinfo from './vertriebsinfo'
import quartalsberichteBestellungen from './quartalsberichteBestellungen'
import zusaetzlicheInformationen from './zusaetzlicheInformationen'
import fondsAntragCheck from './fondsAntragCheck'
import pendingActions from './pendingActions'
import datenschutzschulung from './datenschutzschulung'
import dcsSynchronisation from './dcsSynchronisation'
import vertragsspiegel from './vertragsspiegel'
import marktberichtUploaden from './marktberichtUploaden'
import eMailAccess from './eMailAccess'
import sipgate  from './sipgate'
import mrmoney  from './mrmoney'
import fahrzeugdaten from './fahrzeugdaten'
import spartentypeinstellung from './spartentypeinstellung'
import nafi from './nafi'
import persoenlichesGeschaeft from './persoenlichesGeschaeft'
import ruhestandsplanung from './ruhestandsplanung'
import kundenGesellNr from './kundenGesellNr'
import stoppuhr from './stoppuhr'
import infobriefe from './infobriefe'
import infofilme from './infofilme'
import datenschutzkundenaenderungen from './datenschutzkundenaenderungen'
import dbm from './dbm'
import vuBlock from './vuBlock'
import mscmarketing from './mscmarketing'
import courtageliste from './courtageliste'
import stepper from './stepper'
import wertpapiereAlleKunden from './wertpapiereAlleKunden'
import tan from './tan'
import inszipfile from './inszipfile'
import chattestquery from './chattestquery'
import { v4 as uuidv4 } from 'uuid';
import interneStatistiken from './interneStatistiken'
import biProGesellschaftZuordnen from './zuordnungGesellschaftenBipro'
import biproCategory from './biproCategory'
import gdv from './gdv'
import gubbi from './gubbi'
import brokerSearch from './brokerSearch'
import listSchaden from './listSchaden'
import affiliate from './affiliate'

Vue.use(Vuex)

const modules = {
  core,
  httpRequests,
  communication,
  customer,
  documents,
  intern,
  financialCalculator,
  cms,
  dashboard,
  versicherungen,
  fileUpload,
  bipro,
  calendar,
  calendarSettings,
  schadensmeldungen,
  customerFilters,
  dokumentenarchiv,
  depotpositions,
  retirementScenario,
  myGoals,
  wertentwicklung,
  multiBanking,
  graphicalCourse,
  customerSearch,
  steckbrief,
  antrag,
  antragEfonds,
  dynamicDocument,
  anlegerprofil,
  anlageziele,
  ansprechpartner,
  gesellschaft,
  persoenlicheDaten,
  fondsfavoriten,
  fondsfinder,
  webrtc,
  log,
  login,
  wertpapierinfo,
  fondsvergleich,
  virtualsubdepots,
  depotpositionenedit,
  balance,
  insurance,
  portfolio,
  investmentAdvice,
  wertpapierorder,
  beratungsmappe,
  mailcomposer,
  account,
  marktbericht,
  risikoverteilung,
  beteiligungen,
  fondsinfo,
  expost,
  externeZugang,
  credit,
  searchConfigs,
  sessionTime,
  stornoWarnung,
  mscNews,
  mergePeople,
  vertragWP,
  brokerStatistics,
  otherProducts,
  vertragStatus,
  externeZugangBank,
  brokerData,
  brokerPermissions,
  transaktionVerlauf,
  bridge,
  sso,
  beratungVersicherung,
  ruhestandsplanung,
  kennzahlen,
  customerCategory,
  versicherungsantraege,
  abrechnungen,
  maklerauftraege,
  maklerAntrag,
  tags,
  emailNotRecognized,
  openSigns,
  insuranceBuchung,
  courtagetabelleVersicherung,
  vermittlernummern,
  tapi,
  menu,
  menuConfig,
  menuStructure,
  limit,
  quartalsberichte,
  fcConfig,
  loading,
  domainFC,
  configDefinition,
  versicherungstarife,
  berichte,
  process,
  versandProtokolle,
  vermittlerwechsel,
  vermoegensverwaltung,
  eingegangeneDokumente,
  fee,
  exPostManuelle,
  vermittlerstruktur,
  honorare,
  risikoklassenDefinition,
  rundmailstatus,
  courtagetabelleInvestment,
  templatesEdit,
  makler34f,
  geldwaeschebelehrung,
  maklerTexte,
  eingebundeneWertpapiere,
  externeZugangConfig,
  freistellungsauftrag,
  deckblatt,
  vertriebsinfo,
  quartalsberichteBestellungen,
  zusaetzlicheInformationen,
  fondsAntragCheck,
  pendingActions,
  datenschutzschulung,
  vertragsspiegel,
  dcsSynchronisation,
  marktberichtUploaden,
  eMailAccess,
  navigationLog,
  sipgate,
  mrmoney,
  fahrzeugdaten,
  spartentypeinstellung,
  nafi,
  persoenlichesGeschaeft,
  kundenGesellNr,
  stoppuhr,
  infobriefe,
  infofilme,
  datenschutzkundenaenderungen,
  dbm,
  vuBlock,
  mscmarketing,
  courtageliste,
  stepper,
  wertpapiereAlleKunden,
  tan,
  inszipfile,
  interneStatistiken,
  chattestquery,
  biProGesellschaftZuordnen,
  biproCategory,
  gdv,
  gubbi,
  brokerSearch,
  listSchaden,
  affiliate,
}

checkGettersDeclarationIntegrity(modules)

function generateRootState() {
  return {
    __DYNAMIC_APP_ID__: uuidv4(), // it is used in the `src/App.vue` component to force re-render
  };
}

/**
 * @typedef {import('vuex').ActionContext} ActionContext
 */
const store = new Vuex.Store({
  modules,
  state: {
    ...generateRootState(),
  },
});

export function generateNewAppId() {
  store.replaceState({
    ...store.state,
    ...generateRootState(),
  });
}

export default store;
