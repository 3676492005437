var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _c("div", { staticClass: "box__title" }, [_vm._v("Stammdaten")]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          !_vm.isMainPerson
            ? [
                _c("PersonBeziehungFields", {
                  attrs: {
                    relationship: _vm.relationship,
                    isComponentHalfSize: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.addRelationshipDataChanged($event)
                    }
                  }
                }),
                _vm.relationship.relation &&
                _vm.relationship.relation.value ===
                  "WIRTSCHAFTLICHER_EIGENTUEMER"
                  ? _c("InputField", {
                      attrs: {
                        label: "%-Angabe der wirtschaftlichen Beteiligung",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.addRelationshipDataChanged(
                            _vm.relationship
                          )
                        }
                      },
                      model: {
                        value: _vm.relationship.relation.beteiligung,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.relationship.relation,
                            "beteiligung",
                            $$v
                          )
                        },
                        expression: "relationship.relation.beteiligung"
                      }
                    })
                  : _vm._e(),
                _c("hr")
              ]
            : _vm._e(),
          _vm.hatSyncUser
            ? _c("InputToggleSwitch", {
                attrs: { label: "Synchronisation", inLineLabel: true },
                on: {
                  input: function($event) {
                    return _vm.addCustomerDataEditedCombo("personalDataAddress")
                  }
                },
                model: {
                  value: _vm.personalDataAddress.synchronisation,
                  callback: function($$v) {
                    _vm.$set(_vm.personalDataAddress, "synchronisation", $$v)
                  },
                  expression: "personalDataAddress.synchronisation"
                }
              })
            : _vm._e(),
          _vm.isMainPerson && _vm.isByPass
            ? _c("InputToggleSwitch", {
                attrs: {
                  label:
                    "Kundendaten werden durch automatische Updates angepasst / überschrieben",
                  inLineLabel: true,
                  disabled: !_vm.isEditable
                },
                on: {
                  input: function($event) {
                    return _vm.addCustomerDataEditedCombo("personalDataAddress")
                  }
                },
                model: {
                  value: _vm.personalDataAddress.automaticUpdated,
                  callback: function($$v) {
                    _vm.$set(_vm.personalDataAddress, "automaticUpdated", $$v)
                  },
                  expression: "personalDataAddress.automaticUpdated"
                }
              })
            : _vm._e(),
          _vm.isMainPerson && _vm.isByPass
            ? _c("InputToggleSwitch", {
                directives: [
                  {
                    name: "fc-tooltip",
                    rawName: "v-fc-tooltip",
                    value:
                      "Wenn diese Person bei einer anderen Kundennummer nochmal existiert,\n dann darf diese Person mit der anderen Person NICHT vereinigt werden!",
                    expression:
                      "'Wenn diese Person bei einer anderen Kundennummer nochmal existiert,\\n dann darf diese Person mit der anderen Person NICHT vereinigt werden!'"
                  }
                ],
                attrs: {
                  label: "Person darf nicht vereinigt werden.",
                  inLineLabel: true,
                  disabled: !_vm.isEditable
                },
                on: {
                  input: function($event) {
                    return _vm.addCustomerDataEdited("personalDataAddress")
                  }
                },
                model: {
                  value: _vm.personalDataAddress.disableUnitPerson,
                  callback: function($$v) {
                    _vm.$set(_vm.personalDataAddress, "disableUnitPerson", $$v)
                  },
                  expression: "personalDataAddress.disableUnitPerson"
                }
              })
            : _vm._e(),
          _vm.isIntern && _vm.showRisikolevel
            ? _c("InputToggleSwitch", {
                attrs: {
                  label: "Risikoeinstufung: manuelle Zuweisung",
                  inLineLabel: true
                },
                on: {
                  input: function($event) {
                    return _vm.addCustomerDataEditedCombo("personalDataAddress")
                  }
                },
                model: {
                  value: _vm.personalDataAddress.isRisikolevelManuel,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.personalDataAddress,
                      "isRisikolevelManuel",
                      $$v
                    )
                  },
                  expression: "personalDataAddress.isRisikolevelManuel"
                }
              })
            : _vm._e(),
          _vm.showRisikolevel
            ? _c("InputRadioBoxGroup", {
                attrs: {
                  title: "Risikoeinstufung (GWG)",
                  isComponentHalfSize: "",
                  disabled: !_vm.isRisikolevelEditable,
                  vertical: false,
                  values: [
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" }
                  ]
                },
                on: {
                  input: function($event) {
                    return _vm.addCustomerDataEdited("personalDataAddress")
                  }
                },
                model: {
                  value: _vm.personalDataAddress.risikolevel,
                  callback: function($$v) {
                    _vm.$set(_vm.personalDataAddress, "risikolevel", $$v)
                  },
                  expression: "personalDataAddress.risikolevel"
                }
              })
            : _vm._e(),
          _vm.isInvers && _vm.isByPass
            ? _c("InputField", {
                attrs: {
                  label: "Risikokennzahl",
                  isComponentHalfSize: true,
                  disabled: !_vm.isEditable,
                  type: "number",
                  precision: 1
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("personalDataAddress")
                  }
                },
                model: {
                  value: _vm.personalDataAddress.risikokennzahl,
                  callback: function($$v) {
                    _vm.$set(_vm.personalDataAddress, "risikokennzahl", $$v)
                  },
                  expression: "personalDataAddress.risikokennzahl"
                }
              })
            : _vm._e(),
          _vm.isByPass
            ? _c("ComboBox", {
                attrs: {
                  label: "Anredeform",
                  isComponentHalfSize: true,
                  values: _vm.anredePronomen,
                  disabled: !_vm.isEditable,
                  firstEmpty:
                    !_vm.isNewPerson && !_vm.personalDataAddress.anredePronomen
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEditedCombo(
                      "personalDataAddress",
                      "anredePronomen",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.personalDataAddress.anredePronomen,
                  callback: function($$v) {
                    _vm.$set(_vm.personalDataAddress, "anredePronomen", $$v)
                  },
                  expression: "personalDataAddress.anredePronomen"
                }
              })
            : _vm._e(),
          _c("ComboBox", {
            attrs: {
              label: "Anrede",
              labelClass: _vm.depoteroeffnungenLabelClass,
              isComponentHalfSize: true,
              values: _vm.customerDataConfig.genders,
              disabled: !_vm.isEditable,
              firstEmpty: !_vm.isNewPerson && !_vm.personalDataAddress.title
            },
            on: {
              change: function($event) {
                return _vm.addCustomerDataEditedCombo(
                  "personalDataAddress",
                  "title",
                  $event
                )
              }
            },
            model: {
              value: _vm.personalDataAddress.title,
              callback: function($$v) {
                _vm.$set(_vm.personalDataAddress, "title", $$v)
              },
              expression: "personalDataAddress.title"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Titel",
              isComponentHalfSize: true,
              disabled: !_vm.isEditable
            },
            on: {
              change: function($event) {
                return _vm.addCustomerDataEdited("personalDataAddress")
              }
            },
            model: {
              value: _vm.personalDataAddress.titles,
              callback: function($$v) {
                _vm.$set(_vm.personalDataAddress, "titles", $$v)
              },
              expression: "personalDataAddress.titles"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Vorname",
              isComponentHalfSize: true,
              disabled: !_vm.isEditable
            },
            on: {
              change: function($event) {
                return _vm.addCustomerDataEdited("personalDataAddress")
              }
            },
            model: {
              value: _vm.personalDataAddress.firstName,
              callback: function($$v) {
                _vm.$set(_vm.personalDataAddress, "firstName", $$v)
              },
              expression: "personalDataAddress.firstName"
            }
          }),
          _c("InputField", {
            attrs: {
              "data-field-target": "lastNameField",
              label: "Name",
              labelClass: _vm.depoteroeffnungenLabelClass,
              isComponentHalfSize: true,
              disabled: !_vm.isEditable,
              validateUntouched: ""
            },
            on: {
              change: function($event) {
                return _vm.addCustomerDataEdited("personalDataAddress")
              }
            },
            model: {
              value: _vm.personalDataAddress.lastName,
              callback: function($$v) {
                _vm.$set(_vm.personalDataAddress, "lastName", $$v)
              },
              expression: "personalDataAddress.lastName"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "c/o",
              isComponentHalfSize: true,
              disabled: !_vm.isEditable
            },
            on: {
              change: function($event) {
                return _vm.addCustomerDataEdited("personalDataAddress")
              }
            },
            model: {
              value: _vm.personalDataAddress.co,
              callback: function($$v) {
                _vm.$set(_vm.personalDataAddress, "co", $$v)
              },
              expression: "personalDataAddress.co"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Familienstand",
              labelClass: _vm.depoteroeffnungenLabelClass,
              isComponentHalfSize: true,
              values: _vm.customerDataConfig.personalStates,
              disabled: !_vm.isEditable
            },
            on: {
              change: function($event) {
                return _vm.addCustomerDataEditedCombo(
                  "personalDataAddress",
                  "personalStatus",
                  $event
                )
              }
            },
            model: {
              value: _vm.personalDataAddress.personalStatus,
              callback: function($$v) {
                _vm.$set(_vm.personalDataAddress, "personalStatus", $$v)
              },
              expression: "personalDataAddress.personalStatus"
            }
          }),
          _vm.isByPass
            ? [
                _vm.isMainPerson
                  ? _c("InputField", {
                      attrs: {
                        label: "Briefanrede Depot",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEditedCombo(
                            "personalDataAddress"
                          )
                        }
                      },
                      model: {
                        value: _vm.personalDataAddress.briefanredeDepot,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.personalDataAddress,
                            "briefanredeDepot",
                            $$v
                          )
                        },
                        expression: "personalDataAddress.briefanredeDepot"
                      }
                    })
                  : _vm._e(),
                _c("InputField", {
                  attrs: {
                    label: "Briefanrede Person",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.addCustomerDataEditedCombo(
                        "personalDataAddress"
                      )
                    }
                  },
                  model: {
                    value: _vm.personalDataAddress.briefanredePerson,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.personalDataAddress,
                        "briefanredePerson",
                        $$v
                      )
                    },
                    expression: "personalDataAddress.briefanredePerson"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label: "Person verstorben",
                    inLineLabel: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    input: function($event) {
                      _vm.addCustomerDataEditedCombo("personalDataAddress")
                      _vm.handlePersonDeceased($event)
                    }
                  },
                  model: {
                    value: _vm.personalDataAddress.personDeceased,
                    callback: function($$v) {
                      _vm.$set(_vm.personalDataAddress, "personDeceased", $$v)
                    },
                    expression: "personalDataAddress.personDeceased"
                  }
                }),
                _vm.personalDataAddress.personDeceased
                  ? _c("DatePickerField", {
                      attrs: {
                        label: "Sterbedatum",
                        validateUntouched: "",
                        isValueAsString: "",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        input: function($event) {
                          _vm.addCustomerDataEdited("personalDataAddress")
                          _vm.handleDateOfDeath($event)
                        }
                      },
                      model: {
                        value: _vm.personalDataAddress.dateOfDeath,
                        callback: function($$v) {
                          _vm.$set(_vm.personalDataAddress, "dateOfDeath", $$v)
                        },
                        expression: "personalDataAddress.dateOfDeath"
                      }
                    })
                  : _vm._e(),
                _c("InputField", {
                  attrs: {
                    label: "Kundenreferenznr.",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.addKundenReferenzNummerDataChanged($event)
                    }
                  },
                  model: {
                    value: _vm.kundenReferenzNummer,
                    callback: function($$v) {
                      _vm.kundenReferenzNummer = $$v
                    },
                    expression: "kundenReferenzNummer"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Gruppenmitglied von.",
                    isComponentHalfSize: true,
                    disabled: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.addKundenReferenzNummerDataChanged($event)
                    }
                  },
                  model: {
                    value: _vm.gruppenMitglied,
                    callback: function($$v) {
                      _vm.gruppenMitglied = $$v
                    },
                    expression: "gruppenMitglied"
                  }
                }),
                _vm.isMainPerson
                  ? [
                      _c("ComboBox", {
                        attrs: {
                          label: "Kundenkategorie",
                          isComponentHalfSize: true,
                          values: _vm.customerDataConfig.customerCategories,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          change: function($event) {
                            return _vm.addCustomerDataEditedCombo(
                              "personalDataAddress",
                              "category",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.personalDataAddress.category,
                          callback: function($$v) {
                            _vm.$set(_vm.personalDataAddress, "category", $$v)
                          },
                          expression: "personalDataAddress.category"
                        }
                      }),
                      _vm.personalDataAddress.dateCreated
                        ? _c("DatePickerField", {
                            attrs: {
                              label: "Anlagedatum des Kunden",
                              isValueAsString: "",
                              isComponentHalfSize: true,
                              disabled: ""
                            },
                            model: {
                              value: _vm.personalDataAddress.dateCreated,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.personalDataAddress,
                                  "dateCreated",
                                  $$v
                                )
                              },
                              expression: "personalDataAddress.dateCreated"
                            }
                          })
                        : _vm._e(),
                      _c("InputToggleSwitch", {
                        attrs: {
                          label: "Interessent",
                          inLineLabel: true,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          input: function($event) {
                            return _vm.addCustomerDataEditedCombo(
                              "personalDataAddress"
                            )
                          }
                        },
                        model: {
                          value: _vm.personalDataAddress.interested,
                          callback: function($$v) {
                            _vm.$set(_vm.personalDataAddress, "interested", $$v)
                          },
                          expression: "personalDataAddress.interested"
                        }
                      }),
                      _c("InputToggleSwitch", {
                        attrs: {
                          label: "automatische Anpassung sperren",
                          inLineLabel: true,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          input: function($event) {
                            return _vm.addCustomerDataEditedCombo(
                              "personalDataAddress"
                            )
                          }
                        },
                        model: {
                          value: _vm.personalDataAddress.categoryAutoLock,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.personalDataAddress,
                              "categoryAutoLock",
                              $$v
                            )
                          },
                          expression: "personalDataAddress.categoryAutoLock"
                        }
                      }),
                      _c("ComboBox", {
                        staticClass: "_betreuer",
                        attrs: {
                          label: "Betreuer",
                          isComponentHalfSize: true,
                          values: _vm.customerBetreuer || [],
                          disabled: !_vm.isEditable
                        },
                        on: {
                          change: function($event) {
                            return _vm.addCustomerDataEditedCombo(
                              "personalDataAddress",
                              "betreuer",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.personalDataAddress.betreuer,
                          callback: function($$v) {
                            _vm.$set(_vm.personalDataAddress, "betreuer", $$v)
                          },
                          expression: "personalDataAddress.betreuer"
                        }
                      }),
                      _c("InputTextArea", {
                        attrs: {
                          label: "Anmerkungen (für den Kunden nicht sichtbar)",
                          isComponentHalfSize: true,
                          disabled: !_vm.isEditable,
                          rows: 5,
                          maxlength: 4000
                        },
                        on: {
                          change: function($event) {
                            return _vm.addCustomerDataEdited(
                              "personalDataAddress"
                            )
                          }
                        },
                        model: {
                          value: _vm.personalDataAddress.interneBemerkung,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.personalDataAddress,
                              "interneBemerkung",
                              $$v
                            )
                          },
                          expression: "personalDataAddress.interneBemerkung"
                        }
                      })
                    ]
                  : _vm._e()
              ]
            : _vm._e(),
          _vm.isMainPerson ? _c("UserPicture") : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }