var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.finished
    ? _c(
        "div",
        [
          _c(
            "TemplateContainer",
            [
              _vm.$route.path === "" || _vm.$route.path === "/"
                ? [
                    _vm.page == "/fondsshop" ? [_c("Fondsshop")] : _vm._e(),
                    _vm.page == "/home" ? [_c("Home")] : _vm._e(),
                    _vm.page == "/home7" ? [_c("Home7")] : _vm._e(),
                    _vm.page == "/home9" ? [_c("Home9")] : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }