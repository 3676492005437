<template>
  <div class="formulare-list__container">

    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      :actions="headerActions"
      title="Gesellschaften"
      @action-NEW_GESELLSCHAFT="openCreateGesellschaftModal"
    />

    <BaseFilter 
      filterId="790ca065-681f-4a9f-952f-ec8c993ca818"
      title="Gesellschaften filtern" 
      :metadata="metadata" 
      :configFilter="configFilter"
      :immidiateSearch="!isIntern"
      hasSmartSearch
      showSaveButton
      @onFilter="doFilter"
    />

    <div class="box__container">
      <GhostLoading v-if="loading" type="table" :config="{ table: { rows: 10 } }" />
      <Table
        v-else
        title="Gesellschaften"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="20"
        :exportConfig="{roottext: 'Gesellschaften'}"
        :mobileConfig="{}"
        @click-id="goToGesellschaft"
        @click-label="goToGesellschaft"
        @action-DELETE="deleteGesellschaft"
      />
    </div>

    <BaseModal
        ref="createGesellschaftModal"
        modalTitle="Gesellschaft erstellen"
        labelButtonConfirm="Erstellen"
        :confirmDisabled="!gesellschaftData.id || !gesellschaftData.name || !gesellschaftData.art"
        @onConfirmButton="createGesellschaft"
    >
      <InputField
        label="ID"
        v-model="gesellschaftData.id"
        :renderDanger="!gesellschaftData.id"
      />

      <InputField
        label="Bezeichnung"
        v-model="gesellschaftData.name"
        :renderDanger="!gesellschaftData.name"
      />

      <ComboBox
        label="Art"
        v-model="gesellschaftData.art"
        :values="artValues"
      />
    </BaseModal> 
  </div>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import CORE_TYPES from "@/store/core/types";

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { mapGetters } from "vuex";
import { VIEW_ROLES } from '@/router/roles'

import Table from "@/components/table2/Table.vue";
import { TextColumn, PillColumn, NumberColumn, IconColumn, Icon, ConfirmedAction, ActionColumn, } from "@/components/table2/table_util.js";
import { makeQueryParam } from '@/helpers/utils-helper';

import BaseFilter from "@/components/core/BaseFilter.vue";
import axios from 'axios';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue'
import {
  PhCheck,
  PhWarning,
} from "phosphor-vue";

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    BaseFilter,
    GhostLoading,
    BaseModal,
    ComboBox,
    InputField,
  },
  data() {
    return {
      gesellschaftenList: [],
      filterParams: {},
      loading: false,
      gesellschaftData: {},
      artValues: [
        {
          label: 'Fondsgesellschaft',
          value: 'Fondsgesellschaft',
        },
        {
          label: 'Versicherung',
          value: 'Versicherung',
        },
        {
          label: 'Bank',
          value: 'Bank',
        },
        {
          label: 'Geschlossene Fonds',
          value: 'Geschlossene Fonds',
        },
        {
          label: 'Pool',
          value: 'Pool',
        },
      ],
    }
  },
  watch: {
    $route() {
      if (this.isModusBroker) {
        this.doSearch();
      }
    },
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isAdmin: CORE_TYPES.GETTERS.IS_ADMIN,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    configFilter() {
      return {
        placeholderForDefSearchInput: this.isModusBroker ? "Bezeichnung" : 'Name, ID, Ansprechpartner',
        checkDefaultSearchInput: false,
        hideFirstColumn: this.isModusBroker,
        singleFilter: this.isModusVersDaten,
      }
    },
    isVersicherung() {
      return this.$route.meta?.isVersicherung
    },
    headers() {
      const headers = {
        lockedLeft: [
          ...(!this.isModusBroker ? [TextColumn("id", "ID").makeLink(),] : []),
          TextColumn("label", this.isModusBroker ? "Bezeichnung" : "Name").makeLink(this.isModusBroker),
        ],
        center: [
          ...(this.isIntern ? [
            TextColumn("art", "Art"),
            PillColumn("status", "Status", null, 1),
            NumberColumn("countZugaenge", "Aktive Zugänge"),
            TextColumn("email", "E-Mail"),
          ] : []),
          ...(this.isModusBroker && this.isVersicherung ? [
            IconColumn("mitVertrag", "Vermittlung möglich", null, 1),
          ] : []),
          TextColumn("ort", "Ort"),
        ],
        lockedRight: [],
      }

      if (this.isIntern) {
        headers.lockedRight.push(ActionColumn("actions", "Aktionen"))
      }

      return headers
    },
    rows() {
      return this.gesellschaftenList.map(item => ({
        ...item,
        mitVertrag: item.hasVertrag ? Icon(PhCheck, "", 18, "bold", "color-success") : Icon(PhWarning, "", 18, "bold", "color-danger"),
        status: item.inaktiv ? {label: 'inaktiv', type: 'danger'} : {label: 'aktiv', type: 'success'},
        actions: this.makeActions(item)
      }))
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('NEW_GESELLSCHAFT', 'Neue Gesellschaft')
          .withVisible(() => this.isAdmin),
      ];
    },
    metadata() {
      if (this.isModusBroker) {
        return this.metadataBroker
      } else {
        return this.metadataIntern
      }
    },
    metadataIntern() {
      return [
        {
          type: "group",
          key: "allgemein",
          label: "Allgemein",
          menuItems: [
            {
              type: "text",
              label: "Name",
              key: "name",
            },
            {
              type: "text",
              label: "ID",
              key: "id",
            },
            {
              type: "text",
              label: "Ansprechpartner",
              key: "ansprechpartner",
            },
          ],
        },
        {
          type: "group",
          key: "art",
          label: "Art",
          menuItems: this.artValues.map(art => ({
            type: "group",
            label: art.label,
            key: art.value,
          }))
        },
        {
          type: "group",
          key: "sonstige",
          label: "Sonstige",
          menuItems: [
            {
              type: "default",
              label: "nutzt Servicepaket",
              key: "mitServicepaket",
            },
            {
              type: "default",
              label: "nutzt Bannerwerbung",
              key: "mitBannerwerbung",
            },
            {
              type: "default",
              label: "ist Toppartner",
              key: "istToppartner",
            },
            {
              type: "default",
              label: "Mit Direktvertrag",
              key: "mitDirektvertrag",
            },
          ],
        },
      ]
    },
    metadataBroker() {
      return [
        {
          type: "group",
          key: "allgemein",
          label: "Allgemein",
          menuItems: [
            {
              type: "text",
              label: "Bezeichnung",
              key: "name",
            },
            {
              type: "default",
              label: "(noch) keine Vermittlung möglich",
              key: "mitVertrag",
            },
          ],
        },
      ]
    },
    currentMenuLocal() {
      if (this.isModusBroker) {
        const infoArt = this.$route.path.split('/').at(-1)

        return {
          label: this.$appNavigation.currentMenu.label,
          key: infoArt,
        }
      }
    },
    isModusBroker() {
      const isModusBroker = !!this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN, VIEW_ROLES.VIEW_BROKER])
      return isModusBroker && !this.isModusVersDaten
    },
    isModusVersDaten() {
      return this.$route.meta?.isModusVersDaten
    },
  },
  methods: {
    makeActions(item) {
      const actions = []

      if (this.isAdmin) {
        actions.push(ConfirmedAction("DELETE", "", "Löschen", `Soll die Gesellschaft '${item.label}' wirklich gelöscht werden?`, 'Gesellschaft löschen', "Löschen"))
      }
      
      return actions
    },
    doFilter(filterOptions) {
      let params = {};
      filterOptions.forEach(fc => {
        if (fc.group === "art") {
          params[fc.group] = fc.key;
        } else if (fc.group === "sonstige") {
          params[fc.key] = true
        } else if (fc.key) {
          params[fc.key] = fc.value;
        }
      });
      
      this.filterParams = params
      this.doSearch()
    },
    doSearch(filterParams = this.filterParams) {
      this.loading = true

      const payload = {...filterParams}

      if (this.isModusVersDaten) {
        payload.art = 'Versicherung'
      } else if (this.isModusBroker) {
        payload.mitVertrag = !filterParams.mitVertrag
        payload.infoArt = this.currentMenuLocal.key
      }

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/list`, payload, config).then(response => {
        if (response && response.data) {
          this.gesellschaftenList = response.data && response.data.sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase())) || []
        }
      }).finally(() => this.loading = false)
    }, 
    deleteGesellschaft(row) {
      this.loading = true

      const params = makeQueryParam({ gesellschaftId: row.id })

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/delete?${params}`, config).then(() => {
        const index = this.gesellschaftenList.findIndex(gesell => row.id === gesell.id);
        if (index != -1) {
          this.gesellschaftenList.splice(index, 1);
        }
      }).finally(() => this.loading = false)
    },
    openCreateGesellschaftModal() {
      this.gesellschaftData = {
        art: 'Fondsgesellschaft',
      }
      this.$refs.createGesellschaftModal.open()
    },
    createGesellschaft() {
      this.loading = true

      const payload = {
        id: this.gesellschaftData.id,
        art: this.gesellschaftData.art,
        hauptDaten: {
          name: this.gesellschaftData.name
        }
      }

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/create`, payload, config).then(() => {
        this.goToGesellschaft({id: this.gesellschaftData.id})
      }).finally(() => this.loading = false)
    },
    goToGesellschaft(row) {
      this.$store.commit(GESELLSCHAFT_TYPES.MUTATIONS.RESET_STATE);
      this.$router.push(`${this.$route.path}/${row.id}`);
    },
  },

  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: "zurück",
      to,
      from,
    });

    next()
  },
}
</script>
