<template>
  <div class="customer-picture__container" @click="$emit('click')">

    <template v-if="imageUrl">
        <div 
          class="no-line-height"
          :class="{
          'image-cropper--small': size === 'small',
          'image-cropper--large': size === 'large'
          }" >

          <img 
            :src="imageUrl"
            :class="{
              'rounded--small': size === 'small',
              'rounded--large': size === 'large'
            }"
            :alt="ariaDescription"
          />
        </div>
    </template>
    <template v-else>
      <div class="customer-picture__image-placeholder customer-picture__container-img"
        :class="{
          'customer-picture__container-img--small': size === 'small',
          'customer-picture__container-img--large': size === 'large'
        }">
        <ph-user :size="24" />
      </div>
    </template>

  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import { PhUser } from 'phosphor-vue'

export default {
  props: {
    size: {
      type: String,
      default: 'small'
    },
    image: {
      type: String,
      default: ''
    },
    useParamsImage: {
      type: Boolean,
      default: false,
    },
    ariaDescription: {
      type: String,
      default: 'Profilbild des Nutzers'
    },
  },
  computed: {
    ...mapGetters({
      userPictureUrl: CORE_TYPES.GETTERS.GET_USER_PICTURE_URL,
    }),
    imageUrl() {
      if (this.useParamsImage) {
        return this.image;
      }

      return this.userPictureUrl;
    }
  },
  components: {
    PhUser
  }
}
</script>

<style scoped>

  .customer-picture__image-placeholder {
    color: var(--color-workspaces-nav-background);
    background-color: var(--color-workspaces-nav-text);
  }

  .customer-picture__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 0 0 auto;
  }

  .customer-picture__container-div {
    border-radius: 100%;
    background-size: cover;
    background: #eee no-repeat center;
  }

  .customer-picture__container-img {
    border-radius: 50%;
  }

  .image-cropper--small {
    width: 24px;
    height: auto;
    position: relative;
    overflow: hidden;
  }
  .customer-picture__container-img--small {
    width: 24px;
    height: 24px;
  }

  .customer-picture__container-img--large {
    width: 100px;
    height: 100px;
  }

  .image-cropper--large {
    width: 100px;
    height: auto;
    position: relative;
    overflow: hidden;
  }

  .rounded--small,
  .rounded--large {
    border-radius: 50%;
    object-fit: cover;
    object-position: top center;
  }

  .rounded--small {
    height: 24px;
    width: 24px;
  }

  .rounded--large {
    height:100px; 
    width: 100px; 
  }  

  .customer-picture__container-div--small {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .customer-picture__container-div--large {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

</style>