var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    !_vm.getLoaded ? _c("div", { staticClass: "cms_ghost-filler" }) : _vm._e(),
    _c(
      "div",
      {
        staticClass: "router_view_class",
        class: {
          router_view_margin_logged: _vm.isBroker || _vm.isIntern,
          router_view_margin_unlogged: !_vm.isBroker && !_vm.isIntern
        }
      },
      [_vm._t("default"), _c("router-view")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }