export const MUTATION_PREFIX = 'ANLEGERPROFIL: ';
export const ACTIONS_PREFIX = 'ANLEGERPROFIL_ACTIONS_';
export const GETTERS_PREFIX = 'ANLEGERPROFIL_GETTERS_';

export default {
  MUTATIONS: {
    GET_ANLEGERPROFIL_SUCCESS: MUTATION_PREFIX + 'GET_ANLEGERPROFIL_SUCCESS',
    SAVE_ANLEGERPROFIL_SUCCESS: MUTATION_PREFIX + 'SAVE_ANLEGERPROFIL_SUCCESS',
    CHECK_ANLEGERPROFIL_SUCCESS: MUTATION_PREFIX + 'CHECK_ANLEGERPROFIL_SUCCESS',
    UPDATE_DATA_STORE: MUTATION_PREFIX + 'UPDATE_DATA_STORE',
    UPDATE_DATA_STORE_EXPERIENCE_CARD: MUTATION_PREFIX + 'UPDATE_DATA_STORE_EXPERIENCE_CARD',
    DOWNLOAD_PDF_SUCCESS: MUTATION_PREFIX + 'DOWNLOAD_PDF_SUCCESS',
    UPDATE_ANLEGERPROFIL_EXPERIENCE_CATEGORIES: MUTATION_PREFIX + 'UPDATE_ANLEGERPROFIL_EXPERIENCE_CATEGORIES',
    START_SAVE_ANLEGERPROFIL_STATE: MUTATION_PREFIX + 'START_SAVE_ANLEGERPROFIL_STATE',
    STOP_SAVE_ANLEGERPROFIL_STATE: MUTATION_PREFIX + 'STOP_SAVE_ANLEGERPROFIL_STATE',
    UPDATE_SCHIENE: MUTATION_PREFIX + 'UPDATE_SCHIENE',
    ANLAGEZIELE_UPDATE_DATA: MUTATION_PREFIX + 'ANLAGEZIELE_UPDATE_DATA',
    ANLAGEZIELE_ADD_ZEILE: MUTATION_PREFIX + 'ANLAGEZIELE_ADD_ZEILE',
    ANLAGEZIELE_REMOVE_ZEILE: MUTATION_PREFIX + 'ANLAGEZIELE_REMOVE_ZEILE',
    COPY_FINANCE_SUCCESS: MUTATION_PREFIX + 'COPY_FINANCE_SUCCESS',
    SET_BERATUNGS_MAPPE_ID: MUTATION_PREFIX + 'SET_BERATUNGS_MAPPE_ID',
    SET_SELECTED_PERSON_TYPE: MUTATION_PREFIX + 'SET_SELECTED_PERSON_TYPE',
    SET_PERSON_TYPE: MUTATION_PREFIX + 'SET_PERSON_TYPE',
    REMOVE_ALL_KENNTNISSE: MUTATION_PREFIX + 'REMOVE_ALL_KENNTNISSE',
    UPDATE_IS_VORGELEGT: MUTATION_PREFIX + 'UPDATE_IS_VORGELEGT',
    UPDATE_ELTIF: MUTATION_PREFIX + 'UPDATE_ELTIF',
    UPDATE_DATA_STORE_ZUSATZ_DJE_ZIELMARKT_ROWS: MUTATION_PREFIX + 'UPDATE_DATA_STORE_ZUSATZ_DJE_ZIELMARKT_ROWS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_ANLEGERPROFIL: ACTIONS_PREFIX + 'GET_ANLEGERPROFIL',
    SAVE_ANLEGERPROFIL: ACTIONS_PREFIX + 'SAVE_ANLEGERPROFIL',
    SAVE_ANLAGEZIELE: ACTIONS_PREFIX + 'SAVE_ANLAGEZIELE',
    CHECK_ANLEGERPROFIL: ACTIONS_PREFIX + 'CHECK_ANLEGERPROFIL',
    DOWNLOAD_PDF: ACTIONS_PREFIX + 'DOWNLOAD_PDF',
    SIGN_PDF: ACTIONS_PREFIX + 'SIGN_PDF',
    COPY_FINANCE_FROM_CUSTOMER_DATA: ACTIONS_PREFIX + 'COPY_FINANCE_FROM_CUSTOMER_DATA',
    REFUSE_CATEGORY: ACTIONS_PREFIX + 'REFUSE_CATEGORY',
    SEND_TO_ELECTRONIC_SIGNATURE: ACTIONS_PREFIX + 'SEND_TO_ELECTRONIC_SIGNATURE',
  },
  GETTERS: {
    ANLEGERPROFIL: GETTERS_PREFIX + 'ANLEGERPROFIL',
    WARNINGS: GETTERS_PREFIX + 'WARNINGS',
    BERATUNGS_MAPPE_ID: GETTERS_PREFIX + 'BERATUNGS_MAPPE_ID',
    SAVE_ANLEGERPROFIL_STATE: GETTERS_PREFIX + 'SAVE_ANLEGERPROFIL_STATE',
    SELECTED_PERSON_TYPE: GETTERS_PREFIX + 'SELECTED_PERSON_TYPE',
    PERSON_TYPE: GETTERS_PREFIX + 'PERSON_TYPE',
  }
}
