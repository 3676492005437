import dayjs from 'dayjs';
import Vue from 'vue';
import {formatNumber} from '@/helpers/number-formatter.js';
import {preformancePerAnno} from "@/helpers/commonfunctions"

dayjs.locale('de');

const offsetTZ = (new Date().getTimezoneOffset() )* 60000;

export const utils = {
  getDerivedData(serie, factor, add, length) {
    const result = [];
    let k = 1;
    if (factor[0] === 0 ) {
      result.push(null);
      while (factor[k] === 0) {
        result.push(null);
        k++;
      }
      result.push(serie[k] );
      k++;
    } else {
      result.push(serie[0] );
    }
    
    length = Math.min(length, factor.length);
    let value;
    for (; k < length; k++) {
      value = result[k - 1] * factor[k] + (!add || !factor[k] ? 0 :(add[k] - add[k - 1]))
      if (isNaN(value)) {
        value = result[k - 1];
      }
      result.push(value);
    }
    return result;
  },
  getIncreasedData(initial, factor, add, length) {
    const result = [];
    result.push(initial);
    length = Math.min(length, (add?.length || 0));
    const temp = Math.pow(factor, 1 / 365);
    for (let k = 1; k < length; k++) {
      result.push((result[k - 1] + (!add ? 0 :(add[k] - add[k - 1]))) * temp);
    }
    return result
  },
  getPercentData(data, startIdx, endExcl) {
    const result = [];
    endExcl = endExcl || data.length;
    for (let k = 0, factor = null, value = null; k < endExcl; k++) {
      if (k >= startIdx) {
        value = data[k];
        if ( k >= 0 && !factor && value ) {
          factor = value;
          if (factor) {
            value = 100;
          }
        } else if ( k > 0 && factor) {
          value = value * 100 / factor;
        }
      }
      result.push(value);
    }
    return result;
  },
  reduceData(data, xType, xlabels) {
    if (xType === 0) {
      return data;
    }
    const result = [];
    if ( xType === 1 ) {
      if (xlabels === undefined) {
        for (let k = 0; k < data.length; k = k + 7) {
          result.push(data[k]);
        }
      } else {
        let woche = dayjs(xlabels[0]).isoWeek(), w;
        let value = data?.[0] || null;
        let cnt = value === null ? 0 : 1;
        for (let k = 1; k < data?.length; k++) {
          w = dayjs(xlabels[k]).isoWeek()
          if (w === woche) {
            if (data[k] !== null) {
              cnt++;
              value = value === null ? data[k] : value + data[k];
            }
          } else {
            result.push(value/cnt);
            value = data[k];
            cnt = value === null ? 0 : 1;
            woche = w;
          }
        }
        result.push(value/cnt);
      }
    } else if ( xType === 2 ) {
      if (xlabels === undefined) {
        let dt = new Date(data[0]);
        result.push(data[0]);
        dt = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
        while (dt.getTime() < data[data.length - 1]) {
          result.push(dt.getTime());
          dt = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
        }
      } else {
        let monat = new Date(xlabels[0]).getMonth(), m;
        let value = data[0];
        let cnt = value === null ? 0 : 1;
        for (let k = 1; k < data.length; k++) {
          m = new Date(xlabels[k]).getMonth();
          if (m === monat) {
            if (data[k] !== null) {
              cnt++;
              value = value === null ? data[k] : value + data[k];
            }
          } else {
            result.push( value === null ? null : value/cnt);
            value = data[k];
            cnt = value === null ? 0 : 1;
            monat = m;
          }
        }
        result.push(value/cnt);
      }
    }
    return result;
  },
  calcIRR( beginnWert,  endWert, gewichtungsfaktor, cashFlows) {
    endWert = endWert || 0;
		let resultR1 = 0;
		let resultR2 = 0;
		let resultRi = 0;
		let endWertTmp1 = 0;
		let endWertTmp2 = 0;
		let counter = 0;
		let repeat = true;
		do {
			let einbezahlteKapital = 0;
			let abgezinsteCashflows = 0;
			let gewichtung = 0;
			for (let i = 0; i < gewichtungsfaktor.length; i++) {
				abgezinsteCashflows += cashFlows[i] * Math.pow((1 + resultRi), gewichtungsfaktor[i]);
				einbezahlteKapital += cashFlows[i];
				gewichtung += cashFlows[i] * gewichtungsfaktor[i];
			}
			endWertTmp2 = beginnWert * (1 + resultRi) + abgezinsteCashflows;

			repeat = Math.abs(endWert - endWertTmp2) / endWert * 100 > 0.001 && counter < 50;

			if (resultR2 === 0) {
				resultR2 = (endWert - beginnWert - einbezahlteKapital) / (beginnWert + gewichtung);
				if (resultR2 < -1) {
					resultR2 = -1;
				}
				resultRi = resultR2;
				endWertTmp1 = endWertTmp2;
			} else if (repeat) {
        if ( ((endWert - endWertTmp2) - (endWert - endWertTmp1)) == 0 ) {
          break;
        }
				resultRi = resultR1 -
						((endWert - endWertTmp1)
								/ ((endWert - endWertTmp2) - (endWert - endWertTmp1)))
								* (resultR2 - resultR1);
				
				resultR1 = resultR2;
				resultR2 = resultRi;
				endWertTmp1 = endWertTmp2;
			}
			counter++;
		} while (repeat);

		return resultRi * 100;
	},
  getWeIntervall(series, xLabels ) {
    let von = null;
    let bis = null;
    let i = 0;
    for (i = 0; i < series.length; i++) {
      if (series[i] !== null && series[i] !== 0) {
        von = i;
        break;
      }
    }
    for (i = series.length - 1; i >= 0; i--) {
      if (series[i] !== null && series[i] !== 0) {
        if (i < series.length - 1 && series[i+1] == 0) {
          bis = i + 1;
        } else {
          bis = i;
        }
        break;
      }
    }
    if (von === null || bis === null) {
      return null;
    } 

    const years = !xLabels?.length ? 1 : (xLabels[bis] - xLabels[von]) / (3600 * 24000 * 365) ;
    return {von, bis, years}
  },
  getKapitalgewichteteWertentwicklung(series, anlagesumme, xLabels, kind, anlagesummeAlsBeginnwert ) {
    const intervall = this.getWeIntervall(series, xLabels );
    if (intervall == null) {
      return [null, null];
    }
    const {von, bis, years} = intervall;
    if (kind === 'funds_course' || kind === 'performance' ) {
			return [(100 * (series[bis] - series[von]) / series[von]), years] ;
		}
    let gewichtungsfaktor = [];
    let cashFlows =  [];
    let cashFlow = null;
    for (let i = von; i <= bis; i++) {
      let cashFlowTmp = anlagesumme[i];

      if (cashFlow === null || cashFlow != cashFlowTmp) {
        if (cashFlow != null) {
          cashFlows.push(cashFlowTmp - (cashFlow || 0));
          gewichtungsfaktor.push( ( xLabels[bis] -  xLabels[i]) / (xLabels[bis]  - xLabels[von]  ));
        }
        cashFlow = cashFlowTmp;
      }
    }
    anlagesummeAlsBeginnwert = anlagesumme[von] != null && anlagesummeAlsBeginnwert;
    const we = this.calcIRR((!anlagesummeAlsBeginnwert ? series[von] : anlagesumme[von]), series[bis], gewichtungsfaktor, cashFlows);
    return [we, years];
  },
  getZeitgewichteteWertentwicklung(series, cashflows, xLabels, kind ) {
    const intervall = this.getWeIntervall(series, xLabels );
    if (intervall == null) {
      return [null, null];
    }
    const {von, bis, years} = intervall;
    if (kind === 'funds_course' || kind === 'performance' || !cashflows?.length ) {
			return [(100 * (series[bis] - series[von]) / series[von]), years] ;
		}
    const offset = 0 ; // (new Date()).getTimezoneOffset() * 60000;
    let lastValueIdx = series.length;
    for (let i = series.length - 1; i >= 0; i--) {
      if (series[i]) {
        lastValueIdx = i;
        break;
      }
    }
    cashflows = cashflows.filter(item => item.time - offset >= xLabels[von] && item.time - offset <= xLabels[bis] )
    if (!cashflows.length) {
      return [(100 * (series[bis] - series[von]) / series[von]), years] ;
    }
    if ( cashflows[0].time - offset != xLabels[von]) {
      cashflows.unshift({datum: dayjs(xLabels[von]).format('DD.MM.YYYY'), wert: 0, time: (xLabels[von] + offset) })
    }
    let cashflow, ix0, ix1 = cashflows.length - 1;
    if ( cashflows[ix1].time - offset != xLabels[bis]) {
      cashflows.push({datum: dayjs(xLabels[bis]).format('DD.MM.YYYY'), wert: 0, time: (xLabels[bis] + offset) })
    }
    let we = 1;
    for (let k = 1; k < cashflows.length; k++) {
      ix0 = xLabels.findIndex( x => x === cashflows[k - 1].time  - offset);
      ix1 = xLabels.findIndex( x => x === cashflows[k].time  - offset);
      if (ix1 > lastValueIdx) {
        cashflow = 0;
        ix1 = lastValueIdx
      } else {
        cashflow = cashflows[k].wert;
      }
      if (ix0 != -1 && ix1 != -1 && series[ix0] ) {
        we *= (series[ix1] - cashflow)/series[ix0];
      } else {
        if (process.env.VUE_APP_USER === 'hannes') console.log( k, cashflows[k].datum, 'ix0',ix0, ix1, (ix0 != -1) && series[ix0], (ix0 != -1)&& dayjs(xLabels[ix0]).format('DD.MM.YYYY'), (ix1 != -1)&& dayjs(xLabels[ix1]).format('DD.MM.YYYY') )
      }
    }
    return [(we - 1)*100, years];
  },
  calcVola: (data, anlageData, kind) => {
    const result = [];
    if ( data.length ) {
      let lastValue = null, value = 0, investment = (anlageData ? anlageData[0] : 0), sum = 0, cr = 0;
      const len = kind === 'aggregate' ? data.length -1 : data.length ;
      for (let ixX=0; ixX < len; ixX++) {
        value = data[ixX];
        if ( value != null ) {
          if ( lastValue === null) {
            lastValue = value;
            investment = (anlageData ? anlageData[ixX] : 0)
          } else {
            if ( anlageData && investment !== anlageData[ixX] ) {
              cr = 100 * Math.log((value - (anlageData[ixX] - investment)) / lastValue);
              investment = anlageData[ixX];
            } else {
              cr = 100 * Math.log(value / lastValue);
            }
            if (isNaN(cr) || cr == Infinity || cr == -Infinity) {
              cr = 0;
            } else {
              result.push(cr);
            }
            lastValue = value;
            sum += cr;
          }
        }
      }
      const d = sum / result.length;
      sum = 0;
      result.forEach( r => {
        sum = sum + (r-d)*(r-d);
      })
      if (result.length > 1) {
        sum = sum / (result.length - 1);
        const v = Math.sqrt(sum * 257 / result.length) * Math.sqrt(result.length);
        return isNaN(v) ? 0 : v;
      }
    }
    return 0;
  },
  arSlice(data, von, bis) {
    if (von < 0) {
      von = 0;
    }
    if (bis > data.length) {
      bis = data.length;
    }
    if (von === 0 && bis === data.length) {
      return data;
    }
    if (bis === data.length) {
      return data.slice(von);
    }
    return data.slice(von, bis);
  },
  calcPerformanceChart(config, anlagesummeAlsBeginnwert) {
    const anlagesumme = config.anlage, xLabels = config.xLabel;
    let series = config.aktWert;
    if (config.type === 'derived') {
      series = this.getDerivedData(config.aktData, series, anlagesumme, series.length);
    }
    const startIndex = config.startIndex;

    const intervall = this.getWeIntervall(series, xLabels );
    if (intervall == null) {
      return [null, null];
    }

    const performance = []
    for (let k = 0; k < series.length; k++) {
      if ( k < startIndex) {
        performance.push(null);
        continue;
      }
      let von = startIndex;
      let bis = k > intervall.bis ? intervall.bis : k;
      let gewichtungsfaktor = [];
      let cashFlows =  [];
      let cashFlow = null;
      for (let i = von; i <= bis; i++) {
        let cashFlowTmp = anlagesumme[i];

        if (cashFlow === null || cashFlow != cashFlowTmp) {
          if (cashFlow != null) {
            cashFlows.push(cashFlowTmp - (cashFlow || 0));
            gewichtungsfaktor.push( ( xLabels[bis] -  xLabels[i]) / (xLabels[bis]  - xLabels[von] ));
          }
          cashFlow = cashFlowTmp;
        }
      }
      anlagesummeAlsBeginnwert = anlagesumme[von] != null && startIndex == 0 && anlagesummeAlsBeginnwert;
      const we = this.calcIRR((!anlagesummeAlsBeginnwert ? series[von] : anlagesumme[von]), series[bis], gewichtungsfaktor, cashFlows);
      performance.push(we);
    }
    return performance;
  },
  tooltipPerfChartRow(param, series) {
    if (param.seriesIndex != null && param.dataIndex != null ){
      const idx = param.dataIndex;
      const len = idx + 1;
      const serie = series[param.seriesIndex];
      let aktWert = serie.config.aktWert;
      aktWert = this.arSlice(aktWert, 0, len);
      const anlage = this.arSlice(serie.config.anlage, 0, len);   
      if (serie.config.type === 'derived') {
        const aktData = this.arSlice(serie.config.aktData, 0, len);   
        aktWert = this.getDerivedData(aktData, aktWert, anlage, aktWert.length);
      }
      const we = serie.data[idx];
      const years = !serie.config.xLabel?.length ? 1 : (serie.config.xLabel[idx] - serie.config.xLabel[0]) / (3600 * 24000 * 365) ;
      const wePA = preformancePerAnno(years, we);
      const vola = this.calcVola(aktWert, anlage, 'aggregate');
            
      return (param.seriesIndex != 0 ? '' : '<div><span></span><span>Bezeichnung &nbsp;</span><span>We. gesamt &nbsp;</span><span>We. p.a. &nbsp;</span><span>Volatilität</span></div>')
      + `<div><span>${param.marker}</span><span>${param.seriesName} &nbsp;</span><span>${formatNumber(we, 2)} % &nbsp;</span><span>${formatNumber(wePA, 2)} % &nbsp;</span><span>${formatNumber(vola, 2)} %</span></div>`;
    }
  },
  tooltipDetailsPerformance(param) {
    const { marker, seriesName, value } = param;
    const formattedValue = formatNumber((Array.isArray(value) ? value[1] : value) - 100, 2) + ' %';
    return `<div><span>${marker}</span> <span>${seriesName}:</span> <b>${formattedValue}</b></div>`;
  },
  getMaxSerie(series) {
    const data = [];
    let value;
    for (let i = 0; i < series[0]?.data?.length; i++) {
      value = 0;
      for (let k = 0; k < series.length; k++) {
        value = Math.max(value, series[k].data[i]);
      }
      data.push(value);
    }
    return [{config : series[0]?.config, data: data, name: ''}];
  },
  getSeries(kind, source, startIdx, endExcl, xType, xlabels, session ) {
    const series = source.data.series;
    const result = [];
    let aktuelSerie = null;
    let aktuelSerie2 = null;
    let anlageSerie = null;
    let anlageSerie2 = null;
    let idx = 0;
    if (kind === 'aggregate') {
      idx = 1 // start with anlagesumme
    }
    let smooth = false;
    
    while(idx < series.length ) {
      const serie = series[idx];
      if (kind === 'aggregate' && idx === 1) {
        idx = 0;
      } else if (kind === 'aggregate' && idx === 0) {
        idx = 2;
      } else {
        idx++;
      }
      if (serie.config.visible === undefined) {
        serie.config.visible = true;
      }
      if (serie.config.color && serie.config.color.charAt(0) === '#') {
        const c = serie.config.color.substr(1);
        if (c.length === 6) {
          serie.config.color = 'rgb(' + parseInt(c.substr(0,2), 16) + ',' + parseInt(c.substr(2,2), 16) + ',' + parseInt(c.substr(4,2), 16) + ')';
        } else if (c.length === 3) {
          serie.config.color = 'rgb(' + parseInt(c.substr(0,1), 16) + ',' + parseInt(c.substr(1,1), 16) + ',' + parseInt(c.substr(2,1), 16) + ')';
        } else if (c.length === 8) {
          serie.config.color = 'rgb(' + parseInt(c.substr(0,2), 16) + ',' + parseInt(c.substr(2,2), 16) + ',' + parseInt(c.substr(4,2), 16) + ')';
        } 
        
      }
      if (serie.data2 && serie.data) {
        smooth = session.smooth;
      } else {
        smooth = false;
      }
      const serieData = smooth ? serie.data : (serie.data2 || serie.data)
      let calcSerie = null;
      if (kind === 'aggregate') { // Grafischer Verlauf
        if (serie.config.type !== 'value' && (serie.name === 'Anlagesumme') ) {
          serie.config.type = 'value';
          serie.config.hidden = true;
        }
        if (serie.config.type === 'value') {
          calcSerie = {
            name: serie.name,
            config: {...serie.config, abAnfang: startIdx===0 && !session.grafNachTransakt },
            data: this.arSlice(serieData, startIdx, endExcl),
            data2: this.arSlice((serie.data2 || serie.data), startIdx, endExcl),
          };
        } else if (serie.config.type === 'derived') {
          try {
            calcSerie = {
              name: serie.name,
              config: {...serie.config, abAnfang: false },
              data2: this.getDerivedData(aktuelSerie2, this.arSlice((serie.data2 || serie.data), startIdx, endExcl), anlageSerie2, endExcl-startIdx)
            };
            calcSerie.data = [];
            let lastValue = calcSerie.data2[0];
            for (let j = 0; j < calcSerie.data2.length; j++) {
              if ( anlageSerie[j] === anlageSerie2[j] ) {
                lastValue = calcSerie.data2[j];
              }
              calcSerie.data.push(lastValue); 
            }
          } catch (e) { console.error(e); }
        } else if (serie.config.type === 'increased') {
          try { 
            calcSerie = {
              name: serie.name,
              config: {...serie.config, abAnfang: false },
              data: this.getIncreasedData(aktuelSerie[0], serie.config.factor, anlageSerie, endExcl-startIdx)
            };
          } catch (e) { console.error(e); }
        }
      } else if (kind === 'perf_chart') {
        const abAnfang = startIdx===0 && !session.grafNachTransakt;
        const configSerie = {...serie.config, abAnfang};
        configSerie.tooltipRow = (param, series) => { return this.tooltipPerfChartRow(param, series); };
        let data = serie.data;
        configSerie.anlage = this.arSlice(configSerie.anlage, startIdx, endExcl);
        configSerie.xLabel = this.arSlice(configSerie.xLabel, startIdx, endExcl);
        configSerie.aktWert = this.arSlice(configSerie.aktWert, startIdx, endExcl);
        if (startIdx) {
          if (configSerie.aktData?.length) {
            configSerie.aktData = this.arSlice(configSerie.aktData, startIdx, endExcl);
          }
          data = utils.calcPerformanceChart(configSerie, abAnfang);
        } else if (endExcl !== serie.data.length) {
          data = this.arSlice(data, startIdx, endExcl);
        }
        calcSerie = {
          name: serie.name,
          config: configSerie,
          data: data,
        };
      } else {
        const configSerie = {...serie.config }
        configSerie.pos_value = this.arSlice(serieData, startIdx, endExcl);
        configSerie.funds_course = this.arSlice(serie.funds_course, startIdx, endExcl);
        configSerie.performance = this.getPercentData(this.arSlice(serie.funds_course, startIdx, endExcl), 0);
        if (kind === 'pos_value') { // Depopositionen Wertentwicklung
          try {
            const abAnfang = serie.config.anlagesummeAlsBeginnwert != null  ? serie.config.anlagesummeAlsBeginnwert  : startIdx===0 || serieData[startIdx - 1] === null;
            calcSerie = {
              name: serie.name,
              config: {...configSerie, abAnfang },
              data: this.arSlice(serieData, startIdx, endExcl),
              anlageData: this.arSlice(serie.investment, startIdx, endExcl),
            };
          } catch (e) { console.error(e); }
        } else if (kind === 'funds_course') { // Depopositionen Kursentwicklung
          try {
            calcSerie = {
              name: serie.name,
              config: {...configSerie, abAnfang: false },
              data: this.arSlice(serie.funds_course, startIdx, endExcl),
              value: this.arSlice(serieData, startIdx, endExcl),
              anlageData: this.arSlice(serie.investment, startIdx, endExcl),
            };
          } catch (e) { console.error(e); }
        } else if (kind === 'performance') { // Depopositionen Kursperformance
          try {
            calcSerie = {
              name: serie.name,
              config: {...configSerie, abAnfang: false, tooltipRow: this.tooltipDetailsPerformance },
              data: this.getPercentData(this.arSlice(serie.funds_course, startIdx, endExcl), 0),
              value: this.arSlice(serieData, startIdx, endExcl),
              anlageData: this.arSlice(serie.investment, startIdx, endExcl),
            };
          } catch (e) { console.error(e); }
        }
      }
      if (null != calcSerie) {
        if (kind === 'aggregate') {
          if (calcSerie.name === 'Aktueller Wert') {
            aktuelSerie = calcSerie.data;
            aktuelSerie2 = calcSerie.data2;
            calcSerie.anlageData = anlageSerie;
            calcSerie.anlageData2 = anlageSerie2;
          } else if (calcSerie.name === 'Anlagesumme') {
            anlageSerie =  calcSerie.data;
            anlageSerie2 = calcSerie.data2;
          } else  {
            calcSerie.anlageData = anlageSerie;
            calcSerie.anlageData2 = anlageSerie2;
          }
        }
        if (process.env.VUE_APP_USER === 'hannes') console.log('calcSerie', {calcSerie: JSON.parse(JSON.stringify(calcSerie))}, {serie: JSON.parse(JSON.stringify(serie))});
        if (calcSerie.anlageData && calcSerie.anlageData.length || kind === 'funds_course' || kind === 'performance' ) {
          if (kind === 'funds_course' || kind === 'performance' ) {
            if (calcSerie.config.kapitalGewichtet) {
              calcSerie.perf = this.getKapitalgewichteteWertentwicklung(calcSerie.data, null, xlabels, kind, false );
            } else {
              calcSerie.perf = this.getZeitgewichteteWertentwicklung(calcSerie.data, null, xlabels, kind );
            }
            calcSerie.vola = this.calcVola(calcSerie.data, null, kind);
            //
          } else {
            const anlagesummeAlsBeginnwert = calcSerie.config.abAnfang && kind === 'pos_value' ? startIdx <= calcSerie.config.firstIndexAnlage : calcSerie.config.abAnfang;
            const data = calcSerie.data2 || calcSerie.data;
            const anlageData = calcSerie.anlageData2 || calcSerie.anlageData;
            calcSerie.config.data = data;
            calcSerie.config.anlage = anlageData;
            if (calcSerie.config.kapitalGewichtet) {
                calcSerie.perf = this.getKapitalgewichteteWertentwicklung(data, anlageData, xlabels, kind, anlagesummeAlsBeginnwert);
            } else {
              const cashFlows = kind === 'pos_value' 
                  ? source.anlageSummeNetto.filter(a => 0 == calcSerie.name.indexOf(a.fond))
                  : source.anlageSummeNetto.filter(a => a.fond == 'ANLAGESUMMENETTO');
              calcSerie.perf = this.getZeitgewichteteWertentwicklung(data, cashFlows, xlabels, kind );
            }
            calcSerie.vola = this.calcVola(data, anlageData, kind);
          }
          let name2 = '';
          let we = '';
          if (calcSerie.perf?.length) {
            we = calcSerie.perf?.length ? calcSerie.perf[0] : 0;
            if (session.performancePA) {
              we = preformancePerAnno(calcSerie.perf[1], we);
              calcSerie.config.we = we;
              we = '* Wertentw. p.a. ' + formatNumber(we, 2);
            } else {
              calcSerie.config.we = we;
              if ( calcSerie.name === 'Aktueller Wert') {
                we = '* Wertentwicklung (gesamt) ' + formatNumber(we, 2);
              } else {
                we = '* Wertentw. ' + formatNumber(we, 2);
              }
            }
            calcSerie.config.vola = calcSerie.vola;
            name2 = we + ' % | Vola.: ' + formatNumber(calcSerie.vola, 2) + ' %';
          }
          if (kind === 'aggregate' && calcSerie.name === 'Aktueller Wert') {
            result.unshift({
              name: calcSerie.name,
              name2: name2,
              config: calcSerie.config,
              data: this.reduceData(calcSerie.data, xType, xlabels),
            })
          } else {
            // const anlageData = kind === 'pos_value' ? calcSerie.anlageData : null;
            result.push({
              name: calcSerie.name,
              name2: name2,
              config: calcSerie.config,
              data: this.reduceData(calcSerie.data, xType, xlabels),
              value: calcSerie.value ? this.reduceData(calcSerie.value, xType, xlabels) : null,
              anlageData: calcSerie.anlageData
            });
          }
        } else if (!calcSerie.config.hidden){
          if (kind === 'perf_chart') {
            result.push({
              name: calcSerie.name,
              name2: '',
              config: calcSerie.config,
              data: this.reduceData(calcSerie.data, xType, xlabels),
              id: 'perf_chart_' + calcSerie.name.toLowerCase().replace(/ /g, '_'),
            });
          } else {
            result.push({
              name: calcSerie.name,
              name2: '',
              config: calcSerie.config,
              data: this.reduceData(calcSerie.data, xType, xlabels)
            });
          }
        }
      }
    }
    return { series: result }
  },
  getXLabel(source) {
    const xlabels = [];
    let time = source.aggregate.options.endDate;
    let idx = source.aggregate.options.len;
    while (idx > 0) {
      xlabels.unshift(time - offsetTZ);
      idx--;
      time -= 86400000;
    }
    return xlabels;
  },
  refreshIntervall(source, session, startTime, endTime) {
    if (!source.data) {
      if (process.env.VUE_APP_USER === 'hannes') console.log('refreshIntervall error',{source: JSON.parse(JSON.stringify(source)), session: JSON.parse(JSON.stringify(session))});
      return;
    }
    const kind = session.kind;
    let ixStart = null, ixEnd = null;
    const xlabels = [];
    let time = source.data.options.endDate;
    let idx = source.data.options.len - 1;
    while (idx >= 0) {
      if (ixEnd===null && time <= endTime) {
        ixEnd = idx + 1; // end exclusive
      }
      if (ixEnd !== null) {
        xlabels.unshift(time - offsetTZ);
      }
      if (ixStart===null && time < startTime) {
        ixStart = idx;
        break;
      }
      idx--;
      time -= 86400000;
    }
    if (ixStart === null) {
      ixStart = 0;
    }
    if (process.env.VUE_APP_USER === 'hannes') console.log('refreshIntervall', kind, ixStart, ixEnd, {source: JSON.parse(JSON.stringify(source)), session: JSON.parse(JSON.stringify(session))});
    
    let xType = 0; // no reduce data for charts
    session.xType = xType;
    const result = this.getSeries(kind, source, ixStart, ixEnd, xType, xlabels, session);
    session.colors = result.series.map( s => s.config.color);
    session.series = result.series;
    console.log(result)
    Vue.set(session, 'xLabels', this.reduceData(xlabels, xType));
  },
}