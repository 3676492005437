<template>
  <div :style="iconContainerStyle">
    <span
      class="soscomp-icons-font"
      :style="iconStyle"
      v-html="iconElement">
    </span>
  </div>
</template>

<script>

export default {
  props: {
    size: {
      default: 32,
      type: Number
    },
    icon: {
      type: String,
      required: true,
    }
  },
  computed: {
    sizePx() {
      return this.size + 'px';
    },
    iconContainerStyle() {
      return { 
        width: this.sizePx, 
        height: this.sizePx, 
      }
    },
    iconStyle() {
      return {
        'font-size': this.sizePx, 
        'line-height': this.sizePx,
        'vertical-align': 'top',
        width: this.sizePx, 
        height: this.sizePx,
      }
    },
    iconElement() {
      switch (this.icon) {
        case 'AUTH-FACE':
        case 'FACE-ID':
          return '&#xE575;';

        case 'AUTH-FINGERPRINT':
        case 'TOUCH-ID':
          return '&#xE90D;';

        default:
          return '';
      }
    }
  }
}
</script>

<style scoped>



</style>