var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-24px" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Aktivierung Ihres Zugangs")
        ]),
        _c("p", [_vm._v("Vielen Dank für Ihre Registrierung.")]),
        _c("p", [
          _vm._v(
            "in Kürze erhalten Sie von uns eine E-Mail und eine SMS mit je einem Aktivierungscode. Die E-Mail enthält außerdem Ihre Kundennummer, diese entspricht Ihrem Loginnamen."
          )
        ]),
        _c("p", [
          _vm._v(
            "Falls Sie die E-Mail nicht erhalten, prüfen Sie Ihren Spam-Ordner bzw. wenden sich an Ihren Betreuer."
          )
        ]),
        _c("InputField", {
          attrs: {
            label: "Login / Kunden&shy;nummer",
            disabled: _vm.lockLoginName
          },
          model: {
            value: _vm.loginName,
            callback: function($$v) {
              _vm.loginName = $$v
            },
            expression: "loginName"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Sicherheits&shy;code aus <nobr>E-Mail</nobr>",
            disabled: _vm.lockEmailToken
          },
          model: {
            value: _vm.emailToken,
            callback: function($$v) {
              _vm.emailToken = $$v
            },
            expression: "emailToken"
          }
        }),
        _c("InputField", {
          attrs: { label: "SMS Code" },
          model: {
            value: _vm.smsToken,
            callback: function($$v) {
              _vm.smsToken = $$v
            },
            expression: "smsToken"
          }
        }),
        _c(
          "div",
          [
            _c(
              "BaseButton",
              {
                attrs: {
                  disabled:
                    _vm.loading ||
                    !_vm.loginName ||
                    !_vm.emailToken ||
                    !_vm.smsToken
                },
                on: {
                  click: function($event) {
                    return _vm.activateAccount()
                  }
                }
              },
              [_vm._v("Zugang aktivieren")]
            ),
            _c(
              "BaseButton",
              {
                attrs: { disabled: _vm.loading, isSecondary: "" },
                on: {
                  click: function($event) {
                    return _vm.resendTokens()
                  }
                }
              },
              [_vm._v("Sicherheitscode erneut senden")]
            ),
            _c(
              "BaseButton",
              {
                attrs: { isSecondary: "" },
                on: {
                  click: function($event) {
                    return _vm.goToLogin()
                  }
                }
              },
              [_vm._v("Zurück zum Login")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }