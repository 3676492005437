<template>
  <div class="box__container chart-container">
    <div v-if="showTitle" class="box__title">
      {{ title }}
      <a v-if="chart.type == 'SFDRChart' || chart.type == 'TaxonomieChart' || chart.type == 'PaiChart'" class="btn btn-clean clickable" @click="infoModal(chart.type)">
        <PhInfo :size="20" class="ml-1"/>
      </a>
    </div>
    <div class="row">
      <div class="col-12" :class="{'col-xl-6' : !chart.barChart}">
        <template v-if="chart.type == 'PaiChart'">
          <GaugeChartMultiple
            :chartData="getEChartData(chart)"
            format="percent"
            height="350px" 
            :isTooltip="false"
          />P
        </template>
        <template v-else-if="chart.type == 'SFDRChart' || chart.type == 'TaxonomieChart'">
          <PieChart
            v-if="!chart.barChart"
            :chartData="getEChartData(chart)" 
            :customColors="getESGCustomColors()"
            format="percent"
            height="350px" 
          />
        </template>
        <template v-else-if="chart.type !== 'ChartDistributionStates'">
          <PieChart
            v-if="!chart.barChart"
            :chartData="getEChartData(chart)" 
            format="percent"
            height="350px" 
          />
          <BarChart
            v-else
            :chartData="getEChartData(chart)" 
            :axisLabelFormatterShowOption="getAxisLabelFormatterShowOption(chart)"
            format="percent" 
            height="400px" 
          />
        </template>
        <template v-else>
          <MapChart
            :chartData="getEChartData(chart)" 
          />
        </template>
      </div>

      <template v-if="chart.type !== 'ChartDistributionStates'">
        <div class="col-12 col-xl-6 d-flex chart-label" v-if="!chart.barChart">
          <div class="row">
            <div class="col-12 d-flex chart-label chart-label-dot" v-for="(label, index) of getChartLegends(chart).slice(0,5)" :key="index">
              <PhCircle size="16" weight="fill" :color="getColor(index)" />
              <span>{{label}}</span>
            </div>
            <div v-if="showMore">
              <div class="col-12 d-flex chart-label chart-label-dot" v-for="(label, index) of getChartLegends(chart).slice(5)" :key="index + 5">
                <PhCircle size="16" weight="fill" :color="getColor(index + 5)" />
                <span>{{label}}</span>
              </div>
            </div>
            <div class="col-12 d-flex chart-label clickable" v-if="getChartLegends(chart).length > 5">
              <a @click="toggleMoreLabels()">{{ showMore ? 'Weniger' : 'Mehr' }}</a>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-12 col-xl-6 d-flex chart-label" v-if="!chart.barChart">
          <div class="row">
            <div class="col-12 d-flex chart-label chart-label-dot" v-for="(label, index) of getChartLegends(chart).slice(0,5)" :key="index">
              <PhCircle size="16" weight="fill" :color="getColorMonochrom(index, label)" :class="{'empty-circle': getColorMonochrom(index, label) == '#ffffff'}" />
              <span>{{label}}</span>
            </div>
            <div v-if="showMore">
              <div class="col-12 d-flex chart-label chart-label-dot" v-for="(label, index) of getChartLegends(chart).slice(5)" :key="index + 5">
                <PhCircle size="16" weight="fill" :color="getColorMonochrom(index + 5, label)" :class="{'empty-circle': getColorMonochrom(index, label) == '#ffffff'}" />
                <span>{{label}}</span>
              </div>
            </div>
            <div class="col-12 d-flex chart-label clickable" v-if="getChartLegends(chart).length > 5">
              <a @click="toggleMoreLabels()">{{ showMore ? 'Weniger' : 'Mehr' }}</a>
            </div>
          </div>
        </div>
      </template>
    </div>
    <BaseModal ref="infoModalRef" :showCancelButton="false" labelButtonConfirm="Ok">
      <template v-slot:modalTitle>
        <span v-html="title"></span>
      </template>
      <span v-html="infoModalText"></span>
    </BaseModal>
  </div>
</template>

<script>
import PieChart from '@/components/charts/echarts/PieChart.vue';
import BarChart from '@/components/charts/echarts/BarChart.vue';
import MapChart from '@/components/charts/echarts/MapChart.vue';
import GaugeChartMultiple from '@/components/charts/echarts/GaugeChartMultiple.vue';
import {countriesMap} from '@/components/charts/echarts/map-helpers/countries_de.js'
import { PhCircle, PhInfo } from 'phosphor-vue';
import BaseModal from '@/components/core/BaseModal.vue';

import screenSize from '@/mixins/screen-size';
import { getColorsMixedContrastDecreasing, getColorLimitedListBasedOnLightingDecreasing } from "@/helpers/colors-helper.js";
import { MAP_CHART_TYPE_TO_TITLE } from './types';

import * as echarts from 'echarts';
import axios from 'axios';
import { mapColors } from '../charts/echarts/map-helpers/map';

export default {
  mixins: [screenSize],
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    chart: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tableDataNew: null,
      horizontalPie: true,
      pieChart: [],
      showMore: false,
      infoModalRef: null,
      infoModalText: '',
    };
  },
  computed: {
    title() {
      return MAP_CHART_TYPE_TO_TITLE[this.chart?.type] || this.chart?.type || '';
    },
    chartColors() {
      return [...getColorsMixedContrastDecreasing()]
    },
  },
  watch: {
    horizontalPie: 'setPieOptions',
  },
  methods: {
    getChartData(chart) {
      let resultData = [];
      if (chart.properties) {
        chart.properties.map(val => {
          resultData.push({
            label: val.key,
            value: val.value,
          })
        })
      }
      return {
        name: '',
        data: resultData
      };
    },
    getEChartData(chart) {
      return (chart?.properties || []).map(val => ({
        name: val.key,
        value: val.value,
      }));
    },
    getESGCustomColors() {
      return [this.getColor(0), '#d4d4d4', '#d4d4d4', '#d4d4d4']
    },
    getChartLegends(chart){
      return chart?.properties?.filter(prop => prop.key)
        .map(prop => `${prop.key} (${prop.value && prop.value.toFixed(2).replace('.', ',')}%)`) || [];
    },
    initChart(apexChart) {
      if ( apexChart.type === 'pie') {
        this.pieChart.push(apexChart);
        this.setPieOption(apexChart);
      }
    },
    setPieOptions() {
      this.pieChart.forEach( pie => this.setPieOption(pie));
    },
    setPieOption(pie) {
        const opt = pie.type === 'pie'
          ? {
            chart: {
              width: 300,
              height: 300,
            }
          }
          : {
            chart: {
              width: null,
              height: null,
            }
          };
        pie.updateOptions( opt,
          false, // redrawPaths,
          true, // animate,
          false, // updateSyncedCharts)
        );
    },
    getColor(index) {
      if(index || index == 0) {
        const length = getColorsMixedContrastDecreasing().length;
        if(index < length) {
          return getColorsMixedContrastDecreasing()[index];
        }
        return getColorsMixedContrastDecreasing()[index % length];
      }
    },
    getColorMonochrom(index, label) {
      if (index || index == 0) {
        const colors = mapColors()
        const length = colors.length;
        const countries = countriesMap();
        const countryName = label.split(' (')
        if (!countries[countryName[0]]) {
          return '#ffffff';
        }
        if (index < length) {
          return colors[index];
        }
        return colors[index % length];
      }
    },
    toggleMoreLabels() {
      this.showMore = !this.showMore;
    },
    getAxisLabelFormatterShowOption(chart) {
      if (chart.type === 'ChartTopholdings') {
        return 2;
      }
      return 'short';
    },
    infoModal(type) {
      this.infoModalText = '';
      switch (type) {
        case 'SFDRChart':
          this.infoModalText = 'Hierunter fallen Investitionen, die einen einfachen Beitrag zur Erreichung eines Umweltziels oder eines sozialen Ziels leisten. Es gibt keine festen gesetzlichen Kriterien, die messbar erfüllt sein müssen.'
          break;
        case 'TaxonomieChart':
          this.infoModalText = 'Hierunter fallen Investitionen, die einen wesentlichen Beitrag zur Erreichung mindestens eines Umweltziels leisten, welcher mit technischen Bewertungskriterien messbar ist. Die Taxonomieverordnung beschränkt sich bislang auf das E in ESG und nennt dabei die folgenden sechs Umweltziele: Klimaschutz, Anpassung an den Klimawandel, nachhaltige Nutzung von Wasser- und Meeresressourcen, Wandel zu einer Kreislaufwirtschaft, Vermeidung von Umweltverschmutzung, Schutz von Ökosystemen und Biodiversität.'
          break;
        case 'PaiChart':
          this.infoModalText = `Diese Kategorie basiert auf einem anderen Ansatz als die Kategorien A und B. Es wird keine aktive Nachhaltigkeitsstrategie verfolgt, also
                                nicht explizit Investitionen getätigt, die ein Nachhaltigkeitsziel positiv fördern. Vielmehr wird umgekehrt darauf geachtet, dass die wichtigsten nachteiligen Auswirkungen auf Nachhaltigkeitsfaktoren (also auf ESG-Aspekte) vermieden werden. Die PAIs lassen sich in fünf Obergruppen zusammenfassen, also Bereiche in denen nachteilige Nachhaltigkeitsauswirkungen einzeln oder kumulativ vermieden werden sollen: Treibhausgasemissionen, Wasserverschmutzung, Biodiversität, Abfälle, Soziales & Arbeitnehmerbelange (einschließlich Menschenrechte und Korruption).`
          break;
        default:
          break;
      }
      if (this.infoModalText && this.$refs.infoModalRef) {
        this.$refs.infoModalRef.open()
      }
    }
  },
  mounted() {
    this.horizontalPie = window.innerWidth > 600;
    this.$addResizeListener((event) => { this.horizontalPie = window.innerWidth > 600; })
    
  },
  components: {
    PhCircle,
    PhInfo,
    PieChart,
    BarChart,
    MapChart,
    GaugeChartMultiple,
    BaseModal,
  },
}
</script>

<style scoped>
.chart-container {
  align-content: stretch;
  flex-grow: 1;
}

.chart-item {
  display: flex;
  align-items: stretch;
}

.chart-label {
  align-items: center;
}
.chart-label-dot {
  align-items: flex-start;
}
.chart-label-dot svg {
  margin-top: 5px;
  margin-right: 7px;
  height: 16px;
  width: 15px;
  min-width: 15px;
  min-height: 16px;
}


body {
	background-color: var(--color-box);
	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px; 
	color: #000;
}

a:link, a:visited {
	color: #4682b4;
}

a:hover {
	color: #4169e1;
}

#main	{
	width: 1200px;
	height: 800px;
}
.chart-label-dot .empty-circle {
  border: 1px solid #cccccc;
  border-radius: 50%;
  height: 14px;
  width: 14px;
}
</style>
