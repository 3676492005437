<template>
<div>
	<BaseModal
		ref="addNewTagModal"
		modalTitle="Tag Liste"
		:autoClose="false"
		@onConfirmButton="submit()"
		@close="close()">

		<Table
			:headers="tagHeaders"
			:rows="tagsRows"
			:rowsPerPage="20"
			@action-DELETE="deleteTag"
		>
		</Table>

		<hr/>

		<div class="font-strong">
			Neue Tag hinzufügen
		</div>

		<InputField 
			label="Tag"
			v-model="newTagForm.description"/>

	</BaseModal>
</div>
</template>

<script>

import TAGS_TYPES from '@/store/tags/types'
import InputField from '../core/forms/InputField'
import BaseModal from '@/components/core/BaseModal.vue'
import Table from '@/components/table2/Table.vue'
import { TextColumn, SimpleAction, ActionColumn } from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';

export default {
	props: {
		tagsOption: {
			default: () => [],
		},
	},

	data() {
		return {
			newTagForm: {
				description: ''
			}
		}
	},

	computed: {
		...mapGetters({
			tags: TAGS_TYPES.GETTERS.TAGS,
		}),
		tagHeaders() {
			return {
				lockedLeft: [
					TextColumn("description", "Tag Bezeichnung").makeAlwaysVisible(),
				],
				lockedRight: [
					ActionColumn("actions", ""),
				],
			};
		},

		tagsRows() {
			return this.tags?.map(tag => {
				return {
					...tag,
					actions: [SimpleAction("DELETE", '', "Löschen")]
				}
			})
		}
	},

	methods: {
		close() {
      this.$emit("close")
    },

		deleteTag(tag) {
			this.$confirmModal({
				title: 'Tag löschen',
				message: `Wollen Sie die Tag <b>"${tag.description}"</b> wirklich löschen?`,
				labelButtonConfirm: 'Löschen'
			})
			.then(() => this.$store.dispatch(TAGS_TYPES.ACTIONS.REMOVE_TAG, { id: tag.id }));
		},

		async submit() {
			await this.$store.dispatch(TAGS_TYPES.ACTIONS.ADD_TAG, this.newTagForm)
			this.close();
		}
	},
	mounted() {
		this.$refs.addNewTagModal.open()
	},
	components: {
		BaseModal,
		InputField,
		Table,
	},

}
</script>

<style scoped>

</style>