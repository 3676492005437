<template>
  <div>
    <BaseModal 
      ref="editTransactionSplit"
      modalTitle="Buchung aufteilen"
      size="lg"
      :showDefaultButtons="false"
      @onCloseButton="close()"
      @onCancelButton="close()">

      <div class="row">
        <div class="col-6">
          <div class="text-muted">Konto</div>
          <div>{{accountName}}</div>
        </div>
        <div class="col-6">
          <div class="text-muted">Empfänger</div>
          <div>{{name}}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="text-muted">Verwendungszweck</div>
          <div>{{purpose}}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="text-muted">Buchung</div>
          <div>{{entryDate | date}}</div>
        </div>
        <div class="col-6">
          <div class="text-muted">Original Betrag</div>
          <div>{{ totalAmount | currency }}</div>
        </div>
      </div>

        <Table
            tableId="ba077a63-72bb-4bc2-901c-7377e3349676"
            :headers="headers"
            :rows="rows"
            rowId="categoryKey"
            :rowsPerPage="10"
            @action-REMOVE_SPLIT="removeSplit($event.index)"
        >
            <template v-slot:categoryKey="row">
                <ComboBox 
                    v-model="categorySplit[row.index].categoryKey" 
                    :values="categoriesComboBox">
                </ComboBox>
            </template>
            <template v-slot:amount="row">
                <InputField type="currency" :precision="2" v-model="categorySplit[row.index].amount"/>
            </template>
        </Table>
      
      <div class="row" v-if="errorMessage">
        <div class="col-12">
          {{ errorMessage }}
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <div class="row">
          <div class="col-auto verbleibend__label">
            <span>verbleibend: {{ amountLeft | currency }}</span>
          </div>
          <div class="col-auto">
            <BaseButton @click="addSplitTransaction()" isSecondary>
              Kategorie hinzufügen
            </BaseButton>
          </div>
        </div>
      </div>

      <template v-slot:footer>
        <BaseButton class="editsplits-button" @click="close()" isSecondary>
          Abbrechen
        </BaseButton>
        <BaseButton v-if="categorySplit.length === 0" class="editsplits-button" @click="submit()" :disabled="!originalSplit || !originalSplit.length">
          Aufteilung löschen
        </BaseButton>
        <BaseButton v-else class="editsplits-button" @click="submit()" :disabled="!!errorMessage">
          Speichern
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import BaseButton from '@/components/core/BaseButton'
import InputField from '@/components/core/forms/InputField'
import Table from "@/components/table2/Table.vue";
import {SlotColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue'
import { PhPlus } from 'phosphor-vue'
import ComboBox from '@/components/core/forms/ComboBox.vue'

export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    originalSplit: {
      type: Array,
      required: true,
    }
  },

  components: {
    BaseModal,
    BaseButton,
    InputField,
    Table,
    ComboBox,
    PhPlus,
  },

  data: function () {
    const categorySplit = [];
    if (this.originalSplit && this.originalSplit.length) {
      this.originalSplit.forEach(split => {
        categorySplit.push({
          categoryKey: split.categoryKey,
          amount: split.amount,
          customerComment: split.customerComment,
        })
      })
    } else {
      categorySplit.push({
        categoryKey: this.transaction.category.category,
        amount: this.transaction.amount,
      })
    }
    return {
      transactionId: this.transaction.id,
      accountName: this.transaction.account.displayName,
      name: this.transaction.name,
      purpose: this.transaction.purpose,
      entryDate: this.transaction.entryDate,
      categoryDisplayName: this.transaction.category.displayName,
      category: this.transaction.category.category,
      amount: this.transaction.amount,
      totalAmount: this.transaction.totalAmount,
      categorySplit: categorySplit,
        headers: {
            lockedLeft: [
                SlotColumn("categoryKey", "Kategorie"),
                SlotColumn("amount", "Betrag"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        },
    }
  },

  computed: {
    ...mapGetters({
      categoryTree: MULTIBANKING_TYPES.GETTERS.CATEGORY_TREE,
    }),
    categoriesComboBox() {
      if (!this.categoryFlat) {
        return []
      }

      return this.categoryFlat.map(cat => ({
        label: cat.displayName,
        value: cat.category,
        disabled: this.categoryTree.includes(cat)
      }))
    },
    categoryFlat() {
      let result = []
      this.categoryTree.forEach(parentCategory => {
        result.push(parentCategory)
        result = result.concat(parentCategory.children)
      })
      return result
    },
    errorMessage() {
      if (this.categorySplit.length === 0)
        return null
      if (this.categorySplit.length === 1) // && uniqueSplitTransactionNotAllowed
        return "Bitte wählen Sie eine weitere Kategorie!"
      const uniqueCategories = new Map()
      for (let i = 0; i < this.categorySplit.length; i++) {
        if (uniqueCategories.has(this.categorySplit[i].categoryKey))
          return "Die Kategorien müssen sich bei einer aufgeteilten Buchung unterscheiden!"
        uniqueCategories.set(this.categorySplit[i].categoryKey, true)
        if (!this.categorySplit[i].amount)
          return "Kategorien dürfen als Betrag nicht '0' haben!"
      }
      if (this.amountLeft != 0)
          return "Es gibt noch einen Restbetrag, der keiner Kategorie zugeordnet wurde."
      return null
    },
    amountLeft() {
      let remaining = this.totalAmount
      this.categorySplit.forEach(split => {
        if (!isNaN(split.amount))
          remaining -= split.amount
      })
      return remaining
    },
    rows() {
        if (!this.categorySplit)
            return [];
        console.log("calclating rows ", this.categorySplit.length);
        const actions = [
            SimpleAction("REMOVE_SPLIT", '', "Kategorie entfernen"),
        ];
        return this.categorySplit.map((split, index) => ({
            categoryKey: split.categoryKey,
            amount: split.amount,
            index,
            actions,
        }))
    },
  },

  methods: {
    removeSplit(index) {
        this.categorySplit.splice(index, 1);
    },
    addSplitTransaction(split) {
      this.categorySplit.push({
        categoryKey: this.category,
        amount: this.amountLeft,
      })
    },
    updateAmount(split, amountString) {
      let amount = parseFloat(amountString)
      if (amount > 0)
        split.amount = amount
    },
    submit() {
      this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.SAVE_TRANSACTION_SPLIT, {
        transactionId: this.transactionId,
        splits: this.categorySplit.length ? this.categorySplit : null,
      })
      this.close()
    },
    close() {
      this.$emit("close")
    },
  },

  mounted() {
    this.$refs.editTransactionSplit.open()
  }
}
</script>

<style scoped>
.editsplits-row {
  display: flex;
  flex-direction: row;
}
.editsplits-col {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 5px 0;
}
.editsplits-button {
  margin: 0 5px;
}
.editsplits-category-dropdown {
  -z-index: 99999;
}
.text-muted {
  color: #aaaaaa;
}

.verbleibend__label{
  align-self: center;
}
</style>