import dayjs from 'dayjs';
import { isDate } from '@/helpers/commonfunctions.js';

import { isRolesIncludes } from '@/router/guards';
import { VIEW_ROLES } from '@/router/roles';

const FILTERS_RANGE = ['paramSProdBeginn', 'paramSProdEnde', 'paramSProdBearbDatum', 'paramSProdBeitrag', 'paramSProdBezugSumme', 'paramSProdSollprovision'];

const DEFAULT_SEARCH_INPUT_KEY = 'defaultSearchInput';
const QUERY_KEY = 'paramQuery';

function isRangeFilter(key) {
  return FILTERS_RANGE.indexOf(key) >= 0;
}

export function prepareFilters(filters) {
  if(!filters) {
    return {};
  }

  return filters.reduce((result, f) => {
    const key = f.key;
    if(!key) {
      return result;
    }

    if(key === DEFAULT_SEARCH_INPUT_KEY) {
      result[QUERY_KEY] = f.value;
    } else if(isRangeFilter(key)) {
      const start = f.value[0];
      const end = f.value[1];
      result[`${key}Ab`] = isDate(start?.value) && dayjs(start?.value).format('DD.MM.YYYY') || start?.value;
      result[`${key}Bis`] = isDate(end?.value) && dayjs(end?.value).format('DD.MM.YYYY') || end?.value;
    } else {
      result[key] = f.value;
    }

    return result;
  }, {});
}

function allgemeinSearchGroup(data) {
  const allgemeinSearchGroup = {
    type: 'group',
    key: 'allgemein',
    label: 'Allgemein',
    menuItems: [],
  };

  if(isRolesIncludes([VIEW_ROLES.VIEW_INTERN])) {
    allgemeinSearchGroup.menuItems.push({
      type: 'text',
      label: 'Interne Nummer',
      key: 'paramVertragsNr',
    });

    allgemeinSearchGroup.menuItems.push({
      type: 'text',
      label: 'Maklernummer',
      key: 'paramMaklernr',
    });
    
    allgemeinSearchGroup.menuItems.push(
      {
        type: 'text',
        label: 'Kundennummer',
        key: 'paramKundennr',
      },
      {
        type: 'text',
        label: 'Kundenname',
        key: 'paramKundenname',
      },
      {
        type: 'text',
        label: 'Kundenvorname',
        key: 'paramKundenvorname',
      },
    );
  
  }

  if(isRolesIncludes([VIEW_ROLES.VIEW_BROKER])) {
    allgemeinSearchGroup.menuItems.push(
      {
        type: 'text',
        label: 'Kundennummer',
        key: 'paramKundennr',
        inputmode: 'numeric',
      },
      {
        type: 'text',
        label: 'Kundenname',
        key: 'paramKundenname',
      },
      {
        type: 'text',
        label: 'Kundenvorname',
        key: 'paramKundenvorname',
      },
    );

    allgemeinSearchGroup.menuItems.push({
      type: 'default',
      label: 'Inklusive Verträge der Struktur',
      key: 'mitStruktur',
    });      
  }

  allgemeinSearchGroup.menuItems.push({
    type: 'text',
    label: 'Produktnummer',
    key: 'paramSProdNr',
  });
  allgemeinSearchGroup.menuItems.push({
    type: 'text',
    label: 'Gesellschaft',
    key: 'paramGesellschaft',
  });
  allgemeinSearchGroup.menuItems.push({
    type: 'combobox',
    label: 'Produktart',
    key: 'paramSProdArt',
    comboOptions: [...data?.prokuktArt || []],
  });
  allgemeinSearchGroup.menuItems.push({
    type: 'text',
    label: 'Produktbezeichnung',
    key: 'paramSProdBez',
  });

  return allgemeinSearchGroup;
}

function datenSearchGroup(data, rights) {
  const datenSearchGroup = {
    type: 'group',
    key: 'daten',
    label: 'Daten',
    menuItems: [],
  };

  datenSearchGroup.menuItems.push({
    type: 'dateRange',
    label: 'Beginn',
    key: 'paramSProdBeginn',
  });
  datenSearchGroup.menuItems.push({
    type: 'dateRange',
    label: 'Ende',
    key: 'paramSProdEnde',
  });
  datenSearchGroup.menuItems.push({
    type: 'numberRange',
    label: 'Beitrag',
    key: 'paramSProdBeitrag',
  });
  datenSearchGroup.menuItems.push({
    type: 'numberRange',
    label: 'Bezugssumme',
    key: 'paramSProdBezugSumme',
  });
  if(rights?.allowReadBearbStatus) {
    datenSearchGroup.menuItems.push({
      type: 'combobox',
      label: 'Status',
      key: 'paramSProdBearbStatus',
      comboOptions: [...data?.bearbeitungsstatus || []],
    });
    datenSearchGroup.menuItems.push({
      type: 'dateRange',
      label: 'Datum Status',
      key: 'paramSProdBearbDatum',
    });
  }
  datenSearchGroup.menuItems.push({
    type: 'combobox',
    label: 'Zahlrhythmus',
    key: 'paramSProdZahlRhythmus',
    comboOptions: [...data?.zahlrhythmus || []],
  });
  datenSearchGroup.menuItems.push({
    type: 'text',
    label: 'Tarif',
    key: 'paramSProdTarif',
  });
  datenSearchGroup.menuItems.push({
    type: 'text',
    label: 'Bemerkung',
    key: 'paramSProdBemerkung',
  });
  datenSearchGroup.menuItems.push({
    type: 'combobox',
    label: 'Fremdanlage',
    key: 'paramFremdanlage',
    comboOptions: [...data?.fremdanlagen || []],
  });
  if(rights?.allowReadSollprovision) {
    datenSearchGroup.menuItems.push({
      type: 'numberRange',
      label: 'Sollprovision',
      key: 'paramSProdSollprovision',
    });
  }

  return datenSearchGroup;
}

export function searchMenu(data, rights) {
  return [
    allgemeinSearchGroup(data),
    datenSearchGroup(data, rights),
  ];
}
