<template>
  <div class="currency-label__container" :class="{ 'currency-label__container--inline': showInlineLabel }">
    <div v-if="label" class="currency-label__label" :class="{ 'currency-label__label--regular': showInlineLabel }">
      {{ label }}
    </div>
    <div class="currency-label__number-container" :class="containerClasses">
      <span>{{formattedIntegerValue}}</span>
      <span v-if="precision > 0">{{decimalSeparator}}</span>
      <span class="currency-label__fractional-value">{{fractionalValue}}</span>
      <span>&nbsp;{{symb}}</span>
      <span v-if="info">&nbsp;{{info}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { },
    colored: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    decimalSeparator: {
      type: String,
      default: ','
    },
    thousandSeparator: {
      type: String,
      default: '.'
    },
    symb: {
      type: String,
      default: '€'
    },
    showZeroIfEmpty: {
      type: Boolean,
      default: true
    },
    showInlineLabel: {
      type: Boolean,
      default: false
    },
    precision: {
      type: Number,
      default: 2,
    },
    info: {
      type: String,
      default: '',
    },
  },
  computed: {
    stringValue() {
      if (typeof this.value === 'string') {
        if ( this.value.includes(this.decimalSeparator) ) {
          return this.value.replace(this.decimalSeparator, this.thousandSeparator)
        }
        return this.value;
      }
      if (!isNaN(this.value)) {
        return (+(this.value ?? 0))?.toFixed?.(this.precision) || '0.00';
      }
      return this.value || '0.00';
    },
    splitValue() {
      const splittedValue = this.stringValue.split('.');
      return splittedValue.length === 2 ? splittedValue : null;
    },
    fractionalValue() {
      const result = this.splitValue?.[1];
      if (result) {
        return result
          .substr(0, this.precision)
          .padEnd(this.precision, '0');
      }

      return this.showZeroIfEmpty ? ''.padEnd(this.precision, '0') : result;
    },
    integerValue() {
      return this.splitValue && this.splitValue[0]
    },
    formattedIntegerValue() {
      const result = this.integerValue && this.integerValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator)
      if (result) {
        return result
      }
      return this.showZeroIfEmpty ? '0' : result
    },
    containerClasses() {
      const result = []
      if (this.colored && !isNaN(this.value)) {
        if (this.value > 0) {
          result.push('color-success')
        } else if (this.value < 0) {
          result.push('color-danger')
        }
      }
      
      return result;
    }
  }
}
</script>

<style scoped>
  .currency-label__fractional-value {
    font-size: 0.75em;
  }
  .currency-label__container {
    display: flex;
    flex-direction: column;
  }
  .currency-label__container--inline {
    flex-direction: row;
    justify-content: space-between;
  }
  .currency-label__number-container {
    white-space: nowrap;
  }
  .currency-label__label {
    font-size: 0.75em;
  }
  .currency-label__label--regular {
    font-size: 1em;
  }
</style>