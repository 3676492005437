<template>
  <div>
    <BaseModal 
      ref="editModal"
      modalTitle="Domain"
      :showConfirmButton="false"
      @onCloseButton="close()"
      @close="close()">

      <Table v-if="!loading && rows.length"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        @action-DELETE="deleteDomain"/>
      <GhostLoading v-else-if="loading" type="table" />
      <NoData v-else noIcon />

      <template #footer>
        <BaseButton @click="$refs.modalAdd.open()">Domain hinzufügen</BaseButton>
      </template>

    </BaseModal>
    <BaseModal
      ref="modalAdd"
      modalTitle="Domain hinzufügen"
      :autoClose="false"
      @onConfirmButton="saveDomain()"
    >

      <InputField 
        label="URL" 
        v-model="domainForm.domainUrl"/>

      <ComboBox
        label="Verwendung"
        v-model="domainForm.destination"
        :values="destinationValues"
        :sortComboboxValues="false"/>

    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, ConfirmedAction} from "@/components/table2/table_util.js";
import InputField from '@/components/core/forms/InputField.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import { mapGetters } from 'vuex'
import ComboBox from '@/components/core/forms/ComboBox.vue'
import DOMAIN_FC_TYPES from '@/store/domainFC/types.js'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const DESTINATION_VALUES = [
  { label: 'Standardroute', value: null },
  { label: 'Login', value: 'LOGIN'},
  { label: 'CMS', value: 'CMS'},
]

function domainForm() {
  return {
    domainUrl: '',
    destination: null,
  }
}

export default {
  props: {
  },

  data: function () {
    return {
      loading: false,
      domainForm: domainForm(),
    }
  },

  computed: {
    ...mapGetters({
      domainsFC: DOMAIN_FC_TYPES.GETTERS.DOMAINS_FC
    }),

    headers() {
      return {
        lockedLeft: [
          TextColumn("domainUrl", "URL"),
        ],
        center: [
          TextColumn("destinationLabel", "Destination"),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ],
      }
    },

    rows() {
      if (!this.domainsFC) {
        return []
      }

      return this.domainsFC.map(domain => ({
        ...domain,
        destinationLabel: this.destinationValues.find(dest => dest.value === domain.destination)?.label || '',
        actions: [
          ConfirmedAction("DELETE", '', "Löschen", `Soll der Eintrag "${domain.domainUrl}" wirklich gelöscht werden?`, 'Eintrag löschen', "Löschen"),
        ]
      }))
    },

    destinationValues() {
      return [...DESTINATION_VALUES]
    }
  },

  methods: {
    deleteDomain(domain) {
      if (domain.id) {
        this.$store.dispatch(DOMAIN_FC_TYPES.ACTIONS.DELETE_DOMAIN_FC, domain.id)
      }
    },
    async saveDomain() {
      if (this.domainForm?.domainUrl) {
        try {
          await this.$store.dispatch(DOMAIN_FC_TYPES.ACTIONS.SAVE_DOMAIN_FC, this.domainForm)
          this.$refs.modalAdd.close()
          this.domainForm = domainForm()
        } catch (error) {
          // empty block
        }
        
      }
    },
    close() {
      this.$emit("close")
    },
    async loadDomains() {
      this.loading = true
      await this.$store.dispatch(DOMAIN_FC_TYPES.ACTIONS.GET_DOMAINS_FC)
      this.loading = false;
    }
  },
  mounted() {
    this.$refs.editModal.open()
    this.loadDomains();
  },

  components: {
    BaseModal,
    Table,
    InputField,
    BaseButton,
    ComboBox,
    GhostLoading,
    NoData,
  },
}
</script>
