var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputToggleSwitch", {
        attrs: {
          value: _vm.data.eltif || "0",
          label: "Kunde wünscht ein ELTIF Produkt",
          inLineLabel: "",
          suppressValidationMessage: "",
          disabled: _vm.isDisabled
        },
        on: { change: _vm.updateStoreEltif }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }