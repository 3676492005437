<template>
  <div>
    <Table v-if="rows.length"
        tableId="d7c41d19-545f-47ff-81a5-418d5176e3af"
        :title="title"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        rowId="fileId"
        @action-DELETE="deleteFile"
    >
      <template v-slot:bezeichnung="row"> 
        <DownloadLink target="_blank" rel="noopener noreferer" 
            :title="row.bezeichnung || row.filename || 'Unbekannt'"
            :filename="row.bezeichnung || row.filename || 'Unbekannt'"
            downloadServicePath="/get_simple_file"
            :queryParams="{
              fileId: row.fileId, 
            }"
        />
      </template>
    
    </Table>
    <NoData v-else noIcon content="Keine Anhänge vorhanden" :title="title" />
  </div>
</template>

<script>

import CORE_TYPES from '@/store/core/types';

import { mapGetters } from "vuex";
import {TextColumn, ActionColumn, ConfirmedAction, SlotColumn} from "@/components/table2/table_util.js";

import Table from "@/components/table2/Table.vue";
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import NoData from '@/components/core/NoData.vue';

export default {
  props: {
    title: {
      type: String,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
        headers: {
            lockedLeft: [
                SlotColumn("bezeichnung", "Dateiname", 80, 1).makeLink(),
            ],
            center: [
                TextColumn("sizeKB", "Größe"),
            ],
            lockedRight: [
                ActionColumn("actions"),
            ],
        },
    };
  },
  computed: {
    ...mapGetters({
        isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    rows() {
        return this.files.map(f => ({
            ...f,
            sizeKB: f.size != null ? `${Math.ceil(f.size / 1024)} KB` : '',
            actions: [
              ...(this.isCustomerOnly ? [] : [
                ConfirmedAction("DELETE", '', "Löschen", `Soll der Anhang "${f.bezeichnung || 'Keine Bezeichnung'}" wirklich gelöscht werden?`, 'Anhang löschen', "Löschen")
              ]),
            ],
        }))
    }
  },
  methods: {
    deleteFile(file) {
      this.$emit('deleteFile', file.fileId);
    },
  },
  components: {
    Table,
    BaseFileSelect,
    DownloadLink,
    NoData,
  },
}
</script>
