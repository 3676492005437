<template>
  <div class="container" v-if="stepperMediator">
    <div :class="['template-top', stepperClass, stepType]" v-if="isSmallOrMediumScreen">

      <div v-if="$slots.headerTemplate" :class="['template-top', stepperClass, stepType]">
        <slot name="headerTemplate"></slot>
      </div>

      <Stepper3
        ref="stepper3"
        :steps="stepperMediator.getCurrentStepList()"
        stepType="step"
        :disabledUpdate="stepType === 'fixed'"
        :visible="showStepper"
        :stepperName="stepperName"
        :selectedStepKey="currentStepKey"
        :selectedSubstepKey="currentSubstepKey"
        :stepperService="stepperMediator"
        @setStep="setStepOnMobile"
        @setSubstep="setSubstepOnMobile"
      ></Stepper3>
    </div>

    <div v-if="$slots.headerTemplate && !isSmallOrMediumScreen" :class="['template-top', stepperClass, stepType]">
      <slot name="headerTemplate"></slot>
    </div>       

    <ContentWithStepper :stepperCollapsed="stepperCollapsed"  v-if="!isSmallOrMediumScreen">
      <template v-if="showVerticalStepper" #stepper>
        <div class="box__container">
          <DynamicVerticalStepper
            v-if="isDynamicDocument"
            ref="stepper"
            class="responsive-stepper"
            :stepType="stepType"
            :stepperName="stepperName"
            :selectedStepKey="currentStepKey"
            :selectedSubstepKey="currentSubstepKey"
            :stepperService="stepperMediator" 
            :showCollapse="true"
            :showSaveSpinner="showSaveSpinner"
            @setStep="setStep" 
            @setSubstep="setSubstep"
            @collapsed="stepperCollapsed = $event"
            @addStep="$emit('addStep')"
            @removeStep="$emit('removeStep', $event)"
            @copyStep="$emit('copyStep', $event)"
            @orderChanged="$emit('orderChanged', $event)"
          />
          <VerticalStepper
            v-else
            ref="stepper"
            class="responsive-stepper"
            :stepType="stepType"
            :stepperName="stepperName"
            :selectedStepKey="currentStepKey"
            :selectedSubstepKey="currentSubstepKey"
            :stepperService="stepperMediator" 
            :showCollapse="true"
            :showSaveSpinner="showSaveSpinner"
            @setStep="setStep" 
            @setSubstep="setSubstep"
            @collapsed="stepperCollapsed = $event"
          />
        </div>
      </template>
      <template #content>
        <div class="row my-0 template-middle" :class="[stepperClass, stepType]">
          <div class="col-12 template-content" :class="[stepperClass, getTemplateContentClass()]">
            <slot name="contentTemplate" :data="currentStep" :currentSubstep="currentSubstep"></slot>
            <div v-if="$slots.footerTemplate" class="template-bottom">
              <slot name="footerTemplate"></slot>
            </div>
          </div>
          <div v-if="hasSidetextTemplate" class="col-md-3 col-12" :class="[getSideTextClass()]">
            <slot name="sidetextTemplate"></slot>
          </div>
        </div>
        <div class="template-navigation-buttons" v-if="!hideNavigationButtons && (visibleSteps.length > 1 || !!$slots.customBottomButtons)">
          <div class="box__container">
            <div v-if="!!$slots.customBottomButtons" class="m-0 mr-3">
              <slot name="customBottomButtons"></slot>
            </div>
            <slot name="buttonsTemplate">
              <BaseButton v-if="hasZurueck" @click="backStep()" :disabled="showSaveSpinner" isSecondary class="m-0 mr-3">
                Zurück <AnimatedSpinner v-if="showSaveSpinner && lastBottomButtonAction === ACTION_PREV" />
              </BaseButton>
              <BaseButton v-if="hasWeiter" @click="nextStep()" :disabled="showSaveSpinner" is-primary class="m-0 mr-3">
                Weiter <AnimatedSpinner v-if="showSaveSpinner && lastBottomButtonAction === ACTION_NEXT" />
              </BaseButton>
              <BaseButton v-if="hasFertig && !hasWeiter" @click="fertig()" :disabled="showSaveSpinner" is-primary class="m-0">
                Schließen <AnimatedSpinner v-if="showSaveSpinner && lastBottomButtonAction === ACTION_FINISH" />
              </BaseButton>
            </slot>
          </div>
        </div>
      </template>
    </ContentWithStepper>

    <BaseModal
        ref="editModal"
        :modalTitle="editModalTitle"
        size="lg"
        :showCancelButton="false"
        @onCloseButton="onCloseEditModal()"
        :actions="baseModalActions"
        :showConfirmButton="false"
        :autoCloseOnRouteNavigation="false"
        :backNavigationOnClose="backNavigationOnClose"
        @action-WEITER="nextStep()"
        @action-ZURUCK="backStep()"
        @action="emitAction">

        <div class="modal__container" v-if="!loadingStep">

          <div class="row my-0 template-middle" :class="[stepperClass, stepType]">
            <div class="col-12 template-content" :class="[stepperClass, getTemplateContentClass()]">
              <slot name="contentTemplate" :data="currentStep" :currentSubstep="currentSubstep"></slot>
              <div v-if="$slots.footerTemplate" class="template-bottom">
                <slot name="footerTemplate"></slot>
              </div>
            </div>
            <div v-if="hasSidetextTemplate" class="col-md-3 col-12" :class="[getSideTextClass()]">
              <slot name="sidetextTemplate"></slot>
            </div>
          </div>
        </div>
    </BaseModal>    
  </div>
</template>
<script>
import BaseButton from "@/components/core/BaseButton.vue";
import Step from "./Step.vue";
import Stop from "./Stop.vue";
import Stepper2 from "./Stepper2.vue";
import Stepper3 from '@/components/stepper/Stepper3.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import DynamicVerticalStepper from '@/components/dynamicDocumentStepper/DynamicVerticalStepper.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import CORE_TYPES from "@/store/core/types";
import STEPPER_TYPES from "@/store/stepper/types";
import { mapGetters } from 'vuex';
import BaseModal from '@/components/core/BaseModal.vue';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import BRIDGE_TYPES from "@/store/bridge/types";
import { getStepVisible } from '@/components/antrag/antrag-utils';

const ACTION_NEXT = 'next';
const ACTION_PREV = 'prev';
const ACTION_FINISH = 'finish';

export default {
  props: {
    stepperName: {
      type: String,
      default: "default"
    },
    stepperClass: {
      type: String,
      default: ""
    },
    stepType: {
      type: String,
      default: "step",
    },
    showStepper: {
      type: Boolean,
      default: true
    },
    stepperMediator: {
      default: () => null
    },
    hasFertig: {
      type: Boolean,
      default: true,
    },
    selectedStepKey: {
			type: String,
			default: '',
		},
    selectedSubstepKey: {
      type: String,
			default: '',
    },
    showVerticalStepper: {
      type: Boolean,
      default: true,
    },
    showSaveSpinner: {
      type: Boolean,
      default: false,
    },
    isDynamicDocument: {
      type: Boolean,
      default: false,
    },
    hideNavigationButtons: {
      type: Boolean,
      default: false,
    },
    additionalActionsForMobile: {
      type: Array,
      default: () => [],
    },
    backNavigationOnClose: {
      type: Boolean,
      default: false,
    },
    ignoreOpenModalOnMount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentStep: {},
      currentSubstep: {},
      // steps: [],
      stepperCollapsed: false,
      lastBottomButtonAction: '',
      ACTION_NEXT,
      ACTION_PREV,
      ACTION_FINISH,
      loadingStep: false,
    };
  },
  computed: {
    ...mapGetters({
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      shouldReopenAppModal: STEPPER_TYPES.GETTERS.SHOULD_REOPEN_APP_MODAL,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      screenSize: CORE_TYPES.GETTERS.SCREEN_WIDTH,
    }),    
    editModalTitle() {
      return this.currentSubstep?.label || this.currentStep?.label || '';
    },
    isSmallOrMediumScreen() {
      return this.isSmallScreen || this.isMediumScreen;
    },
    isMediumScreen() {
      return this.screenSize === 'md';
    },
    visibleSteps() {
      const steps = this.stepperMediator.getCurrentStepList();
      return getStepVisible(steps, null);
    },
    currentStepKey() {
      return this.currentStep?.stepKey;
    },
    currentSubstepKey() {
      return this.currentSubstep?.substepKey;
    },
    hasSidetextTemplate() {
      return !!this.$slots.sidetextTemplate;
    },
    zuruckEnabled() {
      const index = this.stepperMediator.getCurrentStepIndex(this.currentStepKey);
      return index > 0;
    },
    zuruckEnabledSubsteps() {
      if(!this.currentStep?.substeps?.length) {
        return false;
      }
      const substepIndex = this.stepperMediator.getCurrentSubStepIndex(this.currentStepKey, this.currentSubstepKey);
      return substepIndex > 0;
    },
    weiterEnabled() {
      const index = this.stepperMediator.getCurrentStepIndex(this.currentStepKey);
      let notHidden = this.stepperMediator.getCurrentStepList().slice(index+1).filter(step=>!step.hidden).length;
      return notHidden > 0;
    },
    weiterEnabledSubsteps() {
      const step = this.currentStep;
      if(!step?.substeps?.length) {
        return false;
      }
      const substepIndex = step.substeps.findIndex((substep) => substep.substepKey === this.currentSubstepKey);
      const notHiddenSubstep = step.substeps.slice(substepIndex+1).filter((substep) => !substep.hidden).length;
      return notHiddenSubstep > 0;
    },
    hasWeiter() {
      return this.weiterEnabled || this.weiterEnabledSubsteps
    },
    hasZurueck() {
      return this.zuruckEnabled || this.zuruckEnabledSubsteps
    },
    baseModalActions() {
      const showNavigationButtons = !(this.hideNavigationButtons);

      const actions = [
        BaseModalSimpleAction('ZURUCK', 'Zurück')
          .withPrimary(() => false)
          .withVisible(() => showNavigationButtons && this.hasZurueck),
        BaseModalSimpleAction('WEITER', 'Weiter')
          .withPrimary(() => true)
          .withVisible(() => showNavigationButtons && this.hasWeiter),
      ];

      return [...actions, ...this.additionalActionsForMobile];
    },    
  },
  watch: {
    'stepperMediator.stepListSource': {
      handler(steps) {
        if(steps.length > 0) {
          this.setStepObj({ 
            stepKey: this.selectedStepKey,
            substepKey: this.selectedSubstepKey,
          }, false);
        }
      },
      immediate: true,
    },
    selectedStepKey: {
      handler(stepKey) {
        if(this.currentStepKey !== stepKey) {
          this.setStepObj({ 
            stepKey,
            substepKey: this.selectedSubstepKey,
          }, false);
        }
      },
      immediate: true,
    },
    selectedSubstepKey: {
      handler(substepKey) {
        if(this.currentSubstepKey !== substepKey) {
          this.setSubstep({
            stepKey: this.selectedStepKey, 
            substepKey,
          }, false);
        }
      },
      immediate: true,
    },
    steps() {
      if(this.currentStepKey !== this.selectedStepKey) {
        this.setStepObj({ 
          stepKey: this.selectedStepKey,
          substepKey: this.selectedSubstepKey,
        }, false);
      }
    },
    showSaveSpinner(value) {
      if(!value) {
        this.lastBottomButtonAction = '';
      }
    },
  },
  methods: {
    getSideTextClass() {
      return "template-sidetext " + this.stepperClass;
    },
    openEditModal() {
      if (!this.$refs.editModal.isOpen) {
        this.$refs.editModal.open();
      }
    },
    getTemplateContentClass() {
      const classes = [];
      if(this.hasSidetextTemplate) {
        classes.push('col-md-9');
      }
      return classes.join(' ');
    },
    async setStepOnMobile(stepKey) {
      await this.setStep(stepKey, true);
    },
    setSubstepOnMobile(params) {
      this.setSubstep(params, true);
      this.displayEditModal();
   
    },
    displayEditModal() {
      if (this.isSmallOrMediumScreen) {
          
        if (this.shouldReopenAppModal) {
          this.openEditModal();
        } else {
          this.$store.commit(STEPPER_TYPES.MUTATIONS.SET_SHOULD_REOPEN_APP_MODAL, {shouldReopenAppModal: true,});
        }
      }   
    },
    async setStep(stepKey, emit = true) {
      await this.setStepObj({stepKey}, emit);
    },
    async setStepObj(params, emit = true) {
      if(emit) {
        this.$emit("set-step", params?.stepKey);
        this.displayEditModal();
      } else {
        this.setStepByKey(params?.stepKey);
        this.setSubstepByKey(params?.stepKey, params?.substepKey);
      }
    },
    setSubstep(params, emit = true) {
      if(emit) {
        this.$emit('set-substep', params);
        if (this.isSmallOrMediumScreen) {
          this.$store.commit(STEPPER_TYPES.MUTATIONS.SET_SHOULD_REOPEN_APP_MODAL, {shouldReopenAppModal: true,});
        }
      } else {
        this.setStepByKey(params?.stepKey);
        this.setSubstepByKey(params?.stepKey, params?.substepKey);
      }
      if (this.isSmallOrMediumScreen) {
        this.$store.commit(STEPPER_TYPES.MUTATIONS.SET_SHOULD_REOPEN_APP_MODAL, {shouldReopenAppModal: true,});
      }      
    },
    setStepByKey(stepKey) {
      const step = this.stepperMediator.getCurrentStep(stepKey);
      if (!step) return ;

      this.currentStep = {
        ...step,
      };
    },
    setSubstepByKey(stepKey, substepKey) {
      this.currentSubstep = {};

      const step = this.stepperMediator.getCurrentStep(stepKey);
      if(!step?.substeps?.length) {
        return ;
      }

      const substep = substepKey && step.substeps.find((substep) => substep.substepKey === substepKey) || step.substeps[0];
      if(!substep) {
        return ;
      }

      this.currentSubstep = {
        stepKey: step?.stepKey,
        substepKey: substep?.substepKey,
      };
    },
    nextStep() {
      this.lastBottomButtonAction = ACTION_NEXT;
      const nextStep = this.stepperMediator.getNextSubstep(this.currentStepKey, this.currentSubstepKey);
      if (!nextStep) return;

      const currentSubstepIndex = this.stepperMediator.getCurrentSubStepIndex(nextStep?.stepKey, nextStep?.substepKey);
      if(nextStep?.substepKey && currentSubstepIndex >= 0) {
        this.setSubstep(nextStep);
      } else {
        this.setStep(nextStep?.stepKey);
      }
      this.$emit("next-step", nextStep);
    },
    backStep() {
      this.lastBottomButtonAction = ACTION_PREV;
      const prevStep = this.stepperMediator.getPreviousSubstep(this.currentStepKey, this.currentSubstepKey);
      if (!prevStep) return;

      const currentSubstepIndex = this.stepperMediator.getCurrentSubStepIndex(prevStep?.stepKey, prevStep?.substepKey);
      if(prevStep?.substepKey && currentSubstepIndex >= 0) {
        this.setSubstep(prevStep);
      } else {
        this.setStep(prevStep?.stepKey);
      }
      this.$emit("previous-step", prevStep);
    },
    emitAction(actionData) {
      this.$emit("handleOtherActions", actionData);
    },
    fertig() {
      this.lastBottomButtonAction = ACTION_FINISH;
      this.$emit("on-fertig");
    },
    setStepHidden(stepKey, hidden = true) {
      this.stepperMediator.setStepHidden(stepKey, hidden);
      this.updateStepper();
    },
    updateStepper() {
      this.$refs.stepper?.updateStepList();
    },
    setSubstepHidden(stepKey, substepKey, hidden = true) {
      this.stepperMediator.setSubstepHidden(stepKey, substepKey, hidden);
    },
    removeStep(step) {
      this.$emit('removeStep', step);
    },
    addStep() {
      this.$emit('addStep');
    },
    onCloseEditModal() {
      this.showingEditModal = false;

      if (this.backNavigationOnClose) {
        this.$goToLastPage();
      }
    },    
  },
  components: {
    BaseButton,
    Step,
    Stop,
    Stepper2,
    Stepper3,
    VerticalStepper,
    ContentWithStepper,
    AnimatedSpinner,
    DynamicVerticalStepper,
    BaseModal,
  },
  mounted() {
    if (this.isSmallOrMediumScreen && this.shouldReopenAppModal && !this.ignoreOpenModalOnMount) {
      this.openEditModal();
    }
  },
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
}

.template-top {
  flex-grow: 1;
  margin-left: 0;
  margin-right: 0;
}

.template-bottom {
  flex-grow: 1;
  margin: 1rem 0rem;
  padding: 1rem 0rem;
}

.template-sidetext {
  flex: 0;
  overflow: hidden;
}

.d-lg-flex > .box__container {
  flex-grow: 1;
}

.template-navigation-buttons > .box__container {
  display: flex;
  align-items: baseline;
}

.modal__container {
  /* padding: 24px; */
  padding: 0px 24px;
}

@media (min-width: 768px) {
  .template-sidetext {
    flex: 0 1 350px;
    padding: 1rem;
    padding-top: 0rem;
  }
}

@media (min-width: 768px) {
  .warnings-small-screen {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .modal__container {
    padding: 0px 8px;
  }

}

</style>
