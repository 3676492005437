<template>
  <div>
    <DashboardPanel 
      id="ea95c832-346a-4069-9667-6971c1f3bf62"
      :title="headerTitle"
      :data="dashboardData"
      :subtitle="subtitle" 
      :headerActions="headerActions"
      :noPrimaryAction="false"
    >
      <template v-for="(chart, key) in depotCharts" v-slot:[chart.type]="slotProps">
        <DepotpositionsChart v-if="chart" :chart="chart" :showTitle="false" />
      </template>
        
      <template v-slot:ChartMontecarlo>
      <ChartMontecarlo :depotId="depotid"/>
      </template>
    </DashboardPanel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PhCheck, PhWarning  } from 'phosphor-vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import DashboardPanel from '@/components/dashboard/DashboardPanel.vue';
import { VIEW_ROLES, BROKER_STRUCTURE_ROLES, EMPLOYEE_ROLES } from '@/router/roles';
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import DepotpositionsChart from './DepotpositionsChart.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import NoData from '@/components/core/NoData.vue';
import CORE_TYPES from '@/store/core/types'
import InteractiveHelpCommonsMixin from '@/assets/mixins/interactivehelpcommonsmixins.js';
import { MAP_CHART_TYPE_TO_TITLE } from './types';

import ChartMontecarlo from '@/components/charts/ChartMontecarlo.vue';

export default {
  components: {
    PhCheck,
    PhWarning,
    CurrencyLabel,
    DashboardPanel,
    NoData,
    DepotpositionsChart,
    ChartMontecarlo,
    GhostLoading,
    Block,
  },
  props: {
    headerTitle: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    headerActions: {
      type: Array,
      default: () => [],
    },
  },
  validators: {},
  mixins: [InteractiveHelpCommonsMixin],
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    depotid: function () {
      this.retrieveCharts();
      this.$store.commit(DEPOTPOSITIONS_TYPES.MUTATIONS.CHANGE_ID, this.depotid);
    },
    config: 'retrieveCharts',
  },
  computed: {
    ...mapGetters({
      charts: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CHARTS,
      config: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CONFIG,
      depotid: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      paiChart: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_ESG_CHART,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    depotCharts() {
      const allCharts = this.paiChart && this.paiChart[this.depotid] || {}
      const PaiChart = {barChart: false, properties: [], type: 'PaiChart'}
      const SFDRChart = {barChart: false, properties: [], type: 'SFDRChart'}
      const TaxonomieChart = {barChart: false, properties: [], type: 'TaxonomieChart'}
      if (allCharts && Object.keys(allCharts).length) {
        const properties = [...allCharts.properties];
        PaiChart.properties = properties.filter(v => v.key !== 'SFDR' && v.key !== 'Taxonomie')
        SFDRChart.properties = properties.filter(v => v.key == 'SFDR')
        TaxonomieChart.properties = properties.filter(v => v.key == 'Taxonomie')
        if (SFDRChart.properties && SFDRChart.properties.length && SFDRChart.properties[0].value) {
          SFDRChart.properties = [SFDRChart.properties[0], {key: '', value: 1 - SFDRChart.properties[0].value}]
        }
        if (TaxonomieChart.properties && TaxonomieChart.properties.length && TaxonomieChart.properties[0].value) {
          TaxonomieChart.properties = [TaxonomieChart.properties[0], {key: '', value: 1 - TaxonomieChart.properties[0].value}]
        }
      }
      const charts = this.charts && this.charts[this.depotid] && this.charts[this.depotid].charts || {};
      if (!Object.keys(charts).length) {
        return {}
      }
      PaiChart.properties = PaiChart.properties.map(v => ({...v, value: v.value * 100}))
      TaxonomieChart.properties = TaxonomieChart.properties.map(v => ({...v, value: v.value * 100}))
      SFDRChart.properties = SFDRChart.properties.map(v => ({...v, value: v.value * 100}))
      return {
        ...charts,
        TaxonomieChart,
        SFDRChart,
        PaiChart,
      }
    },
    depotChartsArray() {
      return Object.values(this.depotCharts)
    },
    hasDepotChartsData() {
      return !!Object.keys(this.depotCharts).length;
    },
    dashboardData() {
      const widgets = Object.values(this.depotCharts) // We add montecarlo here manually since it uses a different component to display than depotcharts
      const data = {
        widgets: widgets.filter(v => v.type).map(chart => ({
            name: chart.type,
            title: this.title(chart?.type),
            titleComponent: this.isESG(chart.type)
              ? () => import('@/components/depotpositions/DepotpositionsESGChartTitle.vue')
              : null,
            visible: () => true,
            loading: () => this.loading,
            isNavigable: false
        }))
      }
      //add montecarlo
      data.widgets.push(
        {
            name: 'ChartMontecarlo',
            title: 'Rendite-Simulation',
            titleComponent: null,
            visible: () => this.isBrokerOrBypass,
            loading: () => this.loading,
            isNavigable: false
        })
      return data
    },
  },
	mounted: function() {
    this.retrieveConfig();
    this.retrieveCharts();
  },
  methods: {
    isESG(type) {
      return type == 'SFDRChart' || type == 'TaxonomieChart' || type == 'PaiChart'
    },
    title(type) {
      return MAP_CHART_TYPE_TO_TITLE[type] || type || '';
    },
    retrieveConfig() {
      if (!this.config || !Object.keys(this.config).length) {
        this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_CONFIG, {});
      }
    },
    async retrieveCharts() {
      this.loading = true;
      if (this.depotid && this.charts && !this.charts[this.depotid] && this.config && this.config.charts) {
        await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_CHARTS, {
          depotid: this.depotid,
          charts: this.config.charts
        })
      }
      if (!this.depotid || !this.paiChart || this.depotid && this.paiChart && !this.paiChart[this.depotid]) {
        await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_ESG_CHART, {depotid: this.depotid})
      }
      this.loading = false;
    },
  },

  filters: {
  },

}
</script>

<style scoped>
</style>