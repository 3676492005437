<template>
  <div class="box__container">
    <div class="mb-3" v-if="isBrokerOrBypass">
      <BaseButton @click="openSubCustomerSearch()">Gruppenmitglied hinzufügen</BaseButton>
    </div>

    <Table v-if="!loading && rows.length"
        tableId="d40cc1ae-8b4e-4fcd-9bc4-c896de80e04b"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        rowId="customerID"
        @click-name="openSubCustomerNewTab"
        @action-DELETE="openDeleteModal"
    />
    <div v-else-if="loading" class="text-centered"><AnimatedSpinner /></div>
    <div v-else>Keine Daten</div>

    <BaseModal
      ref="subCustomerDeleteModal"
      size="sm"
      modalTitle="Gruppenmitglied entfernen"
      @onConfirmButton="deleteSubCustomer()"
    >
      <template #default>
        {{ subCustomerToDelete.fullName }} wirklich aus der Liste entfernen?
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import CORE_TYPES from '@/store/core/types';

import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import BaseModal from '@/components/core/BaseModal.vue';


export default {
  data() {
    return {
        loading: false,
        subCustomerToDelete: {},
        headers: {
            lockedLeft: [
                TextColumn("vorname", "Vorname"),
            ],
            center: [
                TextColumn("anschrift", "Adresse"),
                TextColumn("interneBemerkung", "Bemerkung"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        },
    };
  },
  computed: {
    ...mapGetters({
      subCustomers: CUSTOMERDATA_TYPES.GETTERS.SUB_CUSTOMERS,
      selectedSubCustomers: CUSTOMER_SEARCH_TYPES.GETTERS.SET_SELECTED_CUSTOMERS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    rows() {
        const actions = [
            SimpleAction("DELETE", '', "Löschen"),
        ];
        return this.subCustomers.map(row => ({
            ...row,
            actions,
        }))
    }
  },
  methods: {
    openSubCustomerSearch() {
      this.$router.push(`/persoenlichedaten/sub-customer-search?back=${this.$route.path}`);
    },
    saveSubCustomerIfNeeded() {
      if(!this.$route.query?.usersselected)  return;

      const selectedSubCustomers = (this.selectedSubCustomers || []).filter(sc => !!sc.customerID).map(sc => sc.customerID);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.ADD_SUB_CUSTOMERS, [...selectedSubCustomers]);
      this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.SET_SELECTED_CUSTOMERS, []);
    },
    openSubCustomerNewTab(subCustomer) {
      this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, {
        data: subCustomer,
      });
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
        customerId: subCustomer?.customerID,
      });
    },
    async findSubCustomers() {
      this.loading = true;
      await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_SUB_CUSTOMERS);
      this.loading = false;
    },
    openDeleteModal(subCustomer) {
      this.subCustomerToDelete = {
        ...subCustomer,
      };

      this.$refs.subCustomerDeleteModal.open();
    },
    deleteSubCustomer() {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_SUB_CUSTOMER, this.subCustomerToDelete);
    },
    setNameCol() {
      let name = TextColumn("name", "Name")
      if (this.isBrokerOrBypass) {
        name = TextColumn("name", "Name").makeLink()
      }
      this.headers.lockedLeft = [
        name,
        ...this.headers.lockedLeft,
      ]
    },
  },
  watch: {
    isBrokerOrBypass: function (val) {
      this.setNameCol();
    },
  },
  mounted() {
    this.findSubCustomers();
    this.saveSubCustomerIfNeeded();
    this.setNameCol();
  },
  components: {
    BaseButton,
    Table,
    AnimatedSpinner,
    BaseModal,
  },
}
</script>
