<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
        <PageHeaderTitleNavigation 
            :id="$appNavigation.currentOptionMenuId" 
            title="Persönliches Geschäft Liste" 
            :actions="headerActions"
            @action-ADD="openFragebogen({id : 'neu'})"
        />

        <div class="box__container">
            <div class="box__title">Gespeicherte Fragenbogen</div>
            <div v-if="fragebogenList.length">
                <Table 
                    rowId="depotnr + lagerstelle + jahr"
                    :headers="headers"
                    :rows="fragebogenList"
                    :rowsPerPage="20"
                    @click-depotnr="openFragebogen"
                    @action-OPEN="openFragebogen"
                    @action-DELETE="removeFragebogen"
                >
                </Table>
            </div>
            <NoData v-else/>
        </div>
    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import PERS_GESCHAEFT_TYPES from '@/store/persoenlichesGeschaeft/types';
import { mapGetters } from 'vuex';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, PillColumn, ActionColumn, SimpleAction, ConfirmedAction} from "@/components/table2/table_util.js";
import {DEPOT_STATUS_VALUES} from './Fragebogen.vue';
import presoenlichesGeschaeftMixin from "@/mixins/persoenliches-geschaeft-mixin";

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    mixins: [presoenlichesGeschaeftMixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        NoData,
        Table,
    },
    data() {
        return {
            headers: {
                lockedLeft: [
                    PillColumn("geprueftStatus", "Prüfungstatus"),
                    TextColumn("jahr", "Jahr"),
                    TextColumn("depotnr", "Depotnummer").makeAlwaysVisible().makeLink(),
                    TextColumn("lagerstelle", "Lagerstelle"),
                ],
                center: [
                    TextColumn("depotStatusText", "Depotstatus"),
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            }
        }
    },
    mounted() {
        this.$store.dispatch(PERS_GESCHAEFT_TYPES.ACTIONS.GET_GESPEICHERTE_FRAGEBOGEN_LIST);
    },
    computed: {
        ...mapGetters({
            fragebogen: PERS_GESCHAEFT_TYPES.GETTERS.GESPEICHERTE_FRAGEBOGEN_LIST,
        }),
        headerActions() {
            return [
                PageHeaderSimpleAction('ADD', 'Neuer Fragebogen'),
            ];
        },
        fragebogenList() {
            const actions = [
                SimpleAction("OPEN", '', "Öffnen"), 
                ];
            const actionsWithDelete = [
                SimpleAction("OPEN", '', "Öffnen"),
                ConfirmedAction("DELETE", '', "Löschen", "Wollen Sie den ausgewählten Fragebogen wirklich löschen?")

            ]
            return (this.fragebogen || []).map(row => {
                const isDeleteAllowed = row.geprueftStatus.type !== 'success' || this.origiginIsIntern;
                return {
                    ...row,
                    depotStatusText: row.depotStatus && DEPOT_STATUS_VALUES.find(elem => elem.value === row.depotStatus)?.status || '',
                    actions: isDeleteAllowed ? actionsWithDelete : actions,
                }
            });
        },
    },
    methods: {
        openFragebogen(data) {
            if (data?.id) {
                this.$addBreadcrumb({
                    label: `zurück zum persönlichen Geschäft`, 
                    fullPath: `/intern/aufsichtsrechtliches/persoenliches-geschaeft`,
                    breadcrumb: 'Persönliches Geschäft Jahr 2022',
                });
                this.$router.push(`/intern/aufsichtsrechtliches/persoenliches-geschaeft-fragegoben/${data.id}`)
            }
        },
        removeFragebogen(data) {
            if (data?.id) {
                this.$store.dispatch(PERS_GESCHAEFT_TYPES.ACTIONS.REMOVE_FRAGEBOGEN, data.id);
            }
        },
    }
}
</script>

<style>

</style>