var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          !_vm.isPendingAction
            ? _c("OptionMenu", {
                attrs: {
                  id: _vm.$appNavigation.currentOptionMenuId,
                  defaultMenu: _vm.$appNavigation.currentOptionMenu
                }
              })
            : _vm._e(),
          _c("PageHeaderTitleNavigation", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              title: _vm.title
            }
          }),
          _c("StepperForm", {
            ref: "stepperForm",
            attrs: {
              stepType: "fixed",
              stepperMediator: _vm.tabsStepperMediator,
              showSaveSpinner: _vm.showSaveSpinner,
              selectedStepKey: _vm.currentStep,
              selectedSubstepKey: _vm.currentSubstep,
              hasFertig: false
            },
            on: {
              "set-step": function($event) {
                return _vm.setStepByKey($event)
              },
              "set-substep": function($event) {
                return _vm.setSubstepByKey($event)
              },
              "next-step": function($event) {
                return _vm.saveData($event)
              }
            },
            scopedSlots: _vm._u(
              [
                _vm.currentStep
                  ? {
                      key: "contentTemplate",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "row my-0" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              _vm._l(_vm.componentsForCurrentStep, function(
                                component,
                                index
                              ) {
                                return _c("div", { key: index }, [
                                  !_vm.isComponentHidden(component)
                                    ? _c(
                                        "div",
                                        { key: index + component.id },
                                        [
                                          _c("AntragComponent", {
                                            attrs: {
                                              component: component,
                                              comboboxSelection:
                                                (_vm.comboboxSelections &&
                                                  _vm.comboboxSelections[
                                                    component.id
                                                  ]) ||
                                                _vm.comboboxSelections ||
                                                [],
                                              data: _vm.data || {},
                                              disabled:
                                                _vm.disabledForCurrentStep,
                                              isComponentHalfSizeEnabled: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateData(
                                                  component.id,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  : {
                      key: "buttonsTemplate",
                      fn: function() {
                        return [_c("NoData", { attrs: { noIcon: "" } })]
                      },
                      proxy: true
                    }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "errorModal",
          attrs: {
            modalTitle: "Fehler",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_c("label", [_vm._v(_vm._s(_vm.errorMessage))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }