<template>
    <div>
        <OptionMenu
            :id="$appNavigation.currentOptionMenuId"
            :defaultMenu="$appNavigation.currentOptionMenu" >
        </OptionMenu>

        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.label"
            :subtitle="subtitle"
            :id="$appNavigation.currentOptionMenuId"
            :actions="actions"
            @action-PDF="getPdf"
        >
        </PageHeaderTitleNavigation>

        <StepperForm
            stepperName="retirement"
            :stepperMediator="stepper"
            :selectedStepKey="stepKey"
            @set-step="setStepByKey"
            >

            <template #contentTemplate>
                <RuhestandsplanungStep />
            </template>
        </StepperForm>
    </div>
</template>

<script>
import StepperForm from '@/components/stepper/StepperForm.vue';
import { StepperMediator } from '@/components/stepper/utils';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import RuhestandsplanungStep from '@/views/ruhestandsplanung/RuhestandsplanungStep.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import mixin from '@/views/ruhestandsplanung/mixin.js';
import RUHESTANDSPLANUNG_TYPES from '@/store/ruhestandsplanung/types';
import MY_GOALS_TYPES from '@/store/myGoals/types';
import {formatNumber} from '@/helpers/number-formatter.js';

export default {
    mixins: [mixin],
    components: {
        StepperForm,
        PageHeaderTitleNavigation,
        OptionMenu,
        RuhestandsplanungStep,
    },
    data() {
        return {
            steps: [
                {
                    stepKey: 'settings',
                    label: 'Einstellungen',
                    totalProgress: 1
                }, {
                    stepKey: 'gesamtvermoegen',
                    label: 'Gesamtvermögen',
                    totalProgress: 2
                }, {
                    stepKey: 'scenario',
                    label: 'Szenario',
                    totalProgress: 3
                }, {
                    stepKey: 'overview',
                    label: 'Zusammenfassung',
                    totalProgress: 4
                }],
        }
    },
    mounted() {
        this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_SCENARIO, this.id);
        this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_VERMOEGEN);
        this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.GET_GOALS);
    },
    computed: {
        subtitle() {
            return `<b>${this.scenario.title || ''}</b>`;
        },
        stepKey() {
            return this.$route.params.stepKey;
        },
        stepper() {
            return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
        },
        actions() {
            const actions = [];
            if (this.scenario) {
                actions.push(PageHeaderSimpleAction('PDF', 'PDF'));
                actions.push(PageHeaderSimpleAction('NEW_TICKET', 'Nachricht an Betreuer'));
            }
            return actions;
        }
    },
    methods: {
        setStepByKey(stepKey) {
            if (!this.scenario.title) {
                this.$confirmModal({
                    title: 'Name ist erforderlich', 
                    message: 'Bitte geben Sie einen Name ein.', 
                    labelButtonConfirm: 'Ok',
                })
            } else if (stepKey && this.stepKey !== stepKey) {
                this.saveScenario();
                this.$router.push(`${this.path}/${this.id}/${stepKey}`);
            }
        },
        async getPdf() {
            await this.saveScenario();
            const data = {
                apexSeries: this.apexSeriesCurrentScenario?.[0]?.data || [], 
                apexAnnotations: this.apexAnnotationsCurrentScenario?.xaxis?.map(elem => ({ x: elem?.x || 0, label: elem?.label?.text || ''})),
                vermoegenBenoetigtRenteBeginn: formatNumber(this.vermoegenBenoetigtRenteBeginn, 0),
                wunschrenteMitInflation: formatNumber(this.wunschrenteMitInflation(this.scenario), 0),
                restKapitalEffektiv: formatNumber(this.restKapitalEffektiv, 0),
                sparrate: formatNumber(this.sparrate, 2),
                vermoegenGeplant: formatNumber(this.vermoegenGeplant, 0),
                alterVermoegenEnde: formatNumber(this.alterVermoegenEnde, 0),
                renditeCalc: formatNumber(this.renditeCalc, 2),
                renditeDepotGewichtet: formatNumber(this.renditeDepotGewichtetCurrentScenario, 2),
                sparrateErhoehungCalc: formatNumber(this.sparrateErhoehungCalc),
            }
            this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_PDF, {id: this.scenario.id, data})
        }
    },
    beforeRouteLeave(to, from, next) {
        this.saveScenario();
        next();
    },
}
</script>

<style>

</style>