<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="mb-3">
        <ComboBox
          v-model="depotPosition"
          :isComponentHalfSize="true"
          :values="valuesForDepot"
          label="Depotpositionnummer"
          @input="setPositionVerkauf($event)"
        />
      </div>
      <div class="row mt-0">
        <div class="col">
          <div
            class="input-forms__label-content input-forms__label-content--bigger"
            v-if="title"
          >
            <ph-bank :size="16" />&nbsp;{{ title }}
          </div>
        </div>

        <div class="col-12 col-xl-4 mt-3 pl-2">
          <BaseButton
            :isEmbedded="true"
            :disabled="disabled || !depotPosition"
            @click="openFormsFinder"
            >Fonds hinzufügen</BaseButton
          >
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <Table
        v-if="rows.length"
        :headers="headers"
        :rows="rows"
        rowId="index"
        hidePagination
        @action-DELETE="openModalDeleteFond"
      >
        <template v-slot:einmalBetrag="row">
          <InputField
            type="currency"
            :precision="2"
            :value="row.einmalBetrag"
            :id="'einmalBetrag' + row.index"
            :disabled="disabled || row.einmalIstGesamtbestand || einmalBetragDisabled(row)"
            @change="onChangeInputField($event, row, 'einmalBetrag')"
          />
        </template>
        <template v-slot:einmalAnteile="row">
          <InputField
            type="number"
            :precision="4"
            :value="row.einmalAnteile"
            :id="'einmalAnteile' + row.index"
            :disabled="disabled || row.einmalIstGesamtbestand"
            @change="onChangeInputField($event, row, 'einmalAnteile')"
          />
        </template>
        <template v-slot:einmalIstGesamtbestand="row">
          <InputToggleSwitch
            :value="row.einmalIstGesamtbestand"
            :id="'einmalIstGesamtbestand' + row.index"
            :disabled="disabled"
            @input="onChangeInputField($event, row, 'einmalIstGesamtbestand')"
          />
        </template>

        <template v-slot:sparplaeneLoeschen="row">
          <InputToggleSwitch
            :value="row.sparplaeneLoeschen"
            :id="'sparplaeneLoeschen' + row.index"
            :disabled="disabled"
            @input="onChangeInputField($event, row, 'sparplaeneLoeschen')"
          />
        </template>
      </Table>
      <div v-if="config && config.positionFields">
        <WertpapierAuswahlPositionFields
          :config="config"
          :categoryId="categoryId"
          :antragId="antragId"
          :disabled="disabled"
        />
      </div>

      <DeletePositionModal
        ref="deleteModal"
        :position="positionToDelete"
        :positionLabel="labelToDelete"
        @delete="doDeletePosition()"
      ></DeletePositionModal>

       <BaseModal
        ref="fehlerModal"
        labelButtonCancel="Ok"
        :showConfirmButton="false"
      >
        <template v-slot:modalTitle>
          <ph-warning :size="16" class="color-danger mr-2" />
          <span class="color-danger">
            Fehler: Sie haben die maximal mögliche Anzahl an Fonds erreicht. Es sind nur 5 Positionen möglich.</span
          >
        </template>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import BaseButton from "@/components/core/BaseButton.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import BaseModal from "@/components/core/BaseModal.vue";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  ActionColumn,
  SlotColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import WertpapierAuswahlPositionFields from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import { mapGetters } from "vuex";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import { PhWarning, PhBank } from "phosphor-vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import {parse} from '@/helpers/number-formatter.js';

export default {
  mixins: [antragNavigationMixin, antragMixin],
  props: {
    title: {},
    antragData: {},
    config: {},
    antragTyp: {},
    antragId: {},
    comboboxSelections: {},
    categoryId: {},
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false,
    },
    warnings: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseButton,
    BaseModal,
    ComboBox,
    InputField,
    Table,
    DeletePositionModal,
    WertpapierAuswahlPositionFields,
    InputRadioBoxGroup,
    PhWarning,
    PhBank,
    InputToggleSwitch,
  },
  data() {
    return {
      depotPosition: "",
      isinVerkauf: "",
      fondsnameVerkauf: "",
      positionToDelete: null,
      labelToDelete: null,
      headers: {
        lockedLeft: [
          TextColumn("fondsnameVerkauf", "Depotposition"),
          TextColumn("isinVerkauf", "ISIN"),
          SlotColumn("einmalAnteile", "Anteile", 80),
          SlotColumn("einmalBetrag", "Betrag", 100),
        ],
        center: [
          TextColumn("isin", "ISIN").makeHidden,
          TextColumn("isinKauf", "Tausch in"),
          TextColumn("fondsnameKauf", "Fondsname"),
          SlotColumn(
            "einmalIstGesamtbestand",
            "oder den gesamten Anteilebestand",
            250
          ),
          SlotColumn("sparplaeneLoeschen", "bestehende Sparpläne löschen", 250),
        ],
        lockedRight: [ActionColumn("actions", "")],
      },
    };
  },
  watch: {
    antragData: {
      handler(newValue) {
        this.updateDepotPosition();
      },
      immediate: true,
    },
    positions: {
      handler(newPositions) {
        this.updatePositions(newPositions);
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.depotPosition && this.antragData) {
      this.depotPosition = this.antragData['INPUT_DEPOTNUMMER_EINMAL_AUSWAHL'] === 'Bitte treffen Sie eine Auswahl' || '' 
      ? '' : this.antragData['INPUT_DEPOTNUMMER_EINMAL_AUSWAHL'];
    }
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
    }),
    rows() {
      const actions = this.disabled
        ? []
        : [SimpleAction("DELETE", "", "Löschen")];
      return (this.positions || []).map((row) => ({
        ...row,
        actions,
      }));
    },
    positions() {
      if (
        this.positionsAll &&
        this.antragId &&
        this.positionsAll[this.antragId]
      ) {
        let positions = this.positionsAll[this.antragId][this.categoryId] || [];
        return positions.sort((p1, p2) => p1?.fondsnameVerkauf?.localeCompare(p2?.fondsnameVerkauf));
      }
    },
    valuesForDepot() {
      return this.config?.depotValues || [];
    },
  },
  methods: {
    getFondsName(pos) {
      return (
        (this.positionInfo &&
          this.positionInfo[pos.isin] &&
          this.positionInfo[pos.isin].wertpapiername) ||
        pos.fondsname ||
        pos.wertpapiername
      );
    },
    getIsin(pos) {
      return (
        pos &&
        this.positionInfo &&
        this.positionInfo[pos.isin] &&
        (this.isWkn ? this.positionInfo[pos.isin].wkn : pos.isin)
      );
    },
    getEinmalBetrag(pos) {
      return pos.einmalBetrag == "" ? null : pos.einmalBetrag;
    },
    openFormsFinder() {
      if (this.positions?.length >= 5) {
        this.$refs.fehlerModal.open();
      } else {
        const isDimensional = !!(this.depotPosition && this.valuesForDepot?.find(pos => pos.value === this.depotPosition)?.label?.includes('Dimensional Funds'));
       
        const config = Object.assign({}, this.config);
        config.isDimensional = !isDimensional;
        const query = JSON.parse(config.query) || {};
        const posVerkauf = (config.positionsVerkauf || {})[this.depotPosition];
        let tauschVonIsin = posVerkauf?.length > 1 && posVerkauf[1] ? posVerkauf[1] : '';
        query['tauschVonValue'] = tauschVonIsin;
        config.query = JSON.stringify(query);

        this.goToFormsFinder(
          'TAUSCH',
          config,
          this.suppressFilterLagerstelle
        );
      }
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        additionalIdentifier: ['isinKauf', 'isinVerkauf', 'posGrpId'],
        fonds: [
          {
            ...positionChange,
            isin,
          },
        ],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    updateDepotPosition() {
      if (!this.depotPosition && this.antragData) {
        this.depotPosition = this.antragData['INPUT_DEPOTNUMMER_EINMAL_AUSWAHL'] === 'Bitte treffen Sie eine Auswahl' || '' 
        ? '' : this.antragData['INPUT_DEPOTNUMMER_EINMAL_AUSWAHL'];
      }
    },
    updatePositions(positions) {
      if (this.$route.query?.backAction && positions?.some(pos => !pos.begleitscheinId)) {
        positions = positions.map((pos, index) => {
        if (pos.posGrpId) {
          return pos;
        } else {
          const posVerkauf = (this.config.positionsVerkauf || {})[this.depotPosition];
          let fondsnameVerkaufValue = posVerkauf?.length > 1 &&  posVerkauf[0] ?posVerkauf[0] : '';

          return {
            ...pos,
            index: index,
            fondsnameKauf: this.getFondsName(pos),
            fondsnameVerkauf: fondsnameVerkaufValue,
            einmalBetrag: this.getEinmalBetrag(pos),
            begleitscheinId: this.depotPosition,
            lagerstelle: "FODB",
          }}
        });
      
        if (positions?.length) {
          const payload = {
            id: this.antragId,
            categoryId: this.categoryId,
            fonds: positions,
            additionalIdentifier: ['isinKauf', 'isinVerkauf', 'posGrpId'],
          };
          this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
        }
        this.save();
      }
    },
    onChangeInputField($event, position, field) {
      if (position && field) {
        position[field] = $event || null;
        this.commitUpdatePositionsFromEvent(position, position.isinKauf);
      }
    },
    openModalDeleteFond(fond) {
      if (fond) {
        this.positionToDelete = fond;
        this.labelToDelete = `${fond.fondsnameVerkauf} Tausch in ${fond.fondsnameKauf}`;
        this.$refs.deleteModal.open();
      }
    },
    doDeletePosition() {
      this.deletePosition(
        this.antragId,
        this.categoryId,
        this.positionToDelete
      );
      this.positionToDelete = null;
      this.labelToDelete = null;
    },
    setPositionVerkauf(position) {
      const payload = {
        id: this.antragId,
        data: {
          INPUT_DEPOTNUMMER_EINMAL_AUSWAHL: position,
        },
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload);
      this.depotPosition = position;
    },
    einmalBetragDisabled(row) {
      return parse(row?.einmalAnteile) > 0;
    },
  },
};
</script>

<style scoped>
.input-forms__label-content--bigger {
  font-size: 1.5rem;
}
.input-forms__label-content--bigger > i {
  font-size: 2rem;
}
</style>