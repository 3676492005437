<template>
  <BaseButton isClear @click="retrieveTutorials()" class="hide-on-small-screen">
    <ph-question :size="size" />

    <BaseModal modalTitle="Verfügbare Tutorials" ref="savedTutorialsModal" :showConfirmButton="false" :showCancelButton="true" labelButtonCancel='Abbrechen'>
    
        <p v-if="rows.length">
            <b>Bitte wählen Sie einen Tutorial-Titel, um die Präsentation zu starten</b>
        </p>
        <p v-else>
            Keine Tutorials für diese Seite verfügbar
        </p>

        <Table v-if="rows.length"
            :headers="headers"
            :rows="rows"
            :selected="isTableEditable ? selectedRows : undefined"
            @selected="selectedRows = $event"
            @click-title="startTutorial"
            @action-EDIT="editTutorial"
            @action-DELETE="confirmDeleteTutorial"
            :rowsPerPage="10"
        />
    </BaseModal>    

    <BaseModal @onConfirmButton="deleteTutorial()" ref="deleteTutorialConfirmation" modalTitle="Tutorial löschen" labelButtonConfirm="Löschen">
      {{ deleteTutorialMessage }}
    </BaseModal>

  </BaseButton>
  
</template>

<script>
import { PhTrash, PhQuestion, PhPencilLine } from 'phosphor-vue'
import CORE_TYPES from "@/store/core/types";
import LOG_TYPES from '@/store/log/types';
import BaseModal from "@/components/core/BaseModal.vue";
import InteractiveHelpCommonFunctions from "@/assets/mixins/interactivehelpcommonfunctions.js";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import InteractiveHelpMixin from "@/assets/mixins/interactivehelpmixins.js";
import HelpRecordingMixin from "@/assets/mixins/helprecordingmixin.js";
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseButton from '@/components/core/BaseButton.vue';
import { VIEW_ROLES } from '@/router/roles';

export default {
  mixins: [
    InteractiveHelpCommonsMixin,
    InteractiveHelpMixin,
    HelpRecordingMixin,
    InteractiveHelpCommonFunctions,
  ],
  props: {
    size: {
      default: 18
    }
  },
  components: {
    PhTrash,
    PhQuestion,
    BaseModal,
    PhPencilLine,
    Table,
    BaseButton,
  },
  computed: {
    ...mapGetters({
      tutorialRecordedItems: CORE_TYPES.GETTERS.TUTORIAL_RECORDED_ITEMS,
      recordingTutorial: CORE_TYPES.GETTERS.RECORDING_TUTORIAL,
      recordingTutorialTitle: CORE_TYPES.GETTERS.RECORDING_TUTORIAL_TITLE,
      recordedTutorials: CORE_TYPES.GETTERS.RECORDED_TUTORIALS,
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    headers() {
        const headers = {
            lockedLeft: [
                TextColumn("title", "Titel").makeLink(),
            ],
            lockedRight: [],
        }
        if (this.isTableEditable)
            headers.lockedRight.push(ActionColumn("actions", "Aktionen"));
        return headers;
    },
    rows() {
        const actions = [
            SimpleAction("EDIT", '', "Bearbeiten"),
            SimpleAction("DELETE", '', "Löschen"),
        ];
        return (this.recordedTutorials || []).map(rt => ({
            ...rt,
            actions,
        }));
    },
    isTableEditable() {
      // return (this.isByPass && this.isTestCustomer) || this.isTestBroker;
      return this.isIntern;
    },
    deleteTutorialMessage() {
      if (this.selectedRows && this.selectedRows.length) {
        return 'Sollten diese Tutorials wirklich gelöscht werden?';
      } else {
        const tutorialTitle = this.selectedTutorial && this.selectedTutorial.title;
        return tutorialTitle && `Soll das Tutorial "${tutorialTitle}" wirklich gelöscht werden?`;
      }

    },
    isIntern() {
        return this.hasRoles(VIEW_ROLES.VIEW_BROKER_AS_INTERN) || this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN);
    },     
  },
  watch: {
    recordedTutorials: function(newValue, oldValue) {
      this.$refs.savedTutorialsModal.open();
    }
  },  
  methods: {
    closeModal() {
      this.$refs.savedTutorialsModal.close();
    },
    retrieveTutorials() {
      this.$store.dispatch(CORE_TYPES.ACTIONS.RETRIEVE_TUTORIALS, {
        kundennr: this.userid,
        routerPath: this.$route.path
      });
    },  
    startTutorial(tutorial) {
        const items = tutorial.items;
      this.closeModal();

      this.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_TITLE, {
        tutorialTitle: tutorial.title
      });      

      this.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_ROUTE, {
        tutorialRoute: tutorial.routerPath
      });

      this._resetTutorial();

      if (!items || !items.length) {
        return;
      }

      items.forEach(ri => {
        this.elementsIdArray.items.push({
          id: ri.helpPath,
          message: ri.helpMessage,
          routerPath: ri.routerPath,
          stepKey: ri.stepKey,
          element: ri.element,
          autoClick: ri.autoClick,
          noClick: ri.noClick,          
        });
      });

      this.startInteractiveHelp(null);
    },    
    confirmDeleteTutorial(tutorial) {
      this.selectedTutorial = tutorial;
      this.$refs.deleteTutorialConfirmation.open();
    },
    deleteTutorial() {
        if (this.selectedRows && this.selectedRows.length > 0) { 
          this.deleteMultipleTutorials();
        } else {
          this.deleteSingleTutorial();
        }     
    },
    deleteSingleTutorial() {
      if (this.selectedTutorial && this.selectedTutorial.id) {
        const payload = {tutorialId: this.selectedTutorial.id};
        this.$store.dispatch(CORE_TYPES.ACTIONS.REMOVE_TUTORIAL, payload).then(() => {
          this.retrieveTutorials();
        });  

      }
    },
    deleteMultipleTutorials() {
      let itemsDeleted = 0;
      this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, {selectedRows: this.selectedRows});
      this.selectedRows.forEach( t => {

        if (t.id) {
          const payload = {tutorialId: t.id};
          this.$store.dispatch(CORE_TYPES.ACTIONS.REMOVE_TUTORIAL, payload).then(() => {
            itemsDeleted++;
            if (itemsDeleted == this.selectedRows.length) {
              this.selectedRows = [];
              this.retrieveTutorials();
            }
          });   

        } else {
          itemsDeleted++;
        }
      });
    },
    editTutorial(tutorial) {
      this._resetTutorial();

      tutorial.items.forEach(i => {
        let step = {
          helpPath: i.helpPath,
          helpMessage: i.helpMessage,
          routerPath: i.routerPath,
          stepKey: i.stepKey,
          element: i.element,
          canvas: i.canvas,
          autoClick: i.autoClick,
          noClick: i.noClick,
        };

        this.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_TITLE, {
          tutorialTitle: tutorial.title
        });
        this.$store.dispatch(CORE_TYPES.ACTIONS.ADD_RECORDED_ITEM, step);

        this.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_LAST_ID, {
          id: tutorial.id
        });

        this.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_ROUTE, {
          tutorialRoute: tutorial.routerPath
        });

        this.closeModal();

        this.$router.push({ path: "/home/tutorial-recorded-items" }).catch(() => {});
      });
    },   
  },
  data: function () {
    return  {     
        selectedTutorial: null,
        selectedRows: [],
    }
  }
}
</script>

<style scoped>
.middle {
    display: flex;
}
</style>