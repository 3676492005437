<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

        <PageHeaderTitleNavigation 
            :id="$appNavigation.currentOptionMenuId"
            :title="$appNavigation.currentMenu.label">
        </PageHeaderTitleNavigation>

        <BaseFilter
            :filterId="saveKey"
            :configFilter="configFilter"
            :metadata="searchMenu"
            :defaultOptions="defaultOptionsData"
            showSaveButton
            hasSmartSearch
            @onFilter="handleSearch"
        />
        <HandleTablePin keyOfPIN="TABELLE_MAKLER_ANTRAEGE" />

         <div v-if="filtered" class="box__container">
            <Table class="table-container" v-if="!loading && rows && rows.length"
                :title="TABLE_TITLE"
                ref="tableResult"
                tableId="66be2dca-d420-4d21-a093-1adc48dedee8"
                configModalTitle="Vermittlerwechsel Versicherungen"
                :headers="headers"
                :rows="rows"
                :rowsPerPage="25"
                :exportConfig="{roottext: 'Vermittlerwechsel Versicherungen'}"
                :mobileConfig="{title: 'gesellschaft', headers: ['kunde', 'datum', 'pruefstatus']}"
                @click-gesellschaft="navigateToGesellschaft($event.gesellschaftId, $event.gesellschaftArt)"
                @click-polNr="openInsurance($event, 'allgemeine')"
                @click-document="downloadFiles"
                @click-kunde="openCustomerNewTab"
                @click-makler="openBrokerNewTab($event.maklernr, '/home/broker-overview')"
                @click-maklernr="openBrokerNewTab($event.maklernr, '/home/broker-overview')"
                @action="handleTableAction($event)"
                @click-courtageErhalten="openInsurancesNewTab($event, 'courtage')"
            >
         
            <template v-slot:countMailsSend="row">  
                <div v-if="row && row.countMailsSend === 'Keine'">     
                    {{row.countMailsSend}}
                </div>
                <div v-else>
                    <a @click="goToEmailsList(row)">{{row.countMailsSend}}</a>
                </div>
            </template>

            <template v-slot:pruefstatus="row">  
                <div v-if="istBearbeiter">
                    <ComboBox
                        :value="row.pruefstatus" 
                        :values="comboboxValuesStatus"
                        @change="checkStatus('pruefstatus', $event, row)">
                    </ComboBox>
                </div>     
                <div v-else>
                    <Pill :type="'info'" v-fc-tooltip="row.tooltip"
                        :label="getStatusLabel(row)"></Pill>
                </div>
            </template>
           
            <template v-slot:kd="row">  
                <InputToggleSwitch :value="row.kd" inLineLabel suppressValidationMessage
                    :disabled="!istBearbeiter" @input="saveData('kd', $event, row)" />
            </template>
           
            </Table>
            <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
            <NoData v-else :title="TABLE_TITLE" />
        </div>

        <BaseModal ref="hatFehler" :showCancelButton="false" labelButtonConfirm="Ok">
            <template v-slot:modalTitle>
                <ph-warning :size="16" class="mr-2" style="color: orangered"/>
                Fehler
            </template>
            <div>
                Wählen Sie bitte einen Status: geprüft, nicht geprüft, nicht versendet, erledigt, selbst gesendet, abgelehnt.
            </div>
        </BaseModal>
        <BaseModal ref="infoMaklervertrag" :showCancelButton="false" labelButtonConfirm="Ok" @onConfirmButton="closeInfoModal">
            <template v-slot:modalTitle>
               {{infoTitle}}
            </template>
            <div v-if="infoText && infoText.length">
                <div class="mt-1" v-for="(line, index) of infoText" :key="index">
                    {{line}}
                </div>
            </div>
        </BaseModal>
        <BaseModal ref="emptyFile" :showCancelButton="false" labelButtonConfirm="Ok">
            <template v-slot:modalTitle>
               <ph-warning :size="16" class="mr-2" style="color: orangered"/>Fehler
            </template>
            <div>Es liegen keine Dokumente vor.</div>
        </BaseModal>
        <BaseModal ref="errorFile" :showCancelButton="false" :showCloseButton="false" labelButtonConfirm="Schließen"
            @onConfirmButton="closeErrrorModal()">
            <template v-slot:modalTitle>
               <ph-warning :size="16" class="mr-2" style="color: orangered"/>Fehler
            </template>
            <div>{{errorStr || 'Dokument kann nicht geöffnet werden.'}}</div>
            <div v-if="files && files.length">
                <div class="mt-4">Dokumente:</div>
                <div v-for="(file, index) of files" :key="index">
                    <a @click="openFile(file)">{{file.fileName}}</a>
                </div>
            </div>
        </BaseModal>
        <BaseModal ref="deleteAuftragModal" labelButtonConfirm="Ja" labelButtonCancel="Nein"
            @onConfirmButton="removeEintrag(toDelete.id)" @onCancelButton="closeDeleteModal()" @onCloseButton="closeDeleteModal()">
            <template v-slot:modalTitle>
               <ph-warning :size="16" class="mr-2" style="color: orangered"/>Eintrag löschen
            </template>
            <div v-if="toDelete">Soll der Eintrag für {{toDelete.gesellschaft}} von {{toDelete.kunde}} ({{toDelete.kundennr}}) vom {{toDelete.datum}}, Vertrag {{toDelete.polNr}} wirklich gelöscht werden?</div>
        </BaseModal>
        <BaseModal ref="resetVersandModal" labelButtonConfirm="Ja" labelButtonCancel="Nein"
            @onConfirmButton="resetVersand()">
            <template v-slot:modalTitle>
               <ph-warning :size="16" class="mr-2" style="color: orangered"/>Makleraufträge erneut versenden
            </template>
            <div>Es werden alle angezeigten Makleraufträge, die noch nicht abgeschlossen sind so versendet als wären sie neu hochgeladen worden.</div>
        </BaseModal>
        <BaseModal ref="emptyModal" labelButtonCancel="Ok" :showConfirmButton="false">
            <template v-slot:modalTitle>
               <ph-warning :size="16" class="mr-2" style="color: orangered"/>Keine Makleraufträge
            </template>
            <div>Keine Makleraufträge angezeigt.</div>
        </BaseModal>
        <BaseModal ref="bestandsuebertragungStatusEditModal" :showConfirmButton="true" @onConfirmButton="sendBestandsuebertragungStatusEdit()">
            <template v-slot:modalTitle>
               Bemerkung für Status
            </template>
            <div>
            <InputField 
                isComponentHalfSize
                placeholder="Bemerkung für Status"
                v-model="bemerkungFurStatus"/>

            </div>
        </BaseModal>     
        <BaseModal ref="auftragStornierenModal" labelButtonConfirm="Ja" labelButtonCancel="Nein"
            @onConfirmButton="auftragStornieren">
            <template v-slot:modalTitle>
               <ph-warning :size="16" class="mr-2" style="color: orangered"/>Auftrag stornieren
            </template>
            <div>
                Durch die manuelle Stornierung wird der Auftrag als "Nicht durchgefürht" markiert. Der Maklerauftrag wird dann nicht weiter an die 
                Gesellfschaft geschickt. Möchten Sie den Auftrag wirklich stornieren?
            </div>
        </BaseModal>
        <BaseModal 
        ref="auftragBestaetigungModal" 
        labelButtonConfirm="Ja"
        labelButtonCancel="Nein"
        @onConfirmButton="auftragBestaetigung">
            <template v-slot:modalTitle>
               <ph-warning :size="16" class="mr-2" style="color: orangered"/>Auftrag bestätigen
            </template>
            <div>
                Durch die manuellen Bestätigung wird der Auftrag als "Erfolgreich" markiert. Der Maklerauftrag wird dann nicht weiter an die Gesellschaft 
                geschickt. Möchten Sie den Auftrag wirklich bestätigen?
            </div>
        </BaseModal>
    </div>
</template>

<script>

import BaseFilter from '@/components/core/BaseFilter.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn, NumberColumn, ActionColumn, SimpleAction, SlotColumn, DateColumn, IconColumn, Icon, } from "@/components/table2/table_util.js";
import Pill from '@/components/core/Pill.vue';
import { PhPencilLine, PhFileText, PhWarning } from 'phosphor-vue';
import AUFTRAEGE_TYPES  from '@/store/maklerauftraege/types';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import BaseModal from "@/components/core/BaseModal.vue";
import stornosMixin from '@/mixins/stornos/stornos-mixin.js';
import navigationMixin from '@/mixins/navigation-mixin.js';
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES, VIEW_ROLES } from '@/router/roles';
import { openFile } from '@/components/table/export/utils';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import HandleTablePin from '@/components/core/HandleTablePin.vue';
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';

const TABLE_TITLE = 'Vermittlerwechsel Versicherungen';

export default {
    mixins: [stornosMixin, navigationMixin],
    components: {
        Table,
        Pill,
        BaseModal,
        ComboBox,
        InputToggleSwitch,
        BaseButton,
        PhPencilLine,
        PhFileText,
        PhWarning,
        InputField,
        OptionMenu,
        PageHeaderTitleNavigation,
        GhostLoading,
        NoData,
        HandleTablePin,
        BaseFilter,
    },
    data() {
        return {
            TABLE_TITLE,
            saveKey: "maklerauftraegeSearch",
            configFilter: { 
                placeholderForDefSearchInput: 'Kundennummer, Kundenname',
                noResetOnDefaultSearchInputExit: true,
                defaultSearchInputKeys: ['FILTER_KUNDE'],
                filterType: 'wertpapierinfo',
            },
            selectedFilters: null,
            rowsPerPage: 20,
            infoTitle: null,
            infoText: null,
            currentFilename: null,
            unsignedAttachments: null,
            signedAttachments: null,
            comboboxValuesStatus: [{value: 'ANGELEGT:', label: 'nicht hochgeladen'},
				{value: 'PRUEFEN', label: 'Zu prüfen'},
				{value: 'VERSENDEN', label: 'Erwarte Bestätigung'},
				{value: 'FEHLGESCHLAGEN', label: 'Nicht durchgeführt'},
				{value: 'UEBERTRAGEN', label: 'Erfolgreich'}],
            errorStr: '',
            files: null,
            toDelete: null,
            loading: false,
            filtered: false,
            bemerkungFurStatus: '',
            statusData: {},
            uebertragVertragId: null,
        }
    },
    mounted() {
        const filters = { FILTER_ZEIGE_ZU_VERSENDENDE: true, FILTER_ZEIGE_UNGEPRUEFTE: true, FILTER_VALUE_MODUS_SEITBEGINN: true, FILTER_TYP: 'alle'};
        this.$store.commit(AUFTRAEGE_TYPES.MUTATIONS.SET_DEFAULT_FILTERS, filters)
        this.$store.dispatch(AUFTRAEGE_TYPES.ACTIONS.IST_BEARBEITER)
    },
    computed: {
        ...mapGetters({
            auftraegeStore: AUFTRAEGE_TYPES.GETTERS.AUFTRAEGE,
            istBearbeiterStore: AUFTRAEGE_TYPES.GETTERS.IST_BEARBEITER,
            hasEstimatedDoneStore: AUFTRAEGE_TYPES.GETTERS.HAS_ESTIMATED_DONE,
            defaultFiltersStore: AUFTRAEGE_TYPES.GETTERS.DEFAULT_FILTERS,
            isFA: CORE_TYPES.GETTERS.IS_FA,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        canViewCourtageStep(){
            return this.hasRoles([
                [VIEW_ROLES.VIEW_BROKER],
                [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER],
                [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN],
            ]);
        },        
        zugriffKunden() {
            return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]])
        },
        istBearbeiter() {
            return this.istBearbeiterStore;
        },
        hasEstimatedDone() {
            return this.hasEstimatedDoneStore;
        },
        columnActions() {


            let result = [
                SimpleAction("dokument", '', "Das Dokument anzeigen"),
            ];

            if (this.istBearbeiter) {
                result.push( SimpleAction("changeLog", '', "Änderungslog anzeigen"));
                result.push( SimpleAction("delete", '', "Eintrag löschen"));
            }

            result.push( SimpleAction("archive", '', "Dokumentenarchiv der Versicherung öffnen"));
            result.push( SimpleAction("stornieren", '', "Auftrag stornieren"));
            result.push( SimpleAction("bestaetigung", '', "Auftrag bestätigen"));


            return result;
        },
        searchMenu() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: this.menuItemsAllgemein
                },
                {
                    type: 'group',
                    key: 'zeitraum',
                    label: 'Anträge in angegebenen Zeitraum',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Übertragungen der letzten Tage',
                            key: 'FILTER_DATUM_LETZTE_TAGE',
                        },
                        {
                            type: 'dateRange',
                            label: 'Übertragungen im angegebenen Zeitraum',
                            key: 'ZEITRAUM',
                        },
                        {
                            type: 'default',
                            label: 'Übertragungen seit Beginn',
                            key: 'FILTER_VALUE_MODUS_SEITBEGINN',
                        },
                    ]
                },
                {
                    type: 'group',
                    key: 'status',
                    label: 'Status',
                    menuItems: [
                        {
                            type: 'default',
                            label: 'Ungeprüfte Übertragungen zeigen',
                            key: 'FILTER_ZEIGE_UNGEPRUEFTE',
                        },
                        {
                            type: 'default',
                            label: 'Zu versendende Übertragungen zeigen',
                            key: 'FILTER_ZEIGE_ZU_VERSENDENDE',
                        },
                        {
                            type: 'default',
                            label: 'Abgeschlossene Übertragungen zeigen',
                            key: 'FILTER_ZEIGE_FERTIGE',
                        },
                    ]
                },
            ]
        },
        menuItemsAllgemein() {
            const items = [
                {
                    type: 'text',
                    label: 'Kundenname / -nummer',
                    key: 'FILTER_KUNDE',
                },
                {
                    type: 'text',
                    label: 'Gesellschaft',
                    key: 'FILTER_GESELLSCHAFT',
                },
                {
                    type: 'text',
                    label: 'Vers. Nr.',
                    key: 'FILTER_POLNR',
                },
            ]
            if (this.istBearbeiter) {
                items.push( 
                    {
                        type: 'text',
                        label: 'Nur Kunden der Maklerstruktur',
                        key: 'FILTER_MAKLERNR_STRUKTURLEITER',
                    },
                    {
                        type: 'combobox',
                        label: 'Übertragungstyp',
                        key: 'FILTER_TYP',
                        comboOptions: [{ label: 'Alle', value: 'alle'}, { label: 'Makleraufträge', value: 'MA'}, { label: 'MFA-Übertragung', value: 'MEHRFACHAGENT'}],
                    },
                );
            }
            return items
        },
        defaultOptionsData() {
            return {
                FILTER_ZEIGE_UNGEPRUEFTE: {
                    value: "",
                },
                FILTER_ZEIGE_ZU_VERSENDENDE: {
                    value: "",
                },        
                FILTER_ZEIGE_FERTIGE: {
                    value: "",
                }, 
                FILTER_DATUM_LETZTE_TAGE: {
                    value: "30",
                }, 
                
            }; 
        }, 
        filters() {
           return this.defaultFiltersStore || {}
        }, 
        auftraege() {
            return this.auftraegeStore || [];
        },
        headers() {

            let result = {
              lockedLeft: [
                IconColumn("document", "").makeLink(),
                  SlotColumn("pruefstatus", "Status").makeSortable().withExporter(cell => cell),
                  DateColumn("datum", "Datum"),
                  TextColumn("gesellschaft", "Gesellschaft").addCellProps({lineClamp: 2}).makeLink(),
                  TextColumn("kunde", "Kunde").makeLink(this.zugriffKunden).addCellProps({lineClamp: 2}).makeSortable().withExporter(cell => cell),
                ],
                center: [
                    TextColumn("sparte", "Sparte"),
                    TextColumn("polNr", "VSNR").makeLink(),
                    SlotColumn("countMailsSend", "# Mailsvers.").makeSortable().withExporter(cell => cell),
                ],
                lockedRight: [
                    SlotColumn("tooltip", "Statushinweis").makeSortable().withExporter(cell => cell),
                    ActionColumn("actions", "Aktionen"),
                ],
            };
            
            if (this.hasEstimatedDone) {
                result.lockedLeft.push(TextColumn("estimatedDone", "gesch. Dauer"));
            }
            
            if (this.istBearbeiter) {
                result.lockedLeft.push(TextColumn("art", "Art"));
            }      
            
            result.center.push(TextColumn("nextSend", "nächster Versand"));
            result.center.push(TextColumn("vNummerBemerkung", "V-Nummer Bemerkung"));
            result.center.push(TextColumn("vNummer", "V-Nummer"));
            result.center.push(TextColumn("makler", "Makler").makeLink());
            result.center.push(TextColumn("maklernr", "Maklernr").makeLink());
            result.center.push((this.canViewCourtageStep ? TextColumn("courtageErhalten", "Courtage AP erhalten").makeLink() : TextColumn("courtageErhalten", "Courtage AP erhalten")));
            result.center.push(TextColumn("bpcourtageErhalten", "Courtage BP erhalten"));                  
            result.center.push(TextColumn("kundennr", "Kundennr"));
            result.center.push(SlotColumn("kd", "Korrespondenz"));
            result.center.push(DateColumn("gdv_update", `${this.isFA ? 'OMDS' : 'GDV'} aktualisiert`));
            result.center.push(TextColumn("lastUpdate", "letzte Änderung"));
            result.center.push(DateTimeColumn("erstellt", "erstellt"));
            result.center.push(DateTimeColumn("geschlossen", "geschlossen"));
            result.center.push(NumberColumn("positionen", "Positionen"));

            return result;
        },
        rows() {

            return this.auftraege.map(a => {
                return {
                    ...a,
                    document: [Icon('PhFileArrowDown')],
                    actions: this.columnActions
                }
            });


        },
    },
    methods: {
        openModalConfig() {
            this.$refs.tableResult.openColumnsConfig()
        },
        async handleSearch(filterParams) {
            const filters = {}

            if (Array.isArray(filterParams)) {
                filterParams.forEach(fc => filters[fc.key] = fc.value)
            } else {
                Object.assign(filters, filterParams);

            }
      
            if (filters?.customerId) {
                filters.FILTER_KUNDE = filters.customerId;
            }
            this.selectedFilters = Object.assign({}, filters);
            this.$store.commit(AUFTRAEGE_TYPES.MUTATIONS.SET_DEFAULT_FILTERS, filters)
            try {
                this.filtered = true;
                this.loading = true;
                await this.$store.dispatch(AUFTRAEGE_TYPES.ACTIONS.GET_AUFTRAEGE, { filters: filters });  
            } finally {
                this.loading = false;
            }
        },
        closeInfoModal() {
            this.infoTitle = null;
            this.infoText = null;
        },
        handleTableAction(event) {
            const id = event?.row?.id;
            const versUebertragId = event?.row?.versUebertragId;

            if (event?.key === 'dokument') {
                this.downloadFiles(event.row)
            } else if (event?.key === 'delete') {
                this.toDelete = event.row;
                this.$refs.deleteAuftragModal.open();
            } else if (event?.key === 'archive') {
                this.openInsurance(event.row, 'dokumente')
            } else if (event?.key === 'changeLog') {
                this.$router.push({path: '/home/maklerauftraege/aenderungslog', query: {versUebertragId}});
            } else if (event?.key === 'stornieren') {
                this.uebertragVertragId = id;
                this.$refs.auftragStornierenModal.open();
            } else if (event?.key === 'bestaetigung') {
                this.uebertragVertragId = id;
                this.$refs.auftragBestaetigungModal.open();
            }
        },
        auftragStornieren() {
            if (this.uebertragVertragId) {
                this.$store.dispatch(AUFTRAEGE_TYPES.ACTIONS.AUFTRAG_STORNIEREN, {id: this.uebertragVertragId }).then(() => {
                    this.handleSearch(this.selectedFilters);
                });
            }
        },
        auftragBestaetigung() {
            if (this.uebertragVertragId) {
                this.$store.dispatch(AUFTRAEGE_TYPES.ACTIONS.AUFTRAG_BESTAETIGUNG, {id: this.uebertragVertragId }).then(() => {
                    this.handleSearch(this.selectedFilters);
                });
            }
        },
        getStatusLabel(item) {
            return item?.pruefstatus && this.comboboxValuesStatus.find(elem => elem.value === item.pruefstatus)?.label;
        },
        openInsurance(item, stepKey) {
            if (item?.vertragId) {
                this.openInsurancesNewTab({ versId: item.vertragId, kundennr: item.kundennr, kundenVorname: '',  kundenName: '' }, stepKey);
            }
        },
        closeErrrorModal() {
            this.errorStr = null;
            this.files = null;
            this.$refs.errorFile.close();
        },
        openFile(file) {
            if (file) {
                openFile(file.bytes, file.componentType, file.fileName)
            }
        },
        removeEintrag(id) {
            id && this.$store.dispatch(AUFTRAEGE_TYPES.ACTIONS.REMOVE_AUFTRAG, { id: id });
            this.closeDeleteModal();
        },
        closeDeleteModal() {
            this.toDelete = null;
            this.$refs.deleteAuftragModal.close();
        },
        resetVersand() {
            const ids = this.tableData?.records?.map(auftrag => auftrag.id);
            if (ids?.length) {
                this.$store.dispatch(AUFTRAEGE_TYPES.ACTIONS.RESET_VERSAND, { ids: ids });
            }
        },
        sendBestandsuebertragungStatusEdit() {
            this.saveData(this.statusData?.compId, this.statusData?.value, this.statusData?.row, this.bemerkungFurStatus);
        },
        showBemerkungFurStatusModal() {
            this.$refs.bestandsuebertragungStatusEditModal.open();
        },
        checkStatus(compId, value, row) {
            if (value == 'FEHLGESCHLAGEN') {
                this.showBemerkungFurStatusModal();
                this.statusData = {compId, value, row};
            } else {
                this.saveData(compId, value, row);
            }
        },
        saveData(compId, value, row, bemerkungFurStatus) {
            if (row?.id) {
                this.$store.dispatch(AUFTRAEGE_TYPES.ACTIONS.UPDATE_AUFTRAG, { id: row.id, [compId]: value, bemerkungFurStatus });
            }
        },
        goToEmailsList(row) {
            if(!row) {
                return;
            }
            this.$router.push({path: '/home/maklerauftraege/emailliste', query: {versUebertragId: row?.id, insuranceId: row?.vertragId, userId: row?.kundennr}});
        },
        downloadFiles({id}) {
            if (id) {
                this.$store.dispatch(AUFTRAEGE_TYPES.ACTIONS.GET_PDF_LIST, { id }).then(response => {
                    if (response?.errorStr) {
                        this.errorStr = response.errorStr;
                        this.files = response.files;
                        this.$refs.errorFile.open();
                    } else if (response?.files?.length) {
                        response.files.forEach(file => openFile(file.bytes, file.contentType, file.fileName));
                    } else {
                        this.$refs.emptyFile.open()
                    }
                })
                .catch(() => {
                    this.errorStr = null;
                    this.files = null;
                    this.$refs.errorFile.open();
                })
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit(AUFTRAEGE_TYPES.MUTATIONS.SET_DEFAULT_FILTERS, null)
        this.$addBreadcrumb({
            label: 'zurück zu den Makleraufträgen', 
            to,
            from,
        });
        next()
    }
}
</script>

<style>

</style>