<template>
  <div>
    <GhostLoading v-if="loading" type="table" :title="title" />
    <Table
      v-else
      :title="title"
      :headers="headers"
      :rows="rows"
    />
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import FEE_TYPES from '@/store/fee/types';
import CORE_TYPES from '@/store/core/types';
import DOCUMENT_TYPES from '@/store/documents/types';

import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from '@/components/table2/Table.vue';
import { TextColumn, DateColumn, NumberColumn, ActionColumn, LinkAction, } from '@/components/table2/table_util.js';

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      abrechnungen: FEE_TYPES.GETTERS.ABRECHNUNGEN,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      simpleFileLinkmaker: DOCUMENT_TYPES.GETTERS.GET_SIMPLE_FILE_LINK_MAKER,
    }),
    headers() {
      return {
        center: [
          TextColumn('invoiceNr', 'Rechnungsnr'),
          TextColumn('title', 'Typ'),
          TextColumn('status', 'Status'), // TODO add isCustomer
          DateColumn('beginDateFee', 'von'),
          DateColumn('endDateFee', 'bis'),
          TextColumn('periodFee', 'Zeitraum'),
          NumberColumn('amountNetto', 'Gebühr netto', 2),
          NumberColumn('amountBrutto', 'Gebühr brutto', 2),
          NumberColumn('mwstSumme', 'MwSt.', 2),
          NumberColumn('rueckerstattung', 'Rückerstattung', 2),
          NumberColumn('abrechnungssumme', 'Abrechnungssumme', 2),
          NumberColumn('erstattungPeriode', 'erstattet vorherige Periode', 2),
          TextColumn('kundenIban', 'IBAN'),
          TextColumn('kundenBic', 'BIC'),
          TextColumn('kundenBank', 'Bank'),
          TextColumn('kundenKontoinhaber', 'Kontoinhaber'),
        ],
        lockedRight: [
          ActionColumn('actions', 'Aktionen'),
        ],
      };
    },
    rows() {
      return [ ...this.abrechnungen || [] ].map(row => ({
          ...row,
          actions: [
            LinkAction('DOWNLOAD', '', 'Download', this.simpleFileLinkmaker(row.fileName, row.fileId)),
          ],
        }));
    },
  },
  methods: {
    async findAbrechnungen() {
      try {
        this.loading = true;
        await this.$store.dispatch(FEE_TYPES.ACTIONS.FIND_ABRECHNUNGEN);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.findAbrechnungen();
  },
  components: {
    GhostLoading,
    Table,
  },
}
</script>
