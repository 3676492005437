<template>
  <div>
    <BaseModal
      ref="bestellungenModal"
      size='lg'
      :modalTitle="`Bestellungen ${bestellungenData.bestellungName}`"
      labelButtonConfirm="Bestellen "
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()"
      @onCloseButton="onCancelModal()"
    >

    <template v-if="bestellungenData && bestellungenData.rows">
      <div>
        <div class="row">
          <div class="col-12">
            <p>Soll Ihnen eine E-Mail mit einem Link zu der PDF Datei der {{bestellungenData.bestellungName}} für folgende Kunden zugesandt werden?</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <Table v-if="rows && rows.length"
                tableId="46fbd016-475e-47c5-8abf-a9e074527bb2"
                :headers="headers"
                :rows="rows"
                :rowsPerPage="20"
                @action-delete="confirmDeleteCustomer($event.data)"
            />
            <NoData v-else-if="rows && rows.length === 0" />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <hr>
            <p v-if="bestellungenData.modus==='QB'">Achtung, die Datei kann je nach Anzahl der Kunden sehr groß werden (pro Kunde ca. 150KB). Bei zu großen Dateien werden die PDF Dateien geteilt und Ihnen in der E-Mail mehrere Links zugesandt.</p>
            <p v-if="bestellungenData.modus==='DA'">Achtung, die Datei kann je nach Anzahl der Kunden sehr groß werden (pro Kunde ca. 50KB). Bei zu großen Dateien werden die PDF Dateien geteilt und Ihnen in der E-Mail mehrere Links zugesandt.</p>

            <hr>
          </div>    
        </div>
        <div class="row" v-if="bestellungenData.modus==='DA'">
          <div class="col-12">
            <DatePickerField
              isValueAsString
              isComponentHalfSize
              label="Stand der Depotauszüge"
              validateUntouched
              v-model="inputStand"
            />           
          </div>    
        </div>          
        <div class="row" v-if="bestellungenData.modus==='DA'">
          <div class="col-12">
            <hr>
            <p>Wenn Sie ein Datum in der Vergangenheit angeben, dann wird Ihnen anstelle der aktuellen Depotauszüge historische Depotauszüge erstellt. Das Datum darf maximal 3 Jahre in der Vergangenheit liegen.</p>
            <hr>
          </div>    
        </div>        
      </div>
    </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/core/BaseModal.vue";
import { mapGetters } from 'vuex';
import QUARTALSBERICHTE_BESTELLUNGEN_TYPES from '@/store/quartalsberichteBestellungen/types';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';

export default {
  components: {
    BaseModal,
    Table,
    NoData,
    DatePickerField,
  },
  data() {
    return {
      customers: null,
      changingPosition: false,
      selectedCustomers: [],
      inputStand: null,
    };
  },
  computed: {
    ...mapGetters({
      bestellungenData: QUARTALSBERICHTE_BESTELLUNGEN_TYPES.GETTERS.BESTELLUNGEN_DATA,
      executeOrdersResult: QUARTALSBERICHTE_BESTELLUNGEN_TYPES.GETTERS.EXECUTE_ORDERS_RESULT,
    }), 
    headers() {
        return {
              lockedLeft: [
                  TextColumn("kundennr", "Kundennr"),
              ],
              center: [
                  TextColumn("fullName", "Name"),
              ],
              lockedRight: [
                  ActionColumn("actions", "Aktionen"),
              ],
          };
    },
    rows() {
      if (!this.bestellungenData?.rows) {
        return [];
      }

      const actions = [SimpleAction("delete", '', "Löschen")];

      const rows = this.bestellungenData?.rows?.map((r,index) => {
        return {
          [r[0].key]: r[0].value,
          [r[1].key]: r[1].value,
          rowIndex : index,
          actions,
        }
      });
      return rows;
    },
  },
  methods: {
    open() {
      this.$refs.bestellungenModal.open();
    },
    onConfirmModal() {

      this.selectedCustomers = [];

      this.rows.forEach(r => {
        this.selectedCustomers.push(r.kundennr);
      });

      this.$emit("save", 
        {
          customers: this.selectedCustomers, 
          inputStand: this.inputStand,
          bestellungModus: this.bestellungenData.modus==='QB' ? 'action_orderqb' : 'action_orderda'
        }
        );
        
      this.$refs.bestellungenModal.close();
    },
    onCancelModal() {
      this.$refs.bestellungenModal.close();
    },
    confirmDeleteCustomer(row) {
      this.rows.splice(row?.rowIndex, 1);

    },
  },
}
</script>
