<template>
  <TeleportToBody>
    <ResizableModal 
      ref="resizablePopup" 
      :modalTitle="modalTitle" 
      :initialWidth="initialWidth"
      :initialHeight="initialHeight"
      :sizeIsPercentage="sizeIsPercentage"
      :startPosition="startPosition"
      :id="id"
      :fullClientHeight="fullClientHeight"
      :saveLastPosition="shouldSaveLastPosition"
      :withBackdropLayer="withBackdropLayer"
      @onRestore="onRestore" 
      @onMinimize="onMinimize" 
      @onMaximize="onMaximize" 
      @onResize="resizeObjectContainer"
      @onClose="cleanUpMemory">

        <div ref="thumbnails" id="thumbnails" class="thumbnails-layer" :class="{ 'visible': showThumbnails }"></div>

        <!-- Additional header buttons -->
         <template #toolbar-buttons-left>
            <template v-if="usePDFJs && !isMinimized">
              <button ref="triggerThumbnails" type="button" class="base-modal__header--button clickable"  @click="toggleThumbnailsLayer()" :disabled="errorLoading">
                <PhList  :size="24" alt="Thumbnails" />
              </button>

              <button type="button" class="base-modal__header--button clickable" @click="zoomIn()" :disabled="errorLoading">
                <PhMagnifyingGlassPlus :size="24" alt="Icon zum Vergrößern" />
              </button>
              <button type="button" class="base-modal__header--button clickable"  @click="zoomOut()" :disabled="errorLoading">
                <PhMagnifyingGlassMinus :size="24" alt="Icon zum Verkleinern" />
              </button>

            </template>
         </template>

         <template #toolbar-buttons-center>
            <template v-if="usePDFJs && !isMinimized">
              <button type="button" class="base-modal__header--button navigator clickable" @click="prevPage()" :disabled="isProcessingPDF || pageNum <= 1 || errorLoading" v-if="pageCount > 1">
                <PhCaretLeft :size="24" alt="Icon zum Klicken auf die vorherige Seite"/>
              </button>
              <span class="current__pdf__page" v-if="pageCount > 1">{{ pageNum }}</span>
              <button type="button" class="base-modal__header--button navigator clickable" @click="nextPage()" :disabled="isProcessingPDF || pageNum >= pageCount || errorLoading" v-if="pageCount > 1">
                <PhCaretRight :size="24" alt="Icon zum Klicken auf die nächste Seite"/>
              </button>
            </template>
         </template>

         <template #toolbar-buttons-right>
            <template v-if="usePDFJs && !isMinimized">
              <button type="button" class="base-modal__header--button clickable"  @click="printPDF()" :disabled="errorLoading">
                      <PhPrinter :size="24" />
                    </button>
                    <button type="button" class="base-modal__header--button clickable" v-if="signatureUrlMetadata" >
                      <DownloadLink target="_blank" rel="noopener noreferer" 
                          :filename="signatureUrlMetadata.fileName"
                          :disabled="errorLoading"
                          :downloadServicePath="signatureUrlMetadata.downloadServicePath"
                          isSecondary
                          :queryParams="signatureUrlMetadata.queryParams"
                      >
                        <PhDownload :size="24" />
                      </DownloadLink>                    
              </button> 

            </template>
         </template>

        <!-- Main content for the popup -->
        <GhostLoading v-if="(isProcessingPDF || !objectUrl) && !errorLoading">
          <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />
              <Block type="paragraph" />

        </GhostLoading>
        
        <div class="bug__icon__div" v-if="errorLoading">
          <h1>Ups!</h1>
          <p>
            Da ist etwas schiefgelaufen!
          </p>
          <UndrawLocationSearch />
        </div>
        
        <div class="object-container" ref="objectContainer" v-if="!errorLoading" @scroll="handleScroll" @wheel="handleWheel">
          <canvas v-if="usePDFJs" v-show="!isProcessingPDF" ref="pdfCanvas"></canvas>

          <iframe v-else v-show="!isProcessingPDF" :src="objectUrl" width="100%" height="100%" @load="loading=false">
            Der Browser unterstützt das Format nicht. Bitte laden Sie die Datei herunter: <a :href="objectUrl">Herunterladen</a>.
          </iframe>

        </div>
    </ResizableModal>
  </TeleportToBody> 
</template>

<script>
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
GlobalWorkerOptions.workerSrc = '/web-workers/pdf.worker.min.js'; // Adjust the path as needed


import ResizableModal from '@/components/core/ResizableModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import TeleportToBody from '@/components/core/teleport/TeleportToBody.vue';
import { PhCaretLeft , PhCaretRight , PhMagnifyingGlassPlus, PhMagnifyingGlassMinus, PhPrinter, PhDownload, PhList  } from 'phosphor-vue';
import LOG_TYPES from '@/store/log/types';
import Vue from 'vue';
import axios from "axios";
import { downloadLinkMaker } from '@/helpers/utils-helper';
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import { buildMessage, } from '@/helpers/log-message-helper';
import UndrawLocationSearch from '@/components/icons/undraw/UndrawLocationSearch.vue'

export default {
  name: 'PreviewPDF',
  components: {
    ResizableModal,
    GhostLoading,
    Block,
    TeleportToBody,
    PhMagnifyingGlassPlus,
    PhMagnifyingGlassMinus,
    PhCaretLeft, 
    PhCaretRight,
    PhPrinter,
    PhDownload,
    DownloadLink,
    UndrawLocationSearch,
    PhList,
  },  
  props: {
    modalTitle: {
      default: 'Objekt-Vorschau'
    },
    initialHeight: {
      type: Number,
      default: 400, 
    },
    initialWidth: {
      type: Number,
      default: 600, 
    }, 
    objectUrl: {
      type: String,
    },
    sizeIsPercentage: {
      type: Boolean,
      default: false, 
    },
    startPosition: {
      type: String,
      default: 'TOP_LEFT',
      validator(value) {
        return ['TOP_RIGHT', 'TOP_LEFT', 'BOTTOM_RIGHT', 'BOTTOM_LEFT'].includes(value);
      }
    },
    fullClientHeight: {
      type: Boolean,
      default: false, 
    },
    id: {
      type: String,
      required: true,
      validator: (value) => {
        const notEmpty = !!value && !!value.trim();
        return notEmpty;
      },
    },
    saveLastPosition: {
      type: Boolean,
      default: false, 
    }, 
    signatureUrlMetadata: {
      type: Object,
    },
    withBackdropLayer: {
      type: Boolean,
      default: false, 
    },    
  },  
  data() {
    return {
      loading: true,
      loadingSignatures: true,
      pdfDoc: null,
      pageNum: 1, // Current page
      pageCount: 0, // Total number of pages
      scale: 1.0,
      isMinimized: false,
      signatureData: [],
      errorLoading: false,
      lastScrollTime: null,
      lastScrollTop: null,
      edgeTimer: null,
      edgeTimeout: 400, // how long to wait at edge before accepting next scroll as intent
      isAtTop: false,
      isAtBottom: false,
      scrollIntentDetected: false,
      showThumbnails: false,
      thumbnailsAlreadyGenerated: false,
      shouldEtractSignaturesMetadata: true,
      signaturesMetadata: null,
      cachedSignatures: [],
    };
  },
  computed: {
    shouldFetchSignature() {
      return this.signatureUrlMetadata?.fetchSignatures && !this.signaturesMetadata || (this.signaturesMetadata?.hasSignatures && this.signaturesMetadata?.signaturePages?.find(sp => sp == this.pageNum));
    },
    isProcessingPDF() {
      return this.loading || this.loadingSignatures;
    },
    shouldSaveLastPosition() {
      return !this.isMinimized && this.saveLastPosition;
    },
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    },
    looksLikePDF() {
      if (!this.objectUrl) { return; }
      // Extract the URL before any query parameters
      const baseUrl = this.objectUrl.split('?')[0];
      // Check if the base URL ends with '.pdf', ignoring any case sensitivity
      return baseUrl.match(/\.pdf$/i) !== null;
    },
    usePDFJs() {
      return /* this.isIOS && */ this.looksLikePDF;
    },
  },
  methods: {
    onClickOutside(e) {
        if (!this.$refs.triggerThumbnails.contains(e.target) && !this.$refs.thumbnails.contains(e.target)) {
            this.showThumbnails = false;
        }
    },
    handleScroll() {
      const el = this.$refs.objectContainer;
      const scrollTop = el.scrollTop;
      const scrollHeight = el.scrollHeight;
      const clientHeight = el.clientHeight;

      const atTop = scrollTop <= 0;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 1;

      if (!atTop && !atBottom) {
        this.resetEdgeDetection();
        return;
      }

      // First arrival at top or bottom
      if ((atTop && !this.isAtTop) || (atBottom && !this.isAtBottom)) {
        this.isAtTop = atTop;
        this.isAtBottom = atBottom;
        this.scrollIntentDetected = false;

        clearTimeout(this.edgeTimer);
        this.edgeTimer = setTimeout(() => {
          this.scrollIntentDetected = true;
        }, this.edgeTimeout);
      }

    },
    handleWheel(event) {
      const scrollingDown = event.deltaY > 0;
      const scrollingUp = event.deltaY < 0;

      if (!this.scrollIntentDetected || this.loading) return;

      if (scrollingDown && this.isAtBottom && this.pageNum < this.pageCount) {
        this.nextPage();
        this.resetEdgeDetection();
      } else if (scrollingUp && this.isAtTop && this.pageNum > 1) {
        this.prevPage();
        this.resetEdgeDetection();
      }
    },
    resetEdgeDetection() {
      clearTimeout(this.edgeTimer);
      this.scrollIntentDetected = false;
      this.isAtTop = false;
      this.isAtBottom = false;
    },
    async generateThumbnails() {
      for (let pageNum = 1; pageNum <= this.pdfDoc.numPages; pageNum++) {
        await this.generateThumbnail(pageNum); 
      }

      this.thumbnailsAlreadyGenerated = true;
    },
    generateThumbnail(pageNum) {
      return this.pdfDoc.getPage(pageNum).then(page => {
        const viewport = page.getViewport({ scale: 0.2 });
        const canvasWrapper = document.createElement("div");
        canvasWrapper.style.setProperty('width', '120px', 'important');
        canvasWrapper.style.setProperty('height', 'auto', 'important');
        canvasWrapper.style.setProperty('max-height', '192px', 'important');
        canvasWrapper.style.setProperty('display', 'flex', 'important');
        canvasWrapper.style.setProperty('flex-wrap', 'wrap', 'important');
        canvasWrapper.style.setProperty('flex-direction', 'row', 'important');
        canvasWrapper.style.setProperty('justify-content', 'center', 'important');
        canvasWrapper.style.setProperty('background-color', 'var(--color-box)', 'important');
        canvasWrapper.style.setProperty('cursor', 'pointer', 'important');
        canvasWrapper.onclick = () => this.goToPage(pageNum);
        const pageNumberDiv = document.createElement("div");
        pageNumberDiv.style.setProperty('display', 'flex', 'important');
        pageNumberDiv.style.setProperty('justify-content', 'center', 'important');
        pageNumberDiv.style.setProperty('flex-grow', '1', 'important');
        pageNumberDiv.style.setProperty('color', 'var(--color-box)', 'important');
        pageNumberDiv.style.setProperty('background-color', 'var(--color-secondary-text)', 'important');
        pageNumberDiv.textContent = pageNum;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        return page.render({ canvasContext: context, viewport }).promise.then(() => {
          canvasWrapper.appendChild(canvas);
          canvasWrapper.appendChild(pageNumberDiv);
          document.getElementById('thumbnails').appendChild(canvasWrapper);
        });
      });
    },    
    async printPDF() {
      try {
        // console.log("Starting print process...");

        this.loading = true;
        // await this.$nextTick();

        const pdf = await getDocument(this.objectUrl).promise;
        const printContainer = document.createElement("div");
        printContainer.classList.add("print-container");

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale: 1.5 });

          const canvas = document.createElement("canvas");
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const context = canvas.getContext("2d");

          // Render PDF page
          await page.render({ canvasContext: context, viewport }).promise;

          // Fetch signatures
          if (this.shouldFetchSignature) {
            const signatures = await this.fetchSignatures(pageNum);

            if (signatures && signatures.length > 0) {

              const signatureAlreadyCached = this.cachedSignatures.find(cs => cs?.page  == pageNum)?.signatures?.length;

              if (!signatureAlreadyCached) {
                this.cachedSignatures.push({page: pageNum, signatures});
                // console.log("cached signatures array updated")
              }

              // console.log(`Overlaying signatures on page ${pageNum}`);
              this.overlaySignatures(context, signatures, viewport);
  
              // Wait for signature rendering
              await new Promise((resolve) => setTimeout(resolve, 200)); // Ensure rendering completes
            }
          }

          printContainer.appendChild(canvas);
        }

        // Add print container to the DOM
        document.body.appendChild(printContainer);

        // Hide everything else for print
        const originalStyle = document.body.style.cssText;
        document.body.style.cssText = `
          visibility: hidden;
          position: relative;
        `;
        printContainer.style.visibility = "visible";
        printContainer.style.position = "absolute";
        printContainer.style.top = "0";
        printContainer.style.left = "0";
        printContainer.style.width = "100vw";

        // console.log("Opening print dialog...");
        window.print();

        // Restore original styles and clean up
        document.body.style.cssText = originalStyle;
        document.body.removeChild(printContainer);

      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error?.message, 'danger'));
        this.errorLoading = true;
      } finally {
        this.loading = false;

      }
    },
    //The signature retrieval logic is unlikely to be reused in any other component
    //For that reason Vuex (store) was not necessary here
    async fetchSignatures(pageNum) {
      try {

        let signatures = this.cachedSignatures.find(cs => cs?.page  == pageNum)?.signatures;

        if (signatures?.length) {
          return signatures;
        }

        // console.log(this.cachedSignatures, "cachedSignatures");

        const ROOT_PDF_VORSCHAU_SERVICE_PATH = '/pdf_vorschau_service'
        const signatureServiceUrl="/docSignatures";
        const signatureUrl = downloadLinkMaker(this.$store.getters, `${ROOT_PDF_VORSCHAU_SERVICE_PATH}${signatureServiceUrl}`, this.signatureUrlMetadata?.fileName, this.signatureUrlMetadata?.queryParams);

        // console.log(`Requesting signatures for: ${filename}, page: ${pageNum}`);
        const response = await axios.get(`${signatureUrl}`, {
          params: { page: pageNum, extractSignaturesMetadata: this.shouldEtractSignaturesMetadata }, // Send the page number as a query parameter
        });

        this.shouldEtractSignaturesMetadata = false;

        if (this.shouldFetchSignature && this.signaturesMetadata == null) {
          this.signaturesMetadata = response?.data.find(item => item.signaturesMetadata)?.signaturesMetadata;
        }

        // console.log(response?.data)

        // console.log(this.signaturesMetadata, "this.signaturesMetadata")

        
        return response.data;
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "Error fetching signature data:", error});
        return [];
      }
    },
    overlaySignatures(context, signatures, viewport) {
      signatures?.forEach((signature) => {
        const { x, y, width, height, imageBase64 } = signature;

        if (!imageBase64) {
          return;
        }

        // Convert PDF coordinates to canvas coordinates using viewport
        const [canvasX, canvasY] = viewport.convertToViewportPoint(x, y);
        const canvasWidth = width * viewport.scale;
        const canvasHeight = height * viewport.scale;

        const img = new Image();
        img.src = `data:image/png;base64,${imageBase64}`;
        img.onload = () => {
          context.drawImage(img, canvasX, canvasY - canvasHeight, canvasWidth, canvasHeight);
        };
      });
    },
    async loadPDF() {
      try {
        this.pdfDoc = await getDocument(this.objectUrl).promise;
        Vue.set(this, "pageCount", this.pdfDoc.numPages);
        this.renderPage(this.pageNum);
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error?.message, 'danger'));
        this.errorLoading = true;
      }
    },
    async renderPage(num) {
      try {
        this.loading = true;
        this.loadingSignatures = true;

        const page = await this.pdfDoc.getPage(num);
        const viewport = page.getViewport({ scale: this.scale });
        const canvas = this.$refs.pdfCanvas;

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        await page.render(renderContext).promise;

        // Fetch and overlay signature data
        if (this.shouldFetchSignature) {

          const signatures = await this.fetchSignatures(num);

          if (signatures && signatures.length > 0) {

            const signatureAlreadyCached = this.cachedSignatures.find(cs => cs?.page  == num)?.signatures?.length;

            if (!signatureAlreadyCached) {
              this.cachedSignatures.push({page: num, signatures});
              // console.log("cached signatures array updated")
            }

            this.overlaySignatures(context, signatures, viewport);
          }
        }

        // We are scrolling the page to 1 every time a new page is loaded
        // so that the scrolling method "handleScroll" can be triggered to load the previous page
        this.$nextTick(() => {
          const el = this.$refs.objectContainer;

          if (el) {
            el.scrollTop = 0; // Start at top
  
            requestAnimationFrame(() => {
              el.scrollTop = 1; // Trigger real scroll movement
            });

          }

        });
        
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error?.message, 'danger'));
        this.errorLoading = true;
      } finally {
        this.loading = false;
        this.loadingSignatures = false;
      }
    },
    onFileLoaded() {
      this.loadPDF();
    },
    resizeObjectContainer() {
      let container = this.$refs.objectContainer;
      if (container) {
        container.style.minHeight = `${this.initialHeight - 80}px`;
      }
    },
    onMinimize() {
      this.resizeObjectContainer();
      this.isMinimized = true;
    },
    onRestore() {
      this.resizeObjectContainer();
      this.isMinimized = false;
    },
    onMaximize() {
      this.resizeObjectContainer();
      this.isMinimized = false;
    },
    async showPopup() {
      Vue.set(this, "loading", true);
      Vue.set(this, "pageNum", 1);
      Vue.set(this, "scale", 1.0);
      Vue.set(this, "pageCount", 0);
      await this.$nextTick(); // Wait for DOM update
      this.$refs.resizablePopup.showPopup();

      if (this.looksLikePDF) {
        await this.loadPDF();
        Vue.set(this, "loading", false);
      } 
    },
    nextPage() {
      if (this.pageNum >= this.pageCount) return;
      this.pageNum++;
      this.renderPage(this.pageNum);
    },
    prevPage() {
      if (this.pageNum <= 1) return;
      this.pageNum--;
      this.renderPage(this.pageNum);
    },
    goToPage(whatPage) {
      this.pageNum = whatPage;
      this.renderPage(this.pageNum);
      this.showThumbnails = false;
    },
    async zoomIn() {
      this.scale += 0.2;
      await this.renderPage(this.pageNum);
    },
    async zoomOut() {
      this.scale = Math.max(1.0, this.scale - 0.2); // Prevent zoom out below 50%
      await this.renderPage(this.pageNum);
    },
    toggleThumbnailsLayer() {
      if (!this.showThumbnails) {
        if (!this.thumbnailsAlreadyGenerated) {
          this.generateThumbnails()
        } 

        document.addEventListener("mousedown", this.onClickOutside);
      } 
      this.showThumbnails = !this.showThumbnails;
    },
    cleanUpMemory() {
      if (this.pdfDoc) {
        this.pdfDoc.destroy();  // Terminate the worker associated with the PDF document
      }
      this.errorLoading = false;
      this.thumbnailsAlreadyGenerated = false;
      this.showThumbnails = false;
      this.shouldEtractSignaturesMetadata = true;
      this.signaturesMetadata = null;
      this.cachedSignatures.length = 0;
      document.removeEventListener("mousedown", this.onClickOutside);
    },    
  },
  beforeDestroy() {
    this.cleanUpMemory();
  },
};
</script>

<style scoped>
.base-modal__header--button.navigator {
  margin-left: 0; /* override the 6px */
}


.thumbnails-layer {
  display: flex; 
  flex-wrap: wrap; 
  gap: 8px;
  position: absolute;
  background: var(--color-secondary-text);
  width: 280px;
  height: 100vh;
  z-index: 1000;
  top: 90px;
  left: 0;
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.3s ease;
  justify-content: center;
  align-content: flex-start;
  padding: 8px;
  overflow-y: auto;
  padding-bottom: 110px;
}

.thumbnails-layer.visible {
  transform: translateX(0); 
  opacity: 1;
}

#thumbnails canvas {
  width: 120px;  
  height: auto;
}

.bug__icon__div {
  display: flex;
  flex-direction: column;
  height: calc(100% - 88px);
  justify-content: center;
  align-items: center;
  padding: 0 32px;
}

button:disabled svg {
  opacity: 0.4;
  cursor: not-allowed;
}

.object-container {
  width: 100%; 
  height: calc(100% - 82px); 
  overflow: auto; 
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.object-container canvas {
  display: block;
  margin: 0 auto;
  width: auto; 
  height: auto;
  max-width: none; 
  max-height: none; 
}

.object-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.current__pdf__page {
  display: inline-block;
  user-select: none;
  border-radius: 4px;
  padding: 0px;
  margin: 0px;
  min-width: 24px;
  text-align: center;
  border: 1px solid var(--color-text);
  color: var(--color-text);
}

.spacer {
  display: inline-block;
  width: 16px;
  min-width: 16px;
}

button > a {
    color: var(--color-secondary-text) !important;
  }

@media print {
  ::v-deep(body *) {
    visibility: hidden;
  }

  .print-container {
    visibility: visible;
    position: static;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    display: block;
  }
  @page {
    size: A4;
    margin: 10mm;
  }  
  canvas {
    display: block;
    width: 100%;
    height: auto;
    page-break-after: always;
  }

}

</style>