var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "login-container" },
        [
          _c("HeaderLogin", { staticClass: "login-header" }),
          _c(
            "div",
            {
              staticClass: "login-content",
              style: { backgroundImage: _vm.loginContentBackground },
              attrs: { "data-app-content": "" }
            },
            [
              _c("div", { staticClass: "login-logo-box" }, [
                _c("img", {
                  attrs: { src: _vm.logoLoginBoxBackground, alt: "Makler Logo" }
                })
              ]),
              _c("div", { staticClass: "login-box" }, [
                _c("hr", { staticClass: "login-logo__bottom--spacer" }),
                _c("div", { staticClass: "login-logo__box-title" }, [
                  _vm._v(" " + _vm._s(_vm.loginWelcomeTitle) + " ")
                ]),
                _vm.showNativeMeansOfLogin
                  ? _c(
                      "form",
                      {
                        key: "nativeMeansOfLoginForm",
                        staticClass: "login__form"
                      },
                      [
                        _vm.mfaChallengeId != null
                          ? _c("div", { staticClass: "mb-3" }, [
                              _vm._v(" " + _vm._s(_vm.mfaChallengeHint) + " ")
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "login__form--auto-login-buttons" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "login__form--auto-login-button__container"
                              },
                              [
                                _c(
                                  "BaseButton",
                                  {
                                    attrs: { isLink: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showRegularCredentialFields()
                                      }
                                    }
                                  },
                                  [_vm._v(" Mit Passwort anmelden ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "login__form--auto-login-button__container"
                              },
                              [
                                _vm.loadedLoginData.loginName
                                  ? _c(
                                      "BaseButton",
                                      {
                                        staticClass: "button-auth",
                                        attrs: {
                                          isClear: "",
                                          disabled:
                                            _vm.hasLoginInProgress ||
                                            _vm.isLoggedIn,
                                          title: _vm.authenticationTypeLabel
                                        },
                                        on: { click: _vm.loadHashedPassword }
                                      },
                                      [
                                        _vm.hasLoginInProgress || _vm.isLoggedIn
                                          ? _c("AnimatedSpinner", {
                                              staticClass:
                                                "button-auth--spinner"
                                            })
                                          : _vm._e(),
                                        _c("NativeAuthIcon", {
                                          staticClass: "button-auth--icon"
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _vm.mfaError
                          ? _c("DownloadAppInfo", {
                              staticClass: "mt-3",
                              attrs: {
                                errors: _vm.mfaError,
                                appName: _vm.mfaAppName,
                                urlIOS: _vm.mfaUrlIOs,
                                urlAndroid: _vm.mfaUrlAndroid
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _c(
                      "form",
                      {
                        key: "loginForm",
                        staticClass: "login__form",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.login.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _vm.mfaChallengeId != null
                          ? _c(
                              "div",
                              { staticClass: "mb-3" },
                              [
                                _c("div", [
                                  _vm._v(
                                    " " + _vm._s(_vm.mfaChallengeHint) + " "
                                  )
                                ]),
                                _vm.confirm2FA &&
                                _vm.mfaType === _vm.MFA_TYPE_CODE_SMS
                                  ? _c("InputField", {
                                      staticClass: "login__form--input",
                                      attrs: {
                                        label: "SMS Sicherheitscode",
                                        inputmode: "numeric",
                                        trimValue: "",
                                        autofocus: "",
                                        placeholder: "SMS Sicherheitscode",
                                        autocomplete: "one-time-code"
                                      },
                                      model: {
                                        value: _vm.loginForm.mfaCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.loginForm,
                                            "mfaCode",
                                            $$v
                                          )
                                        },
                                        expression: "loginForm.mfaCode"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : [
                              _c("InputField", {
                                staticClass: "login__form--input",
                                attrs: {
                                  id: "username",
                                  label: "Zugangsnummer oder E-Mail",
                                  placeholder: "Zugangsnummer oder E-Mail",
                                  autocomplete: "username",
                                  autofocus: ""
                                },
                                model: {
                                  value: _vm.loginForm.username,
                                  callback: function($$v) {
                                    _vm.$set(_vm.loginForm, "username", $$v)
                                  },
                                  expression: "loginForm.username"
                                }
                              }),
                              _c("InputField", {
                                staticClass: "login__form--input",
                                attrs: {
                                  id: "password",
                                  label: "Passwort",
                                  type: "password",
                                  allowPlainPassword: "",
                                  autocomplete: "current-password",
                                  enterkeyhint: "send",
                                  placeholder: "Passwort"
                                },
                                model: {
                                  value: _vm.loginForm.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.loginForm, "password", $$v)
                                  },
                                  expression: "loginForm.password"
                                }
                              }),
                              _vm.confirm2FA && _vm.mfaChallengeId == null
                                ? _c("InputField", {
                                    staticClass: "login__form--input",
                                    attrs: {
                                      label: "Sicherheitscode",
                                      autocomplete: "one-time-code",
                                      placeholder: "Sicherheitscode"
                                    },
                                    model: {
                                      value: _vm.loginForm.factor2,
                                      callback: function($$v) {
                                        _vm.$set(_vm.loginForm, "factor2", $$v)
                                      },
                                      expression: "loginForm.factor2"
                                    }
                                  })
                                : _vm._e()
                            ],
                        _vm.mfaError && _vm.mfaUrlIOs && _vm.mfaUrlAndroid
                          ? _c("DownloadAppInfo", {
                              staticClass: "mb-3",
                              attrs: {
                                errors: _vm.mfaError,
                                appName: _vm.mfaAppName,
                                urlIOS: _vm.mfaUrlIOs,
                                urlAndroid: _vm.mfaUrlAndroid
                              }
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "login__form--buttons",
                            class: {
                              "has-no-button-native-login": !_vm.showFieldsForNativeMeansOfLoginToggle
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "login__form--button login__form--button-login"
                              },
                              [
                                _c(
                                  "BaseButton",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.hasLoginInProgress ||
                                        _vm.isLoggedIn ||
                                        _vm.isMfaSmsCodeInvalid,
                                      type: "submit",
                                      isPrimary: ""
                                    }
                                  },
                                  [
                                    _vm.hasLoginInProgress || _vm.isLoggedIn
                                      ? _c("AnimatedSpinner")
                                      : _vm._e(),
                                    _vm._v(" Anmelden ")
                                  ],
                                  1
                                ),
                                !_vm.hasLoginInProgress &&
                                _vm.mfaType === _vm.MFA_TYPE_CODE_SMS
                                  ? _c(
                                      "BaseButton",
                                      {
                                        staticClass: "ml-3",
                                        attrs: { isSecondary: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.resetMfaData()
                                          }
                                        }
                                      },
                                      [_vm._v("Abbrechen")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            !_vm.hasLoginInProgress && !_vm.mfaChallengeId
                              ? [
                                  _vm.showFieldsForNativeMeansOfLoginToggle
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "login__form--button login__form--button-native-login"
                                        },
                                        [
                                          _c(
                                            "BaseButton",
                                            {
                                              attrs: { isLink: "" },
                                              on: {
                                                click: function($event) {
                                                  _vm.showFieldsForNativeMeansOfLogin = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.authenticationTypeLabel
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "login__form--button login__form--button-forgot-password"
                                    },
                                    [
                                      _c(
                                        "BaseButton",
                                        {
                                          attrs: { isLink: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.recoverPassword()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "Passwort zurücksetzen/anfordern"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.showKundeLoginSelfRegistration
                                    ? [
                                        _c("div", {
                                          staticClass:
                                            "login__form--new-account-divider"
                                        }),
                                        _vm.showRegistrationButton
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "login__form--button login__form--button-register"
                                              },
                                              [
                                                _c(
                                                  "BaseButton",
                                                  {
                                                    attrs: { isLink: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.goToRegistration()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Neues Konto erstellen"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      2
                    )
              ]),
              _vm.showCallModalWebrtcGap
                ? _c("div", { staticClass: "call-modal-webrtc-gap" }, [
                    _vm._v("   ")
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }