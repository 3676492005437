<template>
  <div>
    <InputToggleSwitch :value="data.eltif || '0'" label="Kunde wünscht ein ELTIF Produkt" inLineLabel suppressValidationMessage
      :disabled="isDisabled" @change="updateStoreEltif" />
  </div>
</template>

<script>

import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    InputToggleSwitch
  },
  data() {
    return {
      eltif: '0',
    };
  },
  mounted() {
    this.updateWarnings();
  },
  methods: {
    updateStoreEltif(value) {
      this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_ELTIF, '1' === value || value === true ? true : false);
    }
  },
}
</script>

<style scoped>
</style>