<template>
  <span class="pill__status" :class="pillClass" v-if="label || $slots.default" @click="$emit('click', $event)">
    <template v-if="label"><span v-html="sanitize(label)" :class="{ 'clickable': isClickable, }"></span></template>
    <slot v-else-if="$slots.default" />
    <span v-if="showCloseIcon" class="clickable">
      <PhXCircle v-on:click="removePill()" size="16" />
    </span>    
  </span>
</template>

<script>
import { PhXCircle } from 'phosphor-vue'
import { sanitize } from '@/helpers/string-helper.js';

export default {
  props: {
    label: {
    },
    type: {
      type: String,
      default: 'primary' // primary, success, warning, info, danger
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PhXCircle
  },
  computed: { 
    pillClass() {
      const classes = [];

      if (this.type) {
        classes.push(`pill__status--${this.type}`)
      }

      if(this.bordered) {
        classes.push('pill__status--bordered')
      }

      if (this.bold) {
        classes.push('font-bold')
      }

      return classes.join(' ')
    },
    isClickable() {
      return !!this.$listeners?.click;
    },
  },
  methods: {
    removePill() {
      this.$emit("removePill")
    },
    sanitize(html) {
      return sanitize(html);
    },
  }
}
</script>

<style>

.pill__status {
  border-radius: 4px;
  padding: 4px 4px;
  box-decoration-break: clone;
  white-space: nowrap;
  font-size: 0.8em;
}

.pill__status--bordered {
  border: 1px solid;
}

.pill__status--primary {
  /*
  background-color: var(--color-primary);
  color: white;
  */
  color: var(--color-primary);
}

.pill__status--success {
  /* background-color: var(--color-success);
  color: white; */
  color: var(--color-success);
}

.pill__status--warning {
  /* background-color:var(--color-warning);
  color: white; */
  color: var(--color-warning);

}

.pill__status--info {
  /* background-color: var(--color-info);
  color: white; */
  color: var(--color-info);
}

.pill__status--danger {
  /* background-color: var(--color-danger);
  color: white; */
  color: var(--color-danger);
}

.pill__status--default {
  border: 1px solid var(--color-text);
  color: var(--color-text);
}

.pill__status--black {
  color: var(--color-text);
}
</style>