<template>
    <div class="container">
      <div class="cms_ghost-filler" v-if="!getLoaded"></div>
      <div
        class="router_view_class"
        :class="{ router_view_margin_logged: isBroker || isIntern, router_view_margin_unlogged: !isBroker && !isIntern }"
      >
        <slot></slot>
        <router-view></router-view>
      </div>
    </div>
  </template>
  <script>
  
  import { mapGetters } from "vuex";
  import CORE_TYPES from "@/store/core/types";
  import templateRoutes from "@/views/cms/templateRoutes";
  
  export default {
    components: {
    },
    data() {
      return {
        availableRoutes: [
          "home9",
          "register",
          "login",
          "selbstauskunft"
        ],
        loaded: false,
        allroutes:{},
      };
    },
    computed: {
      ...mapGetters({
        maklerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
        isBroker: CORE_TYPES.GETTERS.IS_BROKER,
        isIntern: CORE_TYPES.GETTERS.IS_INTERN
  
        /*       maklerLogo: CORE_TYPES.GETTERS.GET_BROKER_LOGO
         */
      }),
      getLoaded(){
        return this.loaded;
      },
      maklerLogo() {
        /* 
          Makler Logo is a request to restful/cms/getBrokerLogo/maklernr
          So we test against maklerLogo String. If its empty we do not show an image => not <img>
          Else we request with prefix
        */
       /*  if (this.maklerInfo.maklerLogo) {
          const computedLogoURL = window.location.origin +  process.env.VUE_APP_API +  "/cms/getBrokerLogo/" + this.maklerInfo.maklernr
          console.log(computedLogoURL);
          return computedLogoURL;
        }
        console.log("MaklerLogo: kein Logo gefunden");
        return ""; */
        return this.maklerInfo.maklerLogo;
      }
    },
    mounted() {
      let currRoute = this.$route.path.split('/').pop();
  
      let thisroutefound=[...templateRoutes].find(x=>x.path===currRoute);
      if ([...templateRoutes].find(x=>x.path===currRoute) ){
        this.loaded = true;
      }
              
      if (currRoute === '' || currRoute === '/') {
        this.loaded = true;
      }
    },
    updated() {
      this.loaded = true;
  
    },
    created() {},
    methods: {}
  };
  </script>
  <style scoped>
  .container {
    background: var(--color-background);
    position: absolute;
    left: 0;
    width: 100%;
  }
  .cms_ghost-filler {
    height: 720px;
    background: var(--color-background);
  }
  
  .head_foot_painter {
    background: var(--color-top-nav-background);
  }
  .navi-painter{
      background: var(--color-workspaces-nav-background);
  }
  .router_view_class {
    margin: 0 auto;
  }
  .router_view_margin_logged {
    margin-top: 0em;
  }
  
  .router_view_margin_unlogged {
    margin-top: 13em;
  }
  
  .myheader_unlogged {
    width: 100%;
    position: fixed;
    z-index: 100;
    /* box-shadow: rgba(0, 0, 0, 0.117647) 0 1px 3px; */
    box-shadow: 0 4px 16px rgb(85 85 85 / 10%);
  }
  .myheader_logged {
    width: 100%;
  }
  
  #footer_component {
    grid-area: footer_component;
  }
  
  @media only screen and (max-width: 1024px) {
    .container {
      grid-template-columns: 1fr;
      grid-template-areas:
        "header"
        "naviheader"
        "footer_component";
    }
    .cms_ghost-filler {
      height: 450px;
      background: var(--color-background);
    }
  }
  </style>